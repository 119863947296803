import React from 'react';
import useStyles from './AdditionalDataField.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const AdditionalDataField = ({ data = [] }) => {
    const classes = useStyles();

    return (
    <TableContainer component={Paper}>
        <Table size="small">
            <TableBody>
                <TableRow className={classes.head}>
                    {
                        data.map((item, index) => <TableCell key={index} component="th" scope="row" align="left" className={classes.cell}>{item.field}</TableCell>)
                    }
                </TableRow>
                    <TableRow>
                        {
                            data.map((item, index) => <TableCell key={index} component="th" scope="row">
                                    {item.value}
                                </TableCell>
                            )
                        }
                    </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
    )
}

export default AdditionalDataField;
