import isCidr from "is-cidr";

const ipRegex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
const awsSubnetIdRegex = /^subnet-([0-9]|[a-z]){17}$/;
const awsInstanceIdRegex = /^i-([0-9]|[a-z]){17}$/;

const isIp = (value) => {
  return ipRegex.test(value);
};

const validateCidr = function (cidr) {
  return isCidr.v4(cidr);
};

const isMac = (value) => {
  return macRegex.test(value);
};

const isAwsSubnetId = (value) => awsSubnetIdRegex.test(value);

const isAwsInstanceId = (value) => awsInstanceIdRegex.test(value);

export { isIp, validateCidr, isMac, isAwsSubnetId, isAwsInstanceId };
