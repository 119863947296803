import { logout, SUBSCRIPTION_USER } from './auth/authActions'


export default function action(_action, router) {
    return async (dispatch, getState) => {
        try {
            return await _action(dispatch, getState);
        }
        catch (ex) {
            if (ex.status == 401) {
                dispatch(logout());
            } else if (ex.status == 405) {
                dispatch({ type: SUBSCRIPTION_USER });
            }
        }
    }
}