import TopTalkersIps from './topTalkersIps/topTalkersIps';
import TranslatableTab from './translatableTab/translatableTab';
import portsMapping from '../../../containers/topTalkers/references/ports_mapping.json';
import protocolsMapping from '../../../containers/topTalkers/references/protocols_mapping.json';
import {
	PORT_NAME_FIELD, PROTOCOL_NAME_FIELD,
} from '../collapsibleRecordComponent/collapsibleRecordSummary/spanWithTranslation/translationConsts';

export const TOP_TALKERS_TAB_NAME = 'Top Talkers IPs';
export const SRC_PORTS_TAB_NAME = 'Src. Ports';
export const DST_PORTS_TAB_NAME = 'Dst. Ports';
export const PROTOCOLS_TAB_NAME = 'Proto.';

const DATA_KEYS = {
	topTalkers: 'topTalkers',
	srcPorts: 'topSourcePort',
	dstPorts: 'topDestinationPort',
	topProto: 'topProtocol',
};

export const TABS = [TOP_TALKERS_TAB_NAME, SRC_PORTS_TAB_NAME, DST_PORTS_TAB_NAME, PROTOCOLS_TAB_NAME];

export const TABS_CONFIGURATION = [
	{
		tabName: TOP_TALKERS_TAB_NAME,
		Component: TopTalkersIps,
		dataKey: DATA_KEYS.topTalkers,
		columns: ['Address', 'Count'],
		recordCopyKey: 'IP',
		componentProps: {},
	},
	{
		tabName: SRC_PORTS_TAB_NAME,
		Component: TranslatableTab,
		dataKey: DATA_KEYS.srcPorts,
		columns: ['Source Port', 'Count'],
		recordCopyKey: 'sourcePort',
		componentProps: {
			valueKey: 'sourcePort',
			countKey: 'Count',
			translationMapping: portsMapping,
			mappingField: PORT_NAME_FIELD,
		},
	},
	{
		tabName: DST_PORTS_TAB_NAME,
		Component: TranslatableTab,
		dataKey: DATA_KEYS.dstPorts,
		columns: ['Destination Port', 'Count'],
		recordCopyKey: 'destPort',
		componentProps: {
			valueKey: 'destPort',
			countKey: 'Count',
			translationMapping: portsMapping,
			mappingField: PORT_NAME_FIELD,
		},
	},
	{
		tabName: PROTOCOLS_TAB_NAME,
		Component: TranslatableTab,
		dataKey: DATA_KEYS.topProto,
		columns: ['Protocol', 'Count'],
		recordCopyKey: 'Protocol',
		componentProps: {
			valueKey: 'Protocol',
			countKey: 'Count',
			translationMapping: protocolsMapping,
			mappingField: PROTOCOL_NAME_FIELD,
		},
	},
];
