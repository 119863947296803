import React, { Component }  from 'react';
import { connect } from 'react-redux';
import _ from 'lodash'

const FilterImage = require('../../../../assets/smart-table/filter-selected.png');

class SimpleField extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const { alias, className, filterable } = this.props;
        return (
            <div className={className}>
                <span>{alias}</span>
                {
                    filterable ?
                        <img
                            src={FilterImage}
                            alt=""
                            className='filter-img'
                        /*onClick={() => {
                            isSelected = true;
                            onClick(field, isSelected);
                        }}*/
                        />
                        : null
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SimpleField);
