import { CircularProgress, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import { required, validateForm } from 'redux-form-validators';

import SubmitButton from '../../../../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../../../../shared/components/form/TextField/TextField';

import useStyles from './SamplerSetup.css';

const SamplerSetup = ({ createSampler, onNext, handleSubmit }) => {
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);

	const createSamplerHandler = async samplerData => {
		setIsLoading(true);
		await createSampler(samplerData);
		onNext();
	};

	return <Grid container>
		<form onSubmit={handleSubmit(createSamplerHandler)} className={classes.formWrapper}>
			<Field
				component={TextField}
				label="Sampler name"
				name="name"
				type="text"
				className={classes.textField}
				required
			/>
			<Grid item xs={12} className={classes.explanationWrapper}>
				<Typography paragraph className={`${classes.explanationLabel} ${classes.explanationLabelTop}`}>Download one zip file that will contain the following:</Typography>
				<Typography paragraph className={classes.explanationLabel}>1. Setup instructions and ova link (readme txt file)</Typography>
				<Typography paragraph className={classes.explanationLabel}>2. Registration command (txt file)</Typography>
			</Grid>
			<Grid item xs={12} className={classes.buttonWrapper}>
				{isLoading ? <CircularProgress /> : <SubmitButton className={classes.button} label="Add Sampler and download files" /> }
			</Grid>
		</form>

	</Grid>;
};

SamplerSetup.propTypes = {
	createSampler: PropTypes.func,
	onNext: PropTypes.func,
	handleSubmit: PropTypes.func,
};

const validate = validateForm({
	name: [required({ msg: 'Please enter sampler name' })],
});

export default reduxForm({ enableReinitialize: true, validate })(SamplerSetup);
