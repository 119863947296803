export const ACCEPTLIST_CONFIG = {
	title: 'Acceptlist',
};

export const MODAL_INITIAL_VALUES = {
	threatsService: true,
	vcaService: true,
	customAlertsService: true,
	isBidirectional: false,
};

export const RULE_MODAL_CREATE_TYPE = 'CREATE';
export const RULE_MODAL_EDIT_TYPE = 'EDIT';
export const RULE_MODAL_TITLE = 'Rule properties';

export const VALIDATION_ATLEAST_ONE_FIELD = ['srcIps', 'srcPorts', 'dstIps', 'dstPorts', 'protocolIds'];
export const VALIDATION_ATLEAST_ONE_SERVICE = ['threatsService', 'vcaService', 'customAlertsService'];

export const VALIDATION_DATA_ERROR = 'Please select at-least one property';
export const VALIDATION_SERVICE_ERROR = 'Please select at-least one service';

export const MODAL_TYPE_TO_TITLE = {
	[RULE_MODAL_CREATE_TYPE]: 'Add a rule',
	[RULE_MODAL_EDIT_TYPE]: 'Edit rule',
};

export const MODAL_TYPE_TO_SUBMIT_TEXT = {
	[RULE_MODAL_CREATE_TYPE]: 'Add rule',
	[RULE_MODAL_EDIT_TYPE]: 'Save',
};

export const MODAL_TYPE_TO_ERROR_TEXT = {
	[RULE_MODAL_CREATE_TYPE]: 'Failed to create rule',
	[RULE_MODAL_EDIT_TYPE]: 'Failed to update rule',
};

export const MODAL_TYPE_TO_SUCCESS_TEXT = {
	[RULE_MODAL_CREATE_TYPE]: 'Rule created',
	[RULE_MODAL_EDIT_TYPE]: 'Rule updated',
};

export const RULES_RETRIVE_FAILED_MESSAGE = 'Failed to get rules from the server';
export const RULE_TOGGLE_FAILED_MESSAGE = 'Failed to toggle rule';
export const RULE_DELETE_FAILED_MESSAGE = 'Failed to delete rule';
