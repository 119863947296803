
// Firewall device functions
import { createCollectorInternalPortMapping } from '../../controller/businessLogic/mappers';
import { addCollectorToDevice, updateCollectorDevice } from '../../controller/services/collectorDeviceRequests';
import { addCollector as addCollectorRequest } from '../../controller/services/collectorRequests';
import { addDevice, updateDevice } from '../../controller/services/deviceRequests';
import { addPublicCloudDevice, updatePublicCloudDevice } from '../../controller/services/publicCloudDeviceRequests';

// Firewall functions
export const addFirewallDevice = async device => {
	if (device.samplingRate) {
		device.samplingRate = parseInt(device.samplingRate, 10);
	}

	return addDevice(device);
};

export const editFirewallDevice = async device => updateDevice(device._id, {
	IP: device.IP,
	name: device.name,
	samplingRate: parseInt(device.samplingRate, 10),
	brand: device.brand,
	model: device.model,
});

// Switch device functions
export const addSwitchDevice = async device => addCollectorToDevice(
	device.collectorId,
	device.samplerId,
	device.name,
	device.type,
	device.IP,
	device.model,
	device.brand,
	parseInt(device.samplingRate, 10),
	device.status);

export const editSwitchDevice = async device => updateCollectorDevice(device._id, {
	IP: device.IP,
	status: device.STATUS,
	name: device.name,
	samplingRate: parseInt(device.samplingRate, 10),
	brand: device.brand,
	model: device.model,
});

// Cloud device functions
export const addCloudDevice = async device => addPublicCloudDevice(device);

export const editCloudDevice = async device => {
	const { name, _id: deviceId } = device;
	return updatePublicCloudDevice(deviceId, { name });
};

// Add collector
export const addCollector = async collector => {
	const mapping = createCollectorInternalPortMapping(collector);
	const response = await addCollectorRequest(collector.collectorName, collector.osName.toLowerCase(), mapping, collector.privateIP);
	return response;
};

// Add Sampler
// Since sampler is a stepper creation, we create the sampler in the first step, and just return it when finished
export const addSampler = async sampler => sampler;
