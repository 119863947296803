import React, { PureComponent } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import {
	ANOMALIES_POLLING_INTERVAL,
	BACKGROUND_PROCESSES_POOLING_INTERVAL,
	GET_TOTALS_INTERVAL,
	SUMMARY_POLLING_INTERVAL,
	VISIBLE_DEVICES_POLLING_INTERVAL,
} from '../../../../constants/constants';
import { newUpdateBackgroundProcess } from '../../../backgroundProcess/controller/redux/backgroundProcessAction';
import {
	enrichNotification,
	getSummaryData,
	getTotals,
} from '../../../backgroundProcess/controller/redux/analyzeActions';
import { getVisibleDevicesCount } from '../../../deviceManagement/controller/visibleDeviceCount/actions';

class IntervalWrapper extends PureComponent {
	static propTypes = {
		children: PropTypes.element.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = { loaded: false };
	}

	async UNSAFE_componentWillMount() {
		setInterval(this.updateSummaryData.bind(this), SUMMARY_POLLING_INTERVAL);
		setInterval(this.updateTotals.bind(this), GET_TOTALS_INTERVAL);
		setInterval(this.updateBackgroundProcess.bind(this), BACKGROUND_PROCESSES_POOLING_INTERVAL);
		setInterval(this.updateAnomalize.bind(this), ANOMALIES_POLLING_INTERVAL);
		setInterval(this.updateVisibleDevicesCount.bind(this), VISIBLE_DEVICES_POLLING_INTERVAL);
	}

	async componentDidUpdate(prevProps) {
		if (!prevProps.user && prevProps.user !== this.props.user) {
			this.updateTotals();
		}
	}

	async updateBackgroundProcess() {
		await this.props.newUpdateBackgroundProcess();
	}

	async updateTotals() {
		if (!_.isEmpty(this.props.user)) {
			await this.props.getTotals();
		}
	}

	async updateSummaryData() {
		if (!_.isEmpty(this.props.user)) {
			await this.props.getSummaryData();
		}
	}

	async updateAnomalize() {
		if (!_.isEmpty(this.props.user)) {
			await this.props.enrichNotification();
		}
	}

	async updateVisibleDevicesCount() {
		if (this.props.user) {
			this.props.getVisibleDevicesCount();
		}
	}

	componentDidCatch(error, info) {
		console.log(error.message);
	}

	render() {
		const { children } = this.props;
		return (
			<>
				{children}
			</>
		);
	}
}

IntervalWrapper.propTypes = {
	getVisibleDevicesCount: PropTypes.func,
	enrichNotification: PropTypes.func,
	getSummaryData: PropTypes.func,
	newUpdateBackgroundProcess: PropTypes.func,
	getTotals: PropTypes.func,
	user: PropTypes.shape({
		accountId: PropTypes.string,
		email: PropTypes.string,
		firstName: PropTypes.string,
		isAdmin: PropTypes.bool,
		lastLogin: PropTypes.string,
		lastName: PropTypes.string,
		mfa: PropTypes.string,
		referralId: PropTypes.string,
		_id: PropTypes.string,
	}),
};
export default withRouter(
	connect(state =>
		({
			user: state.auth.user,
			summaryData: state.analyze.summaryData,
			processes: state.backgroundProcesses.processes,
			currentProcess: state.backgroundProcesses.currentProcess,
			needSubscription: state.auth.needSubscription,
			threats: state.threats.threats,
			alertes: state.alertes.alertes,
			graphs: state.alertes.graphs,
		}),
	{
		getSummaryData,
		enrichNotification,
		newUpdateBackgroundProcess,
		getTotals,
		getVisibleDevicesCount,
	},
	)(IntervalWrapper),
);
