import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	emptyStateContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: '50px',
	},
	emptyStateText: {
		fontSize: '24px',
		lineHeight: '13px',
		fontWeight: 500,
		color: theme.palette.inputText.main,
	},
	emptyStateSubtext: {
		fontSize: '20px',
		lineHeight: '28px',
		width: '650px',
		fontWeight: 200,
		color: theme.palette.inputText.main,
		marginTop: '24px',
	},
	noDetectionsImageNoFilters: {
		width: '320px',
		height: '250px',
		display: 'block',
		margin: 'auto',
		marginBottom: '20px',
	},
	noDetectionsImageFilters: {
		width: '125px',
		height: '125px',
		display: 'block',
		margin: 'auto',
		marginBottom: '20px',
	},
}));

export default useStyles;
