import React from "react";
import step1Side from "../../../../../../../assets/walkthrough/analysis/steps/step1Side.png";
import step4Main from "../../../../../../../assets/walkthrough/analysis/steps/step4Main.png";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step4.css";
import MainPanelTemplateV2
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const TITLE = "Devices";
const DESCRIPTION = "Select what network gateways to run the query on, from a set of devices on the \"Devices\" page.";

const Step4 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV2 title={TITLE}
                                 image={<img src={step4Main} className={classes.image}/>}
                                 description={DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step4;