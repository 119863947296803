import React, { Component } from 'react';
import AlerteGraph from './alerteGraph/alertGraphWrapper';
import { connect } from 'react-redux';


class Graph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        }
    }

    render() {
        const { data, isEnabled } = this.props;
        return (<div className="graph">
            { isEnabled == false ? <div /> : <AlerteGraph
                data={ data }
                threshold={ this.props.threshold } /> }

        </div>);
    }
}



const mapStateToProps = state => {

    return {
        selectedFilter: state.alertes.selectedFilter,
        allGraphs: state.alertes.graphs
    };
};
export default connect(mapStateToProps, null)(Graph);