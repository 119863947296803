import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import React from 'react';

import useStyles from './tabColumns.css';

const TabColumns = ({ columns }) => {
	const classes = useStyles();
	return (
		<Grid className={classes.root} container xs={12}>
			<Grid item xs={5}>{columns[0]}</Grid>
			<Grid item xs={4}>{columns[1]}</Grid>
		</Grid>
	);
};

TabColumns.propTypes = {
	columns: PropTypes.array,
};

export default TabColumns;
