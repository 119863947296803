import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CollapsibleRecordComponent from '../../collapsibleRecordComponent/collapsibleRecordComponent';

const TopTalkersIps = ({ data }) => {
	const [activePanelName, setActivePanelName] = useState();

	const handleChange = panel => (event, isExpanded) => {
		setActivePanelName(isExpanded ? panel : false);
	};

	return (
		<>
			{data.map((dataToDisplay, index) => (
				<CollapsibleRecordComponent
					key={`panel${index}`}
					data={dataToDisplay}
					activePanelName={activePanelName}
					handleChange={handleChange}
					panelName={`panel${index}`}
				/>
			))}
		</>
	);
};

TopTalkersIps.propTypes = {
	data: PropTypes.array,
};

export default TopTalkersIps;

