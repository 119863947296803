import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    button: {
        position: 'absolute',
        top: 10,
        right: 10,
        cursor: 'pointer'
    }
}));

export {useStyles}