import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    iconAlignment: {
        verticalAlign: "text-bottom",
    },

    upgradeIcon: {
        verticalAlign: "middle",
        filter: "invert(55%) sepia(77%) saturate(785%) hue-rotate(151deg) brightness(90%) contrast(108%)",
    },
}));

export {useStyles}