import React, { useEffect, useRef, useState } from 'react';
import { WidgetContainer } from '../../../index';
import {DEVICE_TRAFFIC_WIDGET_TITLE, TRAFFIC_BY_PORT} from '../deviceMonitoringTexts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useStyles } from './TrafficByPort.jsx.css';
import { config, generateColors } from './pieChartConfig';
import useInterval from '../../../../customHooks/useInterval';
import { getPortsByDevice } from '../../services/requests';
import { NoData } from '../sharedComponents/NoData';
import PropTypes from 'prop-types';

const FETCH_DEVICE_PORTS_INTERVAL_MS = 1000 * 60 * 5;
let currentDeviceId;

const TrafficByPort = ({ deviceId }) => {
	const classes = useStyles();
	const chartRef = useRef(null);
	const [options, setOptions] = useState(config);
	const [isDeviceHaveData, setIsDeviceHaveData] = useState(true);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		fillGraphWithPortsData(true);
		currentDeviceId = deviceId;
	}, [deviceId]);

	useEffect(() => {
		if (isLoading === true) {
			chartRef?.current?.chart?.showLoading('Loading data...');
		} else {
			chartRef?.current?.chart?.hideLoading();
		}
	}, [isLoading]);

	const fillGraphWithPortsData = async (hasDeviceChanged = false) => {
		currentDeviceId = deviceId;

		if (hasDeviceChanged) {
			setIsLoading(true);
		}

		try {
			const { data: ports, deviceId: requestDeviceId } = await getPortsByDevice(deviceId);

			if (Boolean(currentDeviceId) && currentDeviceId !== requestDeviceId) {
				return;
			}

			setIsDeviceHaveData(ports?.length > 0);

			const colorArray = generateColors(ports.length);
			setOptions(prevOptions => ({
				...prevOptions,
				plotOptions: { pie: { colors: colorArray } },
				series: [{ ...prevOptions.series[0],
					data: ports.map(item => ({
						...item,
						y: item.volume,
					})),
				}],
			}));
		} finally {
			setIsLoading(false);
		}
	};

	useInterval(fillGraphWithPortsData, FETCH_DEVICE_PORTS_INTERVAL_MS);

	return (
		<WidgetContainer title={TRAFFIC_BY_PORT}>
			{isDeviceHaveData
				? <div className={classes.highchartsContainer}>
					<HighchartsReact
						highcharts={Highcharts}
						options={options}
						containerProps={{ style: { height: '100%' } }}
						ref={chartRef}
					/>
				</div> : <NoData/>}
		</WidgetContainer>
	);
};

TrafficByPort.propTypes = {
	deviceId: PropTypes.string.isRequired,
};
TrafficByPort.defaultValue = {};

export default React.memo(TrafficByPort);
