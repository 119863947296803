import React from "react";
import { Field } from "redux-form";

import DirectionSelectCreateable from "../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable";
import QueryInput from "../../../shared/components/queryInput/queryInput";
import { useStyles as useInputStyles } from "./interfacesFieldsInput.css";
import { validateNumericSelector } from "../../backgroundProcess/controller/validations/validation";

import { useStyles } from "./interfacesFields.css";
import "./interfacesFieldsSelector.scss";
import { textSelectorStyles } from '../../../shared/utils/customSelectorStyles';

const InterfacesFields = () => {
    const classes = useStyles();
    const inputClasses = useInputStyles();

    return (
        <div className={classes.root}>
            <QueryInput
                title="Input Interface"
                classes={inputClasses}
                input={
                    <Field
                        name={"inputInterface"}
                        component={DirectionSelectCreateable}
                        placeholder="Add one or more interface numbers"
                        additionalClasses="interfaces-selector"
                        validation={validateNumericSelector}
                        extraStyles={textSelectorStyles}
                    />
                }
            />
            <QueryInput
                title="Output Interface"
                classes={inputClasses}
                input={
                    <Field
                        name={"outputInterface"}
                        component={DirectionSelectCreateable}
                        placeholder="Add one or more interface numbers"
                        additionalClasses="interfaces-selector"
                        validation={validateNumericSelector}
                        extraStyles={textSelectorStyles}
                    />
                }
            />
        </div>
    );
};

export default InterfacesFields;
