import _ from 'lodash';

export const thresholdObject = {
    'GT': {
        value:"GT", 
        sign: '>',
        label: 'Greater Than'
    },
    'GTE': {
        value:"GTE", 
        sign: '>=',
        label: 'Greater or Equal'
    },
    'LT': {
        value:"LT", 
        sign: '<',
        label: 'Less Than'
    },
    'LTE': {
        value:"LTE", 
        sign: '<=',
        label: 'Less Equal'
    }
}

let keys = Object.keys(thresholdObject);

export const thresholdArray = _.map(keys, (e) => {
    let temps = thresholdObject[e];
    return {
        value: e,
        ...temps
    }
});


