import React from 'react';
import PaperFrame from '../../../shared/components/PaperFrame/PaperFrame';
import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { useStyles } from './billingFrame.css';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const BillingFrame = ({ invoices = [] }) => {
	const classes = useStyles();
	return (
		<PaperFrame elevation={1} title={'Billing'} height={'unset'} width={920} minHeight={'420px'}>
			<Table>
				<TableHead className={classes.header}>
					<TableRow>
						<TableCell align={'left'}>
							<Typography>Date/Invoice</Typography>
						</TableCell>
						<TableCell align={'left'}>
							<Typography>Description</Typography>
						</TableCell><
							TableCell align={'left'}>
							<Typography>Payment Method</Typography>
						</TableCell>
						<TableCell align={'left'}>
							<Typography>Status</Typography>
						</TableCell>
						<TableCell align={'left'}>
							<Typography>Total</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{invoices.map((invoice, index) => (
						<TableRow classes={{ root: classes.cell }} key={index}>
							<TableCell>
								<Typography className={classes.basicFontSize}>{moment.unix(invoice.dueDate).format('MMMM D, YYYY')}</Typography>
								<Link color={'primary'} href={invoice.pdf} className={clsx([classes.basicFontSize, classes.link])}>{invoice.number}&nbsp;&nbsp;<DownloadIcon/></Link>
							</TableCell>
							<TableCell align={'left'}>
								<Typography className={classes.basicFontSize}><strong>{invoice.product}</strong></Typography>
							</TableCell>
							<TableCell align={'left'}>
								<Typography>{invoice.last4 && invoice.last4.padStart(10, '*')}</Typography>
							</TableCell>
							<TableCell align={'center'}>
								<Typography variant="body2" className={invoice.isPaid ? classes.paid : classes.incomplete}>{invoice.status.toUpperCase()}</Typography>
							</TableCell >
							<TableCell align={'left'}>
								<Typography className={classes.total}>${Number(invoice.total).toLocaleString()}</Typography>
							</TableCell>
						</TableRow>

					))}
				</TableBody>
			</Table>
		</PaperFrame>
	);
};

BillingFrame.propTypes = {
	invoices: PropTypes.arrayOf(
		PropTypes.shape({
			dueDate: PropTypes.number,
			pdf: PropTypes.string,
			number: PropTypes.string,
			product: PropTypes.string,
			status: PropTypes.string,
			isPaid: PropTypes.bool,
			total: PropTypes.number,
			last4: PropTypes.string,
		})),
};
export default BillingFrame;
