import React from 'react';
import FilterDropdown from '../../../../shared/components/filterDropdown/filterDropdown';
import { Star, StarOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import useStyles from './highlightFilter.css';
import { ALL_LABEL, HIGHLIGHTED_LABEL } from '../filtersConfig';

const HighlightFilterDropdown = ({ onHighlight }) => {
	const classes = useStyles();

	const items = [{
		label: ALL_LABEL,
		value: false,
		icon: <StarOutlined className={classes.all} />,
	},
	{
		label: HIGHLIGHTED_LABEL,
		value: true,
		icon: <Star className={classes.highlighted} />,
	}];

	return (
		<FilterDropdown items = {items} defaultSelectionIndex={0} onItemSelected={onHighlight} />
	);
};

HighlightFilterDropdown.propTypes = {
	onHighlight: PropTypes.func.isRequired,
};

export default HighlightFilterDropdown;
