import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    dialog: {
        overflow: 'visible !important'
    },
    container: {
        height: '100%'
    },
    selectItem: {
        height: '11%',
        maxWidth: '300px'
    },

    form: {
        height: '90%'
    }
}));

export {useStyles}