import Grid from '@material-ui/core/Grid';
import React from 'react';
import { ReactComponent as VisibilityForInformationIcon } from '../../../../../../../assets/endpointInventory/VisibilityForInformation.svg';
import { ReactComponent as DownloadIcon } from '../../../../../../../assets/endpointInventory/Download.svg';
import { useStyles } from './endpointInventroyReport.css';
import CustomTooltip from '../../../../../../../shared/components/Tooltip/CustomTooltip';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';

import PropTypes from 'prop-types';
import { toLocaleDateString } from '../../../../../../../helpers/datesHelper';

const propTypes = {
	date: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	downloadLink: PropTypes.string.isRequired,
};

const EndpointInventoryReport = ({ date, description, downloadLink }) => {
	const classes = useStyles();
	const reportFormattedDate = toLocaleDateString(date * 1000);

	const downloadFile = () => {
		const downloadElement = document.createElement('a');
		downloadElement.href = downloadLink;
		downloadElement.setAttribute('download', `endpoint_inventory_report_${reportFormattedDate}`);
		downloadElement.click();
	};

	return (
		<Grid container alignItems="stretch" spacing={2}>
			<Grid item xs={9} className={classes.reportLabel}>
                Generated on {reportFormattedDate}
			</Grid>

			<Grid item xs={3} container>
				<Grid xs={6}>
					<CustomTooltip TransitionComponent={Fade} title={<span style={{ whiteSpace: 'pre-line', fontSize: '12px' }}>{description}</span>}>
						<IconButton disableRipple edge={false} size={'small'}>
							<VisibilityForInformationIcon />
						</IconButton>
					</CustomTooltip>
				</Grid>
				<Grid xs={6}>
					<CustomTooltip TransitionComponent={Fade} title="Download">
						<IconButton disableRipple edge={false} size={'small'} onClick={downloadFile}>
							<DownloadIcon />
						</IconButton>
					</CustomTooltip>
				</Grid>
			</Grid>
		</Grid>);
};

EndpointInventoryReport.propTypes = propTypes;

export default EndpointInventoryReport;
