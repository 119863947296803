import {REQ_ACCOUNTS} from "./adminActions";

const initialState = {
    accounts: [],
};
export default function (state = initialState, {type, payload}) {
    switch (type) {
        case REQ_ACCOUNTS:
            return {
                ...state,
                accounts: [
                    ...payload,
                ]
            };
        default:
            return state;
    }
}