import React from "react";
import step1Side from "../../../../../../../assets/walkthrough/analysis/steps/step1Side.png";
import step1Main from "../../../../../../../assets/walkthrough/analysis/steps/step1Main.png"
import {useStyles} from "./step1.css";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV1
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV1/mainPanelTemplateV1";

const TITLE = "Analysis bar";
const DESCRIPTION = "Here you can run network queries.";

const Step1 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV1 title={TITLE}
                                 image={<img src={step1Main} className={classes.image}/>}
                                 description={DESCRIPTION} />
        </StepTemplate>
    )
}

export default Step1;