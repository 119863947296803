const dropdownSelectorStyles = {
	option: styles => ({
		...styles,
		cursor: 'pointer',
	}),
	control: styles => ({
		...styles,
		cursor: 'pointer',
	}),
};

const textSelectorStyles = {
	menuList: provided => ({
		...provided,
		maxHeight: '140px',
	}),
	option: styles => ({
		...styles,
		cursor: 'pointer',
	}),
	control: styles => ({
		...styles,
		cursor: 'text',
	}),
};

export { dropdownSelectorStyles, textSelectorStyles };
