import { collectorTypes } from '../../view/devicesManagementConfig';

const s3AddressSpaceByEnv = {
	gov: 's3-us-gov-west-1',
	prod: 's3',
	eu: 's3',
	dev: 's3',
};

const s3Address = s3AddressSpaceByEnv[process.env.REACT_APP_CYNAMICS_ENV];

const osType = [
	{
		label: 'Docker',
		value: 'DOCKER',
		supportedBy: { [collectorTypes.LEGACY_COLLECTOR_TYPE]: true },
	},
	{
		label: 'Windows Server',
		value: 'WINDOWS_SERVER',
		supportedBy: { [collectorTypes.LEGACY_COLLECTOR_TYPE]: true },
	},
	{
		label: 'Ubuntu',
		value: 'UBUNTU',
		supportedBy: { [collectorTypes.LEGACY_COLLECTOR_TYPE]: false },
	},
	{
		label: 'CentOS',
		value: 'CENTOS',
		supportedBy: { [collectorTypes.LEGACY_COLLECTOR_TYPE]: false },
	},
	{
		label: 'Ubuntu 22',
		value: 'UBUNTU_22',
		supportedBy: [{ [collectorTypes.SAMPLER_TYPE]: false }],
	},
];

export {
	osType,
	s3Address,
};
