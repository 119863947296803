import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    screenTitle: {
        fontSize: '45px',
        fontWeight: 'bold',
        marginBottom: '20px'
    }

}));

export {useStyles}