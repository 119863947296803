import React from 'react';
import clsx from 'clsx';
import QueryInput from '../../../shared/components/queryInput/queryInput';
import { Field } from 'redux-form';
import DirectionSelectCreateable
	from '../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable';
import { validateNumericSelector } from '../../backgroundProcess/controller/validations/validation';
import { textSelectorStyles } from '../../../shared/utils/customSelectorStyles';
import { useStyles as useInputStyles } from '../interfacesFields/interfacesFieldsInput.css';
import { useStyles } from './vlanFields.css';
import CheckboxInput from '../../../shared/components/checkboxInput/CheckboxInput';
import { ReactComponent as BidirectionalIcon } from '../../../assets/icons/bidirectionalIcon.svg';
import PropTypes from 'prop-types';
import {
	DST_VLAN_ID_LABEL,
	SRC_VLAN_ID_LABEL,
	VLAN_ID_PLACEHOLDER,
	VXLAN_ID_LABEL,
	VXLAN_ID_PLACEHOLDER,
} from './texts';

const propTypes = {
	formName: PropTypes.string.isRequired,
};

const VlanFields = ({ formName }) => {
	const inputClasses = useInputStyles();
	const styles = useStyles();

	return (
		<div className={styles.root}>
			<QueryInput
				title={SRC_VLAN_ID_LABEL}
				classes={inputClasses}
				input={
					<Field
						name={'vlan.srcVlanId'}
						component={DirectionSelectCreateable}
						placeholder={VLAN_ID_PLACEHOLDER}
						additionalClasses="interfaces-selector"
						validation={validateNumericSelector}
						extraStyles={textSelectorStyles}
					/>
				}
			/>
			<QueryInput
				title={DST_VLAN_ID_LABEL}
				classes={inputClasses}
				input={
					<Field
						name={'vlan.dstVlanId'}
						component={DirectionSelectCreateable}
						placeholder={VLAN_ID_PLACEHOLDER}
						additionalClasses="interfaces-selector"
						validation={validateNumericSelector}
						extraStyles={textSelectorStyles}
					/>
				}
			/>
			<CheckboxInput name={'vlanIdBidirectionalTraffic'} label={'Bidirectional'} formName={formName} Icon={BidirectionalIcon} />
			<div className={styles.vxlan}>
				<QueryInput
					title={VXLAN_ID_LABEL}
					classes={inputClasses}
					input={
						<Field
							name={'vlan.vxlanId'}
							component={DirectionSelectCreateable}
							placeholder={VXLAN_ID_PLACEHOLDER}
							additionalClasses="interfaces-selector"
							validation={validateNumericSelector}
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>

		</div>
	);
};

VlanFields.propTypes = propTypes;
export default VlanFields;
