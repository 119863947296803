import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import './optionsMenu.scss';
import CustomTooltip from '../../../../../shared/components/Tooltip/CustomTooltip';
import PropTypes from 'prop-types';

export default class OptionsMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
		};
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	deactivate = () => {
		this.props.deactivate();
		this.setState({ anchorEl: null });
	};

	activate = () => {
		this.props.activate();
		this.setState({ anchorEl: null });
	};

	delete = () => {
		this.props.delete();
		this.setState({ anchorEl: null });
	};

	settings = () => {
		this.props.settings();
		this.setState({ anchorEl: null });
	};

	render() {
		const { anchorEl } = this.state;
		const open = Boolean(anchorEl);
		const { isDelete, isShowEnable } = this.props;

		return (
			<div className="menu-integration-box">
				<CustomTooltip title={'Options'}>
					<IconButton
						onClick={this.handleClick}
					>
						<MoreVertIcon/>
					</IconButton>
				</CustomTooltip>
				<Menu
					id="long-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={this.handleClose}
					className="integration-popover"
				>
					<MenuItem onClick={() => {
						this.settings();
					}}>Settings</MenuItem>
					{isShowEnable
						? <MenuItem onClick={() => {
							this.activate();
						}}>Activate</MenuItem>
						: <MenuItem onClick={() => {
							this.deactivate();
						}}>Deactivate</MenuItem>}
					{isDelete && <MenuItem onClick={() => {
						this.delete();
					}}>Delete</MenuItem>}
				</Menu>
			</div>
		);
	}
}

OptionsMenu.propTypes = {
	deactivate: PropTypes.func,
	activate: PropTypes.func,
	settings: PropTypes.func,
	delete: PropTypes.func,
	isDelete: PropTypes.bool,
	isShowEnable: PropTypes.bool,
};
