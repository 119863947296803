import {
	OPEN_BANNER, CLOSE_BANNER,
} from './actionTypes';

export default function (state = [], { type, payload }) {
	switch (type) {
		case OPEN_BANNER:
		{
			payload.isOpen = true;
			if (state.length > 0) {
				const doesBannerExist = state.some(banner => {
					const { dismissed, ...rest } = banner;
					if (JSON.stringify(rest) === JSON.stringify(payload)) {
						return true;
					}

					return false;
				});
				if (doesBannerExist) {
					return state;
				}
			}

			return [...state, payload];
		}

		case CLOSE_BANNER:
		{
			const updated = [...state];
			if (payload.dismissed) {
				updated[updated.length - 1].dismissed = payload.dismissed;
			} else {
				updated.pop();
			}

			return updated;
		}

		default:
			return state;
	}
}
