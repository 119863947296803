import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	root: {
		fontSize: '14px',
	},
	passwordContent: {
		content: '\\00B7',
	},
	userSettingsMainDiv: {
		margin: '20px',
	},
	phoneNumber: {
		cursor: 'pointer',
	},
	mfaSection: {
		display: 'flex',
		alignItems: 'center',
	},
}));

export { useStyles };
