import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	firstColumn: {
		color: theme.palette.secondary.main,
		fontWeight: 600,
		paddingRight: '8px',
		verticalAlign: 'text-top',
	},
}));

export default useStyles;
