import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

export default class TabPanel extends Component {


    render() {
        const { children, value, index, ...other } = this.props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ height: '100%' }}>
                        {children}
                    </Box>
                )}
            </div>
        );
    }
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
