import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		margin: 0,
		padding: 0,
	},
	label: {
		margin: 0,
	},
}));

export default useStyles;
