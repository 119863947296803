import React, {Component} from "react";
import './makeRemovable.scss';
import {ReactComponent as Cross} from '../../../../../assets/backgroundProcess/x.svg';
import Grid from "@material-ui/core/Grid";

const MakeRemovable = ({children, className, handleRemove}) => {
    return (
        <Grid container>
            <Grid item xs={1}>
                <button
                    className={className}
                    onClick={handleRemove}
                >
                    <Cross/>
                </button>
            </Grid>
            <Grid item xs={11}>
                {children}
            </Grid>
        </Grid>
    )
}

export default MakeRemovable;
