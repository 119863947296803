import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { useStyles } from './endpointInventoryIntegration.css';
import PropTypes from 'prop-types';
import EndpointInventoryForm from './endpointInventoryForm/endpointInventoryForm';
import { createFilters } from '../../../../../shared/components/queryLine/selectors/commonSelectCreatable/selectCreatableFiltersGenerate';
import {
	createReports,
	getReports,
	getAutoCompleteOptions,
} from '../../../../endpointInventory/services/endpointInventoryRequests';
import EndpointInventoryReportsView from './endpointInventoryReportsView/endpointInventoryReportsView';

const propTypes = {
	updateSelectedIntegration: PropTypes.func.isRequired,
	setSnackBarSeverity: PropTypes.func.isRequired,
	setSnackBarMessage: PropTypes.func.isRequired,
	setIsSnackBarShouldOpen: PropTypes.func.isRequired,

};
const MAX_REPORTS_PER_ACCOUNT = 5;
const ERROR_UNAUTHORIZED = 403;

const EndpointInventoryIntegration = ({ updateSelectedIntegration, setSnackBarSeverity, setSnackBarMessage, setIsSnackBarShouldOpen }) => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [vendorOptions, setVendorOptions] = useState([]);
	const [originOptions, setOriginOptions] = useState([]);
	const [reports, setReports] = useState([]);

	const userEmail = useSelector(state => state && state.auth && state.auth.user && state.auth.user.email);

	const handleSubmit = async formValues => {
		setIsLoading(true);
		try {
			const endpointDetailsReportFilters = createFilters(formValues.endpointDetailsReport.filters);

			const createReportsRequest = { ...formValues, endpointDetailsReport: { ...formValues.endpointDetailsReport,
				filters: endpointDetailsReportFilters } };

			await createReports(createReportsRequest);
			setSnackBarSeverity('success');
			setSnackBarMessage('Your report was successfully submitted');
		} catch (e) {
			if (e.status === ERROR_UNAUTHORIZED) {
				setSnackBarMessage('You\'ve reached the maximum number of reports');
			} else {
				setSnackBarMessage('Something went wrong. Try again');
			}

			setSnackBarSeverity('error');
		} finally {
			setIsLoading(false);
			setIsSnackBarShouldOpen(true);
			updateSelectedIntegration();
		}
	};

	useEffect(() => {
		async function getAutoCompleteOptionsData() {
			try {
				const { vendorOptions, originOptions } = await getAutoCompleteOptions();
				setVendorOptions(vendorOptions);
				setOriginOptions(originOptions);
			} catch {}
		}

		async function getReportsData() {
			try {
				const res = await getReports();
				setReports(res);
			} catch {}
		}

		getAutoCompleteOptionsData();
		getReportsData();
	}, []);

	return (
		<div className={classes.paper}>
			<Grid container direction="row" alignItems="stretch" spacing={2}>
				<Grid item xs={6}>
					<EndpointInventoryForm
						onSubmit={handleSubmit}
						userEmail={userEmail}
						isLoading={isLoading}
						maxReportsPerAccount={MAX_REPORTS_PER_ACCOUNT}
						vendorOptions={vendorOptions}
						originOptions={originOptions}
					/>
				</Grid>
				<Grid item xs={1}>
					<Divider orientation="vertical" variant="center" />
				</Grid>
				<Grid item xs={5}>
					<EndpointInventoryReportsView reports={reports}/>
				</Grid>
			</Grid>
		</div>
	);
};

EndpointInventoryIntegration.propTypes = propTypes;
export default EndpointInventoryIntegration;
