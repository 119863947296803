import React, {useEffect} from 'react';
import Plan from "../../plan/plan";
import clsx from 'clsx';
import {useStyles} from "./selectPlanContainer.css";
import {Typography} from "@material-ui/core";
import {useSelector} from "react-redux";
import {BASIC_PLAN_ID, CUSTOM_PLAN_ID, FREE_PLAN_ID} from "../../../../constants/planFields";


function SelectPlanContainer({plans, setSelectedPriceName, selectedPriceName, onSelectPlan, currentPlanDetails}) {
    const classes = useStyles();

    return (
        <div className={classes.plansContainer}>
            <Typography className={classes.currentPlanTitle} align={"center"} paragrap>
                You're currently on our <span className={classes.planName}>{currentPlanDetails.productName}</span> plan. Upgrade, downgrade or cancel at any time.
            </Typography>
            <div className={classes.plansOptions}>
                {plans.map((plan, index) =>
                    <div key={`divFor-${plan.baseProductId}`} className={clsx({[classes.regularPlan]: !plan.isPopular})}>
                        <Plan key={`planFor-${plan.baseProductId}`}
                              plan={plan}
                              onPriceChange={setSelectedPriceName}
                              isCurrent={currentPlanDetails.baseProductId === plan.baseProductId}
                              onPlanSelected={onSelectPlan}
                              selectedPriceName={selectedPriceName}
                        />
                    </div>
                )
                }
            </div>
        </div>
    )
}

export default SelectPlanContainer;