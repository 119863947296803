import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Card from '../card/card';
import '../card/card.scss';
import { groupTypes } from '../ipgroupModal/groupTypes';

class CardContainer extends React.Component {
	render() {
		const { groups, onDeleteGroup, onEditGroup } = this.props;
		const getGroupItemsValues = (objectItems, type) => objectItems.filter(item => item.type === type).map(item => item.value.value);

		return (
			<>
				{groups.map((group, index) => <Card index={index}
					key={group.objectGroup.objectId}
					cidrs={getGroupItemsValues(group.objectItems, groupTypes.CIDR) || []}
					macs={getGroupItemsValues(group.objectItems, groupTypes.MAC) || []}
					organizations={getGroupItemsValues(group.objectItems, groupTypes.ORGANIZATION) || []}
					awsInstanceIds={getGroupItemsValues(group.objectItems, groupTypes.AWS_INSTANCE_ID) || []}
					awsSubnetIds={getGroupItemsValues(group.objectItems, groupTypes.AWS_SUBNET_ID) || []}
					groupTitle={group.objectGroup.title}
					groupDescription={group.objectGroup.description}
					groupTags={group.objectGroup.tags || []}
					groupId={group.objectGroup.objectId}
					onEditGroup={() => onEditGroup(group)}
					onDeleteGroup={() => onDeleteGroup(group)}
					isEditable={group.objectGroup.isEditable}
					isDeletable={group.objectGroup.isDeletable}
				/>)}
			</>
		);
	}
}

CardContainer.propTypes = {
	groups: PropTypes.array,
	onDeleteGroup: PropTypes.func,
	onEditGroup: PropTypes.func,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
