import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px'
    },
    title: {
        fontSize: '15px',
        fontWeight: 'bold',
        lineHeight: '0.89',
        letterSpacing: '0.4px',
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
    }
});

export {useStyles};