import * as yup from 'yup';

/***
 * File for yup extension methods.
 * The methods can be used wherever wanted, without import them.
 */
const ipv4OrCidrRegex = /^(((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([1-9]|1[0-9]|2[0-8]|3[0-2])){0,1}){0,1}((\s*)(?=[^,])){0,1})+$/;

function ipv4OrCidr(message = 'Invalid IP address/CIDR') {
	return this.matches(ipv4OrCidrRegex, {
		message,
		excludeEmptyString: true,
	}).test('ip', message, value => value === undefined || value.trim() === ''
		? true
		: value.split('.').find(i => parseInt(i, 10) > 255) === undefined);
}

yup.addMethod(yup.string, 'ipv4OrCidr', ipv4OrCidr);
