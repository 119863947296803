import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from './CheckboxInput.css';
import { Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import analyzer from '../../../helpers/analyzer';
import { CHANGE_ACTION } from '../../../helpers/analyzerConstants';

const propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	formName: PropTypes.string.isRequired,
	optionalStyles: PropTypes.object,
};

const CheckboxInput = ({ name, label, formName, optionalStyles, analyzerCategory, onChange = () => {}, Icon, ...checkboxProps }) => {
	const classes = optionalStyles || useStyles();
	const dispatch = useDispatch();
	let formValue = false;
	const formState = useSelector(state => getFormValues(formName)(state));
	if (formState) {
		formValue = formState[name];
	}

	// A wrapper for the material UI checkbox component since the 'onChange' method gets overridden by redux form
	// eslint-disable-next-line react/prop-types
	const CheckboxWrapper = ({ onChangeCheckBox, checked, analyzerCategory, ...props }) => (
		<Checkbox color={'primary'}
			className={classes.root}
			checked={checked}
			onChange={event => {
				onChangeCheckBox(event);
				analyzerCategory && analyzer.event({ category: analyzerCategory, action: CHANGE_ACTION, label });
			}}
			icon={<span className={classes.icon} />}
			{...props}/>);

	return (
		<div className={classes.wrapper}>
			<Field name={name}
				onChangeCheckBox={event => {
					onChange(event);
					dispatch(change(formName, name, event.target.checked));
				}}
				analyzerCategory={ analyzerCategory }
				component={CheckboxWrapper}
				checked={formValue || false}
				{...checkboxProps} />
			{Icon && <Icon />}
			<label className={classes.label}>{label}</label>
		</div>
	);
};

CheckboxInput.propTypes = {
	analyzerCategory: PropTypes.string,
	formName: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	optionalStyles: PropTypes.object,
	Icon: PropTypes.node,
};

CheckboxInput.propTypes = propTypes;
export default CheckboxInput;
