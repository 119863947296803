import React from 'react';
import { Grid, MenuList, Popover, Typography } from '@material-ui/core';
import { useStyles } from './accountMenu.css';
import settingsIcon from '../../assets/accountMenu/settingsIcon.svg';
import premiumIcon from '../../assets/accountMenu/premiumIcon.svg';
import helpIcon from '../../assets/accountMenu/helpIcon.svg';
import referAFriend from '../../assets/accountMenu/referAFriend.svg';
import supportTicket from '../../assets/accountMenu/supportTicket.svg';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../app/controller/redux/auth/authActions';
import { setOpenReferAFriendFlow } from '../referAFriend/redux/referAFriendActions';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_PAGE_FEATURE_TOGGLE } from '../../constants/featureToggles';

const AccountMenu = ({ isOpen, onClose, anchorPosition, userName, accountName, isSubscriptionsVisible = false, history, onOpenSupportDialog }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const handleHelpClick = () => {
		history.push('/help');
	};

	const handleSettingsClick = () => {
		history.push('/settings');
	};

	const handleSubscriptionClick = () => {
		history.push('/subscription');
	};

	const handleReferAFriendClick = () => {
		dispatch(setOpenReferAFriendFlow(true));
	};

	return (
		<Popover open={isOpen}
			anchorPosition={anchorPosition}
			onClose={onClose}
			anchorReference={'anchorPosition'}
			classes={{ paper: classes.root }}>
			<Grid container>
				<Grid item xs={12} className={classes.header}>
					<Typography className={classes.userName}> {userName} </Typography>
					<Typography className={classes.accountName}> {accountName} </Typography>
				</Grid>
				<MenuList className={classes.options}>
					<MenuItem className={classes.option} onClick={handleSettingsClick}>
						<Grid item xs={2}> <img src={settingsIcon} className={classes.optionsIcon} alt="settings" /> </Grid>
						<Grid item xs={10}> <Typography className={classes.optionsText}> Settings </Typography> </Grid>
					</MenuItem>
					{SUBSCRIPTION_PAGE_FEATURE_TOGGLE && isSubscriptionsVisible && <MenuItem className={classes.option} onClick={handleSubscriptionClick}>
						<Grid item xs={2}> <img src={premiumIcon} className={classes.optionsIcon} alt="subscription" /> </Grid>
						<Grid item xs={10}> <Typography className={classes.optionsText}> Subscription </Typography> </Grid>
					</MenuItem>}
					<MenuItem container className={classes.option} onClick={handleReferAFriendClick}>
						<Grid item xs={2}> <img src={referAFriend} className={classes.optionsIcon} alt="refer a friend" /> </Grid>
						<Grid item xs={10}> <Typography className={classes.optionsText}> Refer a friend </Typography> </Grid>
					</MenuItem>
					<MenuItem container className={classes.option} onClick={onOpenSupportDialog}>
						<Grid item xs={2}> <img src={supportTicket} className={classes.supportIcon} alt="create support ticket" /> </Grid>
						<Grid item xs={10}> <Typography className={classes.optionsText}> Create support ticket </Typography> </Grid>
					</MenuItem>
					<MenuItem container className={classes.option} onClick={handleHelpClick}>
						<Grid item xs={2}> <img src={helpIcon} className={classes.optionsIcon} alt="help" /> </Grid>
						<Grid item xs={10}> <Typography className={classes.optionsText}> Help </Typography> </Grid>
					</MenuItem>
				</MenuList>
				<Grid item className={classes.logout} onClick={dispatch(logout)}>
                    Logout
				</Grid>
			</Grid>
		</Popover>
	);
};

AccountMenu.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.bool,
	anchorPosition: PropTypes.number,
	userName: PropTypes.string,
	accountName: PropTypes.string,
	isSubscriptionsVisible: PropTypes.bool,
	history: PropTypes.array,
	onOpenSupportDialog: PropTypes.func,
};

export default withRouter(AccountMenu);
