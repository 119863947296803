import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.secondary.main,
		color: 'white',
		boxShadow: '0 4px 5px 0 rgba(133, 133, 133, 0.55)',
	},
	summary: {
		fontSize: '18px',
		paddingLeft: '24px',
		paddingRight: '25px',
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		margin: '0',
		alignItems: 'center',
	},
	icon: {
		color: theme.palette.primary.main,
	},
	expandText: {
		color: theme.palette.primary.main,
		fontSize: '16px',
	},
}));

export { useStyles };
