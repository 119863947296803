import React, {useEffect} from 'react';
import usePolling from "../../customHooks/usePolling";
import Loader from "../Loader/Loader";
import {useDispatch, useSelector} from "react-redux";
import {setTitle} from "../../../containers/app/controller/redux/app/appActions";
import {getAccountPublicFields} from "../../../containers/app/controller/redux/account/accountActions";
import {setCurrentPlanDetails} from "../../../containers/plans/redux/plansActions";
import {
    getAccountInteractions,
    getUserInteractions
} from "../../../containers/app/controller/redux/dashboardInteractions/actions";


function CoreDataLoader({children, delay = 5000}) {
    const dispatch = useDispatch();

    const dataToLoad = async () => {
        await dispatch(getAccountPublicFields());
        await dispatch(setCurrentPlanDetails());
        dispatch(getAccountInteractions());
        dispatch(getUserInteractions());
    }

    const stopPolling = () => {
        const isAccountInit = useSelector(state => state.account.isInitialized);
        const isPlanInit = useSelector(state => state.plans.isInitialized);
        return isAccountInit && isPlanInit;
    }

    const {isLoading, error, data} = usePolling(dataToLoad, delay, stopPolling, true, 900000);

    useEffect(() => {
        dispatch(setTitle(""));
    }, [])


    return (
        <React.Fragment>
            {isLoading && <Loader/>}
            {(error || data) && children}
        </React.Fragment>
    )
}

export default CoreDataLoader;