import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "0 6px"
    },
    title: {
        fontWeight: "bold",
        color: "black"
    },
    description: {
        padding: "0 10px",
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
        color: theme.palette.dialogDescription.main
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '420px',
        height: '520px'
    },
    actions: {
        justifyContent: "center",
        marginBottom: "10px"
    },
    save: {
        textAlign: "center",
        width: "150px",
        height: "40px",
        borderRadius: "5px",
        fontWeight: "bold",
        fontSize: "16px"
    },
    sectionTitle: {
        padding: "0 10px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "black",
        marginTop: "5px"
    },
    divider: {
        margin: "0 10px"
    }
}));

export {useStyles}
