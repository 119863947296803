import {Dialog, Grid, Paper, Typography} from "@material-ui/core";
import ExitButton from "../../../shared/components/ExitButton/ExitButton";
import headerImg from '../../../assets/notifications/referAFriendPrimary.png';
import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {useStyles} from "./ReferAFriendPrompt.css";

const TITLE = "Refer friends and earn more events";
const DESCRIPTION = `For every friend you refer, you each get 15 more events.
    Events allow you to view in analysis and receive additional \n information over more detections.`
const BUTTON = "Refer a friend";

const ReferAFriendPrompt = ({isOpen, onClose, callback}) => {
    const classes = useStyles({headerImg});

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={"md"}>
            <Box height={"570px"} width={"823px"}>
                <ExitButton onClick={onClose}/>
                <Paper className={classes.box} elevation={0}>
                    <div className={classes.headerContainer}>
                        <div className={classes.headerImg} />
                    </div>

                    <div className={classes.boxItem}>
                        <Typography className={classes.header} gutterBottom align={"center"} variant={"h1"}>{TITLE}</Typography>
                        <Typography className={classes.description} align={"center"} variant={"body2"} color={"textSecondary"}>{DESCRIPTION}</Typography>
                    </div>
                    <Button className={classes.button} onClick={callback}>{BUTTON}</Button>
                </Paper>
            </Box>
        </Dialog>
    )
}


export default ReferAFriendPrompt;