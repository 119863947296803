import React, {useEffect} from "react";
import {withRouter} from 'react-router-dom';
import ReactGA from "react-ga";

const RouterEventsSubscriber = ({location:{pathname}}) => {
    useEffect(() => {
            ReactGA.pageview(pathname);
    }, [pathname])

    return(<></>)
}

export default withRouter(props => <RouterEventsSubscriber {...props}/>);