import React from 'react';
import { Button, Typography } from '@material-ui/core';
import useStyles from './noTokensDisplay.css';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setPlansFlowActivation } from '../../../plans/redux/plansActions';
import PremiumDisplay from '../../../../assets/notifications/blue-all-good.svg';

const NoTokensDisplay = ({ feature }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const onUpgradeClick = () => {
		dispatch(setPlansFlowActivation(true, false, feature));
	};

	return (
		<div className={classes.container}>
			<div className={classes.content}>
				<img src={PremiumDisplay} alt="Premium icon" className={classes.premiumImage}/>
				<Typography className={classes.headline}>
					You discovered a premium feature!
				</Typography>
				<Typography className={classes.text}>
					You have reached the Event’s limit of your plan.
				</Typography>
				<Typography className={classes.text}>
					To receive additional information over more detections, upgrade your plan.
				</Typography>
				<Button feature={'vca'} className={classes.upgradeButton} onClick={onUpgradeClick}>See upgrade options</Button>
			</div>
		</div>
	);
};

NoTokensDisplay.propTypes = {
	feature: PropTypes.string.isRequired,
};

export default NoTokensDisplay;
