const INCLUDE_INDICATOR = 'i';
const EXCLUDE_INDICACTOR = 'o';
/***
 * The function createFilters creates dynamically include:{} and exclude:{} of every property inside the given filters.
 * input:
 * filters -> one or more properties, with reactSelect values inside.
 *
 * output:
 * filters = {
 *   [filterProperty1] : {
 *       include: {...}
 *       exclude: {...}
 *   }
***/
const createFilters = (filters = []) => {
	const parsedFilters = {};

	Object.keys(filters).forEach(filterField => {
		parsedFilters[filterField] = parseFilter(filters[filterField]);
	});
	return parsedFilters;
};

/***
 * The function parseFilter creates an object with include & exclude properties. Created by the reactSelect direction property (direction = i/o)
 * input:
 * filters -> reactCreatable (works both to selectCreatable & simpleCreatableSelect) values.
 *
 * output:
 * filters = {
 *       include: {...}
 *       exclude: {...}
 *      }
 */
const parseFilter = (filter = []) => {
	const include = [];
	const exclude = [];

	filter.forEach(({ value, direction }) => {
		if (direction === INCLUDE_INDICATOR) {
			include.push(value);
		}

		if (direction === EXCLUDE_INDICACTOR) {
			exclude.push(value);
		}
	});
	return {
		...(include.length > 0 && { include }),
		...(exclude.length > 0 && { exclude }),
	};
};

export {
	createFilters,
	parseFilter,
};
