import action from "../middleware";
import http from "../../../../../services/httpService/http";
import {SET_ERROR} from "../message/messageActions";
import {ACCOUNTS_URI} from "../../services/urls";


export const REQ_ACCOUNTS = "REQ_ACCOUNTS";
const getAccounts = () => {
    return action(async dispatch => {
        try {
            let accountsDic = await http.get(ACCOUNTS_URI);
            let accounts = Object.keys(accountsDic).map((id, index) => {
                return ({_id: id, ...accountsDic[id]})
            });
            dispatch({
                type: REQ_ACCOUNTS,
                payload: accounts,
            });
        } catch (e) {
            dispatch({
                type: SET_ERROR,
                payload: e.message || 'Accounts load failed.'
            });
        }
    });
};
export {
    getAccounts,
}
