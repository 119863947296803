import React from 'react';
import './process.scss'
import { connect } from "react-redux";
import { removeCurrentBackgroundProcess } from '../../../controller/redux/backgroundProcessAction'
import AddIcon from 'mdi-react/AddIcon'
import StyleButton from '../../../../../shared/components/form/Buttons/StyleButton'
import { compose } from 'redux';
import { withRouter } from 'react-router';
import {expandForm} from "../../../controller/redux/analysisFormActions";

export class Process extends React.Component {

    constructor(props) {
        super(props)

        this.handleClick = this.handleClick.bind(this);
    }


    handleClick = (event, value) => {
        this.props.expandForm();
        this.props.removeCurrentBackgroundProcess()
        this.props.history.push('/analysis')

    }

    render() {
        return (
            <>
                <StyleButton
                    onClick={ this.handleClick }
                    label=''
                    icon={ <AddIcon /> }
                    className={ `vv-analyze-button add-button background-process-button` }
                    style={{width:'20px'}}
                />
            </>
        );
    }

}


const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = {
    removeCurrentBackgroundProcess,
    expandForm
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Process); 
