import React, {useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import BoundedFormWithTitles from "../../../../contactSales/boundedFormWithTitles";
import {sendFeedbackToSales} from "../../../../services/planRequests";
import {
    FEEDBACK_FORM_SUBTITLE,
    FEEDBACK_FORM_TITLE,
    attachCallbackToSuccessComponent,
    attachCallbackToErrorComponent,
    FEEDBACK_OPTIONS,
    FEEDBACK_FORM_INITIAL_VALUES, ORDER_SUMMARY_TITLE
} from "./downgradeFromBasicPlanConfiguration";
import FeedbackForm from "../../../../contactSales/forms/feedbackForm";
import DowngradeOrderSummary from "../../../../orderSummary/downgradeOrderSummary/downgradeOrderSummary";
import {createSubscription} from "../../../../../paymentMethod/requests/requests";
import {useStyles} from "./downgradeFromBasicPlan.css";

const UpgradeToCustomPlan = ({onError, onSuccess, onCloseDialog, flowType, selectedBaseProduct, currentBaseProduct, selectedPriceName}) => {
    const [isFeedbackSent, setIsFeedbackSent] = useState(false);
    const classes = useStyles();
    const onFeedbackFormSubmit = async (values) => {
        try {
            const feedbackSelectedLinesIndexes = Object.keys(values).filter(optionIndex => { return values[optionIndex] && !isNaN(optionIndex)})
            const feedbackSelectedLinesValues = feedbackSelectedLinesIndexes.map( feedbackLineIndex => FEEDBACK_OPTIONS[feedbackLineIndex])

            // Push if there is more info
            values.moreInfo && feedbackSelectedLinesValues.push(values.moreInfo)

            const feedbackRequest = {
                flow:flowType,
                data: {reasonsForDowngrading: feedbackSelectedLinesValues}
            };

            console.log(feedbackRequest)
            await sendFeedbackToSales(feedbackRequest)
        } finally {
            setIsFeedbackSent(true)
        }
    }

    const onChangePlan = async () => {
        const newPlanData = {
            targetBaseProductId: selectedBaseProduct.baseProductId,
            priceKey: selectedPriceName.toUpperCase(),
        };

        try{
            await createSubscription(newPlanData);
            onSuccess(attachCallbackToSuccessComponent(onCloseDialog))
        }catch(e){
            onError(attachCallbackToErrorComponent(onCloseDialog))
        }

    }


    return (
        <Grid container justify={"space-evenly"} alignContent={"center"} direction={"column"} style={{height: '90%'}}>
            {isFeedbackSent ?
                <div className={classes.orderSummaryContainer}>
                    <Typography paragraph className={classes.orderSummaryTitle} align={"center"} variant={"h6"} color={"textSecondary"}>{ORDER_SUMMARY_TITLE}</Typography>
                    <DowngradeOrderSummary selectedPlan={selectedBaseProduct} currentPlan={currentBaseProduct} selectedPriceName={selectedPriceName} onCancel={onCloseDialog} onSubmit={onChangePlan} />
                </div>
                    :
                <BoundedFormWithTitles title={FEEDBACK_FORM_SUBTITLE} subtitle={FEEDBACK_FORM_TITLE} displayAdditionalQuestions>
                    <FeedbackForm onSubmit={onFeedbackFormSubmit} initialValues={FEEDBACK_FORM_INITIAL_VALUES} />
            </BoundedFormWithTitles>}

        </Grid>
    )
}

export default UpgradeToCustomPlan;