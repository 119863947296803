import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	icon: {
		marginLeft: '6px',
		fontSize: '18px',
	},
}));

export default useStyles;
