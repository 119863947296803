import { Button, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import React from 'react';
import setNotificationsImage from '../../../../../assets/walkthrough/overview/steps/setNotifications.png';
import { useStyles } from './setNotifications.css';
import SetNotificationsForm from './setNotificationsForm/setNotificationsForm';

const SetNotifications = ({ finishCallback, handleClose }) => {
	const classes = useStyles();

	return (
		<>
			<DialogActions>
				<Button onClick={handleClose} className={classes.close} >
					<CloseIcon/>
				</Button>
			</DialogActions>
			<div className={classes.root}>
				<img src={setNotificationsImage} className={classes.image}/>
				<div className={classes.text}>
					<Typography className={classes.title}>
						Now let's set alerts notification settings
					</Typography>
					<Typography className={classes.description}>
						Add your email
					</Typography>
				</div>
				<SetNotificationsForm onSubmit={finishCallback}/>
			</div>
		</>

	);
};

SetNotifications.propTypes = {
	finishCallback: PropTypes.func,
	handleClose: PropTypes.func,
};

export default SetNotifications;
