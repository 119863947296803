import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { TOOLTIP_DEFAULT_PROPS } from './tooltipConfig';

const CustomTooltip = props => (
	<Tooltip {...TOOLTIP_DEFAULT_PROPS} {...props}>
		{props.children}
	</Tooltip>
);

CustomTooltip.propTypes = {
	children: PropTypes.node.isRequired,
};

export default CustomTooltip;
