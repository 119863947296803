import http from '../../../../services/httpService/http';
import {
	lightWeightPublicCloudDevicePath,
	publicCloudDeviceConfigPath,
	publicCloudDevicePath,
	publicCloudDeviceVisibleCountPath,
} from './urls';

const getPublicCloudDevices = () => http.get(publicCloudDevicePath);

const addPublicCloudDevice = newPublicCloudDevice => http.post(publicCloudDevicePath, newPublicCloudDevice);

const getPublicCloudDevice = deviceId => http.get(`${publicCloudDevicePath}${deviceId}`);

const removePublicCloudDevice = deviceId => http._delete(`${publicCloudDevicePath}${deviceId}`);

const updatePublicCloudDevice = (deviceId, updatedParams) => http.put(`${publicCloudDevicePath}${deviceId}`, updatedParams);

const getPublicCloudDeviceVisibleCount = () => http.get(publicCloudDeviceVisibleCountPath);

const getLightweightPublicCloudDevices = () => http.get(lightWeightPublicCloudDevicePath);

const getPublicCloudDeviceConfig = deviceId => http.get(publicCloudDeviceConfigPath(deviceId));

export {
	getPublicCloudDevices,
	addPublicCloudDevice,
	updatePublicCloudDevice,
	getPublicCloudDevice,
	removePublicCloudDevice,
	getPublicCloudDeviceVisibleCount,
	getLightweightPublicCloudDevices,
	getPublicCloudDeviceConfig,
};
