import { Grid, Tab, Tabs } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import TabPanel from '../../../../shared/components/tabPanel/tabPanel';
import AnalysisGraph from '../../analysisGraph/AnalysisGraph';
import useStyles from './Details.css';
import {
	DETECTION_TYPES,
	findingsComponentMapping,
	rawdataComponentMapping,
} from '../../../../shared/components/detectionsTable/detectionsTableConfig';
import { Skeleton } from '@material-ui/lab';
import CustomTooltip from '../../../../shared/components/Tooltip/CustomTooltip';

const TABS_ARIA_LABEL = 'event details tabs';
const FINDINGS_LABEL = 'Findings';
const ANALYSIS_LABEL = 'Analysis';
const RAW_DATA_LABEL = 'Raw data';
let FindingsTab = findingsComponentMapping[DETECTION_TYPES.VCA];
let RawdataTab = rawdataComponentMapping[DETECTION_TYPES.VCA];
const THIRTY_DAYS_IN_MS = 30 * 24 * 60 * 60 * 1000;

const Details = ({ history, eventData, analysisResult, analysisError, isAnalysisLoading, isLoading }) => {
	const classes = useStyles();
	const getTooltipContent = text => <p className={classes.tooltipContent}>{text}</p>;
	const [isDetectionOver30Days, setIsDetectionOver30Days] = useState(false);

	useEffect(() => {
		setTabNumber(0);
	}, [eventData]);

	useEffect(() => {
		if (eventData) {
			FindingsTab = findingsComponentMapping[eventData.detectionType];
			RawdataTab = rawdataComponentMapping[eventData.detectionType];
			setIsDetectionOver30Days(isDetectionOlderThan30Days());
		}
	}, [eventData]);

	const [tabNumber, setTabNumber] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTabNumber(newValue);
	};

	const tabPanelWithContent = useCallback(content => {
		if (isLoading) {
			return <Skeleton variant="rounded" width="100%" height="18em" animation="wave"/>;
		}

		return content;
	}, [isLoading]);

	const isDetectionOlderThan30Days = () => {
		const todaysDateInMillis = new Date();
		const detectionDate = new Date(eventData.firstDetectionTime);
		const timeDiff = todaysDateInMillis.getTime() - detectionDate.getTime();
		return timeDiff < THIRTY_DAYS_IN_MS;
	};

	return (
		<Grid container>
			<Grid item xs={12} className={classes.tabsContainer}>
				<Tabs
					TabIndicatorProps={{ className: classes.tabIndicator }}
					value={tabNumber}
					onChange={handleTabChange}
					textColor={'primary'}
					aria-label={TABS_ARIA_LABEL}>
					<Tab className={classes.tab} label={FINDINGS_LABEL} />
					<Tab
						style={{ pointerEvents: 'auto' }}
						disabled={eventData && !isDetectionOver30Days}
						className={`${classes.tab} ${eventData && isDetectionOver30Days && classes.hoveredTab}`}
						label={
							<CustomTooltip title={getTooltipContent('Analysis not available for detections older than 30 days')} disableHoverListener={eventData && !isLoading && isDetectionOver30Days}>
								<span>{ANALYSIS_LABEL}</span>
							</CustomTooltip>
						}
					/>
					<Tab
						style={{ pointerEvents: 'auto' }}
						disabled={eventData && !(eventData.rawdata && eventData.rawdata.length > 0)}
						className={`${classes.tab} ${eventData && (eventData.rawdata && eventData.rawdata.length > 0) && classes.hoveredTab}`}
						label={
							<CustomTooltip title={getTooltipContent('Raw data not available')} disableHoverListener={eventData && !isLoading && (eventData.rawdata && eventData.rawdata.length > 0)}>
								<span>{RAW_DATA_LABEL}</span>
							</CustomTooltip>
						}
					/>
				</Tabs>
			</Grid>
			<Grid item xs={12}>
				<TabPanel index={0} value={tabNumber} className={classes.tabContent}>
					{
						tabPanelWithContent(<FindingsTab history={history} eventData={eventData}/>)
					}
				</TabPanel>
				<TabPanel index={1} value={tabNumber} className={classes.tabContent}>
					{
						tabPanelWithContent(<AnalysisGraph eventData={eventData} result={analysisResult} error={analysisError} isLoading={isAnalysisLoading} />)
					}
				</TabPanel>
				<TabPanel index={2} value={tabNumber} className={classes.tabContent}>
					{
						tabPanelWithContent(<RawdataTab eventData={eventData}/>)
					}
				</TabPanel>
			</Grid>
		</Grid>
	);
};

Details.propTypes = {
	eventData: PropTypes.object,
	analysisResult: PropTypes.object,
	analysisError: PropTypes.object,
	isAnalysisLoading: PropTypes.bool,
	isLoading: PropTypes.bool,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export default Details;
