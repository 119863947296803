import { Field } from 'redux-form';
import TextField from '../form/TextField/TextField';
import DatePicker from '../datePicker/datePicker';
import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

const FieldByType = ({ readOnly, index, value, prop, onChange, field, isDisabled }) => {
	if (!field) {
		return null;
	}

	switch (field.type) {
		case 'text':
			return (
				<Field
					key={index}
					type="text"
					className="form-field"
					label={`${field.alias}`}
					name={`${field.accessor}`}
					onChange={e => onChange({ prop, value: e })}
					readOnly={readOnly}
					disabled={isDisabled}
					component={TextField}
				/>
			);
		case 'date':
			return (
				<DatePicker
					value={value}
					onChange={e => onChange(prop, moment(e).format('DD.MM.YYYY'))}
					dateFormat="DD.MM.YYYY"
					timeFormat={false}
					inputProps={{ className: 'rdt-input' }}
				/>
			);
		default:
			return null;
	}
};

const fieldType = {
	accessor: PropTypes.string,
	alias: PropTypes.string,
	type: PropTypes.string,
	className: PropTypes.string,
	headerClassName: PropTypes.string,
	newEntryField: PropTypes.bool,
	editField: PropTypes.bool,
	allowView: PropTypes.bool,
	allowEdit: PropTypes.bool,
	show: PropTypes.bool,
	filterable: PropTypes.bool,
};

FieldByType.propTypes = {
	readOnly: PropTypes.bool,
	index: PropTypes.number,
	value: PropTypes.string,
	prop: PropTypes.string,
	onChange: PropTypes.func,
	field: fieldType,
	isDisabled: PropTypes.bool,
};

export {
	FieldByType,
};
