import React from "react";
import step1Side from "../../../../../../../assets/walkthrough/analysis/steps/step1Side.png";
import step3Main from "../../../../../../../assets/walkthrough/analysis/steps/step3Main.png";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step3.css";
import MainPanelTemplateV2
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const TITLE = "Query type";
const DESCRIPTION = "Select a query to run from a wide range of built-in options such as number of packets, number of new flows and more."

const Step3 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV2 title={TITLE}
                                 image={<img src={step3Main} className={classes.image}/>}
                                 description={DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step3;