import { CircularProgress, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { format, numericality, required, validateForm } from 'redux-form-validators';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../shared/components/form/TextField/TextField';
import { FormWrapper } from '../../../../shared/components/simpleTable/simpleFormContainer';
import DeviceSubForm from '../deviceConfig/deviceForm';

import useStyles from './collectorDeviceForm.css';
import CollectorsSubForm from './collectorsSubForm/CollectorsSubForm';

const CollectorDeviceForm = ({
	initialValues,
	onSubmit,
	handleSubmit,
	change,
	setConfigType,
	deviceReferences,
	collectors = [],
	samplers = [],
	isSubmittingForm,
	configType,
	isEditMode,
	form,
}) => {
	const formData = useSelector(state => getFormValues(form)(state));
	const selectedCollector = (initialValues || formData).collectorId || '';
	const selectedSampler = (initialValues || formData).samplerId || '';

	const classes = useStyles();

	const deviceSubFormInitialValues = isEditMode && configType ? { ...{ initialValues }, brand: configType.brand, model: configType.value } : initialValues;

	return <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
		<FormWrapper title="Device properties" wrapperStyle={{}} childrenStyle={classes.childrenStyle}>
			<Field
				component={TextField}
				label="Device name"
				className="form-field"
				required
				name="name"
				type="text"
				margin="none"
			/>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Field
						component={TextField}
						required
						label="Device private IP"
						className="form-field"
						name="IP"
						type="text"
						margin="none"
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						component={TextField}
						required
						label="Sampling rate"
						className="form-field"
						name="samplingRate"
						type="number"
						margin="none"
					/>
				</Grid>
			</Grid>
			<DeviceSubForm
				mode={isEditMode ? 'edit' : 'add'}
				initialValues={deviceSubFormInitialValues}
				deviceReferences={deviceReferences}
				onChangeConfig={e => {
					Object.keys(e).map((a, i) => change(a, Object.values(e)[i]));
				}}
				onDisapear={() => {}}
				setConfigType={setConfigType}
				change={change}
				form={form}
				isEditMode={isEditMode}
				isSamplerSupported
			/>
			<CollectorsSubForm
				collectors={collectors}
				samplers={samplers}
				selectedCollector={selectedCollector}
				selectedSampler={selectedSampler}
				isEditMode={Boolean(isEditMode)}
				change={change}
				form={form}
			/>
			<Field component={TextField} required name="status" type="hidden" hidden />
		</FormWrapper>
		<div style={{ marginBottom: '30px' }}>
			{isSubmittingForm ? (
				<CircularProgress color="primary" />
			) : (
				<SubmitButton label={isEditMode ? 'Save' : 'Add switch'} />
			)}
		</div>
	</form>;
};

CollectorDeviceForm.propTypes = {
	change: PropTypes.func,
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	configType: PropTypes.shape({
		brand: PropTypes.any,
		value: PropTypes.any,
	}),
	deviceReferences: PropTypes.any,
	handleSubmit: PropTypes.func,
	initialValues: PropTypes.object,
	isEditMode: PropTypes.bool,
	isSubmittingForm: PropTypes.bool,
	modalOpen: PropTypes.bool,
	onSubmit: PropTypes.func,
	reset: PropTypes.func,
	setConfigType: PropTypes.func,
	form: PropTypes.string,
};

// Redux Form
const ipValidator = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
const validate = validateForm({
	IP: [required({ msg: 'Please enter IP' }), format({ with: ipValidator, msg: 'Please enter a valid IP' })],
	collector: [required({ msg: 'Please enter select the Collector' })],
	name: [required({ msg: 'Please enter device name' })],
	type: [required({ msg: 'Please enter device Type' })],
	samplingRate: [required({ msg: 'Please enter a Sampling Rate' }), numericality({ '>': 0 })],
});

export default reduxForm({
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(CollectorDeviceForm);
