import { Typography } from '@mui/material';
import EndpointsTable from '../../endpointsTable/endpointsTable';
import { LoadingTableBody } from '../../endpointsTable/TableSkeleton';
import { FullTableBody, EmptyTableBody } from './TableBody';
import { useStyles } from '../InactiveEndpoints.css';
import React from 'react';
import PropTypes from 'prop-types';

const IpsTable = ({ tableType, columns, rows, isLoading, ipsNumber = 0 }) => {
	const emptyTableMessage = !isLoading && rows.length === 0
		? <EmptyTableBody tableType={tableType}/> : null;
	const createTableName = () => tableType + ' (' + (ipsNumber) + ')';

	const classes = useStyles();
	return (
		<div className={classes.tableWrapper}>
			<Typography className={classes.tableTitle}>
				{createTableName()}
			</Typography>
			<div className={classes.table}>
				<EndpointsTable columns={columns} emptyTableMessage={emptyTableMessage}>
					{
						isLoading ? <LoadingTableBody columns={columns} rowLength={5}/>
							: <FullTableBody columns={columns} rows={rows}/>
					}
				</EndpointsTable>
			</div>
		</div>
	);
};

IpsTable.propTypes = {
	tableType: PropTypes.string,
	columns: PropTypes.array,
	rows: PropTypes.array,
	ipsNumber: PropTypes.number,
	isLoading: PropTypes.bool,
};
IpsTable.defaultValue = {
	ipsNumber: 0,
};

export default IpsTable;
