import { WidgetContainer } from '../../../index';
import React, { useEffect, useState } from 'react';
import { INACTIVE_ENDPOINTS_WIDGET_TITLE } from '../deviceMonitoringTexts';
import NewAndInactiveTables from './helpers/NewAndInactiveTables';
import PropTypes from 'prop-types';
import { mergeDeviceNameByMapping } from '../../utils/DeviceMonitoringUtils';

const InactiveEndpoints = ({ newIps, newIpsNumber, inactiveIps, inactiveIpsNumber, isLoading, devicesIdToDeviceNameMapping }) => {
	const [newIpsWithDeviceName, setNewIpsWithDeviceName] = useState([]);
	const [inactiveIpsWithDeviceName, setInactiveIpsWithDeviceName] = useState([]);

	useEffect(() => {
		if (!isLoading) {
			const newIpsWithDeviceName = mergeDeviceNameByMapping(newIps, devicesIdToDeviceNameMapping);
			const inactiveIpsWithDeviceName = mergeDeviceNameByMapping(inactiveIps, devicesIdToDeviceNameMapping);
			setNewIpsWithDeviceName(newIpsWithDeviceName);
			setInactiveIpsWithDeviceName(inactiveIpsWithDeviceName);
		}
	}, [isLoading]);

	return (
		<WidgetContainer title={INACTIVE_ENDPOINTS_WIDGET_TITLE}>
			<NewAndInactiveTables
				newIps={newIpsWithDeviceName}
				newIpsNumber={newIpsNumber}
				inactiveIps={inactiveIpsWithDeviceName}
				inactiveIpsNumber={inactiveIpsNumber}
				isLoading={isLoading}
			/>
		</WidgetContainer>
	);
};

InactiveEndpoints.propTypes = {
	newIps: PropTypes.array,
	newIpsNumber: PropTypes.number,
	inactiveIps: PropTypes.array,
	inactiveIpsNumber: PropTypes.number,
	isLoading: PropTypes.bool,
	devicesIdToDeviceNameMapping: PropTypes.object,
};
InactiveEndpoints.defaultValue = {
	newIps: [],
	newIpsNumber: 0,
	inactiveIps: [],
	inactiveIpsNumber: 0,
	devicesIdToDeviceNameMapping: {},
};

export default InactiveEndpoints;
