import { REQ_CHARGE , REQ_CHARGE_ERROR} from "./paymentActions";

const initialState = {
    payment: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case REQ_CHARGE: 
        return{
           // ... state, 
            payment: payload.paymentResult
        }

        case REQ_CHARGE_ERROR: 
        return{
            ... state, 
            payment: payload.payment
        }
        default:
            return state;
    }
}