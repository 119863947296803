import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import _ from 'lodash';

import 'react-table/react-table.css';
import './simpleTable.scss';

export default class SimpleTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			columns: props.columns || [],
			data: props.data || [],
			pages: props.pages || null,
			searchFields: props.searchFields || [],
			totalItemsInPage: props.totalItemsInPage || 50,
			isFilterable: props.isFilterable || false,
			isResizable: props.isResizable || false,
			activeTab: props.activeTab || '1',
			filtered: [],
		};

		this.toggleColumnSelector = this.toggleColumnSelector.bind(this);
		this.toggleFilterSelector = this.toggleFilterSelector.bind(this);
		this.onFilteredChange = this.onFilteredChange.bind(this);
	}

	toggleColumnSelector = (selected, removed) => {
		// Get from state only selected columns
		let columns = this.state.columns.map(col =>
			selected && selected.some(s => s.value === col.accessor)
				? {
					...col,
					show: true,
				}
				: { ...col, show: false },
		);
		columns = columns.filter(col => col !== null);
		this.resetFilter(removed, this.state.filtered);
		this.setState({ columns });
	};

	// Selected is parameter from the filterSelector component
	toggleFilterSelector = (selected, isSelected) => {
		const newColumns = this.state.columns;
		// Show the filter for selected column
		newColumns.forEach(c => {
			if (selected && selected.accessor === c.accessor) {
				c.filterable = isSelected;
			}
		});
		// We create new array cause the table filtering does not change.
		const columns = [];
		for (const record of newColumns) {
			const newRecord = { ...record };
			columns.push(newRecord);
		}

		// If we remove filter we want to remove that active filtering
		this.setState({ columns });
		// Reset filter
		if (!isSelected) {
			const removed = {
				value: selected.accessor,
			};
			this.resetFilter(removed, this.state.filtered);
			this.setState(); // Hide the filter
		}
	};

	// After deselecting column we must reset the values of the filter
	resetFilter = (removed, currentFiltered) => {
		if (!removed) {
			return;
		}

		// Id of the filtered array object & value of the removed paremeter
		// is key/accessor
		const filtered = [...currentFiltered];
		const removedIndex = currentFiltered.findIndex(f => f.id === removed.value);
		if (removedIndex !== -1) {
			filtered.splice(removedIndex, 1);
			// Remove filter if we remove the column from column selector
			filtered.forEach((item, index) => {
				if (index === removedIndex) {
					item.filtered = false;
				}
			});
		}

		this.setState({ filtered });
	};

	/// on filter change event
	onFilteredChange = filtered => {
		this.setState({ filtered });
	};

	// Filter table data by text for columns defined in tableConfig under field name searchFields
	filterCustomColumns = state => {
		const { data } = state;
		let filterData = data;
		const searchValue = state.search;
		if (searchValue) {
			filterData = data.filter(row =>
				state.searchFields.some(field => (
					String(row[field])
                        && String(row[field].toLowerCase()).includes(searchValue.toLowerCase())
				)),
			);
		}

		return filterData;
	};

	render() {
		const { columns, pages, filtered, isFilterable, isResizable, totalItemsInPage } = this.state;
		const { data, className } = this.props;

		return (
			<div className="smart-table-wrapper">
				{
					_.isEmpty(data) ? <div></div> : <ReactTable
						// eslint-disable-next-line no-return-assign
						ref={ref => (this.table = ref)}
						resizable={isResizable}
						data={data}
						pages={pages} // Display the total number of pages
						loading={false} // Display the loading overlay when we need it
						defaultPageSize={totalItemsInPage}
						minRows={0}
						filterable={isFilterable}
						columns={columns}
						className={`-highlight ${className}`}
						manual={false}
						onFetchData={() => { }}
						// If we have manual=false fetchData method still works
						filtered={filtered}
						onFilteredChange={this.onFilteredChange}
						nextText=">"
						previousText="<"
						pageText=""
						NoDataComponent={() => null}
					/>
				}
			</div>
		);
	}
}

SimpleTable.propTypes = {
	activeTab: PropTypes.string,
	className: PropTypes.string,
	columns: PropTypes.array,
	data: PropTypes.array,
	isFilterable: PropTypes.bool,
	isResizable: PropTypes.bool,
	pages: PropTypes.any,
	searchFields: PropTypes.array,
	totalItemsInPage: PropTypes.number,
};
