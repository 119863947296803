import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Field, reduxForm } from "redux-form";

import SubmitButton from "../../../../../../shared/components/form/Buttons/SubmitButton";
import DirectionSelectCreateable from "../../../../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable";

import { useStyles } from "./endpointInventoryForm.css";

const PROP_TYPES = {
    onSubmit: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userEmail: PropTypes.string.isRequired,
    valid: PropTypes.bool,
    modalOpen: PropTypes.bool,
    isLoading: PropTypes.bool,
    maxReportsPerAccount: PropTypes.number,
};

const INITIAL_VALUES = {
    endpointDetailsReport: { isSelected: true },
    vendorOriginSummaryReport: { isSelected: true },
    vendorSummaryReport: { isSelected: true },
};

const EndpointInventoryForm = ({ onSubmit, handleSubmit, userEmail, isLoading, maxReportsPerAccount, vendorOptions, originOptions }) => {
    const renderForm = () => {
        const classes = useStyles();

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-edit-body-public-cloud">
                    <Grid container>
                        <Grid item xs={12} className={classes.formTitle}>
                            Endpoint Inventory
                        </Grid>
                        <Grid item xs={12} className={classes.subTitle}>
                            Cynamics autonomously discovers your endpoints. <br />
                            The report includes both summarized reports of vendors and their origins, and a detailed report of endpoint
                            details.
                            <br />
                            <br />
                            *The Endpoints Inventory report is available to supported devices only
                        </Grid>
                        <Grid item xs={12} className={classes.limitationReportsText}>
                            <text className={classes.emailText}>
                                {" "}
                                You can generate up to {maxReportsPerAccount} reports in 30 days, per account
                            </text>
                        </Grid>
                        <Grid spacing={1} container className="modal-auto-onboarding-form-section">
                            <Grid item xs={1}>
                                <Field
                                    label="From"
                                    className="form-field"
                                    name="vendorSummaryReport.isSelected"
                                    component="input"
                                    type="checkbox"
                                    value="true"
                                />
                            </Grid>
                            <Grid item xs={11} className={classes.formLabel}>
                                Summarized vendors report
                            </Grid>
                            <Grid item xs={1}>
                                <Field
                                    label="To"
                                    className="form-field"
                                    name="vendorOriginSummaryReport.isSelected"
                                    component="input"
                                    type="checkbox"
                                    value="true"
                                />
                            </Grid>
                            <Grid item xs={10} className={classes.formLabel}>
                                Summarized vendor origins report
                            </Grid>

                            {vendorOptions && vendorOptions.length !== 0 && (
                                <>
                                    <Grid item xs={10} className={classes.filterLabel}>
                                        Vendor
                                    </Grid>
                                    <Grid item xs={12} className={classes.formLabel}>
                                        <Field
                                            name="endpointDetailsReport.filters.vendor"
                                            component={DirectionSelectCreateable}
                                            options={vendorOptions}
                                            placeholder={"Vendor filters"}
                                        />
                                    </Grid>
                                </>
                            )}

                            {originOptions && originOptions.length !== 0 && (
                                <>
                                    <Grid item xs={10} className={classes.filterLabel}>
                                        Origin
                                    </Grid>
                                    <Grid item xs={12} className={classes.formLabel}>
                                        <Field
                                            name="endpointDetailsReport.filters.origin"
                                            component={DirectionSelectCreateable}
                                            options={originOptions}
                                            placeholder={"Origin filters"}
                                        />
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={12} className={classes.outputFileText}>
                                The Endpoint Inventory report will be sent shortly to:{" "}
                                <text className={classes.emailText}> {userEmail} </text>
                            </Grid>

                            <Grid item xs={12}>
                                {isLoading ? (
                                    <CircularProgress
                                        style={{ color: "#00b8ec", display: "block", marginLeft: "auto", marginRight: "auto" }}
                                    />
                                ) : (
                                    <SubmitButton label={"Generate"} className="modal-auto-onboarding-find-button" />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </form>
        );
    };

    return renderForm();
};

EndpointInventoryForm.propTypes = PROP_TYPES;
export default reduxForm({
    enableReinitialize: true,
    initialValues: INITIAL_VALUES,
    form: "endpointInventoryForm",
})(EndpointInventoryForm);
