import countries from "../../../shared/datasets/countries";

const FORMAT = '{line1}, {city}{state}, {postal_code}, {country}';

const getCountryNameByCode = (countryCode) => {
    const country = countries.find((country) => country.code === countryCode);
    if (country) {
        return country.name;
    }
    return "";
}

const getAddressString = (billingDetails, format = FORMAT) => {
    const {line1 = "", city = "", state = "", postal_code = "", country = ""} = billingDetails;
    format = format.replace('{line1}', line1);
    format = format.replace('{city}', city);
    format = format.replace('{state}', state? ` ${state}` : '');
    format = format.replace('{country}', getCountryNameByCode(country));
    format = format.replace('{postal_code}', postal_code);
    return format;
}

export {getAddressString};

