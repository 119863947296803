import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import ExitButton from '../ExitButton/ExitButton';
import HeaderBackground from '../../../assets/modal/header-mask.svg';
import DeviceIcon from '../../../assets/modal/device.svg';

import useStyles from './ModalHeader.css';

const ModalHeader = ({ title, icon, onClose }) => {
	const classes = useStyles({ HeaderBackground });

	return (
		<Grid item xs={12} className={classes.header}>
			<img src={icon || DeviceIcon} alt="header-icon" className={classes.headerIcon} />
			<div className={classes.headerLabel}>
				{title}
			</div>
			<ExitButton onClick={onClose} color="white"/>
		</Grid>);
};

ModalHeader.propTypes = {
	title: PropTypes.str,
	icon: PropTypes.element,
	onClose: PropTypes.func,
};

export default ModalHeader;
