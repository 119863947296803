import React from 'react';
import {Typography} from "@material-ui/core";
import StyleButton from "../../../shared/components/form/Buttons/StyleButton";
import {useStyles} from "./PaymentFrame.css";


function SubscriptionType({currentPlanName, onClick}) {
    const classes = useStyles();

    return (
        <div className={classes.subscriptionHeaderTopContainer}>
            <div style={{flex: '0 1 auto'}}>
                <Typography variant={"subtitle1"} className={classes.textBlockTitle}>SUBSCRIPTION
                    TYPE</Typography>
            </div>
            <div className={classes.subscriptionNameSection}>
                <Typography className={classes.productName} variant={"h6"}
                            color={"primary"}>{currentPlanName}</Typography>
                <StyleButton className={classes.button} variant={"outlined"} onClick={onClick} label={"CHANGE PLAN"}/>
            </div>

        </div>
    )
}

export default SubscriptionType;