import { makeStyles } from '@material-ui/core';
import { COLOR_NORMAL, COLOR_WHITE, FONT_MEDIUM, FONT_SMALL } from './consts';

const COLOURS = {
	[COLOR_WHITE]: theme => `${theme.palette.primary.white}`,
	[COLOR_NORMAL]: theme => `${theme.palette.primary.main}`,
};

const FONT_SIZES = {
	[FONT_SMALL]: '14px',
	[FONT_MEDIUM]: '16px',
};

const useStyles = makeStyles(theme => ({
	button: ({ color, font, contained }) => ({
		textTransform: 'none !important',
		color: `${COLOURS[color](theme)} !important`,
		fontWeight: '400 !important',
		fontFamily: `${theme.typography.fontFamily} !important`,
		borderColor: `${theme.palette.primary.main} !important`,
		minWidth: '120px !important',
		height: '40px',
		lineHeight: '1 !important',
		fontSize: `${FONT_SIZES[font]} !important`,

		[theme.breakpoints.down('lg')]: {
			minWidth: !contained ? '86px !important' : '120px !important',
		},
	}),

	active: {
		border: `2px solid ${theme.palette.primary.main} !important`,
	},

	badge: {
		backgroundColor: `${theme.palette.primary.main} !important`,
	},
}));

export default useStyles;
