import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    containerGrid: {
        color: '#48648b',
        fontSize: '14px',
        fontWeight:'normal',
        minWidth: '100%',
        lineHeight:2.14,
    },
    button:{
        width: '180px',
        fontSize: '16px',
        border: `solid 1px ${theme.palette.primary.main}`,
        borderRadius: '6px',
        height: '50px',
        fontWeight: 700,
        marginLeft: '1.6em',
        marginTop: '-0.7em',
    },
    containerNoneGrid: {
        color: '#48648b',
        fontSize: '14px',
        fontWeight:'normal',
        minWidth: '100%',
        lineHeight:1.4
    },
}));

export {useStyles}
