import React, { useState } from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './spanWithTranslation.css';

const SpanWithTranslation = ({
	children,
	translation = '',
	additionalClasses,
	...otherTypographyProps
}) => {
	const [showOriginalLabel, setShowOriginalLabel] = useState(true);
	const classes = useStyles({ translation });

	const translate = event => {
		event.preventDefault();
		translation && setShowOriginalLabel(!showOriginalLabel);
	};

	return (
		<>
			{showOriginalLabel ? (
				<Typography
					{...otherTypographyProps}
					onClick={translate}
					className={clsx(additionalClasses, classes.translationEnabled)}
				>
					{children}
				</Typography>
			) : (
				<Typography
					{...otherTypographyProps}
					color={'primary'}
					onClick={translate}
					className={clsx(additionalClasses, classes.translationEnabled)}
				>
					{translation}
				</Typography>
			)}
		</>
	);
};

SpanWithTranslation.propTypes = {
	children: PropTypes.node.isRequired,
	translation: PropTypes.string,
	additionalClasses: PropTypes.object,
};

export default SpanWithTranslation;
