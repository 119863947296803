import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	successMessage: {
		color: theme.palette.primary.main,
		fontWeight: '600',
		alignSelf: 'center',
	},
	label: {
		cursor: 'pointer',
		marginLeft: '3px',
		color: theme.palette.inputText.main,
	},
	'xs-label': {
		fontSize: '10px',
	},
	'small-label': {
		fontSize: '14px',
	},
	iconWrapper: {
		display: 'flex',
		alignSelf: 'center',
		marginTop: '1px',
	},
}));

export default useStyles;
