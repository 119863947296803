import { getEnrichmentData } from '../../../../containers/SummaryView/controller/services/ipEnrichment/requests';
import { getVendorDataByMac } from '../../../../containers/endpointInventory/services/endpointInventoryRequests';
import {
	ALIAS_FIELD,
	aliasAndVendorFields,
	enrichmentAndAliasAndVendorFields,
	enrichmentAndAliasFields, MAC_FIELD,
} from './enrichmentMappings';
import { isPublic } from './ipHelper';

const pickFields = (object, fieldsList) => {
	const newObj = {};
	fieldsList.forEach(field => {
		if (object[field]) {
			newObj[field] = object[field];
		} else {
			newObj[field] = null;
		}
	});
	return newObj;
};

export const getEnrichmentAndVendorDataFields = async (ip, mac, pickedFields) => {
	const data = await Promise.allSettled([getEnrichmentData(ip), getVendorDataByMac(mac)]);
	const result = data.filter(res => res.status === 'fulfilled')
		.reduce((
			previous, current) => ({ ...previous, ...(current.value) }), {});
	if (Object.keys(result).length > 0) {
		return pickFields(result, pickedFields);
	}

	const rejectedReasons = data.filter(res => res.status === 'rejected').map(res => res.reason);
	const reason = rejectedReasons.length > 0 ? rejectedReasons.join(', ') : 'No data returned';
	throw new Error(reason);
};

export const getEnrichmentDataFields = async (ip, pickedFields) => {
	if (ip) {
		const enrichmentData = await getEnrichmentData(ip);
		return pickFields(enrichmentData, pickedFields);
	}

	return [];
};

export const getFieldsToDisplay = (ip, mac) => {
	if (ip && isPublic(ip)) {
		if (mac) {
			return [...enrichmentAndAliasAndVendorFields, MAC_FIELD];
		}

		return enrichmentAndAliasFields;
	}

	if (mac) {
		return [...aliasAndVendorFields, MAC_FIELD];
	}

	return [ALIAS_FIELD];
};

export const getData = async (ip, mac, fields) => {
	if (mac) {
		try {
			const data = await getEnrichmentAndVendorDataFields(ip, mac, fields);
			return { ...data, mac };
		} catch {
			return { mac };
		}
	}

	const data = await getEnrichmentDataFields(ip, fields);
	return data;
};
