import React, { useState } from 'react';
import SimpleSelectWithIcon from '../../shared/components/dropdown/simpleSelectWithIcon';
import { Box, Dialog, Divider, Grid } from '@material-ui/core';
import { cloudFilterOptions, filterMapping, AWS_FILTER_VALUE } from './cloudProvidersConfiguration';
import { useStyles } from './cloudFilterModal.css';

const MODAL_WIDTH = '630px';
const MODAL_HEIGHT = '640px';

function CloudFilterModal({ isOpen, handleClose, hostingFormName }) {
	const [filterType, setFilterType] = useState(AWS_FILTER_VALUE);
	const classes = useStyles();

	const CloudFilterForm = filterMapping[filterType].formComponent;
	return (
		<Dialog onClose={handleClose} open={isOpen} maxWidth={'xl'} PaperProps={{ classes: { root: classes.dialog } }}>
			<Box css={{ margin: '20px' }} height={MODAL_HEIGHT} width={MODAL_WIDTH}>
				<Grid container xs={12} className={classes.container}>
					<Grid item xs={6} className={classes.selectItem}>
						<SimpleSelectWithIcon
							items={cloudFilterOptions}
							defaultValue={AWS_FILTER_VALUE}
							onChange={value => setFilterType(value)}
							InputProps={{ style: { height: '45px' } }}
						/>
					</Grid>

					<Grid item container xs={12} className={classes.form}>
						<CloudFilterForm onSubmit={handleClose} hostingFormName={hostingFormName}/>
					</Grid>
				</Grid>
			</Box>
		</Dialog>

	);
}

export default CloudFilterModal;
