import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "60%"
    },
    button: {
        marginTop: "20px",
        width: "170px"
    }
}));

export {useStyles}