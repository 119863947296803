import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import MobileStepper from "@material-ui/core/MobileStepper";
import {useStyles} from "./walkthroughStepper.css";

const WalkthroughStepper = ({ steps, finishCallback, hasEndComponent = true }) => {
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyles();

    const handleNext = () => {
        if (activeStep < steps.length - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            finishCallback();
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }


    return (
        <div className={classes.root}>
            {steps[activeStep]()}
            <MobileStepper
                variant="dots"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                className={classes.stepper}
                nextButton={
                    <Button size="small" onClick={handleNext} className={classes.buttons}>
                        {!hasEndComponent && activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.buttons}>
                        Back
                    </Button>
                }
            />
        </div>
    )
}

export default WalkthroughStepper;