import React, {Component} from "react";
import _ from 'lodash';
import BaseIntegrationForm from '../baseIntegrationForm';
import {WEEKLY_REPORT} from '../../../controller/constants/integrationTypes';
import reportIcon from '../../../../../assets/integrations/weekly-email.svg';
import './reportIntegration.scss';
import {Field, FieldArray} from "redux-form";
import {
    build_houres,
    build_timeZone,
    build_week_days, getCurrentTimeZone,
    processTimeZone
} from "../../../controller/businessLogic/reportsTimeUtils";
import {
    getWeeklyReportIntegration,
    createWeeklyReportPolicy,
    updateWeeklyReportPolicy
} from "../../../controller/services/weeklyReportRequests";
import http from "../../../../../services/httpService/http";
import {activeTypes} from "../../../controller/constants/activeTypes";
import Dropdown from "../../../../../shared/components/dropdown/dropdown";
import EmailArrayField from "../fieldArrayComponents/emailArrayField";
import Grid from "@material-ui/core/Grid";
import {openSnackbar} from "../../../../snackbarAlert/controller/redux/actions";
import {connect} from "react-redux";

const FORM_NAME = "weekly-report-integration-form"
const RECIPIENTS_FIELD_ARRAY_NAME = "emails"
const ERROR_MESSAGE = "Something went wrong, please try again";
const SUCCESS_MESSAGE = "Your weekly report details successfully saved";

class WeeklyReportIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            weeklyReport: {},
            initialValues: {}
        };
        this.source = http.getCancelTokenSource();
        this.saveIntegration = this.saveIntegration.bind(this);
    }

    async getWeeklyReportPolicies() {
        const policies = await getWeeklyReportIntegration({cancelToken: this.source.token});
        let policy = {}
        if (!_.isEmpty(policies)) {
            policy = policies[0];
            policy.email = JSON.parse(policy.Recipients).email;
        }
        return _.isEmpty(policies) ? {} : policy;
    }

    async createWeeklyReportPolicy(object) {
        const emails = object.emails.filter((email) => !_.isEmpty(email));
        const payload = {
            "SendingTriggerDay": object.SendingTriggerDay,
            "SendingTriggerHour": parseInt(object.SendingTriggerHour),
            "SendingTimeZone": object.SendingTimeZone,
            "Recipients": {
                "email": emails
            },
            "Version": "1"
        }
        const policies = await createWeeklyReportPolicy(payload);
        return policies;
    }

    async updateWeeklyReportPolicy(reportPolicyId, policy) {
        const emails = policy.emails.filter((email) => !_.isEmpty(email));
        const toUpdateObject = {
            "SendingTriggerDay": policy.SendingTriggerDay,
            "SendingTriggerHour": parseInt(policy.SendingTriggerHour),
            "SendingTimeZone": policy.SendingTimeZone,
            "Recipients": {
                "email": emails
            },
            "Version": "1",
            "Enabled": true
        }

        let policies = await updateWeeklyReportPolicy(reportPolicyId, toUpdateObject);
        return policies;
    }

    componentDidMount = async () => {
        try {
            const weeklyReportPolicies = await this.getWeeklyReportPolicies();
            const weeklyReport = _.isEmpty(weeklyReportPolicies) ? {} : weeklyReportPolicies;
            this.setState({weeklyReport});
            this.setState({initialValues: this.createInitialValues()});
        } catch (exception) {
            console.log(exception.message);
        }
    }

    createInitialValues() {
        const report = this.state.weeklyReport;
        if (!_.isEmpty(report)) {
            return {
                emails: report.email,
                SendingTriggerDay: report.SendingTriggerDay,
                SendingTriggerHour: report.SendingTriggerHour,
                mode: 'edit'
            }
        }
        return {
            emails: [],
            SendingTriggerDay: build_week_days()[0],
            SendingTriggerHour: build_houres()[0],
            mode: 'create'
        }
    }

    saveIntegration = async (reportPolicy) => {
        const {updateSelectedIntegration, updateEnabledIntegration} = this.props
        const {weeklyReport} = this.state;

        try {
            if (reportPolicy.mode === 'create') {
                await this.createWeeklyReportPolicy({...reportPolicy, SendingTimeZone: getCurrentTimeZone()});
            } else if (reportPolicy.mode === 'edit') {
                await this.updateWeeklyReportPolicy(weeklyReport.Id, {
                    ...reportPolicy,
                    SendingTimeZone: getCurrentTimeZone()
                });
            }
            await updateSelectedIntegration(null);
            updateEnabledIntegration(WEEKLY_REPORT, activeTypes.ON);
            this.props.openSnackbar("success", SUCCESS_MESSAGE, 4000);

        } catch (e) {
            this.props.openSnackbar("error", ERROR_MESSAGE, 4000);
        }

    }

    WeeklyReportForm = ({change}) => {
        return <>
            <Grid container spacing={2} style={{marginBottom: '30px'}}>
                <Grid item xs={6}>
                    <Field
                        name="SendingTriggerHour"
                        label="Hour"
                        type="number"
                        component={Dropdown}
                        onFieldChange={(value) => {
                            change('SendingTriggerHour', value)
                        }}
                        options={build_houres()}
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        name="SendingTriggerDay"
                        label="Deliver on"
                        type="number"
                        component={Dropdown}
                        onFieldChange={(value) => {
                            change('SendingTriggerDay', value)
                        }}
                        options={build_week_days()}
                        required
                    />
                </Grid>
            </Grid>


            <div className="email-report">
                <FieldArray
                    name={RECIPIENTS_FIELD_ARRAY_NAME}
                    component={EmailArrayField}
                    formName={FORM_NAME}
                    required
                />
            </div>
        </>
    }

    componentWillUnmount() {
        this.source.cancel('Weekly reports request was canceled');
    }

    render() {
        const {initialValues} = this.state;
        return (
            <BaseIntegrationForm
                title="Weekly summarized alert report"
                subTitle="Get a concise report once a week, with information about network status, traffic, threats, and attacks."
                form={FORM_NAME}
                onSubmit={this.saveIntegration}
                initialValues={initialValues}
                BaseIntegrationFormComponent={this.WeeklyReportForm}
            />
        )
    }
}

const mapDispatchToProps = {
    openSnackbar,
};

export default connect(null, mapDispatchToProps)(WeeklyReportIntegration);
