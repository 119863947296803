import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    md:{
        fontSize: '30px',
        fontWeight: 'normal'
    },

    sm:{
        fontSize: '24px'
    }
}));

export {useStyles}