import { Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as CheckIcon } from '../../../../assets/icons/check.svg';
import downArrow from '../../../../assets/threats/downarrow.svg';
import PopoverField from '../../../../containers/acceptlist/rulesTable/advancedCells/popoverField/popoverField';
import { toLocaleDateString } from '../../../../helpers/datesHelper';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import DateRange from '../fields/dateRange';
import Activity from './Activity/Activity';
import AdditionalDataField from './AdditionalDataField/AdditionalDataField';
import AddEditField from './addEditField';
import SimpleField from './simpleField';
import Status from '../../statusIcon/status';

const STATUS_LABELS = {
	activeDevice: 'activeDevice',
	inactiveDevice: 'inactiveDevice',
};

const StyledButton = withStyles(theme => ({
	root: {
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}))(Button);

class FieldsFactory {
	createTextField(accessor, alias, filterable = true, allowView = true, allowEdit = true,
		show = true, mapper = null, className = null, tooltip = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			// eslint-disable-next-line react/prop-types
			Cell({ value }) {
				let renderedValue = value || '-';
				if (mapper) {
					renderedValue = mapper(value) || '-';
				}

				return <CustomTooltip title={tooltip ? tooltip(renderedValue) : ''}>
					<span>
						{renderedValue}
					</span>
				</CustomTooltip>;
			},
		};
	}

	createDateField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'date',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell: ({ value }) => (value && moment(value).isValid()) ? toLocaleDateString(value, true) : '',
			filterMethod(filter, row) {
				// Compare row date with the range from the date picker on UI
				if (filter.value) {
					const startDate = moment(filter.value[0]);
					const endDate = moment(filter.value[1]);
					const rowDate = moment(new Date(row.date));
					return rowDate.isBetween(startDate, endDate);
				}

				return true;
			},
			// Filter html
			// eslint-disable-next-line react/prop-types
			Filter: ({ onChange }) =>
				<DateRange onChange={ selected => onChange(selected) } />,
		};
	}

	createCheckBoxField(accessor, alias, onSelect, onSelectAll) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: true,
			show: true,
			className: null,
			sortable: false,
			type: 'checkBox',
			Header: () => (
				<Checkbox
					onClick={() => onSelectAll()}
					style ={{
						color: '#fff',
					}}
				/>
			),
			/* eslint-disable react/prop-types */
			Cell: ({ original }) =>
				<Checkbox
					checked={ original.isSelected }
					color="default"
					onChange={ () => onSelect(original) }
				/>,
			/* eslint-disable react/prop-types */

		};
	}

	createActivityField(accessor, alias, checkIsOn, activeText, inactiveText) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: false,
			allowEdit: false,
			show: true,
			resizable: false,
			type: 'actions',
			Header: () => (
				<SimpleField alias={ alias } className={ null } filterable={ false } />
			),

			Cell({ original }) {
				const isActive = checkIsOn(original);
				return (
					<Activity isActive={isActive}
						activeLabel={activeText}
						inactiveLabel={inactiveText}/>
				);
			},
		};
	}

	createAttachedField(accessor, alias, checkIsOn, attachedText, unattachedText) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: false,
			allowEdit: false,
			show: true,
			resizable: false,
			type: 'actions',
			Header: () => (
				<SimpleField alias={ alias } filterable={ false } />
			),

			Cell({ original }) {
				const isActive = checkIsOn(original);
				return <span>
					{isActive ? attachedText : unattachedText}
				</span>;
			},
		};
	}

	createActivityFieldWithTooltip(accessor, alias, checkIsOn) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: false,
			allowEdit: false,
			show: true,
			resizable: false,

			type: 'actions',
			Header: () => (
				<SimpleField alias={ alias } className={ null } filterable={ false } />
			),

			Cell({ original }) {
				const isActive = checkIsOn(original);

				if (!isActive) {
					return (
						<span>
							<Status status={STATUS_LABELS.inactiveDevice}/>
						</span>
					);
				}

				return (
					<span>
						<Status status={STATUS_LABELS.activeDevice} />
					</span>
				);
			},
		};
	}

	createReadyFieldWithTooltip(accessor, alias, checkIsOn, readyText, notReadyText, notReadyTooltip, isCollectorTypeSupportReadiness) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: false,
			allowEdit: false,
			show: true,
			resizable: false,

			type: 'actions',
			Header: () => (
				<SimpleField alias={ alias } className={ null } filterable={ false } />
			),

			Cell({ original, row }) {
				const isReady = checkIsOn(original);

				if (isCollectorTypeSupportReadiness(row.entityType)) {
					return (
						<CustomTooltip title={isReady ? '' : notReadyTooltip}>
							<span>
								{isReady ? readyText : notReadyText}
							</span>
						</CustomTooltip>);
				}

				return (
					<span>-</span>
				);
			},
		};
	}

	createPopOverField(accessor, alias, onClickCallback) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: true,
			allowEdi: true,
			show: true,
			className: null,
			type: 'popOver',
			Header: () => (
				<SimpleField alias={ alias } className={ null } filterable={ false } />
			),

			Cell: ({ original }) =>
				<StyledButton endIcon={<img src={downArrow} alt={'show more'}/>}
					onClick={() => onClickCallback(original)}>Click
                    to view</StyledButton>,
		};
	}

	createAddEditField(accessor, handleEdit, handleDelete, handleDownload, checkIsDeletable, handleRecorder,
		className = 'alignEnd', show = true) {
		return {
			accessor,
			alias: '',
			filterable: false,
			allowView: false,
			allowEdit: false,
			show,
			resizable: false,
			className,
			type: 'actions',
			Header: () => (
				<SimpleField alias="" className={ className } filterable={ false } />
			),

			Cell: ({ original }) => (
				<AddEditField deviceInfo={ original }
					handleRecorder={handleRecorder}
					handleDelete={ checkIsDeletable(original) ? handleDelete : null }
					handleEdit={ handleEdit }
					handleDownload={ handleDownload } />
			),
		};
	}

	createAdditionalDataPopoverField(accessor, alias) {
		return {
			accessor,
			alias,
			filterable: false,
			allowView: true,
			allowEdi: true,
			show: true,
			className: null,
			type: 'popover',
			Header: () => (
				<SimpleField alias={ alias } className={ null } filterable={ false } />
			),

			Cell: ({ original }) =>
				<PopoverField title="Click to view">
					{ }
					<AdditionalDataField data={original.additionalData} />
				</PopoverField>,
		};
	}

	createCheckboxField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => <SimpleField alias={alias} className={className} filterable={filterable} />,
			Cell: ({ value }) => (value ? <CheckIcon /> : '-'),
		};
	}
}
FieldsFactory.propTypes = {
	original: PropTypes.object,
};

export default new FieldsFactory();
