import axios from 'axios';
import _ from 'lodash';

const get = (endpoint, config) => axios.get(endpoint, config);

const post = (endpoint, data, config) => axios.post(endpoint, data, config);

const put = (endpoint, data, config) => axios.put(endpoint, data, config);

const _delete = (endpoint, data, config) => axios.delete(endpoint, { ...config, data });

const getCancelTokenSource = () => axios.CancelToken.source();

export default {
	get,
	post,
	put,
	_delete,
	getCancelTokenSource,
};
