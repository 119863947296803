import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { format, numericality, required, validateForm } from 'redux-form-validators';
import Dropdown from '../../../../../shared/components/dropdown/dropdown';
import SubmitButton from '../../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../../shared/components/form/TextField/TextField';
import { FormWrapper } from '../../../../../shared/components/simpleTable/simpleFormContainer';
import SimpleModal from '../../../../../shared/components/simpleTable/simpleModal';

import { ACCOUNT_VALIDATE_REGEX, VPC_VALIDATE_REGEX } from '../../../../cloudFilter/aws/validator';

const AWSForm = ({ onSubmit, handleSubmit, awsRegions, change, isEditMode, isSubmittingForm }) => (
	<form onSubmit={handleSubmit(onSubmit)} className="modal-form">
		<FormWrapper title=" " wrapperStyle={{}}>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Field
						name="name"
						component={ TextField }
						label="Display name"
						type="text"
						margin="none"
						className="form-field"
						required
					/>
					{/* label="VPC ID"  -> Should be Identifier, Because there is no way to change the identifier type, today,
                        The label is VPC ID. */}
				</Grid>
				<Grid item xs={6}>
					<Field
						name="identifierValue"
						disabled={isEditMode}
						component={ TextField }
						label="VPC ID"
						type="text"
						margin="none"
						className="form-field"
						required
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Field
						name="aws.accountId"
						disabled={isEditMode}
						component={ TextField }
						label="Account ID"
						type="text"
						margin="none"
						className="form-field"
						required
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						name="aws.region"
						isDisabled={isEditMode}
						component={ Dropdown }
						label="Select region name"
						options={ awsRegions }
						onFieldChange={value => {
							change('aws.region', value);
						}}
						required
					/>
				</Grid>
			</Grid>
		</FormWrapper>
		<div style={{ marginBottom: '30px' }}>
			{isSubmittingForm ? (
				<CircularProgress color="primary" />
			) : (
				<SubmitButton label={isEditMode ? 'Save' : 'Add cloud device'} />
			)}
		</div>
	</form>
);

// *** In future when user could choose more then VPC, we will get the identifier validator format from configuration
// dict {type: identifierValidator}

// Redux Form
const validate = validateForm({
	name: [required({ msg: 'Please enter a display name' })],
	identifierValue: [required({ msg: 'Please enter identifier' }), format({ with: VPC_VALIDATE_REGEX, msg: 'Please enter a valid VPC' })],
	aws: {
		accountId: [required({ msg: 'Please enter aws account ID' }), format({ with: ACCOUNT_VALIDATE_REGEX, msg: 'Please enter a valid AWS account name' })],
		region: [required({ msg: 'Please select a region' })],
	},
});

export default reduxForm({
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(AWSForm);
