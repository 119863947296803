import React, {useState} from 'react';
import {Paper, Popper} from "@material-ui/core";
import {useStyles} from "./PasswordTooltip.css";
import clsx from "clsx";


function PasswordTooltip({anchor, isOpen, passwordContent, placement = "right"}) {
    const [arrowRef, setArrowRef] = useState(null);
    const classes = useStyles();

    const checkIfAttrExists = (attr) => {
        if(passwordContent){
            return passwordContent.includes(attr);
        }
        return false;
    }

    return (
        <>
            <Popper disablePortal={true} className={classes.popperMargin} anchorEl={anchor} open={isOpen}
                    placement={placement} modifiers={{
                arrow: {
                    enabled: true,
                    element: arrowRef

                }
            }}>
                <div className={classes.arrow} ref={setArrowRef}></div>
                <Paper className={classes.paper}>
                    <div className={classes.bubbleTitle}>Password must include</div>
                    <ul>
                        <li className={clsx({[classes.attributeApplied]: checkIfAttrExists("length")})}><div className={classes.bubbleSubtitle}>At least 12 characters</div></li>
                        <li className={clsx({[classes.attributeApplied]: checkIfAttrExists("number")})}><div className={classes.bubbleSubtitle}>A least 1 number</div></li>
                        <li className={clsx({[classes.attributeApplied]: checkIfAttrExists("uppercase")})}><div className={classes.bubbleSubtitle}>An upper case character</div></li>
                        <li className={clsx({[classes.attributeApplied]: checkIfAttrExists("lowercase")})}><div className={classes.bubbleSubtitle}>A lower case character</div></li>
                        <li className={clsx({[classes.attributeApplied]: checkIfAttrExists("symbol")})}><div className={classes.bubbleSubtitle}>A special character</div></li>
                    </ul>
                </Paper>
            </Popper>
        </>
    )
}


export default PasswordTooltip;