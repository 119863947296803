import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentUser } from '../app/controller/redux/auth/authActions';
import { compose } from 'redux';
import { getGroupsAsync, getAccountPublicFields } from '../app/controller/redux/account/accountActions';
import { withRouter } from 'react-router';
import { getInsights } from '../SummaryView/controller/redux/insights/actions';
import { getLiteCollectorDevices, getLiteDevices, getLitePublicCloudDevices } from '../deviceManagement/controller/lightweightReducer/actions';
import { getUsers } from '../app/controller/redux/user/userActions';
import {
	createAuthenticationMetaDataCookie, getHashPathFromStorage, isUserAuthenticated,
	redirectToHostedUi, removeHashPathFromStorage, saveHashPathToStorage,
} from './services/tools';
import { generateAuthenticationCookie } from './services/requests';
import { CODE_PARAMETER } from './authenticationProcessConfig';

const AuthenticateUser = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const authenticateUser = async () => {
			const { search, origin, hash } = window.location;
			const params = new URLSearchParams(search);
			const code = params.get(CODE_PARAMETER);

			if (params.get(CODE_PARAMETER)) {
				const { expiresIn, userId } = await generateAuthenticationCookie(origin, code);
				const expirationDate = new Date(expiresIn);
				createAuthenticationMetaDataCookie(expirationDate, userId);
				const hashPath = getHashPathFromStorage();
				removeHashPathFromStorage();

				window.location.href = hashPath ? `${origin}/${hashPath}` : origin;
			}

			if (isUserAuthenticated()) {
				dispatch(getAccountPublicFields());
				dispatch(getCurrentUser());
				dispatch(getUsers());
				dispatch(getLiteDevices());
				dispatch(getLiteCollectorDevices());
				dispatch(getLitePublicCloudDevices());
				dispatch(getGroupsAsync());
				dispatch(getInsights());
			} else {
				saveHashPathToStorage(hash);
				redirectToHostedUi(origin);
			}
		};

		authenticateUser();
	}, []);

	return null;
};

export default compose(
	withRouter,
)(AuthenticateUser);
