import { deviceTypes } from '../../view/devicesManagementConfig';
import { deleteCollectorDevice, getCollectorDevice, updateCollectorDevice } from './collectorDeviceRequests';
import { getDevice as getPublicDevice, removeDevice, updateDevice } from './deviceRequests';
import { getPublicCloudDevice, removePublicCloudDevice, updatePublicCloudDevice } from './publicCloudDeviceRequests';

const deleteDevice = {
	[deviceTypes.PUBLIC_DEVICE]: removeDevice,
	[deviceTypes.CORE_DEVICE]: deleteCollectorDevice,
	[deviceTypes.CLOUD_DEVICE]: removePublicCloudDevice,
};

const editDevice = {
	[deviceTypes.PUBLIC_DEVICE]: updateDevice,
	[deviceTypes.CORE_DEVICE]: updateCollectorDevice,
	[deviceTypes.CLOUD_DEVICE]: updatePublicCloudDevice,
};

const getDevice = {
	[deviceTypes.PUBLIC_DEVICE]: getPublicDevice,
	[deviceTypes.CORE_DEVICE]: getCollectorDevice,
	[deviceTypes.CLOUD_DEVICE]: getPublicCloudDevice,
};

export {
	deleteDevice,
	editDevice,
	getDevice,
};
