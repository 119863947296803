/* eslint-disable */
import React, { Component } from 'react';

function makeReduxCreatable(Select) {
	return class extends Component {
		constructor(props) {
			super(props);
			this.state = {};
		}

		render() {
			const { input } = this.props;
			return (
				<Select
					{ ...this.props }
					value={ input.value }
					onChange={ value => input.onChange(value) }
					onBlur={ e => input.onBlur(input.value) }
					className="react-select react-select-underline react-select-creatable"
					classNamePrefix="react-select"
				/>
			);
		}
	};
}

export default makeReduxCreatable;
