import React, { Component } from 'react';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import InsightMenu from '../../insightMenu/insightMenu';
import moment from 'moment';
import * as d3 from "d3-format";
import _ from 'lodash'; 
import { minMaxformater } from '../../../../../../constants/formater';
import {Tooltip} from "@material-ui/core";

class TableHeaderLineInsights extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { row, isOpen } = this.props;
         
        return (

            <>
                <Tooltip title={isOpen ? 'Collapse' : 'Expand'}>
                <TableCell align="left">
                    { !_.isEmpty(row.assets.connections) ? <IconButton size="small" onClick={ this.props.onOpenRow }>
                        { isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                    </IconButton> : '' }
                </TableCell>
                </Tooltip>
                <TableCell align="left">{ moment(row.from *1000 ).format('HH:mm') }</TableCell>
                <TableCell align="left">{ moment(row.to *1000).format("HH:mm") }</TableCell>
                <TableCell align="left">{ d3.format(minMaxformater)(row.value) }</TableCell>
                <TableCell align="left">{ d3.format(minMaxformater)(row.ratio * 100) }</TableCell>
                <TableCell align="left">
                    <>
                        <InsightMenu
                            runQuery={ (e) => {
                                this.props.runQuery(row);
                            } } />
                    </>
                </TableCell>
            </>
        );
    }
}

export default TableHeaderLineInsights;