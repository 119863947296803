import axios from 'axios';
import AppConfig from '../../config';
import store from '../../containers/app/controller/redux/store/store';
import { logout } from '../../containers/app/controller/redux/auth/authActions';
import RequestError from './middleware/error';
import {
	getUserIdFromCookie,
	getUserIdFromSession,
	isUserAuthenticated, isUserIdOverSession, setUserIdOverSessions,
} from '../../containers/authenticateUser/services/tools';

axios.defaults.baseURL = AppConfig.server.url;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
axios.interceptors.response.use(response => response.data, error => onRejection(error));
axios.interceptors.request.use(config => onRequestFulfilled(config), error => Promise.reject(error));

const onRejection = error => {
	if (error.response) {
		if (error.response.status === 401) {
			store.dispatch(logout());
		}

		throw new RequestError(error.response.status, error.response.statusText, error.response.data.message || error.message);
	} else {
		throw new RequestError(null, null, error.message);
	}
};

const onRequestFulfilled = config => {
	if (isUserAuthenticated()) {
		if (getUserIdFromSession() === getUserIdFromCookie()) {
			return config;
		}

		if (!isUserIdOverSession()) {
			setUserIdOverSessions(getUserIdFromCookie());
			return config;
		}
	}

	if (!isUserIdOverSession() && !isUserAuthenticated()) {
		return config;
	}

	store.dispatch(logout());
};
