import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        height: "100%",
        padding: "20px"
    },
    button: {
        width: "200px"
    },
    image: {
        height: "220px",
        width: "450px"
    },
    text: {
        fontWeight: "bold",
        color: theme.palette.inputText.main,
        fontSize: "16px",
        textAlign: "center"
    },
    serviceName: {
        color: "#7287a5"
    }
}));

export {useStyles}