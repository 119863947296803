import { validateCidr, isMac, isAwsInstanceId, isAwsSubnetId } from '../../controller/validations/validation';

const groupTypes = {
	MAC: 'MAC',
	CIDR: 'CIDR',
	ORGANIZATION: 'ORGANIZATION',
	AWS_INSTANCE_ID: 'AWS_INSTANCE_ID',
	AWS_SUBNET_ID: 'AWS_SUBNET_ID',
};

const groupTypesManager = {
	MAC: {
		selectDisplayText: 'MAC',
		inputPlaceholder: 'MAC address',
		validate: value => isMac(value),
		shouldShow: () => true,
	},
	CIDR: {
		selectDisplayText: 'CIDR',
		inputPlaceholder: 'CIDR address',
		validate: value => validateCidr(value),
		shouldShow: () => true,
	},
	ORGANIZATION: {
		selectDisplayText: 'Organization/DNS',
		inputPlaceholder: 'Organization/DNS Name',
		validate: value => value !== '',
		shouldShow: () => true,
	},
	AWS_INSTANCE_ID: {
		selectDisplayText: 'AWS Instance ID',
		inputPlaceholder: 'Instance ID',
		validate: value => isAwsInstanceId(value),
		shouldShow: () => true,
	},
	AWS_SUBNET_ID: {
		selectDisplayText: 'AWS Subnet ID',
		inputPlaceholder: 'Subnet ID',
		validate: value => isAwsSubnetId(value),
		shouldShow: () => true,
	},
};

export { groupTypes, groupTypesManager };
