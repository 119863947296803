import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    form: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        //justifyContent: 'space-evenly'
        rowGap: '25px'
    },

    mailFieldContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },

    mailHelperText: {
        marginTop: '12px',
        textIndent: '3px'
    },

    button: {
        height: '50px'
    }
}));


export default useStyles;