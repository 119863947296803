import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	title: {
		marginBottom: theme.spacing(3),
		fontSize: '16px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	description: {
		fontSize: '14px',
		fontWeight: 400,
	},
	selector: {
		margin: '6px 0',
	},
}));

export default useStyles;
