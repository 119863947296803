import React from 'react';
import useStyles from './statementBox.css';
import emptyTableFilters from '../../../../../assets/detectionCenter/emptyTableFilters.svg';
import { Typography } from '@mui/material';
import { Grid } from '@material-ui/core';

const NO_DATA_TEXT = 'No data available';
const NO_DATA_SUBTEXT_LINE_1 = 'No data available for the selected time segment,';
const NO_DATA_SUBTEXT_LINE_2 = 'you may want to try adjusting your query parameters.';

const LoadingBar = () => (
	<div className="graph-loading-container">
		<div className="graph-loading" />
		<div className="graph-loading-text">
                Processing data please wait ...
		</div>
	</div>
);
const NoTimeSegment = () => (
	<div className="no-segment">
		<div className="crossed-circle" />
		<div className="vv-no-segment-text">
                No time segment selected
		</div>
	</div>
);

const RunNewAnalyse = () => (
	<div className="no-segment">
		<div className="background-process-logo" />
		<div className="vv-no-segment-text">
                Run new analysis
		</div>
	</div>
);
const ErrorAnalyse = () => (
	<div className="no-segment">
		<div className="background-process-logo" />
		<div className="vv-no-segment-text">
                An error occure
		</div>
	</div>
);

/// if the dates selected do not have any data, then the server responds with empty response
/// we are handling this and display text like “NO DATA AVAILABLE FOR SELECTED TIME SEGMENT”
const NoData = () => (
	<div className="no-segment">
		<div className="vv-no-segment-text">
                No data available for selected time segment
		</div>
	</div>

);

const AnalysisNoData = () => {
	const classes = useStyles();

	return (
		<Grid className={classes.noSegment}>
			<img
				src={emptyTableFilters}
				alt="No detections with filter"
				className={classes.noDataImage}
			/>
			<Typography className={classes.noDataMainText}>
				{NO_DATA_TEXT}
			</Typography>
			<Typography className={classes.noDataSubText}>
				{NO_DATA_SUBTEXT_LINE_1} <br/> {NO_DATA_SUBTEXT_LINE_2}
			</Typography>
		</Grid>
	);
};

export {
	NoData,
	ErrorAnalyse,
	RunNewAnalyse,
	LoadingBar,
	NoTimeSegment,
	AnalysisNoData,
};

