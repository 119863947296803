import React from "react";
import ErrorIndication from "../../../shared/components/IndicationComponent/types/ErrorIndication";
import SuccessIndication from "../../../shared/components/IndicationComponent/types/SuccessIndication";

const CONTACT_FORM_TITLE = 'Contact sales team';
const CONTACT_FORM_SUBTITLE = 'Please contact our sales team to modify your plan';

const attachCallbackToErrorComponent = (callback) => <ErrorIndication callback={callback} header={"Something went wrong"} description={"Unfortunately, your form was not submitted.\n Please try again later."} buttonText={"Ok"} />;
const attachCallbackToSuccessComponent = (callback) => <SuccessIndication callback={callback} header={"Congrats, your form has been submitted!"} description={"A member of our sales team will be in contact with you shortly"} buttonText={"Finish"}/>;



export {
    CONTACT_FORM_TITLE,
    CONTACT_FORM_SUBTITLE,
    attachCallbackToErrorComponent,
    attachCallbackToSuccessComponent,
}