import React from 'react';
import {Switch} from "@material-ui/core";
import {styled} from '@material-ui/core/styles';


const CustomSwitch = styled(Switch)(({theme}) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        backgroundColor: 'white',
        border: '1px solid',
        borderColor: (props) => props.disabled? 'black' : theme.palette.primary.main,
        '&:before, &:after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        }

    },
    '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#fff',
            }
        }
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
        color: (props) => props.disabled? theme.palette.primary.disabled : theme.palette.primary.main,
    }
}));

function StyledSwitch({onChange, ...props}) {
    return (<CustomSwitch {...props}  onChange={onChange}  />)
}

export default StyledSwitch;