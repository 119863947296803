import { DAILY_REPORT, DAILY_TOP_DETECTIONS_REPORT, EMAIL, SMS, SYSLOG, WEEKLY_REPORT } from '../constants/integrationTypes';
import { activateDailyReportIntegration } from '../services/dailyReportRequests';
import { activeDailyTopDetectionsReportIntegration } from '../services/dailyTopDetectionsReport';
import { activateEmailIntegration } from '../services/emailRequests';
import { activateSmsIntegration } from '../services/smsRequests';
import { activateSyslogIntegration, deleteSyslogIntegration } from '../services/syslog';
import { activateWeeklyReportIntegration } from '../services/weeklyReportRequests';

const arrangeEnabledIntegrations = availableIntegrations => {
	const integrationsEnabledObj = {};
	// eslint-disable-next-line guard-for-in
	for (const integrationName in availableIntegrations) {
		integrationsEnabledObj[integrationName] = availableIntegrations[integrationName].isActive;
	}

	return integrationsEnabledObj;
};

const activateIntegrationsByType = (type, isEnabled, reportIds) => {
	switch (type) {
		case EMAIL:
			return activateEmailIntegration(isEnabled);
		case SMS:
			return activateSmsIntegration(isEnabled);
		case DAILY_TOP_DETECTIONS_REPORT:
			return activeDailyTopDetectionsReportIntegration(isEnabled);
		case DAILY_REPORT:
			return activateDailyReportIntegration(reportIds.dailyReport, isEnabled);
		case WEEKLY_REPORT:
			return activateWeeklyReportIntegration(reportIds.weeklyReport, isEnabled);
		case SYSLOG:
			return activateSyslogIntegration(isEnabled);
		default:
			break;
	}
};

const deleteIntegrationsByType = {
	[SYSLOG]: deleteSyslogIntegration,
};

export {
	activateIntegrationsByType, arrangeEnabledIntegrations, deleteIntegrationsByType
};

