import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    topContainer: {
        height: '100%',
        padding: '5px 33px'
    },

    displayInlineFlex: {display: 'inline-flex'},

    textBlockTitle: {
        fontSize: '14px',
        color: '#A8A6A6',
        fontWeight: '400 !important'
    },

    productName: {
        fontSize: '20px',
        fontWeight: '600',
        fontStyle: 'normal'
    },

    boldName: {
        fontWeight: '600'
    },

    bigText: {
        fontSize: '14px'
    },

    smallText: {
        fontSize: '12px'
    },

    ccDivider: {margin: '0px 5px', color: '#979797'},

    icon: {
        '&>img': {
            width: 'unset',
            margin: '0'
        }
    },

    updateButtonSection: {
        alignSelf: 'center'
    },

    button: {
        width: '160px',
        float: 'right'
    },
    subscriptionHeaderTopContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },

    subscriptionNameSection: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%'
    },

    textFrame: {
        display: 'flex',
        flexDirection: 'column',
        '&>*':{
            paddingTop: '8px'
        }
    }
}));

export {useStyles}
