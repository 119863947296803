import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useStyles } from './simpleFormContainer.css';

export default class SimpleFormContainer extends Component {
	render() {
		return (
			<div className="modal-form-container">
				<div className="modal-login-text-wrapper">
					<div className="modal-login-text">
						{this.props.header}
					</div>
				</div>
				{this.props.children}
			</div>
		);
	}
}

SimpleFormContainer.propTypes = {
	children: PropTypes.node,
	header: PropTypes.node,
};

export class SimpleFormContainerConfig extends Component {
	render() {
		return (
			<div className="modal-form-container-config">
				{this.props.children}
			</div>
		);
	}
}

SimpleFormContainerConfig.propTypes = {
	children: PropTypes.node,
};

/***
 *
 * @param title - title of the form
 * @param titleStyle - *optional custom style
 * @param childrenStyle - *optional children style
 * @param childrenContainerStyle - *optional children container style
 * @returns {JSX.Element}
 */
export const FormWrapper = ({ title,
	titleStyle,
	subTitle,
	subTitleStyle,
	childrenStyle,
	childrenContainerStyle,
	wrapperStyle,
	wrapperClass,
	children }) => {
	const classes = useStyles();
	return (
		<Grid container alignItems="flex-start" className={`${!wrapperStyle && classes.wrapperStyle} ${wrapperClass && wrapperClass}`} style={wrapperStyle && wrapperStyle}>
			{title && <Grid item xs={12} className={titleStyle ? titleStyle : classes.titleStyle}>
				{title}
			</Grid>}
			{subTitle
            && <Grid item xs={12} className={subTitleStyle ? subTitleStyle : classes.subTitleStyle}>{subTitle}</Grid>}
			<Grid item xs={12} className={childrenContainerStyle && { ...childrenContainerStyle }}>
				{React.Children.toArray(children).map((child, index) => (
					<div key={index} className={childrenStyle ? childrenStyle : classes.childrenStyle}>
						{child}
					</div>))}
			</Grid>
		</Grid>);
};

FormWrapper.propTypes = {
	title: PropTypes.string,
	titleStyle: PropTypes.object,
	subTitle: PropTypes.string,
	subTitleStyle: PropTypes.object,
	childrenStyle: PropTypes.object,
	childrenContainerStyle: PropTypes.object,
	wrapperStyle: PropTypes.object,
	wrapperClass: PropTypes.string,
	children: PropTypes.node,
};

