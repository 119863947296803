import React, {useEffect, useState} from 'react';
import clsx from "clsx";
import {useStyles} from "./passwordField.css";
import {calculatePasswordStrength} from "../../../containers/resetPassword/passwordUtils";

function StrengthMeter({password}) {
    const classes = useStyles();
    const [strength, setStrength] = useState({id: 0, value: ''});

    useEffect(() => {
        const calculatedStrength = calculatePasswordStrength(password);
        setStrength(calculatedStrength);
    }, [password])

    return (
        <div className={classes.containerWidth}>
            <div className={clsx(classes.strengthMeter, classes[`strengthMeterFill${strength.id}`])}></div>
            <span
                className={clsx(classes.meterLabel, classes[`strengthMeterSpan${strength.id}`])}>{strength.value}</span>
        </div>
    )

}


export default StrengthMeter;