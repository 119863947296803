import Grid from "@material-ui/core/Grid";
import {Field,getFormValues} from "redux-form";
import TextField from "../../../../shared/components/form/TextField/TextField";
import React from "react";
import Dropdown from "../../../../shared/components/dropdown/dropdown";
import countries from "../../../../shared/datasets/countries";
import states from "../../../../shared/datasets/states";
import {useSelector} from "react-redux";
import {RenderPhoneInputField} from "../../../integrations/view/modals/fieldArrayComponents/smsArrayField";
import {useStyles} from "./billingForm.css";
import emailValidation from "../../../integrations/controller/validations/emailInputValidation";
import {phoneNumberValidation, stateFieldValidation} from "../../validation/paymentMethodValidations";
import Typography from "@material-ui/core/Typography";

const BillingForm = ({change}) => {
    const classes = useStyles();
    const selectedCountry = useSelector(state => getFormValues('checkoutForm')(state)['country']);
    const selectedState= useSelector(state => getFormValues('checkoutForm')(state)['state']);
    const countryOptions = countries.map((country)=>{
        return {label: country.name, value: country.code}
    });
    const stateOptions = states.map(state => {
        return {label: state.name, value: state.abbreviation}
    });

    const changeCountryInput = (value) => {
        change('country', value);
        if (value !== 'US' && selectedState) {
            change('state', null);
        }
    }

    const changeStateInput = (value) => {
        change('state', value)
    }

    const inputStyle = {
        height: "45px",
        width: "100%"
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant={"body1"} color={"textSecondary"}>Billing</Typography>
            </Grid>

            <Grid item xs={6}>
                <Field
                    component={ TextField }
                    required={true}
                    label="Email"
                    name="email"
                    type="email"
                    margin="none"
                    validate={ emailValidation }
                    InputProps={{style: inputStyle}}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ TextField }
                    required={true}
                    label="Address Line 1"
                    name="addressLine1"
                    type="text"
                    margin="none"
                    InputProps={{style: inputStyle}}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ TextField }
                    label="Address Line 2"
                    name="addressLine2"
                    type="text"
                    margin="none"
                    InputProps={{style: inputStyle}}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ Dropdown }
                    required
                    options={countryOptions}
                    label="Country"
                    name="country"
                    onFieldChange={changeCountryInput}
                    displayError={true}
                    type="text"
                    margin="none"
                    InputProps={{style: inputStyle}}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ TextField }
                    required={true}
                    label="City"
                    name="city"
                    type="text"
                    margin="none"
                    InputProps={{style: inputStyle}}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ Dropdown }
                    required={selectedCountry==='US'}
                    isDisabled={selectedCountry!=='US'}
                    onFieldChange={changeStateInput}
                    displayError={true}
                    options={stateOptions}
                    label="State"
                    name="state"
                    type="text"
                    margin="none"
                    InputProps={{style: inputStyle}}
                    validate={stateFieldValidation}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ TextField }
                    required={true}
                    label="Zipcode"
                    name="zipcode"
                    type="text"
                    margin="none"
                    InputProps={{style: inputStyle}}
                />
            </Grid>
            <Grid item xs={6}>
                <Field
                    component={ RenderPhoneInputField }
                    label="Phone number"
                    name="phoneNumber"
                    type="text"
                    margin="none"
                    isDefaultDisplayed={false}
                    className={classes.input}
                    InputProps={{style: inputStyle}}
                    validate={phoneNumberValidation}
                />
            </Grid>
        </Grid>
    )
}

export default BillingForm;