import React from "react";
import alertsBarSide from "../../../../../../assets/walkthrough/customAlerts/steps/myAlertsLocation.png";
import alertsBarMain from "../../../../../../assets/walkthrough/customAlerts/steps/alertsBar.png";
import alertIndex from "../../../../../../assets/walkthrough/customAlerts/steps/alertsTriggerIndex.png";
import {STEP_3_DESCRIPTION, STEP_3_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV3
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV3/mainPanelTemplateV3";

const Step3 = () => {
    return (
        <StepTemplate sidePanelImage={alertsBarSide}>
            <MainPanelTemplateV3 title={STEP_3_TITLE}
                                 image={<img src={alertsBarMain} height={"324px"} width={"273px"}/>}
                                 description={STEP_3_DESCRIPTION}
                                 subDescriptionImage={<img src={alertIndex} alt={"Alert trigger"}/>}/>
        </StepTemplate>
    )
}

export default Step3;