import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    cloudProviderSelector: {
        width: '540px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '24px'
    },
    loader: {
        height: '500px',
        display: 'flex',
        alignContent: 'center'
    },
    titleStyle:{
        marginBottom: theme.spacing(3),
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.palette.secondary.main
    },
}));

export {useStyles}
