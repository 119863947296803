import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	rootPaper: { width: 636, height: '100%', paddingLeft: 50, paddingTop: 20 },
	formContainer: { minWidth: '92%', height: '100%' },
	gridContainer: { minWidth: '100%', height: '100%' },
	gridItem: { minWidth: '100%', margin: '12px 0' },
	deviceTypeText: { fontWeight: 'bold' },
}));

export { useStyles };
