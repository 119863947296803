import moment from "moment";
import Validation from "../../../../services/validation";

const validateQueryForm = (formData, processes, maxSegmentDurationInHours, maxProcessesCount) => {
    let error = "";
    if (processes.length === maxProcessesCount) {
        error = `You can run up to ${maxProcessesCount} queries at the same time`;
    } else {
        error = validateQueryTimes(formData, maxSegmentDurationInHours);
    }
    return error;
};

const validateQueryTimes = (formData, maxSegmentDurationInHours) => {
    let error = "";
    const { datefrom, dateto } = formData;

    if (dateto.isAfter(moment()) || datefrom.isAfter(moment())) {
        error = "Time segment must be in the past";
    } else if (datefrom.isAfter(dateto)) {
        error = `Time segment can't be reversed`;
    } else if (dateto.diff(datefrom, "hours") > maxSegmentDurationInHours) {
        error = `Time segment can't be longer than ${maxSegmentDurationInHours} hours`;
    }
    return error;
};

const warningQueryForm = (state) => {
    const warnings = {};

    const { datefrom, dateto } = state;
    const fromMoment = moment(datefrom);
    const toMoment = moment(dateto);
    const maxMoment = moment(fromMoment).add(5, "hours");
    if (toMoment.isAfter(maxMoment)) {
        warnings["dateto"] = "Time segment can't be longer than 5 hours.";
        warnings["datefrom"] = "Time segment can't be longer than 5 hours.";
    }
    if (fromMoment.isAfter(moment())) {
        warnings["dateto"] = "Time segment can't be in the future";
    }
    if (toMoment.isAfter(moment())) {
        warnings["datefrom"] = "Time segment can't be in the future";
    }

    return warnings;
};

// This function validates the end time in case it's in the future - sets it as now
// possible on link creation from threats
const validateLinkQueryFutureTimes = (content) => {
    const { dateto } = content;
    if (moment(dateto).isAfter(moment())) {
        return {
            ...content,
            dateto: moment(),
        };
    }
    return content;
};

const validateNumericSelector = (value) => {
    return (
        !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(value)) && // ...and ensure strings of whitespace fail
        value >= 0
    );
};
const validatePort = (value) => Validation.portRegex.test(value);
const validateIp = (value) => Validation.ipRegex.test(value);
const validateMac = (value) => Validation.macRegex.test(value);
const validateIpOrMac = (value) => validateIp(value) || validateMac(value.toUpperCase());

export {
    validateQueryForm,
    warningQueryForm,
    validateLinkQueryFutureTimes,
    validateQueryTimes,
    validateNumericSelector,
    validateMac,
    validateIp,
    validateIpOrMac,
    validatePort
};
