import http from "../../../../services/httpService/http";
import {GROUPS_PATH} from "./urls";


const getGroups = () => {
    return http.get(GROUPS_PATH);
}
const updateGroup = (groupId, newGroup) => {
    return http.put(`${GROUPS_PATH}/${groupId}`, newGroup);
}
const deleteGroup = (groupId) => {
    return http._delete(`${GROUPS_PATH}/${groupId}`);
}
const addGroup = (newGroup) => {
    return http.post(GROUPS_PATH, newGroup)
}
export {
    getGroups,
    updateGroup,
    deleteGroup,
    addGroup
}
