import { CircularProgress, Grid } from '@material-ui/core';
import React, { Component, useEffect } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import { format, numericality, required, validateForm } from 'redux-form-validators';
import CheckboxInput from '../../../../shared/components/checkboxInput/CheckboxInput';
import Dropdown from '../../../../shared/components/dropdown/dropdown';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../shared/components/form/TextField/TextField';
import FormContainer, { FormWrapper } from '../../../../shared/components/simpleTable/simpleFormContainer';
import SimpleModal from '../../../../shared/components/simpleTable/simpleModal';
import { osType } from '../../controller/constantes/osType';
import { defaultValueipFix, defaultValueNetflow, defaultValueSFlow } from '../../controller/constantes/portvalues';
import { collectorTypes } from '../devicesManagementConfig';

const INITIAL_VALUES = {
	isNetFlow: true,
	isSFlow: true,
	isIpFix: true,
	isNextAvailable: false,
	isCollectorName: false,
	isOsName: false,
	ipfix: defaultValueipFix,
	netflow: defaultValueNetflow,
	sflow: defaultValueSFlow,
};

let CollectorForm = props => {
	const { modalOpen, reset, handleSubmit, change, isNetFlow, isSFlow, isIpFix, onSubmit, isSubmittingForm, wrapperStyle } = props;

	useEffect(() => {
		modalOpen === false && reset();
	}, [modalOpen]);

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="modal-form">
			<FormWrapper title="Collector properties" wrapperStyle={wrapperStyle}>
				<Field
					component={TextField}
					label="Collector name"
					className="form-field"
					name="collectorName"
					type="text"
				/>

				<Field
					component={Dropdown}
					options={osType.filter(option => option.supportedBy[collectorTypes.LEGACY_COLLECTOR_TYPE])}
					className="form-field"
					name="osName"
					label={'Choose the OS'}
					onFieldChange={value => {
						change('osName', value);
					}}
				/>
				<Field
					component={TextField}
					label="Collector private IP"
					className="form-field"
					name="privateIP"
					placeHolder={'Collector Private IP'}
				/>
				<Grid container direction="row" alignItems="stretch" spacing={2}>
					<Grid item xs={1}>
						<CheckboxInput name={'isNetFlow'} label={''} formName={'collectorForm'}/>
					</Grid>
					<Grid item xs={3}>
						<Field
							disabled={!isNetFlow}
							component={TextField}
							label="NetFlow"
							className="form-field"
							required
							name="netflow"
							type="number"
							margin="none"/>
					</Grid>
					<Grid item xs={1}>
						<CheckboxInput name={'isSFlow'} label={''} formName={'collectorForm'}/>
					</Grid>
					<Grid item xs={3}>
						<Field
							disabled={!isSFlow}
							component={ TextField }
							label="SFlow"
							className="form-field"
							required
							name="sflow"
							type="number"
							margin="none" />
					</Grid>
					<Grid item xs={1}>
						<CheckboxInput name={'isIpFix'} label={''} formName={'collectorForm'}/>
					</Grid>
					<Grid item xs={3}>
						<Field
							disabled={!isIpFix}
							component={ TextField }
							label="IpFix"
							className="form-field"
							required
							name="ipfix"
							type="number"
							margin="none" />
					</Grid>
				</Grid>

				<Field
					component={TextField}
					name="status"
					type="hidden"
					hidden
				/>
			</FormWrapper>
			<div style={{ marginBottom: '30px' }}>
				{isSubmittingForm ? (
					<CircularProgress color="primary" />
				) : (
					<SubmitButton label="Save" />
				)}
			</div>
		</form>
	);
};

const ipValidator = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
const validate = validateForm({
	privateIP: [required({ msg: 'Please enter IP' }), format({ with: ipValidator, msg: 'Please enter a valid IP' })],
	collectorName: [required({ msg: 'Please the Collector Name' })],
	osName: [required({ msg: 'Please enter select the Collector OS' })],
});

CollectorForm = reduxForm({
	enableReinitialize: true,
	form: 'collectorForm',
	initialValues: INITIAL_VALUES,
	validate,
})(CollectorForm);

const selector = formValueSelector('collectorForm'); // <-- same as form name
CollectorForm = connect(state => {
	const isNetFlow = selector(state, 'isNetFlow');
	const isSFlow = selector(state, 'isSFlow');
	const isIpFix = selector(state, 'isIpFix');
	return {
		isNetFlow,
		isSFlow,
		isIpFix,
	};
})(CollectorForm);

export default CollectorForm;
