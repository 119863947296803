import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
/**
 * 
 * TO CHANGE FOR ON MAINTENANT -> REMOVE ALL THE DEVICE TAB => CHANGE IT TO CYNAMICS TAB 
 * 
 */
const DeviceTab = withStyles({
    indicator: {
        backgroundColor: '#7287a5'
    },
})(Tabs);

export {
    DeviceTab
}
