import React from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import TextField from "../../../shared/components/form/TextField/TextField";
import SubmitButton from "../../../shared/components/form/Buttons/SubmitButton";
import { email, required } from "../../../shared/components/form/Validation/FieldLevelValidation";
import {Grid} from "@material-ui/core";

const GetResetLinkForm = (props)=> {
    const {invalid, onSubmit, handleSubmit} = props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container direction={'column'}>
                    <Grid xs={12}>
                        <Field
                            component={TextField}
                            label="Email"
                            className="form-field"
                            name="email"
                            type="text"
                            validate={[required, email]}
                        />
                    </Grid>
                    <Grid xs={9}>
                        <SubmitButton label='Submit'
                                      isDisabled={invalid}
                                      className="form-submit"
                        />
                    </Grid>
                </Grid>
            </form>
        );
}

export default reduxForm({
    form: 'get-reset-link-form',
    fields: ['email']
})(GetResetLinkForm);
