import React from 'react';
import './notificationBox.scss';
import { CSSTransition } from 'react-transition-group';
import Notification from '../alertNotifications/Notification';
import { removeNotification, removeAnomaly } from '../../../backgroundProcess/controller/redux/actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { VCA_NOTIFICATION_TYPE, fetchVcaDetections } from '../../controller/services/vca/vcaService';
import { updateDetection } from '../../../vcaManagement/services/vcaRequests';
import EmptyBox from '../emptyStateBox/EmptyBox';
import Brain from '../../../../assets/virtual-cyber-analyst/brain.png';
import PropTypes from 'prop-types';
import {
	actionTypeToPayload,
	detectionActionTypes
} from "../../../../shared/components/detectionsTable/detectionsTableConfig";

class NotificationBox extends React.Component {
	static defaultProps = {
		summaryData: {
			notifications: [],
			anomalies: [],
		},
	};

	constructor(props) {
		super(props);
		const notifications = props.summaryData.notifications ? props.summaryData.notifications : [];
		const anomalies = props.summaryData.anomalies ? props.summaryData.anomalies : [];

		this.state = {
			notifications,
			newNotification: null,
			anomalies,
			vcaDetections: [],
		};
		this.currentTimeout = {
			id: null,
			callback() {
			},
		};
		this.handleNewNotification = this.handleNewNotification.bind(this);
	}

	componentDidMount() {
		this.getVcaDetections();
	}

	componentDidUpdate(prevProps) {
		let { notifications } = this.props.summaryData;
		const prevNotifications = prevProps.summaryData.notifications || [];
		notifications = notifications || [];

		let { anomalies } = this.props.summaryData;
		const prevAnomalize = prevProps.summaryData.anomalies || [];
		anomalies = anomalies || [];

		if (anomalies.length > prevAnomalize.length) {
			this.handleNewNotification(anomalies[0]);
		}

		if (notifications.length > prevNotifications.length) {
			this.handleNewNotification(notifications[0]);
		}
	}

	getVcaDetections = async () => {
		const vcaDetections = await fetchVcaDetections();
		this.setState(prevState => ({
			...prevState,
			vcaDetections,
		}));
	};

	handleNewNotification = newNotification => {
		if (this.currentTimeout.id) {
			clearTimeout(this.currentTimeout.id);
			this.currentTimeout.callback();
		}

		this.setState({ newNotification }, () => {
			const callback = () => {
				this.currentTimeout.id = null;
				this.setState({
					newNotification: null,
				});
			};

			this.currentTimeout = {
				id: setTimeout(callback, 3000),
				callback,
			};
		});
	};

	removeItem = notification => {
		if (_.has(notification, 'groupId')) {
			this.props.removeAnomaly(notification.id);
		} else if (notification.type === VCA_NOTIFICATION_TYPE) {
			this.removeVcaDetection(notification.id);
		} else {
			this.props.removeNotification(notification.id);
		}
	};

	removeVcaDetection = id => {
		const updatedVcaCollection = this.state.vcaDetections.filter(detection => detection.id !== id);
		this.setState(prevState => ({
			...prevState,
			vcaDetections: updatedVcaCollection,
		}));
		updateDetection(id, actionTypeToPayload[[detectionActionTypes.DELETE]]);
	};

	render() {
		const { summaryData } = this.props;
		const { anomalies, removedANOMALISES, notifications } = summaryData;
		const { newNotification, vcaDetections } = this.state;
		let allNotification = _.sortBy(notifications.concat(anomalies).concat(vcaDetections), e => -e.time);

		if (!_.isEmpty(newNotification)) {
			allNotification = _.filter(allNotification, e => e.id !== newNotification.id);
		}

		allNotification = allNotification.filter(e => !removedANOMALISES.includes(e.id));
		const noNofitications = _.isEmpty(allNotification) && _.isEmpty(newNotification) && allNotification.length === 0;
		const emptyBoxConfig = this.props.generateEmptyOverviewBoxesConfig(noNofitications);
		return (
			<div className="notifications">
				<div className="title">
					<div className="title-and-icon">
						<img src={Brain} className="general-box-icon" alt="Threats" />
						<div>
                    Virtual Cyber Analyst
						</div>
					</div>
				</div>
				<div className="insights-notifications" style={{ height: window.innerHeight * 39 / 100 }}>

					{/* Threats */}
					{((notifications && notifications.length) || newNotification)
                    && <CSSTransition in={Boolean(this.state.newNotification)} timeout={400} classNames="fade">
                    	<div>
                    		<Notification isNew={true}
                    			showNewNotification={Boolean(this.state.newNotification)}
                    			notification={this.state.newNotification}/>
                    	</div>
                    </CSSTransition>}

					{noNofitications
						? <EmptyBox title={emptyBoxConfig.alerts.title} description={emptyBoxConfig.alerts.description} image={emptyBoxConfig.alerts.image}/>
						: <>
							{allNotification
								.map((notification, i) => <Notification isNew={false}
									showNewNotification={false}
									key={i}
									removeNotification={() => this.removeItem(notification)}
									notification={notification}/>)}
						</>}
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	summaryData: state.analyze.summaryData,

});

const mapDispatchToProps = {
	removeNotification,
	removeAnomaly,
};

NotificationBox.propTypes = {
	summaryData: PropTypes.object,
	removeAnomaly: PropTypes.func,
	removeNotification: PropTypes.func,
	generateEmptyOverviewBoxesConfig: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBox);
