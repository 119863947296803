import React, { useState } from 'react';
import { Box, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './textHoverPopover.css';

const TextHoverPopover = ({ text, children, disabled, ...tooltipProps }) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Box>
			<Tooltip title={children} classes={{ tooltip: classes.tooltip }} disableHoverListener={disabled} {...tooltipProps}>
				<Typography
					aria-owns={open ? 'mouse-over-popover' : undefined}
					aria-haspopup="true"
					onMouseEnter={ !disabled && handlePopoverOpen}
					onMouseLeave={handlePopoverClose}
					className={classes.text}
				>
					{text}
				</Typography>
			</Tooltip>
		</Box>
	);
};

TextHoverPopover.defaultProps = {
	disabled: false,
};

TextHoverPopover.propTypes = {
	text: PropTypes.string.isRequired,
	children: PropTypes.node,
	disabled: PropTypes.bool,
};

export default TextHoverPopover;
