import {CONDENSE_FORM ,EXPAND_FORM ,TOGGLE_EXPAND_FORM} from "./actionTypes";

const toggleExpandForm = () => {
    return {
        type: TOGGLE_EXPAND_FORM,
        payload: {}
    }
}

const expandForm = () => {
    return {
        type: EXPAND_FORM,
        payload: {}
    }
}

const condenseForm = () => {
    return {
        type: CONDENSE_FORM,
        payload: {}
    }
}

export {
    toggleExpandForm,
    expandForm,
    condenseForm
}