import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import CynamicsLogo from '../../../../../assets/cynamics-logo.svg';
import AccountMenuSection from '../../../../accountMenu/accountMenuSection';
import SidebarContent from './SidebarContent';

const sidebarClass = classNames({
	sidebar: true,
	'sidebar--show': true,
	'sidebar--collapse': false,
});

const Sidebar = ({ links, changeSidebarVisibility }) => {
	const customizedLogoPath = useSelector(state => state.account.customizedLogoPath);

	return (
		<div className={sidebarClass}>
			<div className="sidebar-main">
				<NavLink exact to={'/'} className="sidebar-logo">
					{customizedLogoPath
						? <img src={customizedLogoPath} alt="" style={{ height: '16px' }}/>
						: <>
							<img src={CynamicsLogo} alt="" className="logo"/>
							<div className="title">
                                Cynamics
							</div>
						</>}

				</NavLink>
				<div className="sidebar__wrapper sidebar__wrapper--desktop">
					<SidebarContent
						links={links}
						changeSidebarVisibility={changeSidebarVisibility}
					/>
				</div>
			</div>
			<div className="sidebar-bottom">
				<AccountMenuSection/>
			</div>
		</div>
	);
};

Sidebar.propTypes = {
	changeSidebarVisibility: PropTypes.func,
	links: PropTypes.array,
};
export default Sidebar;
