import React from "react";
import step5Side from "../../../../../../assets/walkthrough/overview/steps/step5side.png";
import step5Main from "../../../../../../assets/walkthrough/overview/steps/step5main.png";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "../step4/step4.css";
import MainPanelTemplateV2
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const Step5 = () => {
    const classes = useStyles();
    const description = (
        <>
            Summary of your custom-alerts (see more details in "Alerts" page)
        </>
    )
    return (
        <StepTemplate sidePanelImage={step5Side}>
            <MainPanelTemplateV2 title="Custom alerts"
                                 image={<img src={step5Main}/>}
                                 description={description}/>
        </StepTemplate>
    )
}

export default Step5;