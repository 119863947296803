import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    submitButton: {
        width: '50%',
        height: '50px',
        display: 'block',
        marginRight: 'auto',
        marginLeft: 'auto'
    },
    formAlignment: {
        display: 'flex',
        alignContent: 'center',
        height: '100%',
        width: '100%'
    }
}));

export {useStyles}