import EndpointsTable from '../../endpointsTable/endpointsTable';
import { LoadingTableBody } from '../../endpointsTable/TableSkeleton';
import FullTableBody from './TableBody';
import { useStyles } from '../EndpointsDeviation.css';
import React from 'react';
import PropTypes from 'prop-types';
import { endpointsDeviationColumns } from '../EndpointsDeviationConfig';

const EndpointsDeviationTable = ({ endpointsDeviationRows, isLoading }) => {
	const classes = useStyles();
	return (
		<div className={classes.card}>
			<div className={classes.tableWrapper}>
				<EndpointsTable columns={endpointsDeviationColumns}>
					{
						isLoading ? <LoadingTableBody columns={endpointsDeviationColumns} rowLength={6}/>
							: <FullTableBody classes={classes} rows={endpointsDeviationRows}/>
					}
				</EndpointsTable>
			</div>
		</div>
	);
};

EndpointsDeviationTable.propTypes = {
	endpointsDeviationRows: PropTypes.array,
	isLoading: PropTypes.bool,
};
EndpointsDeviationTable.defaultValue = {
};

export default EndpointsDeviationTable;
