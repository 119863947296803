import { DEVICE_CATEGORY } from '../../../../helpers/analyzerConstants';
import CollectorForm from '../collector/collectorForm';
import SamplerCreationStepper from '../collector/samplerModal/SamplerCreationStepper';
import CoreDeviceForm from '../collectorDevice/collectorDeviceForm';
import CloudDeviceFormContainer from '../publicCloudDevice/publicCloudForms/publicCloudDeviceFormContainer';
import publicDeviceForm from '../publicDevice/publicDeviceForm';
import { addCloudDevice, addCollector, addFirewallDevice, addSampler, addSwitchDevice, editCloudDevice, editFirewallDevice, editSwitchDevice } from './submissionFunctions';

export const ANALYZER_CATEGORY = DEVICE_CATEGORY;

export const CREATE_MODE = 'create';
export const EDIT_MODE = 'edit';

export const DEVICE_EDIT_MODAL_TEXT = 'Edit device';
export const DEVICE_CREATE_MODAL_TEXT = 'Add device';
export const LEGACY_COLLECTOR_CREATE_MODAL_TEXT = 'Add collector';
export const SAMPLER_CREATE_MODAL_TEXT = 'Add sampler';

export const FIREWALL_DEVICE_TYPE = 'firewall';
export const SWITCH_DEVICE_TYPE = 'switch';
export const CLOUD_DEVICE_TYPE = 'cloud';

export const LEGACY_COLLECTOR_TYPE = 'collector';
export const SAMPLER_TYPE = 'sampler';

export const ADD_LEGACY_COLLECTOR_STEP = 'addLegacyCollector';
export const ADD_SAMPLER_STEP = 'addSampler';
export const ADD_DEVICE_STEP = 'addDevice';

export const DEVICE_SAVE_SUCCESS_MESSAGE = 'Device details were added to the dashboard';
export const COLLECTOR_SAVE_SUCCESS_MESSAGE = 'Collector details were added to the dashboard';
export const COLLECTOR_SAVE_ERROR_MESSAGE = 'Something went wrong. Try again\'';
export const DEVICE_EDIT_SUCCESS_MESSAGE = 'Device details were edited successfully';
export const DEVICE_ERROR_MESSAGE = 'Something went wrong. Try again';

export const DEVICE_TYPES = [
	{ label: 'Firewall', value: FIREWALL_DEVICE_TYPE },
	{ label: 'Switch', value: SWITCH_DEVICE_TYPE },
	{ label: 'Cloud', value: CLOUD_DEVICE_TYPE },
];

export const HEADER_TEXT = {
	[ADD_DEVICE_STEP]: {
		[CREATE_MODE]: DEVICE_CREATE_MODAL_TEXT,
		[EDIT_MODE]: DEVICE_EDIT_MODAL_TEXT,
	},
	[ADD_LEGACY_COLLECTOR_STEP]: {
		[CREATE_MODE]: LEGACY_COLLECTOR_CREATE_MODAL_TEXT,
	},
	[ADD_SAMPLER_STEP]: {
		[CREATE_MODE]: SAMPLER_CREATE_MODAL_TEXT,
	},
};

export const DEVICE_FORMS = {
	[FIREWALL_DEVICE_TYPE]: publicDeviceForm,
	[SWITCH_DEVICE_TYPE]: CoreDeviceForm,
	[CLOUD_DEVICE_TYPE]: CloudDeviceFormContainer,
};

export const FORMS_EXTRA_PROPS = {
	[FIREWALL_DEVICE_TYPE]: {
		form: 'create-device',
		initialValues: { status: 'active', samplingRate: 1 },
	},
	[SWITCH_DEVICE_TYPE]: {
		form: 'create-core-device',
		initialValues: { status: 'active', samplingRate: 1 },
	},
	[CLOUD_DEVICE_TYPE]: {
		form: 'cloud-device',
		initialValues: {},
	},
	[LEGACY_COLLECTOR_TYPE]: {
		form: 'collectorForm',
		wrapperStyle: { paddingRight: '50px', display: 'flex', alignItems: 'center', flexDirection: 'column', whiteSpace: 'nowrap' },
		modalOpen: true,
		mode: 'add',
	},
	[SAMPLER_TYPE]: {
		containerStyle: { paddingRight: '50px', paddingBottom: '20px' },
	},
};

export const DEVICE_SUBMISSION_MAP = {
	[FIREWALL_DEVICE_TYPE]: {
		[CREATE_MODE]: addFirewallDevice,
		[EDIT_MODE]: editFirewallDevice,
	},
	[SWITCH_DEVICE_TYPE]: {
		[CREATE_MODE]: addSwitchDevice,
		[EDIT_MODE]: editSwitchDevice,
	},
	[CLOUD_DEVICE_TYPE]: {
		[CREATE_MODE]: addCloudDevice,
		[EDIT_MODE]: editCloudDevice,
	},
};

export const COLLECTOR_FORMS = {
	[LEGACY_COLLECTOR_TYPE]: CollectorForm,
	[SAMPLER_TYPE]: SamplerCreationStepper,
};

export const COLLECTOR_SUBMISSION_MAP = {
	[LEGACY_COLLECTOR_TYPE]: addCollector,
	[SAMPLER_TYPE]: addSampler,
};

