import React from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';

const PaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
	const handleBackButtonClick = event => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = event => {
		onPageChange(event, page + 1);
	};

	const getTotalPageCount = () => Math.ceil(count / rowsPerPage);

	return (
		<div style={{ width: '100%' }}>
			<IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
				<KeyboardArrowLeft />
			</IconButton>
			{`${page} of ${getTotalPageCount()}`}
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage)}
				aria-label="next page">
				<KeyboardArrowRight />
			</IconButton>
		</div>
	);
};

PaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

export default PaginationActions;
