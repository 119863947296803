import React, { Component } from 'react';
import {Grid} from "@material-ui/core";
import * as d3 from "d3-format";
import { minMaxformater } from '../../../../../../../constants/formater';
import { useStyles } from './dayStatistics.css'

const DayStatistics = ({ alertsCounter, minValue, maxValue, diffFromAveragePercent }) => {
    const sign = diffFromAveragePercent > 0 ? '+' : diffFromAveragePercent == 0 && '';
    const classes = useStyles();

    return (
        <Grid container className={classes.gridContainer}>
            <Grid container direction={"column"} item xs={8} >
                <Grid xs={4} className={classes.alertsGridItem}>
                    {alertsCounter} alerts
                </Grid>
                <Grid xs={2} className={classes.gridItem}>
                    Min: { d3.format(minMaxformater)(minValue) }
                </Grid>
                <Grid xs={2} className={classes.gridItem}>
                    Max: { d3.format(minMaxformater)(maxValue) }
                </Grid>
            </Grid>
            <Grid item xs={4} className={sign == '+' ? classes.positiveDiffFromAverage : classes.negativeDiffFromAverage}>
                {sign}{diffFromAveragePercent}%
            </Grid>
        </Grid>
    );
}

export default DayStatistics;