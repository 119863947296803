import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '800px',
        height: '400px'
    },
    skipButton: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        position: 'absolute',
        right: 10,
        top: 10,
        cursor: 'pointer'
    }
}));

export {useStyles}