import React from 'react';
import './closeButton.scss';
import CloseIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';

function RoundCloseButton({ onClick, label }) {
	return (
		<IconButton onClick={onClick}>
			<CloseIcon/>
			{label && <span>{label}</span>}
		</IconButton>
	);
}

export default RoundCloseButton;
