import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    loading: {
        display: "none"
    },
    notLoading: {
        display: 'block'
    }
}));


export {useStyles}