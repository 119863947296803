import {makeStyles} from '@material-ui/core/styles';
import chat from '../../../../assets/chatIcon.svg';

const useStyles = makeStyles((theme) => ({
    chat: {
        height: '50px',
        width: '100%',
        padding: '10px',
        backgroundImage: `url(${chat})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },

    subTextForImage: {
        minHeight: '30px'
    },

    priceTitle: {
        fontSize: '30px'
    },

    boldCaption: {
        fontWeight: 'bold'
    },

    box: {
        height: '100px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },

    twoOptionPricePlan:{
      alignItems: (props) => props.align
    },

    switchContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'baseline',
        display: 'flex'
    },

    singleLineBox: {
        justifyContent: 'normal'
    }
}));

export {useStyles}