import { deviceTypes } from '../devicesManagementConfig';
import { PUBLIC_CLOUD_DEVICE_CONFIG } from '../publicCloudDevice/publicCloudDeviceConfiguration';
import { createDeviceConfiguration } from '../../controller/services/deviceRequests';
import { getPublicCloudDeviceConfig } from '../../controller/services/publicCloudDeviceRequests';
export const DOWNLOAD_LINK_MODE = 'download_link';

const deviceTypeMapping = {
	[deviceTypes.PUBLIC_DEVICE]: 'firewall',
	[deviceTypes.CORE_DEVICE]: 'switch',
	[deviceTypes.CLOUD_DEVICE]: 'cloud',
};

const defaultFormDeviceType = deviceTypeMapping[deviceTypes.PUBLIC_DEVICE];

const deviceConfigurationByType = {
	[deviceTypes.PUBLIC_DEVICE](deviceDetails) {
		return deviceDetails;
	},
	[deviceTypes.CORE_DEVICE](deviceDetails) {
		return deviceDetails;
	},
	[deviceTypes.CLOUD_DEVICE](deviceDetails) {
		return PUBLIC_CLOUD_DEVICE_CONFIG[deviceDetails.cloudProvider];
	},
};

export const LINK_DOWNLOAD_SUBMISSION_MAP = {
	[deviceTypes.PUBLIC_DEVICE]: {
		[DOWNLOAD_LINK_MODE]: createDeviceConfiguration,
	},
	[deviceTypes.CORE_DEVICE]: {
		[DOWNLOAD_LINK_MODE]: createDeviceConfiguration,
	},
	[deviceTypes.CLOUD_DEVICE]: {
		async [DOWNLOAD_LINK_MODE](deviceConfig) {
			return { data: await getPublicCloudDeviceConfig(deviceConfig._id) };
		},
	},
};

export {
	defaultFormDeviceType,
	deviceTypeMapping,
	deviceConfigurationByType,
};
