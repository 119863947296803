import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import CustomTooltip from '../../../../Tooltip/CustomTooltip';
import { getDeviceNameTooltip } from '../../../utils/DeviceMonitoringUtils';
import { TableCell, TableRow } from '@material-ui/core';
import { useStyles } from '../InactiveEndpoints.css';

const EmptyTableBody = ({ tableType }) => {
	const classes = useStyles();
	return (
		<Typography className={classes.noDataSingleTable}>{'No ' + tableType}</Typography>
	);
};

const FullTableBody = ({ columns, rows = [] }) => {
	const classes = useStyles();
	return (
		<>
			{rows.map(row => (
				<CustomTooltip title={getDeviceNameTooltip(row.deviceName)} className={classes.tooltip} key={row.id}>
					<TableRow className={classes.tableRow}>
						{columns.map(column => {
							const value = row[column.id];
							return (
								<TableCell key={column.id} align={column.align}>
									{value}
								</TableCell>
							);
						})}
					</TableRow>
				</CustomTooltip>
			))}
		</>
	);
};

FullTableBody.propTypes = {
	columns: PropTypes.array,
	rows: PropTypes.array,
};
FullTableBody.defaultValue = {
};
EmptyTableBody.propTypes = {
	tableType: PropTypes.string,
};
EmptyTableBody.defaultValue = {
};

export {
	FullTableBody,
	EmptyTableBody,
};
