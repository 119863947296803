import _ from 'lodash';
import moment from 'moment'
const threatSeverity = {
    'normal': 0,
    'medium': 1,
    'high': 2,
};

class SummaryData {
    constructor(maxSize = 100000) {
        this.maxSize = maxSize;
        this.queriesHistory = {}; // keep history info for summary data
        this.arr = [];
        this.notifications = [];
        this.anomalies = []
        this.notficationId = 0;
    }

    enqueue(queryType, section) {
        let data = this.queriesHistory[queryType] || [];
        let lastSection = data[data.length - 1];
        let latestTime = lastSection ? lastSection.points[lastSection.points.length - 1].x : 0;
        let newPoints = section.points.filter(point => point.x > latestTime);
        if (newPoints.length > 0) {
            if (lastSection && section.level === lastSection.level)
                data[data.length - 1].points = lastSection.points.concat(newPoints);
            else
                data.push(section);
            this.removeEarliest(data);
            this.queriesHistory[queryType] = data;
        } else {
            console.log('No POINT')
        }
        return data;
    }

    getDataset(queryType) {
        return this.queriesHistory[queryType] || [];
    }

    removeEarliest(data) {
        const numOfPoints = data.reduce((n, sec) => n + sec.points.length, 0);
        if (numOfPoints > this.maxSize) {
            data[0].points.shift();
            if (!data[0].points.length)
                data.shift();
        }
    }
    updateAnomalizes = (anomalies) => {
        this.anomalies = anomalies;
    }

    updateNotifications = (statuses, messages, devices, prevSummaryData, dispatch, _id, createdAt, prevStatuses) => {
        for (let id in devices){
            let key = id;
            let attackStatus = statuses[key] ? statuses[key] : 0;
            let message = messages[key] ? messages[key] : "";
            const lastAttackStatus = prevStatuses[key];

            if (attackStatus !== lastAttackStatus && attackStatus !== 0) {

                const severity = Object.keys(threatSeverity).find(key => threatSeverity[key] === attackStatus);
                const newNotification = {
                    id: _id,
                    severity,
                    attackStatus: attackStatus,
                    ip: devices[id].IP,
                    identifierValue: devices[id].identifierValue,
                    title: devices[id].name,
                    message: message,
                    time: moment(createdAt).valueOf(),
                };
                this.notifications.push(newNotification)
            }
        }
        this.notifications = _.uniqBy(this.notifications, 'id');
        return this.notifications;
    };
    removeNotification = (id) => {
        this.notifications = _.remove(this.notifications, n => n.id !== id)
        return this.notifications;
    };

    removeAnomaly = (id) => {
        this.anomalies = _.remove(this.anomalies, n => n.id !== id)
        return this.anomalies;
    };

    getArray() {
        return this.arr;
    }

    size() {
        return this.arr.length;
    }
}

export default new SummaryData(16);
