import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	select: {
		fontSize: '14px',
		borderColor: theme.palette.border.main,
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '4px',
		paddingLeft: '16px',
		paddingTop: '12px',
		paddingBottom: '12px',
		'&:hover': {
			borderColor: theme.palette.border.main,
		},
		'&:focus': {
			borderRadius: '4px',
			background: 'white',
			color: theme.palette.secondary.main,
		},
		'& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
			backgroundColor: 'transparent',
			padding: 0,
			height: '16.625px',
			fontSize: '14px',
		},
	},
	error: {
		marginLeft: '14px',
	},
	icon: {
		right: 12,
		position: 'absolute',
		userSelect: 'none',
		pointerEvents: 'none',
	},
	paper: {
		marginTop: 8,
		borderColor: theme.palette.border.main,
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '4px',
		maxHeight: '385px',
		boxShadow: 'none',
		overflowY: 'scroll',
	},
	list: {
		fontSize: '14px',
		color: theme.palette.inputText.main,
		paddingTop: 0,
		paddingBottom: 0,
		background: 'white',
		boxShadow: 'none',
		'& li': {
			paddingTop: 10,
			paddingBottom: 10,
		},
		'& li:hover': {
			background: theme.palette.selectorMenuHover.main,
		},
		'& li.Mui-selected': {
			color: 'white',
			background: theme.palette.primary.main,
			fontWeight: 'bold',
		},
		'& li.Mui-selected:hover': {
			background: theme.palette.primary.main,
		},
	},
	formControl: {
		width: '100%',
	},
}));

export { useStyles };
