import React from 'react';
import {Field, reduxForm} from "redux-form";
import TextField from "../../../../shared/components/form/TextField/TextField";
import SubmitButton from "../../../../shared/components/form/Buttons/SubmitButton";
import {required, validateForm} from "redux-form-validators";

function ContactForm({handleSubmit, onSubmit, handleSubmitForm}) {
    const inputStyle = {
        height: "50px",
        width: '330px'
    }

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <Field
                component={TextField}
                required={true}
                label={"Name"}
                name={"name"}
                InputProps={{
                    style: inputStyle
                }}
            />
            <Field
                component={TextField}
                required={true}
                label={"Email"}
                name={"email"}
                InputProps={{
                    style: inputStyle
                }}
            />
            <Field
                component={TextField}
                required={true}
                label={"Phone"}
                name={"phone"}
                InputProps={{
                    style: inputStyle
                }}
            />

            <SubmitButton label={"Submit"} style={inputStyle}/>
        </form>
    )
}

const validate = validateForm({
    name: [required({ msg: 'Please enter your name' })],
    email: [required({ msg: 'Please enter your email' })],
    phone: [required({ msg: 'Please enter your phone number' })],
});

export default reduxForm({
    form: 'contact-form',
    validate
})(ContactForm);