/**
 * Function to transform the data of the series
 * Transforms an array of objects by adding 'x' and 'y' properties based on the maximum number of columns.
 * And set visibility based the visibility of the data class.
 *
 * @param {Array} data - The array of objects to be transformed.
 * @param {number} maxColumns - The maximum number of columns in the tile map.
 * @param {Object} chartRef - Chart reference.
 * @param {string} valueKey - key for the value in the object
 * @returns {Array} - The transformed array of objects with added 'x' and 'y' & visible properties.
 */
export const transformData = (data, maxColumns, chartRef, valueKey = 'value') => {
	const { allItems: dataClasses } = chartRef?.current?.chart?.legend || {};

	// Iterate through the array and add x and y properties & visible
	return data.map((item, index) => {
		let visible = true;

		// Calculate x and y based on the index
		const x = Math.floor(index / maxColumns);
		const y = index % maxColumns;
		item.x = x;
		item.y = y;
		if (dataClasses?.length > 0) {
			visible = dataClasses.some(dataClassItem => {
				// Check if item[valueKey] is within the specified range (or no range is defined)
				const withinRange
					= (item[valueKey] >= dataClassItem.from || dataClassItem.from === undefined)
					&& (item[valueKey] <= dataClassItem.to || dataClassItem.to === undefined);

				// Check if dataClassItem is visible
				const isVisible = dataClassItem.visible;

				return withinRange && isVisible;
			});
		}

		return { ...item, x, y, visible };
	});
};
