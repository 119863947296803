const SUMMARY_POLLING_INTERVAL = 2500; //interval for state polling
const CAPTCHA_KEY_V2_CHECKBOX = "6Ld9Z-MUAAAAAIlQ704Pqky7PB-NJgv5gxrlxxQo";
const BACKGROUND_PROCESSES_POOLING_INTERVAL = 5000; //every 5 seconds
const GET_TOTALS_INTERVAL = 30 * 1000; //every 30 seconds
const GRAPH_BUCKET_INTERVAL = 5000; // interval for graph bucket
const ANOMALIES_POLLING_INTERVAL = 15 * 1000;
const VISIBLE_DEVICES_POLLING_INTERVAL = 60 * 1000;
const DEVICE_ACTIVITY_POLLING_INTERVAL = 1 * 60 * 1000; // 1 min refresh Active / Inactive

const REFRESH_ALERTS = 1 * 60 * 1000; // Interval REFRESH ALERT

export {
    CAPTCHA_KEY_V2_CHECKBOX,
    SUMMARY_POLLING_INTERVAL,
    BACKGROUND_PROCESSES_POOLING_INTERVAL,
    GET_TOTALS_INTERVAL,
    GRAPH_BUCKET_INTERVAL,
    ANOMALIES_POLLING_INTERVAL,
    REFRESH_ALERTS,
    DEVICE_ACTIVITY_POLLING_INTERVAL,
    VISIBLE_DEVICES_POLLING_INTERVAL
}
