import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	icon: {
		fill: props => props.isHover ? theme.palette.primary.main : '#48648B',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
	},
}));

export { useStyles };
