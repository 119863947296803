import PropTypes from 'prop-types';
import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { useFormik } from 'formik';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import { useStyles } from './phoneVarificationForm.css';
import TextFieldWrapper from '../../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import { CODE_FORM_KEY, VERIFICATION_FORM_INITIAL_VALUE, SUBMITTING_MAX_COUNT } from '../setupPhoneConfig';
import { VERIFY_CODE_FIELD_LABEL } from '../mfaSetUpTexts';
import { codeValidationSchema } from '../../steps/stepsConfig';

const PhoneVerificationForm = ({ onSubmitCallback, isDisabled }) => {
	const classes = useStyles();

	const { values, handleChange, touched, errors, handleSubmit, submitCount, isSubmitting } = useFormik({
		initialValues: VERIFICATION_FORM_INITIAL_VALUE,
		enableReinitialize: true,
		validationSchema: codeValidationSchema,
		async onSubmit({ code }) {
			await onSubmitCallback(code);
		},
	});

	return (
		<form onSubmit={handleSubmit} className={classes.verifyContainer}>
			<Grid container item xs={12} className={classes.container} >
				<Grid xs={6} className={classes.verifyField}>
					<TextFieldWrapper
						fullWidth
						id={CODE_FORM_KEY}
						name={CODE_FORM_KEY}
						label={VERIFY_CODE_FIELD_LABEL}
						onChange={handleChange}
						value={values[CODE_FORM_KEY]}
						error={touched[CODE_FORM_KEY] && Boolean(errors[CODE_FORM_KEY])}
						helperText={touched[CODE_FORM_KEY] && errors[CODE_FORM_KEY]}
						size={'small'}
						className={classes.verifyField}
						required
					/>
				</Grid>
				<Grid xs={3} className={classes.submitContainer}>
					{isSubmitting
						? <CircularProgress color="primary" className={classes.circularProgress}/>
						: <SubmitButton label={'Set-up phone'} size="small"
							className={classes.submitButton} isDisabled={submitCount > SUBMITTING_MAX_COUNT || isDisabled}/>}
				</Grid>
			</Grid>
		</form>

	);
};

PhoneVerificationForm.propTypes = {
	isDisabled: PropTypes.bool,
	onSubmitCallback: PropTypes.func,
};

PhoneVerificationForm.defaultProps = {
	isDisabled: false,
	onSubmitCallback() {},
};
export default PhoneVerificationForm;
