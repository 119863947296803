import React, { Component } from 'react';
import LineMetaWrapper from '../lineMetaWrapper/lineMetaWrapper';
import Graph from '../graph/graph';
import LineSwitch from '../switch/lineSwitch';
import './line.scss';
import {
    updateAlert, getAlertes,
    deleteAlert, getAlerteStatistic,
    updateSelectedAlertEdition, updateAlertToogle,
    updateGivenGraph, updateLoadingStatusGivenGraph
} from '../../../controller/redux/actions';
import { connect } from 'react-redux';
import UpdateTreshold from '../../updateThreshold/updateThreshold';
import LineMenu from '../lineMenu/lineMenu';
import { thresholdObject } from '../../../controller/constants/threshold';
import moment from 'moment';
import { mapPayloadToForm, mapFormToPayload } from '../../../controller/businessLogic/payload-transformations';
import _ from 'lodash';
import * as d3 from "d3-format";
import numeral from 'numeral';
import { minMaxformater } from '../../../../../constants/formater';


class Line extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditingTreshold: false,
            isDesabledAlerte: true,
            currentStatistics: null
        }
    }

    onUpdateTreshHold = async (newTreshold) => {
        this.setState({ isEditingTreshold: true });
    }

    onHandleSubmit = async (newFormValues) => {

        const { content } = this.props;


        const currentPayload = mapPayloadToForm(content);

        currentPayload.thresholdType = newFormValues['thresholdType']
        currentPayload.thresholdValue = numeral(newFormValues['thresholdValue'])._value;


        await this.props.updateAlert(content.Id, mapFormToPayload(currentPayload));
        this.props.updateLoadingStatusGivenGraph(content.Id, true);


        const { value, timeUnit } = this.props.selectedFilter;
        const to = moment().add(1, 'days').startOf("days").unix();
        const from = moment().add(1, 'days').startOf("days").subtract(value, timeUnit).unix();
        const NewGraphValue = await getAlerteStatistic(content.Id, from, to);
        this.props.updateLoadingStatusGivenGraph(content.Id, false);
        this.props.updateGivenGraph(content.Id, NewGraphValue);

        await this.props.getAlertes();
        this.setState({ isEditingTreshold: false, currentStatistics: NewGraphValue });

    }
    onHandleClose = async () => {
        this.setState({ isEditingTreshold: false });
    }
    getAlertStatisticAsync = async (contentId) => {
        const { value, timeUnit } = this.props.selectedFilter;
        const to = moment().add(1, 'days').startOf("days").unix();
        const from = moment().add(1, 'days').startOf("days").subtract(value, timeUnit).unix();
        const NewGraphValue = await getAlerteStatistic(contentId, from, to);
        return NewGraphValue;

    }
    onUpdateAlertes = async () => {
        const { content } = this.props;
        const payload = mapPayloadToForm(content);
        await this.props.updateSelectedAlertEdition(payload);
        await this.props.getAlertes();

    }
    onRemoveTresHold = async () => {
        const { content } = this.props;
        await this.props.deleteAlert(content.Id);
        await this.props.getAlertes();

    }
    onUpdateAlerteStatus = async (newValue) => {
        const { content } = this.props;

        await this.props.updateAlertToogle(content.Id, newValue);
        const NewGraphValue = await this.getAlertStatisticAsync(content.Id)
        this.props.updateGivenGraph(content.Id, NewGraphValue)
        await this.props.getAlertes();
    }
    getNewValueCurrentGraph = async () => {
        const { content } = this.props;
        const NewGraphValue = await this.getAlertStatisticAsync(content.Id);
        this.props.updateGivenGraph(content.Id, NewGraphValue)
    }

    render() {

        const { content, graphs, selectedBar } = this.props;
        const currentStatistics = _.isEmpty(graphs) ? {} : _.find(graphs, e => { return e.alertPolicyId == content.Id })

        const { isEditingTreshold, isDesabledAlerte = content.enabled } = this.state;

        const initialValues = {
            thresholdValue: parseInt(content.data.threshold.value),
            thresholdType: thresholdObject[content.data.threshold.type]
        }

        return (
            <div className="line-content">
                <div className="line">
                    <div className="alerte-name">
                        { content.name }
                    </div>

                    <div className="wrapper">
                        <LineMetaWrapper label={ "Daily Min." } value={ _.has(currentStatistics, 'latestMinValue') ? d3.format(minMaxformater)(currentStatistics.latestMinValue) : ' - ' } />
                        <LineMetaWrapper label={ "Daily Max." } value={ _.has(currentStatistics, 'latestMaxValue') ? d3.format(minMaxformater)(currentStatistics.latestMaxValue) : ' - ' } />
                        <LineMetaWrapper label={ "Threshold" }
                            value={ `${thresholdObject[content.data.threshold.type].sign} ${d3.format(minMaxformater)(content.data.threshold.value)}` } />
                        <Graph
                            isEnabled={ content.enabled }
                            Id={ content.Id }
                            data={ currentStatistics }
                            threshold={ content.data.threshold.value } />
                        <div className="disable-content-line">
                            { content.enabled ?
                                <>
                                    <LineMetaWrapper label={ "Avg. daily alerts" }
                                        value={ _.has(currentStatistics, 'average') ? currentStatistics.average : ' - ' } />
                                    <LineMetaWrapper label={ "Alerts today" }
                                        value={ _.has(currentStatistics, 'latestAlertsCounter') ? currentStatistics.latestAlertsCounter.toFixed(0) : ' - ' }
                                        extra={ _.has(currentStatistics, 'latestDiffFromAveragePercent') ? currentStatistics.latestDiffFromAveragePercent.toFixed(0) : ' - ' } />
                                </>
                                : null }
                        </div>

                        <LineSwitch
                            onChange={ this.onUpdateAlerteStatus }
                            isEnabled={ content.enabled } />

                        <LineMenu
                            lineContext={ content }
                            onClickUpdateThreshold={ this.onUpdateTreshHold }
                            onClickUpdateAlerte={ this.onUpdateAlertes }
                            onClickRemoveAlerte={ this.onRemoveTresHold }
                        />
                    </div>
                </div>
                { isEditingTreshold ? <div className="line-update-threshold">
                    <UpdateTreshold
                        form="update-threshold"
                        onSubmit={ this.onHandleSubmit }
                        onClose={ this.onHandleClose }
                        initialValues={ initialValues } />
                </div> : null }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        selectedFilter: state.alertes.selectedFilter,
        graphs: state.alertes.graphs,
        selectedBar: state.alertes.selectedBar
    };
};
const mapDispatchToProps = {
    updateAlert,
    getAlertes,
    deleteAlert,
    updateSelectedAlertEdition,
    updateAlertToogle,
    getAlerteStatistic,
    updateGivenGraph,
    updateLoadingStatusGivenGraph
};
export default connect(mapStateToProps, mapDispatchToProps)(Line);

