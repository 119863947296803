import React from "react";
import Grid from "@material-ui/core/Grid";
import EndpointInventoryReport from "./endpointInventoryReport/endpointInventroyReport";
import { useStyles } from './endpointInventoryReportsView.css'
import PropTypes from "prop-types";

const propTypes = {
    reports: PropTypes.object.isRequired,
}

const EndpointInventoryReportsView = ({ reports }) => {
    const classes = useStyles();

    return(
        <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={12} className={classes.titleLabel}>
                Download previously generated reports of the last 30 days.
            </Grid>
            <Grid item xs={8}>
                {reports.map( report => {
                    return <EndpointInventoryReport
                                date={report.CreationTime}
                                description={report.Description}
                                downloadLink={report.ZipLink}
                                key={report.ZipLink}
                                className={classes.reportRow}/>
                })}

            </Grid>
        </Grid>)
}

EndpointInventoryReportsView.propTypes = propTypes;

export default EndpointInventoryReportsView;
