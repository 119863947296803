import {
	OPEN_SNACKBAR, CLOSE_SNACKBAR,
} from './actionTypes';
import initialValues from './initialValues';

export default function (state = initialValues, { type, payload }) {
	switch (type) {
		case OPEN_SNACKBAR:
			return {
				...state,
				isOpen: true,
				severity: payload.severity,
				message: payload.message,
				autoHideDuration: payload.autoHideDuration,
				action: payload.action,
				customClass: payload.customClass,
			};
		case CLOSE_SNACKBAR:
			return initialValues;
		default:
			return state;
	}
}
