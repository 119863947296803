import * as yup from 'yup';

import { CODE_FORM_KEY } from '../setupPhone/setupPhoneConfig';
import { CODE_VALIDATION_ERROR_MESSAGE } from '../setupPhone/mfaSetUpTexts';

export const MFA_SELECTOR = 'mfa';
export const SMS_MFA_METHOD = 'sms';
export const QR_MFA_METHOD = 'qr';

// SMS Mfa steps
export const KEEP_EXISTING_NUMBER_STEP = 'keepExistingNumber';
export const SET_NEW_NUMBER_STEP = 'setNewNumber';

export const MFA_SETUP_MODAL_HEADER = 'Multi-Factor Authentication';

export const codeValidationSchema = yup.object({
	[CODE_FORM_KEY]: yup
		.string(CODE_VALIDATION_ERROR_MESSAGE)
		.length(6)
		.required(CODE_VALIDATION_ERROR_MESSAGE),
});

