import React from "react";
import MakeRemovable from "./makeRemovable";
import EmailRecipient from "./emailRecipient";
import { inputTypes, addAnotherInputStrs } from "../../../controller/constants/inputTypes";
import SmsRecipient from "../sms/smsRecipient";

const inputArrayFields = ({ fields, inputType }) => {
    let addAnotherInputText = addAnotherInputStrs[inputType];
    return (
        <div>
            {
                fields.map((value, index) => {
                    return (
                        <MakeRemovable
                            className={ inputType === inputTypes.EMAIL ? 'remove-button-email' : 'remove-button-sms' }
                            handleRemove={ (e) => {
                                e.preventDefault();
                                fields.remove(index);
                            } }
                            key={ index }>
                            { inputType === inputTypes.EMAIL ?
                                <EmailRecipient email={ value } index={ index } key={ index } />
                                : <SmsRecipient number={ value } index={ index } key={ index } /> }
                        </MakeRemovable>
                    )
                })
            }
            <div className="email-add-recipient" onClick={ () => { fields.push("") } }>
                { addAnotherInputText }
            </div>
        </div>
    )
}
export default inputArrayFields;