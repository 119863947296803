import { Checkbox, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getFormValues } from 'redux-form';

import InformationHover from '../../../../../shared/components/informationHover/InformationHover';
import useStyles from './SamplerCheckbox.css';

const SamplerCheckbox = ({ change, form }) => {
	const classes = useStyles();

	const [currentValue, setCurrentValue] = useState(true);
	const formData = useSelector(state => getFormValues(form)(state));

	useEffect(() => {
		setCurrentValue('isDeviceSupported' in formData ? formData.isDeviceSupported : true);

		return () => {
			change('isDeviceSupported', true);
		};
	}, []);

	return (
		<Grid item xs={12} className={classes.wrapper}>
			<Checkbox
				disableRipple
				name="isDeviceSupported"
				color="primary"
				onChange={value => {
					const { checked } = value.target;
					setCurrentValue(checked);
					change('isDeviceSupported', checked);
				}}
				checked={currentValue}
			/>
			<Typography paragraph className={classes.label}>Switch supports sFlow/NetFlow/IPFix</Typography>
			<InformationHover message="Uncheck if sFlow/NetFlow cannot be sent from this device" />
		</Grid>
	);
};

SamplerCheckbox.propTypes = {
	change: PropTypes.func,
	form: PropTypes.string,
};

export default SamplerCheckbox;
