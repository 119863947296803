/* eslint-disable no-negated-condition */
import React, { Component } from 'react';
import '../noNotificationBox/notificationBox.scss';

import { connect } from 'react-redux';
import _ from 'lodash';
import Insight from '../insights/insight';

import { getInsights, removeInsight } from '../../controller/redux/insights/actions';
import { toLocaleDateString } from '../../../../helpers/datesHelper';
import EmptyBox from '../emptyStateBox/EmptyBox';
import Threats from '../../../../assets/threats/threats.png';
import PropTypes from 'prop-types';

const THREATS_BOX_TITLE = 'Threats';
class InsightsBox extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
		this.removeItem = this.removeItem.bind(this);
	}

	componentDidMount = async () => {
		await this.props.getInsights();
	};

	removeItem = async id => {
		await this.props.removeInsight(id);
		this.setState({});
	};

	UNSAFE_componentWillReceiveProps = probs => {
		if (probs.removedInsights.length !== this.props.removedInsights.length) {
			return true;
		}
	};

	render() {
		const { insights, removedInsights } = this.props;
		const filteredInsights = !_.isEmpty(insights) ? insights.filter(e => !removedInsights.includes(e.id)) : [];
		const noInsights = _.isEmpty(filteredInsights) && filteredInsights.length === 0;
		const emptyBoxConfig = this.props.generateEmptyOverviewBoxesConfig(noInsights);

		return (
			<div className="notifications">
				<div className="title">
					<div className="title-and-icon">
						<img src={Threats} className="threats-icon" alt="Threats" />
						<div> {THREATS_BOX_TITLE} </div>
					</div>
					<div>
						{ !_.isEmpty(filteredInsights) ? toLocaleDateString(filteredInsights[0].createdAt) : null }
					</div>
				</div>
				<div className="threat-notifications" style={ { maxHeight: window.innerHeight * 39 / 100 } }>
					{ noInsights
                        && <EmptyBox title={emptyBoxConfig.insights.title} description={emptyBoxConfig.insights.description} image={emptyBoxConfig.insights.image}/>}
					{
						filteredInsights.map((insight, i) => <Insight
							key={ i }
							insight={ {
								...insight,
								message: insight.message,
								instances: insight.instances,
							} }
							removeItem={ newValue => {
								this.removeItem(newValue);
							} } />) }
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	insights: state.insights.insights,
	removedInsights: state.insights.removedInsights,
});

const mapDispatchToProps = {
	getInsights,
	removeInsight,
};

InsightsBox.propTypes = {
	removedInsights: PropTypes.array,
	insights: PropTypes.array,
	generateEmptyOverviewBoxesConfig: PropTypes.func,
	getInsights: PropTypes.func,
	removeInsight: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightsBox);
