import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '20px',
		position: 'relative',
		maxHeight: '80vh',
		overflowY: 'scroll',
		overflowX: 'hidden',
		zIndex: 0,
	},
	tableRow: {
		backgroundColor: 'white',
		paddingTop: '1em',
		paddingBottom: '1em',
		width: '100%',
		'&:hover': {
			cursor: 'pointer',
			backgroundColor: '#F2F2F2',
		},
		fontSize: '14px',
	},
	selectedDetection: {
		backgroundColor: '#F2F2F2',
		boxShadow: `inset 5px 0px 0px 0px ${theme.palette.primary.main}`,
	},
	descriptionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	description: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'-webkit-line-clamp': 2,
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
	},
	skeletonTable: {
		marginTop: '20px',
		height: '770px',
	},
	iconTooltipText: {
		fontSize: '14px',
		padding: '0.2em',
	},
	icon: {
		height: '17px',
		width: '17px',
		marginRight: '0.3em',
	},
	emptyRowsPlaceholder: {
		backgroundColor: 'white',
		width: '100%',
		height: '5.8em',
	},
	pagination: {
		position: 'block',
		bottom: 0,
		width: '95%',
	},
	deleteDetectionSnackbar: {
		backgroundColor: '#1D1E20',
		color: 'white',
	},
	undoDeleteButton: {
		color: theme.palette.primary.main,
		fontSize: '12px',
		fontWeight: 600,
		'&:hover': {
			backgroundColor: '#071C36',
		},
	},
	emptyStateContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		marginBottom: '50px',
	},
	emptyStateText: {
		fontSize: '24px',
		lineHeight: '13px',
		fontWeight: 500,
		color: theme.palette.inputText.main,
	},
	emptyStateSubtext: {
		fontSize: '20px',
		lineHeight: '28px',
		width: '650px',
		fontWeight: 200,
		color: theme.palette.inputText.main,
		marginTop: '24px',
	},
	noDetectionsImageNoFilters: {
		width: '320px',
		height: '250px',
		display: 'block',
		margin: 'auto',
		marginBottom: '20px',
	},
	noDetectionsImageFilters: {
		width: '125px',
		height: '125px',
		display: 'block',
		margin: 'auto',
		marginBottom: '20px',
	},
	descriptionCell: {
		width: '20%',
	},
	dateCell: {
		width: '8%',
	},
	severityCell: {
		width: '8%',
	},
	endpointsCell: {
		maxWidth: '44%',
	},
	iconsCell: {
		maxWidth: '20%',
	},
}));

export default useStyles;
