import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	boxMargin: {
		margin: '10px',
	},
	mainContainer: {
		minHeight: '312px',
		width: '370px',
		position: 'relative',
	},

	header: {
		display: 'flex',
		justifyContent: 'space-around',
		verticalAlign: 'top',
		textAlign: 'center',
	},
	headerTitle: {
		fontWeight: '400',
		fontSize: '14px',
		color: theme.palette.inputText.main,

	},
	headerTitleAttrName: {
		fontWeight: '700',
	},
	body: {
		verticalAlign: 'top',
		paddingBottom: '35px',
	},
	footer: {
		width: '100%',
		alignSelf: 'end',
		paddingLeft: '20px',
		display: 'inlineBlock',
		verticalAlign: 'top',
		position: 'absolute',
		bottom: '0',
	},
}));

export default useStyles;
