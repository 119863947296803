import { deviceTypes } from '../devicesManagementConfig';
import React from 'react';

const tableFieldsDisplayNames = {
	samplingRate: 'Sampling Rate',
	displayUpdatedAt: 'Last Modified',
	collector: 'Collector Name',
	sampler: 'Sampler Name',
	identifierValue: 'Identifier',
	cloudProvider: 'Cloud Provider',
	awsRegion: 'Region',
	awsAccountId: 'AWS Account ID',
	gcpProjectId: 'Project ID',
	gcpVpcName: 'Vpc Name',
};

const deviceTypeDisplayNames = {
	[deviceTypes.PUBLIC_DEVICE]: 'Firewall',
	[deviceTypes.CORE_DEVICE]: 'Switch',
	[deviceTypes.CLOUD_DEVICE]: 'Cloud',
};

const additionalDataFieldsMapping = {
	[deviceTypes.PUBLIC_DEVICE]: ['samplingRate', 'displayUpdatedAt'],
	[deviceTypes.CORE_DEVICE]: ['collector', 'sampler', 'samplingRate', 'displayUpdatedAt'],
	[deviceTypes.CLOUD_DEVICE]: [
		'identifierValue',
		'cloudProvider',
		'displayUpdatedAt',
		'awsRegion',
		'awsAccountId',
		'gcpProjectId',
		'samplingRate',
		'gcpVpcName',
	],
};
const INACTIVE_TOOLTIP_CONTENT = <p className="tooltip-content"><b>Waiting for data - </b>your device will become active once all the configuration steps are complete</p>;

export {
	additionalDataFieldsMapping,
	tableFieldsDisplayNames,
	INACTIVE_TOOLTIP_CONTENT,
	deviceTypeDisplayNames,
};
