import React from "react";
import step7side from "../../../../../../../assets/walkthrough/analysis/steps/step7Side.png";
import step8Main from "../../../../../../../assets/walkthrough/analysis/steps/step8Main.png";
import infoIcon from "../../../../../../../assets/walkthrough/analysis/icons/infoIcon.png"
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step8.css";
import MainPanelTemplateV2
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const Step8 = () => {
    const classes = useStyles();

    const TITLE = "Analysis Bar";
    const DESCRIPTION = <span>
    View extended information on IPs, such as ISP, Organization, and DNS, by clicking on the <img
        className={classes.infoIcon} src={infoIcon}/> button.
</span>

    return (
        <StepTemplate sidePanelImage={step7side}>
            <MainPanelTemplateV2 title={TITLE}
                                 image={<img src={step8Main} className={classes.image}/>}
                                 description={DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step8;