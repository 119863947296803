import React, { useState } from 'react';
import { Field } from 'redux-form';

import InputWrapper from '../../../wrapper/inputWrapper';
import DirectionSelectCreateable from '../../../../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable';
import { validateNumericSelector } from '../../../../../backgroundProcess/controller/validations/validation';
import { useStyles } from './inputGroup5.css';
import { textSelectorStyles } from '../../../../../../shared/utils/customSelectorStyles';
import useProtocolIdAutoComplete from '../../../../../../shared/customHooks/useProtocolIdAutoComplete';

const PROTOCOL_ID_ERROR = 'Please enter a valid protocol id.';
const PROTOCOL_ID_PLACEHOLDER = 'Enter Protocol ID / select from dropdown';

const InputGroup5 = () => {
	const classes = useStyles();
	const [isError, setIsError] = useState(false);
	const { options, updateOptions } = useProtocolIdAutoComplete();

	return (
		<div className={classes.root}>
			<div className={classes.protocols}>
				<InputWrapper
					error = {isError ? PROTOCOL_ID_ERROR : null}
					name={'Protocol ID'}
					tooltipText={'IP protocol number such as 6 (TCP) and 17 (UDP)'}
					input={
						<Field
							name="ipProtocol"
							component={DirectionSelectCreateable}
							placeholder={PROTOCOL_ID_PLACEHOLDER}
							options={options}
							validation={value => {
								updateOptions(value);
								const isValidated = validateNumericSelector(value);
								setIsError(value !== '' && !isValidated);
								return isValidated;
							}}
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>
		</div>
	);
};

export default InputGroup5;
