import React, {useState} from 'react';
import TextField from "../../../shared/components/form/TextField/TextField";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";

const Func = () => {}

function PasswordTextField({onClick = Func, onBlur, name = "password", label="Password", ...props  }) {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const toggleVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    }

    return (
        <TextField
            {...props}
            label={label}
            className="form-field"
            name={name}
            type={isPasswordVisible ? 'text' : 'password'}
            onClick={onClick}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={toggleVisibility}
                            edge="end"
                        >
                            {isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
            }}
        />
    )

}


export default PasswordTextField;