import React from 'react';
import './closeButton.scss';
import CloseIcon from "mdi-react/CloseIcon";

function CloseButton({onClick, label}) {
    return (
        <div
            className="close-button"
            onClick={onClick}
        >
            <CloseIcon/>
            {label && <span>{label}</span>}
        </div>
    );
}

export default CloseButton;