const validateCreateAlertForm = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'The name of the alert is required'; 
  }
  if (parseInt(values.timeWindow) > 600) {
    errors.timeWindow = 'Must be lower than 10 min'; 
  }
  return errors
}


export { validateCreateAlertForm }