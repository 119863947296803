// Product ids as expected from api
const BASIC_PLAN_ID = 'BASIC_PLAN';
const FREE_PLAN_ID = 'FREE_PLAN';
const CUSTOM_PLAN_ID = 'CUSTOM_PLAN';
export const YEARLY_BILLING_ID = 'YEARLY';

const FEATURES = {
	DEVICE: 'device',
	USER: 'user',
	VCA: 'vca',
	CUSTOM_ALERTS: 'customAlerts',
};

export {
	BASIC_PLAN_ID,
	FREE_PLAN_ID,
	CUSTOM_PLAN_ID,
	FEATURES,
};
