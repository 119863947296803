import _ from 'lodash';

const effectiveSideLinks = function (sidebarLinks, features, isAnalyst) {
	return sidebarLinks.filter(sideBarLink => {
		if (isAnalyst && !sideBarLink.isAnalystPermit) {
			return false;
		}

		// It means that menu is active by default
		// and has no condition about active feature
		if (_.isEmpty(sideBarLink.feature)) {
			return true;
		}

		const accountHasFeature = _.includes(features, sideBarLink.feature.name);
		const activeFeatureInDefault = sideBarLink.feature.activeIfExists;

		// For pages based on active features
		if (sideBarLink?.feature?.featureName) {
			if (!features.includes(sideBarLink.feature.featureName)) {
				return false;
			}
		}

		// It means that the account doesn't have active feature
		// And we want to show him this anyway
		if (!accountHasFeature && !activeFeatureInDefault) {
			return true;
		}

		// It means that the account has the feature
		// And we do not want to show him this
		return Boolean(accountHasFeature && activeFeatureInDefault);
	});
};

export { effectiveSideLinks };
