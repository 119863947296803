import React, { useState } from 'react';
import { useStyles } from './rawdataExport.css';
import { useSelector, useDispatch } from 'react-redux';
import './exportButton.scss';
import _ from 'lodash';
import { ReactComponent as ExportIcon } from '../../../../../assets/export/export.svg';

import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import { getFormValues } from 'redux-form';
import { validateQueryTimes } from '../../../controller/validations/validation';
import { FormToPayload } from '../../../controller/businessLogic/formPayloadManipulations';
import {
	createRawdataExportRequest,
	createRawdataExportRequestFromLink,
} from '../../../controller/services/visibilityView/requests';
import {
	MAX_SEGMENT_DURATION_IN_HOURS,
	RAWDATA_EXPORT_ERROR_MESSAGE,
	RAWDATA_EXPORT_SUCCESS_MESSAGE,
	SNACKBAR_HIDE_DURATION
} from './constants';
import { Button } from '@material-ui/core';
import analyzer from '../../../../../helpers/analyzer';
import { ANALYSIS_CATEGORY, SUBMIT_ACTION } from '../../../../../helpers/analyzerConstants';
import DescriptionPopup from './descriptionPopup';

const ANALYZER_CATEGORY = ANALYSIS_CATEGORY;

const RawdataExport = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const originalQueryFormData = useSelector(state => getFormValues('create-backgroung-process')(state));
	const currentProcess = useSelector(state => state.backgroundProcesses.currentProcess);
	const [openDescriptionPopup, setOpenDescriptionPopup] = useState(false);

	const handleSubmit = async description => {
		setOpenDescriptionPopup(false);

		analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'RAW_DATA_EXPORT' });
		const linkExists = (!_.isEmpty(currentProcess) && currentProcess.linkId);
		if (linkExists) {
			exportRawdataFromLink(currentProcess.linkId, description);
		} else {
			const error = validateQueryTimes(originalQueryFormData, MAX_SEGMENT_DURATION_IN_HOURS);
			if (error) {
				dispatch(openSnackbar('error', error, SNACKBAR_HIDE_DURATION));
			} else {
				// eslint-disable-next-line new-cap
				const originalQuery = FormToPayload(originalQueryFormData);
				await exportRawdataFromQuery(originalQuery, description);
			}
		}
	};

	const requestExport = () => {
		setOpenDescriptionPopup(true);
	};

	const exportRawdataFromLink = async (linkId, description) => {
		try {
			await createRawdataExportRequestFromLink(linkId, description);
		} catch {
			dispatch(openSnackbar('error', RAWDATA_EXPORT_ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
		}
	};

	const exportRawdataFromQuery = async (originalQuery, description) => {
		try {
			await createRawdataExportRequest(originalQuery, description);
			dispatch(openSnackbar('success', RAWDATA_EXPORT_SUCCESS_MESSAGE, SNACKBAR_HIDE_DURATION));
		} catch {
			dispatch(openSnackbar('error', RAWDATA_EXPORT_ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
		}
	};

	return (
		<>
			<Button className={classes.root} onClick={ requestExport }>
				<ExportIcon className={classes.icon}/><span className={classes.label}>Export</span>
			</Button>
			<DescriptionPopup
				isOpen={openDescriptionPopup}
				onSubmit={handleSubmit}
				onClose={() => setOpenDescriptionPopup(false)}
			/>
		</>
	);
};

export default RawdataExport;

