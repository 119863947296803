import _ from 'lodash';
import { array } from 'prop-types';
import {THREATS_SEVERITIES_CONFIG} from '../constants/constanteSeverity'

const getHighlightedThreats = (threats) => {
    return !_.isEmpty(threats) ? threats.filter(threat => threat.isMarked) : []
}

const getSelectedTabValue = (threats, highlightedThreats, value) => {
    return _.isEmpty(threats) ? 0 : highlightedThreats.length > 0 ? 1 : value;
}


const mapThreatsResponse = (threats) => {
    return threats.map((threat, i) => {
        const {
            assets,
            deviceData,
            eventId,
            isMarked,
            severity,
            threatType,
            firstDetectionTime,
            lastDetectionTime
        } = threat;
        const { id:deviceId, name:deviceName } = deviceData
        threat =  {
            Id: eventId,
            startTime: firstDetectionTime,
            endTime: lastDetectionTime,
            device: deviceId,
            threatName: threatType,
            severity: THREATS_SEVERITIES_CONFIG[severity],
            isMarked,
            deviceName: deviceName
        }
        if(assets) {
            threat['assets'] = {...assets}
        }
        return threat

    }).sort((a, b) => {
        return parseInt(b.date) - parseInt(a.date)
    });

}
export {
    getHighlightedThreats,
    getSelectedTabValue,
    mapThreatsResponse
}