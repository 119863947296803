import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        paddingLeft: "20px",
        paddingRight: "30px",
        paddingTop: "15px"
    },
    imageWrapper: {
        display: "flex",
        alignItems: "center",
        height: "50%"
    },
    image: {
        height: "120px"
    },
    title: {
        paddingTop: "40px",
        display: "flex",
        alignItems: "center",
        height: "25%",
        fontSize: "30px",
        fontWeight: "bold",
    },
    description: {
        height: "25%",
        fontSize: "16px",
        color: theme.palette.dialogDescription.main
    }
}));

export {useStyles}