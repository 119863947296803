/* eslint-disable react/no-multi-comp */
//import 'rc-alertNotifications/assets/index.css';

import React, {  PureComponent } from 'react';
import PropTypes from 'prop-types';

import NotificationSystem from 'rc-notification';
import {clearMessage} from "../../../containers/app/controller/redux/message/messageActions";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

export class BasicNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
    title: '',
  };

  render() {
    const { color, title, message } = this.props;

    return (
        <div className={`notification notification--${color}`}>
          <h5 className="notification__title bold-text">{title}</h5>
          <p className="notification__message">{message}</p>
        </div>
    );
  }
}

export class ImageNotification extends PureComponent {
  static propTypes = {
    img: PropTypes.string.isRequired,
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    title: '',
  };

  render() {
    const { img, title, message } = this.props;

    return (
        <div className="notification notification--image">
          <div className="notification__image">
            <img src={img} alt="" />
          </div>
          <h5 className="notification__title bold-text">{title}</h5>
          <p className="notification__message">{message}</p>
        </div>
    );
  }
}

export class FullWideNotification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    message: PropTypes.string.isRequired,
  };

  static defaultProps = {
    color: '',
  };

  render() {
    const { color, message } = this.props;

    return (
        <div className={`notification notification--full-wide notification--${color}`}>
          <p className="notification__message">{message}</p>
        </div>
    );
  }
}

class Notification extends PureComponent {
  static propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string
  };

  componentDidMount() {
    const { color, title, content, clearMessage } = this.props;

    NotificationSystem.newInstance({}, n => {
      this.notificationRU = n;
      this.showNotification(color, title, content);
      clearMessage();
    });
  }

  showNotification = (color, title, content) => {
    this.notificationRU.notice({
      content: <BasicNotification color={color} title={title} message={content} />,
      duration: 3,
      closable: true,
      style: { top: 0, left: 'calc(100vw - 100%)' },
      className: 'right-up'
    });
  };


  render() {
    return null;
  }
}


const mapStateToProps = state => ({});

const mapDispatchToProps = {clearMessage};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));
