import { FormWrapper } from '../../../../../shared/components/simpleTable/simpleFormContainer';
import SubmitButton from '../../../../../shared/components/form/Buttons/SubmitButton';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleModal from '../../../../../shared/components/simpleTable/simpleModal';
import { Button, Grid, TextField } from '@material-ui/core';
import useStyles from './descriptionPopup.css';
import EyeIcon from '../../../../../assets/modal/eye.svg';

const TITLE = 'Query properties';
const SUB_TITLE = 'Would to like to add any description about this query?';
const DescriptionPopup = ({ onSubmit, isOpen, onClose }) => {
	const classes = useStyles();
	const [description, setDescription] = useState('');
	useEffect(() => {
		setDescription('');
	}, [isOpen]);
	const onChange = event => {
		setDescription(event.target.value);
	};

	const onButtonClick = () => {
		onSubmit(description);
	};

	return (
		<SimpleModal
			isOpen={isOpen}
			toggle={onClose}
			header={TITLE}
			icon={EyeIcon}
			style={{ height: '100vh', marginTop: '25vh' }}
		>
			<FormWrapper subTitle={SUB_TITLE}>
				<Grid container>
					<Grid item xs={12} className={classes.description}>
						<TextField
							type="text"
							label="Query description"
							variant="outlined"
							onChange={ onChange }
							value={description}
							size={'small'}
							fullWidth
						/>
					</Grid>
				</Grid>
			</FormWrapper>
			<div className={classes.buttonsSection}>
				<SubmitButton onClick={onButtonClick} label={'Save'}/>
				<Button onClick={ onButtonClick } className={classes.skipBtn}>Skip</Button>
			</div>
		</SimpleModal>);
};

DescriptionPopup.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	handleSubmit: PropTypes.func,
};

export default DescriptionPopup;
