import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		width: '100%',
		height: '100%',
		display: 'flex',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: 'auto',
		width: 'max-content',
		height: '20em',
	},
	premiumImage: {
		height: '400px',
		width: '90%',
		display: 'block',
	},
	headline: {
		fontSize: '24px',
		fontWeight: 'bold',
		color: '#071C36',
		marginTop: '-4em',
	},
	text: {
		fontSize: '20px',
		color: '#48648B',
	},
	upgradeButton: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		width: '16em',
		height: '3em',
		fontSize: '20px !important',
		marginTop: '1.6em',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
			opacity: 0.9,
		},
	},
}));

export default useStyles;
