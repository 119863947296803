import React from 'react';
import noData from '../../../../../assets/performanceMonitoring/no-data.svg';
import { useStyles } from './NoData.css';
import { NO_DATA_LABEL } from './NoDataConfig';

const NoData = () => {
	const classes = useStyles();

	return (
		<div className={classes.noDataContainer}>
			<img
				src={noData}
				alt={NO_DATA_LABEL}
				className={classes.noDataImage}/>
			<p className={classes.noDataText}>{NO_DATA_LABEL}</p>
		</div>
	);
};

export { NoData };
