import IconButton from '@material-ui/core/IconButton';
import Edit from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import GroupModal from '../../../containers/groupManagement/view/ipgroupModal/groupModal.jsx';
import CustomTooltip from '../Tooltip/CustomTooltip';

import { updateGroup } from '../../../containers/groupManagement/controller/services/requests';
import { openSnackbar } from '../../../containers/snackbarAlert/controller/redux/actions';
import { useStyles } from './GroupQuickEdit.css';

const SAVE_SUCCEEDED = 'Group details have been edited successfully';
const SAVE_ERROR = 'Failed to Edit Group';

const GroupQuickEdit = ({ group }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModalHandler = () => {
		setIsModalOpen(false);
	};

	const saveGroupHandler = async (isEditMode, groupParams) => {
		try {
			await updateGroup(group.objectGroup.objectId, groupParams);
			dispatch(openSnackbar('success', SAVE_SUCCEEDED, 4000));
		} catch {
			dispatch(openSnackbar('error', SAVE_ERROR, 4000));
		}
	};

	return (
		<>
			<div className={classes.container}>
				<div className={classes.groupChip}>
					{group.objectGroup.title}
				</div>
				<CustomTooltip title="Edit">
					<IconButton className={classes.editIcon} onClick={openModal}>
						<Edit fontSize="small"/>
					</IconButton>
				</CustomTooltip>
			</div>
			<GroupModal
				isEditMode={true}
				selectedGroup={group}
				isOpen={isModalOpen}
				onCloseModal={closeModalHandler}
				onSaveGroup={saveGroupHandler}
			/>
		</>

	);
};

GroupQuickEdit.propTypes = {
	group: PropTypes.object.isRequired,
};

export default GroupQuickEdit;
