import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	paper: {
		height: '218px',
		width: '628px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
		justifyContent: 'space-between',
		// BorderRadius: '4px'
	},
	container: {
		height: '100%',
		width: '100%',
	},
	fullWidth: {
		minWidth: '100%',
	},
	mfaDisclaimer: {
		fontWeight: 400,
		fontSize: '24px',
		lineHeight: '32px',
		paddingLeft: '126px',
		paddingRight: '126px',
		paddingTop: '115px',
	},
	mfaDisclaimerSecondLine: {
		fontWeight: 400,
		fontSize: '24px',
		paddingLeft: '126px',
		marginTop: '10px'

	},
	modalPaper: {
		height: 380,
		width: 850,
	},
	setupPhoneDiv: {
		paddingTop: 60,
		paddingLeft: 111,
	},
	disclaimerApproval: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		marginTop: '14%',
		width: '200px',
		height: '45px',
	},
}));

export { useStyles };
