import React, { useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CollapsibleRecordSummary from './collapsibleRecordSummary/collapsibleRecordSummary';
import DestIps from '../tabManager/topTalkersIps/destIps/destIps';
import TabColumns from '../tabColumns/tabColumns';

import useStyles from './collapsibleRecordComponent.css';

const CollapsibleRecordComponent = ({
	activePanelName,
	handleChange,
	panelName,
	data,
}) => {
	const classes = useStyles();
	const [expand, setExpand] = useState(false);
	const innerColumns = ['Dest. IP', 'Count'];

	return (
		<Accordion
			elevation={0}
			expanded={expand && activePanelName === panelName}
			classes={{ root: classes.root, rounded: classes.rounded }}
			onChange={handleChange(panelName)}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon/>}
				aria-controls={`${panelName}-content`}
				id={`${panelName}-header`}
				IconButtonProps={{ onClick: () => setExpand(!expand) }}
			>
				<CollapsibleRecordSummary value={data.IP} valueTranslation={data.MAC} count={data.Count}/>
			</AccordionSummary>
			<AccordionDetails>
				<TabColumns columns={innerColumns}/>
				<DestIps rows={data.Dests}/>
			</AccordionDetails>
		</Accordion>
	);
};

CollapsibleRecordComponent.propTypes = {
	activePanelName: PropTypes.string,
	handleChange: PropTypes.func,
	panelName: PropTypes.string,
	data: PropTypes.object,
};
export default CollapsibleRecordComponent;
