import React from 'react';
import {Paper, Zoom} from "@material-ui/core";
import LoadingAnimated from "../../../assets/animations/LoadingAnimated.png";
import {useStyles} from './Loader.css'


function Loader({message = ""}){
    const classes = useStyles();
    return(
            <>
                <Zoom in={true}>
                    <Paper elevation={0} className={classes.indicatePaper}>
                        <img className={classes.loadingImage}
                             src={LoadingAnimated}/>
                        <div className={classes.loadingSubTitle}>
                            {message}
                        </div>
                    </Paper>
                </Zoom>

            </>
    )
}

export default Loader;