import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	submitButton: {
		alignSelf: 'center',
		fontWeight: '800',
		width: '200px',

	},
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	form: {
		minWidth: '100%',
		marginTop: '10px',
	},
	phoneNumberGrid: {
		width: '317px',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	submitButtonContainerStyle: {
		marginBottom: 'auto',
		marginLeft: '5%',
	},
	phoneNumberField: {
		width: '317px',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	circularProgress: {
		marginRight: 'auto',
		marginLeft: 'auto',
		display: 'block',
	},

}));

export { useStyles };
