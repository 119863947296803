import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useStyles from './Banner.css';
import IconButton from '@material-ui/core/IconButton/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { closeBanner } from './redux/actions';
import { useSelector, useDispatch } from 'react-redux';

const Banner = ({ isOpen, text, customClass, children, onClose, pagesToAppear, dismissed, variant, placement, enableClose }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const currentPage = useSelector(state => state.app.title);

	const close = () => {
		dispatch(closeBanner(true));
		onClose && onClose();
	};

	useEffect(() => {

	}, [currentPage]);

	const shouldBannerAppear = !dismissed && isOpen && pagesToAppear.includes(currentPage);

	return (
		shouldBannerAppear && <div className={`${classes.container} ${classes[variant]} ${classes[placement]} ${classes.banner} ${customClass ? customClass : ''}`}>
			<div className={classes.bannerContent}>
				<div>{text}</div>
				{ children }
			</div>
			{
				enableClose
				&& <IconButton aria-label="close" size="small" className={classes.closeButton} onClick={close}>
					<CloseIcon fontSize="small" className={classes.closeIcon}/>
				</IconButton>
			}
		</div>
	);
};

Banner.propTypes = {
	isOpen: PropTypes.bool,
	text: PropTypes.string,
	customClass: PropTypes.string,
	children: PropTypes.node,
	onClose: PropTypes.func,
	pagesToAppear: PropTypes.array,
	dismissed: PropTypes.bool,
	variant: PropTypes.oneOf(['default', 'warning']),
	placement: PropTypes.oneOf(['top', 'bottom']),
	enableClose: PropTypes.bool,
};

Banner.defaultProps = {
	isOpen: false,
	variant: 'default',
	placement: 'top',
	enableClose: true,
	pagesToAppear: ['Overview', 'Device Management', 'Group Management', 'Alerts', 'Virtual Cyber Analyst', 'Threat Management', 'Analysis View', 'Acceptlist', 'Integrations', 'User Management'],

};

export default Banner;
