import React, { useState, useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useStyles from './endpointsGroup.css';
import _ from 'lodash';
import TextHoverPopover from '../textHoverPopover/textHoverPopover';

const EndpointsGroup = ({ showRemainderPopover, endpoints }) => {
	const endpointTypes = {
		ALIAS: 'alias',
		IP: 'ip',
	};
	const containerRef = useRef(null);
	const endpointsRefs = []; // We add the endpoint refs here on mount
	const classes = useStyles();
	const [endpointsCount, setEndpointsCount] = useState(0);
	const [containerWidth, setContainerWidth] = useState(0);
	const [arrangedEndpoints, setArrangedEndpoints] = useState([]);

	useLayoutEffect(() => {
		const handleWindowResize = () => {
			setContainerWidth(containerRef.current.clientWidth);
		};

		const updatedArrangedEndpoints = arrangeEndpoints();
		setArrangedEndpoints(updatedArrangedEndpoints);
		const updatedEndpointsCount = calculateEndpointsCount();
		setEndpointsCount(updatedEndpointsCount);
		setContainerWidth(containerRef.current.clientWidth);
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [endpoints]);

	useLayoutEffect(() => {
		const updatedEndpointsCount = calculateEndpointsCount();
		setEndpointsCount(updatedEndpointsCount);
	}, [containerWidth, endpointsRefs]);

	const calculateEndpointsCount = () => {
		let endpointsLengthSum = 0;
		let updatedEndpointsCount = 0;
		while (containerRef.current.clientWidth > 0 && endpointsLengthSum <= containerRef.current.clientWidth && updatedEndpointsCount < arrangedEndpoints.length) {
			endpointsLengthSum += endpointsRefs[updatedEndpointsCount].clientWidth;
			updatedEndpointsCount++;
		}

		return updatedEndpointsCount;
	};

	const arrangeEndpoints = () => {
		const aliased = endpoints.filter(endpoint => endpoint.alias);
		const nonAliased = endpoints.filter(endpoint => !endpoint.alias);
		const groupedNonAliased = _.groupBy(nonAliased, endpointTypes.IP);
		const sortedNonAliased = Object.keys(groupedNonAliased).sort((first, second) => groupedNonAliased[second][0].rank - groupedNonAliased[first][0].rank);
		const groupedAliased = _.groupBy(aliased, endpointTypes.ALIAS);
		const sortedAliased = Object.keys(groupedAliased).sort((first, second) => groupedAliased[second].length - groupedAliased[first].length);
		return [...sortedAliased.map(endpoint => ({ text: endpoint, type: endpointTypes.ALIAS })), ...sortedNonAliased.map(endpoint => ({ text: endpoint, type: endpointTypes.IP }))];
	};

	const remainingEndpoints = arrangedEndpoints.slice(endpointsCount, arrangedEndpoints.length);

	const endpointsRemainder = arrangedEndpoints.length - endpointsCount;

	// Maps over endpoints until it reaches the count of the endpoints to display
	// We save the refs for the empty divs as well for the calculation of the endpoints
	const getDisplayedEndpoints = () => arrangedEndpoints.map((endpoint, index) => (
		index < endpointsCount ? <div key={`${index}-${endpoint}`} ref={ref => {
			endpointsRefs[index] = ref;
		}} className={`${classes.chip} ${classes[`${endpoint.type}Chip`]}`}>
			{endpoint.text}
		</div>
			: <div key={`${index}-${endpoint}`} ref={ref => {
				endpointsRefs[index] = ref;
			}}
			></div>
	));

	return (
		<div ref={containerRef} className={classes.root}>
			{
				getDisplayedEndpoints()
			}
			<TextHoverPopover disabled={!showRemainderPopover} text={endpointsRemainder !== 0 && `+${endpointsRemainder}`}>
				<div className={classes.endpointsRemainder}>
					{
						remainingEndpoints.map((endpoint, index) => (
							<div key={`${index}-${endpoint}`} className={`${classes.chip} ${classes[`${endpoint.type}Chip`]}`}>
								{endpoint.text}
							</div>
						))
					}
				</div>
			</TextHoverPopover>
		</div>
	);
};

EndpointsGroup.defaultProps = {
	showRemainderPopover: false,
};

EndpointsGroup.propTypes = {
	endpoints: PropTypes.array.isRequired,
	showRemainderPopover: PropTypes.bool,
};

export default EndpointsGroup;
