import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	// Filter related
	deviceMonitoringGridItem: {
		height: '55%',
		paddingBottom: '1%',
		paddingRight: '1%',
		paddingLeft: '1%',
	},
	filter: {
		height: '15%',
		paddingLeft: '1.5%',
		alignItems: 'center',
	},
	deviceMonitoringGridContainer: {
		minWidth: '100%',
		height: '150%',
	},
}),
);

export { useStyles };
