// Tracking Code
const TRACKING_CODE = 'UA-206544226-1';
const SITE_PAGES_SAMPLING_RATE = 100;

// Analyzer event statuses
const ANALYZER_FAILED_STATUS = "ERROR";
const ANALYZER_SUCCESS_STATUS = "SUCCESS";
const ANALYZER_DISMISS_STATUS = "DISMISS";

// Categories
const DEVICE_CATEGORY = "DEVICES";
const ANALYSIS_CATEGORY = "ANALYSIS";
const INTEGRATION_CATEGORY = "INTEGRATION";
const QUERY_INPUT_CATEGORY = "QUERY_INPUT";
const VCA_MANAGEMENT_CATEGORY = "VCA_MANAGEMENT";
const ALERTS_CATEGORY = "ALERTS";
const TOP_BAR_LINKS_CATEGORY = "TOP_BAR";
const PLANS_CATEGORY = "PLANS";


// Actions
const OPEN_ACTION = "OPEN";
const DISMISS_ACTION = "DISMISS";
const SUBMIT_ACTION = "SUBMIT";
const VISIT_ACTION = "VISIT";
const REDIRECT_ACTION = "REDIRECT";
const LOAD_ACTION = "LOAD";
const CHANGE_ACTION = "CHANGE";

export {
    TRACKING_CODE,
    SITE_PAGES_SAMPLING_RATE,
    ANALYZER_FAILED_STATUS,
    ANALYZER_SUCCESS_STATUS,
    ANALYZER_DISMISS_STATUS,
    PLANS_CATEGORY,
    DEVICE_CATEGORY,
    ANALYSIS_CATEGORY,
    INTEGRATION_CATEGORY,
    QUERY_INPUT_CATEGORY,
    VCA_MANAGEMENT_CATEGORY,
    ALERTS_CATEGORY,
    TOP_BAR_LINKS_CATEGORY,
    OPEN_ACTION,
    DISMISS_ACTION,
    SUBMIT_ACTION,
    VISIT_ACTION,
    REDIRECT_ACTION,
    LOAD_ACTION,
    CHANGE_ACTION
}