import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#F9F9F9',
		margin: '5px',
		border: 'none',
		'&:before': {
			backgroundColor: 'white',
		},
	},
	rounded: {
		borderRadius: '5px',
	},
	summaryValues: {
		fontWeight: '700',
		fontSize: '14px',
	},
	infoIcon: {
		alignSelf: 'center',
		marginBottom: '1px',
		'&>g>g': {
			'&:hover': {
				fill: `${theme.palette.primary.main} !important`,
			},
		},
	},
	icons: {
		height: '100%',
	},
	copy: {
		display: 'flex',
		alignSelf: 'center',
		paddingLeft: '15%',
	},
	count: {
		cursor: 'default',
	},
}));

export default useStyles;
