import React, {useEffect, useRef, useState} from 'react';
import CustomTooltip from "../../Tooltip/CustomTooltip";

const TooltipField = ({text, useTooltip}) => (
    <>
        {useTooltip ?
            <CustomTooltip title={text}>
                <span>{text}</span>
            </CustomTooltip>
            :
            <span>{text}</span>
        }
    </>
);

export default TooltipField;
