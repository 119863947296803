import React, { Component } from 'react';
import InputWrapper from '../../../wrapper/inputWrapper';
import '../../../../../../shared/components/form/Select/react-select.scss';
import './inputGroup2.scss';
import '../group3/inputGroup3.scss';
import { customSelectorStyles } from '../customSelectorStyles';
import DevicesInterfacesSelector from '../../../../../../shared/components/queryLine/selectors/devicesInterfacesSelector';
import simpleValueSelector from '../../../../../../shared/components/queryLine/selectors/simpleValueSelector';
import { Field } from 'redux-form';
import { dataTypeOptions } from '../../../../../backgroundProcess/controller/redux/analyzeActions';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getLiteDevices, getLiteCollectorDevices, getLitePublicCloudDevices } from '../../../../../deviceManagement/controller/lightweightReducer/actions';

class InputGroup2 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount = async () => {
		this.props.getLiteDevices();
		this.props.getLiteCollectorDevices();
		this.props.getLitePublicCloudDevices();
	};

	render() {
		const { publicDevices, collectorDevices, publicCloudDevices } = this.props;
		const allDevices = { ...publicDevices, ...collectorDevices, ...publicCloudDevices };

		const options = [];
		for (const id in allDevices) {
			options.push({
				value: id,
				label: allDevices[id].name,
				direction: 'i',
				type: 'device',
			});
		}

		return (
			<div className="alertes-inputs-line2">
				<div className="alerte-devices">
					<InputWrapper name={ 'Devices' } additionalClasses={'react-select-redux-forms-extra'} input={
						<Field
							name="devices"
							component={ DevicesInterfacesSelector }
							options={ options }
							additionalClasses={'react-select-redux-forms-extra'}
							selectorStyles={customSelectorStyles}
							placeholder="My devices"
						/> } />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	publicDevices: state.liteDevices.publicDevices,
	collectorDevices: state.liteDevices.collectorDevices,
	publicCloudDevices: state.liteDevices.publicCloudDevices,
});

const mapDispatchToProps = {
	getLiteDevices,
	getLiteCollectorDevices,
	getLitePublicCloudDevices,
};
export default connect(mapStateToProps, mapDispatchToProps)(InputGroup2);
