import {
    SELECT_POINT, 
    REMOVE_EVENT_POINT
} from './graphActions';

const initialState = {
    newPoint: null,
    event : null
};



export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SELECT_POINT:
            state.newPoint = payload.newPoint;
            state.event = payload.event;
            return { ...state };

        case REMOVE_EVENT_POINT:
            state.event = null;
            return { ...state };
        default:
            return { ...state };
    }
}
