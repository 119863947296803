import React from 'react';
import { connect } from 'react-redux';
import { compose } from "redux";
import { withRouter } from "react-router";
import SuccessMessage from "../../shared/components/form/SuccessMessage";
import LayoutForm from "../../shared/components/form/LayoutForm";
import _ from 'lodash'
import SubmitButton from '../../shared/components/form/Buttons/SubmitButton';
import './thankyou.scss';

class ThankYou extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <LayoutForm>
                <div className="form thankyou-box">
                    <div>
                        <div className="thank-you-text">A verification email has been sent to your inbox.</div>
                        <div className="thank-you-text">Please click the activation link
                            to complete your registration.</div>
                    </div>
                </div>
            </LayoutForm>
        );
    }
}

const mapStateToProps = state => ({
});
const mapDispatchToProps = {
};
export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(ThankYou)
