import { useEffect, useState } from 'react';
import {
	calculateGraphTimesByTimeframe,
	getHigherLowerPoint,
	isToday,
	getTodayHoursInMinutes,
} from './timeManipulations';
import {
	DROPDOWN_DAY_VALUE,
	DROPDOWN_CUSTOM_VALUE,
	DROPDOWN_DAY_LABEL,
	DRAG_ZOOM_EVENT,
	DROPDOWN_CUSTOM_LABEL,
	DROPDOWN_ITEMS,
} from './config';
const useStockGraphWithZoom = ({ chartRef,
	data,
	date,
	onPointSelect,
	removeLastClickedCircle,
	removeLastHoveredCircle,
	minDate,
	maxDate }) => {
	const [isResetDisabled, setIsResetDisabled] = useState(true);
	const [dropdownItems, setDropdownItems] = useState([{ label: DROPDOWN_DAY_LABEL, value: DROPDOWN_DAY_VALUE }]);
	const [dropdownValue, setDropdownValue] = useState({ value: DROPDOWN_DAY_VALUE });

	useEffect(() => {
		resetDropdownItems();
	}, [data]);

	const handleZoomIn = value => {
		removeLastClickedCircle();
		removeLastHoveredCircle();

		setIsResetDisabled(false);

		calculateXExtremes(value);
	};

	const handleZoomOut = () => {
		removeLastClickedCircle();
		removeLastHoveredCircle();

		calculateYExtremes(minDate, maxDate);

		setIsResetDisabled(true);

		resetDropdownItems();
		setDropdownValue({ value: DROPDOWN_DAY_VALUE });

		if (chartRef.current.chart) {
			chartRef.current.chart.xAxis[0].setExtremes(minDate, maxDate);
		}
	};

	const onZoom = event => {
		removeLastClickedCircle();
		removeLastHoveredCircle();
		onPointSelect(null);

		if (event.trigger === DRAG_ZOOM_EVENT) {
			if (dropdownValue.value !== DROPDOWN_CUSTOM_VALUE) {
				setDropdownItems([...dropdownItems, { label: DROPDOWN_CUSTOM_LABEL, value: DROPDOWN_CUSTOM_VALUE }]);
				setDropdownValue({ value: DROPDOWN_CUSTOM_VALUE });
			}

			setIsResetDisabled(false);

			calculateYExtremes(event.min, event.max);
		}
	};

	const handleDropdownChange = item => {
		setDropdownValue(item);
		resetDropdownItems();
		if (item.value === DROPDOWN_DAY_VALUE) {
			handleZoomOut();
		} else {
			handleZoomIn(item.value);
		}
	};

	const calculateXExtremes = value => {
		const newMinValue = calculateGraphTimesByTimeframe(maxDate, value);

		if (chartRef.current.chart) {
			chartRef.current.chart.xAxis[0].setExtremes(newMinValue, maxDate);
		}

		calculateYExtremes(newMinValue, maxDate);
	};

	const calculateYExtremes = (minX, maxX) => {
		const relevantPoints = data.filter(point => point.x >= minX && point.x <= maxX);

		if (!relevantPoints[0]) {
			return;
		}

		const { lowestValue, highestValue } = getHigherLowerPoint(relevantPoints);

		if (chartRef.current.chart) {
			chartRef.current.chart.yAxis[0].setExtremes(lowestValue, highestValue);
		}
	};

	const resetDropdownItems = () => {
		let relevantHours = DROPDOWN_ITEMS;
		if (isToday(date)) {
			const todayHour = getTodayHoursInMinutes();
			// If default label or hours that are passed this day
			relevantHours = relevantHours.filter(item => item.value <= todayHour || item.value === DROPDOWN_DAY_VALUE);
		}

		setDropdownItems(relevantHours);
	};

	return {
		handleDropdownChange,
		handleZoomIn,
		handleZoomOut,
		onZoom,
		isResetDisabled,
		dropdownItems,
		dropdownValue,
	};
};

export default useStockGraphWithZoom;
