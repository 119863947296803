import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import CopyButtonComponent from '../../../../shared/components/copyButtonComponent/copyButtonComponent';
import SpanWithTranslation
	from '../../collapsibleRecordComponent/collapsibleRecordSummary/spanWithTranslation/spanWithTranslation';

import useStyles from '../../collapsibleRecordComponent/collapsibleRecordComponent.css';
import { formatBase10 } from '../../formatters';

const RecordSummary = ({
	value,
	valueTranslation,
	count,
}) => {
	const classes = useStyles();

	return (
		<Grid
			container
			display={'inline-flex'}
			width={'100%'}
			justifyContent={'space-between'}
			xs={12}
		>
			<Grid item xs={5}>
				<SpanWithTranslation translation={valueTranslation} additionalClasses={classes.summaryValues}>
					{value}
				</SpanWithTranslation>
			</Grid>
			<Grid item xs={3}>
				<Typography className={`${classes.summaryValues} ${classes.count}`}>
					{formatBase10(count)}
				</Typography>
			</Grid>
			<Grid item container justifyContent={'flex-end'} xs={4} className={classes.icons}>
				<div className={classes.copy}>
					<CopyButtonComponent
						valueToCopy={value}
						successMessage={'Copied!'}
						labelSize={'xs'}
						iconWidth={20}
						iconHeight={20}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

RecordSummary.propTypes = {
	count: PropTypes.number.isRequired,
	onClick: PropTypes.func,
	value: PropTypes.string.isRequired,
	valueTranslation: PropTypes.string,
};

export default RecordSummary;
