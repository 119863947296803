import http from '../../services/httpService/http';
import {ACCOUNTS_URI} from "../app/controller/services/urls";


const sendInvitations = async (data) => {
    return await http.post(`${ACCOUNTS_URI}/refer-a-friend`, data);
}

export {
    sendInvitations
}