import http from '../../../services/httpService/http';
import { convertInputToRuleBody, formatRulesFromServer } from './transformers';

const ACCEPTLIST_PATH = 'allowlist/v1';

export const getRules = async (accountGroups, accountDevices) => {
	const rules = await http.get(ACCEPTLIST_PATH);
	return formatRulesFromServer(rules, accountGroups, accountDevices);
};

export const createRule = async rule => {
	const ruleParameters = convertInputToRuleBody(rule);
	await http.post(ACCEPTLIST_PATH, ruleParameters);
};

export const updateRule = async rule => {
	const ruleId = rule.id;
	const ruleParameters = convertInputToRuleBody(rule);
	await http.put(`${ACCEPTLIST_PATH}/${ruleId}`, ruleParameters);
};

export const deleteRule = async ruleId => {
	await http._delete(`${ACCEPTLIST_PATH}/${ruleId}`);
};

export const getDefinition = async (definitionId, params) => http.get(`${ACCEPTLIST_PATH}/definitions/${definitionId}`, params);
