import {ACTIVATE_PLANS_SELECTION_FLOW, SET_CURRENT_PLAN_DETAILS} from "./actionTypes";
import action from "../../app/controller/redux/middleware";
import {geCurrentPlanDetails} from "../services/planRequests";

const setPlansFlowActivation = (isActive, usePreFlowDialog, feature) => {
    return action(async dispatch => {
        dispatch({
            type: ACTIVATE_PLANS_SELECTION_FLOW,
            payload: {isPlansSelectionFlowActive: isActive, usePreFlowDialog, feature}
        });
    });
}

const setCurrentPlanDetails =  () => {
    return action(async dispatch => {
        const planDetails = await geCurrentPlanDetails();

        dispatch({
            type: SET_CURRENT_PLAN_DETAILS,
            payload: planDetails
        });
    });
}

export {setPlansFlowActivation, setCurrentPlanDetails}