import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '449px',
        width: '332px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '4px'
    },

    popularPaper: {
        //490px
        height: '485px',
        borderColor: theme.palette.text.secondary,
        borderRadius: '0px 0px 4px 4px'
    },

    popularBorder:{
        '&::before': {
            content: (props) =>  `"${props.mostPopularText}"`,
            backgroundColor: theme.palette.text.secondary,
            color: 'white',
            fontSize: '16px',
            textAlign: 'center',
            display: 'block',
            height: '36px',
            borderRadius: '4px 4px 0px 0px',
            lineHeight: '36px'
        }
    },
    popularTag:{
        height: '36px',
        backgroundColor: theme.palette.text.secondary,
        display: 'block'
    },

    selectButton: {
        height: '50px',
        width: '250px'
    },

    planName:{
        fontWeight: 'normal',
        textTransform: 'uppercase',
        fontSize: '16px',
        margin: '40px 25px 25px 25px'
    },

    planDetail:{
    }
}));

export {useStyles}