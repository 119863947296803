import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DetectionsTable from '../../shared/components/detectionsTable/detectionsTable';
import { setTitle } from '../app/controller/redux/app/appActions';
import { getLightweightData } from './services/vcaRequests';
import {
	DETECTIONS_TO_DISPLAY,
	MAIN_TABLE_CONFIG,
	PAGES_TO_PRELOAD,
	SEVERITIES_TO_DISPLAY,
	VCA_CONFIG,
	DEFAULT_PAGE_SIZE,
} from './vcaManagementConfig';
import { getDaysBackDateToNow } from '../../helpers/datesHelper';
import useStyles from './vcaPage.css';
import FiltersBlock from './filters/filtersBlock';
import { LAST_48_HOURS_VALUE } from './filters/filtersConfig';
import PropTypes from 'prop-types';
import Storage from "../../services/storage";

const BROWSER_HEIGHT_BUFFER = 0.7;
const DETECTION_HEIGHT = 65;

function VcaPage({ history }) {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [filters, setFilters] = useState(() => {
		const rangeFilter = parseInt(Storage.get('dcTimeRangeFilter')) || LAST_48_HOURS_VALUE;
		return { timeRange: getDaysBackDateToNow(rangeFilter) };
	});
	const [height, setHeight] = useState(document.documentElement.offsetHeight);

	useEffect(() => {
		dispatch(setTitle(VCA_CONFIG.pageTitle));
	}, []);

	useEffect(() => {
		const updateHeight = () => {
			setHeight(document.documentElement.offsetHeight);
		};

		window.addEventListener('resize', updateHeight);
		return () => window.removeEventListener('resize', updateHeight);
	}, []);

	const getDetectionsNumberToDisplay = () => Math.floor((height * BROWSER_HEIGHT_BUFFER) / DETECTION_HEIGHT) || DEFAULT_PAGE_SIZE;

	const fetchDetections = async (
		startPage,
		endPage,
		sortKey,
		sortDirection,
		filters,
	) => {
		const { timeRange, highlighted } = filters;

		const paginationRequests = [];
		for (let i = startPage; i <= endPage; i++) {
			const request = getLightweightData(
				DETECTIONS_TO_DISPLAY,
				timeRange?.from,
				timeRange?.to,
				getDetectionsNumberToDisplay(),
				i,
				SEVERITIES_TO_DISPLAY,
				sortKey,
				sortDirection,
				{ highlighted: highlighted?.value },
			);
			paginationRequests.push(request);
		}

		const response = await Promise.all(paginationRequests);
		return {
			results: response.map(promise => promise.data).flat(),
			totalResults: response[0].totalResults,
		};
	};

	return (
		<div className={classes.container}>
			<FiltersBlock
				onFiltersChange={setFilters}
				initialFilters={filters}
			/>
			<DetectionsTable
				history={history}
				columns={MAIN_TABLE_CONFIG}
				pageSize={getDetectionsNumberToDisplay()}
				fetchDetections={(
					startPage,
					endPage,
					sortKey,
					sortDirection,
					filters,
				) =>
					fetchDetections(startPage, endPage, sortKey, sortDirection, filters)
				}
				pagesToPreload={PAGES_TO_PRELOAD}
				filters={filters}
			/>
		</div>
	);
}

VcaPage.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export default VcaPage;
