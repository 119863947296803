import React from 'react';
import ErrorIndication from "../../../../../../shared/components/IndicationComponent/types/ErrorIndication";
import SuccessIndication from "../../../../../../shared/components/IndicationComponent/types/SuccessIndication";

const attachCallbackToErrorComponent = (callback) => <ErrorIndication callback={callback} header={"Something went wrong"} description={"Unfortunately, your form was not submitted.\n Please try again later."} buttonText={"Ok"} />;
const attachCallbackToSuccessComponent = (callback) => <SuccessIndication callback={callback} header={"Congrats, Your order is confirmed!"} description={"Your changes will be applied as scheduled"} buttonText={"Finish"}/>;

const FEEDBACK_FORM_TITLE = "We're sorry to see you go!";
const FEEDBACK_FORM_SUBTITLE = "Would you tell us why? We might be able to help.";
const FEEDBACK_OPTIONS = [
    "Only needed the service occasionally",
    "Features in different plan are sufficient",
    "Plan is too expensive",
    "Important features are missing",
    "Other reason"
]
const FEEDBACK_FORM_INITIAL_VALUES = {...FEEDBACK_OPTIONS.map( () => false )}

const ORDER_SUMMARY_TITLE = 'Thanks for your feedback! Please review the details to change your plan';

export {
    FEEDBACK_FORM_TITLE,
    FEEDBACK_FORM_SUBTITLE,
    attachCallbackToErrorComponent,
    attachCallbackToSuccessComponent,
    FEEDBACK_OPTIONS,
    FEEDBACK_FORM_INITIAL_VALUES,
    ORDER_SUMMARY_TITLE
}