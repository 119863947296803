import http from "../../../../services/httpService/http";
import {DAILY_REPORT_PATH} from "../constants/urls";

const getDailyReportIntegration = (config) => {
    return http.get(DAILY_REPORT_PATH, config);
}

const activateDailyReportIntegration = (dailyReportPolicyId, newValue) => {
    return http.put(`${DAILY_REPORT_PATH}/toggle/${dailyReportPolicyId}`, { Enabled: newValue });
}

const createDailyReportPolicy = (payload) => {
    return http.post(DAILY_REPORT_PATH, payload)
}

const updateDailyReportPolicy = (reportPolicyId, toUpdateObject) => {
    return http.put(`${DAILY_REPORT_PATH}/${reportPolicyId}`, toUpdateObject);
}

export {
    getDailyReportIntegration,
    activateDailyReportIntegration,
    createDailyReportPolicy,
    updateDailyReportPolicy
}