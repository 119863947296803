import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	tabContainer: {
		width: '100% !important',
		justifyContent: 'space-between',
	},
	tab: {
		minWidth: 'unset',
		fontWeight: '700',
		textTransform: 'none',
	},
	columns: {
		padding: '5px 21px',
	},
}));

export default useStyles;
