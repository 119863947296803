import action from '../../../app/controller/redux/middleware';
import { SET_ERROR } from '../../../app/controller/redux/message/messageActions';
import http from '../../../../services/httpService/http';
import { attackLevels } from '../../../../services/enums';
import SummaryData from '../../../../helpers/summaryData';
import _ from 'lodash';
import moment from 'moment';
import { setDeviceAttackStatuses } from '../../../SummaryView/controller/redux/attackStatus/actions';
import { SET_STATUSES } from '../../../SummaryView/controller/redux/attackStatus/actionTypes';
import { ANALYZE_DATA_URI } from '../services/backgroundProcess/urls';
import { GET_ANALYZE_DATA_LATEST, IP_GROUP_ANOMALIES_PATH } from '../services/visibilityView/urls';
import { getTotalPackets } from '../services/visibilityView/requests';
import { getDetectionsCount } from '../../../vcaManagement/services/vcaRequests';
import { getInsightsAsync } from '../../../SummaryView/controller/services/insights/requests';
import { WEB_ATTACK_QUERY_TYPE_FEATURE_TOGGLE } from '../../../../constants/featureToggles';

export const ANALYSE_DATA_SWITCH = 'ANALYSE_DATA_SWITCH';
export const ANALYZE_DATA = 'ANALYZE_DATA';
export const LOADING_ANALYSIS = 'LOADING_ANALYSIS';
export const UPDATE_SUMMARY = 'UPDATE_SUMMARY';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVE_ANOMALISES = 'REMOVE_ANOMALISES';
export const GET_TOTALS = 'GET_TOTALS';
export const ENRICH_NOTIFICATION = 'ENRICH_NOTIFICATION';
export const dataTypeOptions = [
	{
		prefix: 'Number of',
		shorter: 'Packets',
		label: 'Packets over time',
		value: 1,
		accessor: 'est_packets',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'Flows',
		label: 'Flows over time',
		value: 2,
		accessor: 'est_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Trurlsaffic',
		shorter: 'Volume',
		label: 'Volume over time',
		value: 3,
		accessor: 'est_volume',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: '',
		shorter: 'Attack prob',
		label: 'Attack prob over time',
		value: 4,
		accessor: 'attack_prob',
		isPerSecondSupported: false,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'Src IPs',
		label: 'Unique Source IPs',
		value: 5,
		accessor: 'est_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'Dst IPs',
		label: 'Unique Dest IPs',
		value: 6,
		accessor: 'est_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'New Flow',
		label: 'New Flows',
		value: 7,
		accessor: 'est_new_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'New Source',
		label: 'New Source IPs',
		value: 8,
		accessor: 'est_new_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'New Dest IPs',
		label: 'New Dest IPs',
		value: 9,
		accessor: 'est_new_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'Same Flows',
		label: 'Same Flows',
		value: 10,
		accessor: 'est_same_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'Same IPs',
		label: 'Same Source IPs',
		value: 11,
		accessor: 'est_same_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: 'Number of',
		shorter: 'Same Dest IPs',
		label: 'Same Dest IPs',
		value: 12,
		accessor: 'est_same_flows',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: '',
		shorter: 'Pkts size Avg',
		label: 'Packets Size Average',
		value: 13,
		accessor: 'est_avg_size',
		isPerSecondSupported: false,
		queryType: 'timeUnit',
		isAlert: true,
	},
	{
		prefix: '',
		shorter: 'Pkts size Dist',
		label: 'Packets Size Distribution',
		value: 14,
		accessor: 'est_size_distribution',
		isPerSecondSupported: false,
		queryType: 'numberUnit',
		isAlert: false,
	},
	{
		prefix: 'Number of',
		shorter: 'Dest services',
		label: 'Max num of dest services',
		value: 15,
		accessor: 'max_num_dst_service_conn',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	},
	...WEB_ATTACK_QUERY_TYPE_FEATURE_TOGGLE ? [{
		prefix: '',
		shorter: 'Web attacks',
		label: 'Web attacks',
		value: 16,
		accessor: 'web_classifier',
		isPerSecondSupported: true,
		queryType: 'timeUnit',
		isAlert: true,
	}] : [],
];

const getAnalyzeData = params => action(async dispatch => {
	dispatch({
		type: LOADING_ANALYSIS,
	});
	const res = await http.post(ANALYZE_DATA_URI, params);

	if (!res) {
		return null;
	}

	if (!_.isEmpty(res.error) && res.status === 401) {
		dispatch({
			type: SET_ERROR,
			message: res.error,
		});
		return;
	}

	res.analyzed_df = JSON.parse(res.analyzed_df);
	res.mid_attacks = JSON.parse(res.mid_attacks);
	res.high_attacks = JSON.parse(res.high_attacks);
	const hasData = Boolean(Object.values(res.analyzed_df).length);
	let analyzedData = { hasData };
	if (hasData) {
		let data;
		const est_data = dataTypeOptions.find(dt => dt.value === params.queryTypes[0]);
		if (est_data.queryType === 'timeUnit') {
			data = processAnalysis(res, params.fromTimestamp, params.toTimestamp, est_data.accessor);
			analyzedData = { ...data, ...analyzedData, message: res.message, status: parseInt(res.attack_status) };
		} else if (est_data.queryType === 'numberUnit') {
			data = processSimpleAnalysis(res, est_data.accessor);
			analyzedData = { ...data, ...analyzedData, message: res.message, status: parseInt(res.attack_status) };
		} else {
			console.log('Query Type not supported');
		}
	}

	dispatch({
		type: ANALYZE_DATA,
		payload: { analyzedData },
	});

	return analyzedData;
});

const getAnalyzeDataChangeWithTimeWindow = (analyzedData, est_data, timeWindow) => action(async dispatch => {
	const newObject = manipulateAnalyzedData(analyzedData, est_data, timeWindow);

	dispatch({
		type: ANALYSE_DATA_SWITCH,
		payload: { newObject },
	});
	return newObject;
});

export const manipulateAnalyzedData = (analyzedData, est_data, timeWindow) => {
	const toReturn = _.cloneDeep(analyzedData);

	if (!_.isEmpty(analyzedData) && analyzedData.hasData
		&& analyzedData[est_data]) {
		const analysisLevel = Object.keys(toReturn[est_data]);

		for (let keyindex = 0; keyindex < analysisLevel.length; keyindex++) {
			const key = analysisLevel[keyindex];

			for (let index = 0; index < toReturn[est_data][key].points.length; index++) {
				const element = toReturn[est_data][key].points[index];

				const { topTalkers } = element;

				topTalkers.forEach((o, i) => {
					element.topTalkers[i].Count /= timeWindow;
				});

				toReturn[est_data][key].points.splice(index, 1, {
					y: element.y / timeWindow,
					topTalkers: element.topTalkers,
					x: element.x,
				});
			}
		}
	}

	return toReturn;
};

const processSimpleAnalysis = (data, est_data) => ({
	[est_data]: convertSimpleData(data.analyzed_df),
});

const convertSimpleData = data => {
	const XValue = Object.values(data.packet_size);
	const YValue = Object.values(data.packet_count);
	const newDataArray = XValue.map((p, index) => ({
		x: XValue[index],
		y: YValue[index],
	}));
	return newDataArray;
};

const getTotals = () => action(async (dispatch, getState) => {
	const state = getState();

	const DAY_IN_MILLI_SECONDS = 1000 * 60 * 60 * 24;
	const twoDaysAgo = Date.now() - (Number(DAY_IN_MILLI_SECONDS));
	const insightRequestParams = { fromTimeStamp: moment().subtract(1, 'day').unix(), toTimeStamp: moment().unix() };
	const [dailyDetectionsCountResult, insights] = await Promise.allSettled([
		getDetectionsCount(twoDaysAgo, Date.now()),
		getInsightsAsync(insightRequestParams), // Temporary, Will be deleted when insights will be added to detection center
	]);

	const dailyDetectionsCount = dailyDetectionsCountResult.status === 'fulfilled' ? dailyDetectionsCountResult.value.count : 0;
	const insightCount = insights.status === 'fulfilled' ? insights.value.length : 0; // Temporary, Will be deleted when insights will be added to detection center

	let totals = {
		dailyDetectionsCount: dailyDetectionsCount + insightCount,
		...(state.analyze.totals ? { dailyPackets: state.analyze.totals.dailyPackets } : {}),
	};

	await dispatch({
		type: GET_TOTALS,
		payload: { totals },
	});

	const dailyPacketsResult = await getTotalPackets();

	totals = {
		dailyDetectionsCount: dailyDetectionsCount + insightCount,
		dailyPackets: dailyPacketsResult ? dailyPacketsResult.totalPackets : 0,
	};

	await dispatch({
		type: GET_TOTALS,
		payload: { totals },
	});

	return totals;
});

const enrichNotification = () => action(async (dispatch, getState) => {
	const state = getState();
	const { anomalies } = state.analyze.summaryData;
	const { groups } = state.account;

	const fromTimeStamp = moment().subtract(1, 'm').unix();
	const toTimeStamp = moment().unix();

	const lastAnomalize = await http.get(IP_GROUP_ANOMALIES_PATH, {
		params: { fromTimeStamp, toTimeStamp },
	});

	const mappedValues = lastAnomalize.map(anomaly => {
		const groupsAsset = _.groupBy(anomaly.assets, 'type');
		const AssetKeys = Object.keys(_.isEmpty(groupsAsset) ? [] : groupsAsset);
		let globalMessage = [];
		const message_start = `${_.capitalize(anomaly.severity.toLowerCase())} severity network anomaly`;
		globalMessage.push(message_start);
		AssetKeys.forEach(asset => {
			const messageType = `, ${_.startCase(_.camelCase(asset))}s: `;

			const valuesTest = groupsAsset[asset].map(r => {
				switch (r.type) {
					case 'SUSPICIOUS_PORT':
						return `${r.value} (${r.metadata.type.toUpperCase()})`;
					case 'SUSPICIOUS_CONNECTION':
						return `${Object.values(r.value).join(' - ')}`;
					case 'SUSPICIOUS_IP':
						return `${r.value} (${r.metadata.type.toUpperCase()})`;
					default:
						return '';
				}
			}).join(', ');
			globalMessage = globalMessage.concat([messageType, valuesTest]);
		});
		return {
			id: anomaly._id,
			severity: anomaly.severity.toLowerCase(),
			time: anomaly.timestamp * 1000,
			message: globalMessage.join(''),
			groupId: anomaly.ipGroupId,
		};
	});
	const mappedarray = mappedValues.map(e => ({
		...e,
		title: `IP Group - ${_.find(groups, q => q._id === e.groupId).title}`,
	}));
	const allId = mappedarray.map(e => e.id);
	const mNewAnomalize = anomalies.filter(e => !allId.includes(e.id));
	const uniqAnomalize = _.uniqBy(anomalies.concat(mNewAnomalize).concat(mappedarray), 'id');
	SummaryData.updateAnomalizes(uniqAnomalize);
	dispatch({
		type: ENRICH_NOTIFICATION,
		payload: {
			anomalies: uniqAnomalize,
		},
	});
});

const getSummaryData = () => action(async (dispatch, getState) => {
	const state = getState();
	let summaryData = {};
	let data;

	// TODO: WE MUST REFACTOR THIS CODE
	try {
		data = await http.get(GET_ANALYZE_DATA_LATEST, { includeTotals: true });
	} catch {
		return;
	}

	if (!data) {
		return null;
	}

	data.analyzed_df = JSON.parse(data.analyzed_df);
	data.mid_attacks = JSON.parse(data.mid_attacks);
	data.high_attacks = JSON.parse(data.high_attacks);
	data.attack_status = JSON.parse(data.attack_status);
	data.message = JSON.parse(data.message);

	const hasData = Boolean(Object.values(data.analyzed_df).length);
	summaryData = {
		...summaryData,
		hasData,
	};

	const devices = state.liteDevices.publicDevices;
	const { collectorDevices } = state.liteDevices;
	const { publicCloudDevices } = state.liteDevices;
	const prevAttackStatuses = state.attackStatuses.deviceStatuses;
	const allDevices = { ...devices, ...collectorDevices, ...publicCloudDevices };
	const currentSummaryData = state.analyze.summaryData; // Undefined?

	if (hasData) {
		const { analyzed_df, mid_attacks, high_attacks, _id, createdAt } = data;

		const { est_packets, est_flows, est_volume, time_end } = analyzed_df;

		const processed = {
			volumeOverTime: convertDataset(time_end, est_volume, analyzed_df, mid_attacks, high_attacks, 'est_volume'),
			packetsOverTime: convertDataset(time_end, est_packets, analyzed_df, mid_attacks, high_attacks, 'est_packets'),
			flowsOverTime: convertDataset(time_end, est_flows, analyzed_df, mid_attacks, high_attacks, 'est_flows'),
		};

		dispatch({
			type: SET_STATUSES,
			payload: data.attack_status,
		});
		const attackStatuses = Object.values(data.attack_status);
		const maxAttackStatus = Math.max(...attackStatuses);
		summaryData = {
			...processed,
			...summaryData,
			attackStatus: maxAttackStatus,
		};

		summaryData.notifications = SummaryData.updateNotifications(
			data.attack_status, data.message, allDevices, currentSummaryData, dispatch, _id, createdAt, prevAttackStatuses);
	} else {
		// Reset devices attack statuses
		dispatch({
			type: SET_STATUSES,
			payload: {},
		});

		summaryData = {
			volumeOverTime: SummaryData.getDataset('est_volume'),
			packetsOverTime: SummaryData.getDataset('est_packets'),
			flowsOverTime: SummaryData.getDataset('est_flows'),
			...summaryData,
			attackStatus: 0,
		};
	}

	dispatch({
		type: UPDATE_SUMMARY,
		payload: { summaryData },
	});
	return summaryData;
});

const processAnalysis = (data, fromTime, toTime, est_data) => {
	const { analyzed_df, mid_attacks, high_attacks } = data;

	const { time_start } = analyzed_df;

	return {
		[est_data]: convertDataset(time_start, analyzed_df[est_data], _.pick(analyzed_df, ['top_dest_ports', 'top_source_ports', 'top_protocols', 'top_talkers']), mid_attacks, high_attacks),
	};
};

const convertDataset = (_aXis, _yXis, _topTalkers, _midAttacks, _highAttacks, queryType = null) => {
	let ret = [];
	const midAttacks = Object.values(_midAttacks);
	const highAttacks = Object.values(_highAttacks);
	const aXis = Object.values(_aXis);
	const yXis = Object.values(_yXis);

	const topTalkers = Object.values(_topTalkers.top_talkers);
	const topSourcePort = Object.values(_topTalkers.top_source_ports);
	const topDestinationPort = Object.values(_topTalkers.top_dest_ports);
	const topProtocol = Object.values(_topTalkers.top_protocols);

	const dsPoints = Object.values(aXis).map((x, i) =>
		// Return {x, y: scaleY ? scaleY * yXis[i] : yXis[i]};
		({
			x, y: yXis[i],
			topTalkers: topTalkers[i],
			topSourcePort: topSourcePort[i],
			topDestinationPort: topDestinationPort[i],
			topProtocol: topProtocol[i],
		}),
	);
	ret = highAttacks.map(arr => {
		const points = dsPoints.filter(dp => dp.x >= arr[0] && dp.x <= arr[1]); // Take all x axis points that are in each high attack time range
		// const points = arr.map(x => {
		//     const pt = dsPoints.find(dp => dp.x === x);
		//     return {x, y: pt ? pt.y : dsPoints[dsPoints.length - 1].y}
		// });
		return { level: attackLevels.SEVERE, points };
	}).concat(midAttacks.map(arr => {
		const points = dsPoints.filter(dp => dp.x >= arr[0] && dp.x <= arr[1]); // Take all x axis points that are in each mid attack time range
		// const points = arr.map(x => {
		//     const pt = dsPoints.find(dp => dp.x === x);
		//     return {x, y: pt ? pt.y : dsPoints[dsPoints.length - 1].y}
		// });
		return { level: attackLevels.WARNING, points };
	}));
	let section;
	dsPoints.forEach(dp => {
		if (isInSections(highAttacks, dp) || isInSections(midAttacks, dp)) { // If current point is in high/mid attack sections
			if (section) { // If there is already a section, initalize
				section = null;
			}
		} else {
			if (!section) { // If current time isn't in high/mid attack sections
				section = { level: attackLevels.NONE, points: [] };
				ret.push(section);
			}

			section.points.push(dp);
		}
	});
	// Const xMinDp = Math.min(...dsPoints.map(p => p.x));
	// const xMaxDp = Math.max(...dsPoints.map(p => p.x));
	// if (aXis.indexOf(xStart) === -1)
	//     ret.push({level: attackLevels.NONE, points: [{x: xStart, y: 0}, {x: xMinDp - 1000, y: 0}]});
	// if (aXis.indexOf(xEnd) === -1)
	//     ret.push({level: attackLevels.NONE, points: [{x: xMaxDp + 1000, y: 0}, {x: xEnd, y: 0}]});
	ret = ret.sort((sec1, sec2) => sec1.points[0].x - sec2.points[0].x);

	console.log(ret, ret[0], queryType);

	return queryType ? SummaryData.enqueue(queryType, ret[0]) : ret;
};

const removeNotification = id => action(async dispatch => {
	const notifications = SummaryData.removeNotification(id);
	dispatch({
		type: REMOVE_NOTIFICATION,
		payload: { notifications, removed: id },
	});
});

const removeAnomaly = id => action(async dispatch => {
	const anomalies = SummaryData.removeAnomaly(id);

	dispatch({
		type: REMOVE_ANOMALISES,
		payload: { anomalies, removed: id },
	});
});
const isInSections = (sections, point) => // Find the section that contains the point time
	sections.find(sec => sec[0] <= point.x && sec[1] >= point.x);

export {
	getAnalyzeData,
	getSummaryData,
	processSimpleAnalysis,
	processAnalysis,
	removeNotification,
	removeAnomaly,
	getAnalyzeDataChangeWithTimeWindow,
	getTotals,
	enrichNotification,
	setDeviceAttackStatuses,
};
