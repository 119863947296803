import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Grid, Typography } from '@material-ui/core';
import PaperFrame from '../../../shared/components/PaperFrame/PaperFrame';
import StyleButton from '../../../shared/components/form/Buttons/StyleButton';
import { setPlansFlowActivation } from '../../plans/redux/plansActions';
import { useStyles } from './PaymentFrame.css';
import { CreditCardOptions, getCreditCardData } from './creditCardConfiguration';
import SubscriptionType from './subscriptionTypeHeader';
import TextFrame from './textFrame';
import clsx from 'clsx';
import { getAddressString } from '../services/addressHelper';
import ChangePaymentMethod from '../../paymentMethod/changePaymentMethod/changePaymentMethod';
import PropTypes from 'prop-types';

const PaymentFrame = ({ paymentInfo, currentPlan, displayPaymentMethodSection, fetchPaymentInfo }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [currentPaymentMethodType, setCurrentPaymentMethodType] = useState('');
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState();
	const [creditCardInfo, setCreditCardInfo] = useState(CreditCardOptions.default);
	const [changePaymentMethodOpen, setChangePaymentMethodOpen] = useState(false);

	useEffect(() => {
		if (displayPaymentMethodSection) { // Should be true if payment type is credit card
			const { type: paymentType } = paymentInfo;
			setCurrentPaymentMethod(paymentInfo[paymentType]);
			const ccInfo = getCreditCardData(paymentInfo[paymentType].brand);
			setCreditCardInfo(ccInfo);
			setCurrentPaymentMethodType(paymentType);
		}
	}, [displayPaymentMethodSection, paymentInfo]);

	const openChangePlanModel = () => {
		dispatch(setPlansFlowActivation(true, false));
	};

	const expiresText = () => {
		const { exp_month: expMonth } = currentPaymentMethod;
		const { exp_year: expYear } = currentPaymentMethod;

		return `${expMonth}`.padStart(2, '0') + '/' + `${expYear}`.slice(2);
	};

	return (
		<PaperFrame elevation={1} title={'Payment'} minHeight="30em" height="30em" width="920">
			<Grid xs={12} container className={classes.topContainer}>
				<Grid item xs={12}>
					<SubscriptionType currentPlanName={currentPlan.productName} onClick={openChangePlanModel}/>
				</Grid>
				{displayPaymentMethodSection
					&& currentPaymentMethod
					&& <Grid item container>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid container direction={'row'} xs={12}>
							<Grid item xs={3}>
								<TextFrame title={'PAYMENT METHOD'}>
									<div className={classes.icon}>
										<creditCardInfo.icon />
									</div>
									<div className={classes.displayInlineFlex}>
										<Typography className={classes.smallText}>
											{currentPaymentMethod.last4.padStart(8, '*')}
										</Typography>
										<span className={classes.ccDivider}>
											|
										</span>
										<Typography className={classes.smallText}>
											Expires {expiresText()}
										</Typography>
									</div>
									<Typography variant={'body1'} className={clsx(classes.boldName, classes.smallText)}>{paymentInfo.billing_details.name}</Typography>
								</TextFrame>
							</Grid>
							<Grid item xs={5}>
								<TextFrame title={'BILLING INFORMATION'}>
									<Typography className={clsx(classes.boldName, classes.bigText)} variant={'body1'}>{paymentInfo.billing_details.name}</Typography>
									<Typography className={classes.bigText}>{paymentInfo.billing_details.email}</Typography>
									<Typography className={classes.bigText}>{paymentInfo.billing_details.phone}</Typography>
									<Typography className={classes.bigText}>{getAddressString(paymentInfo.billing_details.address)}</Typography>
								</TextFrame>
							</Grid>

							<Grid item xs={4} className={classes.updateButtonSection}>
								<StyleButton className={classes.button} label={'UPDATE'} variant={'outlined'} onClick={() => setChangePaymentMethodOpen(true)}/>
							</Grid>

							{ changePaymentMethodOpen && <ChangePaymentMethod isOpen={changePaymentMethodOpen}
								closeModal={() => setChangePaymentMethodOpen(false)}
								paymentMethodType={currentPaymentMethodType}
								fetchPaymentInfo={fetchPaymentInfo}
								selectedBaseProduct={currentPlan}/>
							}
						</Grid>

					</Grid>}
			</Grid>
		</PaperFrame>

	);
};

PaymentFrame.propTypes = {
	paymentInfo: PropTypes.shape({
		type: PropTypes.shape({
			brand: PropTypes.string,
		}),
		billing_details: PropTypes.shape({
			name: PropTypes.string,
			email: PropTypes.string,
			phone: PropTypes.string,
			address: PropTypes.string,
		}),
	}),
	currentPlan: PropTypes.shape({
		baseProductId: PropTypes.string,
		priceKey: PropTypes.string,
		productName: PropTypes.string,
	}),
	displayPaymentMethodSection: PropTypes.bool,
	fetchPaymentInfo: PropTypes.func,
};
export default PaymentFrame;
