import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	wrapper: {
		border: '1px solid #DBDFE0',
		width: '90%',
		margin: '0 auto 15px auto',
		padding: '10px 18px',
		borderRadius: '4px',
		display: 'flex',
		justifyContent: 'space-between',
	},
	text: {
		color: '#071A2E',
		fontSize: '18px',
		paddingRight: '8px',
	},
	boldedText: {
		fontWeight: 700,
		display: 'inline',
	},
	edit: {
		fontWeight: 600,
		color: '#00B8EC',
		cursor: 'pointer',
	},
	analyzeLoader: {
		display: 'flex',
		alignSelf: 'center',
		height: '20px !important',
		width: '20px !important',
	},
}));

export default useStyles;
