import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "5px"
    },
    input: {
        width: '100%',
        padding: "8px 5px 5px 10px"
    }
}));

export {useStyles}
