import http from '../../../../../services/httpService/http';

import action from '../middleware';

import _ from 'lodash';
import { countryList, comments } from '../../../../../helpers/default';
import { SET_ERROR } from '../message/messageActions';
import {ACCOUNTS_URI} from "../../services/urls";
import {SUBSCRIPTIONS_URI} from "../../../../registration/controller/urls";

export const GETDEFAULTPLAN = "GETDEFAULTPLAN"

export const GETCOUNTRY = "GETCOUNTRY"
export const GETCOMMENT = "GETCOMMENT"
export const DEFAULT_STRIPE_PLANS = 'DEFAULT_STRIPE_PLANS'
export const SELECTEDPLAN = 'SELECTEDPLAN'
export const CURRENTPLAN = "CURRENTPLAN"

const getCurrentPlan = () => {
    return action(async dispatch => {
        try {
            const response = await http.get(SUBSCRIPTIONS_URI);
            dispatch({ type: CURRENTPLAN, payload: response });

            return response
        } catch (error) {
            //  dispatch({ type: SET_ERROR, message: error.message });

        }
    })
}
const getDefaultStripePlans = () => {
    return action(async dispatch => {
        try {
            const result = await http.get(`${SUBSCRIPTIONS_URI}/defaults`);

            dispatch({ type: GETDEFAULTPLAN, payload: _.groupBy(result, 'planName') })
            dispatch({ type: SELECTEDPLAN, payload: _.groupBy(result, 'planName').Monthly[0] })
            return _.groupBy(result, 'planName')
        } catch (error) {
            //    dispatch({ type: SET_ERROR, message: error.message });

        }

    });
}

const getComments = () => {
    return action(async dispatch => {
        try {

            dispatch({ type: GETCOMMENT, payload: comments })
            return comments;
        } catch (error) {
            dispatch({ type: SET_ERROR, message: error.message });

        }

    })
}

const getCountryList = () => {
    return action(async dispatch => {
        try {
            dispatch({ type: GETCOUNTRY, payload: countryList })
            return countryList
        } catch (error) {
            dispatch({ type: SET_ERROR, message: error.message });

        }
    })
}

const selectPlan = (plantype) => {
    return action(async (dispatch, getState) => {
        try {
            const state = getState().subscription.plans[plantype];
            dispatch({ type: SELECTEDPLAN, payload: state[0] })

        } catch (error) {
            dispatch({ type: SET_ERROR, message: error.message });

        }
    })
}
const createRegister = (payload) => {
    return action(async dispatch => {
        try {
            const response = await http.post(`${SUBSCRIPTIONS_URI}`, payload);
            return response

        } catch (error) {
            dispatch({ type: SET_ERROR, message: error.message });
        }

    })
}
const removeSubscription = () => {
    return action(async (dispatch, getState) => {
        try {
            const response = await http._delete(SUBSCRIPTIONS_URI);
            dispatch({ type: GETDEFAULTPLAN, payload: getState().subscription.currentPlan })
            return response;
        } catch (error) {

            dispatch({ type: SET_ERROR, message: error.message });
        }

    })
}
const validateAccount = async (payload) => {
    const response = await http.post(`${ACCOUNTS_URI}/validate`);
    return _.isEmpty(response) ? true : Object.values(response.error);
}
export {
    getComments,
    getCountryList,
    getDefaultStripePlans,
    createRegister,
    selectPlan,
    getCurrentPlan,
    removeSubscription,
    validateAccount
}