import React, { useState } from 'react';
import {
	Accordion,
	AccordionSummary,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './recordComponent.css';

const RecordComponent = ({
	activePanelName,
	handleChange,
	panelName,
	children,
}) => {
	const classes = useStyles();
	const [expand, setExpand] = useState(false);
	const onClick = event => {
		event.stopPropagation();
	};

	return (
		<Accordion
			elevation={0}
			expanded={expand && activePanelName === panelName}
			classes={{ root: classes.root, rounded: classes.rounded }}
			onChange={handleChange(panelName)}
		>
			<AccordionSummary
				aria-controls={`${panelName}-content`}
				id={`${panelName}-header`}
			>
				{ children }
			</AccordionSummary>
		</Accordion>
	);
};

RecordComponent.propTypes = {
	activePanelName: PropTypes.string,
	handleChange: PropTypes.func,
	panelName: PropTypes.string,
	children: PropTypes.node,
};
export default RecordComponent;
