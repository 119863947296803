import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'start',
		width: '100%',
		padding: 0,
	},
	checkboxLabel: {
		width: 'max-content',
		color: '#425262',
	},
	checkboxSection: {
		'& > *': {
			marginBottom: 0,
		},
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	divider: {
		width: '100%',
		position: 'absolute',
		left: 0,
		backgroundColor: '#C5C5C5',
	},
});

export default useStyles;
