import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    wrapperStyle:{
        paddingLeft: '50px',
        paddingTop: '40px',
        paddingRight: '50px',
        marginBottom: '20px',
    },
    titleStyle:{
        marginBottom: theme.spacing(3),
        fontSize: '16px',
        fontWeight: 'bold',
        color: theme.palette.secondary.main
    },
    subTitleStyle:{
        marginBottom: theme.spacing(3),
        fontSize: '14px',
        color: theme.palette.dialogDescription.main
    },
    childrenStyle:{
        marginBottom: theme.spacing(4),
    }
}));

export {useStyles}
