import { activeFeatures } from '../../../../../constants/activeFeatures';
import SummaryIcon from '../../../../../assets/sidebar/summary.svg';
import EyeIcon from '../../../../../assets/sidebar/visibility.svg';
import DeviceIcon from '../../../../../assets/sidebar/devices.svg';
import UserIcon from '../../../../../assets/sidebar/users.svg';
import GroupIcon from '../../../../../assets/sidebar/groups.svg';
import IntegrationIcon from '../../../../../assets/sidebar/integrations.svg';
import AlertsIcon from '../../../../../assets/sidebar/alerts.svg';
import VcaIcon from '../../../../../assets/sidebar/vca.svg';
import ThreatIcon from '../../../../../assets/icons/threatsIcon.svg';
import AcceptlistIcon from '../../../../../assets/sidebar/acceptlist.svg';
import MonitoringIcon from '../../../../../assets/sidebar/monitoring.svg';
import { PERFORMANCE_MONITORING_FEATURE_TOGGLE } from '../../../../../constants/featureToggles';

export const sidebarLinks = [
	{
		title: 'Overview',
		route: '/overview',
		img: SummaryIcon,
		isAnalystPermit: true,
	},
	...PERFORMANCE_MONITORING_FEATURE_TOGGLE ? [
		{
			title: 'Monitoring',
			route: '/monitoring',
			img: MonitoringIcon,
			isAnalystPermit: true,
			feature: {
				featureName: activeFeatures.PERFORMANCE_MONITORING,
			},
		},
	] : [],
	{
		title: 'Devices',
		route: '/device-management',
		img: DeviceIcon,
		isAnalystPermit: true,
	},
	{
		title: 'Alerts',
		route: '/alerts-management',
		img: AlertsIcon,
		isAnalystPermit: true,
	},
	{
		title: 'VCA',
		route: '/vca-management',
		img: VcaIcon,
		isAnalystPermit: true,
		feature: {
			featureName: activeFeatures.VCA_DETECTION,
		},

	},
	{
		title: 'Threats',
		route: '/threats',
		img: ThreatIcon,
		isAnalystPermit: true,
		feature: {
			featureName: activeFeatures.THREATS,
		},
	},
	{
		title: 'Analysis',
		route: '/analysis',
		img: EyeIcon,
		isAnalystPermit: true,
	},
	{
		title: 'IP groups',
		route: '/ip-group-management',
		img: GroupIcon,
		isAnalystPermit: true,
	},
	{
		title: 'Acceptlist',
		route: '/acceptlist',
		img: AcceptlistIcon,
		isAnalystPermit: true,
	},
	{
		title: 'Integrations',
		route: '/integrations',
		img: IntegrationIcon,
		isAnalystPermit: false,
	},
	{
		title: 'Users',
		route: '/user-management',
		img: UserIcon,
		isAnalystPermit: false,
	},

];
