import {useStyles} from "./mainPanelTemplateV4.css";
import {Grid,Typography} from "@material-ui/core";
import React from "react";

const MainPanelTemplateV4 = ({title, image, description = ""}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.description} style={{height: "unset"}}>
                    {description}
                </Typography>
                <div className={classes.imageWrapper}>
                    {image}
                </div>
            </Grid>
        </Grid>
    )
}

export default MainPanelTemplateV4;