import React, { Component } from 'react';
import Line2 from './advanceLine1/advanceLine1';
import Line3 from './advanceLine2/advanceLine2';
import './advance.scss';
class Advance extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="advance">
				<Line2 {... this.props} />
				<Line3 {... this.props}/>
			</div>
		);
	}
}

export default Advance;
