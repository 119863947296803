import React from 'react';
import './process.scss'
import { connect } from "react-redux";

import { removeAllBackgroundProcess } from '../../../controller/redux/backgroundProcessAction'
import DeleteSweepIcon from 'mdi-react/DeleteSweepIcon'
import StyleButton from '../../../../../shared/components/form/Buttons/StyleButton'
import {expandForm} from "../../../controller/redux/analysisFormActions";

import DeleteModal from '../../../../../shared/components/deleteModal/deleteModal'

export class Process extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            deleteModalOpen: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleFormCancel = (event, value) => {
        this.setState({ deleteModalOpen: false })
    }
    handleClick = (event, value) => {
        console.log(value)
        this.setState({ deleteModalOpen: true })

    }
    handleDeleteEntity = (event, value) => {
        this.props.removeAllBackgroundProcess()
        this.setState({ deleteModalOpen: false })
        this.props.expandForm();
    }
    render() {
        return (
            <>
                <DeleteModal
                    modalOpen={ this.state.deleteModalOpen }
                    toggle={ this.handleFormCancel }
                    onConfirm={ this.handleDeleteEntity }
                    message={ "Are you sure you want to delete all the queries?" }

                ></DeleteModal>
                <StyleButton
                    onClick={ this.handleClick }
                    label=''
                    icon={ <DeleteSweepIcon /> }
                    className={ `vv-analyze-button add-button remove-all-process-button` }
                    style={{width:'20px'}}
                />
            </>
        );
    }

}


const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = {
    removeAllBackgroundProcess,
    expandForm
};
export default connect(mapStateToProps, mapDispatchToProps)(Process); 
