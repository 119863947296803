import utils from "../../../../services/util";

export const COLOR_KEY_VALUE = 'healthScore';
export const CHART_HEIGHT = '20%';
export const MAX_COLUMNS = 6;

const DIVISION_FOR_LABEL_CHARS_PER_WIDTH = 70;

const COLORS = {
	LOW: '#F7817D',
	MEDIUM: '#F8DA8F',
	HIGH: '#B3D6C1',
	INACTIVE: '#ECECEC',
};

const DATA_CLASSES = {
	HIGH: {
		from: 75,
		color: COLORS.HIGH,
		name: 'High',
		visible: true,
		forceRedraw: true,

	},
	MEDIUM: {
		from: 49,
		to: 75,
		color: COLORS.MEDIUM,
		name: 'Medium',
		visible: true,
		forceRedraw: true,
	},
	LOW: {
		from: 0,
		to: 49,
		color: COLORS.LOW,
		name: 'Low',
		visible: true,
		forceRedraw: true,
	},
	INACTIVE: {
		to: 0,
		color: COLORS.INACTIVE,
		name: 'Inactive',
		visible: true,
		forceRedraw: true,
	},
};
export const overrides = {
	tooltip: {
		headerFormat: '',
		shadow: true,
		animation: true, // Enable tooltip animation
		pointFormatter() {
			const suffix = 'bps';
			const formattedValue = utils.convertNumber(this.averageVolume, 2, true);

			return '<span style="color: ' + this.color + '">●</span>'
				+ '<span style="font-size: 13px; font-weight: bold;"> &nbsp;&nbsp;' + this.deviceName + '</span><br>'
				+ '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Current traffic: ' + formattedValue + suffix;
		},
		padding: 10,
		hideDelay: 10,
	},
	colorAxis: {
		states: {
			hover: {
				enabled: false,
			},
		},
		marker: {
			enabled: false,
			states: {
				hover: {
					enabled: false,
				},
			},
		},
		dataClasses: [
			DATA_CLASSES.HIGH,
			DATA_CLASSES.MEDIUM,
			DATA_CLASSES.LOW,
			DATA_CLASSES.INACTIVE,
		],
	},
	plotOptions: {
		colorAxis: {
			states: {
				hover: {
					enabled: false,
				},
			},
		},
		series: {
			states: {
				hover: {
					enabled: false,
				},
			},
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: false,
					},
				},
			},
		},
	},
	series: [
		{
			colorKey: COLOR_KEY_VALUE,
			showInLegend: true,
			marker: {
				enabled: false,
				states: {
					hover: {
						enabled: false,
					},
				},
			},
			dataLabels: {
				enabled: true,
				formatter() {
					const maxChars = Math.floor(this.series.chart.chartWidth / DIVISION_FOR_LABEL_CHARS_PER_WIDTH); // Maximum number of characters based on chart width
					let label = this.point.deviceName;

					if (label.length > maxChars) {
						label = label.substring(0, maxChars) + '...';
					}

					return label;
				},
				style: {
					textOutline: false,
				},

			},
		},
	],
};
