import React from "react";
import step1Side from "../../../../../../assets/walkthrough/overview/steps/step1side.png";
import step1Main from "../../../../../../assets/walkthrough/overview/steps/step1main.png";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step1.css";
import MainPanelTemplateV1 from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV1/mainPanelTemplateV1";


const Step1 = () => {
    const classes = useStyles();
    const description = (
        <>Total number of packets, flows and volume, updated every 5 seconds.<br/>
            Medium threats are marked in yellow, high threats are marked in red
        </>
    )
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV1 title={"Graphs bar"}
                                 image={<img src={step1Main} className={classes.image}/>}
                                 description={description} />
        </StepTemplate>
    )
}

export default Step1;