import conf from '../../../config';
import Cookies from 'js-cookie';
import { AUTH_METADATA_COOKIE_NAME, HASH_PATH_STORAGE_KEY, USER_ID_STORAGE_KEY } from '../authenticationProcessConfig';

export const redirectToHostedUi = origin => {
	const { clientId, hostedUi, responseType, scope } = conf.cognitoConfiguration;
	const href = `${hostedUi}/login?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${origin}`;
	window.location.href = href;
};

export const createAuthenticationMetaDataCookie = (expirationDate, userId) => {
	Cookies.set(AUTH_METADATA_COOKIE_NAME, userId, { expires: expirationDate });
};

export const removeAuthenticationMetaDataCookie = () => {
	Cookies.remove(AUTH_METADATA_COOKIE_NAME);
};

export const isUserAuthenticated = () => Boolean(Cookies.get(AUTH_METADATA_COOKIE_NAME));

export const getUserIdFromCookie = () => Cookies.get(AUTH_METADATA_COOKIE_NAME);

export const logoutUser = origin => {
	const { clientId, hostedUi } = conf.cognitoConfiguration;

	if (isUserIdOverSession()) {
		removeUserIdFromSession();
	}

	if (isUserAuthenticated()) {
		removeAuthenticationMetaDataCookie();
		window.location.href = `${hostedUi}/logout?client_id=${clientId}&logout_uri=${origin}`;
	} else {
		redirectToHostedUi();
	}
};

export const saveHashPathToStorage = hashPath => {
	sessionStorage.setItem(HASH_PATH_STORAGE_KEY, hashPath);
};

export const getHashPathFromStorage = () => sessionStorage.getItem(HASH_PATH_STORAGE_KEY);

export const removeHashPathFromStorage = () => sessionStorage.removeItem(HASH_PATH_STORAGE_KEY);

export const setUserIdOverSessions = userId => {
	sessionStorage.setItem(USER_ID_STORAGE_KEY, userId);
};

export const getUserIdFromSession = () => sessionStorage.getItem(USER_ID_STORAGE_KEY);

export const isUserIdOverSession = () => Boolean(sessionStorage.getItem(USER_ID_STORAGE_KEY));

export const removeUserIdFromSession = () => sessionStorage.removeItem(USER_ID_STORAGE_KEY);

