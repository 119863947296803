import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { format, required, validateForm } from 'redux-form-validators';
import SubmitButton from '../../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../../shared/components/form/TextField/TextField';
import { FormWrapper } from '../../../../../shared/components/simpleTable/simpleFormContainer';
import PropTypes from 'prop-types';
import { PROJECT_ID_VALIDATE_REGEX, SAMPLING_RATE_VALIDATE_REGEX, VPC_VALIDATE_REGEX } from '../../../../cloudFilter/gcp/validator';
import { useStyles } from './gcpForm.css';
import InformationHover from '../../../../../shared/components/informationHover/InformationHover';

const GCPForm = ({ onSubmit, handleSubmit, isEditMode, isSubmittingForm, initialValues }) => {
	const handleGcpSubmit = device => {
		const formObject = {
			...device,
			identifierValue: `${device.gcp.projectId}/${device.gcp.vpcName}`,
			samplingRate: Number(device.samplingRate),
			gcp: {
				...device.gcp,
			},
		};
		onSubmit(formObject);
	};

	const renderSubnetHeader = () => isSubnetsExits() && initialValues.gcp.subnets.length > 50
		? <>
				Subnets (50+)
			<InformationHover
				message="We are displaying up to 50 of your subnets. A complete list of subnets can be found in your GCP platform"
			/>
		</>
		: `Subnets (${isSubnetsExits() ? initialValues.gcp.subnets.length : 0})`;
	const isSubnetsExits = () => (initialValues.gcp.subnets && initialValues.gcp.subnets.length);

	const classes = useStyles();

	return (
		<form onSubmit={handleSubmit(handleGcpSubmit)} className="modal-form">
			<FormWrapper title=" " wrapperStyle={{}}>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Field
							name="name"
							component={ TextField }
							label="Name"
							type="text"
							margin="none"
							className="form-field"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="gcp.projectId"
							disabled={isEditMode}
							component={ TextField }
							label="Project ID"
							type="text"
							margin="none"
							className="form-field"
							required
						/>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Grid item xs={6}>
						<Field
							name="gcp.vpcName"
							disabled={isEditMode}
							component={ TextField }
							label="VPC Name"
							type="text"
							margin="none"
							className="form-field"
							required
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							name="samplingRate"
							disabled={isEditMode}
							component={ TextField }
							label="Sampling rate"
							type="number"
							margin="none"
							className="form-field"
							required
						/>
					</Grid>
				</Grid>
			</FormWrapper>
			{isEditMode ? (
				<>
					<Typography className={classes.titleStyle}>
						{renderSubnetHeader()}
					</Typography>
					<Grid container spacing={1} className={classes.subnetsContainer}>
						{isSubnetsExits() ? initialValues.gcp.subnets.slice(0, 50).map(subnet => (
							<Grid item key={subnet}>
								<div className={classes.subnetChip}>
									{subnet}
								</div>
							</Grid>
						)) : '0 subnets configured - waiting for data from your device'}
					</Grid>
				</>
			) : ''}
			<div style={{ marginBottom: '30px' }}>
				{isSubmittingForm ? (
					<CircularProgress color="primary" />
				) : (
					<SubmitButton label={isEditMode ? 'Save' : 'Add cloud device'} />
				)}
			</div>
		</form>
	);
};

// Redux Form
const validate = validateForm({
	name: [required({ msg: 'Please enter a display name' })],
	samplingRate: [required({ msg: 'Please enter a sampling rate' }), format({ with: SAMPLING_RATE_VALIDATE_REGEX, msg: 'Please enter a number between 1 and 100' })],
	gcp: {
		vpcName: [required({ msg: 'Please enter identifier' }), format({ with: VPC_VALIDATE_REGEX, msg: 'Please enter a valid vpc name' })],
		projectId: [required({ msg: 'Please enter gcp project ID' }), format({ with: PROJECT_ID_VALIDATE_REGEX, msg: 'Please enter a valid gcp project Id' })],
	},
});

GCPForm.propTypes = {
	onSubmit: PropTypes.func,
	handleSubmit: PropTypes.func,
	isEditMode: PropTypes.bool,
	isSubmittingForm: PropTypes.bool,
	initialValues: PropTypes.object,
};

export default reduxForm({
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(GCPForm);
