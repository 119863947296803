const ANALYZE_DATA_PATH='summary-data/v1';
const IP_GROUP_ANOMALIES_PATH = 'ip-group-anomalies/v1';
const GET_ANALYZE_DATA_LATEST = `${ANALYZE_DATA_PATH}/latest/`;
const QUERY_LINK_URL = "link-queries/v1/";
const RAWDATA_EXPORT_URL = "rawdata-export/v1/"
const GET_ANALYZE_TOTAL_DAILY_PACKETS = `${ANALYZE_DATA_PATH}/totaldailypackets/`;
const GET_ANALYZE_TOTAL_DAILY_THREATS = `${ANALYZE_DATA_PATH}/totaldailythreats/`;

export  {
    ANALYZE_DATA_PATH,
    IP_GROUP_ANOMALIES_PATH,
    GET_ANALYZE_DATA_LATEST,
    GET_ANALYZE_TOTAL_DAILY_PACKETS,
    GET_ANALYZE_TOTAL_DAILY_THREATS,
    QUERY_LINK_URL,
    RAWDATA_EXPORT_URL
}