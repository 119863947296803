import http from '../../../services/httpService/http';

const SUBSCRIPTION_URL = '/subscriptions/v2';
const MAX_INVOICES = 6;

const getPaymentInfo = async () => await http.get(`${SUBSCRIPTION_URL}/current/payment-method`);

const getInvoices = async invoicesNumber => await http.get(`${SUBSCRIPTION_URL}/invoices/?limit=${invoicesNumber || MAX_INVOICES}`);

const getProposalDetails = async quoteId => await http.get(`${SUBSCRIPTION_URL}/quotes/${quoteId}`);

const acceptQuoteProposal = async quoteId => await http.post(`${SUBSCRIPTION_URL}/quotes/${quoteId}/accept`);

export { getPaymentInfo, getInvoices, getProposalDetails, acceptQuoteProposal };
