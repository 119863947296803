import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    form: {
        margin: "30px 40px 20px 40px"
    },
    actionButtons: {
        display: "flex",
        justifyContent: "center",
        marginTop: "40px"
    },
    button: {
        width: "35%",
        margin: "0 10px 0 10px"
    },
    paymentTitle: {
        paddingBottom: "20px"
    }
}));

export {useStyles}