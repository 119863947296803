import _ from "lodash";
import moment from "moment";
import {TIME_BEFORE_DEVICE_INACTIVATION} from "../constantes/devices";
import { MINUTES_BEFORE_COLLECTOR_NOT_READY } from "../constantes/collectors";

const isDeviceActive = (data) => {
    const hasLastSeen = !_.isUndefined(data.lastSeen);
    let diff = moment.utc().diff(moment.utc(data.lastSeen), 'minutes')
    // here we calculate the time between the last seen and now in order to add ACTIVE / INACTIVE
    console.log('lastSeen ' + data.name, hasLastSeen ? moment(data.lastSeen).fromNow() : null)
    const isActive = hasLastSeen && diff < TIME_BEFORE_DEVICE_INACTIVATION;
    return isActive;
}

// For poc's
const isDeviceActiveByBoolean = (data) => {
    const isActive = data.activity;
    return isActive;
}

const isCollectorAttached = (data) => {
    return data.attached;
}

const isCollectorReady = (data) => {
    if (data.lastSeen) {
        const diff = moment.utc().diff(moment.utc(data.lastSeen), 'minutes')
        // here we calculate the time between the last seen and now in order to add Ready / Not Ready
        return diff < MINUTES_BEFORE_COLLECTOR_NOT_READY;
    }
}

export {
    isDeviceActive,
    isCollectorAttached,
    isDeviceActiveByBoolean,
    isCollectorReady
}
