import InformationHover from '../informationHover/InformationHover';
import { DROPDOWN_DAY_VALUE, RESET_BUTTON_TEXT } from './config';
import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { ReactComponent as ZoomOutIcon } from '../../../assets/detectionCenter/zoomOut.svg';
import PropTypes from 'prop-types';
import useStyles from './stockGraph.css';
import FilterDropdown from '../filterDropdown/filterDropdown';

const StockGraphHeader = ({
	children,
	graphTitle,
	graphTitleTooltip,
	onZoomOut,
	isResetDisabled,
	dropdownItems,
	dropdownValue,
	onDropdownChange,
}) => {
	const classes = useStyles();
	const [defaultSelectedIndex, setDefaultSelectedIndex] = useState(0);

	useEffect(() => {
		const dayItemIndex = dropdownItems.findIndex(item => item.value === DROPDOWN_DAY_VALUE);
		setDefaultSelectedIndex(dayItemIndex);
	}, [dropdownItems]);

	return <div className={classes.graphHeader}>
		<div className={classes.graphTitle}>
			<span>{graphTitle}</span>
			<InformationHover message={graphTitleTooltip}/>
		</div>
		<div className={classes.graphActions}>
			{children}
			<Button
				variant="text"
				onClick={ onZoomOut }
				disabled={isResetDisabled}
				className={classes.resetButton}
			>
				<ZoomOutIcon
					className={isResetDisabled ? classes.resetButtonIconDisabled : classes.resetButtonIconActive}
				/>
				<Typography className={classes.resetButtonLabel}>
					{RESET_BUTTON_TEXT}
				</Typography>
			</Button>
			<div className={classes.zoomDropdown}>
				<FilterDropdown
					items={dropdownItems}
					onItemSelected={onDropdownChange}
					inputItem={dropdownValue}
					defaultSelectionIndex={defaultSelectedIndex}
				/>
			</div>
		</div>
	</div>;
};

StockGraphHeader.propTypes = {
	graphTitle: PropTypes.string.isRequired,
	graphTitleTooltip: PropTypes.string.isRequired || PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	onZoomOut: PropTypes.func.isRequired,
	onZoomIn: PropTypes.func.isRequired,
	isResetDisabled: PropTypes.bool.isRequired,
	dropdownItems: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
		})).isRequired,
	dropdownValue: PropTypes.shape({
		value: PropTypes.string.isRequired,
	}).isRequired,
	onDropdownChange: PropTypes.func.isRequired,
};
export default StockGraphHeader;
