const extractValuesByType = (values, defaultType) =>
	values.reduce((typeToValues, currentData) => {
		const currentType = currentData?.type || defaultType;
		(typeToValues[currentType] = typeToValues[currentType] || []).push(currentData.value);
		return typeToValues;
	}, {});

export const convertInputToRuleBody = ruleFormData => {
	const srcValues = ruleFormData.srcIps ? extractValuesByType(ruleFormData.srcIps) : {};
	const dstValues = ruleFormData.dstIps ? extractValuesByType(ruleFormData.dstIps) : {};
	const srcDnsValues = ruleFormData.srcDns ? extractValuesByType(ruleFormData.srcDns, 'dns') : {};
	const dstDnsValues = ruleFormData.destDns ? extractValuesByType(ruleFormData.destDns, 'dns') : {};
	const srcIspValues = ruleFormData.srcIsp ? extractValuesByType(ruleFormData.srcIsp, 'isp') : {};
	const dstIspValues = ruleFormData.destIsp ? extractValuesByType(ruleFormData.destIsp, 'isp') : {};
	const srcOrgValues = ruleFormData.srcOrganization ? extractValuesByType(ruleFormData.srcOrganization, 'org') : {};
	const dstOrgValues = ruleFormData.destOrganization ? extractValuesByType(ruleFormData.destOrganization, 'org') : {};

	return {
		...(srcValues.ip && { srcIps: srcValues.ip }),
		...(srcValues.mac && { srcMacs: srcValues.mac }),
		...(srcValues.group && { srcIpGroups: srcValues.group }),
		...(dstValues.ip && { dstIps: dstValues.ip }),
		...(dstValues.mac && { dstMacs: dstValues.mac }),
		...(dstValues.group && { dstIpGroups: dstValues.group }),
		...(ruleFormData.srcPorts && { srcPorts: ruleFormData.srcPorts.map(port => Number(port.value)) }),
		...(ruleFormData.dstPorts && { dstPorts: ruleFormData.dstPorts.map(port => Number(port.value)) }),
		...(ruleFormData.protocolIds && { protocolIds: ruleFormData.protocolIds.map(protocolId => Number(protocolId.value)) }),
		...(ruleFormData.devices && { devices: ruleFormData.devices.map(device => device.value) }),
		...(ruleFormData.description !== undefined && { description: ruleFormData.description }),
		...(ruleFormData.isEnabled !== undefined && { isEnabled: ruleFormData.isEnabled }),
		...(ruleFormData.threatsService !== undefined && { threatsService: ruleFormData.threatsService }),
		...(ruleFormData.customAlertsService !== undefined && { customAlertsService: ruleFormData.customAlertsService }),
		...(ruleFormData.vcaService !== undefined && { vcaService: ruleFormData.vcaService }),
		...(ruleFormData.isBidirectional !== undefined && { isBidirectional: ruleFormData.isBidirectional }),
		...(ruleFormData.srcCountryName && { srcCountryName: ruleFormData.srcCountryName.map(country => country.label) }),
		...(ruleFormData.destCountryName && { dstCountryName: ruleFormData.destCountryName.map(country => country.label) }),
		...(ruleFormData.definitionId !== undefined && { definitionId: ruleFormData.definitionId }),
		...(ruleFormData.detectionId !== undefined && { detectionId: ruleFormData.detectionId }),
		...(ruleFormData.metadata !== undefined && { metadata: ruleFormData.metadata }),
		...(ruleFormData.source !== undefined && { source: ruleFormData.source }),
		...(srcIspValues.isp && { srcIsp: srcIspValues.isp }),
		...(dstIspValues.isp && { dstIsp: dstIspValues.isp }),
		...(srcIspValues.group && { srcIspGroups: srcIspValues.group }),
		...(dstIspValues.group && { dstIspGroups: dstIspValues.group }),
		...(srcOrgValues.org && { srcOrg: srcOrgValues.org }),
		...(dstOrgValues.org && { dstOrg: dstOrgValues.org }),
		...(srcOrgValues.group && { srcOrgGroups: srcOrgValues.group }),
		...(dstOrgValues.group && { dstOrgGroups: dstOrgValues.group }),
		...(srcDnsValues.dns && { srcDns: srcDnsValues.dns }),
		...(dstDnsValues.dns && { dstDns: dstDnsValues.dns }),
		...(srcDnsValues.group && { srcDnsGroups: srcDnsValues.group }),
		...(dstDnsValues.group && { dstDnsGroups: dstDnsValues.group }),
	};
};

const createLabelData = value => ({ value, label: value });
const createTypeData = (type, value, label) => ({ type, value, label });

const formatRuleDisplayData = (rule, ruleDisplayData, accountGroups, accountDevices) => ({
	srcIps: [
		...(ruleDisplayData.srcIps ? ruleDisplayData.srcIps.map(ip => createTypeData('ip', ip, ip)) : []),
		...(ruleDisplayData.srcMac ? ruleDisplayData.srcMacs.map(mac => createTypeData('mac', mac, mac)) : []),
		...(ruleDisplayData.srcIpGroups
			? ruleDisplayData.srcIpGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId]))
			: []),
	],
	dstIps: [
		...(ruleDisplayData.dstIps ? ruleDisplayData.dstIps.map(ip => createTypeData('ip', ip, ip)) : []),
		...(ruleDisplayData.dstMac ? ruleDisplayData.dstMacs.map(mac => createTypeData('mac', mac, mac)) : []),
		...(ruleDisplayData.dstIpGroups
			? ruleDisplayData.dstIpGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId]))
			: []),
	],
	...(ruleDisplayData.srcPorts && { srcPorts: ruleDisplayData.srcPorts.map(createLabelData) }),
	...(ruleDisplayData.dstPorts && { dstPorts: ruleDisplayData.dstPorts.map(createLabelData) }),
	...(ruleDisplayData.description && { description: ruleDisplayData.description }),
	...(ruleDisplayData.srcCountryName && { srcCountryName: ruleDisplayData.srcCountryName.map(createLabelData) }),
	...(ruleDisplayData.dstCountryName && { destCountryName: ruleDisplayData.dstCountryName.map(createLabelData) }),
	srcIsp: [
		...(ruleDisplayData.srcIsp ? ruleDisplayData.srcIsp.map(createLabelData) : []),
		...(ruleDisplayData.srcIspGroups ? ruleDisplayData.srcIspGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId])) : []),
	],
	destIsp: [
		...(ruleDisplayData.dstIsp ? ruleDisplayData.dstIsp.map(createLabelData) : []),
		...(ruleDisplayData.dstIspGroups ? ruleDisplayData.dstIspGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId])) : []),
	],

	srcOrganization: [
		...(ruleDisplayData.srcOrg ? ruleDisplayData.srcOrg.map(createLabelData) : []),
		...(ruleDisplayData.srcOrgGroups ? ruleDisplayData.srcOrgGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId])) : []),
	],

	destOrganization: [
		...(ruleDisplayData.dstOrg ? ruleDisplayData.dstOrg.map(createLabelData) : []),
		...(ruleDisplayData.dstOrgGroups ? ruleDisplayData.dstOrgGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId])) : []),

	],

	srcDns: [
		...(ruleDisplayData.srcDns ? ruleDisplayData.srcDns.map(createLabelData) : []),
		...(ruleDisplayData.srcDnsGroups ? ruleDisplayData.srcDnsGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId])) : []),
	],

	destDns: [
		...(ruleDisplayData.dstDns ? ruleDisplayData.dstDns.map(createLabelData) : []),
		...(ruleDisplayData.dstDnsGroups ? ruleDisplayData.dstDnsGroups.map(groupId => createTypeData('group', groupId, accountGroups[groupId])) : []),
	],
	additionalInfo: {
		...(ruleDisplayData.protocolIds && { protocolIds: ruleDisplayData.protocolIds.map(createLabelData) }),
		services: {
			threatsService: rule.Threats,
			customAlertsService: rule.CustomAlerts,
			vcaService: rule.VCA,
		},
		...(ruleDisplayData.devices && {
			devices: ruleDisplayData.devices.map(deviceId => createTypeData('device', deviceId, accountDevices[deviceId])),
		}),
	},
});

export const formatRulesFromServer = (rules, accountGroups, accountDevices) => {
	const formattedRules = rules.map(rule => {
		const displayData = JSON.parse(rule.DisplayData);
		return {
			id: rule.RuleId,
			isEnabled: rule.IsEnabled,
			creationTime: rule.CreationTime,
			isBidirectional: rule.IsBidirectional || false,
			isEditable: 'IsEditable' in rule ? rule.IsEditable : true,
			isDeletable: 'IsDeletable' in rule ? rule.IsDeletable : true,
			...formatRuleDisplayData(rule, displayData, accountGroups, accountDevices),
		};
	});
	return formattedRules;
};
