/* eslint-disable react/prop-types */

import React from 'react';
import moment from 'moment';

import ThreatWrapper from '../basicActions/threatwrapper';
import ThreatNotification from '../severity/threatNotification';

import ExploreVisibility from '../advanceActions/explore';
import DateRange from '../../../../shared/components/simpleTable/fields/dateRange';
import DeleteField from '../../../../shared/components/simpleTable/fields/deleteField';
import SimpleField from '../../../../shared/components/simpleTable/fields/simpleField';
import { timeFormaterGraphPopOver } from '../../../../constants/formater';
import { toLocaleDateString } from '../../../../helpers/datesHelper';
import UpgradePlanButton from '../../../plans/upgradePlanButton/upsellPlanButton';

class FieldsFactory {
	createTextField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
		};
	}

	createDateField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'date',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell({ value }) {
				return (value && moment(value).isValid()) ? moment(value).format('DD/MM/YYYY') : '';
			},
			filterMethod(filter, row) {
				// Compare row date with the range from the date picker on UI
				if (filter.value) {
					const startDate = moment(filter.value[0]);
					const endDate = moment(filter.value[1]);
					const rowDate = moment(new Date(row.date));
					return rowDate.isBetween(startDate, endDate);
				}

				return true;
			},
			// Filter html
			Filter: ({ onChange }) =>
				<DateRange onChange={ selected => onChange(selected) } />,
		};
	}

	createDateTimeField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'date',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell({ value }) {
				return (value && moment(value).isValid()) ? toLocaleDateString(value, true) : '';
			},
			filterMethod(filter, row) {
				// Compare row date with the range from the date picker on UI
				if (filter.value) {
					const startDate = moment(filter.value[0]);
					const endDate = moment(filter.value[1]);
					const rowDate = moment(new Date(row.date));
					return rowDate.isBetween(startDate, endDate);
				}

				return true;
			},
			// Filter html
			Filter: ({ onChange }) =>
				<DateRange onChange={ selected => onChange(selected) } />,
		};
	}

	createTimeField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'date',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell: ({ value }) => (value && moment(value).isValid()) ? moment(value).format(timeFormaterGraphPopOver) : '',
			filterMethod(filter, row) {
				// Compare row date with the range from the date picker on UI
				if (filter.value) {
					const startDate = moment(filter.value[0]);
					const endDate = moment(filter.value[1]);
					const rowDate = moment(new Date(row.date));
					return rowDate.isBetween(startDate, endDate);
				}

				return true;
			},
			// Filter html
			Filter: ({ onChange }) =>
				<DateRange onChange={ selected => onChange(selected) } />,
		};
	}

	createSeverityField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell({ value }) {
				return <ThreatNotification severity={ value } />;
			},

		};
	}

	createVisibilityField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell({ value }) {
				return value
					? <ExploreVisibility content={ value }/>
					: <UpgradePlanButton feature={'vca'}/>;
			},

		};
	}

	createStarField(accessor, alias, onChangeFavThreat, onDeleteThreat, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => (
				<SimpleField alias={ alias } className={ className } filterable={ filterable } />
			),
			Cell({ value, original }) {
				return <ThreatWrapper
					hadDelete={ true }
					value={ value } alias={ alias }
					className={ className } filterable={ filterable }
					onDeleteThreat={ () => {
						onDeleteThreat(original);
					} }
					onChangeFavThreat={ () => {
						onChangeFavThreat(original);
					} } />;
			},

		};
	}

	createStarDeleteField(accessor, onChangeFavThreat, onDeleteThreat, className = 'alignEnd') {
		return {
			accessor,
			alias: '',
			filterable: false,
			allowView: false,
			allowEdit: false,
			show: true,
			resizable: false,
			className,
			type: 'actions',
			Header: () => (
				<SimpleField alias="" className={ className } filterable={ false } />
			),
			Cell({ original }) {
				return (
					<DeleteField handleDelete={ () => onDeleteThreat(original) } handleEdit={ () => onChangeFavThreat(original) } />
				);
			},
		};
	}
}

export default new FieldsFactory();
