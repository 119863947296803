import React from 'react';
import './advance.scss';
import InputGroup3 from '../groups/group3/inputGroup3';
import InputGroup4 from '../groups/group4/inputGroup4';
import InputGroup5 from '../groups/group5/inputGroup5';

const Advance = () => (
	<div className="advance">
		<InputGroup3 />
		<InputGroup4 />
		<InputGroup5 />
	</div>
);

export default Advance;
