import PlansCallToAction from "../../../assets/plans/plansCallToAction.svg";
import {useDispatch} from "react-redux";
import React from "react";
import {useStyles} from "./upsellPlanButton.css";
import {setPlansFlowActivation} from "../redux/plansActions";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const propTypes = {
    feature: PropTypes.string.isRequired,
    buttonText: PropTypes.string
};

const StyledButton = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
}))(Button);

const UpgradePlanButton = ( {feature, buttonText='Click to view'} ) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const openPremiumPlansModal = () => dispatch(setPlansFlowActivation(true, true, feature));

    return (
        <StyledButton
            endIcon={<img className={classes.upgradeIcon} src={PlansCallToAction} alt={"upgrade plan"}/>}
            onClick={openPremiumPlansModal}
        >
            {buttonText}
        </StyledButton>
    )
};

UpgradePlanButton.propTypes = propTypes;
export default UpgradePlanButton