import action from "../../../app/controller/redux/middleware";
import {SET_VISIBLE_DEVICES_COUNT} from "./actionTypes";
import {getPublicDevicesVisibleCount} from "../services/deviceRequests";
import {getCollectorDevicesVisibleCount} from "../services/collectorDeviceRequests";
import {getPublicCloudDeviceVisibleCount} from "../services/publicCloudDeviceRequests";

const getVisibleDevicesCount = () => {
    return action(async (dispatch, getState) => {
        const {count: publicDeviceVisibleCount} = await getPublicDevicesVisibleCount();
        const {count: collectorDeviceVisibleCount} = await getCollectorDevicesVisibleCount();
        const {count: publicCloudDeviceVisibleCount} = await getPublicCloudDeviceVisibleCount();
        const allDevicesCount = publicDeviceVisibleCount + collectorDeviceVisibleCount + publicCloudDeviceVisibleCount;
        dispatch({ type: SET_VISIBLE_DEVICES_COUNT, payload:{ count: allDevicesCount } })
    });
}

export {getVisibleDevicesCount}