import React, {useEffect, useState} from 'react';
import {Typography} from "@material-ui/core";
import OrderSummary from "../orderSummary/orderSummary";
import Divider from "@material-ui/core/Divider";
import StyleButton from "../../../../shared/components/form/Buttons/StyleButton";
import {ReactComponent as Lock } from '../../../../assets/payment/lock-new.svg';
import {useStyles} from "./UpgradeToPremium.css";

function UpgradeToPremiumOrderSummary({selectedPlan, selectedPriceName, setSelectedPriceName, title, isCharging = true, onSubmit, isSubmitValid}){
    const [priceToRefer, setPriceToRefer] = useState({price: 0, priceName: ''});
    const classes = useStyles();
    useEffect(() => {
        if(selectedPlan.prices){
            const currentPrice = selectedPlan.prices.find((price) => price.priceName === selectedPriceName);
            setPriceToRefer(currentPrice)
        }

    }, [selectedPriceName])

    return(
        <div className={classes.container}>
            <div className={classes.priceDisplay} >
                <OrderSummary selectedPlan={selectedPlan}
                              title={title}
                              selectedPrice={priceToRefer}
                              selectedPriceName={priceToRefer.priceName}
                              onPriceChange={setSelectedPriceName}  />
                {isCharging && <>
                    <Divider  />
                    {<div className={classes.todaysCharge}>
                        <Typography style={{alignSelf: 'center'}} variant={"caption"}> Today's charge </Typography>
                        <Typography style={{alignSelf: 'center'}} variant={"h6"} >${priceToRefer.price.toLocaleString()}</Typography>
                    </div>}
                </>}

            </div>
            {isCharging && <StyleButton startIcon={<Lock />} className={classes.submitButton}  onClick={onSubmit} label={"Place Secure Order"} isDisabled={isSubmitValid} />}
        </div>

    )

}

export default UpgradeToPremiumOrderSummary;