import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { thresholdArray } from '../../controller/constants/threshold';
import simpleValueSelector from '../../../../shared/components/queryLine/selectors/simpleValueSelector';
import timeUnit from '../../../../shared/components/queryLine/timeUnit/timeUnit';
import './updateThreshold.scss';
import ThresholdField from '../../../../shared/components/queryLine/thresholdinput/thresholdField';

class UpdateTreshold extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { handleSubmit } = this.props;

        return (
            <div className="update-threshold">

                <form onSubmit={ handleSubmit }>
                    <div className="form-wrapper">


                        <div className="update-treshold-title">
                            Update Threshold
                        </div>

                        <div className="input-container">
                            <div className="threshold-field-type">

                                <Field
                                    name="thresholdType"
                                    component={ simpleValueSelector }
                                    options={ thresholdArray }
                                />
                            </div>
                            <div className="threshold-field-value">
                                <ThresholdField />
                            </div>
                            <div className="update-threshold-groups">
                                <button
                                    type='button'
                                    onClick={ this.props.onClose }
                                    className="button-update cancel">CANCEL </button>
                                <button
                                    type='submit'
                                    className="button-update save">UPDATE </button>

                            </div>
                        </div>

                    </div>

                </form>
            </div>
        );
    }
}
export default reduxForm({})(UpdateTreshold);
