import {
	SET_ACCOUNT_INTERACTIONS,
	SET_USER_INTERACTIONS,
	UPDATE_WELCOME_DONE,
	UPDATE_WALKTHROUGH_DONE,
	UPDATE_SSO_MIGRATION_NOTIFICATION_DONE,
} from './actionTypes';
import { ANALYSIS_KEY, CUSTOM_ALERTS_KEY, DEVICES_KEY, OVERVIEW_KEY, IP_GROUPS_KEY } from './consts';

const initialState = {
	firstLogin: {
		welcomeDone: true,
	},
	overview: {
		walkthroughDone: true,
	},
	analysis: {
		walkthroughDone: true,
	},
	customAlerts: {
		walkthroughDone: true,
	},
	ipGroups: {
		walkthroughDone: true,
	},
	devices: {
		walkthroughDone: true,
	},
	sso: {
		userNotifiedOnSSOMigration: true,
	},
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case SET_ACCOUNT_INTERACTIONS:
			return {
				...state,
				firstLogin: payload.firstLogin,
			};
		case SET_USER_INTERACTIONS:
			return {
				...state,
				overview: payload.overview,
				analysis: payload.analysis,
				customAlerts: payload.customAlerts,
				devices: payload.devices,
				ipGroups: payload.ipGroups,
				sso: payload.sso,
			};
		case UPDATE_WELCOME_DONE:
			const { firstLogin } = state;
			return {
				...state,
				firstLogin: { ...firstLogin, welcomeDone: payload },
			};
		case UPDATE_WALKTHROUGH_DONE:
			const interactionType = payload.type;
			const { isDone } = payload;
			if (interactionType === ANALYSIS_KEY) {
				const { analysis } = state;
				return {
					...state,
					analysis: { ...analysis, walkthroughDone: isDone },
				};
			}

			if (interactionType === OVERVIEW_KEY) {
				const { overview } = state;
				return {
					...state,
					overview: { ...overview, walkthroughDone: isDone },
				};
			}

			if (interactionType === CUSTOM_ALERTS_KEY) {
				const { customAlerts } = state;
				return {
					...state,
					customAlerts: { ...customAlerts, walkthroughDone: isDone },
				};
			}

			if (interactionType === IP_GROUPS_KEY) {
				const { ipGroups } = state;
				return {
					...state,
					ipGroups: { ...ipGroups, walkthroughDone: isDone },
				};
			}

			if (interactionType === DEVICES_KEY) {
				const { devices } = state;
				return {
					...state,
					devices: { ...devices, walkthroughDone: isDone },
				};
			}

			break;

		case UPDATE_SSO_MIGRATION_NOTIFICATION_DONE:
			return {
				...state,
				sso: { userNotifiedOnSSOMigration: payload },
			};
		default:
			return state;
	}
}
