import React, {useEffect} from 'react';
import priceOptions from "./pricesOptions";


function PriceBlock({pricesArray, onPriceChange, planType, align, selectedPriceName, selectedPriceIndex, setSelectedPriceIndex, isDisabled}) {
    const GetPlanPriceBox = () => {
        return priceOptions[planType]({pricesArray, onPriceChange, align, selectedPriceName, selectedPriceIndex, setSelectedPriceIndex, isDisabled});
    }

    return (
        <>{GetPlanPriceBox()}</>
    )

}


export default PriceBlock;