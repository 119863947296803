import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CustomTooltip from '../../../../../shared/components/Tooltip/CustomTooltip';
import PropTypes from 'prop-types';

export default class LineMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
		};
	}

	handleClick = event => {
		this.setState({ anchorEl: { left: event.clientX - 150, top: event.clientY } });
	};

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	updateThreshold = () => {
		this.setState({ anchorEl: null });
		this.props.onClickUpdateThreshold();
	};

	updateAlerte = () => {
		this.setState({ anchorEl: null });
		this.props.onClickUpdateAlerte();
	};

	removeAlerte = () => {
		this.setState({ anchorEl: null });
		this.props.onClickRemoveAlerte();
	};

	render() {
		const { anchorEl } = this.state;
		const { className } = this.props;
		const open = Boolean(anchorEl);
		return (
			<div className={ className }>
				<CustomTooltip title={'Options'}>
					<IconButton
						onClick={ this.handleClick }
					>
						<MoreVertIcon />
					</IconButton>
				</CustomTooltip>
				<Menu
					anchorPosition={ anchorEl }
					anchorReference="anchorPosition"
					open={ open }
					onClose={ this.handleClose }
				>
					<MenuItem onClick={ () => {
						this.updateThreshold();
					} }>Update Threshold</MenuItem>
					<MenuItem onClick={ () => {
						this.updateAlerte();
					} }>Edit alert</MenuItem>
					<MenuItem onClick={ () => {
						this.removeAlerte();
					} }>Remove alert</MenuItem>
				</Menu>
			</div>
		);
	}
}
LineMenu.propTypes = {
	className: PropTypes.string,
	onClickRemoveAlerte: PropTypes.func,
	onClickUpdateAlerte: PropTypes.func,
	onClickUpdateThreshold: PropTypes.func,
};
