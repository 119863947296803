import React from 'react';
import LayoutForm from "../../../shared/components/form/LayoutForm";
import {Typography} from "@material-ui/core";
import GetResetLinkForm from "../forms/getResetLinkForm";
import {sendResetLink} from "../resetPasswordRequests";
import {compose} from "redux";
import {withRouter} from "react-router";
import {useStyles} from "./screens.css";


function GetResetLinkScreen({history}){
    const classes = useStyles();

    const onSubmit = async ({email}) => {
        sendResetLink({email: email});
        history.push({pathname: "/reset-link-sent", state: {email: email}});
    }
    return(
        <LayoutForm customMessage={" "}>
            <div className={classes.screenTitle}>
                Reset Password
            </div>
            <Typography variant={"body1"}>
                Enter your email address and we'll send you a link to reset your password
            </Typography>
            <GetResetLinkForm onSubmit={onSubmit} />
        </LayoutForm>
    )
}

export default compose(withRouter)(GetResetLinkScreen) ;