import {
	CircularProgress,
	Divider,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SubmitButton from '../../../../../shared/components/form/Buttons/SubmitButton';
import PropTypes from 'prop-types';
import { useStyles } from './syslogIntegrationForm.css';
import InformationHover from '../../../../../shared/components/informationHover/InformationHover';
import {
	CERTIFICATE_FORM_KEY,
	CERTIFICATE_FORM_LABEL,
	CONFIG_BUTTON,
	CONFIG_SUBTITLE,
	CONFIG_TITLE,
	CREATE_SYSLOG_BUTTON_TEXT,
	DOWNLOAD_CONFIG,
	formValidationSchema,
	LOG_SETTING_TITLE,
	LOW_SEVERITY_VALUE,
	NAME_FORM_KEY,
	NAME_FORM_LABEL,
	PORT_FORM_KEY,
	PORT_FORM_LABEL,
	PROTOCOL_DEFAULT_LABEL,
	PROTOCOL_FORM_KEY,
	PROTOCOL_FORM_LABEL,
	PROTOCOL_VALUE,
	SAVE_ERROR_MESSAGE,
	SAVE_SUCCESS_MESSAGE,
	SERVER_ADDRESS_FORM_KEY,
	SERVER_ADDRESS_FORM_LABEL,
	SEVERITIES,
	SEVERITY_TITLE,
	SEVERITY_TOOLTIP,
	SYSLOG_FORM_INITIAL_VALUE,
	TEST_CONNECTION_BUTTON_TEST,
	TEST_CONNECTION_FAILED,
	TEST_CONNECTION_SUCCESS,
	CERTIFICATE_TITLE,
	WATCH_SYSLOG_BUTTON_TEXT,
	CERTIFICATE_EXAMPLE_PLACEHOLDER,
	CERTIFICATE_VIEW_ONLY_VALUE,
	CERTIFICATE_PLACEHOLDER_ROWS_SIZE,
} from './const';
import TextFieldWrapper from '../../../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import { useFormik, validateYupSchema } from 'formik';
import { createSyslogIntegration, getSyslogIntegration, testSyslogConnection } from '../../../controller/services/syslog';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';

const SyslogIntegrationForm = ({ onSubmit, onClose }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [severity, setSeverity] = useState(LOW_SEVERITY_VALUE);
	const dispatch = useDispatch();
	const [config, setConfig] = useState(null);
	const [isWatchMode, setIsWatchMode] = useState(false);
	const [testConnectionOption, setTestConnectionOption] = useState(null);

	const classes = useStyles();
	const sensitiveFieldType = isWatchMode ? 'password' : 'text';

	useEffect(() => {
		async function getConfiguration() {
			try {
				const accountIntegration = await getSyslogIntegration();
				if (accountIntegration) {
					setConfig(accountIntegration);
					setIsWatchMode(true);
				} else {
					setIsWatchMode(false);
					setConfig(null);
				}
			} catch {
				setIsWatchMode(false);
				setConfig(null);
			}
		}

		getConfiguration();
	}, []);

	const { values, handleChange, touched, errors, handleSubmit, handleBlur, setFieldTouched, validateField } = useFormik({
		initialValues: SYSLOG_FORM_INITIAL_VALUE,
		enableReinitialize: true,
		validateOnChange: true,
		validateOnBlur: true,
		validationSchema: isWatchMode ? false : formValidationSchema,
		async onSubmit(result) {
			if (isWatchMode) {
				onClose();
				return;
			}

			setIsLoading(true);

			try {
				await createSyslogIntegration({ ...result, severity, protocol: PROTOCOL_VALUE });
				dispatch(openSnackbar('success', SAVE_SUCCESS_MESSAGE, 4000));
				await onSubmit();
			} catch {
				dispatch(openSnackbar('error', SAVE_ERROR_MESSAGE, 4000));
			} finally {
				setIsLoading(false);
			}
		},
	});

	const validateTestConnectionFields = async (port, serverAddress) => {
		let valid = true;
		if (!serverAddress || errors[SERVER_ADDRESS_FORM_KEY]) {
			await setFieldTouched(SERVER_ADDRESS_FORM_KEY);
			valid = false;
		}

		if (!port || errors[PORT_FORM_KEY]) {
			await setFieldTouched(PORT_FORM_KEY);
			valid = false;
		}

		return valid;
	};

	const testConfiguration = async ({ port, serverAddress }) => {
		if (!await validateTestConnectionFields(port, serverAddress)) {
			return;
		}

		try {
			setTestConnectionOption('loader');
			const isConnected = await testSyslogConnection({ port, serverAddress });
			setTestConnectionOption(isConnected);
			setTimeout(() => {
				setTestConnectionOption(null);
			}, 4000);
		} catch {
			setTestConnectionOption(null);
		}
	};

	const TestConnectionLabelOptions = {
		true: <Typography
			className={clsx(classes.testConnectionButtonText, classes.testConnectionResult, classes.testConnectionSuccess)}>
			{TEST_CONNECTION_SUCCESS}
		</Typography>,
		false: <Typography
			color={'error'}
			className={clsx(classes.testConnectionButtonText, classes.testConnectionResult)}>
			{TEST_CONNECTION_FAILED}
		</Typography>,
		null: <Typography
			className={classes.testConnectionButtonText}>
			{TEST_CONNECTION_BUTTON_TEST}
		</Typography>,
		loader: <Box sx={{ display: 'flex' }}>
			<CircularProgress size={'24px'} />
		</Box>,
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid className={classes.form}>
				<Typography className={classes.configTitle}>
					{CONFIG_TITLE}
				</Typography>
				<Typography className={classes.configSubTitle}>
					<span>
						{CONFIG_SUBTITLE}
					</span>
					<a
						target="_blank" download className={classes.downloadConfigButton} href={DOWNLOAD_CONFIG}
						rel="noreferrer">
						{CONFIG_BUTTON}
					</a>
				</Typography>
				<Typography className={classes.sectionTitle}>
					{LOG_SETTING_TITLE}
				</Typography>
				<Grid container spacing={2}>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<TextFieldWrapper
								fullWidth
								id={NAME_FORM_KEY}
								name={NAME_FORM_KEY}
								label={NAME_FORM_LABEL}
								value={config ? config[NAME_FORM_KEY] : values[NAME_FORM_KEY]}
								error={touched[NAME_FORM_KEY] && Boolean(errors[NAME_FORM_KEY])}
								helperText={touched[NAME_FORM_KEY] && errors[NAME_FORM_KEY]}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={isWatchMode}
								InputLabelProps={{ shrink: config ? config[NAME_FORM_KEY] : values[NAME_FORM_KEY] }}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldWrapper
								fullWidth
								id={SERVER_ADDRESS_FORM_KEY}
								name={SERVER_ADDRESS_FORM_KEY}
								label={SERVER_ADDRESS_FORM_LABEL}
								value={config ? config[SERVER_ADDRESS_FORM_KEY] : values[SERVER_ADDRESS_FORM_KEY]}
								error={touched[SERVER_ADDRESS_FORM_KEY] && Boolean(errors[SERVER_ADDRESS_FORM_KEY])}
								helperText={touched[SERVER_ADDRESS_FORM_KEY] && errors[SERVER_ADDRESS_FORM_KEY]}
								onChange={handleChange}
								onBlur={handleBlur}
								disabled={isWatchMode}
								InputLabelProps={{ shrink: config ? config[SERVER_ADDRESS_FORM_KEY] : values[SERVER_ADDRESS_FORM_KEY] }}
								required
							/>
						</Grid>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={6}>
							<TextFieldWrapper
								fullWidth
								id={PORT_FORM_KEY}
								name={PORT_FORM_KEY}
								label={PORT_FORM_LABEL}
								value={config ? config[PORT_FORM_KEY] : values[PORT_FORM_KEY]}
								error={touched[PORT_FORM_KEY] && Boolean(errors[PORT_FORM_KEY])}
								helperText={touched[PORT_FORM_KEY] && errors[PORT_FORM_KEY]}
								onChange={handleChange}
								onBlur={handleBlur}
								type="number"
								disabled={isWatchMode}
								required
							/>
						</Grid>
						<Grid item xs={6}>
							<TextFieldWrapper
								fullWidth
								id={PROTOCOL_FORM_KEY}
								name={PROTOCOL_FORM_KEY}
								label={PROTOCOL_FORM_LABEL}
								value={config ? PROTOCOL_DEFAULT_LABEL : values[PROTOCOL_FORM_KEY]}
								error={touched[PROTOCOL_FORM_KEY] && Boolean(errors[PROTOCOL_FORM_KEY])}
								helperText={touched[PROTOCOL_FORM_KEY] && errors[PROTOCOL_FORM_KEY]}
								onChange={handleChange}
								onBlur={handleBlur}
								required
								disabled
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid container className={classes.severityTitleContainer}>
					<Typography className={classes.severityTitle}>
						{SEVERITY_TITLE}
					</Typography>
					<InformationHover message={SEVERITY_TOOLTIP} additionalStyles={{ width: '200px' }}></InformationHover>
				</Grid>
				<Grid container>
					<RadioGroup
						aria-label="severity"
						name="severity"
						value={config ? config.severity : severity}
						onChange={e => setSeverity(e.target.value)}
					>
						<span className={classes.severityRadio}>
							{SEVERITIES.map(role => (<FormControlLabel
								key={role.value}
								disabled={isWatchMode}
								value={role.value}
								control={<Radio size="small" color="primary" className={classes.radioControl}/>}
								label={<div className={classes.severityLabel}>{role.label}</div>}
								className={classes.radio}
							/>))}
						</span>
					</RadioGroup>
				</Grid>
				<Divider className={classes.divider}/>
				<Typography className={classes.sectionTitle}>
					{CERTIFICATE_TITLE}
				</Typography>
				<Grid container spacing={2} className={classes.settingsContainer}>
					<Grid item>
						<TextFieldWrapper
							fullWidth
							id={CERTIFICATE_FORM_KEY}
							name={CERTIFICATE_FORM_KEY}
							label={CERTIFICATE_FORM_LABEL}
							value={config ? CERTIFICATE_VIEW_ONLY_VALUE : values[CERTIFICATE_FORM_KEY]}
							error={touched[CERTIFICATE_FORM_KEY] && Boolean(errors[CERTIFICATE_FORM_KEY])}
							helperText={touched[CERTIFICATE_FORM_KEY] && errors[CERTIFICATE_FORM_KEY]}
							onChange={handleChange}
							onBlur={handleBlur}
							type={sensitiveFieldType}
							disabled={isWatchMode}
							InputLabelProps={{ shrink: true }}
							placeholder={CERTIFICATE_EXAMPLE_PLACEHOLDER}
							rows={CERTIFICATE_PLACEHOLDER_ROWS_SIZE}
							multiline
							required
						/>
					</Grid>
				</Grid>
				{/*! isWatchMode && <div>
					<Divider className={classes.divider}/>
					<Typography className={classes.testConnection}>
						{TEST_CONNECTION_LABEL}
						<Link
							className={classes.testConnectionButton}
							onClick={() => testConfiguration(values)}
						>
							{TestConnectionLabelOptions[testConnectionOption]}
						</Link>
					</Typography>
				</div> */}
				<div className={classes.footer}>
					{isLoading ? (
						<CircularProgress color="primary"/>
					) : (
						<SubmitButton
							label={isWatchMode ? WATCH_SYSLOG_BUTTON_TEXT : CREATE_SYSLOG_BUTTON_TEXT}
							className={classes.sendButton}/>
					)}
				</div>
			</Grid>
		</form>
	);
};

SyslogIntegrationForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default SyslogIntegrationForm;
