import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	paper: {
		height: '250px',
		width: '628px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
		justifyContent: 'space-between',
	},
	container: {
		height: '100%',
		width: '100%',
	},
	fullWidth: {
		minWidth: '100%',
	},
	sendCodeLabel: { marginBottom: '10px' },
}));

export { useStyles };
