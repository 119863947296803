import {useStyles} from "./mainPanelTemplateV5.css";
import {Grid,Typography} from "@material-ui/core";
import {ReactComponent as BulletPointIcon} from "../../../../assets/walkthrough/analysis/icons/bulletPoint.svg";
import React from "react";

const MainPanelTemplateV5 = ({title, image, description, bulletPoints}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    { title }
                </Typography>
                <div className={classes.description}>
                    {description}
                </div>
                <div className={classes.image}>
                    { image }
                </div>
                <Typography className={classes.details}>
                    { bulletPoints.map((bulletPoint) => {
                        return (
                            <div className={classes.point}>
                                <BulletPointIcon className={classes.icon}/>
                                {bulletPoint}
                            </div>)
                    })}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default MainPanelTemplateV5;