import {
    REQ_TREATH
} from "./threatsActions";
const initialState = {
    threats: []
};

export default function (state = initialState, { type, payload }) {

    switch (type) {
        case REQ_TREATH:
            
            return {
                ...state,
                threats: payload.threats
            }
        default:
            return {
                ...state
            }
    }
    return state;
}