import React from 'react';

export const ERROR_TITLE = 'Something went wrong';
export const ERROR_MESSAGE = 'Error loading \'Findings\' tab. Please try again.';
export const GRAPH_TITLE = 'Packets over time';
export const GRAPH_TITLE_TOOLTIP = <div>The graph shows data according<br/>to the account’s time zone.</div>;
export const VIEW_ANALYSIS_BTN_LABEL = 'View in Analysis';
export const ANALYSIS_TOOLTIP_DISABLED = <span>Click on a point in the graph<br/>to view the event in Analysis</span>;
export const ALERTS_SAMPLING_RATE = 60 * 5 * 1000;
export const HOUR_IN_MILLIE_SECONDS = 3600000;
export const CREATE_QUERY_FROM_ALERT_REQUEST_FAILED = 'Something went wrong. Try again';
export const REACHED_MAXIMUM_QUERIES = 'You can run up to 7 queries at the same time';
export const QUERY_SUCCESSFULLY_CREATED = 'Query from alert successfully created';
