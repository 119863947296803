
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	menu: {
		marginTop: theme.spacing(1),
		border: `1px solid ${theme.palette.primary.main}`,
		overflow: 'auto',
	},
	itemIcon: {
		minWidth: 'auto',
		marginRight: '2px',
	},
	mainBoxInner: {
		display: 'flex',
		alignItems: 'center',
		marginLeft: '2px',
		fontSize: 16,
		height: '24px',
	},
	checkIcon: {
		marginRight: '2px',
		height: '20px',
		width: '20px',
	},
	title: {
		marginLeft: '2px',
		paddingRight: '8px',
	},
	paper: {
		marginTop: 8,
		borderColor: theme.palette.border.main,
		borderStyle: 'solid',
		borderWidth: '1px',
		borderRadius: '4px',
		backgroundColor: 'white',
		maxHeight: '385px',
		boxShadow: 'none',
		overflowY: 'auto',
	},
	list: {
		color: theme.palette.inputText.main,
		paddingTop: 0,
		paddingBottom: 0,
		background: 'white',
		boxShadow: 'none',
		'& li': {
			paddingTop: 10,
			paddingBottom: 10,
		},
		'& li:hover': {
			background: theme.palette.selectorMenuHover.main,
		},
		'& li.Mui-selected': {
			color: 'white',
			background: theme.palette.primary.main,
		},
		'& li.Mui-selected:hover': {
			cursor: 'default',
			color: 'white',
			background: theme.palette.primary.main,
		},
	},

	select: {
		color: theme.palette.inputText.main,
		fontSize: '14px',
		borderRadius: 4,
	},
	selectItemNotSelected: {
		'&:focus': {
			color: theme.palette.secondary.main,
			backgroundColor: 'white',
		},
		'& MuiInputLabel': {
			backgroundColor: 'white',
		},
	},
	selectItemSelected: {
		'&:focus': {
			color: theme.palette.secondary.main,
			backgroundColor: '#A8C1E4 !important',
			borderRadius: '4px',
		},
	},
	formControl: {
		width: 'inherit',
		borderRadius: '4px',
		backgroundColor: 'white',
	},
	formControlItemSelected: {
		backgroundColor: '#A8C1E4',
	},
	selectInputClosed: {
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: '1px solid',
			borderColor: theme.palette.border.main,
		},
	},
	itemClickable: {
		color: theme.palette.primary.main,
		fontWeight: 400,
	},
}));

export default useStyles;
