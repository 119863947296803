import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formLabel:{
        fontWeight: '100',
        fontSize: "15px"
    },
    filterLabel:{
        fontWeight: '100',
        fontSize: "15px",
        marginTop: "4%"
    },
    formTitle:{
        fontSize: "20px",
        marginBottom: "5%",
        marginTop:"2%"
    },
    subTitle:{
        lineHeight: "20px",
        fontSize:"14px",
        letterSpacing: "0.25px",
        fontWeight: '100',
        color: theme.palette.dialogDescription.main
    },
    formFieldsSection:{
        marginTop: "12%"
    },
    emailText:{
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    outputFileText:{
        fontWeight: '100',
        fontSize: "15px",
        marginTop:"30%"
    },
    limitationReportsText:{
        fontWeight: '100',
        fontSize: "15px",
        marginTop: '2%'
    },
}));

export {useStyles}