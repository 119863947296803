import React, { useEffect, useState } from 'react';
import UpgradeToPremiumPlan from './flowsComponents/upgradeToPremiumPlan/upgradeToPremiumPlan';
import { getFlowType } from '../../services/planRequests';
import { Zoom } from '@material-ui/core';
import { UPGRADE_TO_CUSTOM_PLAN_FLOW_TYPE,
	UPGRADE_TO_PREMIUM_PLAN_FLOW_TYPE,
	DOWNGRADE_FROM_BASIC_PLAN_TYPE,
	DOWNGRADE_FROM_CUSTOM_PLAN_TYPE } from '../../../../constants/plansFlowTypes';
import UpgradeToCustomPlan from './flowsComponents/upgradeToCustomPlan/upgradeToCustomPlan';
import DowngradeFromBasicPlan from './flowsComponents/downgradeFromBasicPlan/downgradeFromBasicPlan';
import Loader from '../../../../shared/components/Loader/Loader';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './completePurchaseWrapper.css';
import clsx from 'clsx';
import analyzer from '../../../../helpers/analyzer';
import { ANALYZER_SUCCESS_STATUS, PLANS_CATEGORY, VISIT_ACTION } from '../../../../helpers/analyzerConstants';

const ANALYZER_CATEGORY = PLANS_CATEGORY;

const flowTypesMapper = {
	[UPGRADE_TO_PREMIUM_PLAN_FLOW_TYPE]: UpgradeToPremiumPlan,
	[UPGRADE_TO_CUSTOM_PLAN_FLOW_TYPE]: UpgradeToCustomPlan,
	[DOWNGRADE_FROM_BASIC_PLAN_TYPE]: DowngradeFromBasicPlan,
	[DOWNGRADE_FROM_CUSTOM_PLAN_TYPE]: null,
};

const CompletePurchaseWrapper = ({ selectedBaseProduct, currentBaseProduct, setSelectedPriceName, selectedPriceName, onError, onSuccess, onCloseDialog }) => {
	const classes = useStyles();
	const [flowType, setFlowType] = useState();
	const [isLoading, setIsLoading] = useState(false);
	const [loaderMessage, setLoaderMessage] = useState('');

	useEffect(() => {
		getFlowTypes();
	}, []);

	const getFlowTypes = async () => {
		const { baseProductId: targetBaseProductId } = selectedBaseProduct;
		setIsLoading(true);
		const { flowId } = await getFlowType(targetBaseProductId);
		setIsLoading(false);
		setFlowType(flowId);
		analyzer.event({ category: ANALYZER_CATEGORY, action: VISIT_ACTION, label: `PLANS_FLOW_${flowId}` });
	};

	const FlowTypeWrapperComponent = flowTypesMapper[flowType];

	return (
		<Grid container justify={'center'} style={{ height: '100%' }}>
			{isLoading && <Zoom><Loader message={loaderMessage} /></Zoom>}
			{flowType && !isLoading
            && <Zoom>
            	<FlowTypeWrapperComponent
            		selectedBaseProduct={selectedBaseProduct}
            		selectedPriceName={selectedPriceName}
            		setSelectedPriceName={setSelectedPriceName}
            		currentBaseProduct={currentBaseProduct}
            		onError={onError}
            		onSuccess={onSuccess}
            		onCloseDialog={onCloseDialog}
            		flowType={flowType}
            		setLoaderMessage={setLoaderMessage}
            		setIsLoading={setIsLoading}
            		isLoading={isLoading}
            	/>
            </Zoom>}

		</Grid>
	);
};

export default CompletePurchaseWrapper;
