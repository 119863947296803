import PropTypes from 'prop-types';
import React from 'react';
import Popover from '@material-ui/core/Popover';
import { useDispatch } from 'react-redux';
import { selectPointOnGraph } from '../../backgroundProcess/controller/redux/graphActions';

const OverSummary = ({ content, isOpen, anchorPosition }) => {
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(selectPointOnGraph(null));
	};

	return (
		<div>
			<Popover
				open={ isOpen }
				onClose={ handleClose }
				anchorPosition={ anchorPosition }
				anchorReference="anchorPosition"
			>
				{ content }
			</Popover>
		</div>
	);
};

OverSummary.propTypes = {
	anchorPosition: PropTypes.object,
	content: PropTypes.node,
	isOpen: PropTypes.bool,
};

export default OverSummary;
