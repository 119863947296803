import React, {Component, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import moment from 'moment';
import DayStatistics from './dayStatistics/dayStatistics'
import {Grid, Paper, Popover, Typography} from "@material-ui/core";
import { setAlertPopOver } from '../../../../controller/redux/actions.js'
import { useStyles } from './popOverAlerts.css'
import AlertsTable from "./alertsTable/alertsTable";
import {createBackgroundProcessesById} from "../../../../../backgroundProcess/controller/redux/backgroundProcessAction";
import {createQueryFromAlertPolicy} from "../../../../controller/services/requests"
import {getQueryById} from "../../../../../backgroundProcess/controller/services/visibilityView/requests";
import {openSnackbar} from "../../../../../snackbarAlert/controller/redux/actions";
import { dateFormaterSlash } from '../../../../../../constants/formater';
import {toLocaleDateString} from "../../../../../../helpers/datesHelper";
import analyzer from "../../../../../../helpers/analyzer";
import {ALERTS_CATEGORY, SUBMIT_ACTION, VISIT_ACTION} from "../../../../../../helpers/analyzerConstants";
const ALERTS_SAMPLING_RATE = 60 * 5 * 1000
const HOUR_IN_MILLIE_SECONDS = 3600000
const CREATE_QUERY_FROM_ALERT_REQUEST_FAILED = "Something went wrong. Try again";
const REACHED_MAXIMUM_QUERIES = 'You can run up to 7 queries at the same time'
const QUERY_SUCCESSFULLY_CREATED = 'Query from alert successfully created'

const ANALYZER_CATEGORY = ALERTS_CATEGORY
const GraphAlertPopOver = ({ selectedBar, anchorPosition }) =>{

    const dispatch = useDispatch()
    const features = useSelector(state => state.account.features) || [];
    const is_custom_alerts_link_to_query = features.includes('custom_alerts_l2q')

    const classes = useStyles();
    const {e: alertsBar} = selectedBar;

    useEffect(()=> {
        alertsBar && analyzer.event({category: ANALYZER_CATEGORY, action: VISIT_ACTION, label: 'ALERT_GRAPH_POP_OVER'})
    }, [alertsBar])

    const handleMakeQuery = async (alertPolicyId, timestamp) => {
        try {
            alertsBar && analyzer.event({category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'ALERT_GRAPH_POP_OVER_LINK_TO_QUERY'})

            const timestampInMilliseconds = timestamp * 1000
            const middleSamplingAlertTimestamp = (ALERTS_SAMPLING_RATE / 2) + timestampInMilliseconds;
            const startTime = middleSamplingAlertTimestamp - (HOUR_IN_MILLIE_SECONDS);
            const endTime = middleSamplingAlertTimestamp + (HOUR_IN_MILLIE_SECONDS);

            const {queryId} = await createQueryFromAlertPolicy(alertPolicyId, startTime, endTime);
            const response = await loadLinkQueryData(queryId);

            !response ? dispatch(openSnackbar("error", REACHED_MAXIMUM_QUERIES , 4000)):
                dispatch(openSnackbar("success", QUERY_SUCCESSFULLY_CREATED , 4000))

        } catch (e) {
            dispatch(openSnackbar("error", CREATE_QUERY_FROM_ALERT_REQUEST_FAILED, 4000));
        }

    }

    const loadLinkQueryData = async(id) => {
        const data = await getQueryById(id);
        const originalQuery = JSON.parse(data.query["OriginalQuery"]);
        const creationTime = data.query["CreationTime"];
        const response = await dispatch(createBackgroundProcessesById(id, originalQuery, creationTime));
        return response
    }

    return (
        <Popover
            open={selectedBar}
            onClose={ () => dispatch(setAlertPopOver(null)) }
            classes={{paper: classes.popOverPaper}}
            anchorReference="anchorPosition"
            anchorPosition={{ top: anchorPosition.top, left: anchorPosition.left }}
        >
            <Grid container direction="column" justify="flex-start" className={classes.mainGrid}>
                <Grid item xs={1} className={classes.dateGrid}>
                    {toLocaleDateString(moment.unix(alertsBar.startTime))}
                </Grid>
                <Grid item xs={3} className={classes.dayStatisticGrid} >
                    <DayStatistics
                        alertsCounter={alertsBar.alertsCounter}
                        minValue={alertsBar.minValue}
                        maxValue={alertsBar.maxValue}
                        diffFromAveragePercent={alertsBar.diffFromAveragePercent}/>
                </Grid>
                <Grid item xs={1} className={classes.recentAlertsLabelGrid}>
                    {alertsBar.alertsList.length > 0 && 'Recent alerts'}
                </Grid>
                <Grid item xs={7} className={classes.recentAlertsTableGrid}>
                    {alertsBar.alertsList.length > 0 &&
                        <AlertsTable
                            alertsList ={alertsBar.alertsList}
                            alertPolicyId={alertsBar.alertPolicyId}
                            onMakeQueryButtonClick = {handleMakeQuery}
                            is_custom_alerts_link_to_query={is_custom_alerts_link_to_query}/>}
                </Grid>
            </Grid>
        </Popover>
    );
}

export default GraphAlertPopOver;