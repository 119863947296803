import {
    REQ_USER,
    REQ_USER_SUCCESS,
    REQ_USER_FAILURE,
    ADD_USER_SUCCESS,
    EDIT_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    NODATA_USER_FAILURE,
    UPDATE_FORM_DATA,
    INVITE_USER_FAILURE
} from './userActions';

const initialState = {
    userForm: {},
    users: {},
    isLoading: false
};
export default function (state = initialState, { type, payload }) {
    switch (type) {
        case REQ_USER:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: true
                }
            };
        case REQ_USER_SUCCESS:
            return {
                ...state,
                users: {
                    ...state.users,
                    data: payload,
                    loading: false,
                    loadError: null
                }
            };
        case REQ_USER_FAILURE:
            return {
                ...state,
                users: {
                    ...state.users,
                    data: null,
                    loading: false,
                    loadError: payload
                }
            };
        case INVITE_USER_FAILURE:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: false,
                    loadError: payload
                }
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                users: {
                    data: state.users.data.filter(u => u._id !== payload),
                    loading: false,
                    loadError: null
                }
            };
        case ADD_USER_SUCCESS:
            return {
                ...state,
                users: {
                    data: state.users.data.concat(payload),
                    loading: false,
                    loadError: null
                }
            };
        case EDIT_USER_SUCCESS:
            return {
                ...state,
                users: {
                    data: state.users.data.map(u => u._id === payload._id ? payload : { ...u }),
                    loading: false,
                    loadError: null
                }
            };
        case NODATA_USER_FAILURE:
            return {
                ...state,
                users: {
                    ...state.users,
                    loading: false,
                    loadError: payload
                }
            };
        case UPDATE_FORM_DATA:// we are update form for initialValues react redux
            return {
                ...state,
                userForm: {
                    ...payload,
                }
            };

        default:
            return state;
    }
}
