import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	cardHeader: {
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.light,
		fontWeight: '800',
		maxHeight: '5%',
	},
	card: {
		height: '100%',
		maxHeight: '100%',
		overflowY: 'auto',
	},
	cardContent: {
		height: '95%',
		maxHeight: '95%',
		paddingTop: '0px !important',
		paddingLeft: '0px !important',
		paddingRight: '0px !important',
		minHeight: '95%',
		display: 'flex',
		alignContent: 'stretch',
		flexDirection: 'column',
	},
	sideComponent: {
		position: 'relative',
		bottom: '5px',
		right: '10px',
	},
}));

export { useStyles };
