import { ReactComponent as AWSIcon } from '../../../cloudFilter/servicesIcons/awsLogo.svg';
import { ReactComponent as GoogleCloudIcon } from '../../../cloudFilter/servicesIcons/googleCloudLogo.svg';
import { ReactComponent as AzureIcon } from '../../../cloudFilter/servicesIcons/azureLogo.svg';
import { DEVICE_CATEGORY } from '../../../../helpers/analyzerConstants';

const PUBLIC_CLOUD_DEVICE_TYPE = 'publicCloudDevice';
const ANALYZER_CATEGORY = DEVICE_CATEGORY;

const DEVICE_ERROR_MESSAGE = 'Something went wrong. Try again';
const DEVICE_SAVE_SUCCESS_MESSAGE = 'Device details were added to the dashboard';
const DEVICE_DELETE_SUCCESS_MESSAGE = 'Device has been deleted successfully';
const DEVICE_EDIT_MESSAGE = 'Device had been edited successfully';
const CONFIGURATION_INTERVAL_IN_SECONDS = 120;
const FETCH_DEVICES_INTERVAL = 6000;

const AWS_CLOUD_PROVIDER_LABEL = 'Amazon AWS';
const AWS_CLOUD_PROVIDER_VALUE = 'aws';

const GOOGLE_CLOUD_PROVIDER_LABEL = 'Google Cloud';
const GOOGLE_CLOUD_PROVIDER_VALUE = 'gcp';

const AZURE_CLOUD_PROVIDER_LABEL = 'Azure Cloud';
const AZURE_CLOUD_PROVIDER_VALUE = 'azure';

const cloudProviderOptions = [
	{
		label: AWS_CLOUD_PROVIDER_LABEL,
		value: AWS_CLOUD_PROVIDER_VALUE,
		icon: AWSIcon,
		isDisabled: false,
	},
	{
		label: GOOGLE_CLOUD_PROVIDER_LABEL,
		value: GOOGLE_CLOUD_PROVIDER_VALUE,
		icon: GoogleCloudIcon,
		isDisabled: false,
	},
	{
		label: AZURE_CLOUD_PROVIDER_LABEL,
		value: AZURE_CLOUD_PROVIDER_VALUE,
		icon: AzureIcon,
		isDisabled: true,
	},
];

const PUBLIC_CLOUD_DEVICE_CONFIG = {
	[AWS_CLOUD_PROVIDER_VALUE]:
		{
			configType: 'awsCloudDevice',
			brandLabel: 'AWS',
			label: 'FlowLogs',
			iAmAwareText: 'I am aware that the AWS Flow Logs won’t be active until I completed these steps',
		},
	[GOOGLE_CLOUD_PROVIDER_VALUE]:
		{
			configType: 'gcpCloudDevice',
			brandLabel: 'GCP',
			label: '(Google Cloud Platform)',
			iAmAwareText: 'I am aware that the GPC device won’t be active until I completed these steps',
		},
};

export {
	CONFIGURATION_INTERVAL_IN_SECONDS,
	PUBLIC_CLOUD_DEVICE_CONFIG,
	DEVICE_SAVE_SUCCESS_MESSAGE,
	DEVICE_ERROR_MESSAGE,
	DEVICE_DELETE_SUCCESS_MESSAGE,
	DEVICE_EDIT_MESSAGE,
	FETCH_DEVICES_INTERVAL,
	cloudProviderOptions,
	AWS_CLOUD_PROVIDER_VALUE,
	PUBLIC_CLOUD_DEVICE_TYPE,
	ANALYZER_CATEGORY,
	GOOGLE_CLOUD_PROVIDER_VALUE,
};
