import {TextField} from "@material-ui/core";
import './textField.scss';
import {
    withStyles,
    makeStyles,
} from '@material-ui/core/styles';
import React, {useState} from "react";

const renderTextField = ({
                             name,
                             input,
                             type,
                             label,
                             placeholder = '',
                             className = '',  // Avoiding classNames here, use Style instead
                             size='small',
                             margin='normal',
                             variant= 'outlined',
                             disabled= false,
                             meta,
                             ...custom
                         }) => {
    return (
        <TextField
            error={meta && meta.touched && meta.invalid}
            helperText={meta && meta.touched && meta.error}
            required={meta && meta.invalid}
            fullWidth
            color={"primary"}
            {...{
                size,
                placeholder,
                margin,
                type,
                name,
                label,
                variant,
                disabled,
                ...input,
                ...custom
            }}

        />
    )
};
export default renderTextField;
