import React, { Component } from 'react';

import RedAlert from '../../../../assets/threats/red-alert.svg';
import YellowAlert from '../../../../assets/threats/yellow-alert.svg';
import { MEDIUM } from '../../controller/constants/constanteSeverity';
import './threath.scss';

class ThreatNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        const title = this.props.severity === MEDIUM ? 'Medium' : 'High';
        const img = this.props.severity === MEDIUM ? YellowAlert : RedAlert;
        const threatClass = this.props.severity === MEDIUM ? 'medium' : 'high';
        return (
            <div className="threath-content">
                <div className="threath-severity">
                    <img src={ img } alt="" srcset="" />
                    <div className={ `text-content ${threatClass}` }>{ title }</div>
                </div>
                { threatClass == 'medium' ? <div className="threath-details">

                </div> : null }
            </div>



        );
    }
}

export default ThreatNotification;