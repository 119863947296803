import { Checkbox, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import StyleButton from '../../../../../../../shared/components/form/Buttons/StyleButton';

import useStyles from './VMConfiguration.css';
import { EXPLANATION_NOTE, EXPLANATION_TEXT, I_AM_AWARE_TEXT } from './vmConfigurationTexts';

const VMConfiguration = ({ onClose, onSubmit, samplerData }) => {
	const classes = useStyles();

	const [isAware, setIsAware] = useState(false);

	const finishHandler = () => {
		if (onSubmit) {
			onSubmit(samplerData);
		}

		onClose();
	};

	return (
		<Grid container className={classes.stepWrapper} xs={12}>
			<Grid item xs={11}>
				<Typography paragraph className={classes.explanationLabel}>{EXPLANATION_TEXT}</Typography>
				<Typography paragraph className={classes.explanationLabel}>{EXPLANATION_NOTE}</Typography>
			</Grid>
			<Grid item xs={12} className={classes.awareSection} onClick={() => setIsAware(current => !current)}>
				<Checkbox
					disableRipple
					color={'primary'}
					checked={isAware}
				/>
				<Typography paragraph className={classes.awareText}>{I_AM_AWARE_TEXT}</Typography>
			</Grid>
			<StyleButton onClick={finishHandler} className={classes.button} label="Got it" isDisabled={!isAware} />

		</Grid>
	);
};

VMConfiguration.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	samplerData: PropTypes.object,
};

export default VMConfiguration;
