import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import mapStyle from './map-style-grey.json';
import conf from "../../../../config";
import MapHint from "./mapHint/MapHints";
import {getMapData} from "../../controller/services/map/requests";
import {deviceStatusIcons} from "./statusIcons";
import {connect} from "react-redux";
import _ from 'lodash';

const refreshMapApiInterval = 30000;
const intitialCenterPointNoDevices = [// display the map from vancover to Tel Aviv
    { lat: 51.039481, lng:-123.696744 }, 
    { lat: 32.0853, lng: 34.7818 }
];

export class MapContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            entities: [],
            mapLoaded: false,
            showingInfoWindow: false,
            activeMarker: {},
            selectedDevice: {},
            interval: null
        };
    }

    componentDidMount() {
        this.updateMap();
        const interval = setInterval(this.updateMap, refreshMapApiInterval);
        this.setState({interval});
    }

    componentWillUnmount() {
        clearInterval(this.state.interval);
    }

    updateMap = async () => {
        let entities = await getMapData();
        if (entities){
            entities = this.matchSeverityIcons(entities);
            this.setState({ entities });
        }
    }

    matchSeverityIcons = (entities) => {
        const normalMarkerIcon = deviceStatusIcons.find(d => d.status === 0).asset;
        return entities.map(entity => {
            let icon = normalMarkerIcon;
            const attackStatuses = this.props.attackStatuses;
            if (attackStatuses[entity._id]) {
                icon = deviceStatusIcons.find(d => d.status === attackStatuses[entity._id]).asset;
            }
            return {
                ...entity,
                icon
            }
        })
    }

    handleMarkerClick = (props, marker) => {
        this.setState({
            selectedDevice: props.data,
            activeMarker: marker,
            showingInfoWindow: true,
        });
    };

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: {},
            });
        }
    };
    displayMarkers = () => {
        return this.state.entities.map((entity, index) => {
            return <Marker key={index}
                id={index}
                position={{ lat: entity.latitude, lng: entity.longitude }}
                data={entity}
                icon={{
                    url: entity.icon,
                    anchor: new window.google.maps.Point(48, 48),
                    scaledSize: new window.google.maps.Size(60, 60)
                }}
                onClick={this.handleMarkerClick}
            />
        });
    };
    handleMapReady = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle
        });
        let points;
        if (this.state.entities.length > 0) {
            points = this.state.entities.map(e => {
                return { lat: Number(e.latitude), lng: Number(e.longitude) };
            });
       } else {
            points = intitialCenterPointNoDevices;
        }

       let bounds = new this.props.google.maps.LatLngBounds();
        for (var i = 0; i < points.length; i++) {
            bounds.extend(points[i]);
        }
        this.setState({ bounds: bounds, mapLoaded: true });
    };

    render() {
        return (
            <Map
                ref={'map'}
                google={this.props.google}
                onReady={this.handleMapReady}
                onClick={this.onMapClicked}
                disableDefaultUI={true}
                bounds={this.state.bounds}
            >
                {this.state.mapLoaded && this.displayMarkers()}
                {/*Marker ipgroupModal */}
                <InfoWindow 
                    marker={this.state.activeMarker}
                    visible={this.state.showingInfoWindow}
                >
                    {this.state.selectedDevice &&
                        <MapHint device={this.state.selectedDevice} />}
                </InfoWindow>
            </Map>
        )
    }
}

const mapWrapper = GoogleApiWrapper({
    apiKey: conf.googleMapsApiKey,
    style: mapStyle
})(MapContainer);

const mapStateToProps = state => ({
    attackStatuses: state.attackStatuses.deviceStatuses
});

export default connect(mapStateToProps, null)(mapWrapper);
