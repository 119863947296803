class UtilService {
  BYTE_SIZE_UNIT_NAMES = ["", "K", "M", "G", "T", "P"];
  getUrlParams(restOptions) {
    if (restOptions) {
      const {
        filters, projection, search, populate, paging, sort, dateRange, languageId, handlers,
      } = restOptions;
      return `?${filters ? `filters=${JSON.stringify(filters)}&` : ''}`
                + `${projection ? `project=${JSON.stringify(projection)}&` : ''}`
                + `${search ? `search=${search}&` : ''}`
                + `${populate ? `populate=${JSON.stringify(populate)}&` : ''}`
                + `${paging ? `page=${Number(paging.page) + 1}&pageSize=${paging.pageSize}&` : ''}`
                + `${sort ? `sort=${sort.field}&order=${sort.order}&` : ''}`
                + `${languageId ? `languageId=${languageId}` : ''}`
                + `${dateRange ? `dateRange=${JSON.stringify(dateRange)}&` : ''}`
                + `${handlers ? `handlers=${JSON.stringify(handlers)}&` : ''}`;
    }
    return '';
  };
   convertNumber(number, roundingDigits, is2Base){
     if(number === 0) return "0";

     const base = is2Base ? 1024 : 1000;
     let pwr = Math.floor(Math.log(number)/Math.log(base));

     if (pwr >= this.BYTE_SIZE_UNIT_NAMES.length){
       pwr = this.BYTE_SIZE_UNIT_NAMES.length-1;
     }
     const unitSizeInBytes = Math.pow(base, pwr);


     if (number < base) {
       roundingDigits = 0
     }
     const normalisedSize = (number/unitSizeInBytes).toFixed(roundingDigits);


     return `${normalisedSize} ${this.BYTE_SIZE_UNIT_NAMES[pwr]}`
   }

}

export default new UtilService();
