import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { Grid, Typography } from '@material-ui/core';

import RuleIcon from '../../../assets/modal/rule.svg';
import TextField from '../../../shared/components/form/TextField/TextField';
import SubmitButton from '../../../shared/components/form/Buttons/SubmitButton';
import SimpleModal from '../../../shared/components/simpleTable/simpleModal';
import SelectCreatable from '../../../shared/components/queryLine/selectors/selectCreatable/SelectCreatable';
import { mapGroups } from '../../backgroundProcess/controller/businessLogic/mappers';
import { FormWrapper } from '../../../shared/components/simpleTable/simpleFormContainer';
import {
	validateIpOrMac,
	validateMac,
	validateNumericSelector,
	validatePort,
} from '../../backgroundProcess/controller/validations/validation';
import {
	MODAL_TYPE_TO_SUBMIT_TEXT,
	MODAL_TYPE_TO_TITLE, RULE_MODAL_TITLE,
	VALIDATION_DATA_ERROR,
	VALIDATION_SERVICE_ERROR,
} from '../acceptlistManagementConfig';

import useStyles from './ruleModal.css';
import InformationHover from '../../../shared/components/informationHover/InformationHover';

import useProtocolIdAutoComplete from '../../../shared/customHooks/useProtocolIdAutoComplete';
import { ReactComponent as BidirectionalIcon } from '../../../assets/icons/bidirectionalIcon.svg';
import CheckboxInput from '../../../shared/components/checkboxInput/CheckboxInput';
import BadgeButton from '../../../shared/components/ButtonWithBadge/BadgeButton';
import IpEnrichmentDialog from '../../ipEnrichmentFields/ipEnrichmentDialog';
import { useSelector } from 'react-redux';
import dialogTypes from '../../ipEnrichmentFields/dialogTypes';

const selectCreatableExtraStyles = {
	input: provided => ({
		...provided,
		fontSize: '14px!important',
	}),
	placeholder: provided => ({
		...provided,
		fontSize: '14px!important',
	}),
};

const setValueType = value => value.type || (validateMac(value.value.toUpperCase()) ? 'mac' : 'ip');

const RuleModal = ({ isOpen, actionType, onClose, handleSubmit, onSubmit, formName, error, groups, devices }) => {
	const classes = useStyles();
	const formValues = useSelector(state => getFormValues(formName)(state)) || {};

	const [isDataInputsChanged, setIsDataInputsChanged] = useState(false);
	const [isSrcIpEnrichmentOpen, setIsSrcIpEnrichmentOpen] = useState(false);
	const [isDstIpEnrichmentOpen, setIsDstIpEnrichmentOpen] = useState(false);
	const [badgeContent, setBadgeContent] = useState({
		src: undefined,
		dest: undefined,
	});
	const [isServiceInputsChanged, setIsServiceInputsChanged] = useState(false);

	const { options: ipProtocolOptions, updateOptions } = useProtocolIdAutoComplete();

	useEffect(() => {
		setIsDataInputsChanged(false);
		setIsServiceInputsChanged(false);
	}, [error]);

	useEffect(() => {
		if (formValues) {
			checkEnrichmentActiveFilters(dialogTypes.DEST.value);
			checkEnrichmentActiveFilters(dialogTypes.SRC.value);
		}
	}, []);

	const groupOptions = mapGroups(groups);

	const deviceOptions = Object.keys(devices).map(deviceId => ({
		value: deviceId,
		label: devices[deviceId].name,
		type: 'device',
	}));

	const dataInputChangedHandler = () => setIsDataInputsChanged(true);
	const serviceInputChangedHandler = () => setIsServiceInputsChanged(true);

	const BIDIRECTIONAL_INFO_TEXT = 'Include reverse connection  (Destination IP and port to Source IP and port)';

	const checkEnrichmentActiveFilters = type => {
		const geoKeys = ['country', 'isp', 'organization', 'dns'];
		const activeFilters = Object.keys(formValues).filter(key => geoKeys.some(option => key.toLowerCase().includes(`${type}${option}`) && formValues[key] && formValues[key].length > 0));
		if (activeFilters.length > 0) {
			setBadgeContent(prevState => ({ ...prevState, [type]: activeFilters.length }));
		} else {
			setBadgeContent({ ...badgeContent, [type]: null });
		}
	};

	const closeDestEnrichmentDialog = () => {
		setIsDstIpEnrichmentOpen(false);
		checkEnrichmentActiveFilters(dialogTypes.DEST.value);
	};

	const closeSourceEnrichmentDialog = () => {
		setIsSrcIpEnrichmentOpen(false);
		checkEnrichmentActiveFilters(dialogTypes.SRC.value);
	};

	return (
		<>
			<SimpleModal isOpen={isOpen} toggle={onClose} icon={RuleIcon} header={MODAL_TYPE_TO_TITLE[actionType]}
				width="1000px">
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormWrapper childrenStyle={classes.gridChildrenStyle}>
						<div className={classes.ruleModalHeader}>
							<div>
								{RULE_MODAL_TITLE}
								{!isDataInputsChanged && error && error.includes(VALIDATION_DATA_ERROR) && (
									<Typography className={classes.errorMessage}>{VALIDATION_DATA_ERROR}</Typography>
								)}
							</div>
							<div className={classes.bidirectionalCheckbox}>
								<CheckboxInput
									name="isBidirectional"
									label="Bidirectional connections"
									formName={formName}
									Icon={BidirectionalIcon}
									onChange={dataInputChangedHandler}
									optionalStyles={{
										root: classes.checkboxRoot,
										label: classes.checkboxLabel,
										icon: classes.checkboxIcon,
									}}
								/>
								<InformationHover
									message={BIDIRECTIONAL_INFO_TEXT}
								/>
							</div>
						</div>

						<Grid item container xs={12}>
							<Grid item xs={12}>
								<Grid item>
									<Typography className={classes.fieldLabel}>Rule name*</Typography>
								</Grid>
								<Field
									component={TextField}
									name="description"
									placeholder="Enter rule name"
									margin="none"
									variant="outlined"
									size="small"
									InputProps={{ className: classes.descriptionInput }}
									required
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} justify="space-between">
							<Grid item xs={6} className={classes.smallInputSize}>
								<Grid item>
									<Typography className={classes.fieldLabel}>Source IP or MAC</Typography>
								</Grid>
								<Field
									component={SelectCreatable}
									name="srcIps"
									label="Source IP or MAC"
									placeholder="Enter IP or MAC or select group"
									isFreeText={true}
									validation={validateIpOrMac}
									additionalClasses="react-select-creatable-filters-acceptlist"
									options={groupOptions}
									extraStyles={selectCreatableExtraStyles}
									onChange={dataInputChangedHandler}
									addType={setValueType}
								/>
							</Grid>
							<Grid item xs={6} className={classes.smallInputSize}>
								<Grid item>
									<Typography className={classes.fieldLabel}>Source Port</Typography>
								</Grid>
								<Field
									component={SelectCreatable}
									name="srcPorts"
									label="Source port"
									placeholder="Enter port"
									isFreeText={true}
									validation={validatePort}
									additionalClasses="react-select-creatable-filters-acceptlist"
									noOptionsMessage={null}
									extraStyles={selectCreatableExtraStyles}
									onChange={dataInputChangedHandler}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} justify="space-between">
							<Grid item xs={6} className={classes.smallInputSize}>
								<Grid item>
									<Typography className={classes.fieldLabel}>Destination IP or MAC</Typography>
								</Grid>
								<Field
									component={SelectCreatable}
									name="dstIps"
									label="Destination IP or MAC"
									placeholder="Enter IP or MAC or select group"
									isFreeText={true}
									validation={validateIpOrMac}
									additionalClasses="react-select-creatable-filters-acceptlist"
									options={groupOptions}
									extraStyles={selectCreatableExtraStyles}
									onChange={dataInputChangedHandler}
									addType={setValueType}
								/>
							</Grid>
							<Grid item xs={6} className={classes.smallInputSize}>
								<Grid item>
									<Typography className={classes.fieldLabel}>Destination Port</Typography>
								</Grid>
								<Field
									component={SelectCreatable}
									name="dstPorts"
									label="Destination port"
									placeholder="Enter port"
									isFreeText={true}
									validation={validatePort}
									additionalClasses="react-select-creatable-filters-acceptlist"
									noOptionsMessage={null}
									extraStyles={selectCreatableExtraStyles}
									onChange={dataInputChangedHandler}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} justify="space-between">
							<Grid item xs={6} className={classes.smallInputSize}>
								<Grid item direction={'row'} container alignItems={'center'}>
									<Typography className={classes.fieldLabel}>Protocol ID</Typography>
									<InformationHover message={'IP protocol number such as 6 (TCP) and 17 (UDP)'}/>
								</Grid>
								<Field
									component={SelectCreatable}
									name="protocolIds"
									label="Protocol ID"
									placeholder="Enter Protocol ID"
									isFreeText={true}
									validation={value => {
										updateOptions(value);
										return validateNumericSelector(value);
									}}
									additionalClasses="react-select-creatable-filters-acceptlist"
									noOptionsMessage={null}
									extraStyles={selectCreatableExtraStyles}
									onChange={dataInputChangedHandler}
									options={ipProtocolOptions}
								/>
							</Grid>
							<Grid item xs={6} className={classes.smallInputSize}>
								<Grid item>
									<Typography className={classes.fieldLabel}>Devices</Typography>
								</Grid>
								<Field
									component={SelectCreatable}
									name="devices"
									label="Devices"
									placeholder="Enter device"
									validation={validateNumericSelector}
									additionalClasses="react-select-creatable-filters-acceptlist"
									options={deviceOptions}
									extraStyles={selectCreatableExtraStyles}
								/>
							</Grid>
						</Grid>
						<Grid container xs={12} justifyContent={'space-between'}>
							<Grid item container xs={6}>
								<Grid item xs={12} className={classes.section}>
                                    Applied engines
									{!isServiceInputsChanged && error && error.includes(VALIDATION_SERVICE_ERROR) && (
										<Typography
											className={classes.errorMessage}>{VALIDATION_SERVICE_ERROR}</Typography>
									)}
								</Grid>
								<Grid item container className={classes.servicesContainer}>
									<Grid item>
										<CheckboxInput
											formName={formName}
											name="threatsService"
											label="Threats"
											onChange={serviceInputChangedHandler}
											optionalStyles={{
												wrapper: classes.checkboxWrapper,
												root: classes.checkboxRoot,
												label: classes.checkboxLabel,
												icon: classes.checkboxIcon,
											}}
										/>
									</Grid>
									<Grid item>
										<CheckboxInput
											formName={formName}
											name="vcaService"
											label="VCA"
											onChange={serviceInputChangedHandler}
											optionalStyles={{
												wrapper: classes.checkboxWrapper,
												root: classes.checkboxRoot,
												label: classes.checkboxLabel,
												icon: classes.checkboxIcon,
											}}
										/>
									</Grid>
									<Grid item>
										<CheckboxInput
											name="customAlertsService"
											label="Custom Alerts"
											formName={formName}
											onChange={serviceInputChangedHandler}
											optionalStyles={{
												wrapper: classes.checkboxWrapper,
												root: classes.checkboxRoot,
												label: classes.checkboxLabel,
												icon: classes.checkboxIcon,
											}}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid item container xs={4} justifyContent={'flex-end'} alignItems={'flex-end'} style={{ gap: '18px' }}>
								<Grid item>
									<BadgeButton label={'Src Geo/Org'} badgeContent={badgeContent.src}
										onClick={() => setIsSrcIpEnrichmentOpen(!isSrcIpEnrichmentOpen)}/>
								</Grid>
								<Grid item>
									<BadgeButton label={'Dst Geo/Org'} badgeContent={badgeContent.dest}
										onClick={() => setIsDstIpEnrichmentOpen(!isDstIpEnrichmentOpen)}/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12} justify="center" className={classes.section}>
							<SubmitButton label={MODAL_TYPE_TO_SUBMIT_TEXT[actionType]}
								className={classes.submitButton}/>
						</Grid>
					</FormWrapper>
				</form>
			</SimpleModal>
			<IpEnrichmentDialog useContinent={false}
				enrichmentType={dialogTypes.SRC}
				fieldComponent={SelectCreatable}
				isOpen={isSrcIpEnrichmentOpen}
				handleClose={closeSourceEnrichmentDialog}/>
			<IpEnrichmentDialog useContinent={false}
				enrichmentType={dialogTypes.DEST}
				fieldComponent={SelectCreatable}
				isOpen={isDstIpEnrichmentOpen}
				handleClose={closeDestEnrichmentDialog}/>
		</>
	);
};

RuleModal.propTypes = {
	actionType: PropTypes.string,
	devices: PropTypes.object,
	error: PropTypes.object,
	formName: PropTypes.string,
	groups: PropTypes.array,
	handleSubmit: PropTypes.func,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
};
export default reduxForm({ enableReinitialize: true })(RuleModal);

