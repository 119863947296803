import {WEB_ATTACK_QUERY_TYPE_FEATURE_TOGGLE} from "../../../../constants/featureToggles";

const dataTypeOptions = [
    { prefix: 'Number of', shorter: 'Packets', label: 'Packets over time', value: 1, accessor: 'est_packets', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'Flows', label: 'Flows over time', value: 2, accessor: 'est_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Traffic', shorter: 'Volume', label: 'Volume over time', value: 3, accessor: 'est_volume', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: '', shorter: 'Attack prob', label: 'Attack prob over time', value: 4, accessor: 'attack_prob', isPerSecondSupported: false, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'Src IPs', label: 'Unique Source IPs', value: 5, accessor: 'est_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'Dst IPs', label: 'Unique Dest IPs', value: 6, accessor: 'est_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'New Flow', label: 'New Flows', value: 7, accessor: 'est_new_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'New Source', label: 'New Source IPs', value: 8, accessor: 'est_new_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'New Dest IPs', label: 'New Dest IPs', value: 9, accessor: 'est_new_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'Same Flows', label: 'Same Flows', value: 10, accessor: 'est_same_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'Same IPs', label: 'Same Source IPs', value: 11, accessor: 'est_same_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: 'Number of', shorter: 'Same Dest IPs', label: 'Same Dest IPs', value: 12, accessor: 'est_same_flows', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    { prefix: '', shorter: 'Pkts size Avg', label: 'Packets Size Average', value: 13, accessor: 'est_avg_size', isPerSecondSupported: false, queryType: "timeUnit", isAlert: true },
    { prefix: '', shorter: 'Pkts size Dist', label: 'Packets Size Distribution', value: 14, accessor: 'est_size_distribution', isPerSecondSupported: false, queryType: "numberUnit", isAlert: false },
    { prefix: 'Number of', shorter: 'Dest services', label: 'Max num of dest services', value: 15, accessor: 'max_num_dst_service_conn', isPerSecondSupported: true, queryType: "timeUnit", isAlert: true },
    ... WEB_ATTACK_QUERY_TYPE_FEATURE_TOGGLE ? [{ prefix: '', shorter: 'Web attacks', label: 'Web attacks', value: 16, accessor: 'web_classifier', isPerSecondSupported: true, queryType: 'timeUnit', isAlert: true }] : [],
];

export { dataTypeOptions}
