import { passwordStrength } from 'check-password-strength';

const STRENGTH_OPTIONS = [
	{
		id: 0,
		value: 'Very weak',
		minDiversity: 0,
		minLength: 0,
	},
	{
		id: 1,
		value: 'Weak',
		minDiversity: 2,
		minLength: 3,
	},
	{
		id: 2,
		value: 'So-so',
		minDiversity: 3,
		minLength: 6,
	},
	{
		id: 3,
		value: 'Almost there',
		minDiversity: 4,
		minLength: 9,
	},
	{
		id: 4,
		value: 'Great',
		minDiversity: 4,
		minLength: 12,
	},
];

const calculatePasswordStrength = password => passwordStrength(password, STRENGTH_OPTIONS);

const getPasswordAttrs = password => {
	if (!password) {
		return [];
	}

	const passwordAttrs = calculatePasswordStrength(password).contains;
	if (password.length >= 12) {
		passwordAttrs.push('length');
	}

	return passwordAttrs;
};

export { getPasswordAttrs, calculatePasswordStrength };
