import http from '../../../../services/httpService/http';
import {
	collectorDevicePath, collectorDeviceVisibleCountPath,
	lightweightCollectorDevicesPath,
} from './urls';

const addCollectorToDevice = (collectorId, samplerId, name, type, IP, model, brand, samplingRate, status) =>
/** @newCollectorDevice
     * { collectorId, name, type, IP, samplingRate, status, model, brand }
     */
	http.post(collectorDevicePath, { collectorId, samplerId, name, type, IP, samplingRate, status, model, brand });
const getCollectorDevices = () => http.get(collectorDevicePath);

const getCollectorDevicesVisibleCount = () => http.get(collectorDeviceVisibleCountPath);

const getLightweightCollectorDevices = () => http.get(lightweightCollectorDevicesPath);

const addCollectorDevice = newCollectorDevice => http.post(collectorDevicePath, newCollectorDevice);

const deleteCollectorDevice = collectorDeviceId =>
// TO DO - TEST DETELE COLLECTOR DEVICE

	http._delete(collectorDevicePath + collectorDeviceId);
const updateCollectorDevice = (collectorDeviceId, newValue) => http.put(collectorDevicePath + collectorDeviceId, newValue);

const getCollectorDevice = collectorDeviceId => http.get(`${collectorDevicePath}${collectorDeviceId}`);
export {
	addCollectorToDevice,
	addCollectorDevice,
	getCollectorDevices,
	getCollectorDevicesVisibleCount,
	deleteCollectorDevice,
	updateCollectorDevice,
	getLightweightCollectorDevices,
	getCollectorDevice,
};

