import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as d3 from "d3-format";
import {minMaxformater, timeFormaterAlertsTable} from "../../../../../../../constants/formater";
import moment from "moment";
import { useStyles } from './alertsTable.css'
import {ReactComponent as VisibilityForInformationIcon} from "../../../../../../../assets/endpointInventory/VisibilityForInformation.svg";
import {ReactComponent as VisibilityUpsellIcon} from "../../../../../../../assets/upsellIcons/visibility-upsell.svg";
import {setPlansFlowActivation} from "../../../../../../plans/redux/plansActions";
import {useDispatch} from "react-redux";
import {FEATURES} from "../../../../../../../constants/planFields";

const columns = [
    { id: 'id', label: '#', minWidth: 5 },
    {
        id: 'time',
        label: 'Time',
        minWidth: 5,
        format: (value) => {return moment.unix(value).format(timeFormaterAlertsTable)}
    },
    {
        id: 'value',
        label: 'Value',
        minWidth: 5,
        align: 'right',
        format: (value) => {return d3.format(minMaxformater)(value)}
    },
    {
        id: 'makeQueryButton',
        label: '',
        minWidth: 5,
    }
];

const AlertsTable = ({alertsList, alertPolicyId, onMakeQueryButtonClick, is_custom_alerts_link_to_query}) => {
    const classes = useStyles();
    const [rows, setRows] = useState();
    const dispatch = useDispatch()

    useEffect(() => {
        const rows = alertsList.map((alert,index) => {
            return {...alert, id:index+1}
        });
        setRows(rows);
    },[]);

    const handleOpenPlansFlow = () => {
        dispatch(setPlansFlowActivation(true, true, FEATURES.CUSTOM_ALERTS))
    }
    
    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, maxHeight: "15px", fontSize: "13px"}}
                                    size={"small"}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && rows.map((row, index) => {
                            return (
                                <TableRow hover key={index}>
                                    {columns.map((column, index) => {
                                        const value = row[column.id];
                                        return (
                                            column.id === 'makeQueryButton' ?
                                                <TableCell className={classes.queryButton} title={"Run query"} >
                                                    {is_custom_alerts_link_to_query ?
                                                        <VisibilityForInformationIcon
                                                            onClick={() => onMakeQueryButtonClick(alertPolicyId, row.time)}
                                                        /> :
                                                        <VisibilityUpsellIcon
                                                            onClick={handleOpenPlansFlow}
                                                        />}
                                                </TableCell> :

                                                <TableCell key={column.id} align={column.align} size={"small"}>
                                                    {column.format && typeof value === 'number' ? column.format(value) : value}
                                                </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}

export default AlertsTable;
