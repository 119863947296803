import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    plansContainer:{
        height: '90%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },

    plansOptions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },

    popularPlan: {

    },

    regularPlan: {
        alignSelf: 'center'
    },

    currentPlanTitle: {
        height: '50px',
        fontWeight: '400',
        fontSize: '21px',
        lineHeight: '50px'
    },
    planName: {
        fontWeight: '700',
    }
}));

export {useStyles}