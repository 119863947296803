import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        paddingLeft: "20px",
        paddingRight: "30px",
        paddingTop: "15px"
    },
    title: {
        paddingTop: "40px",
        display: "flex",
        alignItems: "center",
        height: "25%",
        fontSize: "30px",
        fontWeight: "bold",
    },
    image: {
        display: "flex",
        alignItems: "center",
        height: "20%"
    },
    description: {
        height: "15%",
        fontSize: "16px",
        display: "flex",
        alignItems: "center",
        color: theme.palette.dialogDescription.main
    },
    details: {
        paddingTop: "5px",
        height: "40%",
        fontSize: "18px",
        display: "flex",
        flexDirection: "column",
        alignContent: "spaceAround",
        color: theme.palette.dialogDescription.main
    },
    point: {
        paddingTop: "5px"
    },
    icon: {
        marginRight: "5px",
        marginBottom: "1px"
    }
}));

export {useStyles}