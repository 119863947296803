import parsePhoneNumber, { isValidPhoneNumber } from 'libphonenumber-js';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { change, Field, untouch } from 'redux-form';
import StyleButton from '../../../../../shared/components/form/Buttons/StyleButton';
import MakeRemovable from '../formInputs/makeRemovable';
import { useStyles } from './arrayField.css';

const PHONE_NUMBER_ALREADY_EXIST_ERROR_MSG = 'Phone number already exist';
const ADD_BUTTON_TITLE = 'Add';
const ARRAY_INPUT_FIELD_LABEL = 'Phone number';
const ARRAY_INPUT_FIELD_PLACEHOLDER = 'your@email.com';

const phoneValidation = (value, { recipients }) => {
	let error;
	const phoneNumber = value && parsePhoneNumber(value);

	if (!phoneNumber || !isValidPhoneNumber(phoneNumber.number, phoneNumber.country)) {
		error = 'Please enter a valid phone number';
	}

	const isFound = recipients && phoneNumber && recipients.find(recipient => {
		const recipientPhoneNumber = parsePhoneNumber(recipient);
		return phoneNumber.number === recipientPhoneNumber.number;
	});
	if (isFound) {
		error = PHONE_NUMBER_ALREADY_EXIST_ERROR_MSG;
	}

	// If there is just international dialling code. it's not an error. returns - null.
	return error;
};

const RenderPhoneInputField = ({
	name,
	input,
	type,
	label,
	placeholder = '',
	size = 'small',
	margin = 'normal',
	isDefaultDisplayed = true,
	variant = 'outlined',
	disabled = false,
	onInputChange,
	meta: { touched, error, invalid },
	...rest
}) => (
	<MuiPhoneNumber
		defaultCountry={isDefaultDisplayed ? 'us' : ''}
		onChange={onInputChange}
		error={touched && invalid}
		helperText={touched && error}
		{...{
			size,
			placeholder,
			margin,
			type,
			name,
			label,
			variant,
			disabled,
			...input,
			...rest,
		}}
	/>
);

RenderPhoneInputField.propTypes = {
	disabled: PropTypes.bool,
	input: PropTypes.any,
	isDefaultDisplayed: PropTypes.bool,
	label: PropTypes.string,
	margin: PropTypes.string,
	name: PropTypes.string,
	onInputChange: PropTypes.func,
	placeholder: PropTypes.string,
	size: PropTypes.string,
	type: PropTypes.string,
	variant: PropTypes.string,
	meta: PropTypes.object,
};

const SmsArrayField = ({ formName, fields }) => {
	const [newPhoneNumber, setNewPhoneNumber] = useState();
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleAddItemButtonClick = () => {
		fields.push(newPhoneNumber);
		dispatch(change(formName, 'newPhoneNumber', null));
		dispatch(untouch(formName, 'newPhoneNumber'));
		setNewPhoneNumber(null);
	};

	return (
		<div className={classes.mainDiv}>
			<Field
				className={`form-field ${classes.inputField}`}
				name="newPhoneNumber"
				type="text"
				variant= "outlined"
				component= {RenderPhoneInputField}
				onInputChange={value => setNewPhoneNumber(value)}
				onChange={value => setNewPhoneNumber(value)}
				label={ARRAY_INPUT_FIELD_LABEL}
				placeholder={ARRAY_INPUT_FIELD_PLACEHOLDER}
				InputLabelProps={{
					shrink: true,
				}}
				disableAreaCodes
			/>

			<StyleButton
				label={ADD_BUTTON_TITLE}
				title={ADD_BUTTON_TITLE}
				disabled={ !newPhoneNumber || newPhoneNumber.toString().length < 7 || phoneValidation(newPhoneNumber, { recipients: fields.getAll() })}
				onClick={handleAddItemButtonClick}
				className={classes.addButton}/>

			<div className={classes.list}>
				{
					fields.map((value, index) => (
						<MakeRemovable
							className={ 'remove-button-email'}
							handleRemove={ e => {
								e.preventDefault();
								fields.remove(index);
							} }
							key={ index }>
							{fields.get(index)}
						</MakeRemovable>

					))
				}
			</div>
		</div>
	);
};

SmsArrayField.propTypes = {
	fields: PropTypes.array,
	formName: PropTypes.string,
};

export { SmsArrayField, RenderPhoneInputField, phoneValidation };
