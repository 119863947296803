import PropTypes from 'prop-types';

export const defaultProps = {
	header: 'Remediation',
	contentTitle: '',
	contentSubTitle: '',
	isLoading: false,
};

export const propTypes = {
	header: PropTypes.string,
	contentSubTitle: PropTypes.string,
	contentTitle: PropTypes.string,
	isLoading: PropTypes.bool,
	onDismiss: PropTypes.func.isRequired,
	onSubmitForm: PropTypes.func.isRequired,
};
