import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    box :{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '5px',
        height: '395px',
        width: '491px'
    },

    boxItem: {
        flex: '1 1 auto',
        margin: '10px'
    },

    button: {
        width: '330px',
        height: '50px',
        color: 'white',
        borderRadius: '5px',
        textTransform: 'capitalize',
        backgroundColor: (props) => theme.palette[props.buttonColor].main,
        '&:hover': {
            backgroundColor: (props) => theme.palette[props.buttonColor].hover
        }

    },

    headerImg: {
        backgroundImage: (props) => `url(${props.headerImg})`,
        backgroundSize: 'cover',
        height: '195px',
        width: '65%',
        //backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    },

    header:{
        fontWeight: '600',
        fontSize: '20px'
    },

    description:{
        fontWeight: '400',
        fontSize: '14px',
        whiteSpace: 'pre-wrap'
    }
}));



export {useStyles};