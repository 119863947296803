import React from 'react';
import PropTypes from "prop-types";
import {SvgIcon} from "@material-ui/core";

const propsType = {
    width: PropTypes.string,
    height: PropTypes.string,
}

const defaultProps = {
    width: '10',
    height: '10',
    viewBox: '-5 -5 25 25'

}


function XIcon({width, height, viewBox}) {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path fill={'#F05F5A'} d="M0.177332072,11.8190244 C0.295402081,11.9396748 0.450406812,12 0.605411542,12 C0.760416272,12 0.915421003,11.9396748 1.03349101,11.8190244 L6,6.74397231 L10.966509,11.8190244 C11.084579,11.9396748 11.2395837,12 11.3945885,12 C11.5495932,12 11.7045979,11.9396748 11.8226679,11.8190244 C12.0591107,11.5774141 12.0591107,11.1857643 11.8226679,10.9438447 L6.85615894,5.86910202 L11.5656386,1.05638732 C11.8020814,0.8147771 11.8020814,0.423127244 11.5656386,0.181207664 C11.3294986,-0.0604025548 10.9456197,-0.0604025548 10.7094797,0.181207664 L6,4.99392236 L1.2902176,0.181207664 C1.05407758,-0.0604025548 0.670198675,-0.0604025548 0.434058657,0.181207664 C0.197615894,0.422817884 0.197615894,0.81446774 0.434058657,1.05638732 L5.14353832,5.86910202 L0.177332072,10.9438447 C-0.0591106906,11.1854549 -0.0591106906,11.5771048 0.177332072,11.8190244 Z" id="Path"></path>
        </SvgIcon>
    )
}


XIcon.prototype = propsType;
XIcon.defaultProps = defaultProps;
export default XIcon;