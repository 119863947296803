import React from "react";
import step3Side from "../../../../../../assets/walkthrough/overview/steps/step3side.png";
import step3Main from "../../../../../../assets/walkthrough/overview/steps/step3main.png";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "../step2/step2.css";
import MainPanelTemplateV2
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const Step3 = () => {
    const classes = useStyles();
    const description = (
        <>
            Threats details (network device, suspicious IPs, ports, ,etc.) <br/>
            updated in real time
        </>
    )
    return (
        <StepTemplate sidePanelImage={step3Side}>
            <MainPanelTemplateV2 title="Alerts"
                                 image={<img src={step3Main}/>}
                                 description={description}/>
        </StepTemplate>
    )
}

export default Step3;