import { Field } from 'redux-form';
import React from 'react';

import QueryInput from '../../shared/components/queryInput/queryInput';
import countries from '../../shared/datasets/countries';
import continents from '../../shared/datasets/continents';
import { mapGroups } from '../backgroundProcess/controller/businessLogic/mappers';
import { useStyles } from './ipEnrichmentFields.css';
import DirectionSelectCreateable
	from '../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable';
import { textSelectorStyles } from '../../shared/utils/customSelectorStyles';
import PropTypes from 'prop-types';

const IpEnrichmentFields = ({ type: dialogType, groups: dialogGroups, useContinent, fieldComponent }) => {
	const continentOptions = continents
		.map(continent => ({ label: continent, value: continent }))
		.sort();
	const countryOptions = countries.map(country => ({ label: country.name, value: country.name }));
	const classes = useStyles();
	const { value: type } = dialogType;

	const groups = mapGroups(dialogGroups);

	return (
		<div>
			{useContinent && <div className="ip-continent">
				<QueryInput
					title="Continent"
					classes={classes}
					input={
						<Field
							name={`${type}Continent`}
							component={DirectionSelectCreateable}
							isFreeText={false}
							options={continentOptions}
							placeholder="Select continent..."
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>}
			<div className="ip-country">
				<QueryInput
					title="Country"
					classes={classes}
					input={
						<Field
							name={`${type}CountryName`}
							component={fieldComponent}
							isFreeText={false}
							options={countryOptions}
							placeholder="Select country..."
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>
			<div className="ip-isp">
				<QueryInput
					title="ISP"
					classes={classes}
					input={
						<Field
							name={`${type}Isp`}
							component={fieldComponent}
							isFreeText={true}
							options={groups}
							placeholder="Enter ISP..."
							createOptionOnStart={true}
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>
			<div className="ip-organization">
				<QueryInput
					classes={classes}
					title="Organization"
					input={
						<Field
							name={`${type}Organization`}
							component={fieldComponent}
							isFreeText={true}
							options={groups}
							placeholder="Enter organization..."
							createOptionOnStart={true}
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>
			<div className="ip-dns">
				<QueryInput
					title="DNS Address"
					classes={classes}
					input={
						<Field
							name={`${type}Dns`}
							component={fieldComponent}
							isFreeText={true}
							options={groups}
							placeholder="Enter DNS Address..."
							createOptionOnStart={true}
							extraStyles={textSelectorStyles}
						/>
					}
				/>
			</div>
		</div>
	);
};

IpEnrichmentFields.propTypes = {
	type: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	useContinent: PropTypes.bool,
	groups: PropTypes.object,
	fieldComponent: PropTypes.element,
};

IpEnrichmentFields.defaultProps = {
	useContinent: true,
	fieldComponent: DirectionSelectCreateable,
};
export default IpEnrichmentFields;
