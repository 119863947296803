import React from 'react';
import PropTypes from "prop-types";
import {SvgIcon} from "@material-ui/core";

const propsType = {
    width: PropTypes.string,
    height: PropTypes.string,
}

const defaultProps = {
    width: '10',
    height: '10',
    viewBox: '-5 -5 25 25'

}


function VIcon({width, height, viewBox}) {
    return (
        <SvgIcon width={width} height={height} viewBox={viewBox}>
            <path fill={'#02883F'} d="M4,12 C3.824,12 3.664,11.9114391 3.536,11.7343173 L0.192,7.10701107 C-0.064,6.75276753 -0.064,6.15498155 0.192,5.80073801 C0.448,5.44649446 0.88,5.44649446 1.136,5.80073801 L4,9.76383764 L10.864,0.265682657 C11.12,-0.0885608856 11.552,-0.0885608856 11.808,0.265682657 C12.064,0.619926199 12.064,1.21771218 11.808,1.57195572 L4.464,11.7343173 C4.336,11.9114391 4.176,12 4,12 Z" id="Path"></path>
        </SvgIcon>
    )
}


VIcon.prototype = propsType;
VIcon.defaultProps = defaultProps;
export default VIcon;