const VISIBLE_DEVICE_COUNT_INTERVAL = 60000;
const BASE_QUERY_EXTRA = {
	includedIpsDest: [],
	excludedIpsDest: [],
	excludedIpsSrc: [],
	includedIpsSrc: [],
	typeproto: {
		isIn: false,
		isOut: false,
	},
	firewall: {
		isFWE_ALLOW: false,
		isFWE_DENY: false,
	},
};

export {
	VISIBLE_DEVICE_COUNT_INTERVAL,
	BASE_QUERY_EXTRA,
};
