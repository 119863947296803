import { GET_ACCOUNT_PUBLIC_FIELDS, REQ_ADD_GROUP, REQ_DELETE_GROUP, REQ_FEATURES, REQ_GROUPS, REQ_UPDATE_GROUP, UPDATE_IP_FROM_GROUP } from './accountActions';

const initialState = {
	groups: [],
	features: [],
	restrictions: {},
	isInitialized: false,
};

export default function (state = initialState, { type, payload }) {
	switch (type) {
		case GET_ACCOUNT_PUBLIC_FIELDS:
			return {
				...state,
				name: payload.name,
				subscriptionEnd: payload.subscriptionEnd,
				features: payload.features,
				restrictions: payload.restrictions,
				customizedLogoPath: payload.customizedLogoPath,
				isInitialized: true,
			};
		case REQ_UPDATE_GROUP:
			return {
				...state,
				currentgroup: payload.group,
			};
		case REQ_ADD_GROUP:
			return {
				...state,
				groups: payload.group,
			};
		case REQ_FEATURES:
			return {
				...state,
				features: payload.features,
			};
		case REQ_GROUPS:
			return {
				...state,
				groups: payload.groups,
			};
		case UPDATE_IP_FROM_GROUP:
			return {
				...state,
				currentgroup: payload.group,
			};
		case REQ_DELETE_GROUP:
			return {
				...state,
				currentgroup: payload.group,
			};
		default:
			return state;
	}
}
