import _ from 'lodash';
import { thresholdObject } from '../constants/threshold';
import { dataTypeOptions } from '../../../backgroundProcess/controller/redux/analyzeActions';
import {
	createAwsFilterFromPayload, createAzureFilterFromPayload,
	createDestPortsFromPayload, createDestPortsPayload,
	createEnrichmentInputsByPayload,
	createEnrichmentPayload, createGoogleCloudFilterFromPayload,
	createInterfacesFromPayload,
	createInterfacesPayload, createIpProtocolFromPayload, createIpProtocolsPayload,
	createSrcPortsFromPayload, createSrcPortsPayload,
	createTcpFlagsPayload, createVlanFromPayload, createVlanPayload,
	isFilledTcpFlags,
} from '../../../../shared/utils/formToPayload';
import numeral from 'numeral';
import analyzer from '../../../../helpers/analyzer';
import { ALERTS_CATEGORY, SUBMIT_ACTION } from '../../../../helpers/analyzerConstants';
import parseAwsPayload from '../../../cloudFilter/requestParser';

const ANALYZER_CATEGORY = ALERTS_CATEGORY;

export const mapPayloadToForm = payload => {
	const { alertType, enabled, name, Id, version } = payload;
	const { extra, devices, timeWindow, queryTypes, ipWhoIs, tcpFlags, interfaces, srcPorts, destPorts, ipProtocols, cloudAws, vlan } = payload.data.originalQuery;
	const QueryType = _.find(dataTypeOptions, e => e.value === queryTypes[0]);

	const { value, type } = payload.data.threshold;
	const thresholdType = thresholdObject[type];
	const thresholdValue = value;
	const objectId = Id;
	const formIpWhoIs = _.isEmpty(ipWhoIs) ? {} : createEnrichmentInputsByPayload(ipWhoIs);
	const formTcpFlags = _.isEmpty(tcpFlags) ? {} : tcpFlags;
	const formInterfaces = _.isEmpty(interfaces) ? {} : createInterfacesFromPayload(interfaces);
	const formSrcPorts = srcPorts ? createSrcPortsFromPayload(srcPorts) : [];
	const formDestPorts = destPorts ? createDestPortsFromPayload(destPorts) : [];
	const formIpProtocols = ipProtocols ? createIpProtocolFromPayload(ipProtocols) : [];
	const formAwsFilter = cloudAws ? parseAwsPayload(cloudAws) : {};

	const { isIn, isOut } = extra.typeproto;
	const { isFWE_DENY, isFWE_ALLOW } = !_.isEmpty(extra.firewall) ? extra.firewall : { isFWE_DENY: false, isFWE_ALLOW: false };
	const formVlanFilter = vlan ? createVlanFromPayload(vlan) : {};
	const { excludedIpsDest, includedIpsDest, excludedIpsSrc,
		includedIpsSrc } = extra;

	return {
		isIn, isOut, isFWE_DENY, isFWE_ALLOW,
		ipSource: includedIpsSrc.concat(excludedIpsSrc)
			.map(e => ({ ...e, direction: e.direction == '+' ? 'i' : 'o' })),
		ipDestination: includedIpsDest.concat(excludedIpsDest)
			.map(e => ({ ...e, direction: e.direction == '+' ? 'i' : 'o' })),
		thresholdType,
		thresholdValue,
		...formIpWhoIs,
		...formTcpFlags,
		...formInterfaces,
		queryTypes: QueryType,
		portsSource: formSrcPorts,
		portsDestination: formDestPorts,
		ipProtocol: formIpProtocols,
		alertType, devices, name, objectId, timeWindow, enabled, version,
		aws: formAwsFilter,
		vlan: formVlanFilter,
		vlanIdBidirectionalTraffic: vlan && vlan.vlanIdBidirectionalTraffic,
	};
};

const removeIsNew = array => _.map(array, e => {
	delete e.__isNew__;
	return e;
});

const fillDirectionIfNotExist = array => _.map(array, e => ({ ...e, direction: (_.isEmpty(e.direction) ? '+' : e.direction === 'o' ? '-' : '+') }));

const filterDirections = (array, sign) => {
	const filtered = _.filter(array, e => e.direction === sign);
	return _.isEmpty(filtered) ? [] : filtered;
};

export const mapFormToPayload = form => {
	const extra = {};

	const filtermap = ['ipDestination', 'ipSource'];

	filtermap.forEach(e => {
		form[e] = fillDirectionIfNotExist(removeIsNew(form[`${e}`]));
	});
	const ipDest = form.ipDestination;
	const { ipSource } = form;

	extra.excludedIpsDest = _.map(filterDirections(ipDest, '-'), e => ({ ...e, value: e.value.trim(), label: e.label.trim() }));

	extra.includedIpsDest = _.map(filterDirections(ipDest, '+'), e => ({ ...e, value: e.value.trim(), label: e.label.trim() }));

	extra.excludedIpsSrc = _.map(filterDirections(ipSource, '-'), e => ({ ...e, value: e.value.trim(), label: e.label.trim() }));

	extra.includedIpsSrc = _.map(filterDirections(ipSource, '+'), e => ({ ...e, value: e.value.trim(), label: e.label.trim() }));

	const { devices } = form;

	extra.typeproto = {
		isIn: form.isIn,
		isOut: form.isOut,
	};
	extra.firewall = {
		isFWE_ALLOW: form.isFWE_ALLOW,
		isFWE_DENY: form.isFWE_DENY,

	};

	const ipWhoIs = createEnrichmentPayload(form);

	const tcpFlags = isFilledTcpFlags(form) ? createTcpFlagsPayload(form) : {};

	const interfaces = createInterfacesPayload(form);
	const srcPortsData = createSrcPortsPayload(form);
	const destPortsData = createDestPortsPayload(form);
	const ipProtocolsData = createIpProtocolsPayload(form);
	const aws = createAwsFilterFromPayload(form);
	const googleCloud = createGoogleCloudFilterFromPayload(form);
	const azure = createAzureFilterFromPayload(form);
	const vlan = createVlanPayload(form);

	const { timeWindow, name, version, enabled } = form;

	const result = {
		timeWindow, queryTypes: [form.queryTypes.value], name, version, devices,
		thresholdValue: numeral(_.isString(form.thresholdValue) ? form.thresholdValue.toLowerCase() : form.thresholdValue)._value,
		thresholdType: form.thresholdType.value,
		enabled,
		extra,
		ipWhoIs,
		srcPorts: srcPortsData,
		destPorts: destPortsData,
		ipProtocols: ipProtocolsData,
		vlan,
	};
	if (!_.isEmpty(tcpFlags)) {
		result.tcpFlags = tcpFlags;
	}

	if (!_.isEmpty(interfaces)) {
		result.interfaces = interfaces;
	}

	if (!_.isEmpty(aws)) {
		result.cloudAws = aws;
	}

	if (!_.isEmpty(googleCloud)) {
		result.googleCloud = googleCloud;
	}

	if (!_.isEmpty(azure)) {
		result.azureCloud = azure;
	}

	analyzer.trackQuery(result, ANALYZER_CATEGORY, SUBMIT_ACTION);

	return result;
};
