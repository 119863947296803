import moment from 'moment';
import store from '../containers/app/controller/redux/store/store';

const getFormatters = () => {
	const { locale } = store.getState().userSettings;
	const { timezone } = store.getState().userSettings;
	const FORMAT = store.getState().userSettings.format;

	const DATE_FORMATTER = new Intl.DateTimeFormat(locale, {
		timeZone: timezone,
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	});

	const SHORT_DATE_FORMATTER = new Intl.DateTimeFormat(locale, {
		timeZone: timezone,
		month: '2-digit',
		day: '2-digit',
	});
	const TIME_FORMATTER = new Intl.DateTimeFormat(locale, {
		timeZone: timezone,
		hourCycle: 'h23',
		hour: '2-digit',
		minute: '2-digit',
	});

	return { FORMAT, DATE_FORMATTER, SHORT_DATE_FORMATTER, TIME_FORMATTER };
};

const toLocaleDateString = (date, displayTime = false, shortDate = false, literal = '/') => {
	const copyDate = new Date(date);
	const { FORMAT, DATE_FORMATTER, TIME_FORMATTER } = getFormatters();
	const localDateString = FORMAT
		? moment(copyDate).format(FORMAT)
		: DATE_FORMATTER.format(new Date(copyDate)).replaceAll(/[.\/-]/g, literal);
	const localTimeString = displayTime ? TIME_FORMATTER.format(new Date(copyDate)) : '';

	return [localDateString, localTimeString].join(' ');
};

const toLocaleShortDateString = (date, displayTime = false, literal = '/') => {
	const { SHORT_DATE_FORMATTER, TIME_FORMATTER } = getFormatters();
	const localDateString = SHORT_DATE_FORMATTER.format(new Date(date)).replaceAll(/[.\/-]/g, literal);
	const localTimeString = displayTime ? TIME_FORMATTER.format(new Date(date)) : '';

	return [localDateString, localTimeString].join(' ');
};

/**
 	* Calculates the date [daysBack] days ago and the current date.
 	* @param {number} daysBack - The number of days to subtract from the current date to calculate the "from" date.
 	* @returns {Object} A JSON object with "from" and "to" properties representing the past timestamp [daysBack] days ago and the current date, respectively.
 	*/
const getDaysBackDateToNow = daysBack => {
	const from = new Date();
	const to = new Date();
	from.setDate(to.getDate() - daysBack); // Set the past date by subtracting [days] from the current date

	return { from: from.getTime(), to: to.getTime() };
};

export { toLocaleDateString, toLocaleShortDateString, getDaysBackDateToNow };
