export const OVERVIEW_KEY = "overview"
export const ANALYSIS_KEY = "analysis"
export const CUSTOM_ALERTS_KEY = "customAlerts"
export const IP_GROUPS_KEY = "ipGroups"
export const DEVICES_KEY = "devices"

export const interactionKeys = {
    [OVERVIEW_KEY]: "overview.walkthroughDone",
    [ANALYSIS_KEY]: "analysis.walkthroughDone",
    [IP_GROUPS_KEY]: "ipGroups.walkthroughDone",
    [DEVICES_KEY]: "devices.walkthroughDone",
    [CUSTOM_ALERTS_KEY]: "customAlerts.walkthroughDone"
}