import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		backgroundColor: '#ffffff',
		alignItems: 'center',
	},
	fullScreenWrapper: {
		display: 'flex',
		paddingTop: 10,
		justifyContent: 'center',
		alignItems: 'center',
		height: 'fit-content',
	},
	fullScreenDivider: {
		height: 21,
	},
	actionIcon: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		cursor: 'pointer',
	},
	icon: {
		color: '#48648B',
	},
	highlighted: {
		color: theme.palette.severity.medium,
	},
	tooltipText: {
		fontSize: '14px',
	},
}));

export default useStyles;
