import http from "../../../../services/httpService/http";
import {AUTH_URI} from "../../../app/controller/services/urls";

const API_PATH = `${AUTH_URI}/api-key`;

const getSecret = () => {
    return http.get(API_PATH);
}

export {
    getSecret
}