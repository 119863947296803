import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	header: {
		height: '113px',
		backgroundImage: props => `url(${props.HeaderBackground})`,
		background: 'bottom',
		color: 'white',
		fontSize: '34px',
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerLabel: {
		textAlign: 'center',
		display: 'block',
		lineHeight: '22px',
		fontSize: '36px',
		fontWeight: 700,
	},
	headerIcon: {
		marginRight: '4%',
		marginTop: '2%',
		width: '130px',
	},
}));

export default useStyles;
