import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import { useStyles } from './widgetContainer.css';
import PropTypes from 'prop-types';

const WidgetContainer = ({ children, title, sideComponent, cardProps, cardHeaderProps, cardContentProps }) => {
	const classes = useStyles();
	return (
		<Card raised className={classes.card} elevation={0} {...cardProps}>
			<CardHeader
				title={title}
				className={classes.cardHeader}
				titleTypographyProps={{ style: { fontSize: '1rem' } }}
				action={
					<div className={classes.sideComponent}>{sideComponent}</div>
				}
				{...cardHeaderProps}
			/>
			<CardContent className={classes.cardContent} {...cardContentProps}>
				{children}
			</CardContent>
		</Card>);
};

WidgetContainer.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	cardProps: PropTypes.object,
	cardHeaderProps: PropTypes.object,
	cardContentProps: PropTypes.object,
	sideComponent: PropTypes.any,
};
WidgetContainer.defaultProps = {
	cardProps: {},
	cardContentProps: {},
	cardHeaderProps: {},
	sideComponent: <></>,
};
export default WidgetContainer;
