const { tableConfig } = require('../constantes/tableConfig');

const getNewEntryFields = () => tableConfig.fields.filter(f => f.newEntryField);

const getAllowEdit = () => tableConfig.fields.filter(f => f.editField);

const filterDelete = data => data.filter(d => d.status !== 'DELETED');

export {
	getNewEntryFields,
	getAllowEdit,
	filterDelete,
};
