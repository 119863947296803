import { isFilledTcpFlags } from '../../../../../shared/utils/formToPayload';

const filterCountMethod = (formValues = {}, keys) => Object.keys(formValues).filter(key => keys.includes(key) && formValues[key] && formValues[key].length > 0);

const CLOUD_KEYS = ['accountIds', 'azs', 'destServices', 'srcServices', 'regions', 'instanceIds', 'subnetsIds', 'vpcIds', 'interfacesIds'];
const INPUT_INTERFACES_KEYS = ['inputInterface'];
const OUTPUT_INTERFACES_KEYS = ['outputInterface'];
const VLAN_KEYS = ['srcVlanId', 'dstVlanId', 'vxlanId'];
const SRC_GEO_KEYS = ['srcContinent', 'srcCountryName', 'srcOrganization', 'srcDns', 'srcIsp'];
const DST_GEO_KEYS = ['destContinent', 'destCountryName', 'destOrganization', 'destDns', 'destIsp'];

export const activeFilterMap = {
	moreOptions: [
		{
			count: formData => isFilledTcpFlags(formData) ? 1 : 0,
		},
		{
			count: formData => formData.isIn || formData.isOut ? 1 : 0,
		},
		{
			count: formData => filterCountMethod(formData, INPUT_INTERFACES_KEYS).length,
		},
		{
			count: formData => filterCountMethod(formData, OUTPUT_INTERFACES_KEYS).length,
		},
		{
			count: formData => filterCountMethod(formData.vlan, VLAN_KEYS).length,
		},
	],
	srcEnrichment: [
		{
			count: formData => filterCountMethod(formData, SRC_GEO_KEYS).length,
		},
	],
	dstEnrichment: [
		{
			count: formData => filterCountMethod(formData, DST_GEO_KEYS).length,
		},
	],
	cloud: [
		{
			count: formData => filterCountMethod(formData.aws, CLOUD_KEYS).length,
		},
	],
};
