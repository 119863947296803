import React, {useEffect, useState} from "react";
import SelectPlanContainer from "../../plansContainer/selectPlanContainer/selectPlanContainer";
import {getDefaultPlans} from "../../services/planRequests";
import usePolling from "../../../../shared/customHooks/usePolling";
import Loader from "../../../../shared/components/Loader/Loader";
import Grid from "@material-ui/core/Grid";
import {useSelector} from "react-redux";

const SelectPlanWrapper = ({setSelectedBaseProduct, setSelectedPriceName, selectedPriceName, setCurrentBaseProduct, nextStep}) => {
    const {data = [], isLoading} = usePolling(getDefaultPlans, 500);
    const currentPlanDetails = useSelector(state => state.plans.currentPlanDetails);


    const onPlanSelected = (baseProduct, selectedPriceName) => {
        const currentPlanFullDetails = data.find(plan => plan.baseProductId === currentPlanDetails.baseProductId);
        setCurrentBaseProduct(currentPlanFullDetails);
        setSelectedBaseProduct(baseProduct);
        setSelectedPriceName(selectedPriceName)
        nextStep();
    }

    return (
        <Grid container style={{height: '100%'}} justify={"center"}>
            {isLoading && <Loader/>}
            {data && data.length >= 1 &&
            <SelectPlanContainer plans={data}
                                 onSelectPlan={onPlanSelected}
                                 setSelectedPriceName={setSelectedPriceName}
                                 selectedPriceName={selectedPriceName}
                                 currentPlanDetails={currentPlanDetails}/>

            }
        </Grid>


    )
}

export default SelectPlanWrapper;