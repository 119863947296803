import { WidgetContainer } from '../../../index';
import React, { useEffect, useRef, useState } from 'react';
import { DEVICE_TRAFFIC_WIDGET_TITLE } from '../deviceMonitoringTexts';
import { overrides } from './stockGraphConfig';
import StockGraph from '../../../stockGraph/stockGraph';
import useInterval from '../../../../customHooks/useInterval';
import { getVolumeByDevice } from '../../services/requests';
import moment from 'moment';
import PropTypes from 'prop-types';
import { NoData } from '../sharedComponents/NoData';

const FETCH_DEVICE_TRAFFIC_INTERVAL_MS = 1000 * 60 * 5;
let currentDeviceId;
const DeviceTraffic = ({ deviceId }) => {
	const [data, setData] = useState([]);
	const [minDate, setMinDate] = useState();
	const [maxDate, setMaxDate] = useState();
	const [isDeviceHaveData, setIsDeviceHaveData] = useState(true);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fillGraphWithDeviceTrafficData(true);
		currentDeviceId = deviceId;
	}, [deviceId]);

	const fillGraphWithDeviceTrafficData = async (hasDeviceChanged = false) => {
		currentDeviceId = deviceId;

		if (hasDeviceChanged) {
			setIsLoading(true);
		}

		const { data: volumes, deviceId: requestDeviceId } = await getVolumeByDevice(deviceId);

		if (Boolean(currentDeviceId) && currentDeviceId !== requestDeviceId) {
			return;
		}

		const transformedVolumesData = volumes.map(({ timestamp, volume }) => ({
			x: Number(moment(timestamp * 1000).utc(true)),
			y: volume,
		}));
		const sortedGraphData = transformedVolumesData.sort((a, b) => a.x - b.x);

		if (isDeviceHaveTraffic(sortedGraphData)) {
			setIsDeviceHaveData(true);
		} else {
			setIsDeviceHaveData(false);
		}

		setMinDate(transformedVolumesData[0].x);
		setMaxDate(transformedVolumesData[transformedVolumesData.length - 1].x);
		setData(sortedGraphData);
		setIsLoading(false);
	};

	const isDeviceHaveTraffic = devicesTraffic => {
		let hasData = false;
		for (const deviceTraffic of devicesTraffic) {
			if (deviceTraffic.y) {
				hasData = true;
			}
		}

		return hasData;
	};

	useInterval(fillGraphWithDeviceTrafficData, FETCH_DEVICE_TRAFFIC_INTERVAL_MS);

	return (<WidgetContainer title={DEVICE_TRAFFIC_WIDGET_TITLE}>
		{isDeviceHaveData
			? < StockGraph
				data={data}
				maxDate={maxDate}
				minDate={minDate}
				isLoading={isLoading}
				configOverrides={overrides}
			/> : <NoData/>}
	</WidgetContainer>);
};

DeviceTraffic.propTypes = {
	deviceId: PropTypes.string.isRequired,
};
DeviceTraffic.defaultValue = {
	deviceId: '',
};

export default React.memo(DeviceTraffic);
