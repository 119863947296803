import action from '../middleware';
import http from '../../../../../services/httpService/http';
import _ from 'lodash';
import { SET_ERROR } from '../message/messageActions';
import {
	ACCOUNT_CUSTOMIZATION_URI,
	AUTH_URI,
	DASHBOARD_INTERACTIONS_USER_URI,
	INVITATIONS_URI,
	USER_URI,
} from '../../services/urls';
import moment from 'moment';
import { logoutUser, redirectToHostedUi } from '../../../../authenticateUser/services/tools';
import { UPDATE_WALKTHROUGH_DONE } from '../dashboardInteractions/actionTypes';
import Storage from "../../../../../services/storage";
export const END_SUBCRIPTION = 'END_SUBCRIPTION';
export const SUBSCRIPTION_USER = 'SUBSCRIPTION_USER';
export const SUBCRIPTION_PLAN_USER = 'SUBCRIPTION_PLAN_USER';
export const SUBCRIPTION_PAYMENT_USER = 'SUBCRIPTION_PAYMENT_USER';
export const SAW_FIRT_MESSAGE = 'SAW_FIRT_MESSAGE';
export const ACCOUNT_CUSTOMIZATION = 'ACCOUNT_CUSTOMIZATION';
export const UPDATE_ACCOUNT_CUSTOMIZATION = 'UPDATE_ACCOUNT_CUSTOMIZATION';
export const CANCEL_ALL = 'CANCEL_ALL';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const UPDATE_CURRENT_USER_INFO = 'UPDATE_CURRENT_USER_INFO';
export const START_ONBOARDING = 'START_ONBOARDING';
export const END_ONBOARDING = 'END_ONBOARDING';

const accountCutomisation = () => action(async dispatch => {
	try {
		const res = await http.get(ACCOUNT_CUSTOMIZATION_URI);

		if (res.trialEnd && res.subscriptionEnd) {
			if (moment(res.trialEnd).diff(moment()) < 0
                    && moment(res.subscriptionEnd).diff(moment() < 0)) {
				dispatch({ type: SUBSCRIPTION_USER });
			}
		}

		dispatch({ type: ACCOUNT_CUSTOMIZATION, payload: { customization: res } });
		return res;
	} catch (error) {
		console.log(error);
	}
});

const updateCustomization = key => action(async dispatch => {
	const res = await http.put(ACCOUNT_CUSTOMIZATION_URI,
		{ customizationKey: key,
			customizationValue: true });

	dispatch({ type: UPDATE_ACCOUNT_CUSTOMIZATION, payload: { customization: res } });
});

const login = () => action(async () => {
	// TODO: WIP - We will make some consideration what will be happend from the invitation login
	const { origin } = window.location;
	redirectToHostedUi(origin);
});

const checkValidInvitation = id => action(async dispatch => {
	const res = await http.get(`${INVITATIONS_URI}/${id}`);
	return res;
});

const setPassword = (id, password) => action(async dispatch => {
	const res = await http.post(USER_URI, {
		userInvitationId: id,
		password,
	});
	return res;
});

const logout = () => action(async () => {
	const { origin } = window.location;
	Storage.set('process', []);
	Storage.set('removedInsights', []);
	Storage.set('user', null);
	logoutUser(origin);
});

const onSubcription = () => ({
	type: SUBSCRIPTION_USER,
	payload: false,
});
const cancelAll = () => ({
	type: CANCEL_ALL,
});
const onPlanSubcription = () => ({
	type: SUBCRIPTION_PLAN_USER,
});

const getCurrentUser = () => action(async dispatch => {
	const res = await http.get(`${USER_URI}/current`, { params: { includeMfaSettings: true } });
	if (!res || res.error) {
		console.error('an error occurred while trying to get current user');
		return null;
	}

	dispatch({
		type: GET_CURRENT_USER,
		payload: { user: res },
	});

	return res;
});

const startOnBoarding = () => ({ type: START_ONBOARDING, payload: {} });

const endOnBoarding = () => ({ type: END_ONBOARDING, payload: {} });

const updateUserInfo = userUpdateInfo => action(async dispatch => {
	dispatch({
		type: UPDATE_CURRENT_USER_INFO,
		payload: { userUpdateInfo },
	});
});

export {
	onPlanSubcription,
	onSubcription,
	checkValidInvitation,
	setPassword,
	logout,
	accountCutomisation,
	updateCustomization,
	cancelAll,
	getCurrentUser,
	login,
	startOnBoarding,
	endOnBoarding,
	updateUserInfo,
};
