import React, { useState } from 'react';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import {
	passwordsMatch,
	required,
	passwordMinLength, isPasswordStrong,
} from '../../../shared/components/form/Validation/FieldLevelValidation';
import SubmitButton from '../../../shared/components/form/Buttons/SubmitButton';
import '../setPassword.scss';
import TextFieldMUI from '@material-ui/core/TextField/TextField';
import TextField from '../../../shared/components/form/TextField/TextField';
import { CircularProgress, InputAdornment, Link, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import StrengthMeter from '../../../shared/components/PasswordField/passwordMeter';
import { connect } from 'react-redux';
import PasswordTooltip from './PasswordTooltip/PasswordTooltip';
import { getPasswordAttrs } from '../../resetPassword/passwordUtils';

let SetPasswordForm = ({ handleSubmit, error, invalid, email, password }) => {
	const [passwordVisibility, setPasswordVisible] = useState({ password: false, confirm: false });
	const [tooltipAnchor, setTooltipAnchor] = useState(null);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const setVisibility = field => {
		setPasswordVisible(prevState => ({ ...prevState, [field]: !prevState[field] }));
	};

	const showTooltip = event => {
		if (event.type === 'click') {
			setTooltipAnchor(event.currentTarget);
			setIsTooltipOpen(true);
		} else {
			setIsTooltipOpen(null);
			setIsTooltipOpen(false);
		}
	};

	return (
		<>
			<div className="set-password-form">
				<div className="instructions">
                Please choose a password:
				</div>
				<div className="email-label">
					<TextFieldMUI
						label="Email"
						disabled={true}
						size="large"
						margin="normal"
						variant="outlined"
						fullWidth
						value={email}
					/>
				</div>
				<form className="form" onSubmit={handleSubmit}>
					<div style={{ width: '100%' }} id={'password-dummy'}>
						<Field
							component={TextField}
							label="Password"
							className="form-field"
							name="password"
							type={passwordVisibility.password ? 'text' : 'password'}
							validate={[required, isPasswordStrong]}
							onClick={showTooltip}
							onBlur={showTooltip}
							InputProps={{
								endAdornment:
                                <InputAdornment position="end">
                                	<IconButton
                                		aria-label="toggle password visibility"
                                		onClick={() => setVisibility('password')}
                                		edge="end"
                                	>
                                		{passwordVisibility.password ? <Visibility/> : <VisibilityOff/>}
                                	</IconButton>
                                </InputAdornment>,
							}}
						/>
					</div>

					<PasswordTooltip anchor={tooltipAnchor} isOpen={isTooltipOpen} passwordContent={getPasswordAttrs(password)}/>

					{password && password.length !== 0 && isTooltipOpen && <StrengthMeter password={password}/>}
					<Field
						component={TextField}
						label="Confirm Password"
						className="form-field"
						name="passwordConfirm"
						type={passwordVisibility.confirm ? 'text' : 'password'}
						strengthMeter={false}
						validate={[required, passwordsMatch, passwordMinLength]}
						requiredIndicator={true}
						InputProps={{
							endAdornment:
                            <InputAdornment position="end">
                            	<IconButton
                            		aria-label="toggle password visibility"
                            		onClick={() => setVisibility('confirm')}
                            		edge="end"
                            	>
                            		{passwordVisibility.confirm ? <Visibility/> : <VisibilityOff/>}
                            	</IconButton>
                            </InputAdornment>,
						}}
					/>
					{error && <div className="form-error">{error}</div>}
					<SubmitButton label="Set Password"
						isDisabled={invalid}
						className="form-submit"
					/>
				</form>
			</div>
			<Typography variant="caption" display="block" color={'textSecondary'} style={{ fontStyle: 'oblique', marginTop: '100px' }}>
                We use cookies for a number of purposes, some are strictly necessary for the functioning of the website.
                In addition, We may use third party analytics tools (such as <a style={{ color: '#7b8898', textDecoration: 'underline' }} href="https://policies.google.com/technologies/partner-sites">Google Analytics</a>) to collect information about your use of the website. <br/>
                By clicking “Set Password” and registering, you agree to the use of cookies.
                You can learn more and opt-out by clicking on our <a style={{ color: '#7b8898', textDecoration: 'underline' }} href="https://dd82a457-3aac-41ba-968f-131c270692e5.usrfiles.com/ugd/dd82a4_48b605c415ea4af1840061e816212600.pdf">privacy policy</a>.
			</Typography>
		</>

	);
};

SetPasswordForm = reduxForm({
	form: 'set-password-form',
})(SetPasswordForm);

const selector = formValueSelector('set-password-form');
SetPasswordForm = connect(state => {
	const password = selector(state, 'password');
	return {
		password,
	};
})(SetPasswordForm);
export default SetPasswordForm;
