import React from "react";
import {Typography} from "@material-ui/core";
import {useStyles} from "./welcome.css";
import StyleButton from "../form/Buttons/StyleButton";

const Welcome = ({serviceName, serviceDescription, imageSrc, moveToNextPhase}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <img src={imageSrc} className={classes.image}/>
            <Typography align={"center"} className={classes.text}>
                Welcome to <Typography className={classes.serviceName} variant={"span"}>{serviceName}</Typography> - <br/>
                {serviceDescription}
            </Typography>
            <StyleButton onClick={moveToNextPhase} label="Show me around" className={classes.button}/>
        </div>
    )
}

export default Welcome;