import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	dialogPaperProps: {
		width: '700px',
		maxWidth: '680px',
		height: '570px',
		padding: '2%',
	},
	actionArea: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row-reverse',
	},
	goToOverviewButton: {
		margin: 'auto',
		marginTop: '4%',
		display: 'block',
	},
	tipsSubtitle: {
		color: '#48648B',
		textAlign: 'center',
	},
	tips: {
		textAlign: 'left',
		fontSize: '16px',
		color: '#48648b !important',
		quotes: '"“" "”" "‘" "’"',
		display: 'flex',
		fontWeight: 400,
	},
	contactUs: {
		marginTop: '5%',
		color: '#48648B',
	},
	contactUsCloud: {
		marginTop: '5%',
		color: '#48648B',
		fontSize: '14px',
	},
	tipsAwsCloudDevice: {
		lineHeight: '105%',
		marginTop: '2%',
		textAlign: 'left',
		fontSize: '14px',
		color: '#48648b !important',
		quotes: '"“" "”" "‘" "’"',
		display: 'flex',
	},
	indicateSubTitle: {
		textAlign: 'center',
		fontSize: '16px',
		color: '#48648B',
	},
	indicateTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '22px',
		color: '#48648b',
	},
	indicateSuccessTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '22px',
		color: '#48648b',
		marginBottom: '4%',
		marginTop: '2%',
	},
	indicateAwsCloudTitle: {
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '20px',
	},
	indicateImage: {
		marginLeft: 'auto',
		marginRight: 'auto',
		width: '230px',
		height: '160px',
		display: 'block',
	},
	indicatePaper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '80%',
		justifyContent: 'space-between',
	},
	awsIndicatePaper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		gap: '15px',
	},
	successMessagePaper: {
		margin: 'auto',
	},
	actionIcon: {
		height: '20px',
		width: '20px',
	},
	loadingImage: {
		width: '200px',
		height: '96px',
		display: 'block',
		margin: 'auto',
	},
	loadingSubTitle: {
		marginTop: '10%',
		textAlign: 'center',
	},
}));

export { useStyles };
