import {
	LOADING_GRAPH,
	SELECTED_ALERT_EDITION,
	SELECTED_FILTER,
	REQ_ALERTS_SUCCESS,
	LOADING_GIVEN_GRAPH,
	REQ_ALERTS,
	UPDATE_GIVEN_GRAPH,
	ALERT_FAIL,
	CLEAR_ALERT_ERROR,
} from './actionTypes';

import action from '../../../app/controller/redux/middleware';

import { createAlert, getStatistics, getAlerts, updateAlert as updateAlertApi, deleteAlert as deleteAlertApi, toogleAlert } from '../services/requests';
import { findAlertInStore } from '../businessLogic/finder';
import { mapAlerts } from '../businessLogic/mappers';
import { createFromDateToDate } from '../businessLogic/utils';
import { filterEnabledAlerts } from '../businessLogic/filters';
import { ALERT_VERSION } from '../businessLogic/create-version';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { SNACKBAR_HIDE_DURATION } from '../../../backgroundProcess/view/visibilityView/rawdataExport/constants';

const ALERT_CREATION_ERROR_MESSAGE = 'Alert creation failed, try again';
const ALERT_UPDATE_ERROR_MESSAGE = 'Alert failed to update, try again';

const setAlertPopOver = payload => ({ type: 'SET_ALERT_POP_OVER', payload });

const getAlertesGraph = () => action(async (dispatch, getState) => {
	dispatch(getAlertes());
	const alertManagement = getState().alertes;
	const { selectedFilter, alertes } = alertManagement;
	const { value, timeUnit } = selectedFilter;

	const { from, to } = createFromDateToDate(value, timeUnit);

	dispatch({ type: LOADING_GRAPH, payload: true });

	const enabledAlerts = filterEnabledAlerts(alertes);
	for (const alert of enabledAlerts) {
		const graphElement = await getAlerteStatistic(alert.Id, from, to);
		dispatch(updateGivenGraph(alert.Id, graphElement));
	}
});

const updateLoadingStatusGivenGraph = (id, status) => action(async (dispatch, getState) => {
	const IndexGivenGraph = findAlertInStore(getState(), id);
	dispatch({ type: LOADING_GIVEN_GRAPH, payload: { index: IndexGivenGraph, status } });
});

const updateGivenGraph = (Id, statistics) => action(async (dispatch, getState) => {
	const IndexGivenGraph = findAlertInStore(getState(), Id);
	dispatch({ type: UPDATE_GIVEN_GRAPH, payload: { index: IndexGivenGraph, statistics } });
});

const updateAlertStatistic = (value, unit, title) => action(async dispatch => {
	dispatch({ type: SELECTED_FILTER, payload: { value, unit, title } });
});

const updateAlert = (alerteId, payload) => action(async (dispatch, getState) => {
	try {
		await updateAlertApi(alerteId, payload);
	} catch (e) {
		dispatch(openSnackbar('error', ALERT_UPDATE_ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
	}

	const alertManagement = getState().alertes;
	const { selectedFilter } = alertManagement;
	const { value, timeUnit } = selectedFilter;
	const { from, to } = createFromDateToDate(value, timeUnit);
	const graphElement = await getAlerteStatistic(alerteId, from, to);
	dispatch(updateGivenGraph(alerteId, graphElement));

	const alerts = await getAlerts();
	dispatch(alerts);
});

const updateAlertToogle = (alertId, enabled) => action(async (dispatch, getState) => {
	toogleAlert(alertId, enabled);
	const alertManagement = getState().alertes;
	const { selectedFilter } = alertManagement;
	const { value, timeUnit } = selectedFilter;
	const { from, to } = createFromDateToDate(value, timeUnit);
	const graphElement = await getAlerteStatistic(alertId, from, to);

	dispatch(updateGivenGraph(alertId, graphElement));

	dispatch(getAlerts());
});

const deleteAlert = alerteId => action(async dispatch => {
	await deleteAlertApi(alerteId);
	dispatch(getAlerts());
});

const createAlerts = payload => action(async dispatch => {
	let result;
	try {
		dispatch({ type: CLEAR_ALERT_ERROR });
		payload.version = ALERT_VERSION;
		result = await createAlert(payload); // DONE
	} catch (e) {
		dispatch({
			type: ALERT_FAIL,
			payload: {
				error: e.toString(),
			},
		});
		dispatch(openSnackbar('error', ALERT_CREATION_ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
	}

	const currentData = JSON.parse(result.data);
	const alerts = await getAlerts();
	dispatch(alerts);
	const parsedObject = Object.assign(result, {
		data: {
			originalQuery: JSON.parse(currentData.originalQuery),
			queryPayload: JSON.parse(currentData.queryPayload),
			thresholdType: payload.thresholdType,
			thresholdValue: payload.thresholdValue,
		},
	});

	return parsedObject;
});

const getAlertes = () => action(async dispatch => {
	dispatch({ type: REQ_ALERTS });
	const result = await getAlerts();
	dispatch({ type: REQ_ALERTS_SUCCESS, payload: { alertes: mapAlerts(result) } });
});

const getAlerteStatistic = async (alertPolicyId, startDate, endDate) => {
	const result = await getStatistics(alertPolicyId, startDate, endDate);
	return { ...result, alertPolicyId, startDate };
};

const updateSelectedAlertEdition = selectedItem => action((dispatch, getState) => {
	dispatch({
		type: SELECTED_ALERT_EDITION,
		payload: {
			selectedAlertEdition: selectedItem,
		},
	});
});

export {
	getAlertes,
	getAlerteStatistic,
	createAlerts,
	updateAlert,
	updateAlertToogle,
	deleteAlert,
	updateSelectedAlertEdition,
	updateAlertStatistic,
	getAlertesGraph,
	updateGivenGraph,
	updateLoadingStatusGivenGraph,
	setAlertPopOver,
};
