export const EXTRA_HEIGHT_SIZE_PER_COLUMN = 40;
export const RERENDER_GRAPH_INTERVAL_MS = 300;
export const IS_RESIZE_ANIMATION_ENABLE = true;
const DEFAULT_CHART_HEIGHT = '20%';

// Default graph options
const DEFAULT_GRAPH_OPTIONS = {
	chart: {
		type: 'tilemap',
		inverted: true,
		animation: false,
	},
	ScrollablePlotArea: {
		minHeight: '200px',
	},
	title: {
		text: '',
	},
	subtitle: {
		text: '',
	},
	xAxis: {
		visible: false,
	},
	yAxis: {
		visible: false,
	},
	legend: {
		enabled: true,
		align: 'right',
		verticalAlign: 'top',
		layout: 'vertical',
		title: {
			text: 'Health Status',
		},
		y: 10,
	},
	plotOptions: {
		tilemap: {
			cursor: 'pointer',
			showInLegend: true,
		},
	},
	series: [{}],
	credits: {
		enabled: false,
	},
};

/**
 * Graph Options Configuration
 *
 * This configuration provides default options for a tilemap chart using Highcharts.
 * It supports customization through the 'overrides' parameter.
 *
 * @param {string} chartHeight - The height of the chart.
 * @param {Object} overrides - Custom overrides for the default configuration.
 * @returns {Object} - Merged configuration object.
 */
export const graphOptions = (chartHeight = DEFAULT_CHART_HEIGHT, overrides = {}) => ({
	...DEFAULT_GRAPH_OPTIONS,
	chart: {
		...DEFAULT_GRAPH_OPTIONS.chart,
		height: chartHeight,
	},
	...overrides,
});
