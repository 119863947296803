import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useStyles from './supportTicketDialog.css';
import Typography from '@material-ui/core/Typography';
import supportDialogImage from '../../../assets/accountMenu/supportDialogImage.svg';
import ExitButton from '../../../shared/components/ExitButton/ExitButton';
import { theme } from '../../../helpers/muiTheme';
import { Link } from '@material-ui/core';
import PropTypes from 'prop-types';

const SupportTicketDialog = ({ isOpen = false, onClose }) => {
	const classes = useStyles();

	return (
		<Dialog classes={{ paperWidthMd: classes.root }} maxWidth={'md'} open={isOpen}>
			<ExitButton onClick={onClose} color={theme.palette.inputText.main} />
			<div className={classes.root}>
				<img src={supportDialogImage} className={classes.image} />
				<Typography className={classes.title}>
                    Support ticket
				</Typography>
				<Typography className={classes.text}>
                    Please let us know what went wrong or what you need help with by emailing Cynamics at <Link
						href="mailto:support@cynamics.ai"
					>
						<Typography
							className={classes.mail}>
                            support@cynamics.ai

						</Typography>
					</Link>
				</Typography>
			</div>
		</Dialog>
	);
};

SupportTicketDialog.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

export default SupportTicketDialog;
