import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		alignContent: 'center',
		padding: '0!important',
		margin: '2px!important',
	},
	included: {
		backgroundColor: `${theme.palette.selectCreatableTags.include}!important`,
	},
	excluded: {
		backgroundColor: `${theme.palette.selectCreatableTags.exclude}!important`,
	},
	chipContainer: {
		display: 'flex',
		flexDirection: 'row',
	},
	directionButton: {
		cursor: 'pointer!important',
	},
	label: {
		cursor: 'pointer',
	},
}));

export default useStyles;
