import { useEffect, useRef } from 'react';
import {
	CHART_TOOLTIP_PADDING_END,
	CHART_TOOLTIP_PADDING_START,
	CIRCLE_RADIUS,
	CLICK_PADDING_RADIUS, GRAPH_BIG_CIRCLE_COLOR, GRAPH_POINT_COLOR,
	HOVER_PADDING_RADIUS, OVER_HALO_COLOR,
	OVER_THRESHOLD_COLOR,
	RING_PADDING_COLOR,
	RING_RADIUS,
	STARTING_CIRCLE_OPACITY_ANIMATION, UNDER_HALO_COLOR,
	UNDER_THRESHOLD_COLOR,
} from './config';
import PropTypes from 'prop-types';

let lastClickedPoint = null;
let lastClickedCircle = null;
let lastHoveredCircle = null;
const useStockGraph = ({ handleChartMouseLeave, onPointSelect, isLoading, threshold }) => {
	const chartRef = useRef(null);

	useEffect(() => {
		if (isLoading === true) {
			chartRef.current.chart.showLoading('Loading data...');
		} else {
			chartRef.current.chart.hideLoading();
		}
	}, [isLoading]);

	const removeLastHoveredCircle = () => {
		if (lastHoveredCircle) {
			lastHoveredCircle.paddingCircle.destroy();
			lastHoveredCircle.bigCircle.destroy();
			lastHoveredCircle.smallCircle.destroy();
		}
	};

	const removeLastClickedCircle = () => {
		lastClickedPoint = null;
		if (lastClickedCircle) {
			lastClickedCircle.bigCircle.fadeOut();
			lastClickedCircle.paddingCircle.fadeOut();
			lastClickedCircle.smallCircle.fadeOut();
		}
	};

	const setPosition = (width, height, point) => {
		const tooltipX = Math.min(
			chartRef.current.chart.plotWidth - CHART_TOOLTIP_PADDING_END,
			Math.max(point.plotX + chartRef.current.chart.plotLeft - (width / 2), CHART_TOOLTIP_PADDING_START),
		);

		return {
			x: tooltipX,
			y: 0,
		};
	};

	const paintCircle = (point, onCLick, paddingRadius, overRingColor, underRingColor, animationTime) => {
		const x = point.plotX + chartRef.current.chart.plotLeft;
		const y = point.plotY + chartRef.current.chart.plotTop;
		let fillBigCircleColor = GRAPH_BIG_CIRCLE_COLOR;
		let fillSmallCircleColor = GRAPH_POINT_COLOR;
		if (threshold) {
			fillBigCircleColor = point.y > threshold ? overRingColor : underRingColor;
			fillSmallCircleColor = point.y > threshold ? OVER_THRESHOLD_COLOR : UNDER_THRESHOLD_COLOR;
		}

		const bigCircle = chartRef.current.chart.renderer.circle(
			x,
			y,
			RING_RADIUS,
		).attr({
			fill: fillBigCircleColor,
			zIndex: 5,
			opacity: STARTING_CIRCLE_OPACITY_ANIMATION, // Start with zero opacity
		}).on('click', onCLick)
			.add()
			.animate({ opacity: 1 }, animationTime); // Animation to increase opacity over 500 milliseconds

		const paddingCircle = chartRef.current.chart.renderer.circle(
			x,
			y,
			paddingRadius,
		).attr({
			fill: RING_PADDING_COLOR,
			zIndex: 5,
			opacity: STARTING_CIRCLE_OPACITY_ANIMATION, // Start with zero opacity
		}).on('click', onCLick)
			.add()
			.animate({ opacity: 1 }, animationTime); // Animation to increase opacity over 500 milliseconds

		const smallCircle = chartRef.current.chart.renderer.circle(
			x,
			y,
			CIRCLE_RADIUS,
		).attr({
			fill: fillSmallCircleColor,
			zIndex: 5,
			opacity: STARTING_CIRCLE_OPACITY_ANIMATION, // Start with zero opacity

		}).on('click', onCLick)
			.add()
			.animate({ opacity: 1 }, animationTime); // Animation to increase opacity over 500 milliseconds

		return {
			bigCircle,
			paddingCircle,
			smallCircle,
		};
	};

	const onClickPoint = point => {
		if (lastClickedPoint === point) {
			removeLastClickedCircle();
			onPointSelect(null);
			return;
		}

		if (lastClickedPoint) {
			removeLastClickedCircle();
		}

		const onClickPointEvent = () => onClickPoint(point);
		lastClickedCircle = paintCircle(point, onClickPointEvent, CLICK_PADDING_RADIUS, OVER_THRESHOLD_COLOR, UNDER_THRESHOLD_COLOR, 500);
		lastClickedPoint = point;

		onPointSelect(point);
	};

	const onMouseHover = e => {
		removeLastHoveredCircle();

		const onClickPointEvent = onPointSelect ? () => onClickPoint(e.target) : null;
		lastHoveredCircle = paintCircle(e.target, onClickPointEvent, HOVER_PADDING_RADIUS, OVER_HALO_COLOR, UNDER_HALO_COLOR, 0);
	};

	const onChartMouseLeave = () => {
		removeLastHoveredCircle();
		if (handleChartMouseLeave) {
			handleChartMouseLeave();
		}
	};

	return {
		...{
			removeLastHoveredCircle,
			removeLastClickedCircle,
			onChartMouseLeave,
			setPosition,
			onMouseHover,
			chartRef,
		},
		...(onPointSelect ? { onPointSelect: onClickPoint } : {}),
	};
};

useStockGraph.propTypes = {
	handleChartMouseLeave: PropTypes.func,
	onPointSelect: PropTypes.func,
	threshold: PropTypes.number,
};

useStockGraph.defaultProps = {
	handleChartMouseLeave() {},
	threshold: null,
};

export default useStockGraph;
