import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { useStyles } from './tableHead.css';

const tableHead = ({ order, orderBy, onRequestSort, columns }) => {
	const classes = useStyles();

	const createSortHandler = property => event => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={classes.head}>
			<TableRow>
				{columns.map(headCell => (
					<TableCell
						key={`HEADER-${headCell.label}`}
						sortDirection={headCell.sort && orderBy === headCell.sortParam ? order : false}
						className={classes.tableHeadCell}
					>
						{headCell.sort ? <TableSortLabel
							active={orderBy === headCell.sortParam}
							direction={orderBy === headCell.sortParam ? order : 'asc'}
							onClick={createSortHandler(headCell.sortParam)}
							classes={{ root: classes.headerTextColor, active: classes.active }}
							hideSortIcon={false}
						>
							{headCell.label}
						</TableSortLabel> : <span className={classes.headerTextColor}>{headCell.label}</span>}
					</TableCell>
				))}
				<TableCell />
			</TableRow>
		</TableHead>
	);
};

tableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
};

export default tableHead;
