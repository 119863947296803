export const HIGHLIGHTED_LABEL = 'Highlighted';
export const ALL_LABEL = 'All';

export const LAST_48_HOURS_LABEL = 'Last 48 hours';
export const LAST_48_HOURS_VALUE = 2;
export const LAST_7_DAYS_LABEL = 'Last 7 days';
export const LAST_7_DAYS_VALUE = 7;
export const LAST_14_DAYS_LABEL = 'Last 14 days';
export const LAST_14_DAYS_VALUE = 14;
export const LAST_30_DAYS_LABEL = 'Last 30 days';
export const LAST_30_DAYS_VALUE = 30;
export const LAST_60_DAYS_LABEL = 'Last 60 days';
export const LAST_60_DAYS_VALUE = 60;
export const CUSTOM_RANGE_LABEL = 'Custom range';
