import React from 'react'
import InformationBox from "../base/InformationBox";
import successHeader from '../../../../assets/notifications/all-good.svg';
import PropTypes from "prop-types";


const propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    callback: PropTypes.func,
    outlined: PropTypes.bool,
}

const defaultProps = {
    buttonText: "finish",
}

function SuccessIndication({header, description, buttonText, outlined, callback}){

    return (
        <InformationBox header={header} headerImg={successHeader} buttonText={buttonText} description={description} outlined={outlined} callback={callback} />
    )
}

SuccessIndication.propTypes = propTypes;
SuccessIndication.defaultProps = defaultProps;
export default SuccessIndication;