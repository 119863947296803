import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {Paper, Typography} from "@material-ui/core";
import {useStyles} from "./InformationBox.css";

const propTypes = {
    headerImg: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    buttonColor: PropTypes.oneOf(["primary", "error"]),
    callback: PropTypes.func,
    elevation: PropTypes.number,
    outlined: PropTypes.bool,
}

const defaultProps = {
    elevation: 0,
    buttonColor: "primary"
}

function InformationBox({headerImg, outlined, elevation, header, description, buttonText, buttonColor, callback}) {
    const variant = outlined? "outlined" : '';

    useEffect(() => {

    }, [])

    const onClick = () => {
        callback();
    }

    const classes = useStyles({headerImg, buttonColor});
    return (
        <Paper className={classes.box} variant={variant} elevation={elevation}>
            <div className={classes.headerImg} />
            <div className={classes.boxItem}>
                <Typography className={classes.header} gutterBottom align={"center"} variant={"h1"}>{header}</Typography>
                <Typography className={classes.description} align={"center"} variant={"body2"} color={"textSecondary"}>{description}</Typography>
            </div>
            {callback && <div className={classes.boxItem}>
                <Button className={classes.button} onClick={onClick}>{buttonText}</Button>
            </div>}
        </Paper>
    )


}

InformationBox.propTypes = propTypes;
InformationBox.defaultProps = defaultProps;
export default InformationBox;