import ErrorIndication from "../../../../../../shared/components/IndicationComponent/types/ErrorIndication";
import SuccessIndication from "../../../../../../shared/components/IndicationComponent/types/SuccessIndication";
import {ReactComponent as CreditCardIcon} from "./creditCardIcon.svg";
import {ReactComponent as WireTransferIcon} from "./wireTransferIcon.svg";
import {ReactComponent as PurchaseOrderIcon} from "./purchaseOrderIcon.svg";
import {handleContactSalesSubmitForm, handleCreditCardSubmitForm} from "./submitFormsFunctions";
import React from "react";
import PaymentMethod from "../../../../../paymentMethod/creditCard/paymentMethod";
import PurchaseOrderWireTransfer
    from "../../../../../paymentMethod/purchaseOrderWireTransfer/purchaseOrderWireTransfer";

/// Credit Card Configuration
const CREDIT_CARD_PAYMENT_TYPE_VALUE = "card";
const CREDIT_CARD_PAYMENT_TYPE_LABEL = "Credit Card";
const CreditCardErrorComponent = (callback) => <ErrorIndication header={"Something went wrong"}
                                                                callback={callback}
                                                                description={"Unfortunately, your order was not processed"}
                                                                buttonText={"Try again"}/>
const CreditCardSuccessComponent = (callback) => <SuccessIndication header={"Congrats, your order is confirmed!"}
                                                                    callback={callback}
                                                                    description={"Thank you for your purchase, your new plan will be applied in a few moments"}
                                                                    buttonText={"Finish"}/>
const CreditCardFormComponent = PaymentMethod;

// Purchase Order and Wire Transfer
const PURCHASE_ORDER_PAYMENT_TYPE_VALUE = "PURCHASE_ORDER";
const PURCHASE_ORDER_PAYMENT_TYPE_LABEL = "Purchase Order";

const WIRE_TRANSFER_PAYMENT_TYPE_VALUE = "WIRE_TRANSFER";
const WIRE_TRANSFER_PAYMENT_TYPE_LABEL = "Wire Transfer";

const WireTransferPurchaseOrderSuccessComponent = (callback) => <SuccessIndication header={"Congrats, your form has been submitted!"}
                                                                    callback={callback}
                                                                    description={"A member of our sales team will be in contact with you shortly"}
                                                                    buttonText={"Finish"}/>

// Payment Menu Items
const paymentMethodsMenuItems = [
    {
        label: CREDIT_CARD_PAYMENT_TYPE_LABEL,
        value: CREDIT_CARD_PAYMENT_TYPE_VALUE,
        icon: CreditCardIcon
    },
    {
        label: PURCHASE_ORDER_PAYMENT_TYPE_LABEL,
        value: PURCHASE_ORDER_PAYMENT_TYPE_VALUE,
        icon: PurchaseOrderIcon
    },
    {
        label: WIRE_TRANSFER_PAYMENT_TYPE_LABEL,
        value: WIRE_TRANSFER_PAYMENT_TYPE_VALUE,
        icon: WireTransferIcon
    }
]

const paymentTypesMapping = {
    [CREDIT_CARD_PAYMENT_TYPE_VALUE]:
        {
            formComponent: CreditCardFormComponent,
            handleSubmitForm: handleCreditCardSubmitForm,
            errorComponent: CreditCardErrorComponent,
            successComponent: CreditCardSuccessComponent,
            paymentMethodsMenuItems: paymentMethodsMenuItems,
            displayText: 'credit card'
        },
        [WIRE_TRANSFER_PAYMENT_TYPE_VALUE]:
        {
            formComponent: PurchaseOrderWireTransfer,
            handleSubmitForm: handleContactSalesSubmitForm,
            errorComponent: CreditCardErrorComponent,
            successComponent: WireTransferPurchaseOrderSuccessComponent,
            paymentMethodsMenuItems: paymentMethodsMenuItems,
            displayText: 'wire transfer'
        },
        [PURCHASE_ORDER_PAYMENT_TYPE_VALUE]:
        {
            formComponent: PurchaseOrderWireTransfer,
            handleSubmitForm: handleContactSalesSubmitForm,
            errorComponent: CreditCardErrorComponent,
            successComponent: WireTransferPurchaseOrderSuccessComponent,
            paymentMethodsMenuItems: paymentMethodsMenuItems,
            displayText: 'purchase order'
        }
};

export {
    paymentMethodsMenuItems,
    paymentTypesMapping,
    CREDIT_CARD_PAYMENT_TYPE_VALUE,
    CREDIT_CARD_PAYMENT_TYPE_LABEL
}