import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import { MAX_ROW_IN_TABLE } from '../../app/view/tableConfig/const';
import useStyles from './rawdata.css';
import { Box, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { ReactComponent as ExportIcon } from '../../../assets/export/export-blue.svg';
import { TABLE_COLUMNS } from './utils';
import ErrorMessage from '../../../shared/components/ErrorMessage/ErrorMessage';
import { exportRawdata } from '../services/vcaRequests';
import { downloadFromLink } from '../../../helpers/utils';
import { openSnackbar } from '../../snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';

const columns = TABLE_COLUMNS.map(columnObject => columnObject.column);

const ERROR_TITLE = 'Something went wrong';
const ERROR_MESSAGE = 'Error loading \'Raw data\' tab. Please try again.';
const PREPARING_CSV_DOWNLOAD_MESSAGE = 'Preparing CSV for download. Please try again in a few minutes';
const PREPARING_CSV_MAIL_MESSAGE = 'A CSV of the full data will be sent to you via email in a few minutes';
const CSV_ERROR_MESSAGE = 'Unable to export full data. Please contact support for further assistance';

const Rawdata = ({ eventData, isLoading }) => {
	const classes = useStyles();
	const [rows, setRows] = useState([]);
	const [isSubmittingForm, setSubmittingForm] = useState(false);
	const [isErrorInExport, setErrorInExport] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		if (eventData) {
			const { rawdata } = eventData;
			if (rawdata) {
				setRows(rawdata.sort((a, b) => b.rank - a.rank).map(row => (
					TABLE_COLUMNS.map(col => ({
						Component: col.component || Typography,
						align: 'left',
						value: col.formatValue ? col.formatValue(
							col.value ? row[col.value] : row,
							eventData.endpoints,
						) : row[col.value],
					}),
					)
				)));
			}
		}
	}, [eventData]);
	const exportData = async () => {
		const timeout = setTimeout(() => {
			setSubmittingForm(true);
		}, 1000);

		try {
			const { link, sendMail } = await exportRawdata(eventData.eventId);
			if (link && link.length) {
				downloadFromLink(link);
			} else {
				const snackbarMsg = sendMail ? PREPARING_CSV_MAIL_MESSAGE : PREPARING_CSV_DOWNLOAD_MESSAGE;
				dispatch(openSnackbar('info', snackbarMsg));
			}
		} catch {
			setErrorInExport(true);
			showErrorMessage();
		} finally {
			clearTimeout(timeout);
			setSubmittingForm(false);
		}
	};

	const showErrorMessage = () => {
		dispatch(openSnackbar('error', CSV_ERROR_MESSAGE));
	};

	if (isLoading || !rows) {
		return <SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} />;
	}

	if (!rows || !rows.length) {
		return <Box className={classes.messageWrapper}><ErrorMessage title={ERROR_TITLE} message={ERROR_MESSAGE}/></Box>;
	}

	return (
		<Grid>
			<Grid item xs={12} className={classes.rawdataButton}>
				{isSubmittingForm ? (
					<CircularProgress color="primary" size={20} className={classes.loader} />
				) : (
					<Button variant="text" onClick={ isErrorInExport ? showErrorMessage : exportData }>
						<ExportIcon className={classes.icon}/><Typography className={classes.label}>Export full data</Typography>
					</Button>
				)}
			</Grid>
			<TableContainer >
				<Table aria-label="Extra data" className={classes.root}>
					<TableHead className={classes.head}>
						<TableRow>
							{columns.map(col =>
								<TableCell
									className={classes.headerTextColor}
									key={`HEADER-${col}`}>{col}
								</TableCell>)}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map(row => (
							<TableRow key={row.name} className={classes.tableRow}>
								{row.map((cell, index) =>
									<TableCell key={index} align={cell.align} component="th" scope="row">
										<cell.Component >{cell.value}</cell.Component>
									</TableCell>)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Grid>
	);
};

Rawdata.propTypes = {
	eventData: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
};

export default Rawdata;
