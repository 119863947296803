import utils from '../../../../../services/util';

const COLOR_PERMUTATIONS = {
	2: ['#003F5C', '#FFA600'],
	3: ['#003F5C', '#BC5090', '#FFA600'],
	4: ['#003F5C', '#7A5195', '#BC5090', '#FFA600'],
	5: ['#003F5C', '#7A5195', '#BC5090', '#FF764A', '#FFA600'],
	6: ['#003F5C', '#7A5195', '#BC5090', '#EF5675', '#FF764A', '#FFA600'],
};

const DEFAULT_COLORS = COLOR_PERMUTATIONS[6];

// Constant for tooltip style
const TOOLTIP_STYLE = `
  color: #425262;
  background-color: #ffffff;
  padding: 4px 6px 4px 6px;
  box-shadow: -1px 1px 7px 1px rgba(133, 133, 133, 0.25);
  height: 24px;
  border-radius: 5px;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 16px;
`;

const SUFFIX = 'b';

export const generateColors = count => COLOR_PERMUTATIONS[count] || DEFAULT_COLORS;

export const config = {
	chart: {
		type: 'pie',
	},
	title: {
		text: '',
	},
	subtitle: {
		text: '',
	},
	accessibility: {
		announceNewData: {
			enabled: true,
		},
		point: {
			valueSuffix: '%',
		},
	},
	plotOptions: {
		series: {
			borderRadius: 2,
		},
		pie: {
			allowPointSelect: false,
			cursor: 'pointer',
			colors: ['#003F5C'],
		},
	},
	series: [
		{
			name: 'Ports',
			innerSize: '60%',
			colorByPoint: true,
			dataLabels: {
				connectorShape: 'crookedLine', // Add connectors for labels outside the pie
				connectorWidth: 2,
				connectorPadding: 5,
				softConnector: false,
				distance: 15, // Positive distance to place labels outside the pie
				formatter() {
					const suffix = SUFFIX;
					const { point } = this;
					const formattedValue = utils.convertNumber(point.volume, 2, false);

					return `<div style="font-weight: bold"> ${point.displayName} </div>  ${formattedValue}${suffix}`;
				},
				style: {
					fontWeight: '100',
				},
			},
			data: [],
		},
	],
	tooltip: {
		headerFormat: '',
		shadow: true,
		animation: true, // Enable tooltip animation
		formatter() {
			const suffix = SUFFIX;
			const formattedValue = utils.convertNumber(this.point.volume, 2, false);
			const { ports } = this.point;
			const portsList = ports && ports.length > 0 ? new Set(ports.map(item => item.port)) : [];
			const dot = `<span style="color:${this.color}; font-weight:bold;margin-right:4px">&#9679;</span>`;
			const title = `<span style="margin-right: 7px; font-weight: 600">${this.point.displayName}</span>`;
			const subTitle = `Ports: ${ports && [...portsList].map(port => `${port}`).join(',')}`;
			const valueString = `<span style="margin-right: 7px; font-weight: 400">Volume:</span> ${formattedValue}${suffix}`;

			return `<div style="${TOOLTIP_STYLE}">${dot}&nbsp;${title} <br/>&nbsp;&nbsp;&nbsp;&nbsp;${valueString}${ports ? `<br/>&nbsp;&nbsp;&nbsp;&nbsp;${subTitle}` : ''}</div>`;
		},

	},
	credits: {
		enabled: false,
	},
};
