import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	popoverAnchorElement: {
		display: 'flex',
		'&:hover': {
			color: theme.palette.primary.main,
			cursor: 'pointer',
		},
	},
}));

export { useStyles };
