const nonPublicIpRanges = [
	[0, 16777215],
	[1681915904, 1686110207],
	[3221225472, 3221225727],
	[167772160, 184549375],
	[2851995648, 2852061183],
	[2886729728, 2887778303],
	[3232235520, 3232301055],
	[2130706432, 2147483647],
	[4026531840, 4294967295],
	[3758096384, 4026531839],
];

function ipToint(ip) {
	return ip.split('.').reduce((ipInt, octet) => (ipInt << 8) + parseInt(octet, 10), 0) >>> 0;
}

export const isPublic = ip => {
	const ipInt = ipToint(ip);
	return !nonPublicIpRanges.filter(
		range => range[0] <= ipInt && ipInt <= range[1]).length;
};
