import React, { Component } from 'react';
import '../react-select.scss';
import _ from 'lodash';
function makeReduxSelectInterface(Select) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {}
    }
    render() {
      const { input, options, meta } = this.props;
      const { initial } = meta;
      return (
        <Select
          { ...input }
          onChange={ value => input.onChange(value) }
          onBlur={ (e) => input.onBlur(input.value) }
          options={ options }
          value={ input.value}
          placeholder={ this.props.placeholder }
          {...this.props}
        />
      )
    }
  }
}


export default makeReduxSelectInterface;