import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import CollectorPage from '../../../containers/deviceManagement/view/collector/collectorPage';
import { configDevicesManagement } from '../../../containers/deviceManagement/view/devicesManagementConfig';
import DevicesPage from '../../../containers/deviceManagement/view/devicesPage/devicesPage';
import TabPanel from '../tabPanel/tabPanel';
import { DeviceTab } from './style/DeviceTab';

const deviceManagementTabs = props => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const { features, collectors, samplers, deviceReferences, setCollectors, setSamplers, devices, createDevice, editDevice, deleteDevice, isLoading, addCollectorOnTheFly } = props;
	const containCollectorFeature = features.includes('collector_devices');

	return (
		<div >
			<div>
				<DeviceTab
					className="device-management-tabs-menu"
					onChange={handleChange}
					value={value}>
					<Tab className="device-management-tab" label={configDevicesManagement.tabDevicesName} value={0} />
					<Tab className="device-management-tab" label={configDevicesManagement.tabCollectorName} value={1} />
				</DeviceTab>
			</div>
			<div>
				<TabPanel value={value} index={0}>
					<DevicesPage
						collectors={collectors}
						samplers={samplers}
						devices={devices}
						createDevice={createDevice}
						editDevice={editDevice}
						deleteDevice={deleteDevice}
						deviceReferences={deviceReferences}
						isLoading={isLoading}
						addCollectorOnTheFly={addCollectorOnTheFly}
					/>
				</TabPanel>
				{containCollectorFeature ? <TabPanel value={value} index={1}>
					<CollectorPage
						collectors={collectors}
						samplers={samplers}
						setCollectors={setCollectors}
						setSamplers={setSamplers}
						deviceReferences={deviceReferences}
						isLoading={isLoading}
					/>
				</TabPanel> : null}
			</div>
		</div>
	);
};

deviceManagementTabs.propTypes = {
	features: PropTypes.array,
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	deviceReferences: PropTypes.object,
	setCollectors: PropTypes.func,
	setSamplers: PropTypes.func,
	devices: PropTypes.array,
	createDevice: PropTypes.func,
	editDevice: PropTypes.func,
	deleteDevice: PropTypes.func,
	isLoading: PropTypes.bool,
	addCollectorOnTheFly: PropTypes.func,
};

const mapStateToProps = state => ({
	features: state.account.features,
});

export default connect(mapStateToProps)(deviceManagementTabs);

