import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

import { Field, reduxForm } from 'redux-form';
import CloseIcon from "mdi-react/CloseIcon";
import './reasonform.scss';
import _ from 'lodash';
import { connect } from 'react-redux';
import SubmitButton from "../../../../../shared/components/form/Buttons/SubmitButton";


const reasonReference = {
    'FalsePositive': 'Thanks, feel free to add comments',
    'Known': 'Thanks, please tell us what it is so Cynamics AI will not alert on this in the future',
    'TruePositive': 'Thanks, feel free to add comments'
}
class ReasonForm extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChange = (event) => {
        this.setState({ description: event.target.value });
    }
    onSubmit = () => {
        const {  selectedReason, treath } = this.props;
        const { description } = this.state;
        this.props.onSubmit({ ...treath,  selectedReason, description })
    }
    render() {
        const { reason } = this.state;

        return (
            <div className="reason">
                <CloseIcon
                    className="close-button"
                    onClick={ this.props.closeReason } />
                <div className="reason-title">{ reasonReference[this.props.selectedReason] }</div>
                <TextField
                    type="text"
                    placeholder="Give us more details about what happened"
                    multiline
                    rows={ 4 }
                    onChange={ (newValue) => { this.onChange(newValue) } }
                />
                <SubmitButton
                    type="button"
                  //  isDisabled={ _.isEmpty(this.state.description) }
                    className="style-button table-add-button"
                    onClick={ this.onSubmit }
                    label={ 'Submit' } />

            </div>
        );
    }
}



const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(ReasonForm);


