import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './textWithIcon.css';

const TextWithIcon = ({ text, icon }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<img src={icon} alt="icon" className={classes.icon}/>
			{text}
		</div>
	);
};

TextWithIcon.propTypes = {
	text: PropTypes.string.isRequired,
	icon: PropTypes.node.isRequired,
};

export default TextWithIcon;
