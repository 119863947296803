import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
		height: '100%',
		maxHeight: '100%',
		padding: '1.5%',
	},
	tableWrapper: {
		marginRight: theme.spacing(0.8),
		marginLeft: theme.spacing(0.8),
		width: '50%',
		flexDirection: 'column',
		display: 'flex',
		height: '100%',
		maxHeight: '100%',
	},
	tableTitle: {
		fontSize: '16px !important',
		fontWeight: '600',
		height: '10%',
		maxHeight: '10%',
	},
	table: {
		display: 'flex',
		height: '90%',
		maxHeight: '90%',
		marginTop: theme.spacing(1),
	},
	tableRow: {
		position: 'relative',
		'&:hover': {
			backgroundColor: '#f5f5f5',
		},
	},
	noDataSingleTable: {
		color: '#5B6F87',
		textAlign: 'center',
		fontSize: '18px',
		fontWeight: '400',
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		border: '0.334px solid #DBDFE0',
	},
	tooltip: {
		maxWidth: '12vw',
		wordBreak: 'break-word',
	},
}));

export { useStyles };
