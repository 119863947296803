import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
	KEEP_EXISTING_NUMBER_STEP,
	SET_NEW_NUMBER_STEP,
} from '../stepsConfig';
import { mfaToggle } from '../../requests';
import { SMS_MFA_KEY } from '../../../userSettings/mfaTextsMapping';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';
import ConfigureSmsMfa from './ConfigureSmsMfa';
import ConfigureExistingPhoneMfa from './ConfigureExistingPhoneMfa';
import {updateUserInfo} from "../../../app/controller/redux/auth/authActions";
import {MFA_SETUP_FAILED_SETTLED, MFA_SETUP_SUCCESSFULLY_SETTLED} from "../stepsTexts";

export const mfaSteps = {
	[KEEP_EXISTING_NUMBER_STEP]: ConfigureExistingPhoneMfa,
	[SET_NEW_NUMBER_STEP]: ConfigureSmsMfa,
};
const SmsMfaMethod = ({ onSuccess, phoneNumber }) => {
	const dispatch = useDispatch();
	const [step, setStep] = useState(phoneNumber ? KEEP_EXISTING_NUMBER_STEP : SET_NEW_NUMBER_STEP);

	const handleSetNewPhone = () => {
		setStep(SET_NEW_NUMBER_STEP);
	};

	const configurePhoneAsMfa = async () => {
		try {
			await mfaToggle(SMS_MFA_KEY);
			const dataToUpdate = {
				mfa: SMS_MFA_KEY,
			};
			dispatch(updateUserInfo(dataToUpdate));
			dispatch(openSnackbar('success', MFA_SETUP_SUCCESSFULLY_SETTLED));
			await onSuccess();
		} catch (e) {
			dispatch(openSnackbar('error', MFA_SETUP_FAILED_SETTLED));
		}
	};

	const SelectedStepComponent = mfaSteps[step];
	return (
		<>
			<SelectedStepComponent
				configurePhoneAsMfa={configurePhoneAsMfa}
				phoneNumber={phoneNumber}
				handleSetNewPhone={handleSetNewPhone}
			/>
		</>
	);
};

SmsMfaMethod.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	phoneNumber: PropTypes.string.isRequired,
};

export default SmsMfaMethod;

