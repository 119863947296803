import React, { Component } from 'react';
import './mapHint.scss';
import _ from 'lodash'
import GeneralHint from './generalHint'
import { statusIconsCollector, statusIconsDevices } from '../statusIcons'

export default class MapHints extends Component {
    render() {
        const {device} = this.props;
        const isCollector = device.entityType === 'COLLECTOR';
        return device ? !isCollector ? <GeneralHint device={device} statusIcons={statusIconsCollector} /> // Collector
            : <GeneralHint device={device} statusIcons={statusIconsDevices} /> : null;
    }
};