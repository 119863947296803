import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RecordComponent from '../../recordComponent/recordComponent';

const TabWrapper = ({ children }) => {
	const [activePanelName, setActivePanelName] = useState();

	const handleChange = panel => (event, isExpanded) => {
		setActivePanelName(isExpanded ? panel : false);
	};

	return (
		<RecordComponent activePanelName={activePanelName} handleChange={handleChange}>
			{ children }
		</RecordComponent>
	);
};

TabWrapper.propTypes = {
	children: PropTypes.node.isRequired,
};
export default TabWrapper;
