import lowAlert from '../../assets/threats/grey-alert.svg';
import highAlert from '../../assets/threats/red-alert.svg';
import mediumAlert from '../../assets/threats/yellow-alert.svg';
import {
	dataPrep,
} from './services/tableDataPrep';
import { DETECTION_TYPES } from '../../shared/components/detectionsTable/detectionsTableConfig';
import vcaLogicConfig from './services/vcaLogicConfig.json';
import { VCA_RT_FEATURE_TOGGLE } from '../../constants/featureToggles';

const VCA_CONFIG = {
	pageTitle: 'Virtual Cyber Analyst',
	tabAllName: 'All',
	tabHighlightedName: 'Highlighted',
	defaultDateRange: 14,
};

const DEFAULT_PAGE_SIZE = 12;

const PAGES_TO_PRELOAD = 2;
// Feature toggle - fetch custom alerts only in dev environment
const VCA_DETECTIONS_TO_DISPLAY = VCA_RT_FEATURE_TOGGLE ? [DETECTION_TYPES.VCA, DETECTION_TYPES.VCA_RT] : [DETECTION_TYPES.VCA];
const DETECTIONS_TO_DISPLAY = process.env.REACT_APP_CYNAMICS_ENV === 'dev' ? [...VCA_DETECTIONS_TO_DISPLAY, DETECTION_TYPES.CUSTOM_ALERT] : VCA_DETECTIONS_TO_DISPLAY;
const SEVERITIES_TO_DISPLAY = ['L', 'M', 'H'];

const VCA_SEVERITIES_CONFIG = {
	default: { icon: '', label: '', min: -1, max: -1 },
	low: { icon: lowAlert, label: 'low', min: 0, max: 4 },
	medium: { icon: mediumAlert, label: 'medium', min: 4.1, max: 8.4 },
	high: { icon: highAlert, label: 'high', min: 8.5, max: 10 },
};

const TEXTS = {
	queryCreationFailed: 'Something went wrong. Try again',
	reachedMaxError: 'You can run up to 7 queries at the same time',
	querySuccess: 'Query from VCA successfully created',
};

const MAIN_TABLE_CONFIG = [
	{ sortParam: 'firstDetectionTime', label: 'Detection time', sort: true },
	{ sortParam: '', label: 'Top connections', sort: false },
	{ sortParam: '', label: 'Additional Data', sort: false },
	{ sortParam: 'logicKey', label: 'Logic Name', sort: true },
	{ sortParam: 'severity', label: 'Severity', sort: true },
	{ sortParam: '', label: 'Link to query', sort: false },
];

export const getLogicConfig = (tableData, logicKey, logicVersion, endpoints) => {
	const currentLogic = vcaLogicConfig.detectionLogicsDict[logicKey][logicVersion];
	return {
		title: currentLogic.title,
		columns: currentLogic.findingsTable.columns.map(column => column.title),
		dataPrep: dataPrep(tableData, currentLogic, endpoints),
		...currentLogic.dashboard,
	};
};

export const DEFAULT_LOGIC_VERSION = '1.0';

export { VCA_CONFIG, MAIN_TABLE_CONFIG, TEXTS, VCA_SEVERITIES_CONFIG, PAGES_TO_PRELOAD, DETECTIONS_TO_DISPLAY, DETECTION_TYPES, SEVERITIES_TO_DISPLAY, DEFAULT_PAGE_SIZE };
