import AWSForm from './awsForm';
import { AWS_CLOUD_PROVIDER_VALUE, GOOGLE_CLOUD_PROVIDER_VALUE } from '../publicCloudDeviceConfiguration';
import { REGIONS as AWS_REGIONS } from '../../../../cloudFilter/aws/awsConfiguration';
import GCPForm from './gcpForm';

// AWS Form
const AWS_DEFAULT_TYPE = 'vpc';
const awsInitialValues = {
	cloudProvider: AWS_CLOUD_PROVIDER_VALUE,
	type: AWS_DEFAULT_TYPE,
};

const awsInitialProps = {
	awsRegions: AWS_REGIONS,
};

// GCP Form
const GCP_DEFAULT_TYPE = 'vpc';
const gcpInitialValues = {
	cloudProvider: GOOGLE_CLOUD_PROVIDER_VALUE,
	type: GCP_DEFAULT_TYPE,
};

const gcpInitialProps = {
};

// Forms Mapping
const cloudProvidersFormMapping = {
	[AWS_CLOUD_PROVIDER_VALUE]: {
		formComponent: AWSForm,
		additionalProps: awsInitialProps,
		initialValues: awsInitialValues,
		addTitle: 'Add flow logs',
		editTitle: 'Edit flow logs',
		form: AWS_CLOUD_PROVIDER_VALUE,
	},
	[GOOGLE_CLOUD_PROVIDER_VALUE]: {
		formComponent: GCPForm,
		additionalProps: gcpInitialProps,
		initialValues: gcpInitialValues,
		addTitle: 'Add flow logs',
		editTitle: 'Edit flow logs',
		form: GOOGLE_CLOUD_PROVIDER_VALUE,
	},
};

export { cloudProvidersFormMapping };
