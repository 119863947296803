import React from 'react';
import PropTypes from "prop-types";
import {useStyles} from "./AnalysisIcon.css";
import {SvgIcon} from "@material-ui/core";

const propsType = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}
/*'#00B8EC'*/
const defaultProps = {
    color: '#48648B',
    hover: '#00B8EC',
    width: '40',
    height: '40',
    viewBox: '0 0 18 18'

}


function AnalysisIcon({color, hover, width, height, viewBox, onClick}) {
    const classes = useStyles({color, hover});

    return (
        <SvgIcon titleAccess={"View in Analysis"} classes={{root: classes.icon}} width={width} height={height} viewBox={viewBox}
                 onClick={onClick}>
            <path
                d="M7.59999955,0 C2.07272715,0 0,5.59999967 0,5.59999967 C0,5.59999967 2.07272715,11.1999993 7.59999955,11.1999993 C13.1272719,11.1999993 15.1999991,5.59999967 15.1999991,5.59999967 C15.1999991,5.59999967 13.1272719,0 7.59999955,0 Z M7.59999955,1.39999992 C11.2452357,1.39999992 13.0889914,4.38696554 13.6859369,5.59726547 C13.0883005,6.79916539 11.2307266,9.79999942 7.59999955,9.79999942 C3.9547634,9.79999942 2.11100779,6.81303379 1.51406238,5.60273387 C2.11238961,4.40083394 3.96927249,1.39999992 7.59999955,1.39999992 Z M7.59999955,2.79999983 C6.07378146,2.79999983 4.83636335,4.05369976 4.83636335,5.59999967 C4.83636335,7.14629957 6.07378146,8.3999995 7.59999955,8.3999995 C9.12621764,8.3999995 10.3636357,7.14629957 10.3636357,5.59999967 C10.3636357,4.05369976 9.12621764,2.79999983 7.59999955,2.79999983 Z M7.59999955,4.19999975 C8.36345405,4.19999975 8.98181765,4.82649971 8.98181765,5.59999967 C8.98181765,6.37349962 8.36345405,6.99999958 7.59999955,6.99999958 C6.83654505,6.99999958 6.21818145,6.37349962 6.21818145,5.59999967 C6.21818145,4.82649971 6.83654505,4.19999975 7.59999955,4.19999975 Z"
                id="visibility-off" />
        </SvgIcon>
    )
}


AnalysisIcon.prototype = propsType;
AnalysisIcon.defaultProps = defaultProps;
export default AnalysisIcon;