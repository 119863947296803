import React, { Component } from 'react';
import makeReduxSelectInterface from './maker/makeReduxSelectInterface';
import Select from 'react-select';
import validator from 'validator';

class SimpleValueSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { change, selectorStyles } = this.props;

		return (
			<Select
				options={ this.props.options }
				value={ this.props.value }
				onChange={ this.props.onChange }
				className={ this.props.className }
				isValidNewOption={ value => validator.isDecimal(value) }
				className="react-select-redux-forms react-select-underline react-select-creatable"
				classNamePrefix="react-select"
				styles={selectorStyles}

			/>
		);
	}
}

export default makeReduxSelectInterface(SimpleValueSelector);
