import _ from 'lodash';
import CloudReqBuilder from '../../containers/cloudFilter/requestBuilder';
import { EXCLUDE_SIGN, INCLUDE_SIGN, GROUP_TYPE } from '../components/queryLine/selectors/directionSelectCreatable/consts';
import {
	EXCLUDED_DST_VLAN_ID_KEY,
	EXCLUDED_SRC_VLAN_ID_KEY,
	EXCLUDED_VXLAN_ID_KEY, INCLUDED_DST_VLAN_ID_KEY,
	INCLUDED_SRC_VLAN_ID_KEY, INCLUDED_VXLAN_ID_KEY, VLAN_ID_BIDIRECTIONAL_TRAFFIC,
} from './keys';

const STRING_INPUT_TYPE = 'string';
const NUMBER_INPUT_TYPE = 'number';

const filterInputsByDirection = (inputs, direction, type = STRING_INPUT_TYPE) => {
	const results = [];
	if (!_.isEmpty(inputs)) {
		inputs.forEach(input => {
			if (input.direction === direction) {
				results.push(getInputValueByType(input, type));
			}
		});
	}

	return results;
};

// Default input value is string. If we want to parse to a number, pass number as type
const getInputValueByType = (input, type) => {
	if (type === NUMBER_INPUT_TYPE) {
		return parseInt(input.value);
	}

	return input.value;
};

const createSelectorInput = (payload, incInput, excInput) => {
	const incPayloadInputs = _.isEmpty(payload[incInput]) ? [] : payload[incInput];
	const excPayloadInputs = _.isEmpty(payload[excInput]) ? [] : payload[excInput];
	const incFormInputs = incPayloadInputs.map(input => ({
		value: input.toString(),
		label: input.toString(),
		direction: 'i',
	}));
	const excFormInputs = excPayloadInputs.map(input => ({
		value: input.toString(),
		label: input.toString(),
		direction: 'o',
	}));
	return incFormInputs.concat(excFormInputs);
};

const createGroupsSelectorInput = (payload, incInput, excInput) => {
	const incPayloadInputs = _.isEmpty(payload[incInput]) ? [] : payload[incInput];
	const excPayloadInputs = _.isEmpty(payload[excInput]) ? [] : payload[excInput];
	const incFormInputs = incPayloadInputs.map(input => ({
		value: input.value.toString(),
		label: input.label.toString(),
		direction: 'i',
		type: GROUP_TYPE,
	}));
	const excFormInputs = excPayloadInputs.map(input => ({
		value: input.value.toString(),
		label: input.label.toString(),
		direction: 'o',
		type: GROUP_TYPE,
	}));

	return incFormInputs.concat(excFormInputs);
};

const splitInputsByDirections = (inputs, shouldSaveLabel = false, type = STRING_INPUT_TYPE) => {
	const incResulsts = [];
	const excResults = [];
	if (!_.isEmpty(inputs)) {
		inputs.forEach(input => {
			const value = getInputValueByType(input, type);
			const currentInput = shouldSaveLabel ? { value, label: input.label } : value;
			if (input.direction === 'i') {
				incResulsts.push(currentInput);
			} else {
				excResults.push(currentInput);
			}
		});
	}

	return { incResulsts, excResults };
};

const createIncludeExcludeForField = (fieldData, fieldName) => {
	const field = {};
	if (!_.isEmpty(fieldData)) {
		const freeTextInputs = fieldData.filter(val => val.type !== GROUP_TYPE);
		const groupInputs = fieldData.filter(val => val.type === GROUP_TYPE);

		const { incResulsts: incText, excResults: excText } = splitInputsByDirections(freeTextInputs);
		const { incResulsts: incGroups, excResults: excGroups } = splitInputsByDirections(groupInputs, true);

		field[`inc${fieldName}`] = incText;
		field[`inc${fieldName}Groups`] = incGroups;

		field[`exc${fieldName}`] = excText;
		field[`exc${fieldName}Groups`] = excGroups;
	}

	return field;
};

const createEnrichmentPayload = formData => {
	const ipWhoIs = {};
	// Source
	ipWhoIs.incContinentSrc = filterInputsByDirection(formData.srcContinent, INCLUDE_SIGN);
	ipWhoIs.incCountryNameSrc = filterInputsByDirection(formData.srcCountryName, INCLUDE_SIGN);
	ipWhoIs.excContinentSrc = filterInputsByDirection(formData.srcContinent, EXCLUDE_SIGN);
	ipWhoIs.excCountryNameSrc = filterInputsByDirection(formData.srcCountryName, EXCLUDE_SIGN);

	Object.assign(ipWhoIs, createIncludeExcludeForField(formData.srcOrganization, 'OrgSrc'));
	Object.assign(ipWhoIs, createIncludeExcludeForField(formData.srcIsp, 'IspSrc'));
	Object.assign(ipWhoIs, createIncludeExcludeForField(formData.srcDns, 'DnsSrc'));

	// Destination
	ipWhoIs.incContinentDest = filterInputsByDirection(formData.destContinent, INCLUDE_SIGN);
	ipWhoIs.incCountryNameDest = filterInputsByDirection(formData.destCountryName, INCLUDE_SIGN);
	ipWhoIs.excContinentDest = filterInputsByDirection(formData.destContinent, EXCLUDE_SIGN);
	ipWhoIs.excCountryNameDest = filterInputsByDirection(formData.destCountryName, EXCLUDE_SIGN);

	Object.assign(ipWhoIs, createIncludeExcludeForField(formData.destOrganization, 'OrgDest'));
	Object.assign(ipWhoIs, createIncludeExcludeForField(formData.destIsp, 'IspDest'));
	Object.assign(ipWhoIs, createIncludeExcludeForField(formData.destDns, 'DnsDest'));
	return ipWhoIs;
};

const createEnrichmentInputsByPayload = payload => {
	const enrichmentInputs = {};
	enrichmentInputs.srcContinent = createSelectorInput(payload, 'incContinentSrc', 'excContinentSrc');
	enrichmentInputs.srcCountryName = createSelectorInput(payload, 'incCountryNameSrc', 'excCountryNameSrc');
	enrichmentInputs.srcOrganization = createSelectorInput(payload, 'incOrgSrc', 'excOrgSrc')
		.concat(createGroupsSelectorInput(payload, 'incOrgSrcGroups', 'excOrgSrcGroups'));

	enrichmentInputs.srcIsp = createSelectorInput(payload, 'incIspSrc', 'excIspSrc')
		.concat(createGroupsSelectorInput(payload, 'incIspSrcGroups', 'excIspSrcGroups'));

	enrichmentInputs.srcDns = createSelectorInput(payload, 'incDnsSrc', 'excDnsSrc')
		.concat(createGroupsSelectorInput(payload, 'incDnsSrcGroups', 'excDnsSrcGroups'));

	enrichmentInputs.destContinent = createSelectorInput(payload, 'incContinentDest', 'excContinentDest');
	enrichmentInputs.destCountryName = createSelectorInput(payload, 'incCountryNameDest', 'excCountryNameDest');
	enrichmentInputs.destOrganization = createSelectorInput(payload, 'incOrgDest', 'excOrgDest')
		.concat(createGroupsSelectorInput(payload, 'incOrgDestGroups', 'excOrgDestGroups'));

	enrichmentInputs.destIsp = createSelectorInput(payload, 'incIspDest', 'excIspDest')
		.concat(createGroupsSelectorInput(payload, 'incIspDestGroups', 'excIspDestGroups'));

	enrichmentInputs.destDns = createSelectorInput(payload, 'incDnsDest', 'excDnsDest')
		.concat(createGroupsSelectorInput(payload, 'incDnsDestGroups', 'excDnsDestGroups'));
	return enrichmentInputs;
};

const createTcpFlagsPayload = formData => {
	const data = {};
	data.SYN = Boolean(formData.SYN);
	data.ACK = Boolean(formData.ACK);
	data.FIN = Boolean(formData.FIN);
	data.URG = Boolean(formData.URG);
	data.PSH = Boolean(formData.PSH);
	data.RST = Boolean(formData.RST);
	data.ECE = Boolean(formData.ECE);
	data.CWR = Boolean(formData.CWR);
	return data;
};

const createInterfacesPayload = formData => {
	const interfacesPayload = {};
	interfacesPayload.incInputInterfaces = filterInputsByDirection(formData.inputInterface, INCLUDE_SIGN, NUMBER_INPUT_TYPE);
	interfacesPayload.excInputInterfaces = filterInputsByDirection(formData.inputInterface, EXCLUDE_SIGN, NUMBER_INPUT_TYPE);
	interfacesPayload.incOutputInterfaces = filterInputsByDirection(formData.outputInterface, INCLUDE_SIGN, NUMBER_INPUT_TYPE);
	interfacesPayload.excOutputInterfaces = filterInputsByDirection(formData.outputInterface, EXCLUDE_SIGN, NUMBER_INPUT_TYPE);
	if (_.isEmpty(interfacesPayload.incInputInterfaces) && _.isEmpty(interfacesPayload.incOutputInterfaces)
        && _.isEmpty(interfacesPayload.excInputInterfaces && _.isEmpty(interfacesPayload.excOutputInterfaces))) {
		return {};
	}

	return interfacesPayload;
};

const createSrcPortsPayload = formData => {
	const { portsSource } = formData;
	return portsSource ? {
		incSrcPorts: filterInputsByDirection(portsSource, INCLUDE_SIGN, NUMBER_INPUT_TYPE),
		excSrcPorts: filterInputsByDirection(portsSource, EXCLUDE_SIGN, NUMBER_INPUT_TYPE),
	} : {};
};

const createDestPortsPayload = formData => {
	const { portsDestination } = formData;
	return portsDestination ? {
		incDestPorts: filterInputsByDirection(portsDestination, INCLUDE_SIGN, NUMBER_INPUT_TYPE),
		excDestPorts: filterInputsByDirection(portsDestination, EXCLUDE_SIGN, NUMBER_INPUT_TYPE),
	} : {};
};

const createIpProtocolsPayload = formData => {
	const { ipProtocol } = formData;
	return ipProtocol ? {
		incIpProtocols: filterInputsByDirection(ipProtocol, INCLUDE_SIGN, NUMBER_INPUT_TYPE),
		excIpProtocols: filterInputsByDirection(ipProtocol, EXCLUDE_SIGN, NUMBER_INPUT_TYPE),
	} : {};
};

const createVlanPayload = ({ vlan, vlanIdBidirectionalTraffic }) => vlan ? {
	[INCLUDED_SRC_VLAN_ID_KEY]: filterInputsByDirection(vlan.srcVlanId, INCLUDE_SIGN, NUMBER_INPUT_TYPE),
	[EXCLUDED_SRC_VLAN_ID_KEY]: filterInputsByDirection(vlan.srcVlanId, EXCLUDE_SIGN, NUMBER_INPUT_TYPE),
	[INCLUDED_DST_VLAN_ID_KEY]: filterInputsByDirection(vlan.dstVlanId, INCLUDE_SIGN, NUMBER_INPUT_TYPE),
	[EXCLUDED_DST_VLAN_ID_KEY]: filterInputsByDirection(vlan.dstVlanId, EXCLUDE_SIGN, NUMBER_INPUT_TYPE),
	[INCLUDED_VXLAN_ID_KEY]: filterInputsByDirection(vlan.vxlanId, INCLUDE_SIGN, NUMBER_INPUT_TYPE),
	[EXCLUDED_VXLAN_ID_KEY]: filterInputsByDirection(vlan.vxlanId, EXCLUDE_SIGN, NUMBER_INPUT_TYPE),
	[VLAN_ID_BIDIRECTIONAL_TRAFFIC]: vlanIdBidirectionalTraffic,
} : {};

const createInterfacesFromPayload = interfacesPayload => {
	const inputInterface = createSelectorInput(interfacesPayload, 'incInputInterfaces', 'excInputInterfaces');
	const outputInterface = createSelectorInput(interfacesPayload, 'incOutputInterfaces', 'excOutputInterfaces');
	return { inputInterface, outputInterface };
};

const isFilledTcpFlags = formData => formData.SYN || formData.ACK || formData.FIN || formData.URG || formData.PSH || formData.RST || formData.ECE || formData.CWR;

const createSrcPortsFromPayload = portsPayload => {
	const srcPorts = createSelectorInput(portsPayload, 'incSrcPorts', 'excSrcPorts');
	return srcPorts;
};

const createDestPortsFromPayload = portsPayload => {
	const destPorts = createSelectorInput(portsPayload, 'incDestPorts', 'excDestPorts');
	return destPorts;
};

const createIpProtocolFromPayload = ipProtocolsPayload => {
	const ipProtocols = createSelectorInput(ipProtocolsPayload, 'incIpProtocols', 'excIpProtocols');
	return ipProtocols;
};

const createVlanFromPayload = vlanPayload => {
	const srcVlanId = createSelectorInput(vlanPayload, INCLUDED_SRC_VLAN_ID_KEY, EXCLUDED_SRC_VLAN_ID_KEY);
	const dstVlanId = createSelectorInput(vlanPayload, INCLUDED_DST_VLAN_ID_KEY, EXCLUDED_DST_VLAN_ID_KEY);
	const vxlanId = createSelectorInput(vlanPayload, INCLUDED_VXLAN_ID_KEY, EXCLUDED_VXLAN_ID_KEY);

	return {
		srcVlanId,
		dstVlanId,
		vxlanId,
	};
};

const createAwsFilterFromPayload = ({ aws }) => CloudReqBuilder.aws(aws);

const createAzureFilterFromPayload = ({ azure }) => CloudReqBuilder.azure(azure);

const createGoogleCloudFilterFromPayload = ({ google }) => CloudReqBuilder.google(google);

export {
	filterInputsByDirection,
	createEnrichmentPayload,
	createEnrichmentInputsByPayload,
	createTcpFlagsPayload,
	isFilledTcpFlags,
	createInterfacesPayload,
	createInterfacesFromPayload,
	createSrcPortsPayload,
	createDestPortsPayload,
	createSrcPortsFromPayload,
	createDestPortsFromPayload,
	createIpProtocolFromPayload,
	createIpProtocolsPayload,
	createAwsFilterFromPayload,
	createAzureFilterFromPayload,
	createGoogleCloudFilterFromPayload,
	createVlanFromPayload,
	createVlanPayload,
};
