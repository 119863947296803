/* eslint-disable */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import {
	COLLECTOR_FORMS, COLLECTOR_SAVE_ERROR_MESSAGE, COLLECTOR_SAVE_SUCCESS_MESSAGE,
	COLLECTOR_SUBMISSION_MAP, FORMS_EXTRA_PROPS, SWITCH_DEVICE_TYPE,
} from '../addDeviceConfig';

const AddCollectorOnFly = collectorType => ({ returnToBeginning, onCollectorCreate, ...props }) => {
	const dispatch = useDispatch();
	const [isSubmittingForm, setIsSubmittingForm] = useState(false);

	const FormComponent = COLLECTOR_FORMS[collectorType];
	const formAdditionalProps = FORMS_EXTRA_PROPS[collectorType];

	const onSubmit = async data => {
		try {
			setIsSubmittingForm(true);
			const collector = await COLLECTOR_SUBMISSION_MAP[collectorType](data);
			dispatch(openSnackbar('info', COLLECTOR_SAVE_SUCCESS_MESSAGE));
			onCollectorCreate(collector);
			returnToBeginning(SWITCH_DEVICE_TYPE);
		} catch (e) {
			dispatch(openSnackbar('error', COLLECTOR_SAVE_ERROR_MESSAGE));
		} finally {
			setIsSubmittingForm(false);
		}
	};

	return (
		<FormComponent isSubmittingForm={isSubmittingForm} onSubmit={onSubmit} returnToBeginning={returnToBeginning} {...formAdditionalProps} />
	);
};

export default AddCollectorOnFly;
