import {
	SET_ACCOUNT_INTERACTIONS,
	SET_USER_INTERACTIONS,
	UPDATE_SSO_MIGRATION_NOTIFICATION_DONE,
	UPDATE_WALKTHROUGH_DONE,
	UPDATE_WELCOME_DONE,
} from './actionTypes';
import action from '../middleware';
import http from '../../../../../services/httpService/http';
import { DASHBOARD_INTERACTIONS_URI, DASHBOARD_INTERACTIONS_USER_URI } from '../../services/urls';
import { interactionKeys } from './consts';

const getAccountInteractions = () => action(async dispatch => {
	const interactions = await http.get(DASHBOARD_INTERACTIONS_URI);
	if (interactions && interactions.dashboardInteractions) {
		dispatch({ type: SET_ACCOUNT_INTERACTIONS, payload: interactions.dashboardInteractions });
	}
});

const getUserInteractions = () => action(async dispatch => {
	const interactions = await http.get(DASHBOARD_INTERACTIONS_USER_URI);
	if (interactions && interactions.dashboardInteractions) {
		dispatch({ type: SET_USER_INTERACTIONS, payload: interactions.dashboardInteractions });
	}
});

const updateWalkthroughDone = (type, isDone) => {
	const interactionKey = interactionKeys[type];
	return action(async dispatch => {
		const res = await http.put(DASHBOARD_INTERACTIONS_USER_URI, { interactionKey, interactionValue: isDone });
		dispatch({ type: UPDATE_WALKTHROUGH_DONE, payload: { type, isDone } });
	});
};

const updateWelcomeDone = isDone => action(async dispatch => {
	const res = await http.put(DASHBOARD_INTERACTIONS_URI, {
		interactionKey: 'firstLogin.welcomeDone',
		interactionValue: isDone,
	});
	dispatch({ type: UPDATE_WELCOME_DONE, payload: isDone });
});

const updateUserNotifiedOnSSOMigration = isDone => action(async dispatch => {
	const res = await http.put(DASHBOARD_INTERACTIONS_USER_URI, {
		interactionKey: 'sso.userNotifiedOnSSOMigration',
		interactionValue: isDone,
	});
	dispatch({ type: UPDATE_SSO_MIGRATION_NOTIFICATION_DONE, payload: isDone });
});

export {
	getAccountInteractions,
	getUserInteractions,
	updateWelcomeDone,
	updateWalkthroughDone,
	updateUserNotifiedOnSSOMigration,
};
