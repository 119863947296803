import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
	skipBtn: {
		fontSize: '20px',
		color: theme.palette.primary.main,
		margin: '8px 0 30px 0',
	},
	description: {
		height: '10px',
	},
	buttonsSection: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
}));

export default useStyles;
