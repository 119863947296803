import http from '../../../../services/httpService/http';
const PERFORMANCE_MONITORING_PATH = 'performance-monitoring/v1/';

export const getDevicesPerfData = async () => {
	const { devicesPerformanceData } = await http.get(`${PERFORMANCE_MONITORING_PATH}devices`);
	return devicesPerformanceData;
};

export const getIpMonitoring = async () => await http.get(`${PERFORMANCE_MONITORING_PATH}ip-monitor`);
export const getIpMonitoringByDevice = async deviceId => http.get(`${PERFORMANCE_MONITORING_PATH}${deviceId && deviceId + '/'}ip-monitor`);
export const getVolumeByDevice = async deviceId => http.get(`${PERFORMANCE_MONITORING_PATH}${deviceId && deviceId + '/'}volume`);
export const getPortsByDevice = async deviceId => http.get(`${PERFORMANCE_MONITORING_PATH}${deviceId && deviceId + '/'}port-traffic`);

