import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputField: {
        width: '80%'
    },
    addButton: {
        marginTop: '15px',
        marginLeft: '2%'
    },
    list: {
        marginBottom: '20px',
        marginTop: '20px'
    },
    mainDiv: {
        display:'inline'
    }
}));

export {useStyles}