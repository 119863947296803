import React from 'react';
import { Component } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

export default class Stars extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: this.props.value,
		};
	}

	onClick = e => {
		e.preventDefault();
		this.setState({ value: !this.state.value });
		this.props.onDeleteThreat({ ...this.state.content, value: this.state.value });
	};

	render() {
		return (
			<IconButton aria-label="delete"
				onClick={this.onClick}>
				<DeleteIcon fontSize="small"/>
			</IconButton>
		);
	}
}

