import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	tab: {
		fontSize: '16px',
		textTransform: 'none',
	},
	addGroupButton: {
		marginRight: '15px',
		textAlign: 'right',
	},
	groupsContainerSection: {
		height: '100%',
		overflow: 'overlay',
	},
	container: {
		height: '100%',
	},
}));

export default useStyles;
