import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	container: {
		width: 'calc(100% - 140px)',
		position: 'fixed',
		maxWidth: '100%',
		zIndex: 10,
		right: 0,
	},
	banner: {
		height: '3.5em',
		padding: '0.2em',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		fontSize: '16px',
		fontWeight: 700,
	},
	bannerContent: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: '0 auto 0 auto',
	},
	closeButton: {
		padding: '1em',
		marginRight: '0.7em',
		'&:hover': {
			background: 'none',
		},
	},
	closeIcon: {
		color: 'white',
	},
	default: {
		backgroundColor: '#242424',
		color: 'white',
	},
	warning: {
		backgroundColor: '#D87515',
		color: 'white',
	},
	top: {
		top: 0,
	},
	bottom: {
		bottom: 0,
	},
}));

export default useStyles;
