import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {

    },
    wrapper: {
        margin: 0
    },
    label: {
        fontSize: "14px",
        color: theme.palette.inputText.main
    },
    icon: {
        borderRadius: 3,
        border: "black",
        flex: '0 0 auto',
        padding: '12px',
        transform: 'scale(0.7)',
        outline: 'auto',
        overflow: 'visible',
        fontSize: '1.2rem',
        textAlign: 'center'
    }
}));

export {useStyles}
