import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { validateForm } from 'redux-form-validators';

import SimpleModal from '../../simpleTable/simpleModal';
import SubmitButton from '../../form/Buttons/SubmitButton';
import UserIcon from '../../../../assets/modal/user-icon.png';
import { required, email } from '../../form/Validation/FieldLevelValidation';
import { FieldByType } from '../../form/formUtil';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {
	ADMIN_ROLE_VALUE,
	ROLE_SECTION_TITLE,
	ROLES,
	SUBMIT_BUTTON_LABEL,
	USER_SECTION_TITLE,
	RBAC_FEATURE_TOGGLE,
	ROLE_DISABLED_TOOLTIP,
} from './const';
import CustomTooltip from '../../Tooltip/CustomTooltip';

const radioLabel = (label, desc) => <span>
	<div className="modal-form-user-role-label">{label}</div>
	<div className="modal-form-user-role-desc">{desc}</div>
</span>;
class SmartForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			role: this.props.props.selectedRow
				? this.props.props.selectedRow.data.isAdmin
				: ADMIN_ROLE_VALUE,
		};
	}

	render() {
		const {
			isEdit,
			newEntryFields,
			handleNewEntryInputChange,
			handleEditDataChange,
			editFields,
			selectedRow,
			newUser,
			isRoleDisabled,
		} = this.props.props;
		const { handleSubmit, onSubmit, error } = this.props;
		const fields = selectedRow ? editFields : newEntryFields;
		const { role } = this.state;

		const onRoleRadioChanged = e => {
			this.setState({ role: e.target.value });

			if (selectedRow) {
				handleEditDataChange({ prop: 'role', value: e });
			} else {
				handleNewEntryInputChange({ prop: 'role', value: e });
			}
		};

		const onFormSubmit = e => {
			// Filter non-edit fields
			let reportedFields = e;
			if (isEdit) {
				reportedFields = Object.entries(e).reduce((allowedFields, [key, value]) => {
					const field = fields.find(field => field.accessor === key);
					if (!field || field.allowEdit) {
						allowedFields[key] = value;
					}

					return allowedFields;
				}, {});
			}

			const form = { ...reportedFields, isAdmin: Boolean(role === ADMIN_ROLE_VALUE) };
			return onSubmit(form);
		};

		const roleTooltip = isRoleDisabled ? ROLE_DISABLED_TOOLTIP : '';

		return (
			<form onSubmit={handleSubmit(onFormSubmit)} className="modal-form">
				<div className="modal-form-user-container">
					<span className="modal-section-title">{USER_SECTION_TITLE}</span>
					{
						fields.map((field, index) => <FieldByType
							index={index}
							key={index}
							prop={field.accessor}
							field={field}
							value={selectedRow ? `${selectedRow.data[field.accessor]}` : newUser[field.accessor]}
							placeholder={`${field.alias}`}
							onChange={selectedRow ? handleEditDataChange : handleNewEntryInputChange}
							isDisabled={isEdit && !field.allowEdit}
						/>)
					}
				</div>
				{RBAC_FEATURE_TOGGLE && <div className="modal-form-user-role-container">
					<span className="modal-section-title">{ROLE_SECTION_TITLE}</span>
					<RadioGroup
						aria-label="role"
						name="role"
						value={role}
						onChange={onRoleRadioChanged}
					>
						<CustomTooltip title={roleTooltip}>
							<span>
								{ROLES.map(role => (<FormControlLabel
									key={role.value}
									disabled={isRoleDisabled}
									value={role.value}
									control={<Radio color="primary"/>}
									label={radioLabel(role.label, role.desc)}
								/>))}
							</span>
						</CustomTooltip>
					</RadioGroup>
				</div>}

				{error && <div className="form-error">{error}</div>}
				<div className="modal-login-button-wrapper">
					<SubmitButton label={SUBMIT_BUTTON_LABEL}/>
				</div>
			</form>
		);
	}
}

SmartForm.propTypes = {
	error: PropTypes.any,
	handleSubmit: PropTypes.func,
	onSubmit: PropTypes.func,
	props: PropTypes.shape({
		isEdit: PropTypes.bool,
		newEntryFields: PropTypes.any,
		handleNewEntryInputChange: PropTypes.func,
		handleEditDataChange: PropTypes.func,
		editFields: PropTypes.any,
		selectedRow: PropTypes.object,
		newUser: PropTypes.any,
		isRoleDisabled: PropTypes.bool,
	}),
};

const validate = validateForm({
	firstName: [required],
	lastName: [required],
	email: [required, email],
});
let ConnectedSmartForm = (reduxForm({
	form: 'editUser',
	validate,
})(SmartForm));
ConnectedSmartForm = connect(state => ({
	initialValues: { ...state.userReducer.userForm.data },
}), {})(ConnectedSmartForm);

const modalIconStyle = { marginRight: '4%', marginTop: '2%', width: 131, height: 56 };

class FormModalUser extends Component {
	handleSubmit = async form => {
		const {
			isEdit,
			onEditClick,
			onNewEntryClick,
		} = this.props;

		if (isEdit) {
			onEditClick(form);
		} else {
			await onNewEntryClick(form);
		}
	};

	render() {
		const {
			modalOpen,
			toggle,
			isEdit,
		} = this.props;
		return (
			<SimpleModal width={550} header={isEdit ? 'Edit user' : 'Invite new user'} isOpen={ modalOpen } toggle={ toggle } icon={UserIcon} iconStyle={modalIconStyle}>
				<div className="edit-device-modal">
					<ConnectedSmartForm
						{...this.props.smartForm}
						props={this.props}
						onSubmit={this.handleSubmit}
					/>
				</div>
			</SimpleModal>
		);
	}
}

FormModalUser.propTypes = {
	isEdit: PropTypes.bool,
	modalOpen: PropTypes.bool,
	onEditClick: PropTypes.func,
	onNewEntryClick: PropTypes.func,
	smartForm: PropTypes.any,
	toggle: PropTypes.func,
	isRoleDisabled: PropTypes.bool.isRequired,
};
const mapStateToProps = state => ({
	user: state.auth.user,
	smartForm: state.reduxFormReducer,
});

export default connect(mapStateToProps)(FormModalUser);
