import AccountMenuIcon from '../../assets/accountMenu/accountMenuIcon.svg';
import React, { useState } from 'react';
import AccountMenu from './accountMenu';
import { useSelector } from 'react-redux';
import menuArrow from '../../assets/accountMenu/menuArrow.svg';
import { Typography } from '@material-ui/core';
import SupportTicketDialog from './supportTIcketDialog/supportTIcketDialog';

const AccountMenuSection = () => {
	const [accountMenuOpen, setAccountMenuOpen] = useState(false);
	const [anchorPosition, setAnchorPosition] = React.useState(null);
	const [supportDialogOpen, setSupportDialogOpen] = useState(false);
	const user = useSelector(state => state.auth.user);
	const features = useSelector(state => state.account.features) || {};
	const accountName = useSelector(state => state.account.name) || '';

	const handleClick = event => {
		const domRect = event.currentTarget.getBoundingClientRect();
		const position = { top: domRect.y, left: domRect.x + 150 }; // Padding it a little to the right
		setAnchorPosition(position);
		setAccountMenuOpen(!accountMenuOpen);
	};

	const handleClose = () => {
		setAnchorPosition(null);
	};

	const handleOpenSupportDialog = () => {
		setSupportDialogOpen(true);
	};

	const handleCloseSupportDialog = () => {
		setSupportDialogOpen(false);
	};

	const isOpen = Boolean(anchorPosition);

	const isAnalyst = !user.isAdmin;
	const isSubscriptionVisible = Boolean(!features.includes('original_customer') && !isAnalyst);

	return (
		<div>
			<div className="sidebar__link" onClick={handleClick}>
				<img src={AccountMenuIcon} alt="" className="sidebar-account-icon" />
				<Typography className="sidebar__link-title">
                    Account
				</Typography>
				<img src={menuArrow} className="sidebar-account-menu-button" alt={'account-menu'}/>
			</div>
			<AccountMenu isOpen={isOpen}
				onClose={handleClose}
				anchorPosition={anchorPosition}
				accountName={accountName}
				userName={`${user.firstName} ${user.lastName}`}
				isSubscriptionsVisible={isSubscriptionVisible}
				onOpenSupportDialog={handleOpenSupportDialog} />
			<SupportTicketDialog isOpen={supportDialogOpen}
				onClose={handleCloseSupportDialog}
			/>
		</div>
	);
};

export default AccountMenuSection;
