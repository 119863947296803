import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		width: 'max-content'
	},
	includeExcludeButton: {
		margin: "0 8px",
		height: "12px",
		width: "12px"
	},
	text: {
		width: 'max-content',
		fontSize: '14px'
	},
	deleteButton: {
		height: '6px',
		width: '6px',
		margin: "0 8px"
	},
	included: {
		color: 'white',
		backgroundColor: `${theme.palette.selectCreatableTags.include}!important`,
		'&:hover': {
			backgroundColor: `${theme.palette.selectCreatableTags.include}!important`
		},
	},
	excluded: {
		color: 'white',
		backgroundColor: `${theme.palette.selectCreatableTags.exclude}!important`,
		'&:hover': {
			backgroundColor: `${theme.palette.selectCreatableTags.exclude}!important`
		},
	},
	directionButton: {
		cursor: 'pointer!important',
	},
	label: {
		cursor: 'pointer',
	},
}));

export default useStyles;
