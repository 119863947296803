import React from 'react';
import '../series/graphHint.scss'
import Moment from "moment";
import * as d3 from "d3-format";
import { levelColors } from '../../graphConfig';
import { minMaxformater } from '../../../../../constants/formater';

function GraphHintNumber({ info, xTitle, yTitle }) {
    return (

        <div className="hint-wrapper" style={ { borderColor: levelColors[0] } }>
            <div className="hint">
                <div className="detail">
                    <div className="detail-title">
                        { xTitle }
                    </div>
                    <div className="detail-info">
                        { d3.format(minMaxformater)(info.x) }
                    </div>
                </div>
                <div className="detail">
                    <div className="detail-title">
                        { yTitle }
                    </div>
                    <div className="detail-info">
                        { d3.format(minMaxformater)(info.y) }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GraphHintNumber;
