import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    icon: (props) => ({
        fill: `${props.color} !important`,
        cursor: 'pointer',
        '&:hover': {
            fill: `${props.hover} !important`,
        }

    })
}));

export {useStyles}