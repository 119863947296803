import React, { Component } from 'react'

export default class GeneralMapHint extends Component {
    render() {
        const {device, statusIcons} = this.props; 
        return <div className="mapHint">
            <img src={statusIcons[0]} alt='' className='hint-icon' />
            <div className='hint-message-wrapper'>
                <div className='red' />
                <div className='hint-title'>
                    {device.name}
                </div>
                <div className='hint-message'>
                    {device.IP}
                </div>
            </div>
        </div>
    }
}