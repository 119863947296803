/* eslint-disable react/display-name */
import { Dialog, Zoom } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import analyzer from '../../../../helpers/analyzer';
import {
	ANALYZER_DISMISS_STATUS,
	ANALYZER_FAILED_STATUS,
	ANALYZER_SUCCESS_STATUS,
	DEVICE_CATEGORY,
} from '../../../../helpers/analyzerConstants';
import usePolling from '../../../../shared/customHooks/usePolling';
import LookingForData from '../lookingForData/lookingForData';
import configurationIndicativeMessages from './configurationIndicativeMessages';
import ConfiguringDevice from './configuringDevice/configuringDevice';
import { useStyles } from './configuringProcess.css';

const propTypes = {
	configurationFields: PropTypes.object.isRequired,
	samplingDeviceCallback: PropTypes.func.isRequired,
	downloadDeviceCallBack: PropTypes.func.isRequired,
	selectedDeviceConfig: PropTypes.object.isRequired,
	closeConfiguringProcess: PropTypes.object.isRequired,
	deviceType: PropTypes.string,
	history: PropTypes.object,
};

const INTERVAL_DELAY = 5000;
const I_AM_AWARE_TEXT = ' I am aware that the device won\'t be active until I completed these steps';

const conditionalFinishInterval = data => Boolean(data && data.lastSeen);

const ConfiguringProcess = React.memo(({
	samplingDeviceCallback,
	downloadDeviceCallBack,
	selectedDeviceConfig,
	selectedDeviceConfig: { iAmAwareText = I_AM_AWARE_TEXT },
	closeConfiguringProcess,
	deviceType,
	history,
}) => {
	const dialogRef = React.useRef();
	const [isConfiguredDevice, setIsConfiguredDevice] = useState(false);
	const [isNeedHelp, setIsNeedHelp] = useState(false);
	const {
		data,
		isLoading,
	} = usePolling(samplingDeviceCallback, INTERVAL_DELAY, conditionalFinishInterval, isConfiguredDevice);

	const { stop } = analyzer.useTimingEvent(DEVICE_CATEGORY, `${deviceType.toString().toUpperCase()}_ON_BOARDING_PROCESS`);
	const classes = useStyles();

	useEffect(() => {
		if (dialogRef.current) {
			const scrollPaperElement = dialogRef.current.getElementsByClassName('MuiDialog-scrollPaper')[0];
			if (scrollPaperElement) {
				scrollPaperElement.setAttribute('tabIndex', null);
			}
		}
	});

	const SuccessMessage = () => {
		useEffect(() => {
			stop(ANALYZER_SUCCESS_STATUS);
		});
		const { configType } = selectedDeviceConfig;
		const { successMessage: SuccessComponent } = configurationIndicativeMessages[configType];
		return <SuccessComponent history={history}/>;
	};

	const ErrorMessage = () => {
		useEffect(() => {
			stop(ANALYZER_FAILED_STATUS);
		});
		const { configType } = selectedDeviceConfig;
		const { errorMessage: ErrorComponent } = configurationIndicativeMessages[configType];
		return <ErrorComponent/>;
	};

	const onReturnToWaitingForData = () => {
		setIsNeedHelp(false);
	};

	const onCloseConfiguringProcess = () => {
		isLoading && stop(ANALYZER_DISMISS_STATUS);
		closeConfiguringProcess();
	};

	return (
		<Dialog open ref={dialogRef} onBackdropClick={onCloseConfiguringProcess} style={{ zIndex: 950 }} PaperProps={{ className: classes.dialogPaperProps }}>
			<div className={classes.actionArea}>
				<IconButton
					aria-label="close"
					onClick={onCloseConfiguringProcess}
					className={classes.actionIcon}
				>
					<CloseIcon onClick={onCloseConfiguringProcess}/>
				</IconButton>
				{isNeedHelp && <IconButton aria-label="return" onClick={onReturnToWaitingForData} className={classes.actionIcon}><ArrowBackIcon/></IconButton>}
			</div>
			{!isConfiguredDevice
			&& <Zoom in={true}>
				<ConfiguringDevice
					selectedDeviceConfigType={selectedDeviceConfig.configType}
					deviceBrandName={selectedDeviceConfig.brandLabel}
					deviceModelName={selectedDeviceConfig.label}
					downloadDeviceCallBack={() => downloadDeviceCallBack(selectedDeviceConfig)}
					setIsConfiguredDevice={setIsConfiguredDevice}
					closeConfiguringProcess={closeConfiguringProcess}
					deviceType={deviceType}
					iAmAwareText={iAmAwareText}
				/>
			</Zoom>}
			{isLoading && !isNeedHelp && <LookingForData redownloadConfig={() => downloadDeviceCallBack(selectedDeviceConfig)} openNeedHelp={() => setIsNeedHelp(true)}/>}
			{data && <SuccessMessage/>}
			{ isNeedHelp && <ErrorMessage />}
		</Dialog>
	);
}, () => true);

ConfiguringProcess.propTypes = propTypes;
export default compose(withRouter)(ConfiguringProcess);
