import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { endpointsDeviationColumns, VOLUME_CHANGE_KEY, VOLUME_DIRECTION_IS_UP_KEY } from '../EndpointsDeviationConfig';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useStyles } from '../EndpointsDeviation.css';
import CustomTooltip from '../../../../Tooltip/CustomTooltip';
import { getDeviceNameTooltip } from '../../../utils/DeviceMonitoringUtils';

const FullTableBody = ({ rows = [] }) => {
	const classes = useStyles();
	return (
		<>
			{rows.map(row => (
				<CustomTooltip title={getDeviceNameTooltip(row.deviceName)} className={classes.tooltip} key={row.id}>
					<TableRow className={classes.tableRow}>
						{endpointsDeviationColumns.map(column => {
							const value = row[column.id];
							if (column.id === VOLUME_CHANGE_KEY) {
								return (
									<TableCell key={column.id} align={column.align}>
										{row[VOLUME_DIRECTION_IS_UP_KEY] ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
										{value}%
									</TableCell>
								);
							}

							return (
								<TableCell key={column.id} align={column.align}>
									{value}
								</TableCell>
							);
						})}
					</TableRow>
				</CustomTooltip>
			))}
		</>
	);
};

FullTableBody.propTypes = {
	rows: PropTypes.array,
};
FullTableBody.defaultValue = {
};

export default FullTableBody;
