import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '10px',
        display: 'flex',
        flexDirection: 'column',
        height: '50%'
    },
    title: {
        fontSize: '14px',
        lineHeight: '0.89',
        letterSpacing: '0.4px',
        margin: '5px 0 5px 10px',
        color: theme.palette.advancedButtonsHover.main
    }
}));

export {useStyles};