import { groupTypes } from '../../view/ipgroupModal/groupTypes';

const formatAddresses = selectedGroup => {
	let formattedMacs = [];
	let formattedCIDRs = [];
	let formattedOrganizations = [];
	let formattedAwsInstanceIds = [];
	let formattedAwsSubnetIds = [];

	const macs = selectedGroup.objectItems.filter(item => item.type === groupTypes.MAC);
	if (macs && macs.length) {
		formattedMacs = macs.map(macValue => ({
			address: macValue.value.value,
			type: groupTypes.MAC,
			itemId: macValue.itemId,
		}));
	}

	const cidrs = selectedGroup.objectItems.filter(item => item.type === groupTypes.CIDR);
	if (cidrs && cidrs.length) {
		formattedCIDRs = cidrs.map(cidrValue => ({
			address: cidrValue.value.value, type: groupTypes.CIDR, itemId: cidrValue.itemId,
		}));
	}

	const organizations = selectedGroup.objectItems.filter(item => item.type === groupTypes.ORGANIZATION);
	if (organizations) {
		formattedOrganizations = organizations.map(orgValue => ({
			address: orgValue.value.value,
			type: groupTypes.ORGANIZATION,
			itemId: orgValue.itemId,
		}));
	}

	const awsInstanceIds = selectedGroup.objectItems.filter(item => item.type === groupTypes.AWS_INSTANCE_ID);
	if (awsInstanceIds && awsInstanceIds.length) {
		formattedAwsInstanceIds = awsInstanceIds.map(instanceValue => ({
			address: instanceValue.value.value,
			type: groupTypes.AWS_INSTANCE_ID,
			itemId: instanceValue.itemId,
		}));
	}

	const awsSubnetIds = selectedGroup.objectItems.filter(item => item.type === groupTypes.AWS_SUBNET_ID);
	if (awsSubnetIds && awsSubnetIds.length) {
		formattedAwsSubnetIds = awsSubnetIds.map(subnetValue => ({
			address: subnetValue.value.value,
			type: groupTypes.AWS_SUBNET_ID,
			itemId: subnetValue.itemId,
		}));
	}

	return formattedCIDRs.concat(formattedMacs, formattedOrganizations, formattedAwsInstanceIds, formattedAwsSubnetIds);
};

export { formatAddresses };
