import React from "react";
import myAlertsSide from "../../../../../../assets/walkthrough/customAlerts/steps/myAlertsLocation.png";
import myAlertsMain from "../../../../../../assets/walkthrough/customAlerts/steps/myAlerts.png";
import {STEP_2_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV1
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV1/mainPanelTemplateV1";

const Step2 = () => {
    return (
        <StepTemplate sidePanelImage={myAlertsSide}>
            <MainPanelTemplateV1 title={STEP_2_TITLE}
                                 image={<img src={myAlertsMain}/>}
                                  />
        </StepTemplate>
    )
}

export default Step2;