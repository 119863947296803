import http from "../../../../services/httpService/http";
import {
    deviceConfigurationPath,
    devicePath,
    lightweightPublicDevicesPath,
    publicDeviceVisibleCountPath
} from "./urls";

const getDeviceReferences = () => {
    return http.get(deviceConfigurationPath);
}

const getDevices = () => {
    return http.get(devicePath);
}

const getLightweightDevices = () => {
    return http.get(lightweightPublicDevicesPath);
}

const getPublicDevicesVisibleCount = () => {
    return http.get(publicDeviceVisibleCountPath);
}

const addDevice = (newDevice) => {
    return http.post(devicePath, newDevice)
}

const updateDevice = (deviceId, newDeviceValue) => {
    return http.put(devicePath + deviceId, newDeviceValue);
}

const removeDevice = (deviceId) => {
    return http._delete(devicePath + deviceId);
}

const createDeviceConfiguration = (configurationRequest) => {
    return http.post(deviceConfigurationPath, configurationRequest)
}

const getDevice = (deviceId) => {
    return http.get(`${devicePath}${deviceId}`);
}



export {
    getDeviceReferences,
    addDevice,
    removeDevice,
    updateDevice,
    createDeviceConfiguration,
    getLightweightDevices,
    getDevices,
    getPublicDevicesVisibleCount,
    getDevice
}
