import { Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import useEventAnalysisData from '../../../shared/customHooks/useEventAnalysisData';
import ActionsFullScreen from './actions/ActionsFullScreen';
import Actions from './actions/Actions';
import Details from './details/Details';
import useStyles from './MasterDetails.css';
import Overview from './overview/Overview';
import OverviewSkeleton from './skeletons/OverviewSkeleton';
import NoTokensDisplay from './noTokensDisplay/noTokensDisplay';

const MasterDetails = ({ history, isOpen, eventData, onClose, onCloseFullScreen, isLoading, onDelete, onHighlight, onGoToAnotherDetection, canGoNext, canGoPrevious, isFullScreen }) => {
	const classes = useStyles({ isFullScreen });

	const [canFullScreen, setCanFullScreen] = useState(false);

	useEffect(() => {
		if (document.getElementById('full-screen-portal')) {
			setCanFullScreen(true);
		}
	}, [document.getElementById('full-screen-portal')]);

	const { result: analysisResult, error: analysisError, isLoading: isAnalysisLoading } = useEventAnalysisData(eventData);

	const onFullScreen = ({ eventId }) => {
		history.push(`vca-management/event/${eventId}`);
	};

	const renderMasterDetails = () => <Box className={`${classes.wrapper} ${isOpen ? '' : classes.hidden}`}>
		<Grid container>
			{isFullScreen ? <></> : <Grid item xs={12} className={classes.topBar}>
				{
					<Actions
						onClose={onClose}
						eventData={eventData}
						onHighlight={onHighlight}
						onDelete={onDelete}
						onGoToAnotherDetection={onGoToAnotherDetection}
						canGoPrevious={canGoPrevious}
						canGoNext={canGoNext}
						showDeleteHighlight={!isLoading && eventData && eventData.isAssetsShown}
						onFullScreen={() => onFullScreen(eventData)}
					/>
					/* SetFullScreen as true, setDetectionDetailsToCurrent */
				}
			</Grid>
			}
			{
				!isOpen || isLoading || (eventData && eventData.isAssetsShown)
					? <Grid item xs={12} className={classes.scrollArea}>

						<Grid item xs={12}>
							<Grid justifyContent="space-between">
								<Grid item>
									{
										isLoading || !eventData
											? <OverviewSkeleton />
											: <Overview eventData={eventData} isFullScreen={isFullScreen}/>
									}
									{isFullScreen && <ActionsFullScreen
										className={classes.fullScreenActionsPosition}
										onClose={() => onCloseFullScreen(eventData)}
										eventData={eventData}
										onHighlight={onHighlight}
										onDelete={onDelete}
										showDeleteHighlight={!isLoading && eventData && eventData.isAssetsShown}
									/>
									}
								</Grid>

							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Details
								history={history}
								isLoading={isLoading}
								eventData={eventData}
								analysisResult={analysisResult}
								analysisError={analysisError}
								isAnalysisLoading={isAnalysisLoading}
							/>
						</Grid>
					</Grid>
					: <NoTokensDisplay feature={eventData && eventData.detectionType}/>
			}
		</Grid>
	</Box>;

	const renderMasterDetailsFullScreen = () => {
		if (isFullScreen && canFullScreen) {
			const portalRoot = document.getElementById('full-screen-portal');
			return ReactDOM.createPortal(renderMasterDetails(), portalRoot);
		}
	};

	return isFullScreen && canFullScreen ? renderMasterDetailsFullScreen() : renderMasterDetails();
};

MasterDetails.propTypes = {
	eventData: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
	isLoading: PropTypes.bool,
	onDelete: PropTypes.func,
	onHighlight: PropTypes.func,
	onGoToAnotherDetection: PropTypes.func,
	canGoPrevious: PropTypes.bool,
	canGoNext: PropTypes.bool,
	isFullScreen: PropTypes.bool,
};

export default MasterDetails;

