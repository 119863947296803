
import http from '../../../../services/httpService/http';
import { INVITATIONS_URI, USER_URI } from '../../../app/controller/services/urls';

const getUsers = async () => http.get(USER_URI);
const updateUser = async (userId, newValue) => http.put(`${USER_URI}/${userId}`, newValue);
const deleteUser = async userId => http._delete(`${USER_URI}/${userId}`);
const addUser = async newUser => http.post(INVITATIONS_URI, newUser);
export {
	getUsers,
	updateUser,
	deleteUser,
	addUser,
};

