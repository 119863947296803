import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    width: "180px"
  },
  header: {
    width: "100%",
    backgroundColor: "#E8E4E4",
    border: "1px solid #C1BFBF",
    borderRadius: "5px 5px 0px 0px",
    padding: "7px"
  },
  userName: {
    fontSize: "14px"
  },
  accountName: {
    color: "#686868",
    fontSize: "14px"
  },
  options: {
    width: "100%",
    padding: "7px",
    borderStyle: "none solid none solid",
    borderWidth: "1px",
    borderColor: "#C1BFBF",
  },
  option: {
    padding: "5px 0 5px 0"
  },
  optionsText: {
    fontSize: "13px"
  },
  optionsIcon: {
    height: "15px",
    width: "15px"
  },
  supportIcon: {
    height: "15px",
    width: "20px"
  },
  logout: {
    textAlign: "center",
    width: "100%",
    backgroundColor: "#E8E4E4",
    border: "1px solid #C1BFBF",
    borderRadius: "0px 0px 5px 5px",
    color: "#686868",
    padding: "7px",
    cursor: "pointer"
  }
}));

export {useStyles}