import { Box, List, ListItem, ListItemText, Popover } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useStyles from './popoverField.css';

const PopoverField = ({ title, contentTitle, children }) => {
	const classes = useStyles();

	const [isOpen, setIsOpen] = useState(false);
	const [anchor, setAnchor] = useState({ left: 60, top: 60 });

	const toggle = e => {
		setIsOpen(!isOpen);
		setAnchor({ left: e.clientX, top: e.clientY });
	};

	return (
		<>
			<p className={classes.toggleText} onClick={toggle}>
				{`${title} ${isOpen ? '↑' : '↓'}`}
			</p>
			<Popover onClose={toggle} open={isOpen} anchorPosition={anchor} anchorReference="anchorPosition">
				<Box className={classes.popoverBox}>
					<List dense disablePadding>
						<ListItem className={classes.listTitle}>
							<ListItemText disableTypography primary={contentTitle} />
							<Close className={classes.closeButton} onClick={toggle} />
						</ListItem>
						{children}
					</List>
				</Box>
			</Popover>
		</>
	);
};

PopoverField.propTypes = {
	children: PropTypes.node,
	contentTitle: PropTypes.string,
	title: PropTypes.string,
};

export default PopoverField;
