import { STATUS_LABELS } from '../../../containers/integrations/controller/constants/activeTypes';
import { useStyles } from './status.css';
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { useEffect, useState } from 'react';
import useInterval from '../../customHooks/useInterval';

const Status = ({ status, poller }) => {
	const [currentStatus, setCurrentStatus] = useState(status);
	const { base, [currentStatus]: currentStatusClass } = useStyles();
	const { label, tooltip } = STATUS_LABELS[currentStatus];

	useEffect(() => {
		if (poller) {
			statusIntervalCallback();
		}

		setCurrentStatus(status);
	}, [status]);

	const statusIntervalCallback = async () => {
		try {
			const fetchedStatus = await poller();
			if (STATUS_LABELS[fetchedStatus]) {
				setCurrentStatus(fetchedStatus);
			}
		} catch {}
	};

	poller && useInterval(statusIntervalCallback, 30000);

	return (
		<>
			{tooltip
				? <CustomTooltip title={tooltip}>
					<span className={clsx(base, currentStatusClass)}>{label}</span>
				</CustomTooltip>
				: <span className={clsx(base, currentStatusClass)}>{label}</span>
			}
		</>

	);
};

Status.propTypes = {
	status: PropTypes.string.isRequired,
	poller: PropTypes.func,
};

export default Status;
