import {dataTypeOptions} from "../utils/constantes";

const ANALYSE_DATA_SWITCH = "ANALYSE_DATA_SWITCH";
const ANALYZE_DATA = "ANALYZE_DATA";
const LOADING_ANALYSIS = "LOADING_ANALYSIS";
const UPDATE_SUMMARY = 'UPDATE_SUMMARY';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
const REMOVE_ANOMALIES = "REMOVE_ANOMALIES";
const GET_TOTALS = 'GET_TOTALS';
const ENRICH_NOTIFICATION = "ENRICH_NOTIFICATION";
const TOGGLE_EXPAND_FORM = 'TOGGLE_EXPAND_FORM';
const EXPAND_FORM = 'EXPAND_FORM';
const CONDENSE_FORM = 'CONDENSE_FORM'

export { 
    ANALYSE_DATA_SWITCH,
    ANALYZE_DATA, 
    LOADING_ANALYSIS, 
    UPDATE_SUMMARY, 
    REMOVE_ANOMALIES, 
    REMOVE_NOTIFICATION, 
    GET_TOTALS, 
    ENRICH_NOTIFICATION,
    dataTypeOptions,
    TOGGLE_EXPAND_FORM,
    EXPAND_FORM,
    CONDENSE_FORM
}