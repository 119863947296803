import action from "../../../app/controller/redux/middleware";
import { SET_ERROR } from "../../../app/controller/redux/message/messageActions";

import SummaryData from "../../../../helpers/summaryData";
import _ from 'lodash'
import moment from 'moment'

import {
    ANALYSE_DATA_SWITCH,
    ANALYZE_DATA,
    LOADING_ANALYSIS,
    REMOVE_ANOMALIES,
    REMOVE_NOTIFICATION,
    GET_TOTALS,
    ENRICH_NOTIFICATION,
} from './actionTypes';

import { getIpGroupAnomalise, getTotalPackets, getTotalThreats, getSummaryDatas } from "../services/visibilityView/requests";
import {parseAnalyzedData} from "../businessLogic/parseAnalyzedData";
import {processAnalysis , convertSimpleData, processSimpleAnalysis, manipulateAnalyzedData,convertDataset, isInSections} from "../utils/common";

// TODO REFRACTOR WHEN WORKING ON SUMMURY VIEW 
const getAnalyzeData = (params) => {
    return action(async dispatch => {
        dispatch({
            type: LOADING_ANALYSIS
        });
        let res = await getAnalyzeData(params);

        if (!res) return null;

        if (!_.isEmpty(res.error) && res.status == 401) {
            dispatch({
                type: SET_ERROR,
                message: res.error
            });
            return;
        }

        let analyzedData = parseAnalyzedData(res, params);
        dispatch({
            type: ANALYZE_DATA,
            payload: { analyzedData }
        });

        return analyzedData;
    });
};


const getAnalyzeDataChangeWithTimeWindow = (analyzedData, est_data, timeWindow) => {

    const newObject = manipulateAnalyzedData(analyzedData, est_data, timeWindow)

    return ({
        type: ANALYSE_DATA_SWITCH,
        payload: { newObject }
    });

}



const getTotals = () => {
    return action(async (dispatch, getState) => {
        const resultThreat = await getTotalThreats();
        const resultPacket = await getTotalPackets();

        const dailyThreats = resultThreat ? resultThreat.totalThreats : 0;
        const dailyPackets = resultPacket ? resultPacket.totalPackets : 0;

        let totals = { dailyThreats, dailyPackets };

        dispatch({
            type: GET_TOTALS,
            payload: { totals }
        });

        return totals;
    });
};



const enrichNotification = () => {
    return action(async (dispatch, getState) => {
        const state = getState();
        const { anomalies } = state.analyze.summaryData;
        const { groups } = state.account;

        const fromTimeStamp = moment().subtract(1, 'm').unix()
        const toTimeStamp = moment().unix()

        const lastAnomalize = await getIpGroupAnomalise({
            fromTimeStamp, toTimeStamp
        })

        const mappedValues = lastAnomalize.map(anomaly => {
            const groupsAsset = _.groupBy(anomaly.assets, 'type');
            const AssetKeys = Object.keys(!_.isEmpty(groupsAsset) ? groupsAsset : []);
            var globalMessage = []
            var message_start = `${_.capitalize(anomaly.severity.toLowerCase())} severity network anomaly`
            globalMessage.push(message_start);
            AssetKeys.forEach(asset => {

                const messageType = `, ${asset.toLowerCase().split('_').join(' ')}s :`;

                const valuesTest = groupsAsset[asset].map(r => {
                    return typeof (r.value) == 'string' ? ` ${r.metadata.type.toLowerCase()}Port_${r.value}` : ` ${Object.values(r.value).join('-')} `
                }).join(',');

                globalMessage = globalMessage.concat([messageType, valuesTest])

            })
            return {
                id: anomaly._id,
                severity: anomaly.severity.toLowerCase(),
                time: anomaly.timestamp * 1000,
                message: globalMessage.join(''),
                groupId: anomaly.ipGroupId
            }
        })
        const mappedarray = mappedValues.map(e => {
            return {
                ...e,
                title: `IP Group - ${_.find(groups, q => { return q._id == e.groupId; }).title}`
            }
        });
        const allId = mappedarray.map(e => { return e.id });
        let mNewAnomalize = anomalies.filter(e => { return !allId.includes(e.id) })
        let uniqAnomalize = _.uniqBy(anomalies.concat(mNewAnomalize).concat(mappedarray), 'id');
        SummaryData.updateAnomalizes(uniqAnomalize)
        dispatch({
            type: ENRICH_NOTIFICATION,
            payload: {
                anomalies: uniqAnomalize,
            }
        });
    })
}

const removeNotification = (id) => {
    const notifications = SummaryData.removeNotification(id);

    return {
        type: REMOVE_NOTIFICATION,
        payload: { notifications, removed: id }
    };
}

const removeAnomaly = (id) => {
    const anomalies = SummaryData.removeAnomaly(id);
    return {
        type: REMOVE_ANOMALIES,
        payload: { anomalies: anomalies, removed: id }
    };
}

export {
    getAnalyzeData,
    processSimpleAnalysis,
    processAnalysis,
    removeNotification,
    removeAnomaly,
    getAnalyzeDataChangeWithTimeWindow,
    getTotals,
    enrichNotification
}