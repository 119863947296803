import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	stepWrapper: {
		marginTop: '15px',
		display: 'flex',
		justifyContent: 'center',
	},
	explanationLabel: {
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 400,
		margin: '30px 0',
	},
	awareSection: {
		display: 'flex',
		cursor: 'pointer',
		alignItems: 'center',
	},
	awareText: {
		fontSize: '14px',
		fontWeight: 700,
		lineHeight: '19px',
		color: '#48648B',
		margin: 0,
		userSelect: 'none',
	},
	button: {
		marginTop: '30px',
		width: '200px',
		height: '50px',
		fontWeight: 700,
		fontSize: '16px',
	},
}));

export default useStyles;
