import React, { Component } from 'react';
import { GradientDefs } from 'react-vis';


const blueGradient =
    (
        <GradientDefs>
            <linearGradient
                id="blueGradient"
                gradientUnits="userSpaceOnUse"
                x1="50%" y1="100%" x2="50%" y2="0%"
            >
                <stop offset="0%" stopColor="#a8c1e4" stopOpacity="0.8" />
                <stop offset="100%" stopColor="#48648b" stopOpacity="0.8" />
            </linearGradient>
        </GradientDefs>
    );
const yellowGradient =
    (
        <GradientDefs>
            <linearGradient
                id="yellowGradient"
                gradientUnits="userSpaceOnUse"
                x1="50%" y1="100%" x2="50%" y2="0%"
            >
                <stop offset="0%" stopColor="rgba(247, 213, 0, 0.2)" stopOpacity="0.7" />
                <stop offset="100%" stopColor="#ffcb45" stopOpacity="0.7" />
            </linearGradient>
        </GradientDefs>
    );
const redGradient =
    (
        <GradientDefs>
            <linearGradient
                id="redGradient"
                gradientUnits="userSpaceOnUse"
                x1="50%" y1="100%" x2="50%" y2="0%"
            >
                <stop offset="0%" stopColor="rgba(249, 152, 147, 0.2)" stopOpacity="0.7" />
                <stop offset="100%" stopColor="#f05f5a" stopOpacity="0.7" />
            </linearGradient>
        </GradientDefs>
    );


export {
    redGradient,
    blueGradient,
    yellowGradient
}