import React, { Component } from 'react';
import moment from 'moment';
import './defaultQuery.scss';

import { Field } from 'redux-form';

import { getLiteDevices, getLiteCollectorDevices, getLitePublicCloudDevices } from '../../../../deviceManagement/controller/lightweightReducer/actions';
import { connect } from 'react-redux';
import { dataTypeOptions } from '../../../controller/redux/actionTypes';

import { getTimeRangeByValue, timeRangeOptions } from '../../backgroundProcess/timeRangeMapping/timeRangeMapping';
import { dropdownSelectorStyles, textSelectorStyles } from '../../../../../shared/utils/customSelectorStyles';
import RightArrow from '../../../../../assets/blue-to-arrow.svg';
import InputWrapper from '../../../../alertsManagement/view/wrapper/inputWrapper';
import DevicesInterfacesSelector from '../../../../../shared/components/queryLine/selectors/devicesInterfacesSelector';
import simpleValueSelector from '../../../../../shared/components/queryLine/selectors/simpleValueSelector';
import timeUnit from '../../../../../shared/components/queryLine/timeUnit/timeUnit';
import DateTimePicker from '../../../../../shared/components/dateTimePicker/dateTimePicker';
import PropTypes from 'prop-types';

const MEDIUM_SCREEN_SIZE = 1350;
const LARGE_SCREEN_SIZE = 1700;

class DefaultQuery extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLargeScreen: window.innerWidth >= LARGE_SCREEN_SIZE,
			isSmallScreen: window.innerWidth < MEDIUM_SCREEN_SIZE,
		};
	}

	handleWindowResize = () => {
		this.setState({isLargeScreen: window.innerWidth >= LARGE_SCREEN_SIZE,
			isSmallScreen: window.innerWidth < MEDIUM_SCREEN_SIZE});
	};

	componentDidMount = async () => {
		this.props.getLiteDevices();
		this.props.getLiteCollectorDevices();
		this.props.getLitePublicCloudDevices();
		window.addEventListener('resize', this.handleWindowResize);
	};

	componentWillUnmount = () => {
		window.removeEventListener('resize', this.handleWindowResize);
	};

	updateTimeFields = e => {
		const { timeFrom, timeTo } = getTimeRangeByValue(e.value);
		const { change } = this.props;
		change('datefrom', timeFrom);
		change('timefrom', timeFrom);
		change('dateto', timeTo);
		change('timeto', timeTo);
	};

	resetTimeRangeField = () => {
		const { change } = this.props;
		change('lastTimeRange', []);
	};

	classNameByScreenSize = () => {
		return this.state.isLargeScreen ? '' : this.state.isSmallScreen ? 'small-screen-' : 'medium-screen-';
	}

	render() {
		const { publicDevices, collectorDevices, publicCloudDevices } = this.props;
		const selectedDevices = this.props.initialValues.devices;
		const allDevices = { ...publicDevices, ...collectorDevices, ...publicCloudDevices };

		const options = [];

		Object.keys(allDevices).forEach(id => {
			options.push({
				value: id,
				label: allDevices[id].name,
				direction: 'i',
				type: 'device',
			});
		});

		const FiltereddataTypeOptions = dataTypeOptions;
		return (
			<div className="line1">
				<div className="timeRange">
					<InputWrapper
						name={ 'Quick range' }
						input={ <Field
							name="lastTimeRange"
							component={ simpleValueSelector }
							options={ timeRangeOptions }
							onChange={ this.updateTimeFields }
							className="timeRange"
							selectorStyles={dropdownSelectorStyles}
						/> } />
				</div>
				<div className="timeunit">
					<InputWrapper name={ 'Time window' }
						input={ <Field
							name="timeWindow"
							component={ timeUnit }
							type="number"
						/> } />

				</div>
				<div className={`${this.classNameByScreenSize()}queryType`}>
					<InputWrapper name={ 'Query type' } input={ <Field
						name="queryTypes"
						component={ simpleValueSelector }
						options={ FiltereddataTypeOptions }
						className="alerte-type-field"
						selectorStyles={dropdownSelectorStyles}
					/> } />

				</div>
				<div className={`${this.classNameByScreenSize()}dates-section`}>
					<div className="dateFrom">
						<InputWrapper
							name="From"
							input={<Field
								name="datefrom"
								component={ DateTimePicker }
								onChangeDateTime={event => {
									const valueMoment = moment(event.target.value);
									this.resetTimeRangeField();
									this.props.onChange('datefrom', valueMoment);
									this.props.change('datefrom', valueMoment);
								}}
							/>}

						/>

						{/* <KeyboardDateTimePicker */}
						{/*    variant="inline" */}
						{/*    ampm={false} */}
						{/*    value={this.props.initialValues.datefrom} */}
						{/*    onChange={(value) => { */}
						{/*        this.resetTimeRangeField(); */}
						{/*        this.props.onChange('datefrom', value); */}
						{/*    }} */}
						{/*    onError={console.log} */}
						{/*    format="DD.MM.YYYY HH:mm" */}
						{/* /> */}
					</div>
					{ this.state.isLargeScreen ? (
					<div>
						<InputWrapper
							hasTimeorDate
							input={ <img src={ RightArrow } className="vv-pickers-separator" alt={'to time'}/> } />
					</div>
					) : null
					}

					<div className="dateTo">
						<InputWrapper
							name={'To'}
							input={<Field
								name="dateto"
								component={ DateTimePicker }
								onChangeDateTime={event => {
									const valueMoment = moment(event.target.value);
									this.resetTimeRangeField();
									this.props.onChange('dateto', valueMoment);
									this.props.change('dateto', valueMoment);
								}}
							/>} />

					</div>
				</div>
				<div className={`${this.classNameByScreenSize()}devices`}>
					<InputWrapper name={ 'Devices' } input={
						<Field
							name="devices"
							placeholder={ 'My devices' }
							component={ DevicesInterfacesSelector }
							options={ options }
							value={ selectedDevices }
							onChange={ value => {
								this.props.onChange('devices', value);
							} }
							className="device-selector-field"
							selectorStyles={textSelectorStyles}
						/> } />
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	publicDevices: state.liteDevices.publicDevices,
	collectorDevices: state.liteDevices.collectorDevices,
	publicCloudDevices: state.liteDevices.publicCloudDevices,
});

const mapDispatchToProps = {
	getLiteDevices,
	getLiteCollectorDevices,
	getLitePublicCloudDevices,
};

DefaultQuery.propTypes = {
	onChange: PropTypes.func,
	change: PropTypes.func,
	initialValues: PropTypes.shape({
		devices: PropTypes.array,
	}),
	publicDevices: PropTypes.shape({
		[PropTypes.string.isRequired]: PropTypes.shape({
			IP: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}),
	collectorDevices: PropTypes.shape({
		[PropTypes.string.isRequired]: PropTypes.shape({
			IP: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}),
	publicCloudDevices: PropTypes.shape({
		[PropTypes.string.isRequired]: PropTypes.shape({
			name: PropTypes.string.isRequired,
			identifierValue: PropTypes.string.isRequired,
		}),
	}),
	getLiteDevices: PropTypes.func,
	getLiteCollectorDevices: PropTypes.func,
	getLitePublicCloudDevices: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefaultQuery);

