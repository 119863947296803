import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	container: {
		padding: '0.2em 0 1em 0',
	},
	headline: {
		fontSize: '1.3rem',
		fontWeight: 600,
		margin: props => props.isFullScreen ? '1em 1.3em 1em 0.5em' : '0.3em 0.3em 0.3em 0.6em',
	},
	infoChips: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-around',
		width: '30em',
		height: '3.3em',
		margin: '0.8em 0 0 0.2em',
	},
	infoSubheader: {
		fontWeight: 600,
	},
	lastUpdatedRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		fontSize: '1.1em',
		margin: '0.5em 0 0.5em 1em',
		gap: '2.8%',
	},
	lastUpdatedRowFullScreen: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		fontSize:'1.3em',
		margin: '0.5em 0 0.5em 1em',
		gap: '2.8%',
	},
	descriptionRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		fontSize: props => props.isFullScreen ? '1.3em' : '1.1em',
		margin: '0.5em 0 0.5em 1em',
		gap: '3.7%',
	},
	remediationRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		fontSize: props => props.isFullScreen ? '1.3em' : '1.1em',
		margin: '0.5em 0 0.5em 1em',
		gap: '3.2%',
	},
	endpointsRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		fontSize: props => props.isFullScreen ? '1.3em' : '1.1em',
		margin: '0.5em 0 0.5em 1em',
		gap: '4.4%',
	},
	lastUpdated: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
	},
	description: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
	},
	severityChip: {
		border: '1px solid #C2C2C2',
		width: 'max-content',
		padding: '0.5em',
		borderRadius: '0.4em',
		fontSize: '15px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}));

export default useStyles;
