import {
    REQ_ALERTS,
    REQ_ALERTS_SUCCESS,
    REQ_ALERTS_FAIL,
    SELECTED_ALERT_EDITION,
    SELECTED_FILTER,
    REMOVE_GRAPH,
    UPDATE_GIVEN_GRAPH,
    SET_ALERT_POP_OVER,
    ALERT_FAIL,
    CLEAR_ALERT_ERROR,
} from "./actionTypes";

import { initialValue } from "./initialValues";

export default function (state = initialValue, { type, payload }) {
    switch (type) {
        case SET_ALERT_POP_OVER:
            return {
                ...state,
                selectedBar: payload,
            };
        case UPDATE_GIVEN_GRAPH:
            if (payload.index !== -1) {
                return {
                    ...state,
                    graphs: state.graphs.map((graph, index) => {
                        if (index !== payload.index) {
                            return graph;
                        }
                        return payload.statistics;
                    }),
                };
            }
            return {
                ...state,
                graphs: [...state.graphs, payload.statistics],
            };
        case REMOVE_GRAPH:
            return {
                ...state,
                graphs: [],
            };

        case SELECTED_FILTER:
            return {
                ...state,
                selectedFilter: {
                    value: payload.value,
                    timeUnit: payload.unit,
                    title: payload.title,
                },
            };
        case REQ_ALERTS:
            return {
                ...state,
            };

        case REQ_ALERTS_SUCCESS:
            return {
                ...state,
                alertes: payload.alertes,
            };

        case REQ_ALERTS_FAIL:
            return {
                ...state,
            };

        case SELECTED_ALERT_EDITION:
            return {
                ...state,
                selectedAlertEdition: payload.selectedAlertEdition,
            };

        case ALERT_FAIL:
            return {
                ...state,
                alertError: payload.error,
            };

        case CLEAR_ALERT_ERROR:
            return {
                ...state,
                alertError: null,
            };

        default:
            return state;
    }
}
