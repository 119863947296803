import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	tab: {
		fontWeight: 700,
		fontSize: '16px',
		lineHeight: '16px',
		textTransform: 'none',
		color: theme.palette.secondary.main,
		opacity: 0.7,
		'&:hover': {
			textTransform: 'none',
			fontSize: '16px',
			// Can't get from theme, because if we use opacity, it's effect also on the font
			backgroundColor: 'rgba(7,28,54,0.1)',
			fontWeight: 'bold',
		},
	},
	tabContent: {
		height: '95%',
	},
	borderSelected: {
		height: '1px !important',
	},
	tabIndicator: {
		height: '2px',
		fontWeight: 700,
		backgroundColor: '#7287A5',
	},
	tabsBox: {
		width: '100%',
		height: '5%',
		maxHeight: '5%',
		padding: '20px 25px',
		paddingBottom: '3%',
	},
}));

export default useStyles;
