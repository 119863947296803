import { Divider, Grid, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import useStyles from './Actions.css';
import StarIcon from '@material-ui/icons/Star';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { directionTypes } from '../../../../shared/components/detectionsTable/detectionsTableConfig';
import CustomTooltip from '../../../../shared/components/Tooltip/CustomTooltip';

const Actions = ({ onClose, eventData, onDelete, onHighlight, onGoToAnotherDetection, canGoPrevious, canGoNext, showDeleteHighlight, onFullScreen }) => {
	const classes = useStyles();
	const [isMarked, setIsHighlighted] = useState(eventData && eventData.isMarked);

	useEffect(() => {
		setIsHighlighted(eventData && eventData.isMarked);
	}, [eventData]);

	const getTooltipContent = text => <p className={classes.tooltipText}>{text}</p>;

	const handleDelete = () => {
		onClose();
		onDelete();
	};

	const handleHighlight = () => {
		setIsHighlighted(!isMarked);
		onHighlight();
	};

	return (
		<Grid container className={classes.wrapper}>
			<Grid item xs={2}>
				<CustomTooltip className={classes.iconTooltip} title={getTooltipContent('Back to table')}>
					<IconButton className={classes.icon} onClick={onClose}>
						<ArrowBackIcon />
					</IconButton>
				</CustomTooltip>
			</Grid>
			<Grid item container xs={5}>
				{
					showDeleteHighlight && <>
						<CustomTooltip PopperProps={{ disablePortal: true }} className={classes.iconTooltip} title={getTooltipContent('Delete')}>
							<IconButton className={classes.icon} onClick={handleDelete}>
								<DeleteIcon />
							</IconButton>
						</CustomTooltip>
						<Divider orientation="vertical" variant="middle" textAlign="center" flexItem/>
						<CustomTooltip className={classes.iconTooltip} title={isMarked ? getTooltipContent('Remove highlight') : getTooltipContent('Highlight')}>
							<IconButton onClick={handleHighlight}>
								<StarIcon className={`${isMarked ? classes.highlighted : classes.icon}`} alt="highlight"/>
							</IconButton>
						</CustomTooltip>
					</>
				}
			</Grid>
			<Grid item container xs={5} justifyContent="flex-end">
				<CustomTooltip className={classes.iconTooltip} title={getTooltipContent('Full screen')}>
					<IconButton className={classes.icon} onClick={onFullScreen}>
						{/* <Expand /> */}
						<FullscreenIcon fontSize="large" />
					</IconButton>
				</CustomTooltip>
				<CustomTooltip className={classes.iconTooltip} title={getTooltipContent('Previous')}>
					<IconButton className={classes.icon} onClick={() => onGoToAnotherDetection(directionTypes.PREVIOUS)} disabled={!canGoPrevious}>
						<NavigateBeforeIcon fontSize="large"/>
					</IconButton>
				</CustomTooltip>
				<CustomTooltip className={classes.iconTooltip} title={getTooltipContent('Next')}>
					<IconButton className={classes.icon} onClick={() => onGoToAnotherDetection(directionTypes.NEXT)} disabled={!canGoNext}>
						<NavigateNextIcon fontSize="large"/>
					</IconButton>
				</CustomTooltip>
			</Grid>
		</Grid>
	);
};

Actions.propTypes = {
	onClose: PropTypes.func.isRequired,
	eventData: PropTypes.object,
	onDelete: PropTypes.func,
	onHighlight: PropTypes.func,
	onGoToAnotherDetection: PropTypes.func,
	canGoPrevious: PropTypes.bool,
	canGoNext: PropTypes.bool,
	showDeleteHighlight: PropTypes.bool,
	onFullScreen: PropTypes.func,
};

export default Actions;
