import { useCallback, useEffect, useState } from 'react';
import {
	EXTRA_HEIGHT_SIZE_PER_COLUMN,
	graphOptions,
	IS_RESIZE_ANIMATION_ENABLE,
	RERENDER_GRAPH_INTERVAL_MS,
} from './config';
import { transformData } from './transformers';
import PropTypes from 'prop-types';
import useInterval from '../../customHooks/useInterval';

export const useTileMap = (data, valueKey, chartHeight, maxColumns, overrides, chartRef) => {
	const graphOptionsMemoized = useCallback(
		(height, overrides) => graphOptions(height, overrides),
		[],
	);
	const redrewChart = useCallback(() => {
		if (chartRef.current) {
			chartRef.current.chart.series[0].update({}, true);
		}
	}, [chartRef.current]);

	const [chartOptions, setChartOptions] = useState(graphOptionsMemoized(chartHeight, overrides));

	useEffect(() => {
		updateSeriesData(transformData(data, maxColumns, chartRef, valueKey));
	}, [data, maxColumns]);

	const updateSeriesData = data => {
		setChartOptions({
			series: [{ ...chartOptions.series[0], data }],
		});

		// Resize graph
		chartRef.current.chart.setSize(
			null,
			(Math.floor(data.length / maxColumns) * EXTRA_HEIGHT_SIZE_PER_COLUMN) + 150,
			IS_RESIZE_ANIMATION_ENABLE,
		);
	};

	useInterval(redrewChart, RERENDER_GRAPH_INTERVAL_MS);
	return {
		chartOptions,
	};
};

useTileMap.propTypes = {
	data: PropTypes.array.isRequired,
	chartHeight: PropTypes.number.isRequired,
	maxColumns: PropTypes.number,
	overrides: PropTypes.object,
	chartRef: PropTypes.object.isRequired,
};

useTileMap.defaultProps = {
	maxColumns: 5,
	overrides: {},
};
