
import moment from 'moment';
import action from '../../../../app/controller/redux/middleware';
import _ from 'lodash'
import { dataTypeOptions } from '../../../../backgroundProcess/controller/redux/analyzeActions';
import {
    categorizationPayload
} from '../../businessLogic/entityCreator';
import { GET_INSIGHTS , REMOVE_INSIGHT } from './actionTypes';
import {getInsightsAsync} from '../../services/insights/requests';
import portsMapping from '../../../../../containers/topTalkers/references/ports_mapping.json';
import protocolsMapping from '../../../../../containers/topTalkers/references/protocols_mapping.json';
const queryTypes = 'queryTypes';
const IP_PROTOCOL_INSIGHT_TYPE = 'ipProtocols';

const findDeviceName = (references, id) => {
    const foundObject = references[id];
    if (foundObject) {
        return foundObject.name;
    }
    return null;
}

const convertInsightData = (insightData) => {
    const asset = insightData.insight.getValue(insightData.originalQuery);
    return insightData.insight.label === IP_PROTOCOL_INSIGHT_TYPE ?
        protocolsMapping[asset] &&  protocolsMapping[asset].protocol_name :
        portsMapping[asset] && portsMapping[asset].port_name;
}

// Gets the corresponding handler object from categorizationPayload
const getInsightAttribute = (originalPaylaod) => {
    const value = _.reject(Object.keys(_.pick(originalPaylaod, categorizationPayload.map(e => e.label))), function (key) { return _.isEmpty(originalPaylaod[key]) })[0];
    return _.find(categorizationPayload, e => { return e.label === value })
}

const removeInsight = (id) => {
    return action(async (dispatch, getState) => {
        const newList = getState().insights.removedInsights
        newList.push(id);
        dispatch({
            type: REMOVE_INSIGHT,
            payload: newList
        });
    })
}
const getInsights = () => {
    return action(async (dispatch, getState) => {
        const tempPayload = { fromTimeStamp: moment().subtract(1, 'day').unix(), toTimeStamp: moment().unix() };
        let insights = await getInsightsAsync(tempPayload);
        insights = insights.map(insightData => {
            const insightAttribute = getInsightAttribute(insightData.originalQuery);
            const queryType = dataTypeOptions.find(option => { return option.value === insightData.originalQuery[queryTypes][0]; });
            return {
                createdAt: insightData.createdAt,
                originalQuery: insightData.originalQuery,
                customAlert: insightData.customAlert,
                insight: insightAttribute, // handler object - the whole attribute
                queryType, // query type internal object
                assetId: insightData.entity.value, // this is device id
                assetType: insightData.entity.type, // unused - DOESNT EXIST
                instances: insightData.instances.map(insightData => { return { ...insightData, assets: insightData.metadata } }),
            }
        });

        const publicDevices = getState().liteDevices.publicDevices;
        const collectorDevices = getState().liteDevices.collectorDevices;
        const publicCloudDevice = getState().liteDevices.publicCloudDevices;
        const allDevices = { ...publicDevices, ...collectorDevices, ...publicCloudDevice };
        const enrichedInsights = insights.map(insightData => {
            const assetTitle = findDeviceName(allDevices, insightData.assetId);
            const insightDataConvert = convertInsightData(insightData);
            const assetMeaningDisplay = insightDataConvert? `(${insightDataConvert})` : ""
            return {
                ...insightData,
                assetTitle,
                message: `${insightData.queryType.prefix} ${insightData.queryType.shorter} 
                    in device ${assetTitle} over ${insightData.insight.value} ${insightData.insight.getValue(insightData.originalQuery)} ${assetMeaningDisplay}`,

            }
        })

        dispatch({
            type: GET_INSIGHTS,
            payload: enrichedInsights
        });
    });
}



export {
    getInsights,
    removeInsight
}