import React, {Component, Fragment} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// libs
import {Provider} from 'react-redux';
import {HashRouter} from 'react-router-dom';
import ScrollToTop from './scrollToTop';
import '../../../../shared/utils/yupMethods'
import "../../../../services/httpService/axiosConfigurations";
// router
import Router from '../router/router';
//store
import store from '../../controller/redux/store/store';
import _ from 'lodash';
// style
import 'bootstrap/dist/css/bootstrap.css';
import '../../../../scss/app.scss';
import http from '../../../../services/httpService/http';
import {ThemeProvider} from "@material-ui/core/styles";
import {theme} from "../../../../helpers/muiTheme"
import './app.scss';
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import RouterEventsSubscriber from "../../../../helpers/routerEventsSubscriber";
import MainWrapper from "../router/mainWrapper";
import analyzer from "../../../../helpers/analyzer";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loaded: false
        };
    }

    componentDidMount() {
        window.addEventListener('load', () => {
            this.setState({loading: false});
            setTimeout(() => this.setState({loaded: true}), 500);
        });
        analyzer.initialize()
    }



    render() {
        const {loaded, loading} = this.state;

        return (
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <HashRouter basename='/'>
                        <ScrollToTop>
                            <Fragment>
                                {!loaded
                                && (
                                    <div className={`load${loading ? '' : ' loaded'}`}>
                                        <div className="load__icon-wrap">
                                            <svg className="load__icon">
                                                <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z"/>
                                            </svg>
                                        </div>
                                    </div>
                                )
                                }
                                {/* ROUTER */}
                                <Router/>
                                <RouterEventsSubscriber/>
                            </Fragment>
                        </ScrollToTop>
                    </HashRouter>
                </ThemeProvider>
            </Provider>
        );
    }
}
export default App;
