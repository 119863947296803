import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import useStyles from './stars.css';

const Stars = (props) => {
	const classes = useStyles();
	const handleClick = e => {
		props.onChangeFavThreat({ ...props.value, value: props.value.value });
	};

	return (
		<IconButton onClick={ handleClick }>
			{ props.value ? <StarIcon fontSize="small" className={classes.button}/> : <StarIcon fontSize="small"/> }
		</IconButton >
	);
};

export default Stars;

