import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    reportLabel:{
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: '0.31px',
        color: '#9ca0a4'
    },
}));

export {useStyles}