import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		width: '100%',
		height: '100%',
		color: `${theme.palette.secondary.main}`,
	},
	title: {
		fontWeight: 400,
		fontSize: '38px',
		lineHeight: '22px',
		textAlign: 'center',
		marginTop: '50px',
	},
	paragraphsContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		margin: '40px 0',
	},
	paragraph: {
		fontWeight: 400,
		fontSize: '22px',
		lineHeight: '29px',
	},
	logo: {
		height: '30px',
		width: '190px',
	},
}));

export default useStyles;
