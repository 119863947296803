import React, { Component } from 'react';
import Line from './line/line'
import './sections.scss'
import _ from 'lodash'

class Section extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        const { alertes, graphs } = this.props;
        return (
            <div className="section-container">
                { !_.isEmpty(this.props.title) ? <div className="section-header">
                    { this.props.title } ({ alertes.length })
                </div> : null }

                <div className="section-body">

                    {
                        alertes.map(e => {
                            return <Line key={ e.id } content={ e } graphs={ graphs } />
                        })
                    }
                </div>
            </div>
        );
    }
}


export default Section;
