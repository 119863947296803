import PlusIcon from 'mdi-react/PlusIcon';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FEATURES } from '../../../../constants/planFields';
import analyzer from '../../../../helpers/analyzer';
import { DEVICE_CATEGORY, OPEN_ACTION, VISIT_ACTION } from '../../../../helpers/analyzerConstants';
import { isDevicesRestrictionExceeded } from '../../../../helpers/restrictions';
import { downloadFromLink } from '../../../../helpers/utils';
import DeleteModal from '../../../../shared/components/deleteModal/deleteModal';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import usePrevious from '../../../../shared/customHooks/usePrevious';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { changeDeviceStatus } from '../../controller/businessLogic/recording';
import { createDeviceConfiguration } from '../../controller/services/deviceRequests';
import { editDevice as updateDevice, getDevice } from '../../controller/services/devicesRequestsMapping';
import AddDeviceWrapper from '../addDevice/addDeviceWrapper/addDeviceWrapper';
import ConfiguringProcess from '../configuringProcess/configuringProcess';
import { configDevicesManagement, CONFIGURATION_INTERVAL_IN_SECONDS, deviceConfigFields } from '../devicesManagementConfig';
import DevicesTable from '../devicesTable/devicesTable';
import {
	defaultFormDeviceType,
	deviceConfigurationByType,
	deviceTypeMapping,
	DOWNLOAD_LINK_MODE,
	LINK_DOWNLOAD_SUBMISSION_MAP,
} from './devicesPageConfiguration';
import { activeFeatures } from '../../../../constants/activeFeatures';

const DevicesPage = ({ devices, collectors, samplers, createDevice, editDevice, deleteDevice, deviceReferences, isLoading, addCollectorOnTheFly }) => {
	const dispatch = useDispatch();
	const [isEditMode, setIsEditMode] = useState(false);
	const [isCreatingModalOpen, setIsCreatingModalOpen] = useState(false);
	const [selectedDeviceToEdit, setSelectedDeviceToEdit] = useState(null);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isConfigurationProcessOpen, setIsConfigurationProcessOpen] = useState(false);
	const [configurationDeviceData, setConfigurationDeviceData] = useState(null);
	const [deviceToConfigure, setDeviceToConfigure] = useState(null);
	const [selectedDeviceToDelete, setSelectedDeviceToDelete] = useState(null);
	const [updateTriggerNumber, setUpdateTriggerNumber] = useState(0);
	const [configLink, setConfigLink] = useState(null);
	const { isAdmin } = useSelector(state => state.auth.user);

	const features = useSelector(state => state.account.features);
	const isDemo = features.includes(activeFeatures.DEMO_MODE);

	const previousDevices = usePrevious(devices);
	const ANALYZER_CATEGORY = DEVICE_CATEGORY;

	useEffect(() => {
		analyzer.event({ category: ANALYZER_CATEGORY, action: VISIT_ACTION, label: 'DEVICES' });
	}, []);

	useEffect(() => {
		previousDevices && previousDevices.length !== devices.length && setUpdateTriggerNumber(updateTriggerNumber + 1);
	});

	const handleOpenCreateDevice = () => {
		setIsCreatingModalOpen(true);
		setIsEditMode(false);
		analyzer.event({ category: ANALYZER_CATEGORY, action: OPEN_ACTION, label: 'ADD_DEVICE_FORM' });
	};

	const handleCreateDevice = deviceDetails => {
		setIsEditMode(false);
		const deviceConfig = deviceConfigurationByType[deviceDetails.deviceType](deviceDetails);
		setDeviceToConfigure(deviceDetails);
		if (deviceDetails.link) {
			setConfigLink(deviceDetails.link);
			setConfigurationDeviceData(deviceConfig);
		}

		if (deviceDetails.samplerId) {
			setConfigurationDeviceData(configuration => ({ ...configuration, configType: 'guide' }));
		}

		setIsConfigurationProcessOpen(true);
		createDevice(deviceDetails);
		setIsConfigurationProcessOpen(true);
	};

	const handleEditClick = deviceDetails => {
		setIsEditMode(true);
		setIsCreatingModalOpen(true);
		setSelectedDeviceToEdit(deviceDetails);
		analyzer.event({ category: ANALYZER_CATEGORY, action: OPEN_ACTION, label: 'EDIT_DEVICE_FORM' });
	};

	const handleDeleteClick = deviceDetails => {
		setSelectedDeviceToDelete(deviceDetails);
		setIsDeleteModalOpen(true);
	};

	const handleChangeRecordingStatus = async deviceDetails => {
		try {
			deviceDetails = changeDeviceStatus(deviceDetails);
			await updateDevice[deviceDetails.deviceType](deviceDetails._id, { status: deviceDetails.status });
			editDevice(deviceDetails);
		} catch {
			dispatch(openSnackbar('error', 'Failed to change recording status', 4000));
		}
	};

	const setConfigType = configType => {
		const brand = deviceReferences.brands.find(x => x.value === configType.brand);
		const brandLabel = brand ? brand.label : configType.brand;
		const deviceConfig = { ...configType, brandLabel };
		configType && setConfigurationDeviceData(deviceConfig);
	};

	const generateFieldsForDeviceConfig = device => {
		const deviceConfig = {};
		deviceConfigFields[device.deviceType].forEach(field => {
			deviceConfig[field] = device[field];
		});
		return deviceConfig;
	};

	const downloadGuideTable = async deviceData => {
		const deviceConfig = generateFieldsForDeviceConfig(deviceData);
		dispatch(openSnackbar('info', 'Files are being downloaded', 8000));
		try {
			const deviceDownloadLink = await LINK_DOWNLOAD_SUBMISSION_MAP[deviceData.deviceType][DOWNLOAD_LINK_MODE](deviceConfig);
			if (deviceDownloadLink.data) {
				downloadFromLink(deviceDownloadLink.data);
			}
		} catch {
			dispatch(openSnackbar('error', 'Failed to download configuration', 4000));
		}
	};

	const downloadGuideConfiguringProcess = async deviceData => {
		if (configLink) {
			downloadFromLink(configLink);
			return;
		}

		if (deviceToConfigure) {
			deviceData = deviceToConfigure;
		}

		const deviceConfig = generateFieldsForDeviceConfig(deviceData);
		try {
			const deviceDownloadLink = await createDeviceConfiguration(deviceConfig);
			if (deviceDownloadLink.data) {
				downloadFromLink(deviceDownloadLink.data);
			}
		} catch {
			dispatch(openSnackbar('error', 'Failed to download configuration', 4000));
		}
	};

	const closeAddDevice = () => {
		setIsCreatingModalOpen(false);
	};

	const closeConfiguringProcess = () => {
		setConfigurationDeviceData(null);
		setDeviceToConfigure(null);
		setConfigLink(null);
		setIsConfigurationProcessOpen(false);
	};

	const showAddDeviceButton = (!isLoading || isDemo) && isAdmin;

	return <div className="management-container">
		<div className="add-device-or-collector">
			{showAddDeviceButton && <StyleButton onClick={handleOpenCreateDevice}
				label="Add device"
				icon={<PlusIcon/>}
				className="table-add-button"
				hasRestrictions
				isRestrictionsExceededFunction={isDevicesRestrictionExceeded}
				feature={FEATURES.DEVICE}
				updateTriggerNumber={updateTriggerNumber}
			/>
			}
		</div>
		<div className="public-device-line-break"></div>
		<DevicesTable
			key={devices}
			data={devices}
			onDeleteDevice={handleDeleteClick}
			onEditDevice={handleEditClick}
			onChangeRecordingStatus={handleChangeRecordingStatus}
			onDownloadConfig={downloadGuideTable}
			isLoading={isLoading}
		/>
		{
			isCreatingModalOpen && <AddDeviceWrapper
				initialSelectedDeviceType={isEditMode ? deviceTypeMapping[selectedDeviceToEdit.deviceType] : defaultFormDeviceType}
				collectors={collectors}
				samplers={samplers}
				isEditMode={isEditMode}
				modalOpen={isCreatingModalOpen}
				onSubmit={isEditMode ? editDevice : handleCreateDevice}
				initialValues={isEditMode ? selectedDeviceToEdit : {}}
				toggle={closeAddDevice}
				deviceReferences={deviceReferences}
				setConfigType={setConfigType}
				configType={configurationDeviceData}
				addCollectorOnTheFly={addCollectorOnTheFly}
				startConfigurationProcess={() => setIsConfigurationProcessOpen(true)}
			/>
		}
		{
			isConfigurationProcessOpen && configurationDeviceData && deviceToConfigure
			&& <ConfiguringProcess
				samplingDeviceCallback={() => getDevice[deviceToConfigure.deviceType](deviceToConfigure._id)}
				intervalTimeInSecounds={CONFIGURATION_INTERVAL_IN_SECONDS}
				downloadDeviceCallBack={downloadGuideConfiguringProcess}
				selectedDeviceConfig={configurationDeviceData}
				closeConfiguringProcess={closeConfiguringProcess}
				deviceType={deviceToConfigure.deviceType}
			/>
		}
		<DeleteModal
			modalOpen={isDeleteModalOpen}
			toggle={() => setIsDeleteModalOpen(false)}
			onConfirm={() => deleteDevice(selectedDeviceToDelete)}
			message={configDevicesManagement.removeDeviceConfirmationMessage}
		/>
	</div>;
};

DevicesPage.propTypes = {
	devices: PropTypes.array,
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	createDevice: PropTypes.func,
	editDevice: PropTypes.func,
	deleteDevice: PropTypes.func,
	deviceReferences: PropTypes.object,
	isLoading: PropTypes.bool,
	addCollector: PropTypes.func,
	addCollectorOnTheFly: PropTypes.func,
};

export default DevicesPage;

