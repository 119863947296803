import React, { Component } from "react";
import BaseIntegrationForm from '../baseIntegrationForm';
import smsIcon from '../../../../../assets/integrations/sms-integration.svg';
import { SMS } from '../../../controller/constants/integrationTypes';
import './smsIntegration.scss';
import {getSmsEmailIntegrations, updateSmsIntegrations} from "../../../controller/services/smsRequests";
import inputArrayFields from "../formInputs/inputArrayFields";
import { inputTypes } from "../../../controller/constants/inputTypes";
import {FieldArray} from "redux-form";
import _ from "lodash";
import http from "../../../../../services/httpService/http";
import {activeTypes} from "../../../controller/constants/activeTypes";
import MuiPhoneNumber from "material-ui-phone-number";
import {isValidPhoneNumber} from "react-phone-number-input";
import {SmsArrayField} from "../fieldArrayComponents/smsArrayField";
import parsePhoneNumber from 'libphonenumber-js'
import EmailArrayField from "../fieldArrayComponents/emailArrayField";
import {openSnackbar} from "../../../../snackbarAlert/controller/redux/actions";
import {connect} from "react-redux";

const FORM_NAME = "sms-integration-form"
const RECIPIENTS_FIELD_ARRAY_NAME = "recipients"
const ERROR_MESSAGE = "Something went wrong, please try again";
const SUCCESS_MESSAGE = "Your alerts sms details successfully saved";

class SMSIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues:{
                recipients: []
            }
        };
        this.source = http.getCancelTokenSource();
        this.saveIntegration = this.saveIntegration.bind(this);
    }

    componentDidMount = async () => {
        try {
            const data = await getSmsEmailIntegrations({cancelToken: this.source.token});
            if (!_.isEmpty(data.integrations)){
                const recipients = data.integrations.sms.recipients.map((recipient) => {
                    const phoneNumber = parsePhoneNumber(recipient);
                    return phoneNumber.formatInternational();
                });
                this.setState({initialValues: {recipients}});
            }
        } catch (exception){
            console.log(exception.message);
        }
    }

    saveIntegration = async (formInfo) => {
        try {
            const filteredRecipients = formInfo.recipients.map((number) => {
                const phoneNumber = parsePhoneNumber(number)
                return phoneNumber.number
            });
            await this.props.updateSelectedIntegration(null);
            this.props.updateEnabledIntegration(SMS, activeTypes.ON);
            await updateSmsIntegrations({sms: filteredRecipients});
            this.props.openSnackbar("success", SUCCESS_MESSAGE, 4000);
        } catch (e) {
            this.props.openSnackbar("error", ERROR_MESSAGE, 4000);
        }
    }

    componentWillUnmount() {
        this.source.cancel('SMS integration request canceled');
    }

    SmsForm = () => {
        return(
            <div className="email-recipients">
                <FieldArray
                    name={RECIPIENTS_FIELD_ARRAY_NAME}
                    component={SmsArrayField}
                    formName={FORM_NAME}
                />
            </div>)
    }

    render() {
        const { initialValues } = this.state;
        return (
            <BaseIntegrationForm
                title="Add SMS alert notifications"
                form={FORM_NAME}
                onSubmit={this.saveIntegration}
                initialValues={initialValues}
                BaseIntegrationFormComponent={this.SmsForm}
            />
        )

    }
}

const mapDispatchToProps = {
    openSnackbar,
};

export default connect(null, mapDispatchToProps)(SMSIntegration);




