import React, { useEffect, useState } from 'react';
import './styleButton.scss';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlansCallToAction } from '../../../../assets/plans/plansCallToAction.svg';
import { setPlansFlowActivation } from '../../../../containers/plans/redux/plansActions';

function StyleButton({
	onClick,
	isDisabled,
	label,
	icon,
	hasImage,
	variant = 'contained',
	className = '',
	hasRestrictions = false,
	isRestrictionsExceededFunction,
	feature,
	updateTriggerNumber,
	...props
}) {
	const dispatch = useDispatch();
	const [shouldRestrict, setShouldRestrict] = useState(false);
	const features = useSelector(state => state.account.features);
	const isOriginalAccount = features.includes('original_customer');

	useEffect(() => {
		setShouldRestrict(!isOriginalAccount && hasRestrictions && isRestrictionsExceededFunction());
	}, [updateTriggerNumber]);

	const openPremiumPlansModal = () =>
		dispatch(setPlansFlowActivation(true, true, feature));

	return (
		<Button
			className={className}
			onClick={shouldRestrict ? openPremiumPlansModal : onClick}
			disabled={isDisabled}
			color={'primary'}
			variant={variant}
			{...props}>
			{shouldRestrict ? <PlansCallToAction style={{ marginRight: '10px' }}/> : icon}{label}
		</Button>);
}

export default StyleButton;
