import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
	container: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
	},
	button: {
		color: '#48648B',
	},
	toggleContainer: {
		margin: '0 0.5em',
	},
}));

export { useStyles };
