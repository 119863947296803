import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	graphWrapper: {
		margin: '20px 0px 20px 0px',
		height: '500px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	messageWrapper: {
		marginTop: '10%',
	},
	noDataWrapper: {
		marginTop: '8%',
	},
}));

export default useStyles;
