import { Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import LayoutForm from '../../shared/components/form/LayoutForm';
import {
	CONTACT_ME_TITLE,
	CONTACT_ME_PARAGRAPH_1,
	CONTACT_ME_PARAGRAPH_2,
} from './config';
import logo from '../../assets/contactMe/cynamics-logo-horizontal.svg';
import useStyles from './contactMe.css';
import { contactSalesTeam } from './contactMeRequests';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../snackbarAlert/controller/redux/actions';
import PropTypes from 'prop-types';

const ContactMe = ({ match }) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		const { quoteId } = match.params;
		sendRequestContactSalesTeam(quoteId);
	}, []);

	const sendRequestContactSalesTeam = async quoteId => {
		try {
			await contactSalesTeam(quoteId);
		} catch {
			dispatch(openSnackbar('error', 'Something went wrong', 4000));
		}
	};

	return (
		<div className={classes.container}>
			<LayoutForm customMessage logo={logo} logoClassName={classes.logo}>
				<Typography className={classes.title}>{CONTACT_ME_TITLE}</Typography>
				<div className={classes.paragraphsContainer}>
					<Typography className={classes.paragraph}>
						{CONTACT_ME_PARAGRAPH_1}
					</Typography>
					<Typography className={classes.paragraph}>
						{CONTACT_ME_PARAGRAPH_2}
					</Typography>
				</div>
			</LayoutForm>
		</div>
	);
};

ContactMe.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			quoteId: PropTypes.string,
		}),
	}),
};

export default ContactMe;
