import utils from '../../../services/util';
import moment from 'moment/moment';
import portsMapping from '../../topTalkers/references/ports_mapping.json';

export const DEFAULT_STRING = '-';
const UNKNOWN_PORT = -1;
export const DATE_TIME_FORMAT = 'M/D/Y HH:mm:ss';
const replaceIfEmpty = str => str ? str : DEFAULT_STRING;
export const replaceInvalidPorts = portsArr => portsArr.map(port => replaceInvalidSinglePortAndFindAlias(port)).join(', ');
export const formatDate = value => moment.unix(value).format(DATE_TIME_FORMAT);

const formatIp = (value, endpoints) => formatIpList([value], endpoints);
const formatIpList = (array, endpoints) => findIpAlias(array, endpoints);

const replaceInvalidSinglePortAndFindAlias = port => {
	if (port === UNKNOWN_PORT) {
		return DEFAULT_STRING;
	}

	return findPortAlias([port]);
};

export const findIpAlias = (ip, endpoints) => endpoints ? findItemOrListAlias(ip, endpoints, 'alias') : ip.join(', ');
export const findPortAlias = port => findItemOrListAlias(port, portsMapping, 'port_name');

// Return the alias name of each item in a list using an alias map
const findItemOrListAlias = (list = [], aliasMap = {}, aliasListField) =>
	list.map(item =>
		aliasMap[item] && aliasMap[item][aliasListField]
			? `${item}(${aliasMap[item][aliasListField]})`
			: item)
		.join(', ');

export const LOGIC_FORMAT_VALUE_MAP = {
	CONVERT_2_BASE_NUMBER_FN: value => utils.convertNumber(value, 2, true),
	CONVERT_10_BASE_NUMBER_FN: value => utils.convertNumber(value, 2, false),
	FORMAT_PORT_LIST_FN: array => (array && array.length) ? replaceInvalidPorts(array) : DEFAULT_STRING,
	FORMAT_LIST_FN: array => (array && array.length) ? array.join(', ') : DEFAULT_STRING,
	FORMAT_IPV4: (ip, endpoints) => replaceIfEmpty(formatIp(ip, endpoints)),
	FORMAT_IPV4_LIST: (array, endpoints) => (array && array.length) ? formatIpList(array, endpoints) : DEFAULT_STRING,
	FORMAT_PORT_FN: port => replaceInvalidSinglePortAndFindAlias(port),
	REPLACE_IF_EMPTY: value => replaceIfEmpty(value),
	FORMAT_DATE_TIME_FN: value => replaceIfEmpty(formatDate(value)),
	FORMAT_BOOL_FN: boolVal => boolVal,
};
