const configGroupManagement = {
	PAGE_TITLE: 'Group Management',
	CUSTOM_GROUP_LABEL: 'Custom groups',
	ASSETS_GROUP_LABEL: 'Assets',
	ALIAS_GROUP_LABEL: 'IP aliases',
	TABS_ARIA_LABEL: 'group management tabs',
	ADD_GROUP_BUTTON_LABEL: 'New group',
	ADD_ALIAS_BUTTON_LABEL: 'New alias',
	REMOVE_GROUP_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this group?',
	REMOVE_ALIAS_CONFIRMATION_MESSAGE: 'Are you sure you want to delete this alias?',
	GROUP_CUSTOM_CREATION_TYPE: 'custom',
	GROUP_ALIAS_OBJECT_TYPE: 'alias',
	GROUP_OBJECT_TYPE: 'group',
};

export {
	configGroupManagement,
};
