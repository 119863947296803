import React, { useEffect, useState } from 'react';
import { useStyles } from './publicCloudForms.css';
import SimpleSelectWithIcon from '../../../../../shared/components/dropdown/simpleSelectWithIcon';
import { cloudProvidersFormMapping } from './formsConfigurations';
import { AWS_CLOUD_PROVIDER_VALUE, cloudProviderOptions } from '../publicCloudDeviceConfiguration';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import store from '../../../../app/controller/redux/store/store';

const PublicCloudDeviceFormContainer = ({
	isEditMode,
	onSubmit,
	startConfigurationProcess,
	initialValues: selectedDeviceToEdit,
	isSubmittingForm,
}) => {
	const [cloudProviderSelected, setCloudProviderSelected] = useState(AWS_CLOUD_PROVIDER_VALUE);

	const handleFormSubmit = async values => {
		await onSubmit(values);
		!isEditMode && startConfigurationProcess();
	};

	const generateInitialValues = initialValues => {
		const mergedInitialValues = isEditMode ? { ...selectedDeviceToEdit, ...initialValues } : initialValues;
		return mergedInitialValues;
	};

	const {
		formComponent: FormComponent,
		additionalProps,
		initialValues,
		form,
	} = cloudProvidersFormMapping[cloudProviderSelected];
	const mergedInitialValues = generateInitialValues(initialValues);
	const formAdditionalProps = { ...additionalProps, initialValues: mergedInitialValues };

	useEffect(() => {
		const allForms = store.getState().form;
		if (selectedDeviceToEdit.cloudProvider) {
			setCloudProviderSelected(selectedDeviceToEdit.cloudProvider.toLowerCase());
			return;
		}

		// On re-enter find the dirtiest form and make him the current form
		let longestFormLength = 2;
		Object.keys(cloudProvidersFormMapping).forEach(cloud => {
			if (allForms[cloud] && Object.keys(allForms[cloud].values).length > longestFormLength) {
				setCloudProviderSelected(cloud);
				longestFormLength = Object.keys(allForms[cloud].values).length;
			}
		});
	}, []);

	const classes = useStyles();
	return (
		<>
			<Typography className={classes.titleStyle}>
                Device properties
			</Typography>
			<div className={classes.cloudProviderSelector}>
				<SimpleSelectWithIcon
					items={cloudProviderOptions}
					value={cloudProviderSelected}
					onChange={value => setCloudProviderSelected(value)}
					InputProps={{ style: { height: '45px', fontWeight: 'bold' } }}
					isDisabled={isEditMode}
				/>
			</div>
			{cloudProviderSelected
				&& <FormComponent
					form={form}
					onSubmit={handleFormSubmit}
					isEditMode={isEditMode}
					isSubmittingForm={isSubmittingForm}
					{...formAdditionalProps}
				/>}</>
	);
};

PublicCloudDeviceFormContainer.propTypes = {
	isEditMode: PropTypes.bool,
	onSubmit: PropTypes.func,
	startConfigurationProcess: PropTypes.func,
	initialValues: PropTypes.object,
	isSubmittingForm: PropTypes.bool,
};

export default PublicCloudDeviceFormContainer;

