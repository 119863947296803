import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../helpers/muiTheme';

export default makeStyles(() => ({
	messageWrapper: {
		marginTop: '10%',
	},
	analysisButton: {
		color: theme.palette.text.secondary,
	},
	analysisButtonLabel: {
		marginLeft: '6px',
		fontSize: '16px',
	},
	analysisButtonIconDisabled: {
		fill: theme.palette.stepsText.notActive,
	},
	analysisButtonIconActive: {
		fill: theme.palette.text.secondary,
	},
	tooltipHeader: {
		fontWeight: 600,
	},
	tooltipDotAbove: {
		color: theme.palette.graph.aboveThreshold,
		fontWeight: 'bold',
		marginLeft: '6px',
	},
	tooltipDotBelow: {
		color: theme.palette.graph.belowThreshold,
		fontWeight: 'bold',
		marginLeft: '6px',
	},
	tooltipValue: {
		marginLeft: '4px',
	},
	tooltipDate: {
		marginLeft: '7px',
	},
	loader: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '136px',
	},
}));

