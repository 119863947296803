import React from "react";
import step7Side from "../../../../../../../assets/walkthrough/analysis/steps/step7Side.png";
import step7Main from "../../../../../../../assets/walkthrough/analysis/steps/step7Main.png";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step7.css";
import MainPanelTemplateV2
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const TITLE = "Analysis Bar";
const DESCRIPTION = "View Top Talker details by clicking on the graph.";

const Step7 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={step7Side}>
            <MainPanelTemplateV2 title={TITLE}
                                 image={<img src={step7Main} className={classes.image}/>}
                                 description={DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step7;