import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		justifyContent: 'start',
		alignItems: 'center',
		width: 'auto',
		height: 'auto',
		gap: '8px',
	},
}));

export default useStyles;
