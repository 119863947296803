import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { format, numericality, required, validateForm } from 'redux-form-validators';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../shared/components/form/TextField/TextField';
import Loader from '../../../../shared/components/Loader/Loader';
import { FormWrapper } from '../../../../shared/components/simpleTable/simpleFormContainer';
import SimpleModal from '../../../../shared/components/simpleTable/simpleModal';
import DeviceSubForm from '../deviceConfig/deviceForm';

// TODO: Add destroy() function when modal is closed.
const PublicDeviceForm = ({
	isEditMode,
	modalOpen,
	reset,
	initialValues,
	onSubmit,
	handleSubmit,
	change,
	setConfigType,
	deviceReferences,
	isSubmittingForm,
	configType,
	form,
}) => {
	useEffect(() => {
		modalOpen === false && reset();
	}, [modalOpen]);

	const deviceSubFormInitialValues = !isEditMode && configType ? { ...{ initialValues }, brand: configType.brand, model: configType.value } : initialValues;
	return <form onSubmit={handleSubmit(onSubmit)} className="modal-form">
		<FormWrapper title="Device properties" wrapperStyle={{}}>
			<Field
				component={TextField}
				required
				label="Device name"
				className="form-field"
				name="name"
				type="text"
				margin="none"
			/>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Field
						component={TextField}
						required
						label="Device public IP"
						className="form-field"
						name="IP"
						type="text"
						margin="none"
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						component={TextField}
						required
						label="Sampling rate"
						className="form-field"
						name="samplingRate"
						type="number"
						margin="none"
					/>
				</Grid>
			</Grid>

			<DeviceSubForm
				mode={isEditMode ? 'edit' : 'add'}
				initialValues={deviceSubFormInitialValues}
				deviceReferences={deviceReferences}
				onChangeConfig={e => {
					Object.keys(e).map((a, i) => change(a, Object.values(e)[i]));
				}}
				onDisapear={() => {
				}}
				setConfigType={setConfigType}
				form={form}
			/>

			<Field component={TextField} name="status" type="hidden" hidden />
		</FormWrapper>

		<div style={{ marginBottom: '30px' }}>
			{isSubmittingForm ? (
				<CircularProgress color="primary" />
			) : (
				<SubmitButton label={isEditMode ? 'Save' : 'Add firewall'} />
			)}
		</div>
	</form>;
};

// Redux Form
const ipValidator = /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;
const validate = validateForm({
	IP: [required({ msg: 'Please enter IP' }), format({ with: ipValidator, msg: 'Please enter a valid IP' })],
	name: [required({ msg: 'Please enter device name' })],
	samplingRate: [required({ msg: 'Please enter a Sampling Rate' }), numericality({ '>': 0 })],
	brand: [required({ msg: 'Please enter the Brand' })],
	model: [required({ msg: 'Please enter the Model' })],
});

export default reduxForm({
	destroyOnUnmount: false,
	enableReinitialize: true,
	keepDirtyOnReinitialize: true,
	validate,
})(PublicDeviceForm);
