import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import EyeIcon from "../../../../../assets/icons/eye-icon.svg";

import { Tooltip } from "@material-ui/core";
class InsightMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
    this.runQuery = this.runQuery.bind(this);
    this.showMore = this.showMore.bind(this);
  }
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: { left: event.clientX, top: event.clientY } });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  showMore = () => {
    this.setState({ anchorEl: null });
    this.props.showMore(this.props.anchorEl);
  };

  runQuery = async () => {
    this.setState({ anchorEl: null });
    this.props.runQuery(this.props.anchorEl);
  };

  render() {
    const { anchorEl } = this.state;
    return (
      <>
        <Tooltip title="View in analysis">
          <IconButton size="small" onClick={() => this.runQuery()}>
            <img src={EyeIcon} alt="Run query icon" />
          </IconButton>
        </Tooltip>
      </>
    );
  }
}

export default InsightMenu;
