import { useEffect, useState } from 'react';

const useFetch = fetchFunction => {
	const [data, setData] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	const fetchData = () => {
		fetchFunction()
			.then(data => setData(data))
			.catch(error => setError(error))
			.finally(setIsLoading(false));
	};

	useEffect(() => {
		setIsLoading(true);
		setData(null);
		setError();
		fetchData();
	}, [fetchFunction]);

	return { data, isLoading, error, fetchData };
};

export default useFetch;
