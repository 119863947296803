import {STORE_COLLECTOR_DEVICES, STORE_PUBLIC_CLOUD_DEVICES, STORE_PUBLIC_DEVICES} from "./actionTypes";
import action from "../../../app/controller/redux/middleware";
import {getLightweightCollectorDevices} from "../services/collectorDeviceRequests";
import {getLightweightDevices} from "../services/deviceRequests";
import {getLightweightPublicCloudDevices} from "../services/publicCloudDeviceRequests";

const getLiteDevices = () => {
    return action(async (dispatch, getState) => {
        const devices = await getLightweightDevices();
        dispatch(storePublicDevices(devices));
    });
}

const getLitePublicCloudDevices = () => {
    return action(async (dispatch, getState) => {
        const devices = await getLightweightPublicCloudDevices();
        dispatch(storePublicCloudDevices(devices));
    });
}

const getLiteCollectorDevices = () => {
    return action(async (dispatch, getState) => {
        const devices = await getLightweightCollectorDevices();
        dispatch(storeCollectorDevices(devices));
    });
}
const storePublicDevices = (devices)=>{
    return { type : STORE_PUBLIC_DEVICES , payload : devices};
}
const storeCollectorDevices = (collectorDevices)=>{
    return { type : STORE_COLLECTOR_DEVICES, payload : collectorDevices};
}
const storePublicCloudDevices = (publicCloudDevices) => {
    return { type : STORE_PUBLIC_CLOUD_DEVICES, payload : publicCloudDevices};
}

export {
    storePublicDevices,
    storeCollectorDevices,
    storePublicCloudDevices,
    getLiteDevices,
    getLiteCollectorDevices,
    getLitePublicCloudDevices
}