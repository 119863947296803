import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	wrapper: {
		borderRadius: '5px',
	},
	head: {
		backgroundColor: theme.palette.secondary.main,
		height: '45px',

		'& th:first-child': {
			borderRadius: '5px 0 0 0',
		},

		'& th:last-child': {
			borderRadius: '0 5px 0 0',
		},
	},
	headCell: {
		padding: '0 16px',
	},
	headerTextColor: {
		fontWeight: 'bold',
		fontSize: '16px',
		color: 'white',
	},
}));

export default useStyles;
