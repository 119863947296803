import _, { chunk } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toLocaleDateString } from '../../../../helpers/datesHelper';
import FieldFactory from '../../../../shared/components/simpleTable/fields/fieldsFactory';
import SimpleTable from '../../../../shared/components/simpleTable/simpleTable';
import SkeletonTable from '../../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import { MAX_ROW_IN_TABLE } from '../../../app/view/tableConfig/const';
import { isDeviceActive } from '../../controller/businessLogic/activity';
import { parseDeviceByType } from '../deviceManagementParsers';
import useStyles from './devicesTable.css';
import { additionalDataFieldsMapping, deviceTypeDisplayNames, INACTIVE_TOOLTIP_CONTENT, tableFieldsDisplayNames } from './devicesTableConfig';
import { deviceTypes } from '../devicesManagementConfig';
import { AWS_CLOUD_PROVIDER_VALUE, GOOGLE_CLOUD_PROVIDER_VALUE } from '../publicCloudDevice/publicCloudDeviceConfiguration';
import { useSelector } from 'react-redux';

const CLOUD_DEVICE_PROPERTIES = {
	[AWS_CLOUD_PROVIDER_VALUE]: {
		hasDownload: false,
	},
	[GOOGLE_CLOUD_PROVIDER_VALUE]: {
		hasDownload: true,
	},
};

const hasDownloadPhysicalDevice = deviceInfo => {
	const labelModel = _.has(deviceInfo, 'model') && (!_.isEmpty(deviceInfo.model))
		? deviceInfo.model.split('_')[1] : '';
	return _.has(deviceInfo, 'brand') && ![deviceInfo.brand, labelModel].includes('other');
};

const hasDownloadCloudDevice = deviceInfo => CLOUD_DEVICE_PROPERTIES[deviceInfo.cloudProvider.toLowerCase()].hasDownload;

const DEVICE_TYPE_DOWNLOAD_MAPPING = {
	[deviceTypes.PUBLIC_DEVICE]: hasDownloadPhysicalDevice,
	[deviceTypes.CORE_DEVICE]: hasDownloadPhysicalDevice,
	[deviceTypes.CLOUD_DEVICE]: hasDownloadCloudDevice,
};

const DevicesTable = ({ onEditDevice, onDeleteDevice, onChangeRecordingStatus, onDownloadConfig, data, isLoading }) => {
	const classes = useStyles();
	const [modifiedData, setModifiedData] = useState({});
	const { isAdmin } = useSelector(state => state.auth.user);

	const isDeviceDeletable = () => true;

	useEffect(() => {
		modifyDataToDisplay();
	}, [data]);

	const modifyDataToDisplay = () => {
		const updatedDevices = JSON.parse(JSON.stringify(data));
		updatedDevices.map(device => {
			device = parseDeviceByType[device.deviceType](device);
			device.displayUpdatedAt = toLocaleDateString(device.updatedAt, true);
			device.additionalData = additionalDataFieldsMapping[device.deviceType].map(field => ({ field: tableFieldsDisplayNames[field], value: device[field] }));
			device.additionalData = device.additionalData.filter(field => field.value);
			device.deviceHasDownload = DEVICE_TYPE_DOWNLOAD_MAPPING[device.deviceType](device);
			return device;
		});
		setModifiedData(updatedDevices);
	};

	const deviceTypeMapper = deviceType => deviceTypeDisplayNames[deviceType];

	const columns = [
		FieldFactory.createTextField('IP', 'Device IP', false, true, true, true),
		FieldFactory.createTextField('name', 'Device Name', false, true, true, true),
		FieldFactory.createTextField('deviceType', 'Device Type', false, true, true, true, deviceTypeMapper),
		FieldFactory.createTextField('brandName', 'Device Brand', false, true, true, true),
		FieldFactory.createTextField('modelName', 'Device Model', false, true, true, true),
		FieldFactory.createActivityFieldWithTooltip('activity', 'Activity', isDeviceActive, 'ACTIVE', 'INACTIVE', INACTIVE_TOOLTIP_CONTENT),
		FieldFactory.createAdditionalDataPopoverField('additionalData', 'Additional Data'),
		FieldFactory.createAddEditField(
			'editOrRemove',
			onEditDevice,
			onDeleteDevice,
			onDownloadConfig,
			isDeviceDeletable,
			onChangeRecordingStatus,
			'device-table-row-actions',
			isAdmin,
		),
	];

	if (isLoading || !data) {
		return <SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} className={classes.table} />;
	}

	return <SimpleTable
		totalItemsInPage={MAX_ROW_IN_TABLE}
		pages={chunk(data, MAX_ROW_IN_TABLE).length}
		columns={columns} data={modifiedData} isResizable={false} />;
};

DevicesTable.propTypes = {
	data: PropTypes.array,
	onChangeRecordingStatus: PropTypes.func,
	onDeleteDevice: PropTypes.func,
	onDownloadConfig: PropTypes.func,
	onEditDevice: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default React.memo(DevicesTable);

