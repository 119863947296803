import {useStyles} from "./mainPanelTemplateV3.css";
import {Grid,Typography} from "@material-ui/core";
import React from "react";

const MainPanelTemplateV3 = ({title, image, description, subDescriptionImage, imageAlign="center"}) => {
    const classes = useStyles({imageAlign});
    return (
        <Grid container className={classes.root} spacing={0}>
            <Grid item container spacing={0} xs={5} alignContent={"center"}>
                <Grid item>
                    <Typography className={classes.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.description}>
                        {description}
                    </Typography>
                    {subDescriptionImage}
                </Grid>
            </Grid>
            <Grid item xs={7} className={classes.image}>
                {image}
            </Grid>
        </Grid>
    )
}

export default MainPanelTemplateV3;