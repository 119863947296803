import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    arrow: {
        content: "''",
        position: "absolute",
        top: "50%",
        right: "100%", /* To the left of the tooltip */
        marginTop: "-5px",
        borderWidth: "10px",
        borderStyle: "solid",
        borderColor: "transparent white transparent transparent",
        filter: "drop-shadow(-1px 1px 0px rgba(0, 0, 0, 0.5))",


    },

    paper: {
        padding: '11px 25px',
        boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.5)"
    },

    bubbleTitle:{
        fontSize: '18px'
    },
    bubbleSubtitle:{
        fontSize: '16px'
    },

    attributeApplied:{
        color: 'rgba(0, 0, 0, 0.38)',
        textDecoration: 'line-through'
    },

    popperMargin: {
        marginLeft: '25px',
        zIndex: '1'
    }

}));

export {useStyles}