import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './severityDisplay.css';
import { calculateSeverity } from '../../services/utils';

const propTypes = {
	severity: PropTypes.number.isRequired,
	config: PropTypes.object.isRequired,
};

function Severity({ severity, config }) {
	const [iconData, setIconData] = useState(config.default);
	const classes = useStyles(iconData);

	useEffect(() => {
		const severityKey = calculateSeverity(config, severity);
		const severityData = config[severityKey];
		severityData && setIconData(severityData);
	}, [severity, config]);

	return (
		<div className={classes.root}>
			<img className={classes.icon} src={iconData.icon}/>
			<span className={classes.label}>{iconData.label}</span>
		</div>
	);
}

Severity.prototype = propTypes;
Severity.propTypes = {
	severity: PropTypes.number,
	config: PropTypes.object,
};
export default Severity;
