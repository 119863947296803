import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableRowInsight from '../row/tableRowInsights';

class TableHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { column, rows, insightData } = this.props;
		return (
			<TableContainer style={{ width: 450 }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell />
							{ column.map(c => <TableCell align="left" style={{ width: c.width }} key={c.name} >{ c.name }</TableCell>) }
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>
						{ rows.map(row => (
							<TableRowInsight
								insightData={ insightData }
								queryType={ column[2] }
								key={ row.from } row={ row } />
						)) }
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
}

export default TableHeader;
