import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from '../../../../assets/ip-more-info.svg';
import CopyButtonComponent from '../../../../shared/components/copyButtonComponent/copyButtonComponent';

import SpanWithTranslation from './spanWithTranslation/spanWithTranslation';

import useStyles from '../collapsibleRecordComponent.css';
import EnrichmentPopoverWrapper from '../../../../shared/components/enrichmentPopoverWrapper/enrichmentPopoverWrapper';
import { formatBase10 } from '../../formatters';

const CollapsibleRecordSummary = ({
	value,
	valueTranslation,
	count,
}) => {
	const classes = useStyles();

	return (
		<Grid
			container
			display={'inline-flex'}
			width={'100%'}
			justifyContent={'space-between'}
			xs={12}
		>
			<Grid item xs={5}>
				<SpanWithTranslation translation={valueTranslation} additionalClasses={classes.summaryValues}>
					{value}
				</SpanWithTranslation>
			</Grid>
			<Grid item xs={3}>
				<Typography className={`${classes.summaryValues} ${classes.count}`}>
					{formatBase10(count)}
				</Typography>
			</Grid>
			<Grid item container justifyContent={'flex-end'} xs={3} className={classes.icons}>
				<EnrichmentPopoverWrapper ip={value} mac={valueTranslation}>
					<InfoIcon className={classes.infoIcon}/>
				</EnrichmentPopoverWrapper>
				<div className={classes.copy}>
					<CopyButtonComponent
						valueToCopy={value}
						successMessage={'Copied!'}
						labelSize={'xs'}
						iconWidth={20}
						iconHeight={20}
					/>
				</div>
			</Grid>
		</Grid>
	);
};

CollapsibleRecordSummary.propTypes = {
	count: PropTypes.number.isRequired,
	value: PropTypes.string.isRequired,
	valueTranslation: PropTypes.string,
};

export default CollapsibleRecordSummary;
