import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	toggleText: {
		cursor: 'pointer',
		fontSize: '14px',
		fontWeight: 700,
		color: theme.palette.stepsText.active,
	},
	popoverBox: {
		minWidth: '160px',
		maxWidth: '900px',
		maxHeight: '210px',
		fontSize: '14px',
		paddingBottom: '6px',
	},
	listTitle: {
		background: '#DBDFE0',
		fontWeight: 600,
	},
	listDivider: {
		margin: '0 6px',
	},
	closeButton: {
		width: 'unset',
		position: 'absolute',
		top: '5px',
		right: '5px',
		cursor: 'pointer',
		height: '12px',
	},
}));

export default useStyles;
