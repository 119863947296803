import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from './setupPhone.css';
import PhoneNumberForm from './phoneNumberForm/phoneNumberForm';
import { phoneUpdate, verifyPhone } from '../requests';
import PhoneVerificationForm from './phoneVarificationForm/phoneVarificationForm';
import { useDispatch } from 'react-redux';
import {
	MODAL_TYPE_TO_PHONE_SETUP_ERROR_TEXT,
	CODE_INCORRECT_MESSAGE,
	CODE_FAILED_MESSAGE,
} from './setupPhoneConfig';
import { openSnackbar } from '../../snackbarAlert/controller/redux/actions';
import { SEND_CODE_LABEL, VERIFY_CODE_LABEL } from './mfaSetUpTexts';
import { updateUserInfo } from '../../app/controller/redux/auth/authActions';

const SetupPhone = ({ onSuccessCallback, onErrorCallback, modalType }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [isCodeSent, setIsCodeSent] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState();

	const handleSetCode = async phoneNumber => {
		try {
			await phoneUpdate(phoneNumber);
			setIsCodeSent(true);
			setPhoneNumber(phoneNumber);
			const dataToUpdate = {
				phone: '',
			};
			dispatch(updateUserInfo(dataToUpdate));
		} catch (e) {
			dispatch(openSnackbar('error', CODE_FAILED_MESSAGE));
		}
	};

	const handleVerifyCode = async verificationCode => {
		try {
			await verifyPhone(verificationCode);
			const dataToUpdate = {
				phone: phoneNumber,
			};
			dispatch(updateUserInfo(dataToUpdate));
			onSuccessCallback();
		} catch (error) {
			onErrorCallback(error);
			if (error.status === 400) {
				dispatch(openSnackbar('error', CODE_INCORRECT_MESSAGE));
			} else {
				dispatch(openSnackbar('error', MODAL_TYPE_TO_PHONE_SETUP_ERROR_TEXT[modalType]));
			}
		}
	};

	return (
		<Paper elevation={0} className={classes.paper}>
			<Grid container direction={'column'} className={classes.container}>
				<Grid item xs={6} className={classes.fullWidth}>
					<Grid container direction={'row'}>
						<Grid item xs={12}>
							<Typography style={{ marginBottom: '4px' }}>
								{SEND_CODE_LABEL}
							</Typography>
						</Grid>
						<PhoneNumberForm
							onSubmitCallback={handleSetCode}
							className={classes.fullWidth}
						/>
					</Grid>
				</Grid>
				<Grid xs={1}></Grid>
				<Grid xs={5} className={classes.fullWidth} >
					<Grid item xs={12}>
						<Typography style={{ marginBottom: '12px' }}>
							{VERIFY_CODE_LABEL}
						</Typography>
					</Grid>
					<PhoneVerificationForm
						onSubmitCallback={handleVerifyCode}
						isDisabled={!isCodeSent}
						className={classes.fullWidth}
					/>
				</Grid>
			</Grid>
		</Paper>);
};

SetupPhone.propTypes = {
	onErrorCallback: PropTypes.func,
	onSuccessCallback: PropTypes.func.isRequired,
};

SetupPhone.defaultProps = {
	onErrorCallback() {},
};

export default SetupPhone;

