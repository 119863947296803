
const createDevice = (name, value, direction) => { return [{ label: name, value: value, type: 'device', direction: direction }] }


const categorizationPayload = [
    { label: 'ipProtocols', value: 'protocol', getValue: (originalQuery => originalQuery["ipProtocols"].incIpProtocols) },
    { label: 'destPorts', value: 'destination port', getValue: (originalQuery => originalQuery["destPorts"].incDestPorts) },
    { label: 'srcPorts', value: 'source port', getValue: (originalQuery => originalQuery["srcPorts"].incSrcPorts) }];

export {
    createDevice,
    categorizationPayload
}