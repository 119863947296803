import React, {useEffect, useState} from 'react';
import {Field, formValueSelector, getFormValues, reduxForm} from 'redux-form';
import {
    isPasswordStrong, oldPasswordsMatch, wrongCurrentPasswordError,
    passwordMinLength,
    passwordsMatch,
    required
} from "../../../shared/components/form/Validation/FieldLevelValidation";
import {Grid} from "@material-ui/core";
import {connect, useSelector} from "react-redux";
import PasswordTextField from "../../../shared/components/PasswordField/passwordField";
import PasswordTooltip from "../../invitations/components/PasswordTooltip/PasswordTooltip";
import {getPasswordAttrs} from "../../resetPassword/passwordUtils";
import StrengthMeter from "../../../shared/components/PasswordField/passwordMeter";
import ActionButtons from "../sharedComponents/actionButtons";
import {useStyles} from "./changePasswordForm.css";


const INITIAL_VALUES = {
    password: null
}

const INITIAL_VALIDATE_CURRENT_PASSWORD_FUNCTIONS = [
    required,
    oldPasswordsMatch
]

let ChangePasswordForm = ({invalid, submitting, onSubmit, handleSubmit, cancelFormChanges})=> {
    const [tooltipAnchor, setTooltipAnchor] = useState(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const [isCurrentPasswordMatch, setIsCurrentPasswordMatch] = useState(true);
    const newPassword = useSelector(state => getFormValues('change-password-form')(state)['password']);
    const classes = useStyles();
    const [oldPasswordValidateList, setOldPasswordValidateList] = useState(INITIAL_VALIDATE_CURRENT_PASSWORD_FUNCTIONS);

    useEffect(()=>{
        if(isCurrentPasswordMatch){
            setOldPasswordValidateList(INITIAL_VALIDATE_CURRENT_PASSWORD_FUNCTIONS);
        } else {
            setOldPasswordValidateList([...INITIAL_VALIDATE_CURRENT_PASSWORD_FUNCTIONS, wrongCurrentPasswordError]);
        }
    }, [isCurrentPasswordMatch])

    const showTooltip = (event) => {
        if (event.type === "click") {
            setTooltipAnchor(event.currentTarget.childNodes[1]);
            setIsTooltipOpen(true)
        } else {
            setIsTooltipOpen(null);
            setIsTooltipOpen(false);
        }
    }

    const handleChangePassword = async (value) => {
        try {
            await onSubmit(value);
            setIsCurrentPasswordMatch(true);
        } catch (e) {
            setIsCurrentPasswordMatch(false);
        }
    }

    const disableInvalidation = () => {
        if (!isCurrentPasswordMatch) {
            setIsCurrentPasswordMatch(true);
        }
    }

    return (
        <form onSubmit={handleSubmit(handleChangePassword)} className={classes.formContainer}>
            <Grid item container justify={"center"} className={classes.wrapper} >
                   <Field component={PasswordTextField}
                          name={"oldPassword"}
                          label={"Current Password"}
                          required={true}
                          validate={oldPasswordValidateList}
                          onChange={disableInvalidation}
                   />
                    <Field component={PasswordTextField}
                           name={"password"}
                           label={"New Password"}
                           required={true}
                           validate={[required, passwordsMatch, oldPasswordsMatch, isPasswordStrong, passwordMinLength]}
                           onClick={showTooltip}
                           onBlur={showTooltip}
                    />

                <PasswordTooltip placement={"right"} anchor={tooltipAnchor} isOpen={isTooltipOpen} passwordContent={getPasswordAttrs(newPassword)}/>
                {newPassword && newPassword.length !== 0 && isTooltipOpen&& <StrengthMeter password={newPassword}/>}

                <Field component={PasswordTextField}
                       name={"passwordConfirm"}
                       label={"Confirm Password"}
                       required={true}
                       validate={[required, passwordsMatch, passwordMinLength, isPasswordStrong]}
                />

                <ActionButtons onCancel={cancelFormChanges} isSubmitting={submitting}/>
            </Grid>
        </form>
    );
}

ChangePasswordForm =  reduxForm({
    form: 'change-password-form',
    initialValues: INITIAL_VALUES
})(ChangePasswordForm);


export default ChangePasswordForm;

