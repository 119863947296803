import React, {Component} from "react";
import _ from 'lodash';

import BaseIntegrationForm from '../baseIntegrationForm';
import {EMAIL} from '../../../controller/constants/integrationTypes';
import './emailIntegration.scss';
import {updateEmailIntegrations} from "../../../controller/services/emailRequests";
import {getSmsEmailIntegrations} from "../../../controller/services/smsRequests";
import inputArrayFields from "../formInputs/inputArrayFields";
import {FieldArray} from "redux-form";
import {inputTypes} from "../../../controller/constants/inputTypes";
import http from "../../../../../services/httpService/http";
import {activeTypes} from "../../../controller/constants/activeTypes";
import EmailArrayField from "../fieldArrayComponents/emailArrayField"
import {openSnackbar} from "../../../../snackbarAlert/controller/redux/actions";
import {connect} from "react-redux";

const FORM_NAME = "email-integration-form"
const RECIPIENTS_FIELD_ARRAY_NAME = "recipients"
const ERROR_MESSAGE = "Something went wrong, please try again";
const SUCCESS_MESSAGE = "Your email details for notifications successfully saved";

class EmailIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                recipients: []
            }
        };
        this.source = http.getCancelTokenSource();
        this.saveIntegration = this.saveIntegration.bind(this);
    }

    componentDidMount = async () => {
        try {
            const data = await getSmsEmailIntegrations({cancelToken: this.source.token});
            if (!_.isEmpty(data.integrations)) {
                const recipients = data.integrations.email.recipients;
                this.setState({initialValues: {recipients}});
            }
        } catch (exception) {
            console.log(exception.message);
        }
    }

    saveIntegration = async (formData) => {
        const emails = formData.recipients.filter((email) => !_.isEmpty(email))
        try {
            await updateEmailIntegrations({email: emails});
            this.props.openSnackbar("success", SUCCESS_MESSAGE , 4000);
        }catch (e) {
            this.props.openSnackbar("error", ERROR_MESSAGE , 4000);
        }
        await this.props.updateSelectedIntegration(null);
        this.props.updateEnabledIntegration(EMAIL, activeTypes.ON);

    }

    componentWillUnmount() {
        this.source.cancel('Daily reports request canceled');
    }

    EmailReportForm = () => {
        return (
            <div className="email-recipients">
                <FieldArray
                    name={RECIPIENTS_FIELD_ARRAY_NAME}
                    component={EmailArrayField}
                    formName={FORM_NAME}
                />
            </div>)
    }

    render() {
        const {initialValues} = this.state;
        return (
            <BaseIntegrationForm
                title="Add email alert notifications"
                form={FORM_NAME}
                onSubmit={this.saveIntegration}
                initialValues={initialValues}
                BaseIntegrationFormComponent={this.EmailReportForm}
            />
        )
    }
}

const mapDispatchToProps = {
    openSnackbar,
};

export default connect(null, mapDispatchToProps)(EmailIntegration);
