import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import _ from 'lodash';

import {
	SuspiciousConnectionsTop,
	SuspiciousPortsDstTop,
	SuspiciousPortsSrcTop,
	SuspiciousIpsTop,
} from '../suspicious/suspicious';
import './popoverPopupState.scss';

class PopoverPopupState extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: this.props.isOpen || false,
			anchor: { left: 60, top: 60 },
		};
	}

	changeStatus = e => {
		e.preventDefault();
		this.setState({ isOpen: !this.state.isOpen, anchor: { left: e.clientX - 500, top: e.clientY } });
	};

	render() {
		const { anchor, isOpen } = this.state;
		const { content } = this.props;

		return (
			<>
				<p className="threat-details-toggle-text" onClick={ this.changeStatus }>{`${this.props.title} ${isOpen ? '↑' : '↓'}`}</p>
				<div className="pop-over-layout">

					<Popover
						onClose={ this.changeStatus }
						open={ isOpen }
						anchorPosition={ anchor }
						anchorReference="anchorPosition"

					>
						<div className="explore-menu-toogle" >
							{ _.has(content, 'suspiciousConnectionsTop') && !_.isEmpty(content.suspiciousConnectionsTop) ? <SuspiciousConnectionsTop title={ 'Top Suspicious Connections' } array={ content.suspiciousConnectionsTop } /> : null }
							<div>
								{ _.has(content, 'suspiciousPortsDstTop') && !_.isEmpty(content.suspiciousPortsDstTop) ? <SuspiciousPortsDstTop title={ 'Top Suspicious Dest Ports' } array={ content.suspiciousPortsDstTop } /> : null }
								{ _.has(content, 'suspiciousPortsSrcTop') && !_.isEmpty(content.suspiciousPortsSrcTop) ? <SuspiciousPortsSrcTop title={ 'Top Suspicious Src Ports' } array={ content.suspiciousPortsSrcTop } /> : null }
							</div>
							<div>
								{ _.has(content, 'suspiciousIpsSrcTop') && !_.isEmpty(content.suspiciousIpsSrcTop) ? <SuspiciousIpsTop title={ 'Top Suspicious Src IPs' } connections={ content.suspiciousIpsSrcTop } /> : null }
								{ _.has(content, 'suspiciousIpsDstTop') && !_.isEmpty(content.suspiciousIpsDstTop) ? <SuspiciousIpsTop title={ 'Top Suspicious Dest IPs' } connections={ content.suspiciousIpsDstTop } /> : null }
							</div>
						</div>

					</Popover>

				</div >
			</>

		);
	}
}

PopoverPopupState.propTypes = {
	content: PropTypes.shape({
		suspiciousConnectionsTop: PropTypes.any,
		suspiciousIpsDstTop: PropTypes.any,
		suspiciousIpsSrcTop: PropTypes.any,
		suspiciousPortsDstTop: PropTypes.any,
		suspiciousPortsSrcTop: PropTypes.any,
	}),
	isOpen: PropTypes.bool,
	title: PropTypes.string,
};

export default PopoverPopupState;
