import moment from 'moment';

export const defaultMapping = 3;

export const timeRangeOptions = [
    {
        label: "10 minutes",
        amount: 10,
        timeUnit: 'minutes',
        value: 1
    },
    {
        label: "30 minutes",
        amount: 30,
        timeUnit: 'minutes',
        value: 2
    },
    {
        label: "1 hour",
        amount: 1,
        timeUnit: 'h',
        value: 3
    },
    {
        label: "2 hours",
        amount: 2,
        timeUnit: 'h',
        value: 4
    },
    {
        label: "3 hours",
        amount: 3,
        timeUnit: 'h',
        value: 5
    },
    {
        label: "4 hours",
        amount: 4,
        timeUnit: 'h',
        value: 6
    }
]


export const getTimeRangeByValue = (timeRangeValue) => {
    const mappedTimeRange = timeRangeOptions.find(e => e.value === timeRangeValue);
    const { amount, timeUnit } = mappedTimeRange;
    return {
        timeFrom: moment().subtract(amount, timeUnit),
        timeTo: moment()
    }
};


