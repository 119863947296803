import { OPEN_BANNER, CLOSE_BANNER } from './actionTypes';

const openBanner = (text, pagesToAppear, children, variant, placement, enableClose) => ({
	type: OPEN_BANNER,
	payload: { text, pagesToAppear, children, variant, placement, enableClose },
});

const closeBanner = dismissed => ({
	type: CLOSE_BANNER,
	payload: { dismissed },
});

export { openBanner, closeBanner };
