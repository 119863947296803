import React, { Component } from 'react';
import makeReduxTimeUnit from './maker/timeUnitMaker';
import './timeUnit.scss';
import _ from 'lodash';


class TimeUnit extends Component {


    onChange = (newValue) => {
        this.setState({ timeUnit: newValue })
        this.props.onChange(newValue)
    }
    render() {

        const { value, hasUnit, input } = this.props;
        
        const NumberValue = parseFloat(value);
        return (
            <div className="input-time-unit">
                <input
                    onChange={ this.onChange }
                    className="time-units-input"
                    type="number"
                    min={'1'}
                    value={ value }
                />
                { hasUnit ? <div className="unit-value">Secs</div> : null }
            </div>
        );
    }
}

export default makeReduxTimeUnit(TimeUnit);