import PropTypes from 'prop-types';
import React from 'react';
import { ToggleButton } from '@material-ui/lab';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useStyles } from './toggleButtons.css';

const ToggleButtons = ({ selectedButton, setSelectedButton, toggleButtons, size, exclusive, tabWidth, tabsDisabled, ...props }) => {
	const classes = useStyles();

	const handleChange = (event, newSelectedButton) => {
		if (newSelectedButton !== null) {
			setSelectedButton(newSelectedButton);
		}
	};

	return (
		<ToggleButtonGroup
			color="primary"
			value={selectedButton}
			onChange={handleChange}
			size={size}
			exclusive={exclusive}
			{...props}
		>
			{toggleButtons.map(toggleButton => <ToggleButton disabled={tabsDisabled} classes={{
				selected: classes.selected,
				root: classes.root,
			}} style={{ width: tabWidth }} value={toggleButton.value} key={toggleButton.value}> {toggleButton.label} </ToggleButton>)}
		</ToggleButtonGroup>
	);
};

ToggleButtons.propTypes = {
	exclusive: PropTypes.bool,
	selectedButton: PropTypes.object,
	setSelectedButton: PropTypes.func,
	size: PropTypes.string,
	color: PropTypes.string,
	tabWidth: PropTypes.number,
	toggleButtons: PropTypes.array,
	tabsDisabled: PropTypes.bool
};

ToggleButtons.defaultProps = {
	exclusive: true,
	size: 'medium',
	toggleButtons: [],
	color: 'primary',
	tabWidth: 182,
	tabsDisabled: false,
};

export default ToggleButtons;
