import { getIpMonitoringByDevice } from '../services/requests';
import React from 'react';

const getDeviceMonitoringData = async (deviceIdFilter, setIpMonitorData, setIsLoading) => {
	const ipMonitorData = await getIpMonitoringByDevice(deviceIdFilter);
	setIpMonitorData(ipMonitorData);
	setIsLoading(false);
};

const getDeviceNameTooltip = deviceName => <p><b>Device name: </b>{deviceName}</p>;

const getDevicesIdToDeviceNameMapping = async (liteDevices, setDevicesIdToDeviceNameMapping) => {
	const devicesIdToDeviceNameMapping = Object.entries(liteDevices)
		.reduce((resultMapping, [, devicesByType]) => {
			Object.entries(devicesByType).forEach(([deviceId, { name }]) => {
				resultMapping[deviceId] = name;
			});
			return resultMapping;
		}, {});
	setDevicesIdToDeviceNameMapping(devicesIdToDeviceNameMapping);
};

const mergeDeviceNameByMapping = (itemList, devicesIdToDeviceNameMapping) => {
	const itemsWithDeviceIdFilteredList = itemList.filter(item => item.deviceId);
	return itemsWithDeviceIdFilteredList.map(item => ({
		...item,
		deviceName: devicesIdToDeviceNameMapping[item.deviceId],
	}));
};

export {
	getDeviceMonitoringData,
	getDevicesIdToDeviceNameMapping,
	getDeviceNameTooltip,
	mergeDeviceNameByMapping,
};
