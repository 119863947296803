import {SET_USER_DATE_FORMATS} from "./actionTypes";


const initialState = {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    locale: Intl.DateTimeFormat().resolvedOptions().locale,
    dateFormat: null
}


export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER_DATE_FORMATS:
            return Object.assign(state, payload);
        default:
            return state;
    }
}

