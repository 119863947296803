import React, {useState} from 'react';
import {Dialog, Grid, Zoom} from "@material-ui/core";
import BoundedFormWithTitles from "../contactSales/boundedFormWithTitles";
import {
    attachCallbackToErrorComponent,
    attachCallbackToSuccessComponent,
    CONTACT_FORM_SUBTITLE,
    CONTACT_FORM_TITLE
} from "./changeCustomPlanConfiguration";
import ContactSalesForm from "../contactSales/forms/contactSalesFrom";
import {sendRequestToSales} from "../services/planRequests";
import analyzer from "../../../helpers/analyzer";
import {VISIT_ACTION, PLANS_CATEGORY, SUBMIT_ACTION, ANALYZER_FAILED_STATUS} from "../../../helpers/analyzerConstants";

const FLOW_TYPE = "CHANGE_CUSTOM_PLAN_DETAILS";
const ANALYZER_CATEGORY = PLANS_CATEGORY;

const ChangeCustomPlan = ({onClose}) => {

    const [indicativeComponent, setIndicativeComponent] = useState(() =><></>)
    const [isProcessFinished, setIsProcessFinished] = useState(false)

    const onSubmit = async ( data ) => {
        try {
            const salesData = {
                data: data,
                flow: FLOW_TYPE
            }
            await sendRequestToSales(salesData);
            const SuccessComponent = attachCallbackToSuccessComponent(onClose);
            setIndicativeComponent(SuccessComponent);
            analyzer.event({category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'PLANS_FLOW_CHANGE_CUSTOM_PLAN'})

        } catch (e) {
            const ErrorComponent = attachCallbackToErrorComponent(onClose);
            setIndicativeComponent(ErrorComponent);
            analyzer.event({category: ANALYZER_CATEGORY, action: ANALYZER_FAILED_STATUS, label: 'PLANS_FLOW_CHANGE_CUSTOM_PLAN'})

        } finally {
            setIsProcessFinished(true)
        }
    }
    const IndicativeComponent = indicativeComponent;

    return <Dialog open onClose={onClose}>
        <Zoom>
            {!isProcessFinished ?
                <BoundedFormWithTitles title={CONTACT_FORM_TITLE} subtitle={CONTACT_FORM_SUBTITLE}>
                    <ContactSalesForm onSubmit={onSubmit}/>
                </BoundedFormWithTitles>:
                IndicativeComponent}
        </Zoom>
    </Dialog>
}

export default ChangeCustomPlan