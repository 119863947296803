import React, { Component } from 'react';
import InputWrapper from '../../../wrapper/inputWrapper';
import './inputGroup1.scss';
import _ from 'lodash';
import { Field } from 'redux-form'; // ES6
import timeUnit from '../../../../../../shared/components/queryLine/timeUnit/timeUnit';
import simpleValueSelector from '../../../../../../shared/components/queryLine/selectors/simpleValueSelector';
import { dataTypeOptions } from '../../../../../backgroundProcess/controller/redux/analyzeActions';
import { thresholdArray } from '../../../../controller/constants/threshold';
import ThresholdField from '../../../../../../shared/components/queryLine/thresholdinput/thresholdField';
import { dropdownSelectorStyles } from '../../../../../../shared/utils/customSelectorStyles';

class InputGroup1 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const errors = _.isEmpty(this.props.errors) ? {} : this.props.errors;
		const FiltereddataTypeOptions = _.filter(dataTypeOptions, e => e.isAlert == true);

		return (
			<div className="alertes-inputs-line1">
				<div className="alerte-name">
					<InputWrapper name={ 'Alert name*' }
						error={ _.has(errors, 'name') ? errors.name : '' } input={
							<Field
								name="name"
								component="input"
								type="text"
								className="alerte-name-input"
							/>

						} />
				</div>
				<div className="alerte-type">
					<InputWrapper name={ 'Alert type' } input={ <Field
						name="queryTypes"
						component={ simpleValueSelector }
						options={ FiltereddataTypeOptions }
						className="alerte-type-field"
						selectorStyles={dropdownSelectorStyles}
					/> } />

				</div>
				<div className="alerte-threshold-type">
					<InputWrapper name={ 'Threshold type' } input={ <Field
						name="thresholdType"
						component={ simpleValueSelector }
						options={ thresholdArray }
						selectorStyles={dropdownSelectorStyles}
					/> }
					/>
				</div>
				<div className="alerte-threshold-value">
					<InputWrapper name={ 'Threshold value' } input={
						<ThresholdField />
					} />

				</div>
				<div className="alerteTimeWindow">
					<InputWrapper name={ 'Time window' } error={ _.has(errors, 'timeWindow') ? errors.timeWindow : '' } input={ <Field
						name="timeWindow"
						component={ timeUnit }
						type="number"
					/> } />

				</div>
			</div>
		);
	}
}

export default InputGroup1;
