import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

const StyledToggle = withStyles(() => ({
	root: {
		width: 25,
		height: 13,
		padding: 0,
		display: 'flex',
		margin: '0 2px',
	},
	switchBase: {
		padding: '2px !important',
		color: 'white !important',
		'&$checked': {
			transform: 'translateX(12px)',
			color: 'white',
			'& + $track': {
				opacity: 1,
				backgroundColor: '#00B8EC',
				borderColor: '#00B8EC',
			},
		},
	},
	thumb: {
		width: 9,
		height: 9,
		boxShadow: 'none',
	},
	track: {
		border: '#808080',
		borderRadius: 8,
		opacity: 1,
		backgroundColor: '#808080',
	},
	checked: {},
}))(Switch);

export default StyledToggle;
