import PropTypes from 'prop-types';
import React, { Component } from 'react';
import customAlertIcon from '../../../../../assets/alerts/custom-alert-icon.svg';
import '../../alertNotifications/noNotification/notification.scss';

import * as d3 from 'd3-format';
import _ from 'lodash';
import { minMaxformater } from '../../../../../constants/formater';
import CustomTooltip from '../../../../../shared/components/Tooltip/CustomTooltip';
import { thresholdObject } from '../../../../alertsManagement/controller/constants/threshold';
import AlertGraphWrapper from '../../../../alertsManagement/view/section/graph/alerteGraph/alertGraphWrapper';

const ALERT_NAME_MAX_CHARS = 32;
const TEXT_SMALL_SCREEN_WIDTH = 1650;
const GRAPH_SMALL_SCREEN_WIDTH = 1460;

class CustomAlertNotification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isTextSmallScreen: window.innerWidth <= TEXT_SMALL_SCREEN_WIDTH,
			isGraphSmallScreen: window.innerWidth <= GRAPH_SMALL_SCREEN_WIDTH,

		};
		this.handleResize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		this.setState({
			isTextSmallScreen: window.innerWidth <= TEXT_SMALL_SCREEN_WIDTH,
			isGraphSmallScreen: window.innerWidth <= GRAPH_SMALL_SCREEN_WIDTH,
		});
	}

	render() {
		const { graphs, customAlert } = this.props;

		const currentStatistics = _.isEmpty(graphs) ? {} : _.find(graphs, e => e.alertPolicyId === customAlert.Id);

		const classes = ['threat-wrapper'];
		const { sign } = thresholdObject[customAlert.data.threshold.type];

		const shouldShortenName = this.state.isTextSmallScreen && customAlert.name.length > ALERT_NAME_MAX_CHARS;

		return (
			<>
				<div
					className={ classes.join(' ') }>
					<div className="threat">

						<div className="threat-severity-time">
							<img src={ customAlertIcon } className="threat-severity" alt="" />
						</div>
						<div className="threat-message">
							{shouldShortenName
								? <CustomTooltip title={customAlert.name}>
									<div className="threat-title makebold small-screen-adaptable">
										{ `${customAlert.name.substring(0, ALERT_NAME_MAX_CHARS)}...` }
									</div>
								</CustomTooltip>
								: <div className="threat-title makebold">
									{ customAlert.name }
								</div>
							}
							<div className="threat-description">
								{ sign } { d3.format(minMaxformater)(customAlert.data.threshold.value) }
							</div>
						</div>

					</div>
					<AlertGraphWrapper
						height={ 100 }
						width={ this.state.isGraphSmallScreen ? 180 : 250 }
						data={ currentStatistics }
						threshold={ this.props.threshold } />
				</div>

			</>
		);
	}
}

CustomAlertNotification.propTypes = {
	customAlert: PropTypes.shape({
		Id: PropTypes.string,
		data: PropTypes.object,
		name: PropTypes.string,
	}),
	graphs: PropTypes.array,
	threshold: PropTypes.number,
};

export default CustomAlertNotification;
