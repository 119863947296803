import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        paddingLeft: "20px",
        paddingRight: "30px",
        paddingTop: "15px"
    },
    title: {
        paddingTop: "40px",
        display: "flex",
        alignItems: "center",
        height: "25%",
        fontSize: "30px",
        fontWeight: "bold",
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "row",
        height: "65%",
        alignItems: "center"
    },
    image: {
        width: "20%"
    },
    description: {
        paddingLeft: "15px",
        width: "70%",
        height: "85%",
        fontSize: "16px",
        color: theme.palette.dialogDescription.main,
        display: "flex",
        flexDirection: "column",
        alignItems: "spaceBetween"
    },
    topDescription: {
        height: "65%",
        alignContent: "center"
    },
    bottomDescription: {
        height: "35%",
        alignContent: "center"
    }
}));

export {useStyles}