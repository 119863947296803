import React, { Component } from 'react';

class TableRowOpenable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                { this.props.children }
            </>
        );
    }

}

export default TableRowOpenable;