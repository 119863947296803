import React, {useEffect, useState} from "react";
import {Button, Divider, FormControl, Grid, ListItemIcon, OutlinedInput, Select} from "@material-ui/core";
import {useStyles} from "./upgradeToBasicPlan.css";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import SimpleSelectWithIcon from "../../../../../../shared/components/dropdown/simpleSelectWithIcon";
import {ReactComponent as CreditCardSuppliers} from "../../../../../../assets/plans/creditCardSuppliers.svg"
import {
    paymentTypesMapping,
    paymentMethodsMenuItems,
    CREDIT_CARD_PAYMENT_TYPE_VALUE
} from "./paymentMethodConfiguration"
import Typography from "@material-ui/core/Typography";
import UpgradeToPremiumOrderSummary
    from "../../../../orderSummary/upgradeToPremiumOrderSummary/UpgradeToPremiumOrderSummary";
import analyzer from "../../../../../../helpers/analyzer";
import {SUBMIT_ACTION, VISIT_ACTION, PLANS_CATEGORY} from "../../../../../../helpers/analyzerConstants";
const ANALYZER_CATEGORY = PLANS_CATEGORY;

const UpgradeToPremiumPlan = ({
                                  selectedBaseProduct,
                                  setSelectedPriceName,
                                  selectedPriceName,
                                  onError,
                                  onSuccess,
                                  onCloseDialog,
                                  setLoaderMessage,
                                  setIsLoading,
                                  isLoading
                              }) => {

    const [paymentType, setPaymentType] = useState(CREDIT_CARD_PAYMENT_TYPE_VALUE);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitValid, setIsSubmitValid] = useState(false);

    const handleSubmitForm = async (data, onSuccessCallback=onCloseDialog, onErrorCallback=onCloseDialog) => {
        const {handleSubmitForm, successComponent, errorComponent} = paymentTypesMapping[paymentType];
        try {
            analyzer.event({category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: `PLANS_FLOW_${paymentType.toUpperCase()}`})
            await handleSubmitForm({...data, setIsLoading, setLoaderMessage});
            setIsLoading(false);
            onSuccess(successComponent(onSuccessCallback));

        } catch (e) {
            setIsLoading(false);
            onError(errorComponent(onErrorCallback));
        }
    }

    const FormComponent = paymentTypesMapping[paymentType].formComponent;
    const classes = useStyles()
    return (
        <Grid container className={classes.container}>
            <Grid item xs={8}>
                <Grid container className={classes.paymentContainer}>
                    <Grid item xs={12} className={classes.paymentMethodItem}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={classes.paymentText} variant={"body1"}
                                            color={"textSecondary"}>Payment</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.selectSection}>
                                <SimpleSelectWithIcon
                                    items={paymentMethodsMenuItems}
                                    defaultValue={CREDIT_CARD_PAYMENT_TYPE_VALUE}
                                    onChange={(value) => setPaymentType(value)}
                                    InputProps={{style: {height: "45px"}}}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.creditCardSuppliers}>
                                <CreditCardSuppliers/>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.contact}>
                            <Typography className={classes.contactText}>Can't find what you're looking for?
                                <a className={classes.contactLink} href="mailto:support@cynamics.ai"> contact us</a>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.divider}>
                            <Divider orientation="horizontal"/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.formItem}>
                        <FormComponent
                            handleSubmitForm={handleSubmitForm}
                            isSubmitted={isSubmitted}
                            setIsSubmitted={setIsSubmitted}
                            paymentType={paymentType}
                            paymentName={paymentTypesMapping[paymentType].displayText}
                            selectedPriceName={selectedPriceName}
                            selectedBaseProduct={selectedBaseProduct}
                            setIsSubmitValid={setIsSubmitValid}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Divider variant="middle" color="primary" orientation="vertical" light/>
            </Grid>
            <Grid item xs={3}>
                <Typography variant={"body1"} color={"textSecondary"}>Order Summary</Typography>
                <UpgradeToPremiumOrderSummary selectedPriceName={selectedPriceName}
                                              setSelectedPriceName={setSelectedPriceName}
                                              selectedPlan={selectedBaseProduct}
                                              title={`${selectedBaseProduct.displayText} subscription`}
                                              isCharging={paymentType === CREDIT_CARD_PAYMENT_TYPE_VALUE}
                                              onSubmit={() => setIsSubmitted(true)}
                                              isSubmitValid={isSubmitValid}/>

            </Grid>
        </Grid>
    )
}

export default UpgradeToPremiumPlan;