import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
		maxHeight: '100%',
		padding: '2%',
	},
	tableWrapper: {
		display: 'flex',
		maxHeight: '100%',
	},
	tableRow: {
		position: 'relative',
		'&:hover': {
			backgroundColor: '#f5f5f5',
		},
	},
	tooltip: {
		maxWidth: '12vw',
		wordBreak: 'break-word',
	},
}));

export { useStyles };
