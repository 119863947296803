import React, {useEffect, useState} from 'react';
import LayoutForm from "../../../shared/components/form/LayoutForm";
import {Typography} from "@material-ui/core";
import {useStyles} from "./screens.css";


function ResetLinkSentScreen({history}) {
    const [email, setEmail] = useState("");
    const classes = useStyles();

    useEffect(() => {
        history.location.state && setEmail(history.location.state.email)
    }, [])

    return (
        <LayoutForm customMessage={" "}>
            <div className={classes.screenTitle}>
                Sent! <br/>
                Check your email
            </div>
            <Typography variant={"body1"} paragraph>
                Instructions for resetting your <br/>
                password have been sent to:
            </Typography>

            <Typography variant={"h5"} paragraph>
                {email}
            </Typography>
        </LayoutForm>
    )
}

export default (ResetLinkSentScreen);