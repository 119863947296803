import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	stepperLabel: {
		color: theme.palette.stepsText.notActive,
		fontSize: '16px',
		fontWeight: 700,
	},
	stepperActiveLabel: {
		color: `${theme.palette.stepsText.active} !important`,
		fontWeight: '700 !important',
	},
	stepper: {
		width: '75%',
		margin: 'auto',
	},
}));

export default useStyles;
