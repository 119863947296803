import PropTypes from 'prop-types';
import React from 'react';

import SimpleTable from '../../../shared/components/simpleTable/simpleTable';
import SkeletonTable from '../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import { MAX_ROW_IN_TABLE } from '../../app/view/tableConfig/const';
import FieldFactory from './fieldFactory';

import useStyles from './rulesTable.css';

const mapOriginalValueToLabels = values => values.map(value => value.label);

const serviceToLabelMap = {
	threatsService: 'Threats',
	customAlertsService: 'Custom Alerts',
	vcaService: 'VCA',
};

const additionalInfoLabelMap = {
	protocolIds: 'Protocol'
};

const mapServiceToLabel = services => {
	const servicesText = [];
	for (const [serviceName, isEnalbed] of Object.entries(services)) {
		if (isEnalbed) {
			servicesText.push(serviceToLabelMap[serviceName]);
		}
	}

	return servicesText;
};

const mapAdditionalInfo = additionalInfo => {
	let res = {
		...additionalInfo,
		...(additionalInfo.protocolIds) && {protocolIds: mapOriginalValueToLabels(additionalInfo.protocolIds)},
		...(additionalInfo.devices && {devices: mapOriginalValueToLabels(additionalInfo.devices)}),
		services: mapServiceToLabel(additionalInfo.services),
	};
	return res;
};

const RulesTable = ({ rules, onEdit, onDelete, onToggle, isLoading }) => {
	const classes = useStyles();

	const columns = [
		FieldFactory.createTooltipTextField('description', 'Name',  false, true, false, true),
		FieldFactory.createMultipleValuesField('srcIps', 'Src IP', 'View Src IP', false, true, true, true, mapOriginalValueToLabels),
		FieldFactory.createMultipleValuesField('dstIps', 'Dst IP', 'View Dst IP', false, true, true, true, mapOriginalValueToLabels),
		FieldFactory.createMultipleValuesField('srcPorts', 'Src port', 'View Src port', false, true, true, true, mapOriginalValueToLabels),
		FieldFactory.createMultipleValuesField('dstPorts', 'Dst port', 'View Dst port', false, true, true, true, mapOriginalValueToLabels),
		FieldFactory.createCheckboxField('isBidirectional', 'Bi-directional', false, true, true, true),
		FieldFactory.createAdditionalInfoField('additionalInfo', 'Additional Info', false, true, true, true, mapAdditionalInfo, null, additionalInfoLabelMap),
		FieldFactory.createActionsField('isEnabled', onEdit, onDelete, onToggle),
	];

	return (
		<>
			{isLoading || !rules ? (
				<SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} className={classes.table} />
			) : (
				<SimpleTable
					totalItemsInPage={MAX_ROW_IN_TABLE}
					pages={Math.ceil(rules.length / MAX_ROW_IN_TABLE)}
					columns={columns}
					data={rules.sort((a, b) => a.creationTime - b.creationTime)}
					isResizable={false}
					className={classes.table}
				/>
			)}
		</>
	);
};

RulesTable.propTypes = {
	isLoading: PropTypes.bool,
	onDelete: PropTypes.func,
	onEdit: PropTypes.func,
	onToggle: PropTypes.func,
	rules: PropTypes.array,
};

export default RulesTable;
