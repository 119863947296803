import React from "react";
import createGroupsSide from "../../../../../../assets/walkthrough/groups/steps/creatingGroupsSide.png";
import createGroupsMain from "../../../../../../assets/walkthrough/groups/steps/creatingGroupsContent.png";
import {STEP_1_DESCRIPTION, STEP_1_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV1
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV3/mainPanelTemplateV3";
import {useStyles} from "./step1.css";


const Step1 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={createGroupsSide}>
            <MainPanelTemplateV1 title={STEP_1_TITLE}
                                 image={<img src={createGroupsMain} alt={"Create new group"}
                                             className={classes.image}/>}
                                 description={STEP_1_DESCRIPTION}
            />
        </StepTemplate>
    )
}

export default Step1;