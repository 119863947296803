import React, {useEffect, useState} from 'react';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import TextField from "../../../shared/components/form/TextField/TextField";
import SubmitButton from "../../../shared/components/form/Buttons/SubmitButton";
import { email, required } from "../../../shared/components/form/Validation/FieldLevelValidation";
import {Grid, Typography} from "@material-ui/core";
import StyleButton from "../../../shared/components/form/Buttons/StyleButton";
import blueCheck from "../../../assets/blue-check.svg";
import {connect, useSelector} from "react-redux";
import ActionButtons from "../sharedComponents/actionButtons";
import Saved from "../sharedComponents/saved";

let UpdateUserInfoForm = ({invalid, submitting, initialize, onSubmit, handleSubmit, isFieldChanged, setIsFieldChanged, updateSuccess, cancelChanges, onChange})=> {
    const currentUser = useSelector(state => state.auth.user);
    const cancelFormChanges = () => {
        initialize({firstName: currentUser.firstName, lastName: currentUser.lastName});
        cancelChanges();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} style={{width: '100%'}}>
            <Grid item container xs={12} justify={"space-between"} style={{height: '100%'}}>
                <Grid item container xs={6} justify={"center"}>
                    <Grid item xs={3} style={{alignSelf: 'center'}}>
                        <Typography variant="body2">First Name</Typography>
                    </Grid>
                    <Grid item xs={6} style={{alignSelf: 'center'}}>
                        <Field
                            onChange={(e) => onChange(e, "firstName")}
                            component={TextField}
                            label=""
                            className="form-field"
                            name="firstName"
                            type="text"
                            validate={[required]}
                        />
                    </Grid>
                </Grid>

                {isFieldChanged && <Grid item xs={4} style={{alignSelf: 'center'}}>
                    <ActionButtons isSubmitting={submitting} onCancel={cancelFormChanges}/>
                </Grid>}

                {updateSuccess && <Grid xs={1} item style={{alignSelf: 'center'}}>
                    <Saved/>
                </Grid>}

                <Grid item container xs={12} justify={"space-between"}>
                    <Grid item container xs={6} justify={"center"}>
                        <Grid item xs={3} style={{alignSelf: 'center'}}>
                            <Typography variant="body2">Last Name</Typography>
                        </Grid>
                        <Grid item xs={6} style={{alignSelf: 'center'}}>
                            <Field
                                onChange={(e) => onChange(e, "lastName")}
                                component={TextField}
                                label=""
                                className="form-field"
                                name="lastName"
                                type="text"
                                validate={[required]}
                            />
                        </Grid>
                    </Grid>

                </Grid>
            </Grid>
        </form>
    );
}

UpdateUserInfoForm =  reduxForm({
    form: 'update-user-info-form',
})(UpdateUserInfoForm);

UpdateUserInfoForm = connect(
    state => ({
        initialValues: {
            firstName: state.auth.user.firstName,
            lastName: state.auth.user.lastName
        }})
)(UpdateUserInfoForm);

export default UpdateUserInfoForm;


