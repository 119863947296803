import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        paddingLeft: "20px",
        paddingRight: "30px",
        paddingTop: "15px"
    },
    image: {
        display: "flex",
        alignItems: "center",
        justifyContent: ({imageAlign}) => imageAlign
    },
    title:{
        fontSize: "30px",
        fontWeight: "bold",
    },
    description: {
        fontSize: "16px",
        color: theme.palette.dialogDescription.main
    }
}));

export {useStyles}