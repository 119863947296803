import React, { Component } from "react";
import { Field } from 'redux-form';

import 'react-phone-number-input/style.css';

import './smsRecipient.scss';
import phoneValidation from "../../../controller/validations/phoneInputValidation";
import SimplePhoneInput from "../../../../../shared/components/form/Phone/simplePhone";


export default class SmsRecipient extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <div className="sms-recipient">
            <div className="sms-recipient-value">
                <Field
                    component={ SimplePhoneInput }
                    name={ this.props.number }
                    validate={ phoneValidation }
                />
            </div>
        </div>

    }
}
