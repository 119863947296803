import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const DevicesBannerButton = withStyles(({
	root: {
		color: '#D87515',
		backgroundColor: 'white',
		fontSize: '12px',
		fontWeight: 700,
		marginLeft: '2em',
		paddingLeft: '0.7em',
		paddingRight: '0.7em',
		'&:hover': {
			backgroundColor: 'white',
			opacity: 0.9,
			color: '#D87515',
			boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19)',
		},
	},
}))(Button);

export default DevicesBannerButton;

