const attackLevels = {
    'NONE': 0,
    'WARNING': 1,
    'SEVERE': 2
};

const graphsGroups = {
    'STANDARD': 1,
    'WITH_ATTACK_PROB': 2
};

const planTypes = {
    "free": {"key":"free", "display" : "Free", "price": 0, "intervals": 0},
    "monthly": {"key":"monthly", "display" : "Monthly", "price": 950, "intervals": 12},
    "yearly": {"key":"yearly", "display" : "Yearly", "price": 7500, "intervals": 1}
}
export {attackLevels, graphsGroups, planTypes};
