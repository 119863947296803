import React, {useState} from 'react';
import {Field, reduxForm} from 'redux-form';
import PropTypes from 'prop-types';
import {Grid} from "@material-ui/core";
import SubmitButton from "../../../../shared/components/form/Buttons/SubmitButton";
import TextField from "../../../../shared/components/form/TextField/TextField";
import {email, isPhoneNumber, required} from "../../../../shared/components/form/Validation/FieldLevelValidation";
import {useStyles} from "./feedbackForm.css";
import phoneValidation from "../../../integrations/controller/validations/phoneInputValidation";
import CheckboxInput from "../../../../shared/components/checkboxInput/CheckboxInput";
import {FEEDBACK_OPTIONS} from "../../plansStepperWrapper/completePurchaseWrapper/flowsComponents/downgradeFromBasicPlan/downgradeFromBasicPlanConfiguration"

const FORM_NAME = 'feedback-downgrade-form'

const FeedbackForm = ( props ) => {
    const { onSubmit, handleSubmit, options = FEEDBACK_OPTIONS, dirty, submitting, initialValues } = props
    const classes = useStyles();
    const [isMoreInfoValid, setIsMoreInfoValid] = useState(true)

    const validateMoreInfo = (value) =>{
        if(value && !/\S/.test(value)) {
            setIsMoreInfoValid(false);
            return 'Please enter a valid text or keep it empty'
        }
        else{
            setIsMoreInfoValid(true)
            return undefined
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.formAlignment}>
            <Grid container xs={12} spacing={1}  justify={"center"}>
                    {options.map( (option, index) => {
                        return <Grid item xs={12} key={index}>
                            <CheckboxInput name={index} label={option} formName={FORM_NAME}/>
                        </Grid>
                    })}
                    <Grid item xs={12}>
                        <Field
                            component={TextField}
                            placeholder="Description"
                            name={'moreInfo'}
                            type="text"
                            rows={"1"}
                            validate={validateMoreInfo}
                            multiline={true}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.buttonGrid} >
                        <SubmitButton isDisabled={!dirty || submitting || !isMoreInfoValid} label='Submit' className={classes.submitButton} />
                    </Grid>
                </Grid>
        </form>
    );
}
export default reduxForm({
    enableReinitialize: true,
    form: FORM_NAME,
})(FeedbackForm);
