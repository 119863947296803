import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as ErrorIcon } from '../../../assets/icons/error.svg';
import useStyles from './ErrorMessage.css';

const ErrorMessage = ({ title, message }) => {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			<ErrorIcon />
			<Typography className={`${classes.text} ${classes.title}`}>{title}</Typography>
			<Typography className={`${classes.text} ${classes.message}`}>{message}</Typography>
		</Box>
	);
};

ErrorMessage.propTypes = {
	message: PropTypes.string,
	title: PropTypes.string,
};

export default ErrorMessage;
