import getStripeInstance from "../../../../../paymentMethod/stripe/stripe";
import {createSubscription, updatePaymentMethod, contactSalesTeam} from "../../../../../paymentMethod/requests/requests";
import {getPaymentMethod} from "../../../../../paymentMethod/stripe/requests";

const handleCreditCardSubmitForm = async ({paymentMethodData, subscriptionData, setIsLoading, setLoaderMessage}) => {
    const stripe = await getStripeInstance();
    const stripePaymentMethod = await getPaymentMethod(stripe, paymentMethodData);
    setLoaderMessage('Processing your order...');
    setIsLoading(true);
    if (stripePaymentMethod) {
        // internal API call
        return await subscribe(stripePaymentMethod, subscriptionData);
    }
}

const handleContactSalesSubmitForm = async ({paymentData, subscriptionData, setIsLoading, setLoaderMessage}) => {
    setLoaderMessage('Processing your order...');
    setIsLoading(true)
    const req = {
        ...paymentData,
        ...subscriptionData
    }
    await contactSalesTeam(req);
}

const handleChangePaymentDetails = async ({paymentMethodData}) => {
    const stripe = await getStripeInstance();
    const stripePaymentMethod = await getPaymentMethod(stripe, paymentMethodData);
    if (stripePaymentMethod) {
        // internal API call
        return await updatePaymentDetails(stripePaymentMethod);
    }
}

const subscribe = async (paymentMethod, subscriptionData) => {
    const {selectedPriceName, targetBaseProductId} = subscriptionData;
    const {id, billing_details} = paymentMethod;
    const reqData = {
        targetBaseProductId: targetBaseProductId,
        paymentMethodId: id,
        priceKey: selectedPriceName.toUpperCase(),
        billingInfo: createBillingInfo(billing_details)
    }
    return await createSubscription(reqData);
}

const updatePaymentDetails = async (paymentMethod) => {
    const { id, billing_details} = paymentMethod;
    const reqData = {
        paymentMethodId: id,
        billingInfo: createBillingInfo(billing_details)
    }
    return await updatePaymentMethod(reqData);
}

const createBillingInfo = (stripeBillingDetails) => {
    const billingInfo = {
        city: stripeBillingDetails.address.city,
        country: stripeBillingDetails.address.country,
        email: stripeBillingDetails.email,
        name: stripeBillingDetails.name,
        line1: stripeBillingDetails.address.line1,
        zipCode: stripeBillingDetails.address.postal_code
    }
    // optional fields
    if (stripeBillingDetails.address.line2) {
        billingInfo.line2 = stripeBillingDetails.address.line2;
    }
    if (stripeBillingDetails.address.state) {
        billingInfo.state = stripeBillingDetails.address.state;
    }
    if (stripeBillingDetails.address.phone) {
        billingInfo.phone = stripeBillingDetails.address.phone
    }
    return billingInfo;
}


export {
    handleCreditCardSubmitForm,
    handleContactSalesSubmitForm,
    handleChangePaymentDetails
}