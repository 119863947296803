import portsMapping from '../../../../containers/topTalkers/references/ports_mapping.json';
import { assetTypes } from '../detectionsTableConfig';

export const remediationBuilder = remediation => {
	if (!remediation || remediation.length === 0) {
		return [];
	}

	return remediation.map(step => {
		const allAssets = step.assets.map(asset => enrichAsset(asset)).join(', ');
		return `${step.action}${allAssets ? `: ${allAssets}` : ''}`;
	});
};

const enrichAsset = asset => {
	if (asset.assetType === assetTypes.PORT && portsMapping[asset.value]) {
		return `${asset.value} (${portsMapping[asset.value].port_name})`;
	}

	return asset.value;
};
