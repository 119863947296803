import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    gridItem: {
        minWidth: "100%",
    },
    gridContainer: {
        padding: "9%"
    },
    alertsGridItem:{
        fontSize: "16px",
        color:" #48648b",
        fontWeight: "bold",
        minWidth: "100%"
    },
    positiveDiffFromAverage:{
        color: "#f05f5a",
        fontWeight: "bold"
    },
    negativeDiffFromAverage:{
        color: "#ecaa00",
        fontWeight: "bold"
    }
}));

export {useStyles}