import {
    ANALYZE_DATA,
    LOADING_ANALYSIS,
    UPDATE_SUMMARY,
    REMOVE_NOTIFICATION,
    ANALYSE_DATA_SWITCH,
    GET_TOTALS,
    ENRICH_NOTIFICATION,
    REMOVE_ANOMALISES
} from './analyzeActions';

const initialState = {
    isLoading: false,
    summaryData: {
        anomalies: [],
        removedANOMALISES: [],
        notifications: []
    }
}

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case LOADING_ANALYSIS:
            return { ...state, isLoading: true };
        case ANALYSE_DATA_SWITCH:
            return { ...state, analyzedData: payload.analyzedData };
        case ANALYZE_DATA:
            return { ...state, isLoading: false, analyzedData: payload.analyzedData };
        case UPDATE_SUMMARY:
            return { ...state, isLoading: false, summaryData: { ...state.summaryData, ...payload.summaryData } };
        case ENRICH_NOTIFICATION:

            return {
                ...state,
                isLoading: false,
                summaryData: {
                    ...state.summaryData,
                    anomalies: payload.anomalies
                }
            }
        case REMOVE_NOTIFICATION:
            let newRemovedList = state.summaryData.removedANOMALISES;
            newRemovedList.push(payload.removed);
            return {
                ...state, isLoading: false,
                summaryData: {
                    ...state.summaryData,
                    notifications: payload.notifications,
                    removedANOMALISES: newRemovedList

                }
            }
        case REMOVE_ANOMALISES:
            let toRemove = state.summaryData.removedANOMALISES;
            toRemove.push(payload.removed);

            return {
                ...state, isLoading: false,
                summaryData: {
                    ...state.summaryData,
                    anomalies: payload.anomalies,
                    removedANOMALISES: toRemove
                }
            };
        case GET_TOTALS:
            return { ...state, totals: payload.totals };
        default:
            return state;
    }
}
