import {
    SET_STATUSES
} from './actionTypes';

const initialState = {
    deviceStatuses: {}
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SET_STATUSES:
            return {
                ...state,
                deviceStatuses: payload
            };
        default:
            return state;
    }
}
