const TOOLTIP_DEFAULT_PROPS = {
	id: 'tooltip',
	placement: 'bottom',
	background: '#4a4a4a',
	border: '#4a4a4a',
	offset: 8,
	padding: 5,
	radius: 4,
	enterDelay: 500,
	enterNextDelay: 500,
	PopperProps: { style: { marginTop: -10 } },
};

export {
	TOOLTIP_DEFAULT_PROPS,
};
