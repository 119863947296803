import React, { useEffect } from 'react';
import NotFoundLogo from '../../../assets/404-page.svg';
import useStyles from './notFoundPage.css';
import StyleButton from '../form/Buttons/StyleButton';
import PropTypes from 'prop-types';
import { setTitle } from '../../../containers/app/controller/redux/app/appActions';
import { useDispatch } from 'react-redux';

const PAGE_NOT_FOUND_TITLE = 'Page not found';
const PAGE_NOT_FOUND_DESCRIPTION = 'The page you are looking for was not found or no longer exists';

const NotFoundPage = ({ history }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setTitle(''));
	}, []);
	return (
		<div className={classes.root}>
			<img src={NotFoundLogo} alt="404-page-not-found" width={240} height={230}/>
			<p className={classes.title}>{PAGE_NOT_FOUND_TITLE}</p>
			<p className={classes.description}>{PAGE_NOT_FOUND_DESCRIPTION}</p>
			<StyleButton
				type="submit"
				onClick={() => history.push('/')}
				label="Go to Overview"
				className={classes.redirectButton}
			/>
		</div>
	);
};

NotFoundPage.propTypes = {
	history: PropTypes.object,
};

export default NotFoundPage;
