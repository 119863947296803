import React from 'react';
import PropTypes from 'prop-types';
import { chunk, orderBy } from 'lodash';

import SimpleTable from '../../../../shared/components/simpleTable/simpleTable';
import SkeletonTable from '../../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import FieldFactory from './fieldsFactory';
import { MAX_ROW_IN_TABLE } from '../../../app/view/tableConfig/const';

import useStyles from './threatTable.css';

const ThreatTable = ({ threats, isLoading, onChangeFavThreat, onDeleteThreat }) => {
	const classes = useStyles();

	const columns = [
		FieldFactory.createDateTimeField('startTime', 'Threat Start', false, true, true, true),
		FieldFactory.createDateTimeField('endTime', 'Threat End', false, true, true, true),
		FieldFactory.createTextField('deviceName', 'Device', false, true, true, true),
		FieldFactory.createTextField('threatName', 'Threat Type', false, true, true, true),
		FieldFactory.createSeverityField('severity', 'Severity', false, true, false, true),
		FieldFactory.createVisibilityField('assets', 'Details', false, false, true, true),
		FieldFactory.createStarField('isMarked', 'Actions', onChangeFavThreat, onDeleteThreat, false, true, true, true),
	];

	return (
		<>
			{isLoading || !threats ? (
				<SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} className={classes.table} />
			) : (
				<SimpleTable
					totalItemsInPage={ MAX_ROW_IN_TABLE }
					pages={ chunk(threats, MAX_ROW_IN_TABLE).length }
					columns={ columns } data={ orderBy(threats, e => -e.startTime) } isResizable={ false } />
			)}
		</>
	);
};

ThreatTable.propTypes = {
	onChangeFavThreat: PropTypes.func,
	onDeleteThreat: PropTypes.func,
	threats: PropTypes.array,
	isLoading: PropTypes.bool,
};

export default ThreatTable;

