import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        width: '100%',
        height: '100%'
    },
    wrapper: {
        width: '40%',
        height: '100%',
        margin: '0 auto',
        alignContent: 'space-evenly'
    }


}));

export {useStyles}