import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Stepper from '../../../../../shared/components/stepper/Stepper';
import { SNACKBAR_HIDE_DURATION } from '../../../../backgroundProcess/view/visibilityView/rawdataExport/constants';
import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import { createSampler } from '../../../controller/services/samplerRequests';
import SamplerSetup from './steps/samplerSetup/SamplerSetup';
import VMConfiguration from './steps/vmConfiguration/VMConfiguration';

const SAVE_ERROR_MESSAGE = 'Something went wrong. Try again';

const SamplerCreationStepper = ({ onClose, containerStyle, onSubmit }) => {
	const dispatch = useDispatch();

	const [sampler, setSampler] = useState(null);

	const downloadSamplerFiles = async link => {
		if (typeof (link) === 'string') {
			const a = document.createElement('a');
			a.href = link;
			a.click();
		}
	};

	const saveSampler = async samplerData => {
		try {
			const response = await createSampler(samplerData.name);
			await downloadSamplerFiles(response.link);
			setSampler(response);
		} catch {
			dispatch(openSnackbar('error', SAVE_ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
			onClose();
		}
	};

	const steps = [
		{
			title: 'Sampler Setup',
			component: SamplerSetup,
			additionalProps: {
				form: 'create-sampler',
				createSampler: saveSampler,
			},
		},
		{
			title: 'VM Configuration',
			component: VMConfiguration,
			additionalProps: {
				onClose,
				onSubmit,
				samplerData: sampler,
			},
		},
	];

	return <Stepper steps={steps} containerStyle={containerStyle} />;
};

SamplerCreationStepper.propTypes = {
	onClose: PropTypes.func,
	onSubmit: PropTypes.func,
	containerStyle: PropTypes.object,
};

export default SamplerCreationStepper;
