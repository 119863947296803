import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    head: {
        backgroundColor: '#DBDFE0',
    },
    cell: {
        fontWeight: 'bold !important',
        padding: '0 2.2em 0.9em 1.2em !important',
        textAlign: 'left !important'
    },
    cellTextSize: {
        fontSize: '0.875rem'
    }
}));

export default useStyles;
