import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	container: {
		border: '1px solid #C2C2C2',
		width: 'fit-content',
		padding: '0.7em',
		borderRadius: '0.4em',
		fontSize: '14px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	icon: {
		marginRight: '0.4em',
		height: '18px',
		width: '18px',
	},
}));

export default useStyles;
