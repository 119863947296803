import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.advancedButtons.main,
        color: "white",
        width: "45%",
        height: "40px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "&:hover":{
            backgroundColor: theme.palette.advancedButtonsHover.main
        }
    },
    icon: {
        height: '16px',
        width: '16px'
    },
    label: {
        paddingLeft: "6px"
    }
}));


export {useStyles}
