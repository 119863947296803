import React, { Component } from 'react';
import CollapsibleTable from './collapse/collapseTable';
class InsightTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { instances, instanceType, insightData } = this.props;
        
        return (

            <>
                <CollapsibleTable
                    insightData={ insightData }
                    instanceType={ instanceType }
                    instances={ instances }
                    runQuery={ (e) => {
                        this.props.runQuery(e);
                    } } />
            </>
        );
    }
}

export default InsightTable;