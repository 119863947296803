// React
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { connect } from 'react-redux';
import ProfileIcon from '../../../../../assets/topbar/profile-small-menu.svg';

import HelpIcon from '../../../../../assets/topbar/help-center-icon.svg';

import '../../../../../scss/component/topbar.scss'
import TopBarMenu from './topBarMenu/topBarMenu';

import _ from 'lodash'
import analyzer from "../../../../../helpers/analyzer";
import { REDIRECT_ACTION, TOP_BAR_LINKS_CATEGORY } from "../../../../../helpers/analyzerConstants";
const ANALYZER_CATEGORY = TOP_BAR_LINKS_CATEGORY

class Topbar extends PureComponent {

    handleUserManagementIconClick = () => {
        analyzer.event({category: ANALYZER_CATEGORY, action: REDIRECT_ACTION, label: 'USER_MANAGEMENT'})
    }

    render() {
        const { title, user } = this.props;
        return (
            <div className="topbar">
                <div className="topbar__left">
                    <div className="topbar__title">
                        { title }
                    </div>
                </div>
                <div className="topbar__right">
                    <div className='topbar__action-board'>
                        { this.props.children }
                    </div>
                    <div className="topbar__profile">
                        { _.isEmpty(user) ? null :
                            <div className='name'>
                                Welcome <span className='username'>{ user.firstName + " " + user.lastName } </span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
