export const tableConfig = {
	model: 'user',
	modelDisplayName: 'User',
	fields: [
		{
			accessor: 'firstName',
			alias: 'First name',
			type: 'text',
			className: 'alignStart',
			headerClassName: 'alignStart',
			newEntryField: true,
			editField: true,
			allowView: true,
			allowEdit: true,
			show: true,
			filterable: false,
		},
		{
			accessor: 'lastName',
			alias: 'Last name',
			type: 'text',
			newEntryField: true,
			editField: true,
			allowView: true,
			allowEdit: true,
			show: true,
			filterable: false,
		},
		{
			accessor: 'email',
			alias: 'Email',
			type: 'text',
			newEntryField: true,
			editField: true,
			allowView: true,
			allowEdit: false,
			show: true,
			filterable: false,
		},
		{
			accessor: 'lastLogin',
			alias: 'Last Modified',
			type: 'date',
			allowView: true,
			allowEdit: false,
			show: true,
			filterable: false,
		},
		{
			accessor: 'editOrRemove',
			alias: '',
			className: 'alignEnd',
			type: 'actions',
			allowView: false,
			allowEdit: false,
			show: true,
			filterable: false,
		},
	],
	searchFields: ['firstName', 'employeeId', 'email'],
	// TODO: check with the team how it will be with the backend
	restOptions: {
		// Filters: {
		//     "status": { "$in": [this.data.enums.userStatus.notConfirmed, this.data.enums.userStatus.confirmed, this.data.enums.userStatus.blocked] },
		//     "roles": { "$intersects": [this.data.enums.role.surfer] }
		// },
		// dateRange: {
		//     from: moment().subtract(1, 'month').valueOf(),
		//     to: moment().valueOf()
		// },
		// handlers: ['addStats']
	},
};
