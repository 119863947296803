import {START_WITH_PRIMARY_POPUP, OPEN_REFER_A_FRIEND_CONTAINER} from "./actionTypes";
import action from "../../app/controller/redux/middleware";

export const setOpenReferAFriendPrimaryPopup = (isOpenPrimary) => {
    return action(dispatch => {
        dispatch({
            type: START_WITH_PRIMARY_POPUP,
            payload: isOpenPrimary
        });
    });
}

export const setOpenReferAFriendFlow = (isOpen) => {
    return action(dispatch => {
        dispatch({
            type: OPEN_REFER_A_FRIEND_CONTAINER,
            payload: isOpen
        });
    });
}