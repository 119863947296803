import React from "react";

const QueryInput = ({title, input, error, classes}) => {
    return (
        <div className={classes.root}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.input}>
                {input}
            </div>
            <div className={classes.error}>
                {error}
            </div>
        </div>
    )
}

export default QueryInput;