import http from "../../../../../services/httpService/http";

const mapUrl = 'geo-map/v1/entities/';

const getMapData = () => {
    return http.get(mapUrl);
}

export {
    getMapData
}

