// MyBarChart.js
import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import CollectingDataIcon from '../../../../../../assets/alerts/collecting-data.svg';
import AlertGraph from './alertGraph';
import './alertGraph.scss';
export const LoadingBar = () => (
	<div className="graph-loading-container">
		<div className="graph-loading" />
	</div>
);
const NoData = () => (
	<React.Fragment>
		<img src={ CollectingDataIcon } alt="" style={{ minHeight: '50px', width: '200px' }}/>
	</React.Fragment>
);

const NoDataYet = ({ customWidth }) => (
	<React.Fragment>
		<img onClick={() => alert(customWidth)}src={ CollectingDataIcon } alt="" style={{ minHeight: '50px', width: `${customWidth - 40}px` }} />
	</React.Fragment>
);

class AlerteGraph extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedBar: null,
			isOpen: false,
		};
	}

	createXticks = array => array.map(e => e);

	createGreenBars = array => _.cloneDeep(array.map(e => ({
		x: e.index,
		y0: e.value,
		y: 0, color: 1, e,
	})));

	createRedBars = array => _.cloneDeep(array.map(e => ({
		x: e.index,
		y0: e.value,
		y: 0, color: 0, e,
	})));

	createGreyBars = array => _.cloneDeep(array.map(e => ({
		x: e.index,
		y0: e.value,
		y: 0, color: 2, e,
	})));

	createTreshold = size => {
		const { thresholdValue } = this.props.data;
		const allArrays = [];
		for (let index = 0; index < size; index++) {
			allArrays.push({ x: index, y: thresholdValue });
		}

		return allArrays;
	};

	render() {
		let hasNoData = false;
		const { selectedBar, isOpen } = this.state;
		let { data, threshold, isLoadingAlerte } = this.props;
		let data1 = []; let data2 = []; let
			data3 = [];
		let allValues = []; let allArrays = []; let
			verticalTick = [];

		const emptyGraphObject = { greenStats: [], redStats: [], emptyStats: [] };
		const { greenStats, redStats, emptyStats } = !_.hasIn(data, 'thresholdValue') ? emptyGraphObject : data;

		const size = greenStats.length + redStats.length + emptyStats.length;
		const hasData = size > 0;
		if (_.has(data, 'isLoading') && data.isLoading == true) {
			isLoadingAlerte = data.isLoading;
		}

		if (hasData) {
			const size = greenStats.length + redStats.length + emptyStats.length;
			allArrays = this.createTreshold(size);
			// Map colors green

			data1 = this.createGreenBars(greenStats);
			// Map colors red
			data2 = this.createRedBars(redStats);

			data3 = this.createGreyBars(emptyStats);

			verticalTick = this.createXticks(Array.from(Array(size).keys()).map(e => parseInt(e)));

			// Concat colors into one array
			allValues = _.sortBy(data2.concat(data1).concat(data3), 'x').map((e, i) => ({ ...e, index: i }));
		}

		if (_.every(allValues, e => e.y0 == null || e.y0 == 0)) {
			hasNoData = true;
		}

		const NoGraph = _.cloneDeep(Array.from(Array(this.props.selectedFilter.value).keys()).map(e => parseInt(e)));
		const NoGraphLine = _.cloneDeep(Array.from(Array(this.props.selectedFilter.value).keys()).map(e => ({ x: parseInt(e), y: 5 })));

		return <div className="alert-graph-box">
			<div>
				{ isLoadingAlerte ? <LoadingBar /> : hasData == false
					? <NoData />
					: hasNoData == true ? <NoDataYet customWidth={this.props.width} /> : <AlertGraph
						width={ _.has(this.props, 'width') ? this.props.width : 500 }
						height={ _.has(this.props, 'height') ? this.props.height : 100 }
						verticalTick={ verticalTick }
						allArrays={ allArrays }
						allValues={ allValues }
						selectedFilter={ this.props.selectedFilter } />

				}
			</div>

		</div >;
	}
}

const mapStateToProps = state => ({
	selectedFilter: state.alertes.selectedFilter,
	isLoadingAlerte: state.alertes.isLoadingAlerte,
});

export default connect(mapStateToProps, null)(AlerteGraph);

