import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		height: '100%',
		width: '100%',
	},
	mainText: {
		fontWeight: 400,
		fontSize: '18px',
		lineHeight: '25px',
		color: `${theme.palette.secondary.main}`,
		marginBottom: '20px',
	},
	itemsGap: {
		gap: '10px',
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		border: '1px solid rgba(194, 194, 194, 0.7)',
		borderRadius: '2px',
		padding: '24px 18px',
	},
	itemsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	item: {
		color: `${theme.palette.secondary.main}`,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	name: {
		fontWeight: 400,
		fontSize: '17px',
		lineHeight: '16px',
	},
	price: {
		fontWeight: 600,
		fontSize: '17px',
		lineHeight: '16px',
	},
	divider: {
		width: '100%',
		margin: '20px 0',
		height: '0px',
		border: '0.5px solid rgba(194, 194, 194, 0.7)',
	},
	buttonContainer: {
		width: '100%',
		maxHeight: '50px',
		marginTop: '33px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	acceptButton: {
		color: 'white',
		fontWeight: 500,
		lineHeight: '22px !important',
		fontSize: '18px !important',
		padding: '14px',
	},
	loading: {
		height: '40px',
		width: '40px',
	},
	acceptButtonSkeleton: {
		transform: 'scale(1, 1) !important',
	},
}));

export default useStyles;
