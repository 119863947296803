import React from "react";

import { groupTypes } from "../ipgroupModal/groupTypes";
import Tooltip from "react-simple-tooltip";

import "../card/card.scss";
import "./ipCard.scss";

const MAX_LETTERS_IN_CHIP = 17;

const typeToClassMapping = {
  [groupTypes["CIDR"]]: "box-ip",
  [groupTypes["MAC"]]: "box-mac",
  [groupTypes["ORGANIZATION"]]: "box-organization",
  [groupTypes["AWS_INSTANCE_ID"]]: "box-aws",
  [groupTypes["AWS_SUBNET_ID"]]: "box-aws",
};

const IpCard = ({ value, type }) => {
  return (
    <div className={`${typeToClassMapping[type]} box-ip-core`}>
      {value.length <= MAX_LETTERS_IN_CHIP ? (
        <p>{value}</p>
      ) : (
        <Tooltip className="ip-tooltip" content={value}>
          <p>{value.substring(0, MAX_LETTERS_IN_CHIP - 3)}...</p>
        </Tooltip>
      )}
    </div>
  );
};

export default IpCard;
