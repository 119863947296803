import React from 'react';
import useStyles from './remediationForm.css';
import { useFormik } from 'formik';
import TextFieldWrapper from '../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import SimpleModal from '../../../shared/components/simpleTable/simpleModal';
import { FormWrapper } from '../../../shared/components/simpleTable/simpleFormContainer';
import CheckboxTextField from './CheckboxTextField/checkboxTextField';
import { propTypes, defaultProps } from './props';
import SelectionButton from '../../../shared/components/queryLine/selectors/SelectionButton/SelectionButton';
import { Button, CircularProgress } from '@material-ui/core';
import remediationIcon from '../../../assets/remediation/remediation_form_icon.svg';

function RemediationForm({
	contentTitle,
	contentSubTitle,
	onDismiss,
	onSubmitForm,
	isLoading,
}) {
	const { values, handleChange, handleSubmit } = useFormik({
		initialValues: {
			sourceIPOrMac: '',
			sourcePort: '',
			destinationIPOrMAC: '',
			destPort: '',
			protocolID: '',
			checkboxAll: '',
			checkbox: [],
		},
		onSubmit: onSubmitForm,
	});
	const classes = useStyles();

	return (
		<SimpleModal
			isOpen
			header="Remediation"
			toggle={onDismiss}
			icon={remediationIcon}
		>
			<div className={classes.contentContainer}>
				<FormWrapper wrapperClass={classes.formPadding}>
					<div className={classes.titleSubTitleContainer}>
						<span className={classes.title}>{contentTitle}</span>
						<span className={classes.subTitle}>{contentSubTitle}</span>
					</div>
					<div className={classes.halfWidthTextFieldsContainer}>
						<TextFieldWrapper
							helperText="Type or select group"
							label="Source IP or MAC"
							id="sourceIPOrMac"
							onChange={handleChange}
							value={values.sourceIPOrMac}
							InputProps={{
								startAdornment: (
									<div className={classes.selectionButtonsContainer}>
										<SelectionButton value="IoT Servers" included />
										<SelectionButton value="Public Wifi" included={false} />
									</div>
								),
							}}
						/>
						<TextFieldWrapper
							label="Source port"
							id="sourcePort"
							onChange={handleChange}
							value={values.sourcePort}
						/>
					</div>
					<div className={classes.halfWidthTextFieldsContainer}>
						<TextFieldWrapper
							helperText="Type or select group"
							label="Destination IP or MAC"
							id="destinationIPOrMAC"
							onChange={handleChange}
							value={values.destinationIPOrMAC}
						/>
						<TextFieldWrapper
							label="Dest port"
							id="destPort"
							onChange={handleChange}
							value={values.destPort}
							InputProps={{
								startAdornment: (
									<div className={classes.selectionButtonsContainer}>
										<SelectionButton value="IoT Servers" included />
									</div>
								),
							}}
						/>
					</div>
					<TextFieldWrapper
						label="Protocol ID"
						id="protocolID"
						onChange={handleChange}
						value={values.protocolID}
					/>
					<span className={classes.title}>Gateways</span>
					<CheckboxTextField
						textFieldLabel="Firewalls"
						checkboxSelectAll={{ onChange() {}, name: 'checkboxAll' }}
						checkboxes={[
							{
								label: 'Los Angeles DC',
								onChange() {},
								checked: true,
								name: 'checkbox',
							},
							{
								label: 'New York DC',
								onChange() {},
								checked: false,
								name: 'checkbox',
							},
							{
								label: 'Miami DC',
								onChange() {},
								checked: true,
								name: 'checkbox',
							},
						]}
					/>
					<CheckboxTextField
						textFieldLabel="Switches"
						checkboxSelectAll={{ onChange() {}, name: 'checkboxAll' }}
						checkboxes={[
							{
								label: 'Boston DC',
								onChange() {},
								checked: true,
								name: 'checkbox',
							},
							{
								label: 'Dallas DC',
								onChange() {},
								checked: true,
								name: 'checkbox',
							},
						]}
					/>
					<div className={classes.submitButtonContainer}>
						{isLoading ? <CircularProgress color="primary" /> : <Button
							className={classes.submitButton}
							onClick={handleSubmit}
							color="primary"
							variant="contained"
						>
              Add deny rule
						</Button>}
					</div>
				</FormWrapper>
			</div>
		</SimpleModal>
	);
}

RemediationForm.defaultProps = defaultProps;

RemediationForm.propTypes = propTypes;

export default RemediationForm;
