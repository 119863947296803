import React, { useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { graphOptions } from './config';
import useStockGraph from './useStockGraph';
import useStyles from './stockGraph.css';

const stockGraph = ({ data,
	threshold,
	onPointSelect,
	handleChartMouseLeave,
	minDate,
	maxDate,
	isLoading,
	configOverrides }) => {
	const {
		onChartMouseLeave,
		onMouseHover,
		onClickPoint,
		setPosition,
		chartRef,
	} = useStockGraph({ handleChartMouseLeave, onPointSelect, isLoading, threshold });

	const options = graphOptions({
		data,
		threshold,
		maxDate,
		minDate,
		onMouseHover,
		onClickPoint,
		setPosition,
	});
	const stockGraphOptions = { ...options, ...configOverrides };

	const classes = useStyles();
	return (
		<div className={classes.graphContainer} onMouseLeave={onChartMouseLeave}>
			<HighchartsReact
				highcharts={Highcharts}
				options={stockGraphOptions}
				ref={chartRef}
				containerProps={{ style: { height: '100%' } }}
			/>
		</div>
	);
};

export default stockGraph;
