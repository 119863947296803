import noAlerts from '../assets/no-alerts.svg';
import noAlertsGreen from '../assets/no-alerts-green.svg';
import noInsights from '../assets/no-insights.svg';
import noInsightsGreen from '../assets/no-insights-green.svg';
import noCustomAlertsGreen from '../assets/no-custom-alerts-green.svg';
import noCustomAlerts from '../assets/no-custom-alerts.svg';

export const NO_DEVICES_ONBOARDED = {
	alerts: {
		title: 'No VCA detections',
		description: 'Onboard a device',
		image: noAlerts,
	},
	insights: {
		title: 'No Threats',
		description: 'Onboard a device',
		image: noInsights,
	},
	customAlerts: {
		title: 'No Alerts',
		description: 'Onboard a device',
		image: noCustomAlerts,
	},
};

export const WAITING_FOR_DATA = {
	alerts: {
		title: 'No VCA detections',
		description: 'Waiting for data',
		image: noAlerts,
	},
	insights: {
		title: 'No Threats',
		description: 'Waiting for data',
		image: noInsights,
	},
	customAlerts: {
		title: 'No Alerts',
		description: 'Waiting for data',
		image: noCustomAlerts,
	},
};

export const NOTHING_TO_WORRY = {
	alerts: {
		title: 'No VCA detections',
		description: 'within the last 48 hours',
		image: noAlertsGreen,
	},
	insights: {
		title: 'No Threats',
		description: 'Nothing to worry about',
		image: noInsightsGreen,
	},
	customAlerts: {
		title: 'No Alerts',
		description: '',
		image: noCustomAlertsGreen,
	},
};
