// React
import PropTypes from 'prop-types';
import React from 'react';
// Redux
import { connect } from 'react-redux';
import { setTitle } from '../app/controller/redux/app/appActions';
import {
	updateUser, deleteUser, addUser,
} from './controller/services/requests';
import { openSnackbar } from '../snackbarAlert/controller/redux/actions';

// Components
// import SmartTable from "../../shared/components/smartTable/smartTable";
// import { tableConfig } from "./controller/constantes/tableConfig";
// third party
import DeleteModal from '../../shared/components/deleteModal/deleteModal';
import FormModalUser from '../../shared/components/smartTable/modals/FormModalUser';
import { ADMIN_ROLE_VALUE, ROLES } from '../../shared/components/smartTable/modals/const';
import StyleButton from '../../shared/components/form/Buttons/StyleButton';
import PlusIcon from 'mdi-react/PlusIcon';
import { getUsers, updateForm } from '../app/controller/redux/user/userActions';
import UsersTable from './view/UserTable';
import { getNewEntryFields, getAllowEdit, filterDelete } from './controller/businessLogic/formManagement';
import { configUserManagement } from './configUserManagement';
import { isUsersRestrictionExceeded } from '../../helpers/restrictions';
import { FEATURES } from '../../constants/planFields';

const SAVE_ERROR_MESSAGE = 'Something went wrong. Try again';
const INVITE_SUCCESS_MESSAGE = 'Invitation has been sent';
const MINIMUM_ADMINS_NUMBER = 1;

class UserManagement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			modalOpen: false,
			selectedRow: null,
			newUser: { role: { target: { value: ADMIN_ROLE_VALUE } } },
			isEdit: false,
			deleteModalOpen: false,
			deletableUser: null,
			isLoading: false,
		};
	}

	componentDidMount = async () => {
		this.props.setTitle(configUserManagement.pageTitle);
		this.setState({ isLoading: true });
		this.props.getUsers();
		this.setState({ isLoading: false });
	};

	deleteUserModal = deletableUser => { // Activate modal
		this.setState({ deleteModalOpen: true, deletableUser });
	};

	handleDeleteEntity = async () => {
		try {
			await deleteUser(this.state.deletableUser._id);
			this.props.getUsers();
			this.setState({ deleteModalOpen: false, deletableUser: null });
		} catch (e) {
			this.props.openSnackbar("error", SAVE_ERROR_MESSAGE, 4000);
		}
	};

	addUserModal = () => {
		this.props.updateForm({});
		this.setState({ selectedRow: null, modalOpen: true, isEdit: false });
	};

	editUserModal = data => { /// open edit user modal and pass its data
		const selectedRow = { data:
				{ ...data, /// spread dataModal to prevent binding table row
					isAdmin: ROLES.find(role => role.label === data.isAdmin).value,
				} };
		this.setState({ selectedRow, modalOpen: true, isEdit: true });
		this.props.updateForm(selectedRow);
	};

	handleFormCancel = () => { /// on close modal handle all things for both edit and add new user modal
		this.setState({
			selectedRow: null,
			modalOpen: false,
			deleteModalOpen: false,
			deletableUser: null,
			newUser: {},
		});
		this.props.updateForm({});
	};

	handleEditDataChange = ({ prop, value }) => { /// save edited data and update the form that is handled by redux form
		const { selectedRow } = this.state;
		selectedRow.data[prop] = value;
		console.log({ selectedRow });
		this.setState({ selectedRow });
	};

	handleNewEntryInputChange = user => { /// save input changess from the form of newly creted user
		const newUser = this.state.newUser || {};
		newUser[user.prop] = user.value;
		this.setState({ newUser });
	};

	onEditUser = async selectedRow => {
		try {
			await updateUser(selectedRow._id, {
				email: selectedRow.email,
				firstName: selectedRow.firstName,
				lastName: selectedRow.lastName,
				isAdmin: selectedRow.isAdmin,
			});
			this.props.getUsers();
			this.props.openSnackbar('success', INVITE_SUCCESS_MESSAGE, 4000);

			this.setState({ newUser: {}, modalOpen: false });
		} catch {
			this.props.openSnackbar('error', SAVE_ERROR_MESSAGE, 4000);
		}
	};

	onInviteUser = async newUser => { /// save the data from the form of newly creted user and update redux form
		try {
			await addUser(newUser);
			this.props.getUsers();
			this.props.openSnackbar('success', INVITE_SUCCESS_MESSAGE, 4000);

			this.setState({ newUser: {}, modalOpen: false });
		} catch {
			this.props.openSnackbar('error', SAVE_ERROR_MESSAGE, 4000);
		}
	};

	render() {
		const {
			selectedRow,
			modalOpen,
			deleteModalOpen,
			isEdit,
			newUser,
		} = this.state;
		const { users } = this.props;
		const newEntryFields = getNewEntryFields();
		const editFields = getAllowEdit();
		const isNotEnoughAdmins = isEdit && filterDelete(users).filter(user => user.isAdmin).length <= MINIMUM_ADMINS_NUMBER;

		return (
			<React.Fragment>
				{users ? <div className="content">

					<div className="top-section-user">
						<div className="define-group-text">

						</div>
						<StyleButton onClick={this.addUserModal}
							label="Invite user"
							icon={<PlusIcon/>}
							className="table-add-button"
							hasRestrictions
							isRestrictionsExceededFunction={isUsersRestrictionExceeded}
							feature={FEATURES.USER}
						/>
					</div>
					<div className="management-container">
						{
							users
								? <UsersTable
									onDelete={this.deleteUserModal}
									filterable={false}
									data={filterDelete(users)}
									isLoading={this.state.isLoading}
									onEdit={this.editUserModal}
								/>
								: null
						}
						<FormModalUser
							selectedRow={selectedRow}
							modalOpen={modalOpen}
							handleEditDataChange={this.handleEditDataChange}
							onEditClick={this.onEditUser}
							toggle={this.handleFormCancel}
							isEdit={isEdit}
							editFields={editFields}
							newEntryFields={newEntryFields}
							onNewEntryClick={this.onInviteUser}
							handleNewEntryInputChange={this.handleNewEntryInputChange}
							newUser={newUser}
							isRoleDisabled={isNotEnoughAdmins}
						/>
						<DeleteModal
							modalOpen={deleteModalOpen}
							toggle={this.handleFormCancel}
							onConfirm={this.handleDeleteEntity}
							message={'Are you sure you want to delete this user?'}
						/>
					</div>
				</div> : null}
			</React.Fragment>
		);
	}
}

UserManagement.propTypes = {
	getUsers: PropTypes.func,
	openSnackbar: PropTypes.func,
	setTitle: PropTypes.func,
	updateForm: PropTypes.func,
	users: PropTypes.array,
};

const mapStateToProps = state => ({
	user: state.auth.user,
	users: state.userReducer.users.data,
});

const mapDispatchToProps = {
	setTitle,
	updateForm,
	openSnackbar,
	getUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
