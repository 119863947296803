import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header:{
        backgroundColor: '#EFEFEF',
        border: '1px solid #DBDFE0',
    },
    cell:{
        borderBottom: '1px solid #DBDFE0',
        '&:last-child td, &:last-child th': {
            borderBottom: '1px solid white',
        }
    },

    basicFontSize:{
        fontSize: '14px',
    },

    link: {
        "&:hover":{
            color: theme.palette.primary.main
        }
    },

    paid: {
        color: 'white',
        backgroundColor: '#7287A5',
        borderRadius: '2px',
        padding: '3px 7px',
    },

    incomplete:{
        color: '#7287A5',
        border: '1px solid #7287A5',
        backgroundColor: 'white',
        borderRadius: '2px',
        padding: '3px 1px',
    },

    total: {
        fontWeight: '700',
        fontSize: '14px'
    },

}));

export {useStyles}
