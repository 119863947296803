const BACKGROUND_PROCESS_URI = "analysis/v1";
const CREATE_BACKGROUND_PROCESS_URI = `${BACKGROUND_PROCESS_URI}/trigger/`;
const BACKGROUND_PROCESS_STATUS_URI = `${BACKGROUND_PROCESS_URI}/status`;
const CREATE_PROCESS_BY_ID_URI = `${BACKGROUND_PROCESS_URI}/trigger-by-query-id/`;
const ANALYZE_DATA_URI = `${BACKGROUND_PROCESS_URI}/analyzeVisibility`;
export {
    BACKGROUND_PROCESS_URI,
    CREATE_BACKGROUND_PROCESS_URI,
    BACKGROUND_PROCESS_STATUS_URI,
    CREATE_PROCESS_BY_ID_URI,
    ANALYZE_DATA_URI
}