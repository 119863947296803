import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	container: {
		margin: '20px',
	},
	tab: {
		fontSize: '16px',
		textTransform: 'none',
		fontWeight: 'bold',
	},

	borderSelected: {
		height: '1px !important',
	},
	table: {
		maxHeight: '750px',
	},
}));

export default useStyles;
