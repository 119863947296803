import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import useStyles from './endpointsTable.css';

const EndpointsTable = ({ columns, children, emptyTableMessage }) => {
	const classes = useStyles();

	return (
		<TableContainer>
			<Table stickyHeader aria-label="sticky table" className={classes.table}>
				<TableHead>
					<TableRow>
						{columns.map(column => (
							<TableCell
								className={classes.tableHead}
								key={column.id}
							>
								{column.label}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{children}
				</TableBody>
			</Table>
			{emptyTableMessage}
		</TableContainer>
	);
};

EndpointsTable.propTypes = {
	columns: PropTypes.array,
	children: PropTypes.any,
	emptyTableMessage: PropTypes.any,
};
EndpointsTable.defaultValue = {
	emptyTableMessage: null,
};

export default EndpointsTable;

