const AUTH_URI = 'auth/v1';
const USER_URI = 'users/v1';
const INVITATIONS_URI = 'invitations/v1';
const ACCOUNTS_URI = 'accounts/v1';
const ACCOUNT_CUSTOMIZATION_URI = 'account-customization/v1/';
const PAYMENTS_URI = 'payments/v1';
const DASHBOARD_INTERACTIONS_URI = 'dashboard-interactions/v1';
const DASHBOARD_INTERACTIONS_USER_URI = 'dashboard-interactions/v1/user';

export {
    AUTH_URI,
    USER_URI,
    INVITATIONS_URI,
    ACCOUNTS_URI,
    ACCOUNT_CUSTOMIZATION_URI,
    PAYMENTS_URI,
    DASHBOARD_INTERACTIONS_URI,
    DASHBOARD_INTERACTIONS_USER_URI
}