import React from 'react';
import { transformNumber } from '../../../services/common';
import './infoBox.scss';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

const InfoBox = ({ icon, info, desc, isLoading }) => (
	<div className="infobox">
		<img src={icon} alt="" className="icon"/>
		<div className="info-wrapper">
			<div className="info">
				{isLoading
					? <CircularProgress className="info-loader"/>
					: transformNumber(info)}

			</div>
			<div className="desc">
				{desc}
			</div>
		</div>
	</div>
);

InfoBox.propTypes = {
	icon: PropTypes.string,
	info: PropTypes.string,
	desc: PropTypes.string,
	isLoading: PropTypes.bool,
};
export default InfoBox;
