const config = require(`./config.${process.env.REACT_APP_CYNAMICS_ENV}.json`);
import appClientMapping from './appClientConfigMapping';
import { graphsGroups } from './services/enums';
const { REACT_APP_CLIENT_ID } = process.env;

console.log('process.env', process.env);

const conf = {
	server: {
		url: config.serverUrl,
	},
	googleMapsApiKey: config.googleMapsApiKey,
	graphsGroup: graphsGroups.STANDARD, // GraphGroups.WITH_ATTACK_PROB OR graphGroups.STANDARD
	stripePublicKey: config.stripePublicKey,
	googleAnalyticsTrackingCode: config.googleAnalyticsTrackingCode,
	cognitoConfiguration: appClientMapping[REACT_APP_CLIENT_ID],
};

export default conf;
