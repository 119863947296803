import http from '../../services/httpService/http';
import { USER_URI } from '../app/controller/services/urls';

export const phoneUpdate = async phone => http.put(`${USER_URI}/phone`, { phone });
export const verifyPhone = async verificationCode => http.post(`${USER_URI}/phone/verify`, { verificationCode });
export const verifyTotp = async verificationCode => http.post(`${USER_URI}/mfa/otp/verify`, { verificationCode });
export const mfaToggle = async mfaType => http.put(`${USER_URI}/mfa`, { mfa: mfaType });
export const generateOtp = async mfaType => http.post(`${USER_URI}/mfa/otp`, { mfa: mfaType });
export const verifyOtp = async verificationCode => http.post(`${USER_URI}/mfa/otp/verify`, { verificationCode });

