/* eslint-disable no-console */
import React from "react";
import { Button, Input, PopoverBody } from "reactstrap";

import "./notification-save-query-name.scss";

export default class NotificationPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentName: this.props.object.title,
            newName: "",
            isOpen: true,
        };
    }

    render() {
        const { currentName, isOpen, newName } = this.state;

        return isOpen ? (
            <PopoverBody className="notif-background-process">
                <div className="notif-upper-section">
                    <div className="notif-upper-section-input">
                        <Input
                            className="text-input"
                            type="text"
                            placeholder={currentName}
                            value={newName}
                            onChange={(newName) => this.setState({ newName: newName.target.value })}
                            maxlength="30"
                        />
                    </div>
                </div>

                <div className="notif-lower-section">
                    <button
                        className="notif-button action-save"
                        color="primary"
                        onClick={(e) => {
                            this.props.handleClick("save", { ...this.props.object, newName });
                        }}
                    >
                        Save
                    </button>
                    <button
                        className="notif-button action-cancel"
                        color="danger"
                        onClick={() => {
                            this.props.handleClick("cancel");
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </PopoverBody>
        ) : null;
    }
}
