const SERVICE_NAME = "Alerts"
const SERVICE_DESCRIPTION = "Set up custom alerts to notify over network events"

const STEP_1_TITLE = "Creating alerts";
const STEP_1_DESCRIPTION = "By pressing on the ‘New Alert’ button you can create a new custom alert.\n" +
    "            Similar to ‘Analysis’ -\n" +
    "            specify the query you're interested in, and the threshold threshold you'd like to notify over."

const STEP_2_TITLE = "My alerts";

const STEP_3_TITLE = "Alerts bar";
const STEP_3_DESCRIPTION = "See how many alerts were triggered on each day, by clicking on the red bars.";

const STEP_4_TITLE = "More options";
const STEP_4_DESCRIPTION = "Update the alert’s threshold, edit alert or remove it.";

export {
    SERVICE_NAME,
    SERVICE_DESCRIPTION,
    STEP_1_TITLE,
    STEP_1_DESCRIPTION,
    STEP_2_TITLE,
    STEP_3_TITLE,
    STEP_3_DESCRIPTION,
    STEP_4_TITLE,
    STEP_4_DESCRIPTION
}