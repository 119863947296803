import { FormControl, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useStyles } from './dropdown.css';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import PropTypes from 'prop-types';

const SimpleSelectWithIcon = ({ defaultValue, items, onChange, isDisabled, ...props }) => {
	const iconComponent = props => (
		<ExpandMoreIcon className={props.className + ' ' + classes.icon}/>
	);

	const handleChange = event => {
		onChange(event.target.value);
	};

	const classes = useStyles();
	const menuProps = {
		classes: {
			paper: classes.paper,
			list: classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
	};

	return <FormControl required={true} className={classes.formControl}>
		<Select
			onChange={handleChange}
			disableUnderline
			displayEmpty
			MenuProps={menuProps}
			IconComponent={iconComponent}
			classes={{ root: classes.select }}
			defaultValue={defaultValue}
			disabled={isDisabled}
			{...props}
		>
			{items.map(({ label, icon: Icon, value, isDisabled = false }) => {
				const IconComponent = Icon;
				return <MenuItem key={value} value={value} disabled={isDisabled}>
					<IconComponent style={{ marginRight: '15px', marginBottom: '2px' }}/>{label}
				</MenuItem>;
			})}
		</Select>
	</FormControl>;
};

SimpleSelectWithIcon.propTypes = {
	defaultValue: PropTypes.string,
	items: PropTypes.array,
	onChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	className: PropTypes.string,
};

export default SimpleSelectWithIcon;
