import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import PriceBlock from "../../plan/priceBlock/priceBlock";
import {useStyles} from "./orderSummary.css";
import PropTypes from 'prop-types';

const propTypes = {
    selectedPlan: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    setSelectedPrice: PropTypes.func,
    selectedPrice: PropTypes.string,
    titleColor: PropTypes.string,
    titleSize: PropTypes.oneOf(["md", "sm"])

};

function OrderSummary({
                          selectedPlan,
                          title,
                          onPriceChange,
                          selectedPrice,
                          selectedPriceName,
                          titleColor = "primary",
                          titleSize = "md"
                      }) {
    const classes = useStyles();


    return (
        <Grid item container direction={"column"}>
            <Grid item>
                <Typography className={classes[titleSize]} color={titleColor} variant={"h4"}
                            component={"span"}>{title}</Typography>
            </Grid>
            <Grid item>
                <PriceBlock align={"baseline"}
                            planType={selectedPlan.baseProductId}
                            selectedPriceName={selectedPrice.priceName}
                            pricesArray={selectedPlan.prices}
                            onPriceChange={onPriceChange}
                />
            </Grid>
        </Grid>
    )
}

OrderSummary.propTypes = propTypes;
export default OrderSummary;