import _ from 'lodash';
import moment from 'moment';
import { mapPayloadToForm } from './payload-transformations';
import { ALERT_VERSION } from './create-version';

const setGraphAsLoading = (graphs, payload) => {
	if (!_.isEmpty(graphs)
        && !_.isEmpty(payload)
        && _.has(payload, 'index')
        && !_.isEmpty(graphs[payload.index])) {
		graphs[payload.index].isLoading = payload.status;
	}

	return graphs;
};

const createFromDateToDate = (value, timeUnit) => {
	const to = moment().add(1, 'days').startOf('days').unix();
	const from = moment().add(1, 'days').startOf('days').subtract(value, timeUnit).unix();
	return { to, from };
};

const createInitialPaylaod = () => {
	const initialValuesFormCreate = mapPayloadToForm({
		data: {
			originalQuery: {
				timeWindow: 60, queryTypes: [1], excludedIps: null, devices: [],
				srcPorts: {
					incSrcPorts: [],
					excSrcPorts: [],
				},
				destPorts: {
					incDestPorts: [],
					excDestPorts: [],
				},
				ipProtocols: {
					incIpProtocols: [],
					excIpProtocols: [],
				},
				extra: {
					excludedIpsDest: [], includedIpsDest: [], excludedIpsSrc: [], includedIpsSrc: [],
					typeproto: { isIn: false, isOut: false },
					firewall: { isFWE_ALLOW: false, isFWE_DENY: false },
				},
				version: ALERT_VERSION, enabled: true,
				ipWhoIs: {},
				aws: {
					accountIds: [],
					interfacesIds: [],
					vpcIds: [],
					subnetsIds: [],
					instanceIds: [],
					regions: [],
					azs: [],
					srcServices: [],
					destServices: [],
				},
			},
			threshold: {
				type: 'GT', value: 10,
			},
		},
	});
	return initialValuesFormCreate;
};

export {
	setGraphAsLoading, createFromDateToDate,
	createInitialPaylaod,
};
