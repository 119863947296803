import {loadStripe} from "@stripe/stripe-js";
import conf from "../../../config";

let stripe;
const getStripeInstance = () => {
    if (!stripe) {
        stripe = loadStripe(conf.stripePublicKey);
    }
    return stripe;
}

export default getStripeInstance;
