import http from "../../../services/httpService/http";
const SUBSCRIPTION_PATH = `subscriptions/v2`;
const CONTACT_SALES_PATH = `contact-sales/v1/payment-request`;

const createSubscription = async (data) => {
    return await http.put(SUBSCRIPTION_PATH, data);
}

const updatePaymentMethod = async (data) => {
    return await http.put(`${SUBSCRIPTION_PATH}/current/payment-method`, data);
}
const contactSalesTeam = async (data) =>{
    return await http.post(CONTACT_SALES_PATH, data);
}
export {createSubscription, contactSalesTeam, updatePaymentMethod};