import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import HighlightFilterDropdown from './highlight/highlightFilterDropdown';
import useStyles from './filtersBlock.css';
import TimeRangeFilter from './timeRange/timeRangeFilter';

const FiltersBlock = ({ onFiltersChange, initialFilters }) => {
	const classes = useStyles();
	const [filters, setFilters] = useState(initialFilters);

	useEffect(() => {
		onFiltersChange(filters);
	}, [filters]);

	const onHighlight = filter => {
		if (filter.value) {
			setFilters({ ...filters, highlighted: { ...filter } });
		} else {
			const currentFilters = { ...filters };
			currentFilters.highlighted && delete currentFilters.highlighted;
			setFilters(currentFilters);
		}
	};

	const onTimeRangeChange = filter => {
		setFilters({ ...filters, timeRange: { ...filter } });
	};

	return (
		<div className={classes.container}>
			<TimeRangeFilter onTimeRangeChange={onTimeRangeChange}/>
			<HighlightFilterDropdown onHighlight={onHighlight} />
		</div>
	);
};

FiltersBlock.defaultProps = {
	initialFilters: {},
};

FiltersBlock.propTypes = {
	onFiltersChange: PropTypes.func.isRequired,
	initialFilters: PropTypes.shape({
		timeRange: PropTypes.shape({
			from: PropTypes.number.isRequired,
			to: PropTypes.number.isRequired,
		}),
	}),
};

export default FiltersBlock;

