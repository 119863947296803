import React from 'react';
import useStyles from './BadgeButton.css';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { COLOR_NORMAL, COLOR_WHITE, FONT_MEDIUM, FONT_SMALL } from './consts';

const BadgeButton = ({ label, badgeContent, onClick, disabled = false, color = COLOR_NORMAL, font = FONT_MEDIUM, contained = false }) => {
	const classes = useStyles({ color, font, contained });

	return (
		<>
			{badgeContent
				? <Badge badgeContent={badgeContent} color={'primary'} classes={{ badge: classes.badge }}>
					<Button size={'small'} variant="outlined" classes={{ root: classes.button, outlined: classes.active }} onClick={onClick}>{label}</Button>
				</Badge>
				: <Button disabled={disabled} size={'small'} variant="outlined" classes={{ root: classes.button }} onClick={onClick}>{label}</Button>
			}
		</>

	);
};

BadgeButton.propTypes = {
	label: PropTypes.string.isRequired,
	badgeContent: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	color: PropTypes.oneOf([COLOR_NORMAL, COLOR_WHITE]),
	font: PropTypes.oneOf([FONT_SMALL, FONT_MEDIUM]),
	contained: PropTypes.bool,
};

export default BadgeButton;
