// Plans flow types
const UPGRADE_TO_CUSTOM_PLAN_FLOW_TYPE = 'UPGRADE_TO_CUSTOM_PLAN';
const UPGRADE_TO_PREMIUM_PLAN_FLOW_TYPE = 'UPGRADE_TO_BASIC_PLAN';
const DOWNGRADE_FROM_CUSTOM_PLAN_TYPE = 'DOWNGRADE_FROM_CUSTOM_PLAN';
const DOWNGRADE_FROM_BASIC_PLAN_TYPE = 'DOWNGRADE_FROM_BASIC_PLAN';

export {
    UPGRADE_TO_CUSTOM_PLAN_FLOW_TYPE,
    UPGRADE_TO_PREMIUM_PLAN_FLOW_TYPE,
    DOWNGRADE_FROM_CUSTOM_PLAN_TYPE,
    DOWNGRADE_FROM_BASIC_PLAN_TYPE
}