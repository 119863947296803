import action from "../middleware";
export const UPDATE_REGISTER_CAPTCHA = "UPDATE_REGISTER_CAPTCHA"

const updateRegisterCaptchaToken = (captchaToken) => {
    return action(async dispatch => {
        dispatch({
            type: UPDATE_REGISTER_CAPTCHA,
            payload: { captchaToken }
        });
    });
};

export {
    updateRegisterCaptchaToken
}
