import React from "react";
import step1Side from "../../../../../../assets/walkthrough/overview/steps/step1side.png";
import step2Main from "../../../../../../assets/walkthrough/overview/steps/step2main.gif";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step2.css";
import MainPanelTemplateV2
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const Step2 = () => {
    const classes = useStyles();
    const description = (
        <>
            Top-talkers’ details
            such as top source-IPs, <br/>
            destination-IPs, <br/>
            source-ports, <br/>
            destination-ports and
            protocols, can be viewed
            by clicking on the graphs:
        </>
    )
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV2 title="Graphs bar"
                                 image={<img src={step2Main}/>}
                                 description={description}/>
        </StepTemplate>
    )
}

export default Step2;