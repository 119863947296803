import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	noSegment: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	noDataMainText: {
		color: theme.palette.inputText.main,
		fontSize: theme.typography.subtitle.fontSize,
		fontWeight: theme.typography.subtitle.fontWeight,
	},
	noDataSubText: {
		color: theme.palette.inputText.main,
		fontSize: theme.typography.subtext.fontSize,
		marginTop: '15px !important',
		textAlign: 'center',
	},
	noDataImage: {
		width: '125px',
		marginBottom: '24px',
	},
}));

export default useStyles;
