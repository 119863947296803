import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  wrapper: {
    marginBottom: 0,
    paddingLeft: "50px",
    paddingTop: "40px",
    paddingRight: "50px",
  },
  input: {
    color: theme.palette.inputText.main,
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: "16px",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
  },
  description: {
    marginBottom: theme.spacing(3),
    fontSize: "14px",
    color: theme.palette.dialogDescription.main,
  },
  addButton: {
    height: "100%",
    width: "100%",
    boxShadow: "none",
  },
  formActions: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px",
  },
  saveButton: {
    margin: "10px",
  },
  select: {
    color: theme.palette.inputText.main,
    fontSize: "14px",
    "&:focus": {
      background: "white",
      color: theme.palette.secondary.main,
    },
    "& MuiInputLabel": {
      backgroundColor: "white",
    },
  },
  paper: {
    marginTop: 8,
    borderColor: theme.palette.border.main,
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    maxHeight: "385px",
    boxShadow: "none",
    overflowY: "scroll",
  },
  list: {
    color: theme.palette.inputText.main,
    paddingTop: 0,
    paddingBottom: 0,
    background: "white",
    boxShadow: "none",
    "& li": {
      paddingTop: 10,
      paddingBottom: 10,
    },
    "& li:hover": {
      background: theme.palette.selectorMenuHover.main,
    },
    "& li.Mui-selected": {
      color: "white",
      background: theme.palette.primary.main,
      fontWeight: "bold",
    },
    "& li.Mui-selected:hover": {
      background: theme.palette.primary.main,
    },
  },
}));

export { useStyles };
