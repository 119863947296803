import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';
import sso from '../../assets/sso/sso.svg';
import { useStyles } from './DialogSSO.css';
import { Typography } from '@material-ui/core';
import SubmitButton from '../../shared/components/form/Buttons/SubmitButton';
import { useDispatch } from 'react-redux';
import {
	updateUserNotifiedOnSSOMigration,
} from '../app/controller/redux/dashboardInteractions/actions';

const SSO_TITLE = 'Log in with SSO';
const SSO_MESSAGE_LINE_1 = 'From now on, please use SSO for logging in to Cynamics.';
const SSO_MESSAGE_LINE_2 = 'Your previous password option is now disabled.';
const SSO_BUTTON_TEXT = 'I understand';

const DialogSSO = () => {
	const classes = useStyles();
	const [isOpen, setIsOpen] = useState(true);
	const dispacth = useDispatch();

	const handleClose = () => {
		setIsOpen(false);
		dispacth(updateUserNotifiedOnSSOMigration(true));
	};

	return (
		<>
			<Dialog maxWidth={'md'} open={isOpen}>
				<div className={classes.root}>
					<img src={sso} className={classes.image} alt={'sso image'}/>
					<Typography className={classes.title}>
						{SSO_TITLE}
					</Typography>
					<Typography className={classes.description}>
						{SSO_MESSAGE_LINE_1}<br/>{SSO_MESSAGE_LINE_2}
					</Typography>
					<SubmitButton label={SSO_BUTTON_TEXT} className={classes.button} onClick={handleClose}/>
				</div>
			</Dialog>
		</>
	);
};

export default DialogSSO;
