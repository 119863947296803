import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Grid } from '@material-ui/core';
import useStyles from './groupsManagmentTabs.css';
import GroupsPage from '../groupsPage/groupsPage';
import TabPanel from '../../../../shared/components/tabPanel/tabPanel';
import { configGroupManagement } from '../configGroupManagement';
import GroupModal from '../ipgroupModal/groupModal';
import AliasGroupForm from '../ipAliasesForm/ipAliasesForm';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import PlusIcon from 'mdi-react/PlusIcon';

const propTypes = {
	assetsGroups: PropTypes.object,
	customGroups: PropTypes.object,
	ipAliasesGroups: PropTypes.object,
	onLoadGroups: PropTypes.func,
};

const {
	ASSETS_GROUP_LABEL,
	CUSTOM_GROUP_LABEL,
	ALIAS_GROUP_LABEL,
	TABS_ARIA_LABEL,
	REMOVE_ALIAS_CONFIRMATION_MESSAGE,
	REMOVE_GROUP_CONFIRMATION_MESSAGE,
	ADD_GROUP_BUTTON_LABEL,
	ADD_ALIAS_BUTTON_LABEL,
} = configGroupManagement;

const CUSTOM_GROUP_ADD_GROUP_MODAL_COMPONENT_KEY = 'customGroupAddGroupModalComponent';
const ASSETS_ADD_GROUP_MODAL_COMPONENT_KEY = 'assetsAddGroupModalComponent';
const IP_ALIASES_GROUP_MODAL_COMPONENT_KEY = 'ipAliasesGroupModalComponent';

const tabPanelsGroupModals = {
	[CUSTOM_GROUP_ADD_GROUP_MODAL_COMPONENT_KEY]: GroupModal,
	[ASSETS_ADD_GROUP_MODAL_COMPONENT_KEY]: GroupModal,
	[IP_ALIASES_GROUP_MODAL_COMPONENT_KEY]: AliasGroupForm,
};

const addButtonLabels = [ADD_GROUP_BUTTON_LABEL, ADD_GROUP_BUTTON_LABEL, ADD_ALIAS_BUTTON_LABEL];

const GroupsManagementTabs = ({ customGroups, assetsGroups, ipAliasesGroups, onLoadGroups }) => {
	const classes = useStyles();

	const [tabNumber, setTabNumber] = useState(0);
	const [isAddButtonClicked, setIsAddButtonClicked] = useState(false);

	const handleChange = (event, newValue) => {
		setTabNumber(newValue);
	};

	return (
		<>
			<Grid className={classes.tabsBox} flex>
				<Tabs
					TabIndicatorProps={{ className: classes.tabIndicator }}
					value={tabNumber}
					onChange={handleChange}
					style={{ float: 'left' }}
					textColor={'primary'}
					aria-label={TABS_ARIA_LABEL}>
					<Tab className={classes.tab} label={ASSETS_GROUP_LABEL} />
					<Tab className={classes.tab} label={CUSTOM_GROUP_LABEL} />
					<Tab className={classes.tab} label={ALIAS_GROUP_LABEL} />
				</Tabs>
				<StyleButton
					style={{ float: 'right' }}
					label={addButtonLabels[tabNumber]}
					icon={<PlusIcon />}
					onClick={() => setIsAddButtonClicked(true)}
				/>
			</Grid>
			<TabPanel value={tabNumber} index={0} className={classes.tabContent}>
				<GroupsPage
					data={assetsGroups}
					loadGroups={onLoadGroups}
					groupModalComponent={tabPanelsGroupModals[ASSETS_ADD_GROUP_MODAL_COMPONENT_KEY]}
					isAddButtonClicked={isAddButtonClicked}
					setIsAddButtonClicked={setIsAddButtonClicked}
					removeConfirmationMessage={REMOVE_GROUP_CONFIRMATION_MESSAGE}
				/>
			</TabPanel>
			<TabPanel value={tabNumber} index={1} className={classes.tabContent}>
				<GroupsPage
					data={customGroups}
					loadGroups={onLoadGroups}
					groupModalComponent={tabPanelsGroupModals[CUSTOM_GROUP_ADD_GROUP_MODAL_COMPONENT_KEY]}
					isAddButtonClicked={isAddButtonClicked}
					setIsAddButtonClicked={setIsAddButtonClicked}
					removeConfirmationMessage={REMOVE_GROUP_CONFIRMATION_MESSAGE}
				/>
			</TabPanel>
			<TabPanel value={tabNumber} index={2} className={classes.tabContent}>
				<GroupsPage
					data={ipAliasesGroups}
					loadGroups={onLoadGroups}
					groupModalComponent={tabPanelsGroupModals[IP_ALIASES_GROUP_MODAL_COMPONENT_KEY]}
					isAddButtonClicked={isAddButtonClicked}
					setIsAddButtonClicked={setIsAddButtonClicked}
					removeConfirmationMessage={REMOVE_ALIAS_CONFIRMATION_MESSAGE}
				/>
			</TabPanel>
		</>
	);
};

GroupsManagementTabs.propTypes = propTypes;

export default GroupsManagementTabs;
