import Envelope from "../../../assets/referAFriend/stepper/envelope.png"
import Page from "../../../assets/referAFriend/stepper/page.png"
import Earn from "../../../assets/referAFriend/stepper/give.png"

const stepperConfig = [
    {
        title: "Send Invitation",
        description: `Send a referral link to\n your friends`,
        icon: Envelope
    },
    {
        title: "Registration",
        description: `Friends register to Cynamics via your\n referral link and onboard a device`,
        icon: Page
    },
    {
        title: "15 more events!",
        description: `You and your friend\n get 15 more events`,
        icon: Earn
    }
]

export {stepperConfig};