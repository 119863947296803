// React
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
// Components
import Notification from '../../../../shared/components/Notification/Notification';
import ErrorBoundary from '../layout/error-boundary';
// Redux
import { clearMessage } from '../../controller/redux/message/messageActions';
import { getFeaturesAsync, getGroupsAsync, getAccountPublicFields } from '../../controller/redux/account/accountActions';
import { getSummaryData, getTotals, enrichNotification } from '../../../backgroundProcess/controller/redux/analyzeActions';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../controller/redux/auth/authActions';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { getAlertesGraph, getAlertes } from '../../../alertsManagement/controller/redux/actions';
import { getThreats } from '../../../threatsManagement/controller/redux/threatsActions';
import { newUpdateBackgroundProcess, initBackgroundProcess } from '../../../backgroundProcess/controller/redux/backgroundProcessAction';
import IntervalWrapper from '../IntervalWrapper/intervalWrapper';
import { getLiteDevices, getLiteCollectorDevices, getLitePublicCloudDevices } from '../../../deviceManagement/controller/lightweightReducer/actions';
import { closeSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import SnackBarAlert
	from '../../../../shared/components/snackBarAlert/snackBarAlert';
import { getVisibleDevicesCount } from '../../../deviceManagement/controller/visibleDeviceCount/actions';
import { isUserAuthenticated } from '../../../authenticateUser/services/tools';

class MainWrapper extends PureComponent {
	static propTypes = {
		children: PropTypes.element.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			loaded: false,
		};
	}

	async UNSAFE_componentWillMount() {
		if (isUserAuthenticated()) {
			this.props.getGroupsAsync();
			this.props.getFeaturesAsync();
			this.props.getLiteDevices();
			this.props.getLiteCollectorDevices();
			this.props.getLitePublicCloudDevices();
			this.props.initBackgroundProcess();
			this.props.getVisibleDevicesCount();
			this.setState({ loaded: true, summaryPull: true });
			this.updateTotals();
			this.updateThreats();
			await this.props.getAlertes();
			await this.props.getAlertesGraph();
			this.updateSummaryData();
		} else {
			this.setState({ loaded: true, summaryPull: false });
		}
	}

	async updateSummaryData() {
		if (!_.isEmpty(this.props.user)) {
			await this.props.getSummaryData();
		}
	}

	async initBackgroundProcessWrapper() {
		await this.props.initBackgroundProcess();
	}

	async updateTotals() {
		if (!_.isEmpty(this.props.user)) {
			await this.props.getTotals();
		}
	}

	async updateThreats() {
		this.props.getThreats();
	}

	/** ComponentDidUpdate(prevProps, prevState, snapshot) {
          this.setState({ summaryPull: !!this.props.user });
     }
  */
	componentDidCatch(error, info) {
		console.log(error.message);
	}

	render() {
		const { children, snackbarAlert } = this.props;
		return (
			<IntervalWrapper>
				<React.Fragment>
					<div className="wrapper">
						<ErrorBoundary>
							{ this.state.loaded && children }
						</ErrorBoundary>
					</div>
					<SnackBarAlert isOpen={snackbarAlert.isOpen}
						severity={snackbarAlert.severity}
						autoHideDuration={snackbarAlert.autoHideDuration}
						onClose={this.props.closeSnackbar}
					    action={snackbarAlert.action}
						customClass={snackbarAlert.customClass}
					>
						{ snackbarAlert.message }
					</SnackBarAlert>
				</React.Fragment>
			</IntervalWrapper>);
	}
}

export default withRouter(
	connect(state =>
		({
			user: state.auth.user,
			theme: state.theme,
			message: state.message,
			summaryData: state.analyze.summaryData,
			features: state.account.features,
			processes: state.backgroundProcesses.processes,
			currentProcess: state.backgroundProcesses.currentProcess,
			needSubscription: state.auth.needSubscription,
			threats: state.threats.threats,
			alertes: state.alertes.alertes,
			graphs: state.alertes.graphs,
			snackbarAlert: state.snackbarAlert,
			banners: state.banners,
			publicDevices: state.liteDevices.publicDevices,
			collectorDevices: state.liteDevices.collectorDevices,
			publicCloudDevices: state.liteDevices.publicCloudDevices,
			title: state.app.title,
		}),
	{
		clearMessage,
		getCurrentUser,
		getSummaryData,
		enrichNotification,
		getFeaturesAsync,
		newUpdateBackgroundProcess,
		getTotals,
		getAlertes,
		getAlertesGraph,
		getGroupsAsync,
		initBackgroundProcess,
		getThreats,
		getLiteDevices,
		getLiteCollectorDevices,
		getLitePublicCloudDevices,
		closeSnackbar,
		getAccountPublicFields,
		getVisibleDevicesCount,
	},
	)(MainWrapper),
);
