import React, { Component } from 'react';
import './filters.scss'
import FilterTime from './filterTime';
import _ from 'lodash';
import { connect } from 'react-redux';
import { updateAlertStatistic, getAlertes, getAlertesGraph } from '../../controller/redux/actions';
import { allOptionsFilterShow } from '../../controller/constants/setFiltersContent';
import { REFRESH_ALERTS } from '../../../../constants/constants';

class Filters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterByDefaultValue: _.isEmpty(this.props.selectedFilter) ? allOptionsFilterShow[0].title : this.props.selectedFilter.title
        }
    }

    componentDidMount = () => {
        const detaultTitle = _.isEmpty(this.props.selectedFilter) ? allOptionsFilterShow[0].title : this.props.selectedFilter.title;
        this.setState({ filterByDefaultValue: detaultTitle });
        setInterval(async () => {
            await this.props.getAlertesGraph();
            await this.props.getAlertes();
        }, REFRESH_ALERTS)
    }
    onCloseFilterBy = async (value, unit, title) => {

        this.props.updateAlertStatistic(value, unit, title);
        this.setState({ filterByDefaultValue: title });

        await this.props.getAlertesGraph();
        await this.props.getAlertes();
    }
    onCloseSortBy = (e) => {
        this.setState({ filterSortByDefaultValue: e });
    }
    onCloseShow = (e) => {

        this.setState({ filterShowDefaultValue: e });
    }
    render() {
        const { filterByDefaultValue } = this.state;
        return (

            <div className="filter-alertes">
                <div className="filter-title">
                    Show
               </div>
                <div className="filter-title-indicator">
                    <FilterTime
                        value={ filterByDefaultValue }
                        onCloseReponse={ this.onCloseFilterBy }
                    />
                </div>

            </div>
        );
    }
}





const mapStateToProps = state => {

    return {
        selectedFilter: state.alertes.selectedFilter
    };
};
const mapDispatchToProps = {
    updateAlertStatistic,
    getAlertes,
    getAlertesGraph
};
export default connect(mapStateToProps, mapDispatchToProps)(Filters);