import React from "react";
import createAlertsSide from "../../../../../../assets/walkthrough/customAlerts/steps/createAlertPosition.png";
import createAlertsMain from "../../../../../../assets/walkthrough/customAlerts/steps/createAlertDialog.png";
import thresholds from "../../../../../../assets/walkthrough/customAlerts/steps/thresholds.png";
import {STEP_1_DESCRIPTION, STEP_1_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV3
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV3/mainPanelTemplateV3";

const Step1 = () => {

    return (
        <StepTemplate sidePanelImage={createAlertsSide}>
            <MainPanelTemplateV3 title={STEP_1_TITLE}
                                 image={<img src={createAlertsMain} alt={"Create new alert dialog"}/>}
                                 description={STEP_1_DESCRIPTION}
                                 subDescriptionImage={<img src={thresholds} alt={"Alert threshold"}/>}/>
        </StepTemplate>
    )
}

export default Step1;