const UPDATE_GRAPHS = "UPDATE_GRAPHS";
const REQ_ALERTS = "REQ_ALERTS";
const REQ_ALERTS_SUCCESS = "REQ_ALERTS_SUCCESS";
const REQ_ALERTS_FAIL = "REQ_ALERTS_FAIL";
const SELECTED_ALERT_EDITION = "SELECTED_ALERT_EDITION";
const ALERT_STATISTIC = "ALERT_STATISTIC";
const SELECTED_FILTER = "SELECTED_FILTER";
const REMOVE_GRAPH = "REMOVE_GRAPH";
const UPDATE_GIVEN_GRAPH = "UPDATE_GIVEN_GRAPH";
const LOADING_GRAPH = "LOADING_GRAPH";
const LOADING_GIVEN_GRAPH = "LOADING_GIVEN_GRAPH";
const SET_ALERT_POP_OVER = "SET_ALERT_POP_OVER";
const ALERT_FAIL = "ALERT_CREATION_FAIL";
const CLEAR_ALERT_ERROR = "CLEAR_ALERT_CREATION_FAIL";

export {
    UPDATE_GRAPHS,
    LOADING_GRAPH,
    REMOVE_GRAPH,
    ALERT_STATISTIC,
    SELECTED_ALERT_EDITION,
    SELECTED_FILTER,
    REQ_ALERTS_FAIL,
    REQ_ALERTS_SUCCESS,
    LOADING_GIVEN_GRAPH,
    REQ_ALERTS,
    UPDATE_GIVEN_GRAPH,
    SET_ALERT_POP_OVER,
    ALERT_FAIL,
    CLEAR_ALERT_ERROR,
};
