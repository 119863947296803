import store from '../containers/app/controller/redux/store/store'
import {openSnackbar} from "../containers/snackbarAlert/controller/redux/actions";
import {getQueryById} from "../containers/backgroundProcess/controller/services/visibilityView/requests";
import {createBackgroundProcessesById} from "../containers/backgroundProcess/controller/redux/backgroundProcessAction";

const createLinkToQuery = async (queryId) => {
    const data = await getQueryById(queryId);
    const originalQuery = JSON.parse(data.query["OriginalQuery"]);
    const creationTime = data.query["CreationTime"];
    const response = await store.dispatch(createBackgroundProcessesById(queryId, originalQuery, creationTime));
    return response;
}

const createQuery = async (queryId, texts) => {
    try {
        const response = await createLinkToQuery(queryId);
        response ?  store.dispatch(openSnackbar("success", texts.querySuccess, 4000))  :
            store.dispatch(openSnackbar("error", texts.reachedMaxError, 4000))

    } catch (e) {
        store.dispatch(openSnackbar("error", texts.queryCreationFailed, 4000));
    }
}

export {createQuery};