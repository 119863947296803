import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		fontSize: '12px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'start',
	},
	icon: {
		borderRadius: 3,
		backgroundColor: 'white',
		flex: '0 0 auto',
		padding: '10px',
		transform: 'scale(0.8)',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
	},
	checkboxLabel: {
		fontSize: '12px',
		color: 'white',
	},
}));

export { useStyles };
