import action from "../../../app/controller/redux/middleware";
import _ from 'lodash';
import http from "../../../../services/httpService/http";
import {THREATS_URI} from "../services/urls";
import {THREATS_SEVERITIES_CONFIG} from "../constants/constanteSeverity";

export const REQ_TREATH = "REQ_TREATH";
export const REQ_DELETE_Threats = "REQ_DELETE_Threats";
const getThreats = () => {
    return action(async (dispatch, getState) => {
        const threats = await http.get(THREATS_URI);
        const threatValues = threats.map((threat, i) => {
            const {
                assets,
                deviceData,
                eventId,
                isMarked,
                severity,
                threatType,
                firstDetectionTime,
                lastDetectionTime

            } = threat;
            const { id:deviceId, name:deviceName } = deviceData
            threat =  {
                Id: eventId,
                startTime: firstDetectionTime,
                endTime: lastDetectionTime,
                device: deviceId,
                threatName: threatType,
                severity: THREATS_SEVERITIES_CONFIG[severity],
                isMarked,
                deviceName: deviceName
            }
            if(assets) {
                threat['assets'] = {...assets}
            }
            return threat

        }).sort((a, b) => {
            return parseInt(b.date) - parseInt(a.date)
        });

        dispatch({
            type: REQ_TREATH, payload: {
                threats: threatValues
            }
        })
        return threats;
    })
}

const getThreatById = (timestamp) => {
    return action(async (dispatch, getState) => {
        const threath = await http.post(`${THREATS_URI}/query`, { timestamp })[0];
        dispatch(getThreats())
        return threath;
    })
}
// getbyID TO REMOVE 

const markThreat = (timestamp, status) => {
    return action(async (dispatch, getState) => {
        const threath = await http.put(`${THREATS_URI}/mark`, { timestamp, status })[0];
        dispatch(getThreats())
        return threath
    })
}

const removeThreatById = (timestamp, status) => {
    return action(async (dispatch, getState) => {
        const threath = await http.put(`${THREATS_URI}/ignore`, { timestamp, status })[0];
        dispatch(getThreats())
        return threath
    })
}   

const feedbackAnomalies = (object, annexes) => {
    return action(async (dispatch, getState) => {
        const { reason, description } = annexes;

        const values = getState().threats.threats.find(e => { return e.endTime / 1000 == object.Timestamp })
        const threath = await http.post(`${THREATS_URI}/feedback`, { ...values, annexes });
        return true
    })
}


export {
    getThreats,
    getThreatById,
    markThreat,
    removeThreatById,
    feedbackAnomalies
}