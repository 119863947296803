import React from 'react';
import './input.scss';

import Done from '../../../../assets/threshold/done.svg';
import Error from '../../../../assets/threshold/error.svg';
import { isValidInt } from '../../../../containers/alertsManagement/controller/validations/fieldValidation';
import makeReduxTimeUnit from '../timeUnit/maker/timeUnitMaker';

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning }
}) => (
        <div className="field-input-threshold">
            <div className="field-input">
                <input { ...input } placeholder={ label } type={ type } />
                { isValidInt(input.value) ? <img src={ Done } /> : <img src={ Error } /> }
            </div>

        </div>
    )


export default renderField;
