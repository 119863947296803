import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	table: {
		maxHeight: '100%',
		overflow: 'auto',
		border: '0.334px solid #DBDFE0',
		borderBottom: 'none',
	},

	tableHead: {
		background: '#E3E6EA',
		fontWeight: 'bold',
	},
}));

export default useStyles;
