import http from '../../../../services/httpService/http';
import { DAILY_TOP_DETECTIONS_REPORT } from '../constants/integrationTypes';
import { INTEGRATIONS_DAILY_TOP_DETECTIONS_REPORT_PATH, INTEGRATIONS_ENABLED_PATH } from '../constants/urls';

const activeDailyTopDetectionsReportIntegration = newValue => http.put(INTEGRATIONS_ENABLED_PATH, { type: DAILY_TOP_DETECTIONS_REPORT, newValue });

const updateDailyTopDetectionsReportIntegration = recipients => http.put(INTEGRATIONS_DAILY_TOP_DETECTIONS_REPORT_PATH, { emails: recipients });

export {
	activeDailyTopDetectionsReportIntegration,
	updateDailyTopDetectionsReportIntegration
};

