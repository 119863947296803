import React, { Component } from "react";
import SidebarLink from "./SidebarLink";
import MenuIcon from "mdi-react/MenuIcon";

class SidebarContent extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { links = [] } = this.props;
        return (
            <div className="sidebar__content">
                {/*<div className='sidebar-item' onClick={ this.props.changeSidebarVisibility }>*/}
                {/*    <MenuIcon className='sidebar-image' />*/}
                {/*</div>*/}
                {links.map(link =>
                    <SidebarLink
                        key={ link.title + link.route }
                        title={ link.title }
                        route={ link.route }
                        img={ link.img }
                        onClick={ () => {
                            link.onClick && link.onClick();
                        } }
                    />
                ) }
            </div>
        );
    }
}

export default SidebarContent;
