import React, {Component, useEffect, useState} from 'react';
import BaseIntegrationForm from '../baseIntegrationForm';
import './cynamicsApisIntegration.scss';
import {s3Address} from '../../../../deviceManagement/controller/constantes/osType';
import {getSecret} from "../../../controller/services/cynamicsApiRequests";
import {Field, FieldArray} from "redux-form";
import TextField from "../../../../../shared/components/form/TextField/TextField";
import {InputAdornment} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {FileCopy, Visibility, VisibilityOff} from "@material-ui/icons";
import SubmitButton from "../../../../../shared/components/form/Buttons/SubmitButton";

const downloadApiDocumentation = `https://${process.env.REACT_APP_CYNAMICS_ENV}-cynamics-public-assets.${s3Address}.amazonaws.com/API+Documentation.pdf`;
const timerDisplaySecret = 3000;
const FORM_NAME = "cynamics-api-integration-form"

class CynamicsApisIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isClicked: false,
        }
        this.timeout = {};
    }

    CynamicsApiIntegration = ({change}) => {
        const [authValues, setAuthValues] = useState(this.state.authValues)
        const [isSecret, setIsSecret] = useState(true)

        useEffect(() => {
            const asyncFunc = async () => {
                const authValues = await getSecret()
                setAuthValues(authValues)
                change('clientId', authValues.clientId)
                change('clientSecret', authValues.clientSecret)
            }
            asyncFunc();

        }, [])

        const downloadConfiguration = async (link) => {
            if (typeof (link) == 'string') {
                let a = document.createElement('a');
                a.href = link;
                a.click();
            }
        }

        return <>
            <Field
                component={TextField}
                label="Client ID"
                className="form-field"
                name="clientId"
                disabled={true}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    navigator.clipboard.writeText(authValues.clientId)
                                }}
                                disableRipple={true}
                                fontSize={"small"}
                                edge="end"
                            >
                                <FileCopy fontSize={"small"}/>
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Field
                component={TextField}
                label="Client Secret"
                className="form-field"
                name="clientSecret"
                type={isSecret ? 'password' : 'text'}
                disabled={true}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    setIsSecret(!isSecret)
                                }}
                                disableRipple={true}
                                fontSize={"small"}
                                edge="end"
                            >
                                {isSecret ? <Visibility fontSize={"small"}/> : <VisibilityOff fontSize={"small"}/>}
                            </IconButton>
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => {
                                    navigator.clipboard.writeText(authValues.clientSecret)
                                }}
                                disableRipple={true}
                                fontSize={"small"}
                                edge="end"
                            >
                                <FileCopy fontSize={"small"}/>
                            </IconButton>
                        </InputAdornment>

                    )
                }}
            />

            <SubmitButton
                label={'Download Configuration'}
                type={'Download'}
                style={{textAlign: 'center', marginBottom: '20px', marginTop: '20px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}
                onClick={() => downloadConfiguration(downloadApiDocumentation)}
            />
        </>
    }

    render() {
        return (
            <BaseIntegrationForm
                title="Integrate with Cynamics APIs"
                subTitle="Integrate with Cynamics using the API Key below."
                form={FORM_NAME}
                onSubmit={() => {}}
                withSaveButton={false}
                initialValues={this.state.initialValue}
                BaseIntegrationFormComponent={this.CynamicsApiIntegration}
            />)
    }
}

export default CynamicsApisIntegration;
