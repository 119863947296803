import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import StyleButton from '../../shared/components/form/Buttons/StyleButton';
import React from 'react';
import { useStyles } from './moreOptionsDialog.css';
import TcpFlagsFields from './tcpFlagsFields/tcpFlagsFields';
import PropTypes from 'prop-types';
import InterfacesFields from './interfacesFields/interfacesFields';
import Divider from '@material-ui/core/Divider';
import InOut from './inOut/inOut';
import VlanFields from './vlanFields/vlanFields';

const propTypes = {
	isOpen: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	formName: PropTypes.string.isRequired,
};

const MoreOptionsDialog = ({ isOpen, handleClose, formName }) => {
	const classes = useStyles();
	return (
		<div>
			<Dialog classes={{ paperScrollPaper: classes.root }}
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="more-options-dialog"
				PaperProps={{ style: { border: '2px black' } }} >
				<DialogContent className={classes.root}>
					<DialogContentText className={classes.wrapper}>
						<span className={classes.sectionTitle}>TCP Flags</span>
						<div className={classes.description}>
							<span>Filter by the exact flag combination.</span>
							<span>e.g. SYN, ACK will result only in packets with both SYN, ACK. </span>
						</div>
						<TcpFlagsFields formName={formName}/>

						<Divider className={classes.divider}/>

						<span className={classes.sectionTitle}> Interfaces </span>
						<span className={classes.description}> Filter by device interface numbers </span>
						<InterfacesFields/>

						<Divider className={classes.divider}/>

						<span className={classes.sectionTitle}> Direction </span>
						<span className={classes.description}> Traffic direction to/from the device </span>
						<InOut formName={formName}/>

						<Divider className={classes.divider}/>

						<span className={classes.sectionTitle}> Virtual LAN </span>
						<span className={classes.description}> Filter by VLAN and VXLAN </span>
						<VlanFields formName={formName} />

					</DialogContentText>
				</DialogContent>
				<DialogActions classes={{ root: classes.actions }}>
					<StyleButton className={classes.save} onClick={handleClose} label="Add Options" color="primary"/>
				</DialogActions>
			</Dialog>
		</div>);
};

MoreOptionsDialog.propTypes = propTypes;
export default MoreOptionsDialog;
