import { mapMiminulGroupValues } from "../businessLogic/mapper";
import { STORE_GROUPS } from "./actionTypes";
import { initialValue } from './initialValues';

// TO DO : complete the get and store the minimal version of the Group list 

export default function (state = initialValue, { type, payload }) {
    switch (type) {
        case STORE_GROUPS:
            
            return {
                ...state,
                groups: payload
            };
        default:
            return state;
    }
}