import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
	},
	boxContent: {
		padding: '0.4em',
		display: 'flex',
		flexDirection: 'row',
		margin: 'auto',
		alignItems: 'center',
	},
	messageGroup: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'column',
		textAlign: 'left',
	},
	image: {
		height: '200px',
		width: '200px',
	},
	title: {
		backgroundColor: 'transparent',
		padding: 0,
		fontSize: '24px',
		fontWeight: 'bold',
		color: '#5B6F87',
	},
	description: {
		fontSize: '16px',
		color: '#5B6F87',
	},
}));

export default useStyles;
