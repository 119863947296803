import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { Component } from 'react';
import { Modal } from 'reactstrap';
import DeviceIcon from '../../../assets/modal/device.svg';
import CloseButton from '../form/Buttons/CloseButton';

const DEFAULT_MODAL_WIDTH = 640;
const DEFAULT_MODAL_HEIGHT = 'auto';

export default class SimpleModal extends Component {
	render() {
		const width = this.props.width ? this.props.width : DEFAULT_MODAL_WIDTH;
		const height = this.props.height ? this.props.height : DEFAULT_MODAL_HEIGHT;
		const { iconStyle, returnBackCallback } = this.props;

		return (
			<Modal contentClassName="smart-form-modal" isOpen={this.props.isOpen} toggle={this.props.toggle} style={{ ...{ width }, minWidth: width, ...this.props.style }}>
				<div className="edit-device-modal" style={{ justifyItems: 'center !important', height }}>
					<div className="modal-header" style={{ flexDirection: 'row', height: '120px', justifyContent: 'center', position: 'relative', zIndex: 2 }}>
						{returnBackCallback && <Button onClick={returnBackCallback} style={{ color: '#9ca0a4', display: 'flex', alignItems: 'center', justifyContent: 'space-around', padding: '3px', borderRadius: '3px', position: 'absolute', top: '20px', left: '10px' }}>
							<ArrowBackIcon />
						</Button>}
						<img src={this.props.icon || DeviceIcon} alt="" className="header-image" style={iconStyle ? iconStyle : { marginRight: '4%', marginTop: '2%', width: 131, height: 130 }} />
						<div className="title">
							{this.props.header}
						</div>
						<CloseButton onClick={this.props.toggle} />
						{this.props.backgroundImage && <img src = {this.props.backgroundImage} height="100%" style={{ position: 'absolute', height: '100%', width: '100%', zIndex: -1 }}/>}
					</div>
					{this.props.children}
				</div>
			</Modal>
		);
	}
}
