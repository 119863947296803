import { Divider, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './Actions.css';
import StarIcon from '@material-ui/icons/Star';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CustomTooltip from '../../../../shared/components/Tooltip/CustomTooltip';

const ActionsFullScreen = ({ onClose, eventData, onDelete, onHighlight, showDeleteHighlight, className }) => {
	const classes = useStyles();
	const [isMarked, setIsHighlighted] = useState(eventData && eventData.isMarked);

	useEffect(() => {
		setIsHighlighted(eventData && eventData.isMarked);
	}, [eventData]);

	const getTooltipContent = text => <p className={classes.tooltipText}>{text}</p>;

	const handleDelete = () => {
		onClose();
		onDelete();
	};

	const handleHighlight = () => {
		setIsHighlighted(!isMarked);
		onHighlight();
	};

	return (
		<div className={`${classes.fullScreenWrapper} ${className}`}>
			{
				showDeleteHighlight && <>
					<CustomTooltip PopperProps={{ disablePortal: true }} className={classes.iconTooltip} title={getTooltipContent('Delete')}>
						<IconButton className={classes.icon} onClick={handleDelete}>
							<DeleteIcon />
						</IconButton>
					</CustomTooltip>
					<Divider className={classes.fullScreenDivider} orientation="vertical"/>
					<CustomTooltip className={classes.iconTooltip} title={isMarked ? getTooltipContent('Remove highlight') : getTooltipContent('Highlight')}>
						<IconButton onClick={handleHighlight}>
							<StarIcon className={`${isMarked ? classes.highlighted : classes.icon}`} alt="highlight"/>
						</IconButton>
					</CustomTooltip>
				</>
			}
			<Divider className={classes.fullScreenDivider} orientation="vertical"/>
			<CustomTooltip className={classes.iconTooltip} title={getTooltipContent('Exit full screen')}>
				<IconButton className={classes.icon} onClick={onClose}>
					<FullscreenExitIcon fontSize="large" />
				</IconButton>
			</CustomTooltip>
		</div>
	);
};

ActionsFullScreen.propTypes = {
	onClose: PropTypes.func.isRequired,
	eventData: PropTypes.object,
	onDelete: PropTypes.func,
	onHighlight: PropTypes.func,
	showDeleteHighlight: PropTypes.bool,
	className: PropTypes.object,
};

export default ActionsFullScreen;
