import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    orderSummaryContainer: {
        width: '65%',
        height: '100%'
    },
    orderSummaryTitle: {
        paddingTop: '15px',
        fontSize: '20px !important',
        fontWeight: '600'
    }
}));


export {useStyles}