export const customSelectorStyles = {
	control: base => ({
		...base,
		height: 70,
		minHeight: 70,
		cursor: 'text',
	}),
	option: base => ({
		...base,
		cursor: 'pointer',
	}),
};
