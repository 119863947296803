import React, { Component } from 'react';
import _ from "lodash"
import './lineMetaWrapper.scss'
class LineMetaWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        let { label, value, extra } = this.props;
        let isPositive = !_.isEmpty(extra) && extra.includes('-') ? "minus" : extra == '0' ? ' ' : 'plus';
        const davalue = !_.isEmpty(extra) ? extra.replace('+', '').replace('-', '') : null;
        if (extra == '0') {
            isPositive = ''
        }
        return (
            <div className="line-meta-wrapper">
                <div className="header">{ label }</div>
                <div className="bottom">
                    <div className="values">{ value }</div>
                    { !_.isEmpty(extra) ? <div className={ `extra extra-${isPositive}` }>
                        { isPositive == 'plus' ? '+' : isPositive == '' ? '' : '-' }    { davalue } %

                     </div> : null }
                </div>
            </div>

        );
    }
}



export default LineMetaWrapper;