import { Link, Paper, Typography, Zoom } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorImage from '../../../../assets/error-image.png';
import SuccessImage from '../../../../assets/success-image.png';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import { useStyles } from './configuringProcess.css';

// Success massages
const DeviceSuccessMessage = ({ history }) => {
	const classes = useStyles();
	return (
		<>
			<Zoom in={true}>
				<Paper elevation={0} className={classes.successMessagePaper}>
					<img className={classes.indicateImage}
						src={SuccessImage}
						alt=""
					/>
					<Typography className={classes.indicateTitle}>
                        Well done, your device is now active.
					</Typography>
					<Typography className={classes.indicateSubTitle}>
                        Let{'\''}s go to your dashboard
					</Typography>
					<StyleButton
						onClick={() => history.push('/overview')}
						label="Go to 'Overview'"
						className={classes.goToOverviewButton}
					/>
				</Paper>
			</Zoom>
		</>);
};

// Error massages
const DeviceErrorMessage = () => {
	const classes = useStyles();
	return (
		<>
			<Paper elevation={0} className={classes.indicatePaper}>
				<img className={classes.indicateImage}
					src={ErrorImage}
					alt="error message"
				/>
				<Typography className={classes.indicateTitle}>
                        Something went wrong?
				</Typography>
				<Typography className={classes.tipsSubtitle}>
                        Try these common troubleshooting methods: <br/>
				</Typography>
				<div>
					<Typography className={classes.tips}>
                        1. Make sure you’ve configured the correct device IP in the dashboard <br/>
                        2. Create a firewall rule to allow traffic to Cynamics <br/>
                        3. Check that flow traffic on the respective port is apparent in the firewall log
					</Typography>
					<Typography className={classes.contactUs}>
                        Didn’t work? Contact us at &nbsp;
						<Link
							variant="body2"
							href="mailto:support@cynamics.ai"
						>
							<Typography
								color="primary"
								type="body3"
								style={{
									textDecoration: 'none',
									'&:hover': {
										color: 'primary',
									},
									display: 'inline-flex',
									marginBottom: '2px',
								}}>
                                support@cynamics.ai

							</Typography>

						</Link>
                        &nbsp; for help
					</Typography>
				</div>
			</Paper>
		</>
	);
};

const PublicCloudDeviceErrorMessage = () => {
	const classes = useStyles();
	return (
		<>
			<Paper elevation={0} className={classes.awsIndicatePaper}>
				<img className={classes.indicateImage}
					src={ErrorImage}
					alt=""
				/>
				<Typography className={classes.indicateAwsCloudTitle}>
                        Something went wrong?
				</Typography>
				<Typography className={classes.indicateSubTitle}>
                        Try these common troubleshooting methods: <br/>
				</Typography>
				<div>
					<Typography className={classes.tipsAwsCloudDevice}>
                        1. Note you can track the stack’s progress under the ‘Events’ tab <br/><br/>
                        2. Make sure you have all permissions necessary to install the stack.<br/> Ideally, let an account admin install the stack <br/><br/>
                        3. Verify the following parameters are exactly as provided in the Cynamics Dashboard,<br/> under the Parameters tab: AWSAccountId, AWSDeviceType, AWSRegionName, DeviceIdentifier <br/><br/>
                        4. If a stack fails, fully revert and delete the stack and try again with a new one <br/><br/>
                        5. If a stack fails to revert, verify all its resources can be deleted.<br/> Some resources, like S3, require them to be empty or disabled in order to delete
					</Typography>
					<Typography className={classes.contactUsCloud}>
                        Didn’t work? Contact us at &nbsp;
						<Link
							variant="body2"
							href="mailto:support@cynamics.ai"
						>
							<Typography
								color="primary"
								style={{
									textDecoration: 'none',
									'&:hover': {
										color: 'primary',
									},
									display: 'inline-flex',
									marginBottom: '2px',
									fontSize: 'inherit',
								}}>
                                support@cynamics.ai

							</Typography>
						</Link>
                        &nbsp; for help
					</Typography>
				</div>
			</Paper>
		</>
	);
};

const GcpErrorMessage = () => {
	const classes = useStyles();
	return (
		<>
			<Paper elevation={0} className={classes.indicatePaper}>
				<img className={classes.indicateImage}
					src={ErrorImage}
					alt=""
				/>
				<Typography className={classes.indicateAwsCloudTitle}>
						Something went wrong?
				</Typography>
				<Typography className={classes.indicateSubTitle}>
						Try these common troubleshooting methods: <br/>
				</Typography>
				<div>
					<Typography className={classes.tipsAwsCloudDevice}>
						1. Make sure you have all the permissions necessary to create the new resources. Ideally, let an account admin run the command. <br/><br/>
						2. If the command fails, try to re-run. Contact Cynamics if a problem persists.<br/><br/>
						3. Note that granting permissions to the logging sink is done manually using the IAM console. The IAM principal will be printed once the command completes.<br/><br/>
					</Typography>
					<Typography className={classes.contactUsCloud}>
						Didn’t work? Contact us at &nbsp;
						<Link
							variant="body2"
							href="mailto:support@cynamics.ai"
						>
							<Typography
								color="primary"
								style={{
									textDecoration: 'none',
									'&:hover': {
										color: 'primary',
									},
									display: 'inline-flex',
									marginBottom: '2px',
									fontSize: 'inherit',
								}}>
								support@cynamics.ai

							</Typography>
						</Link>
						&nbsp; for help
					</Typography>
				</div>
			</Paper>
		</>
	);
};

DeviceSuccessMessage.propTypes = {
	history: PropTypes.array,
};
export default {
	guide: {
		successMessage: DeviceSuccessMessage,
		errorMessage: DeviceErrorMessage,
	},
	script: {
		successMessage: DeviceSuccessMessage,
		errorMessage: DeviceErrorMessage,
	},
	none: {
		successMessage: DeviceSuccessMessage,
		errorMessage: DeviceErrorMessage,
	},
	awsCloudDevice: {
		successMessage: DeviceSuccessMessage,
		errorMessage: PublicCloudDeviceErrorMessage,
	},
	gcpCloudDevice: {
		successMessage: DeviceSuccessMessage,
		errorMessage: GcpErrorMessage,
	},
};
