import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	mainGrid: {
		height: '150vh',
		display: 'block',
		overflowX: 'auto',
	},
	storeHealthGrid: {
		height: '49.9%',
		maxHeight: '49.75%',
		padding: '1%',
	},
	dividerGrid: {
		height: '0.2%',
		width: '100%',
	},
	deviceMonitorGrid: {
		height: '49.9%',
	},
}),
);

export { useStyles };
