import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import CreateableSelect from 'react-select/creatable';
import clsx from 'clsx';

import makeReduxCreatable from '../commonSelectCreatable/makeReduxCreatable';
import { ReactComponent as Minus } from '../../../../../assets/selectors/minus.svg';
import { ReactComponent as Plus } from '../../../../../assets/selectors/plus.svg';

import { EXCLUDE_SIGN, INCLUDE_SIGN } from './consts';
import useStyles from './directionSelectCreatable.css';
import CustomTooltip from '../../../Tooltip/CustomTooltip';

const DirectionSelectCreateable = ({
	onChange: saveChanges,
	value,
	options,
	validation,
	isFreeText,
	placeholder,
	createOptionOnStart,
	additionalClasses,
	extraStyles,
	customMultiValueComponent,
	noOptionsMessage = 'No Options',
	addType = () => null,
	disabled = false,
}) => {
	const classes = useStyles();
	const [, setRender] = useState({}); // Used to force re-render the component after changing a chip direction

	const isValidNewOption = value => {
		if (validation) {
			return validation(value);
		}

		return Boolean(isFreeText && value);
	};

	const changeSignHandler = (e, optionValue) => {
		const currentValue = [...value];
		const optionIndex = currentValue.findIndex(option => option.value === optionValue);
		currentValue[optionIndex].direction = currentValue[optionIndex].direction === EXCLUDE_SIGN ? INCLUDE_SIGN : EXCLUDE_SIGN;
		saveChanges(currentValue);
		setRender({});
		e.stopPropagation(); // We do this in order to stop the menu from opening when clicking on a chip
	};

	const customMultiValueChip = props => {
		// eslint-disable-next-line react/prop-types
		const { data: { direction, label, value }, innerRef } = props;

		return (
			<components.MultiValue
				className={clsx(classes.wrapper, {
					[classes.included]: direction === INCLUDE_SIGN,
					[classes.excluded]: direction === EXCLUDE_SIGN })}
				ref={innerRef}
				{...props}
			>
				<CustomTooltip
					title={`Click to ${direction === INCLUDE_SIGN ? 'exclude' : 'include'}`}
					PopperProps={{ style: { marginTop: -5, marginRight: -20 } }}
				>
					<div className={classes.chipContainer} onClick={(e) => changeSignHandler(e, value)}>
						<button className={classes.directionButton}>{direction === INCLUDE_SIGN ? <Plus /> : <Minus />}</button>
						<span className={classes.label}>{label}</span>
					</div>
				</CustomTooltip>
			</components.MultiValue>
		);
	};

	const removeDuplicates = e => e.reduce((acc, option) => {
		const hasValue = acc.valueSet.has(option.value);
		const hasLabel = acc.labelSet.has(option.label);

		if (!hasValue && !hasLabel) {
			acc.uniqueOptions.push(option);
			acc.valueSet.add(option.value);
			acc.labelSet.add(option.label);
		}

		return acc;
	}, { uniqueOptions: [], valueSet: new Set(), labelSet: new Set() }).uniqueOptions;

	const updateNewValueHandler = e => {
		let newOptions = [];
		if (e) {
			newOptions = removeDuplicates(e).map(option => {
				const type = option.type || addType(option);
				return {
					label: option.label,
					value: option.value,
					direction: option.direction || INCLUDE_SIGN,
					...(type && { type }),
				};
			});
		}

		saveChanges(newOptions);
	};

	return (
		<CreateableSelect
			isMulti
			isClearable
			name={'create'}
			value={value}
			onChange={updateNewValueHandler}
			isValidNewOption={isValidNewOption}
			options={options}
			placeholder={placeholder}
			createOptionPosition={createOptionOnStart ? 'first' : 'last'}
			formatCreateLabel={value => `Add free text: ${value}`}
			noOptionsMessage={() => noOptionsMessage}
			styles={extraStyles}
			className={`react-select-redux-forms react-select-underline react-select-creatable ${additionalClasses}`}
			classNamePrefix="react-select"
			isDisabled={disabled}
			components={{
				MultiValue: customMultiValueComponent || customMultiValueChip,
			}}
		/>
	);
};

DirectionSelectCreateable.propTypes = {
	addType: PropTypes.func,
	additionalClasses: PropTypes.string,
	createOptionOnStart: PropTypes.bool,
	customMultiValueComponent: PropTypes.node,
	disabled: PropTypes.bool,
	extraStyles: PropTypes.object,
	isFreeText: PropTypes.bool,
	noOptionsMessage: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	validation: PropTypes.func,
	value: PropTypes.any,
};

export default makeReduxCreatable(DirectionSelectCreateable);
