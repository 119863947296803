import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import PropTypes from 'prop-types';

const LoadingTableBody = ({ columns, rowLength }) =>
	<>
		{Array.from({ length: rowLength }).map((_, rowIndex) => (
			<TableRow key={rowIndex}>
				{columns.map((_, columnIndex) => (
					<TableCell key={`${rowIndex}-${columnIndex}`}>
						<Skeleton animation="wave" variant="text" />
					</TableCell>
				))}
			</TableRow>
		))}
	</>;

LoadingTableBody.propTypes = {
	columns: PropTypes.array,
	rowLength: PropTypes.number,
};
LoadingTableBody.defaultValue = {
};

export { LoadingTableBody };
