import { Box, CircularProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { createQuery } from '../../../../services/linkToQueryService';
import useStyles from './ViewInAnalysis.css';

const TEXTS = {
	queryCreationFailed: 'Something went wrong. Try again',
	reachedMaxError: 'You can run up to 7 queries at the same time',
	querySuccess: 'Query from event successfully created',
};

const ViewInAnalysis = ({ analysisResult, queryId }) => {
	const classes = useStyles();

	const [isQuerying, setIsQuerying] = useState(false);

	const queryAnalysis = async () => {
		setIsQuerying(true);
		await createQuery(queryId, TEXTS);
		setIsQuerying(false);
	};

	const from = moment(analysisResult.originalQuery.xDomain[0]).format('DD/MM HH:mm');
	const to = moment(analysisResult.originalQuery.xDomain[1]).format('DD/MM HH:mm');

	return <Box className={classes.wrapper}>
		<Typography className={classes.text}>
			<Typography className={`${classes.text} ${classes.boldedText}`}>Traffic over time</Typography>
			{`${from} - ${to}`}
		</Typography>
		{isQuerying
			? <CircularProgress color="primary" className={classes.analyzeLoader} />
			: <Typography className={`${classes.text} ${classes.edit}`} onClick={queryAnalysis}>Edit</Typography>}
	</Box>;
};

ViewInAnalysis.propTypes = {
	analysisResult: PropTypes.object,
	queryId: PropTypes.string,
};

export default ViewInAnalysis;
