import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { DeviceTraffic, EndpointsDeviation, InactiveEndpoints, TrafficByPort } from './index';
import { useStyles } from './DeviceMonitoring.jsx.css';
import useInterval from '../../../customHooks/useInterval';
import { ALL_DEVICES_ID, FETCH_DEVICE_MONITORING_DATA_INTERVAL_MS } from './DeviceMonitoringConfig';
import Filter from './Filter/filter';
import { getDeviceMonitoringData, getDevicesIdToDeviceNameMapping } from '../utils/DeviceMonitoringUtils';
import { useSelector } from 'react-redux';

const DeviceMonitoring = () => {
	const [ipMonitorData, setIpMonitorData] = useState({});
	const [deviceIdFilter, setDeviceIdFilter] = useState(ALL_DEVICES_ID);
	const [isLoading, setIsLoading] = useState(true);
	const [devicesIdToDeviceNameMapping, setDevicesIdToDeviceNameMapping] = useState({});

	useEffect(() => {
		getDevicesIdToDeviceNameMapping(liteDevices, setDevicesIdToDeviceNameMapping);
	}, []);

	useEffect(() => {
		getDeviceMonitoringData(deviceIdFilter, setIpMonitorData, setIsLoading);
	}, [deviceIdFilter]);

	const selectedDeviceId = async device => {
		setIsLoading(true);
		setDeviceIdFilter(device.value);
	};

	useInterval(() => getDeviceMonitoringData(deviceIdFilter, setIpMonitorData, setIsLoading),
		FETCH_DEVICE_MONITORING_DATA_INTERVAL_MS);

	const liteDevices = useSelector(state => state.liteDevices);
	const { newIps, newIpsNumber, inactiveIps, inactiveIpsNumber } = ipMonitorData;

	const classes = useStyles();
	return (
		<Grid container spacing={0} direction={'row'} lignItems="top" className={classes.deviceMonitoringGridContainer}>
			<Grid container spacing={2} item xs={12} className={classes.filter}>
				<Filter onItemSelected={selectedDeviceId}/>
			</Grid>
			<Grid item xs={6} className={classes.deviceMonitoringGridItem}>
				<DeviceTraffic deviceId={deviceIdFilter}/>
			</Grid>
			<Grid item xs={6} className={classes.deviceMonitoringGridItem}>
				<InactiveEndpoints
					{...{ newIps, newIpsNumber, inactiveIps, inactiveIpsNumber }}
					isLoading={isLoading}
					devicesIdToDeviceNameMapping={devicesIdToDeviceNameMapping}
				/>
			</Grid>
			<Grid item xs={6} className={classes.deviceMonitoringGridItem}>
				<EndpointsDeviation
					endpointsDeviation={ipMonitorData.endpointsDeviation}
					isLoading={isLoading}
					devicesIdToDeviceNameMapping={devicesIdToDeviceNameMapping}
				/>
			</Grid>
			<Grid item xs={6} className={classes.deviceMonitoringGridItem}>
				<TrafficByPort deviceId={deviceIdFilter}/>
			</Grid>
		</Grid>);
};

DeviceMonitoring.propTypes = {
};
DeviceMonitoring.defaultValue = {
};

export default DeviceMonitoring;
