import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "70px",
        maxHeight: "190px"
    },
    headCell: {
        color: "white",
        width: "40%"
    },
    header: {
        backgroundColor: theme.palette.secondary.main,
    },
    cell: {
        fontSize: "14px",
        color: theme.palette.inputText.main,
    },
    icon: {
        width: "15px",
        height: "15px",
        marginBottom: "5px",
        cursor: "pointer"
    }
}));

export {useStyles}