import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '588px',
        padding: '10px'
    },

    title: {
        fontSize: '20px !important',
        fontWeight: '600'
    },

    subtitle:{
        fontSize: '14px',
        fontWeight: '400'
    },

    submitButton: {
        width: '100%',
        height: '50px',
    },

    buttonGrid: {
        padding: '10px'
    },

    formAlignment: {
        display: 'flex',
        alignContent: 'center',
        height: '100%',
        width: '100%'
    }
}));

export {useStyles}