import React from 'react';
import './errorMessage.scss';
import SubmitButton from "./Buttons/SubmitButton";

function ErrorMessage({message, subMessage, onSubmit}) {
    return (
        <div className="form">
            <div className='form-title'>
                <div className='error-message'>
                    {message}
                </div>
                <p className="sub-message">{subMessage}</p>
            </div>
            <SubmitButton label='OK'
                          className="form-submit"
                          onClick={onSubmit}
            />
        </div>
    );
}

export default ErrorMessage;