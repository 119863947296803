import PropTypes from 'prop-types';

export const defaultProps = {
	textFieldLabel: '',
	checkboxSelectAll: {
		name: '',
	},
	checkboxes: [{
		name: '',
	}],
};

export const propTypes = {
	textFieldLabel: PropTypes.string,
	checkboxSelectAll: PropTypes.shape({
		onChange: PropTypes.func.isRequired,
		name: PropTypes.string,
	}),
	checkboxes: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string.isRequired,
		onChange: PropTypes.func.isRequired,
		checked: PropTypes.bool.isRequired,
		name: PropTypes.string,
	})),
};
