import { isValidPhoneNumber } from 'react-phone-number-input'


const phoneNumberValidation = (value) =>
    (value && !isValidPhoneNumber(value)) && value!=='+'  ?
        'Please enter a valid phone number with dialing code' : undefined

const stateFieldValidation = (value, formData) => {
    if (formData.country !== 'US') {
        return undefined;
    } else if (!value) {
        return "Please enter your state";
    }
    return undefined;
}


export {phoneNumberValidation, stateFieldValidation};