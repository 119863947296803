import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import PhoneNumberWrapper from '../../../../shared/components/phoneNumberWrapper';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { phoneValidation } from '../../../integrations/view/modals/fieldArrayComponents/smsArrayField';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import { useStyles } from './phoneNumberForm.css';
import { PHONE_NUMBER_FORM_INITIAL_VALUE, PHONE_NUMBER_KEY, SUBMITTING_MAX_COUNT } from '../setupPhoneConfig';

const PhoneNumberForm = ({ onSubmitCallback }) => {
	const classes = useStyles();
	const [submitCount, setSubmitCount] = useState(0);
	const [blockTime, setBlockTime] = useState(0);

	const { values, handleChange, touched, errors, handleSubmit,
		setFieldError, setFieldValue, setFieldTouched, isSubmitting } = useFormik({
		initialValues: PHONE_NUMBER_FORM_INITIAL_VALUE,
		validateOnChange: true,
		validateOnBlur: true,
		enableReinitialize: true,
		validate(values) {
			const errors = {};
			const { phoneNumber } = values || {};
			if (phoneNumber) {
				const phoneNumberError = phoneValidation(phoneNumber, { recipients: null });
				if (phoneNumberError) {
					errors.phoneNumber = phoneNumberError;
				}
			}

			return errors;
		},
		async onSubmit(values) {
			const { number } = parsePhoneNumber(values.phoneNumber);
			await onSubmitCallback(number);
			setSubmitCount(submitCount + 1);
			setBlockTime(30);
		},
	});

	useEffect(() => {
		if (blockTime > 0) {
			setTimeout(() => setBlockTime(time => time - 1), 1000);
		}
	}, [blockTime]);

	const formatSeconds = seconds => seconds.toString().length < 2 ? `0${seconds}` : seconds;

	const submitLabel = submitCount > 0 ? blockTime > 0 ? `Resend code in 00:${formatSeconds(blockTime)}` : 'Resend code' : 'Send code';

	return (
		<form onSubmit={handleSubmit} className={classes.form}>
			<Grid container item xs={12} className={classes.container} >
				<Grid xs={6} className={classes.phoneNumberGrid}>
					<PhoneNumberWrapper
						defaultCountry={'us'}
						name={PHONE_NUMBER_KEY}
						id={PHONE_NUMBER_KEY}
						onInputChange={handleChange}
						setFieldError={setFieldError}
						setFieldValue={setFieldValue}
						setFieldTouched={setFieldTouched}
						error={touched[PHONE_NUMBER_KEY] && Boolean(errors[PHONE_NUMBER_KEY])}
						helperText={touched[PHONE_NUMBER_KEY] && errors[PHONE_NUMBER_KEY]}
						value={values[PHONE_NUMBER_KEY]}
						InputLabelProps={{
							shrink: true,
						}}
						size="small"
						className={classes.phoneNumberGrid}
						required
					/>
				</Grid>
				<Grid xs={3} className={classes.submitButtonContainerStyle}>
					{isSubmitting
						? <CircularProgress color="primary" className={classes.circularProgress}/>
						: <SubmitButton label={submitLabel} size="small"
							className={classes.submitButton} isDisabled={isSubmitting || submitCount > SUBMITTING_MAX_COUNT || blockTime > 0}/>}
				</Grid>
			</Grid>
		</form>

	);
};

PhoneNumberForm.propTypes = {
	onSubmitCallback: PropTypes.func,
};

PhoneNumberForm.defaultProps = {
	onSubmitCallback() {},
};

export default PhoneNumberForm;

