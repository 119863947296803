import PropTypes from 'prop-types';
import React, { useState } from 'react';
import SetupPhone from './setupPhone';
import SimpleModal from '../../../shared/components/simpleTable/simpleModal';
import { Paper, Typography } from '@material-ui/core';
import { openSnackbar } from '../../snackbarAlert/controller/redux/actions';
import {
	MODAL_TYPE_TO_PHONE_SETUP_ERROR_TEXT,
	MODAL_TYPE_TO_PHONE_SETUP_SUCCESS_TEXT, MODAL_TYPE_TO_TITLE, CODE_INCORRECT_MESSAGE,
	SETUP_PHONE_CREATE_TYPE, SETUP_PHONE_EDIT_TYPE, MFA_FAILED_MESSAGE,
} from './setupPhoneConfig';
import { useDispatch } from 'react-redux';
import { mfaToggle } from '../requests';
import {NO_MFA_KEY, REMOVE_MFA_DISCLAIMER_TEXT, SMS_MFA_KEY} from '../../userSettings/mfaTextsMapping';
import SubmitButton from '../../../shared/components/form/Buttons/SubmitButton';
import { useStyles } from './setupPhoneProcessWrapper.css';
import {MFA_DISCLAIMER_TEXT, MFA_DISCLAIMER_TEXT_SECOND_LINE} from './mfaSetUpTexts';
import { updateUserInfo } from '../../app/controller/redux/auth/authActions';

const UserMfaDisclaimer = ({ onClose }) => {
	const classes = useStyles();
	return (
		<>
			<Typography className={classes.mfaDisclaimer}>
				{MFA_DISCLAIMER_TEXT}
			</Typography>
			<Typography className={classes.mfaDisclaimerSecondLine}>
				{MFA_DISCLAIMER_TEXT_SECOND_LINE}
			</Typography>
			<SubmitButton label={'Got it'} onClick={onClose} className={classes.disclaimerApproval}/>
		</>

	);
};

const SetupPhoneProcessWrapper = ({ isEditMode, isSmsMfaConfigured, onClose }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const modalType = isEditMode ? SETUP_PHONE_EDIT_TYPE : SETUP_PHONE_CREATE_TYPE;
	const [processFinished, setProcessFinished] = useState(false);

	const handleErrorSetup = () => {
	//	TODO: WIP After, if not used will be deleted
	};

	const handleSuccessSetup = async () => {
		try {
			if (isSmsMfaConfigured) {
				await mfaToggle(SMS_MFA_KEY);
				const dataToUpdate = {
					mfa: SMS_MFA_KEY,
				};
				dispatch(updateUserInfo(dataToUpdate));
			}

			setProcessFinished(true);
			dispatch(openSnackbar('success', MODAL_TYPE_TO_PHONE_SETUP_SUCCESS_TEXT[modalType], 8000));
		} catch (e) {
				dispatch(openSnackbar('error', MFA_FAILED_MESSAGE));
				const dataToUpdate = {
					mfa: NO_MFA_KEY,
				};
				dispatch(updateUserInfo(dataToUpdate));
				onClose()
		}
	};

	const renderUserDisclaimerIfNeeded = () => (
		isSmsMfaConfigured ? <UserMfaDisclaimer onClose={onClose}/> :
			onClose()
	)

	return (
		<SimpleModal
			header={MODAL_TYPE_TO_TITLE[modalType]}
			toggle={onClose}
			width={850}
			isOpen
		>
			<Paper className={classes.modalPaper}>
				{ processFinished
					? renderUserDisclaimerIfNeeded()
					: <div className={classes.setupPhoneDiv}>
						<SetupPhone onSuccessCallback={handleSuccessSetup} onErrorCallback={handleErrorSetup} onClose={onClose}/>
				      </div> }
			</Paper>

		</SimpleModal>);
};

SetupPhoneProcessWrapper.propTypes = {
	isEditMode: PropTypes.bool,
	isSmsMfaConfigured: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

SetupPhoneProcessWrapper.defaultProps = {
	isEditMode: false,
	isSmsMfaConfigured: false,
};

UserMfaDisclaimer.propTypes = {
	onClose: PropTypes.func.isRequired,
};

export default SetupPhoneProcessWrapper;

