import React, {Component} from 'react';
import StyleButton from "../../../../shared/components/form/Buttons/StyleButton";
import OrderSummary from "./base/orderSummary";
import {useStyles} from "./downgradeOrderSummary.css";
import {Grid, Link, Typography} from "@material-ui/core";
import {ReactComponent as BlueArrow} from '../../../../assets/blue-to-arrow.svg'
import {useSelector} from "react-redux";
import moment from "moment";


function DowngradeOrderSummary({selectedPlan, selectedPriceName, currentPlan, onCancel, onSubmit}) {
    const classes = useStyles();
    const currentPlanPriceKey = useSelector(state => state.plans.currentPlanDetails.priceKey);
    const currentPlanSelectedPrice = currentPlan.prices.find(price => price.priceName.toLowerCase() === currentPlanPriceKey.toLowerCase());
    const subscriptionEnd = useSelector(state => state.account.subscriptionEnd);
    const subscriptionDate = moment(subscriptionEnd).format('MMMM D, YYYY');
    const targetPriceToDisplay = selectedPlan.prices.find(price => price.priceName === selectedPriceName);


    return (
        <Grid container justify={"space-evenly"} direction={"column"} className={classes.container}>
            <Grid item className={classes.plansSummary}>
                <div>
                    <Typography paragraph variant={"body2"}>CURRENT PLAN</Typography>
                    <OrderSummary isStarting={false} priceType={currentPlanSelectedPrice.displayText} price={currentPlanSelectedPrice.price} date={subscriptionDate}
                                  planName={currentPlan.displayText} />
                </div>
                <BlueArrow className={classes.arrow} />
                <div>
                    <Typography gutterBottom variant={"body2"}>CHANGING TO</Typography>
                    <OrderSummary isStarting={true} date={subscriptionDate}
                                  priceAsLabel={targetPriceToDisplay.displayText} planName={selectedPlan.displayText}/>
                </div>

            </Grid>

            <Grid item>
                <Typography align={"left"}>
                    Your new subscription will start based on the time left in your current plan. <br /> Your new plan will
                    start on <strong>{subscriptionDate}</strong>
                </Typography>
            </Grid>

            <Grid item className={classes.disclaimer}>
                <Typography>
                    You are about to change to a more limited plan. You may manually select which premium features you
                    wish to disable yourself. If you do not indicate your preference before your plan expires, the
                    system will automatically disable any premium features.
                </Typography>
            </Grid>

            <Grid item container justify={"space-evenly"} className={classes.buttonsWrapper}>
                <Grid item>
                    <StyleButton className={classes.button} variant={"outlined"} label={"Cancel"} onClick={onCancel}/>
                </Grid>
                <Grid item>
                    <StyleButton className={classes.button} variant={"contained"} label={"Change plans"} onClick={onSubmit}/>
                </Grid>
            </Grid>

            <Grid item className={classes.link}>
                <Typography variant={"caption"}>Any questions? Ask at&nbsp;
                    <Link className={classes.linkHover} underline={"always"} href="mailto:contact@cynamics.ai">contact@cynamics.ai</Link>
                </Typography>
            </Grid>
        </Grid>
    )
}


export default DowngradeOrderSummary;