import React, { Component } from 'react';
import Advance from '../advance/advance';
import DefaultQuery from '../defaultQuery/defaultQuery';
import './query.scss';
import { getFormValues, reduxForm } from 'redux-form';

import { validateQueryForm, warningQueryForm } from '../../../controller/validations/validation';
import IpEnrichmentDialog from '../../../../ipEnrichmentFields/ipEnrichmentDialog';
import dialogTypes from '../../../../ipEnrichmentFields/dialogTypes';
import MoreOptionsDialog from '../../../../moreOptions/moreOptionsDialog';
import analyzer from '../../../../../helpers/analyzer';
import { ANALYSIS_CATEGORY, OPEN_ACTION } from '../../../../../helpers/analyzerConstants';
import CloudFilterModal from '../../../../cloudFilter/cloudFilterModal';
import { connect } from 'react-redux';
import { activeFilterMap } from './filterConfig';
import PropTypes from 'prop-types';
import { ANALYSIS_PAYLOAD_TYPE } from '../propTypes';

class Query extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSrcIpEnrichmentOpen: false,
			isDestIpEnrichmentOpen: false,
			isMoreOptionsOpen: false,
			isCloudFilterModalOpen: false,
			badgeContent: {
				srcGeo: null,
				dstGeo: null,
				cloud: null,
				moreOptions: null,
			},
		};
	}

	getActiveFilters = (data, activeFilterOptions) => activeFilterOptions.reduce((total, { count }) => {
		const res = count(data);
		return total + res;
	}, 0);

	onChange = (key, value) => {
		this.setState({ [key]: value });
	};

	handleCloseIpEnrichment = () => {
		const srcActiveFilters = this.getActiveFilters(this.props.formData, activeFilterMap.srcEnrichment);
		const dstActiveFilters = this.getActiveFilters(this.props.formData, activeFilterMap.dstEnrichment);

		this.setState({ isSrcIpEnrichmentOpen: false, isDestIpEnrichmentOpen: false, badgeContent: {
			...this.state.badgeContent,
			srcGeo: srcActiveFilters,
			dstGeo: dstActiveFilters,
		} });
	};

	handleOpenSrcIpEnrichment = () => {
		analyzer.event({ category: ANALYSIS_CATEGORY, action: OPEN_ACTION, label: 'SRC_IP_ENRICHMENT_FILTERS' });
		this.setState({ isSrcIpEnrichmentOpen: true });
	};

	handleOpenDestIpEnrichment = () => {
		analyzer.event({ category: ANALYSIS_CATEGORY, action: OPEN_ACTION, label: 'DEST_IP_ENRICHMENT_FILTERS' });
		this.setState({ isDestIpEnrichmentOpen: true });
	};

	handleOpenMoreOptions = () => {
		analyzer.event({ category: ANALYSIS_CATEGORY, action: OPEN_ACTION, label: 'MORE_OPTIONS_FILTERS' });
		this.setState({ isMoreOptionsOpen: true });
	};

	handleCloseMoreOptions = () => {
		const activeFilters = this.getActiveFilters(this.props.formData, activeFilterMap.moreOptions);
		this.setState({ isMoreOptionsOpen: false, badgeContent: {
			...this.state.badgeContent,
			moreOptions: activeFilters,
		} });
	};

	handleOpenCloudFilterDialog = () => {
		analyzer.event({ category: ANALYSIS_CATEGORY, action: OPEN_ACTION, label: 'CLOUD_FILTERS' });
		this.setState({ isCloudFilterModalOpen: true });
	};

	handleCloudFilterModalClose = () => {
		const activeFilters = this.getActiveFilters(this.props.formData, activeFilterMap.cloud);
		this.setState({ isCloudFilterModalOpen: false, badgeContent: {
			...this.state.badgeContent,
			cloud: activeFilters,
		} });
	};

	disableSubmittionOnEnter = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
		}
	};

	render() {
		return (
			<div className="querycontent" >
				<form onSubmit={ this.props.handleSubmit }
					onKeyPress={this.disableSubmittionOnEnter}>
					<DefaultQuery
						initialValues={ { ...this.props.initialValues, ...this.state } }
						onChange={ this.onChange }
						onAnalyzeClick={ this.props.handleSubmit }
						onCopyLinkClick={ this.props.createQueryLink }
						currentProcessLinkId={this.props.currentProcessLinkId}
						change={ this.props.change } />
					<Advance
						initialValues={ { ...this.props.initialValues, ...this.state } }
						onChange={ this.onChange }
						onCopyLinkClick={ this.props.createQueryLink }
						currentProcessLinkId={this.props.currentProcessLinkId}
						openSrcIpEnrichment={this.handleOpenSrcIpEnrichment}
						openDestIpEnrichment={this.handleOpenDestIpEnrichment}
						openMoreOptions={this.handleOpenMoreOptions}
						openCloudFilterDialog={this.handleOpenCloudFilterDialog}
						isSubmitting={ this.props.isSubmitting }
						activeFilters={ this.state.badgeContent }
					/>
					<CloudFilterModal isOpen={this.state.isCloudFilterModalOpen}
						handleClose={this.handleCloudFilterModalClose}
						hostingFormName={this.props.form}
					/>
					<IpEnrichmentDialog isOpen={this.state.isSrcIpEnrichmentOpen}
						handleClose={this.handleCloseIpEnrichment}
						enrichmentType={dialogTypes.SRC}
					/>
					<IpEnrichmentDialog isOpen={this.state.isDestIpEnrichmentOpen}
						handleClose={this.handleCloseIpEnrichment}
						enrichmentType={dialogTypes.DEST}
					/>
					<MoreOptionsDialog isOpen={this.state.isMoreOptionsOpen}
						handleClose={this.handleCloseMoreOptions}
						formName={this.props.form}
					/>
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	formData: getFormValues('create-backgroung-process')(state),
});

const mapDispatchToProps = () => ({
	// ...
});

// eslint-disable-next-line no-class-assign
Query = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Query);

Query.propTypes = {
	formData: PropTypes.shape(ANALYSIS_PAYLOAD_TYPE),
	handleSubmit: PropTypes.func,
	initialValues: PropTypes.object,
	createQueryLink: PropTypes.func,
	currentProcessLinkId: PropTypes.string,
	change: PropTypes.func,
	isSubmitting: PropTypes.bool,
	form: PropTypes.string,
};

export default reduxForm({
	validateQueryForm,
	warningQueryForm,
	enableReinitialize: true,
})(Query);

