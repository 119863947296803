import _ from 'lodash';
import http from '../../../../../services/httpService/http';
import { GROUPS_PATH } from '../../../../groupManagement/controller/services/urls';
import { END_SUBCRIPTION } from '../auth/authActions';
import action from '../middleware';

export const SET_ERROR = 'SET_ERROR';
export const REQ_UPDATE_GROUP = 'REQ_UPDATE_GROUP';
export const REQ_ADD_GROUP = 'REQ_ADD_GROUP';
export const REQ_FEATURES = 'REQ_FEATURES';
export const REQ_GROUPS = 'REQ_GROUPS';
export const STORE_FEATURES = 'REQ_GROUPS';
export const UPDATE_IP_FROM_GROUP = 'UPDATE_IP_FROM_GROUP';
export const REQ_DELETE_GROUP = 'REQ_DELETE_GROUP';
export const GET_ACCOUNT_PUBLIC_FIELDS = 'GET_ACCOUNT_PUBLIC_FIELDS';

const ACCOUNTS_FEATURES_URL = 'accounts/v1/features';
const ACCOUNTS_URL = 'accounts/v1';

const deleteGroupAsync = groupId => action(async dispatch => {
	try {
		const group = await http._delete(GROUPS_PATH, { groupId });
		dispatch({
			type: REQ_DELETE_GROUP,
			payload: { ...group },
		});
		return group;
	} catch (e) {
		dispatch({
			type: SET_ERROR,
			payload: e.message || 'Account load Features failed.',
		});
	}
});
const getFeaturesAsync = () => action(async dispatch => {
	const result = await http.get(ACCOUNTS_FEATURES_URL);

	if (_.has(result, 'error')) {
		if (result.error.status === 405) {
			dispatch({ type: END_SUBCRIPTION, payload: true });
			return null;
		}
	} else {
		dispatch({
			type: REQ_FEATURES,
			payload: { features: result },
		});
		return result;
	}
});

const getGroupsAsync = () => action(async dispatch => {
	try {
		const groups = await http.get(GROUPS_PATH);

		dispatch({
			type: REQ_GROUPS,
			payload: { groups },
		});
		return groups;
	} catch (e) {
		dispatch({
			type: SET_ERROR,
			payload: e.message || 'Account load Groups failed.',
		});
	}
});

const updateGroupAsync = group => action(async dispatch => {
	try {
		const updatedGroup = await http.put(`${GROUPS_PATH}/${group}`);

		dispatch({
			type: REQ_UPDATE_GROUP,
			payload: { group: updatedGroup },
		});
	} catch {
		dispatch({
			type: SET_ERROR,
			payload: { group },
		});
	}
});

const deleteIpFromGroupAsync = groupId => action(async dispatch => {
	try {
		const updatedGroup = await http._delete(`${GROUPS_PATH}/${groupId}`);

		dispatch({
			type: UPDATE_IP_FROM_GROUP,
			payload: { group: updatedGroup },
		});
		return updatedGroup;
	} catch (e) {
		dispatch({
			type: SET_ERROR,
			payload: { e },
		});
	}
});
const addGroupAsync = group => action(async dispatch => {
	try {
		const addedGroup = await http.post(GROUPS_PATH, group);
		dispatch({
			type: REQ_ADD_GROUP,
			payload: { group: addedGroup },
		});
		return addedGroup;
	} catch {
		dispatch({
			type: SET_ERROR,
			payload: { group },
		});
		return null;
	}
});

const getAccountPublicFields = () => action(async dispatch => {
	try {
		const {
			name,
			subscriptionEnd,
			features,
			restrictions,
			customizedLogoPath,
		} = await http.get(`${ACCOUNTS_URL}/current/`);
		dispatch({
			type: GET_ACCOUNT_PUBLIC_FIELDS,
			payload: {
				name,
				subscriptionEnd,
				features,
				restrictions,
				customizedLogoPath,
			},
		});

		return true;
	} catch {
	}
});

export {
	getFeaturesAsync,
	getGroupsAsync,
	updateGroupAsync,
	addGroupAsync,
	deleteIpFromGroupAsync,
	deleteGroupAsync,
	getAccountPublicFields,
};

