import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { downloadFromLink } from '../../../helpers/utils';
import DeviceManagementTabs from '../../../shared/components/tabs/deviceManagementTabs';
import { setTitle } from '../../app/controller/redux/app/appActions';
import { openSnackbar } from '../../snackbarAlert/controller/redux/actions';
import {
	arrangeLiteDevices,
	arrangeLitePublicCloudDevice,
	mapCollector,
	mapDeviceCollectorWithCollector,
	mapDeviceWithReference,
	mapSamplers,
} from '../controller/businessLogic/mappers';
import {
	storeCollectorDevices,
	storePublicCloudDevices,
	storePublicDevices,
} from '../controller/lightweightReducer/actions';
import { getCollectorDevices } from '../controller/services/collectorDeviceRequests';
import { getCollectors } from '../controller/services/collectorRequests';
import { getDeviceReferences, getDevices } from '../controller/services/deviceRequests';
import { deleteDevice as removeDevice } from '../controller/services/devicesRequestsMapping';
import { getPublicCloudDevices } from '../controller/services/publicCloudDeviceRequests';
import { getSamplers } from '../controller/services/samplerRequests';
import { arrangeLiteDevicesByType, fieldToUpdateByCollectorType, parseDeviceByType } from './deviceManagementParsers';
import { collectorTypes, deviceStoreByType, deviceTypes, deviceTypesTableOrder, POLLING_INTERVAL } from './devicesManagementConfig';

const DeviceManagement = () => {
	const dispatch = useDispatch();
	const [collectors, setCollectors] = useState([]);
	const [samplers, setSamplers] = useState([]);
	const [deviceReferences, setDeviceReferences] = useState(null);
	const [devices, setDevices] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(setTitle('Device Management'));
		setIsLoading(true);
		const fetchDevicesData = async () => {
			Promise.all([fetchDeviceReferences(), fetchCollectors(), fetchDevices(), fetchSamplers()]);
		};

		fetchDevicesData();
		const pollingInterval = setInterval(() => {
			fetchDevicesData();
		}, POLLING_INTERVAL);
		return () => {
			clearInterval(pollingInterval);
		};
	}, []);

	const fetchDeviceReferences = async () => {
		try {
			const deviceReferences = await getDeviceReferences();
			setDeviceReferences(deviceReferences);
		} catch {
			dispatch(openSnackbar('error', 'Failed to display devices models', 4000));
		}
	};

	const fetchCollectors = async () => {
		try {
			const collectors = await getCollectors();
			setCollectors(mapCollector(collectors));
		} catch {
			dispatch(openSnackbar('error', 'Failed to display all of your collectors at this time', 4000));
		}
	};

	const fetchSamplers = async () => {
		try {
			const samplers = await getSamplers();
			setSamplers(mapSamplers(samplers));
		} catch {
			dispatch(openSnackbar('error', 'Failed to display all of your samplers at this time', 4000));
		}
	};

	const fetchPublicDevices = async references => {
		try {
			const publicDevices = await getDevices();
			dispatch(deviceStoreByType[deviceTypes.PUBLIC_DEVICE](arrangeLiteDevices(publicDevices)));
			return mapDeviceWithReference(publicDevices, references);
		} catch {
			dispatch(openSnackbar('error', 'Failed to display all of your devices at this time', 4000));
		}
	};

	const fetchCoreDevices = async references => {
		try {
			const [coreDevices, collectors, samplers] = await Promise.all([getCollectorDevices(), getCollectors(), getSamplers()]);
			const mappedCollectorDevices = mapDeviceCollectorWithCollector(
				mapDeviceWithReference(coreDevices, references), collectors, samplers);
			dispatch(deviceStoreByType[deviceTypes.CORE_DEVICE](arrangeLiteDevices(coreDevices)));
			return mappedCollectorDevices;
		} catch {
			dispatch(openSnackbar('error', 'Failed to display all of your devices at this time', 4000));
		}
	};

	const fetchCloudDevices = async () => {
		try {
			const cloudDevices = await getPublicCloudDevices();
			dispatch(deviceStoreByType[deviceTypes.CLOUD_DEVICE](arrangeLitePublicCloudDevice(cloudDevices)));
			return cloudDevices;
		} catch {
			dispatch(openSnackbar('error', 'Failed to display all of your devices at this time', 4000));
		}
	};

	const fetchDevices = async () => {
		let references = { ...deviceReferences };
		if (!deviceReferences) {
			references = await getDeviceReferences();
		}

		const publicDevices = await fetchPublicDevices(references);
		const coreDevices = await fetchCoreDevices(references);
		const cloudDevices = await fetchCloudDevices();
		const allDevices = [...publicDevices, ...coreDevices, ...cloudDevices];
		setDevices(allDevices);
		setIsLoading(false);
	};

	const addDeviceByDeviceType = newDevice => {
		const groupedDevices = _.groupBy(devices, device => device.deviceType);
		groupedDevices[newDevice.deviceType] = [...(groupedDevices[newDevice.deviceType] || []), newDevice];
		const updatedDevices = deviceTypesTableOrder.reduce((acc, currentValue) => [...acc, (groupedDevices[currentValue] || [])], []);
		return updatedDevices.flat();
	};

	const createDevice = deviceDetails => {
		deviceDetails = parseDeviceByType[deviceDetails.deviceType](deviceDetails, collectors);
		const updatedDevices = addDeviceByDeviceType(deviceDetails);
		setDevices(mapDeviceWithReference(updatedDevices, deviceReferences));
		dispatch(deviceStoreByType[deviceDetails.deviceType](arrangeLiteDevicesByType[deviceDetails.deviceType](updatedDevices)));
	};

	const editDevice = deviceDetails => {
		const updatedDevices = [...devices];
		const deviceIndex = updatedDevices.map(device => device._id).indexOf(deviceDetails._id);
		updatedDevices[deviceIndex] = Object.assign(updatedDevices[deviceIndex], deviceDetails);
		setDevices(updatedDevices);
		dispatch(deviceStoreByType[deviceDetails.deviceType](arrangeLiteDevicesByType[deviceDetails.deviceType](updatedDevices)));
	};

	const deleteDevice = async deviceDetails => {
		try {
			const updatedDevices = [...devices].filter(device => device._id !== deviceDetails._id);
			await removeDevice[deviceDetails.deviceType](deviceDetails._id);
			setDevices(updatedDevices);
			dispatch(deviceStoreByType[deviceDetails.deviceType](arrangeLiteDevicesByType[deviceDetails.deviceType](updatedDevices)));
			dispatch(openSnackbar('info', 'Device has been deleted successfully', 4000));
		} catch {
			dispatch(openSnackbar('error', 'Failed to delete device', 4000));
		}
	};

	const addLegacyCollectorOnTheFly = collector => {
		collector._id = collector.id;
		const updatedCollectors = [...collectors, collector];
		setCollectors(mapCollector(updatedCollectors));
		downloadFromLink(collector.link);
	};

	const addSamplerOnTheFly = sampler => {
		const updatedSamplers = [...samplers, sampler];
		setSamplers(mapSamplers(updatedSamplers));
	};

	const addCollectorOnFlyMapping = {
		[collectorTypes.LEGACY_COLLECTOR_TYPE]: addLegacyCollectorOnTheFly,
		[collectorTypes.SAMPLER_TYPE]: addSamplerOnTheFly,
	};

	const addCollectorOnTheFly = collector => {
		addCollectorOnFlyMapping[collector.entityType](collector);
		return fieldToUpdateByCollectorType[collector.entityType](collector);
	};

	const shouldShowTabs = collectors && samplers && devices;

	return (
		<div>
			<div className="content">
				{
					shouldShowTabs && <DeviceManagementTabs
						collectors={collectors}
						samplers={samplers}
						deviceReferences={deviceReferences}
						devices={devices}
						setCollectors={fetchCollectors}
						setSamplers={fetchSamplers}
						createDevice={createDevice}
						editDevice={editDevice}
						deleteDevice={deleteDevice}
						isLoading={isLoading}
						addCollectorOnTheFly={addCollectorOnTheFly}
					/>
				}
			</div>
		</div>);
};

const mapStateToProps = state => ({
	features: state.account.features,
});

const mapDispatchToProps = {
	setTitle,
	storePublicDevices,
	storeCollectorDevices,
	storePublicCloudDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceManagement);
