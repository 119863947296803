import React from "react";
import myGroupsSide from "../../../../../../assets/walkthrough/groups/steps/myGroupsSide.png";
import myGroupsContent from "../../../../../../assets/walkthrough/groups/steps/myGroupsContent.png";
import groupsColorIndex from "../../../../../../assets/walkthrough/groups/steps/groupsIndex.png";
import {STEP_3_DESCRIPTION, STEP_3_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV3
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV3/mainPanelTemplateV3";
import {useStyles} from "./step3.css";

const Step3 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={myGroupsSide}>
            <MainPanelTemplateV3 title={STEP_3_TITLE}
                                 image={<img src={myGroupsContent} alt={"Group view"}/>}
                                 description={STEP_3_DESCRIPTION}
                                 subDescriptionImage={<img src={groupsColorIndex} alt={"Groups index"} className={classes.image}/>}/>
        </StepTemplate>
    )
}

export default Step3;