export const ALIAS_FIELD = 'alias';
const CONTINENT_FIELD = 'continent';
const ISP_FIELD = 'isp';
const ORG_FILED = 'org';
const DNS_FIELD = 'reverse';
const COUNTRY_FIELD = 'country';
const VENDOR_FIELD = 'companyName';
const VENDOR_COUNTRY_FIELD = 'countryName';
export const MAC_FIELD = 'mac';

export const dataKeysDisplayMappings = {
	[ALIAS_FIELD]: 'Alias',
	[CONTINENT_FIELD]: 'Continent',
	[ISP_FIELD]: 'ISP',
	[ORG_FILED]: 'Organization',
	[DNS_FIELD]: 'DNS',
	[COUNTRY_FIELD]: 'Country',
	[MAC_FIELD]: 'MAC',
	[VENDOR_FIELD]: 'Vendor',
	[VENDOR_COUNTRY_FIELD]: 'Vendor Country',
};

export const enrichmentAndAliasFields = [
	ALIAS_FIELD,
	CONTINENT_FIELD,
	COUNTRY_FIELD,
	ISP_FIELD,
	ORG_FILED,
	DNS_FIELD,
];

export const enrichmentAndAliasAndVendorFields = [
	...enrichmentAndAliasFields, VENDOR_COUNTRY_FIELD, VENDOR_FIELD,
];

export const aliasAndVendorFields = [
	ALIAS_FIELD,
	VENDOR_FIELD,
	VENDOR_COUNTRY_FIELD,
];

