import React, { Component } from 'react';
import Collapse from '@material-ui/core/Collapse';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import TableHeaderLineInsights from '../header/tableHeaderLineInsights';
import TableRowOpenable from './tableRowOpenable';
import { createBackGroundProcesses  } from '../../../../../backgroundProcess/controller/redux/backgroundProcessAction';
import { connect } from 'react-redux';
import moment from 'moment';
import { Connections } from '../../../../../threatsManagement/view/suspicious/suspicious';
import { createDevice } from '../../../../controller/businessLogic/entityCreator';

const ADD_BEFORE_AFTER_INSIGHT_VALUE = 1;
const ADD_BEFORE_AFTER_INSIGHT_UNIT_HOURS = 'h';
const ADD_BEFORE_AFTER_INSIGHT_UNIT_MINUTES = 'm';

class TableRowInsight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
        this.runQuery = this.runQuery.bind(this);
    }
    onOpenRow = (e) => {
        e.preventDefault();
        this.setState({ isOpen: !this.state.isOpen });
    }


    runQuery = async (query) => {

        const { row, insightData } = this.props;
        const { originalQuery, assetTitle, assetId, queryType, insight } = insightData;
        const deviceData = createDevice(assetTitle, assetId, 'o');

        const fromDate = row.from * 1000;
        const toDate = row.to * 1000;

        let NewfromDate = null;
        let NewtoDate = null;

        const hoursBetweenDate = moment(toDate).diff(fromDate, 'h');
        let passingDateControl = false;

        if (hoursBetweenDate < 3) {

            NewfromDate = moment(fromDate).subtract(ADD_BEFORE_AFTER_INSIGHT_VALUE, ADD_BEFORE_AFTER_INSIGHT_UNIT_HOURS).valueOf();
            NewtoDate = moment(toDate).add(ADD_BEFORE_AFTER_INSIGHT_VALUE, ADD_BEFORE_AFTER_INSIGHT_UNIT_HOURS).valueOf();
            passingDateControl = true; 
        } else if (hoursBetweenDate >= 3 && hoursBetweenDate < 5) {
            const Timedifference = moment(toDate).diff(fromDate, 'm');
            // 5H in Minutes is the upper bound. Reduce from that the diff to verify that it's within bounds
            const SpareTime = ((5*60) - Timedifference) / 2; 
            NewfromDate = moment(fromDate).subtract(SpareTime, ADD_BEFORE_AFTER_INSIGHT_UNIT_MINUTES).valueOf();
            NewtoDate = moment(toDate).add(SpareTime, ADD_BEFORE_AFTER_INSIGHT_UNIT_MINUTES).valueOf();
            passingDateControl = true
        }
        if (passingDateControl) {
            const queryObj = {
                devices: deviceData,
                queryTypes: [queryType.value],
                fromTimestamp: NewfromDate,
                toTimestamp: NewtoDate,
                extra: {},
                timeWindow: 60
            };
            queryObj[insight.label] = originalQuery[insight.label]; // The field the insight is based on
            await this.props.createBackGroundProcesses(queryObj);
        } 
    }
    render() {
        const { isOpen } = this.state;
        const { row, queryType } = this.props;
        return (<>
            <TableRow >
                <TableHeaderLineInsights
                    row={ row }
                    isOpen={ isOpen }
                    onOpenRow={ (e) => { this.onOpenRow(e) } }
                    runQuery={ this.runQuery } />
            </TableRow>
            <TableRow>
                <TableCell style={ { paddingBottom: 0, paddingTop: 0 } } colSpan={ 6 }>
                    <Collapse in={ isOpen }>
                        <TableRowOpenable>
                            <div className="explore-menu-toogle" >
                                { _.has(row.assets, 'connections') ? <Connections
                                    queryType={ queryType }
                                    title={ 'TOP CONNECTIONS' }
                                    connections={ row.assets.connections } /> : null }

                            </div>
                        </TableRowOpenable>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>);
    }

}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = {
    createBackGroundProcesses,
};
export default connect(mapStateToProps, mapDispatchToProps)(TableRowInsight);

