import http from "../../../services/httpService/http";
const ENDPOINT_INVENTORY_URL = 'endpoint-inventory/v1/';
const GET_OPTIONS_URL = 'endpoint-inventory/v1/options';

const getVendorDataByMac = (macAddress) => {
    return http.get(`${ENDPOINT_INVENTORY_URL}${macAddress}`);
}
const createReports = (payload) => {
    return http.post(ENDPOINT_INVENTORY_URL,payload)
}
const getAutoCompleteOptions = () => {
    return http.get(GET_OPTIONS_URL)
}
const getReports = () => {
    return http.get(ENDPOINT_INVENTORY_URL)
}

export {
    getVendorDataByMac,
    createReports,
    getReports,
    getAutoCompleteOptions
}