import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CynamicsAPI from '../../../../assets/integrations/cynamics-api.png';
import DailyDetectionReportIcon from '../../../../assets/integrations/daily-detections-report.svg';
import EmailIcon from '../../../../assets/integrations/email-notifications.png';
import SmsNotifications from '../../../../assets/integrations/sms-notifications.png';
import Syslog from '../../../../assets/integrations/syslog-integration.svg';
import WeeklyAndDailyIcon from '../../../../assets/integrations/weekly-and-daily-report.png';
import { activeTypes } from '../../controller/constants/activeTypes';

import SimpleModal from '../../../../shared/components/simpleTable/simpleModal';
import SnackBarAlert from '../../../../shared/components/snackBarAlert/snackBarAlert';
import {
	CYNAMICS_APIS,
	DAILY_REPORT,
	DAILY_TOP_DETECTIONS_REPORT,
	EMAIL,
	ENDPOINT_INVENTORY_REPORTS,
	SMS,
	SYSLOG,
	WEEKLY_REPORT,
	getIntegrationLabel,
} from '../../controller/constants/integrationTypes';
import CynamicsApisIntegration from './cynamicsAPI/cynamicsApisIntegration';
import DailyTopDetectionsReport from './dailyTopDetectionsReport/dailyTopDetectionsReport';
import EmailIntegration from './email/emailIntegration';
import EndpointInventoryIntegration from './endpointInventoryIntegration/endpointInventoryIntegration';
import DailyReportIntegration from './reports/dailyReportIntegration';
import WeeklyReportIntegration from './reports/weeklyReportIntegration';
import SmsIntegration from './sms/smsIntegration';
import SyslogIntegration from './syslog/syslogIntegrationForm';

const propTypes = {
	updateSelectedIntegration: PropTypes.func.isRequired,
	selectedIntegration: PropTypes.func.isRequired,
	updateEnabledIntegration: PropTypes.func.isRequired,
};
const SNACK_BAR_HIDE_DURATION = 4000;

const ICONS = {
	[EMAIL]: EmailIcon,
	[WEEKLY_REPORT]: WeeklyAndDailyIcon,
	[DAILY_REPORT]: WeeklyAndDailyIcon,
	[CYNAMICS_APIS]: CynamicsAPI,
	[SMS]: SmsNotifications,
	[SYSLOG]: Syslog,
	[DAILY_TOP_DETECTIONS_REPORT]: DailyDetectionReportIcon,
};
const INTEGRATION_WIDTH = {
	endpointInventoryReports: '1100px',
};

const IntegrationModalWrapper = ({ selectedIntegration, updateSelectedIntegration, updateEnabledIntegration }) => {
	const [snackBarMessage, setSnackBarMessage] = useState();
	const [isSnackBarShouldOpen, setIsSnackBarShouldOpen] = useState();
	const [snackBarSeverity, setSnackBarSeverity] = useState();

	const toogle = () => {
		updateSelectedIntegration(null);
	};

	return (
		<>
			<SimpleModal
				header={ getIntegrationLabel(selectedIntegration)}
				contentClassName="smart-form-modal"
				isOpen={!_.isEmpty(selectedIntegration)}
				toggle={ toogle }
				icon={ICONS[selectedIntegration]}
				width={INTEGRATION_WIDTH[selectedIntegration]}
				iconStyle={{ marginRight: '4%', marginBottom: '2%', width: 130, height: 66 }}
			>
				{ selectedIntegration === SMS
					? <SmsIntegration updateSelectedIntegration={ updateSelectedIntegration }
						updateEnabledIntegration={updateEnabledIntegration}
					/> : null }
				{ selectedIntegration === EMAIL
					? <EmailIntegration updateSelectedIntegration={ updateSelectedIntegration }
						updateEnabledIntegration={updateEnabledIntegration}/> : null }
				{ selectedIntegration === WEEKLY_REPORT
					? <WeeklyReportIntegration updateSelectedIntegration={ updateSelectedIntegration }
						updateEnabledIntegration={updateEnabledIntegration}/> : null }
				{ selectedIntegration === DAILY_REPORT
					? <DailyReportIntegration updateSelectedIntegration={ updateSelectedIntegration }
						updateEnabledIntegration={updateEnabledIntegration}/> : null }
				{ selectedIntegration === CYNAMICS_APIS
					? <CynamicsApisIntegration updateSelectedIntegration={ updateSelectedIntegration }/> : null }

				{ selectedIntegration === ENDPOINT_INVENTORY_REPORTS
					&& <EndpointInventoryIntegration
						{...{
							updateSelectedIntegration,
							setSnackBarMessage,
							setIsSnackBarShouldOpen,
							setSnackBarSeverity,
						}}/>
				}

				{ selectedIntegration === SYSLOG
					&& <SyslogIntegration
						onSubmit={() => updateEnabledIntegration(SYSLOG, activeTypes.ON)}
						onClose={updateSelectedIntegration} />}

				{ selectedIntegration === DAILY_TOP_DETECTIONS_REPORT
					&& <DailyTopDetectionsReport updateSelectedIntegration={ updateSelectedIntegration }
						updateEnabledIntegration={updateEnabledIntegration} />
				}

			</SimpleModal>
			{isSnackBarShouldOpen
				&& <SnackBarAlert
					isOpen={isSnackBarShouldOpen}
					onClose={() => setIsSnackBarShouldOpen(false)}
					severity={snackBarSeverity}
					autoHideDuration={SNACK_BAR_HIDE_DURATION}>

					{snackBarMessage}
				</SnackBarAlert>}
		</>
	);
};

IntegrationModalWrapper.propTypes = propTypes;
export default IntegrationModalWrapper;
