import React from 'react';
import { ACCEPT_PROPOSAL_TEXT, getCurrency } from '../const';
import useStyles from './ProposalSummary.css';
import { Button } from '@material-ui/core';
import { addCommasToNumber } from '../util';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

const buildPriceString = (item, discount, currency) => (String(discount ? '-' : '') + getCurrency(currency) + (addCommasToNumber((discount ? item.priceOff : item.price), 2)));

const buildAmountOffString = (item, currency) => `${getCurrency(currency)}${addCommasToNumber(item.priceOff, 2)}`;

const Products = ({ quote, classes }) => (
	<>{quote && quote.products && quote.products.map((product, index) => <PriceItem
		item={product}
		discount={false}
		key={`${product.name} -${index}`}
		classes={classes}
	/>)}</>
);

const Discounts = ({ classes, quote }) => <div className={classes.itemsContainer}>
	{quote && quote.discounts && quote.discounts.map((discountItem, index) => (
		<PriceItem item={discountItem} discount key={`${discountItem.name} -${index}`} classes={classes}/>
	))}
</div>;

const Divider = ({ classes }) => <div className={classes.divider}></div>;

const Total = ({ quote, classes }) => quote.total && <PriceItem item={{ name: 'Total', price: quote.total }} index={0} discount={false} classes={classes}/>;

const PriceItem = ({ item, discount, currency, key, classes }) => <div key={key} className={classes.item}>
	<Name item={item} discount={discount} currency={currency} classes={classes}/>
	<Price item={item} discount={discount} currency={currency} classes={classes}/>
</div>;

const ItemSkeleton = ({ height, className }) =>
	<Skeleton height={height ?? 16} width={'100%'} className={className} />;

const Name = ({ item, discount, currency, classes }) =>
	<span className={classes.name}>{item.name} {
		discount ? `(${item.percentOff ? `${item.percentOff}%` : buildAmountOffString(item, currency)} off)`
			: ''}
	</span>;

const Price = ({ item, discount, currency, classes }) => <span className={classes.price}>
	{buildPriceString(item, discount, currency)}{item.interval ? <span className={classes.name}>/{item.interval}</span> : ''}
</span>;

const ProposalSummary = ({ quote, onAcceptProposal, isLoading }) => {
	const classes = useStyles();

	const detailsContainerClasses = [classes.detailsContainer, classes.itemsGap].join(' ');
	const itemsContainerClasses = [classes.itemsContainer, classes.itemsGap].join(' ');
	const skeletonButtonClasses = [classes.buttonContainer, classes.acceptButtonSkeleton].join(' ');

	return (
		<div className={classes.container}>
			<div className={classes.mainText}>
				{ACCEPT_PROPOSAL_TEXT}
			</div>

			{quote.id ? (
				<>
					<div className={detailsContainerClasses}>
						<div className={itemsContainerClasses}>
							<Products quote={quote} classes={classes}/>
							<Discounts classes={classes} quote={quote}/>
						</div>
						<Divider classes={classes}/>
						<Total quote={quote} classes={classes}/>
					</div>
					<div className={classes.buttonContainer}>
						{isLoading ? <CircularProgress color="primary" className = {classes.loading}/>
							: <Button
								onClick={() => onAcceptProposal(quote.id)}
								className={classes.acceptButton}
								fullWidth color="primary"
								variant="contained">
							Accept proposal
							</Button>
						}
					</div>
				</>
			)
				: (
					<>
						<div className={detailsContainerClasses}>
							<div className={classes.itemsContainer}>
								<ItemSkeleton />
								<ItemSkeleton />
							</div>
							<ItemSkeleton />
						</div>
						<ItemSkeleton height={50} className={skeletonButtonClasses} />
					</>
				)
			}
		</div>
	);
};

const quoteDiscountItem = PropTypes.shape({
	name: PropTypes.string,
	interval: PropTypes.number,
	percentOff: PropTypes.number,
}).isRequired;

const quoteItem = PropTypes.shape({
	id: PropTypes.string.isRequired,
	currency: PropTypes.string,
	discountTotal: PropTypes.number,
	total: PropTypes.number.isRequired,
	products: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string.isRequired,
		interval: PropTypes.string.isRequired,
		price: PropTypes.number.isRequired,
	})).isRequired,
	discounts: PropTypes.arrayOf(quoteDiscountItem),
});

Products.propTypes = {
	quote: quoteItem.isRequired,
	classes: PropTypes.object,
};

Discounts.propTypes = {
	quote: quoteItem.isRequired,
	classes: PropTypes.object,
};

Divider.propTypes = {
	classes: PropTypes.object,
};

Total.propTypes = {
	quote: quoteItem.isRequired,
	classes: PropTypes.object,
};

Price.propTypes = {
	item: quoteDiscountItem,
	discount: PropTypes.bool,
	currency: PropTypes.string,
	classes: PropTypes.object,
};

ItemSkeleton.propTypes = {
	height: PropTypes.number,
	className: PropTypes.string,
};

Name.propTypes = {
	item: quoteDiscountItem,
	discount: PropTypes.bool,
	currency: PropTypes.string,
	classes: PropTypes.object,
};

PriceItem.propTypes = {
	item: quoteDiscountItem,
	discount: PropTypes.bool,
	currency: PropTypes.string,
	key: PropTypes.number,
	classes: PropTypes.object,
};

ProposalSummary.defaultProps = {
	isLoading: false,
};

ProposalSummary.propTypes = {
	quote: quoteItem.isRequired,
	onAcceptProposal: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
};

export default ProposalSummary;
