// React
import PropTypes from 'prop-types';
import React from 'react';
// Redux
import { connect } from 'react-redux';
import InfoBox from '../../shared/components/infoBox/InfoBox';
import { removeNotification } from '../backgroundProcess/controller/redux/analyzeActions';
import Map from './view/map/Map';
// Icons
import devicesIcon from '../../assets/icons/devices.png';
import packetsIcon from '../../assets/icons/packets.svg';
import threatIcon from '../../assets/icons/threats.svg';
import welcomeImage from '../../assets/walkthrough/overview/steps/welcome.png';
import GraphBox from '../../shared/components/graphBox/GraphBox';

import _ from 'lodash';
import { selectPointOnGraph } from '../backgroundProcess/controller/redux/graphActions';
import PopupGraph from '../topTalkers/popOvers/popOver';

import moment from 'moment';
import { timeFormaterGraphPopOver } from '../../constants/formater';
import Welcome from '../../shared/components/walkthrough/welcome';
import GraphAlertPopOver from '../alertsManagement/view/section/graph/popOver/popOverAlertes';
import { setTitle } from '../app/controller/redux/app/appActions';
import { updateWalkthroughDone } from '../app/controller/redux/dashboardInteractions/actions';
import { OVERVIEW_KEY } from '../app/controller/redux/dashboardInteractions/consts';
import { getLiteDevices } from '../deviceManagement/controller/lightweightReducer/actions';
import {
	updateDailyTopDetectionsReportIntegration,
} from '../integrations/controller/services/dailyTopDetectionsReport';
import { getSmsEmailIntegrations } from '../integrations/controller/services/smsRequests';
import { openSnackbar } from '../snackbarAlert/controller/redux/actions';
import { formatBase2 } from '../topTalkers/formatters';
import TopTalkers from '../topTalkers/topTalkers';
import WalkthroughDialog from '../walkthrough/walkthroughDialog/walkthroughDialog';
import { configSummaryManagement } from './configSummaryView';
import CustomAlertBox from './view/alerts/alerts/customAlertbox';
import { NO_DEVICES_ONBOARDED, NOTHING_TO_WORRY, WAITING_FOR_DATA } from './view/emptyStateBox/emptyBoxesConfig';
import InsightsBox from './view/insightsBox/InsightsBox';
import NotificationBox from './view/noNotificationBox/NotificationBox';
import SetNotifications from './view/walkthrough/setNotifications/setNotifications';
import Step1 from './view/walkthrough/steps/step1/step1';
import Step2 from './view/walkthrough/steps/step2/step2';
import Step3 from './view/walkthrough/steps/step3/step3';
import Step4 from './view/walkthrough/steps/step4/step4';
import Step5 from './view/walkthrough/steps/step5/step5';

const ACTIVATE_NOTIFICATIONS_FAILED = 'Unable to save email. Email notifications can be set in the Integrations page';
const ACTIVATE_NOTIFICATIONS_SUCCEEDED = 'Activated notifications successfully';
const ACTIVATE_NOTIFICATIONS_FAILED_SNACKBAR_DURATION = 8 * 1000;
const ACTIVATE_NOTIFICATIONS_SUCCEEDED_SNACKBAR_DURATION = 4 * 1000;
const overviewServiceName = 'Overview';
const overviewServiceDescription = 'Your control dashboard';

class SummaryView extends React.Component {
	static defaultProps = {
		summaryData: {
			NumOfDevices: 0,
			notifications: [],
		},
		totals: {
			dailyDetectionsCount: undefined,
			dailyPackets: undefined,
		},
		visibleDevicesCount: undefined,
	};

	constructor(props) {
		super(props);
		this.state = {
			devices: [],
		};
	}

	async componentDidMount() {
		this.props.setTitle(configSummaryManagement.pageTitle);
	}

	finishWalkthrough = async formData => {
		let recipients = [];
		try {
			const data = await getSmsEmailIntegrations();
			if (data && data.integrations) {
				recipients = data.integrations.dailyTopDetectionsReport.recipients;
			}

			if (formData && !recipients.includes(formData.email)) {
				const newRecipients = [...recipients, formData.email];
				await updateDailyTopDetectionsReportIntegration(newRecipients);

				this.props.openSnackbar('success', ACTIVATE_NOTIFICATIONS_SUCCEEDED, ACTIVATE_NOTIFICATIONS_SUCCEEDED_SNACKBAR_DURATION);
			}
		} catch {
			this.props.openSnackbar('error', ACTIVATE_NOTIFICATIONS_FAILED, ACTIVATE_NOTIFICATIONS_FAILED_SNACKBAR_DURATION);
		}

		this.props.updateWalkthroughDone(OVERVIEW_KEY, true);
	};

	areThereNoDevices = _.isEmpty(this.props.publicDevices) && _.isEmpty(this.props.collectorDevices) && _.isEmpty(this.props.publicCloudDevices);

	isDataFlowing = this.props.summaryData && Boolean(this.props.summaryData.volumeOverTime);

	generateEmptyOverviewBoxesConfig = nothingToWorryCondition => {
		if (this.areThereNoDevices) {
			return NO_DEVICES_ONBOARDED;
		}

		if (!this.areThereNoDevices && !this.isDataFlowing) {
			return WAITING_FOR_DATA;
		}

		if (nothingToWorryCondition) {
			return NOTHING_TO_WORRY;
		}

		return null;
	};

	render() {
		const { summaryData, totals, selectedBar } = this.props;
		const steps = [Step1, Step2, Step3, Step4, Step5];

		const canCreateCustomAlert = !this.areThereNoDevices && this.isDataFlowing;

		return (
			<>
				<div className="content">
					<div className="summary-view-container">
						<div className="left-wrapper">
							<div className="summary-numbers">
								<InfoBox
									icon={packetsIcon}
									info={totals.dailyPackets}
									desc={configSummaryManagement.boxDailyPacket}
									isLoading={isNaN(totals.dailyPackets)}
								/>
								<InfoBox
									icon={threatIcon}
									info={totals.dailyDetectionsCount}
									desc={configSummaryManagement.boxDailyThreats}
									isLoading={isNaN(totals.dailyDetectionsCount)}
								/>
								<InfoBox
									icon={devicesIcon}
									info={this.props.visibleDevicesCount}
									desc={configSummaryManagement.boxDevices}
									isLoading={isNaN(this.props.visibleDevicesCount)}
								/>
							</div>

							<div className="info-boxes-wrapper">
								<div className="notifications_anomalies_insights_box">
									<NotificationBox
										generateEmptyOverviewBoxesConfig={this.generateEmptyOverviewBoxesConfig}/>

									<InsightsBox
										generateEmptyOverviewBoxesConfig={this.generateEmptyOverviewBoxesConfig}/>
								</div>

								<div className="notifications_custom_alerts_box_with_map">

									<div className="threat-map">
										<Map/>
									</div>
									<CustomAlertBox
										generateEmptyOverviewBoxesConfig={this.generateEmptyOverviewBoxesConfig}
										canCreateCustomAlert={canCreateCustomAlert}/>
								</div>
							</div>

							{this.props.visibleDevicesCount !== 0 && !this.props.isWalkthroughDone
								&& <WalkthroughDialog
									serviceName={overviewServiceName}
									serviceDescription={overviewServiceDescription}
									welcomeImage={welcomeImage}
									StartComponent={Welcome}
									steps={steps}
									EndComponent={SetNotifications}
									finishCallback={this.finishWalkthrough}/>}

							<PopupGraph content={this.props.newPoint ? <TopTalkers
								topConnectionsData={this.props.newPoint}
								startTime={moment(this.props.newPoint.x).format(timeFormaterGraphPopOver)}
								estVolume={formatBase2(this.props.newPoint.y)}
							/> : null}
							isOpen={!_.isEmpty(this.props.newPoint)}
							anchorPosition={this.props.event}
							/>

						</div>
						{summaryData && Boolean(summaryData.volumeOverTime)
							&& <div className="right-wrapper">
								<GraphBox title={configSummaryManagement.boxVolume} data={summaryData.volumeOverTime}/>
								<GraphBox title={configSummaryManagement.boxFlows} data={summaryData.flowsOverTime}/>
								<GraphBox title={configSummaryManagement.boxPacket} data={summaryData.packetsOverTime}/>
							</div>}
					</div>
				</div>

				{selectedBar && <GraphAlertPopOver
					anchorPosition={selectedBar.anchorPosition}
					selectedBar={selectedBar.selectedBar}/>}
			</>
		);
	}
}

SummaryView.propTypes = {
	NumOfDevices: PropTypes.number,
	dailyPackets: PropTypes.number,
	dailyDetectionsCount: PropTypes.number,
	event: PropTypes.any,
	isWalkthroughDone: PropTypes.bool,
	newPoint: PropTypes.object,
	notifications: PropTypes.array,
	openSnackbar: PropTypes.func,
	selectedBar: PropTypes.object,
	setTitle: PropTypes.func,
	summaryData: PropTypes.shape({
		NumOfDevices: PropTypes.number,
		flowsOverTime: PropTypes.any,
		notifications: PropTypes.array,
		packetsOverTime: PropTypes.any,
		volumeOverTime: PropTypes.any,
	}),
	totals: PropTypes.shape({
		dailyPackets: PropTypes.number,
		dailyDetectionsCount: PropTypes.number,
	}),
	updateWalkthroughDone: PropTypes.func,
	visibleDevicesCount: PropTypes.number,
	publicDevices: PropTypes.shape({
		[PropTypes.string.isRequired]: PropTypes.shape({
			IP: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}),
	collectorDevices: PropTypes.shape({
		[PropTypes.string.isRequired]: PropTypes.shape({
			IP: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
		}),
	}),
	publicCloudDevices: PropTypes.shape({
		[PropTypes.string.isRequired]: PropTypes.shape({
			name: PropTypes.string.isRequired,
			identifierValue: PropTypes.string.isRequired,
		}),
	}),
};

const mapStateToProps = state => ({
	summaryData: state.analyze.summaryData,
	totals: state.analyze.totals,
	newPoint: state.graph.newPoint,
	event: state.graph.event,
	selectedBar: state.alertes.selectedBar,
	isWalkthroughDone: state.interactions.overview.walkthroughDone,
	visibleDevicesCount: state.visibleDevicesCount.value,
	publicDevices: state.liteDevices.publicDevices,
	collectorDevices: state.liteDevices.collectorDevices,
	publicCloudDevices: state.liteDevices.publicCloudDevices,
});

const mapDispatchToProps = {
	removeNotification,
	selectPointOnGraph,
	setTitle,
	openSnackbar,
	updateWalkthroughDone,
	getLiteDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
