import React from 'react';
import {Typography} from "@material-ui/core";
import {useStyles} from "./PaymentFrame.css";


function TextFrame ({title, children}){
    const classes = useStyles();

    return(
        <div className={classes.textFrame}>
            <div>
                <Typography paragraph gutterBottom variant={"subtitle1"}
                            className={classes.textBlockTitle}>{title}</Typography>
            </div>
            <div className={classes.textFrame}>
                {children}
            </div>

        </div>
    )
}

export default TextFrame;