import React, { useCallback, useEffect, useState } from 'react';
import PlusIcon from 'mdi-react/PlusIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import StyleButton from '../../shared/components/form/Buttons/StyleButton';
import RuleModal from './ruleModal/ruleModal';
import RulesTable from './rulesTable/rulesTable';
import useFetch from '../../shared/customHooks/useFetch';
import { createRule, deleteRule as requestDeleteRule, getRules, updateRule } from './services/requests';
import { setTitle } from '../app/controller/redux/app/appActions';
import { openSnackbar } from '../snackbarAlert/controller/redux/actions';
import { SNACKBAR_HIDE_DURATION } from '../backgroundProcess/view/visibilityView/rawdataExport/constants';
import { covertRuleToModalValues, validateRuleForm } from './services/tools';
import { EXCLUSION_RULES_RESTRICTIONS_FIELD } from '../../constants/restrictionsFields';
import {
	ACCEPTLIST_CONFIG,
	MODAL_INITIAL_VALUES,
	MODAL_TYPE_TO_ERROR_TEXT,
	MODAL_TYPE_TO_SUCCESS_TEXT,
	RULE_DELETE_FAILED_MESSAGE,
	RULE_MODAL_CREATE_TYPE,
	RULE_MODAL_EDIT_TYPE,
	RULE_TOGGLE_FAILED_MESSAGE,
	RULES_RETRIVE_FAILED_MESSAGE,
} from './acceptlistManagementConfig';

import useStyles from './acceptlistPage.css';
import { getAndParseDefinition, getFullScreenDefinitionId } from './acceptlistDefinitionHelper';
import PropTypes from 'prop-types';

const NOT_FOUND_ROUTE = '/not-found';

const FORM_NAME = 'rule-form';

const RULE_MODAL_SUBMIT_ACTIONS = {
	// eslint-disable-next-line no-return-await
	[RULE_MODAL_CREATE_TYPE]: async rule => await createRule(rule),
	// eslint-disable-next-line no-return-await
	[RULE_MODAL_EDIT_TYPE]: async rule => await updateRule(rule),
};

const AcceptlistPage = ({ history }) => {
	const classes = useStyles();

	const dispatch = useDispatch();

	const exclusionRulesRestrictionLimit = useSelector(state => state.account.restrictions)[EXCLUSION_RULES_RESTRICTIONS_FIELD];

	const accountGroups = useSelector(state => state.account.groups);

	const mappedGroups = accountGroups.reduce((mapping, group) => Object.assign(mapping, { [group.objectGroup.objectId]: group.objectGroup.title }), {});

	const accountDevices = {
		...useSelector(state => state.liteDevices.publicDevices),
		...useSelector(state => state.liteDevices.collectorDevices),
		...useSelector(state => state.liteDevices.publicCloudDevices),
	};
	const mappedDevices = Object.entries(accountDevices).reduce(
		(mapping, [deviceId, device]) => Object.assign(mapping, { [deviceId]: device.name }),
		{},
	);

	const {
		data: rules,
		isLoading,
		error,
		fetchData,
	} = useFetch(useCallback(() => getRules(mappedGroups, mappedDevices), accountGroups));

	const [isModalOpen, setIsRuleModalOpen] = useState(false);
	const [modalType, setRuleModalType] = useState('');
	const [modalInitialValues, setModalInitialValues] = useState(MODAL_INITIAL_VALUES);

	useEffect(() => {
		dispatch(setTitle(ACCEPTLIST_CONFIG.title));
	}, []);

	useEffect(() => {
		error && dispatch(openSnackbar('error', RULES_RETRIVE_FAILED_MESSAGE, SNACKBAR_HIDE_DURATION));
	}, [error]);

	useEffect(() => {
		const handleHashChange = async () => {
			await tryToOpenDefinitionModal();
		};

		window.addEventListener('hashchange', handleHashChange);
		handleHashChange();
		return () => {
			window.removeEventListener('hashchange', handleHashChange);
		};
	}, []);

	const tryToOpenDefinitionModal = async () => {
		const definitionId = getFullScreenDefinitionId();
		if (definitionId) {
			try {
				const rule = await getAndParseDefinition(definitionId, mappedGroups, mappedDevices);
				openAcceptlistDefinitionModal(rule);
			} catch (error) {
				if (error.status === 404) {
					history.push(NOT_FOUND_ROUTE);
				} else {
					dispatch(openSnackbar('error', 'Something went wrong', 4000));
				}
			}
		}
	};

	const openAcceptlistDefinitionModal = rule => {
		const ruleModalValues = covertRuleToModalValues(rule);
		setModalInitialValues(ruleModalValues);
		handleRuleOpenModal(RULE_MODAL_CREATE_TYPE);
	};

	const handleRuleSubmit = async ruleData => {
		validateRuleForm(ruleData);
		try {
			await RULE_MODAL_SUBMIT_ACTIONS[modalType](ruleData);
			dispatch(openSnackbar('success', MODAL_TYPE_TO_SUCCESS_TEXT[modalType], SNACKBAR_HIDE_DURATION));
		} catch {
			dispatch(openSnackbar('error', MODAL_TYPE_TO_ERROR_TEXT[modalType], SNACKBAR_HIDE_DURATION));
		} finally {
			handleCloseRuleModal();
			fetchData();
		}
	};

	const handleRuleOpenModal = mode => {
		setIsRuleModalOpen(true);
		setRuleModalType(mode);
	};

	const hanleOpenCreateRuleModal = () => {
		setModalInitialValues(MODAL_INITIAL_VALUES);
		handleRuleOpenModal(RULE_MODAL_CREATE_TYPE);
	};

	const handleOpenEditRuleModal = rule => {
		const ruleModalValues = covertRuleToModalValues(rule);
		setModalInitialValues(ruleModalValues);
		handleRuleOpenModal(RULE_MODAL_EDIT_TYPE);
	};

	const handleCloseRuleModal = () => {
		setIsRuleModalOpen(false);
		setRuleModalType('');
	};

	const deleteRule = async rule => {
		try {
			const ruleId = rule.id;
			await requestDeleteRule(ruleId);
		} catch {
			dispatch(openSnackbar('error', RULE_DELETE_FAILED_MESSAGE, SNACKBAR_HIDE_DURATION));
		} finally {
			fetchData();
		}
	};

	const toggleRule = async rule => {
		try {
			await updateRule({ id: rule.id, isEnabled: !rule.isEnabled });
		} catch {
			dispatch(openSnackbar('error', RULE_TOGGLE_FAILED_MESSAGE, SNACKBAR_HIDE_DURATION));
		} finally {
			fetchData();
		}
	};

	return (
		<div>
			<div className={classes.topSection}>
				<Typography className={classes.pageDescriptionText}>
					Here you can set rules for legitimate & expected network traffic events to be ignored by Cynamics
				</Typography>
				<StyleButton
					isDisabled={rules && rules.length >= exclusionRulesRestrictionLimit}
					onClick={hanleOpenCreateRuleModal}
					className={classes.addRuleButton}
					icon={<PlusIcon/>}
					label="Add rule"
				/>
			</div>
			<div className={classes.table}>
				<RulesTable
					isLoading={isLoading}
					rules={rules}
					onEdit={handleOpenEditRuleModal}
					onDelete={deleteRule}
					onToggle={toggleRule}
				/>
			</div>
			{isModalOpen && (
				<RuleModal
					form={FORM_NAME}
					formName={FORM_NAME}
					isOpen={isModalOpen}
					actionType={modalType}
					onClose={handleCloseRuleModal}
					onSubmit={handleRuleSubmit}
					validateForm={validateRuleForm}
					initialValues={modalInitialValues}
					groups={accountGroups}
					devices={accountDevices}
				/>
			)}
		</div>
	);
};

AcceptlistPage.propTypes = {
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export default AcceptlistPage;
