import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		maxWidth: '70%',
	},
	chip: {
		borderRadius: '5px',
		display: 'flex',
		padding: '7px',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '14px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.light,
		height: '25px',
		margin: '0.3em',
		minWidth: 'fit-content',
	},
	aliasChip: {
		backgroundColor: '#071C36',
	},
	ipChip: {
		backgroundColor: '#48648B',
	},
	endpointsRemainder: {
		display: 'grid',
		gridTemplateColumns: 'auto auto auto',
		gap: '0.2em',
		width: 'max-content',
	},
}));

export default useStyles;
