import { Box } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { timeFormaterGraphPopOver } from '../../../constants/formater';
import ErrorMessage from '../../../shared/components/ErrorMessage/ErrorMessage';
import GraphTime from '../../../shared/components/graph/graphs/series/graph';
import Loader from '../../../shared/components/Loader/Loader';
import { NoData } from '../../backgroundProcess/view/visibilityView/statement/statementBox';
import { formatBase2 } from '../../topTalkers/formatters';
import PopupGraph from '../../topTalkers/popOvers/popOver';
import TopTalkers from '../../topTalkers/topTalkers';
import useStyles from './AnalysisGraph.css';
import ViewInAnalysis from './ViewInAnalysis/ViewInAnalysis';

const ERROR_TITLE = 'Something went wrong';
const ERROR_MESSAGE = 'Error loading \'Analysis\' tab. Please try again.';

const AnalysisGraph = ({ eventData, result, error, isLoading }) => {
	const classes = useStyles();

	const topTalkersNewPoint = useSelector(state => state.graph.newPoint);
	const topTalkersEvent = useSelector(state => state.graph.event);

	const [graphData, setGraphData] = useState();
	const [xDomain, setXDomain] = useState();
	const [currentTimeWindow, setCurrentTimeWindow] = useState();
	const [titleGraph, setTitleGraph] = useState();

	const setupGraph = () => {
		if (result) {
			const { xDomain, dataTypeOptions, timeWindow } = result.originalQuery;
			setTitleGraph(dataTypeOptions.label);
			setXDomain(xDomain);
			setCurrentTimeWindow(timeWindow);
			if (result.analysisResponse.analyzedData && result.analysisResponse.analyzedData[dataTypeOptions.accessor]) {
				setGraphData(result.analysisResponse.analyzedData[dataTypeOptions.accessor]);
			}
		}
	};

	useEffect(() => {
		setupGraph();
	}, [result]);

	const linkToQueryId = eventData && eventData.additionalInfo.linkToQuery ? eventData.additionalInfo.linkToQuery.queryId : '';

	return (
		<>
			{isLoading && <Box className={classes.messageWrapper}><Loader /></Box>}
			{error && !result && <Box className={classes.messageWrapper}><ErrorMessage title={ERROR_TITLE} message={ERROR_MESSAGE}/></Box>}
			{result ? graphData
				? <Box className={classes.graphWrapper}>
					<ViewInAnalysis analysisResult={result} queryId={linkToQueryId} />
					<GraphTime
						data={graphData}
						yTitle={titleGraph}
						xDomain={xDomain}
						timeUnits={currentTimeWindow}
					/>
					<PopupGraph
						content={
							topTalkersNewPoint
								? <TopTalkers
									topConnectionsData={topTalkersNewPoint}
									startTime={moment(topTalkersNewPoint.x).format(timeFormaterGraphPopOver)}
									estVolume={formatBase2(topTalkersNewPoint.y)}
								/> : null }
						isOpen={topTalkersNewPoint}
						anchorPosition={topTalkersEvent}
					/>
				</Box>
				: <Box>
					<ViewInAnalysis analysisResult={result} queryId={linkToQueryId} />
					<Box className={classes.noDataWrapper}>
						<NoData/>
					</Box>
				</Box>
				: null}
		</>
	);
};

AnalysisGraph.propTypes = {
	eventData: PropTypes.object,
	result: PropTypes.object,
	error: PropTypes.object,
	isLoading: PropTypes.bool,
};

export default AnalysisGraph;
