// COLLECTORS
import http from "../../../../services/httpService/http";
import {collectorPath} from "./urls";

const addCollector = (collectorName, osName , mapping , privateIP) => {
    const newCollector = {name : collectorName, OSType : osName , mapping , privateIP};
    /**
     * @newCollecor
     * {collectorName  , osName.toLowerCase() , mapping, privateIP}
     */
    return http.post(collectorPath , newCollector);
}
const getCollectors = ()=> {
    return http.get(collectorPath)
}

const updateCollector = (collectorId, newCollectorValue)=> {
    return http.put(collectorPath +  collectorId, newCollectorValue);
}

const removeCollector = (collectorId) => {
    return http._delete(collectorPath  + collectorId);
}

export {
    addCollector, 
    getCollectors,
    updateCollector,
    removeCollector
}