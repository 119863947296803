import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    image: {
        height: "280px",
        width: "300px"
    },
    infoIcon: {
        height: '17px',
        width: '17px'
    }
}));

export {useStyles}