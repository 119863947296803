import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import SendMailDialog from "../SendMail/SendMail";
import ReferAFriendPrompt from "../ReferAFriendPrompt/ReferAFriendPrompt";
import {setOpenReferAFriendFlow, setOpenReferAFriendPrimaryPopup} from "../redux/referAFriendActions";

const ReferAFriendWrapper = ({history}) => {
    const dispatch = useDispatch();
    const startFlowWithPrimary = useSelector(state => state.referAFriend.isStartFlowWithPrimaryPopup)
    const isReferAFriendContainerOpen = useSelector(state => state.referAFriend.isReferAFriendContainerOpen)

    const handleModalSwitch = () => {
        dispatch(setOpenReferAFriendFlow(true));
        dispatch(setOpenReferAFriendPrimaryPopup(false));
    }

    const closeSendMailDialog = () => {
        dispatch(setOpenReferAFriendFlow(false));
        removeReferAFriendQueryParam();
    }

    const closePrimaryModal = () => {
        dispatch(setOpenReferAFriendPrimaryPopup(false));
        removeReferAFriendQueryParam();
    }

    const removeReferAFriendQueryParam = () => {
        const cleanUrl = history.location.pathname;
        history.replace(cleanUrl);
    }

    return (
        <>
            <ReferAFriendPrompt isOpen={startFlowWithPrimary && !isReferAFriendContainerOpen}
                                callback={handleModalSwitch}
                                onClose={closePrimaryModal}/>
            <SendMailDialog onClose={closeSendMailDialog}
                            isOpen={isReferAFriendContainerOpen}/>
        </>
    )
}

export default ReferAFriendWrapper;