import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    root:{
        height: "30%",
        overflow:"visible",
        margin: '10px 0',
    }
});

export {useStyles};
