import React from "react"
import SuccessIndication from "../../../../shared/components/IndicationComponent/types/SuccessIndication";
import {useStyles} from "./confirmationWrapper.css";

const confirmationWrapper = ({indicationComponent = <SuccessIndication header={"test"} description={"testt"} buttonText={"Try again"}/>}) => {

    const classes = useStyles();

    const IndicationComponent =  indicationComponent;
    return (
        <div className={classes.indicatorWrapper}>
            {IndicationComponent}
        </div>
    )
}

export default confirmationWrapper;