import React, { Component } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import * as d3 from 'd3-format';
import GraphHintNumber from "./GraphHintNumber";
import { XYPlot, GradientDefs, Hint, XAxis, YAxis, AreaSeries, HorizontalGridLines, VerticalGridLines } from 'react-vis';


const horizontalGridLinesStyle = {
    stroke: "#7287a5",// blue-grey
    strokeDasharray: [1, 6],
    strokeWidth: 1.5,
    strokeLinecap: "round",
};
const verticalGridLinesStyle = {
    stroke: "#a8c1e4",// light-blue-grey
    strokeDasharray: [1, 6],
    strokeWidth: 1.5,
    strokeLinecap: "round",
};

const blueGradient =
    (
        <GradientDefs>
            <linearGradient
                id="blueGradient"
                gradientUnits="userSpaceOnUse"
                x1="50%" y1="100%" x2="50%" y2="0%"
            >
                <stop offset="0%" stopColor="#a8c1e4" stopOpacity="0.8" />
                <stop offset="100%" stopColor="#48648b" stopOpacity="0.8" />
            </linearGradient>
        </GradientDefs>
    );

export class GraphNumber extends Component {
    constructor(props) {
        super(props)
        this.state = { isOverSerie: false , hintValue : null}

        this.onSeriesMouseOver = this.onSeriesMouseOver.bind(this)
        this.onSeriesMouseOut = this.onSeriesMouseOut.bind(this)
    }
    onSeriesMouseOut = (event) => {
        this.setState({ isOverSerie: false })

    }
    onSeriesMouseOver = (event) => {
        this.setState({ isOverSerie: true })
    }   
    render() {

        const { label, data} = this.props
        const {isOverSerie , hintValue} = this.state 

        const XValue = data.map(a => {
            return a.x;
        })
        const YValue = data.map(a => {
            return a.y;
        })
        const newDataArray = []

        const xDomain = [Math.min(...XValue), Math.max(...XValue)];
        const Miny = Math.min(...YValue)
        const Maxy = Math.max(...YValue)

        const yDomain = [Miny, Maxy];
        const interval = Math.max(...YValue) - Math.min(...YValue);
        const ratiox = 5
        const tick = []
        for (let index = 0; index < ratiox ; index++) {
             tick.push(Miny*index + interval / ratiox);
        }
        tick.splice(0, 1, Miny); 
        tick.push(Maxy); 


        for (let index = 0; index < YValue.length; index++) {
            newDataArray.push({
                x: XValue[index],
                y: YValue[index]
            })
        }

        const [xTitle , yTitle]= [ 'Packets Size' , 'Packets Count' ]
        return (
           <div className='graph'>
                {label && <div className="graph-title">
                    {label} 
                </div>}
                <div className='graph-container'>
                    <AutoSizer>
                        {({ width, height }) => (

                            <div>
                                <XYPlot height={height} width={width}
                                    colorRange={['url(#blueGradient)']}
                                    style={{ stroke: 'black', strokeWidth: 0.6 }}
                                    xDomain={xDomain}
                                    yDomain={yDomain}
                                    margin={{ left: 75, right: 50 }} // to fix axis labels getting truncated
                                    spreadToBuckets={true}

                                >
                                    {blueGradient}
                                    <XAxis
                                        tickValues={XValue}
                                        tickFormat={(v, index) => {
                                            return (index % ratiox) == 0 ? d3.format('.2s')(v) : null;
                                        }}
                                    />
                                    <YAxis
                                        tickValues={tick}
                                        tickFormat={(v, index) => {
                                            return d3.format('.2s')(v);
                                        }}
                                    />
                                    <HorizontalGridLines
                                        style={horizontalGridLinesStyle}
                                    />
                                    <VerticalGridLines
                                        style={verticalGridLinesStyle}
                                    />
                                    <GradientDefs>
                                        <linearGradient id="blueGradient" x1="1" x2="1" y1="1" y2="1">
                                            <stop offset="0%" stopColor="black" stopOpacity={0.4} />
                                            <stop offset="100%" stopColor="green" stopOpacity={0.3} />
                                        </linearGradient>
                                    </GradientDefs>
                                    <AreaSeries
                                        color={'url(#blueGradient)'}
                                        data={newDataArray}
                                        onSeriesMouseOver={(event) => { this.onSeriesMouseOver(event) }}
                                        onSeriesMouseOut={(event)=>{this.onSeriesMouseOut(event)}}
                                        onNearestX={(value, event) => {
                                            this.setState({ hintValue: value });
                                        }}
                                       />

                                    {isOverSerie == true ? <Hint value={hintValue}
                                        align={{ horizontal: 'left', vertical: 'top' }}>
                                        <GraphHintNumber info={hintValue} 
                                            expanded={false}
                                            xTitle={xTitle}
                                            yTitle={yTitle} />  
                                    </Hint> : null}
                                </XYPlot>
                            </div>

                        )}
                    </AutoSizer>
                </div>
            </div>)

    }
}

/**
 *
 */