import React from 'react';
import useStyles from './EmptyBox.css';
import PropTypes from 'prop-types';

const EmptyBox = ({ image, title, description, children }) => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.boxContent}>
				{image && <img src={image} alt="no threats" className={classes.image}/>}
				<div className={classes.messageGroup}>
					<div className={classes.title}>
						{title}
					</div>
					<div className={classes.description}>
						{description}
					</div>
					{children}
				</div>
			</div>
		</div>
	);
};

EmptyBox.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.node,
	children: PropTypes.node,
};

export default EmptyBox;
