import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	head: {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: '0.2em',
	},
	headerTextColor: {
		color: 'white !important',
		fontWeight: 600,
		fontSize: '16px',
	},
}));

export { useStyles };
