const TEN_SECONDS = 10000;// in miliseconds

const horizontalGridLinesStyle = {
    stroke: "#7287a5",// blue-grey
    strokeDasharray: [1, 6],
    strokeWidth: 1.5,
    strokeLinecap: "round",
};
const verticalGridLinesStyle = {
    stroke: "#a8c1e4",// light-blue-grey
    strokeDasharray: [1, 6],
    strokeWidth: 1.5,
    strokeLinecap: "round",
};
const axisStyle = {
    line: { stroke: 'none' },
    ticks: { stroke: 'none' }
};
const levelGradients = {
    0: "url(#blueGradient)",
    1: "url(#yellowGradient)",
    2: "url(#redGradient)"
};
const levelMarkColors = {
    0: "#48648b",
    1: "#ffcb45",
    2: "#f05f5a"
};
const levelColors = {
    0: "#48648b",
    1: "#ffcb45",
    2: "#f05f5a"
};

const numberOfRecordsByScreenSize = {
    xs: 3,
    sm: 4,
    md: 6,
    lg: 10,
    xl: 16,
};

export {
    levelColors,
    levelGradients,
    levelMarkColors,
    verticalGridLinesStyle,
    horizontalGridLinesStyle,
    axisStyle,
    TEN_SECONDS,
    numberOfRecordsByScreenSize
}
