import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

    box: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px',
        height: '100%',
        justifyContent:"center"
    },

    boxItem: {
        margin: '10px',
        height: '33%'
    },

    button: {
        alignSelf: "top",
        width: '330px',
        height: '50px',
        color: 'white',
        borderRadius: '5px',
        textTransform: 'capitalize',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.hover
        }

    },
    headerContainer: {

    },
    headerImg: {
        backgroundImage: (props) => `url(${props.headerImg})`,
        backgroundSize: 'cover',
        height: '184px',
        width: '288px',
        backgroundPosition: 'center'
    },

    header: {
        fontWeight: '700',
        fontSize: '32px',
        marginTop: '5px'
    },

    description: {
        marginTop: '5px',
        fontWeight: '400',
        fontSize: '18px',
        whiteSpace: 'pre-wrap'
    }
}));


export {useStyles};