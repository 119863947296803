import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		border: '1px solid',
		borderColor: theme.palette.table.border,
	},
	tableRow: {
		backgroundColor: 'white',
		paddingTop: '2em',
		paddingBottom: '2em',
		width: '100%',
	},
	head: {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: '0.2em',
	},
	headerTextColor: {
		color: 'white !important',
		fontWeight: 400,
		fontSize: '16px',
		minWidth: '50px',
	},
	label: {
		color: theme.palette.text.secondary,
		fontSize: '16px',
		paddingLeft: '6px',
	},
	rawdataButton: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	messageWrapper: {
		marginTop: '10%',
	},
	loader: {
		marginRight: '60px',
		marginBottom: '16px',
	},
}));

export default useStyles;
