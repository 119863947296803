const configSummaryManagement = {
	pageTitle: 'Overview',
	boxDailyPacket: 'Daily packets sent',
	boxDailyThreats: 'Daily detections',

	boxDevices: 'Active devices',
	boxVolume: 'Volume',
	boxFlows: 'Flows',
	boxPacket: 'Packets',
};

const TEXTS = {
	queryCreationFailed: 'Something went wrong. Try again',
	reachedMaxError: 'You can run up to 7 queries at the same time',
	querySuccess: 'Query from VCA successfully created',
};

const MISSING_DATA_BANNER_PAGES = [configSummaryManagement.pageTitle, 'Group Management', 'Alerts', 'Virtual Cyber Analyst', 'Threat Management', 'Analysis View', 'Acceptlist', 'Integrations', 'User Management'];
export const PAYMENT_OVERDUE_BANNER_PAGES = [configSummaryManagement.pageTitle, 'Device Management', 'Group Management', 'Alerts', 'Virtual Cyber Analyst', 'Threat Management', 'Analysis View', 'Acceptlist', 'Integrations', 'User Management'];

export {
	configSummaryManagement,
	TEXTS,
	MISSING_DATA_BANNER_PAGES,
};
