import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%'
    },

    priceDisplay: {
        width: '90%',
        height: '50%'
    },

    todaysCharge: {
        display: 'inline-flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        width: '100%',
        height: '40%'
    },

    submitButton:{
        width: '100%'
    }
}));

export {useStyles}