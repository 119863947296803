const newIpsColumns = [
	{
		id: 'ip',
		label: 'IP',
	},
	{
		id: 'firstSeen',
		label: 'First Seen',
	},
];

const inactiveIpsColumns = [
	{
		id: 'ip',
		label: 'IP',
	},
	{
		id: 'lastSeen',
		label: 'Last Seen',
	},
];

const NEW_IPS_TYPE = 'New IPs';
const INACTIVE_IPS_TYPE = 'Inactive IPs';

export {
	newIpsColumns,
	inactiveIpsColumns,
	NEW_IPS_TYPE,
	INACTIVE_IPS_TYPE,
};
