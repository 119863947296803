import React from 'react';
import PropTypes from 'prop-types';
import RecordSummary from '../../recordComponent/recordSummary/recordSummary';
import TabWrapper from '../tabWrapper/tabWrapper';

const TranslatableTab = ({ data, valueKey, countKey, translationMapping, mappingField }) => (
	<>
		{data.map((dataToDisplay, index) => {
			const value = dataToDisplay[valueKey];
			const count = dataToDisplay[countKey];
			return (
				<div key={index}>
					<TabWrapper>
						<RecordSummary value={value}
							valueTranslation={value && value in translationMapping ? translationMapping[value][mappingField] : null}
							count={count}/>
					</TabWrapper>
				</div>
			);
		})}
	</>
);

TranslatableTab.propTypes = {
	countKey: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	mappingField: PropTypes.string.isRequired,
	translationMapping: PropTypes.object.isRequired,
	valueKey: PropTypes.string.isRequired,
};
export default TranslatableTab;
