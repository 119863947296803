import React from 'react';
import Intercom from 'react-intercom';
import {connect} from 'react-redux';

const CynamicsIntercom = ({user, ...props}) => {
    const IntercomUserID = {
        user_id: user.id,
        email: user.email,
        name: user.firstName + " " + user.lastName
    };
    const IntercomSettings = {
        alignment: 'right',
        horizontal_padding: 30,
        vertical_padding: 25
    };
    return (
        <Intercom appID="mpjclir5" {...IntercomUserID}{...IntercomSettings} />
    );
};
const mapStateToProps = state => {
    return {
        user: state.auth.user,
    };
};
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(CynamicsIntercom);