const SERVICE_NAME = "Groups";
const SERVICE_DESCRIPTION = "Set groups for monitoring specific assets, services and networks.";

const STEP_1_TITLE = "Creating groups";
const STEP_1_DESCRIPTION = "Here you can add a new group."

const STEP_2_TITLE = "Item type";
const STEP_2_DESCRIPTION = `Select type from a range of built-in options such as MAC and CIDR. 
            You can also add organizational text values, which can be filtered 
            in both Cynamics Anlaysis and Alerts views.`

const STEP_3_TITLE = "My groups";
const STEP_3_DESCRIPTION = "View and edit your groups."

export {SERVICE_NAME, SERVICE_DESCRIPTION, STEP_1_TITLE, STEP_1_DESCRIPTION, STEP_2_TITLE, STEP_2_DESCRIPTION, STEP_3_TITLE, STEP_3_DESCRIPTION}