import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '@material-ui/core';

const TextFieldWrapper = ({
	value,
	onChange,
	isError,
	helperText,
	name,
	...other
}) => (
	<TextField
		fullWidth
		color={'primary'}
		size="small"
		variant= "outlined"
		name={name}
		value={value}
		onChange={onChange}
		error={isError}
		helperText={helperText}
		{...other}/>
);

TextFieldWrapper.propTypes = {
	helperText: PropTypes.string,
	isError: PropTypes.bool,
	onChangeFunction: PropTypes.func,
	value: PropTypes.any.isRequired,
};

TextFieldWrapper.defaultProps = {
	isError: false,
};

export default TextFieldWrapper;
