import React from 'react';
import {stepperConfig} from "./config";
import StepDivider from "../../../assets/referAFriend/stepper/stepDivider.svg";
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import useStyles from "./StaticStepper.css";


const Divider = () => <Box sx={{
    backgroundImage: `url(${StepDivider})`,
    height: '105px',
    width: '135px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
}}/>

const StaticStepper = () => {
    const classes = useStyles();

    return (
        <Grid container justifyContent="center">
            {stepperConfig.map((step, index) =>
                <>
                    <Grid item container direction="column" xs={3} spacing={2}>
                        <Grid item className={classes.stepIconContainer}>
                            <img src={step.icon} alt={step.title} className={classes.stepIcon}/>
                        </Grid>
                        <Grid item>
                            <Typography color="textSecondary"
                                        paragraph
                                        align="center"
                                        className={classes.stepTitle}>
                                {step.title}
                            </Typography>
                            <Typography paragraph
                                        align="center"
                                        className={classes.stepDescription}>
                                {step.description}
                            </Typography>
                        </Grid>

                    </Grid>
                    {index < stepperConfig.length - 1 && <Divider/>}
                </>
            )}

        </Grid>
    )
}

export default StaticStepper;