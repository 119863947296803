import { FormControl, Select } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useStyles } from './dropdown.css';
import CustomTooltip from '../Tooltip/CustomTooltip';

const Dropdown = ({
	options,
	label = 'Select...',
	initialValue,
	displayError = false,
	isDisabled,
	onFieldChange,
	required = false,
	input,
	meta,
	disabledTooltipText,
	...props
}) => {
	const classes = useStyles();
	const [option, setOption] = React.useState(initialValue || '');

	useEffect(() => {
		!isDisabled && !input.value && input.value !== 0 && setOption('');
	}, [options]);

	useEffect(() => {
		setOption(input.value);
	}, [input && input.value]);

	const menuProps = {
		classes: {
			paper: classes.paper,
			list: classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
	};

	const iconComponent = props => (
		<ExpandMoreIcon className={props.className + ' ' + classes.icon}/>
	);

	const handleChange = event => {
		setOption(event.target.value);
		if (onFieldChange) {
			onFieldChange(event.target.value);
		}
	};

	const isDisabledItem = optionTarget => 'isAvailable' in optionTarget && !optionTarget.isAvailable;

	return (
		<div>
			<FormControl required={required} className={classes.formControl}>
				<Select
					value={option}
					disabled={isDisabled}
					onChange={handleChange}
					disableUnderline
					displayEmpty
					MenuProps={menuProps}
					IconComponent={iconComponent}
					classes={{ root: classes.select }}
					{...{ props }}
				>
					<MenuItem value="" disabled>
						{label}
					</MenuItem>
					{
						Array.isArray(options) && options.map((optionTarget, index) => (
							<CustomTooltip key={index} title={isDisabledItem(optionTarget) ? disabledTooltipText : ''} placement={'bottom-start'} {...{ ...optionTarget }}>
								{/* tooltip does not work without span on disabled */}
								<span>
									<MenuItem key={index} {...{ ...optionTarget }} selected={optionTarget.value === option} disabled={isDisabledItem(optionTarget)}>
										{optionTarget.label}
									</MenuItem>
								</span>
							</CustomTooltip>
						))
					}
				</Select>
				{displayError && meta && meta.error && meta.touched && <FormHelperText className={classes.error} error={Boolean(meta.error)}>{meta.error}</FormHelperText>}
			</FormControl>
		</div>
	);
};

Dropdown.propTypes = {
	displayError: PropTypes.bool,
	initialValue: PropTypes.string,
	input: PropTypes.object,
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	meta: PropTypes.object,
	onFieldChange: PropTypes.func,
	options: PropTypes.arrayOf({
		value: PropTypes.string,
		label: PropTypes.string,
	}),
	required: PropTypes.bool,
	className: PropTypes.string,
	disabledTooltipText: PropTypes.string,
};

export default Dropdown;
