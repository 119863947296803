const SET_ACCOUNT_INTERACTIONS = 'SET_ACCOUNT_INTERACTIONS';
const SET_USER_INTERACTIONS = 'SET_USER_INTERACTIONS';
const UPDATE_WELCOME_DONE = 'UPDATE_WELCOME_DONE';
const UPDATE_WALKTHROUGH_DONE = 'UPDATE_WALKTHROUGH_DONE';
const UPDATE_SSO_MIGRATION_NOTIFICATION_DONE = 'UPDATE_SSO_MIGRATION_NOTIFICATION_DONE';

export {
	SET_ACCOUNT_INTERACTIONS,
	SET_USER_INTERACTIONS,
	UPDATE_WELCOME_DONE,
	UPDATE_WALKTHROUGH_DONE,
	UPDATE_SSO_MIGRATION_NOTIFICATION_DONE,
};
