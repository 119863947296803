import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	fullScreenPortalContainer: {
		height: '100%',
		width: '100%',
		position: 'absolute',
	},
}));

export default useStyles;
