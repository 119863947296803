import { theme } from '../../../../helpers/muiTheme';
export const MOCK_TIMESTAMP = 1685879621;

export const mockData = (eventId, firstDetectionTime) => {
	const data = [];

	const currentDate = new Date(firstDetectionTime);
	const threshold = { value: 250000 };
	const alertId = 'ed63f4a7-b029-42aa-8df3-9941b2986bcd';

	for (let i = 0; i < 288; i++) {
		const hour = Math.floor(i * 5 / 60);
		const minute = i * 5 % 60;
		const date = Date.UTC(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate(),
			hour,
			minute);
		const value = Math.floor(Math.random() * 1000000);
		// Const color = value > threshold ? '#F05F5A' : '#00DF5D';
		const color = theme.palette.graph.point;
		data.push({ startTime: date, value, color });
	}

	return { graph: { alertStatistics: { items: data } }, threshold, alertId };
};
