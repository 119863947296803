import React from 'react';
import {Dialog, Grid, TextField, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ExitButton from "../../../shared/components/ExitButton/ExitButton";
import useStyles from "./SendMail.css";
import StaticStepper from "../StaticStepper/StaticStepper";
import CustomLinkGenerator from "./CustomLink/CustomLinkGenerator";
import SendMailForm from "./SendMailForm/SendMailForm";
import Divider from "@material-ui/core/Divider";
import {useDispatch, useSelector} from "react-redux";
import {sendInvitations} from "../requests";
import {openSnackbar} from "../../snackbarAlert/controller/redux/actions";
import headerIcon from "../../../assets/referAFriend/sendMailIcon.png";


const SendMailDialog = ({onClose, isOpen}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const referralId = useSelector(state => state.auth.user.referralId);

    const inviteFriends = (data) => {
        if (data.emails) {
            try{
                const filteredData = data.emails.map((recipient) => recipient.value);
                sendInvitations({recipients: filteredData});
            } catch(ex){
                // ignore exceptions
            }
            onClose();
            dispatch(openSnackbar("success", "Your invitation has been sent!", 4000));
        }
    }

    return (
        <Dialog onClose={onClose} open={isOpen} maxWidth={"xl"}>
            <Box height={"906px"} width={"1221px"}>
                <Grid item xs={12} className={classes.root}>
                    <Grid container direction={"row"}>
                        <Grid item xs={3}>
                            <ExitButton onClick={onClose} color={"white"}/>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} className={classes.header}>
                        <div className={classes.headerIcon}>
                            <img src={headerIcon} height={'70px'} />
                        </div>
                        <div className={classes.headerLabel}>
                            Refer a friend
                        </div>
                    </Grid>

                    <Grid item container xs={10} className={classes.mainContent} justifyContent={"center"}>
                        <Grid item xs={12}>
                            <StaticStepper/>
                            <Divider variant={"middle"}/>
                        </Grid>
                        <Grid item container direction="column" xs={6}
                              className={classes.inviteFriendsContainer}>
                            <Grid item>
                                <Typography variant={"h6"} gutterBottom paragraph color="textSecondary">Invite your
                                    friends</Typography>
                                <Typography gutterBottom paragraph>Insert your friends’ emails and send them invitations
                                    to Cynamics!</Typography>
                                <Grid item container className={classes.sendMailForm}>
                                    <SendMailForm onSubmit={inviteFriends}/>
                                </Grid>
                            </Grid>
                            {referralId && <Grid item>
                                <Typography color="textSecondary">Or share your referral link:</Typography>
                                <CustomLinkGenerator referralId={referralId}/>
                            </Grid>}

                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}

export default SendMailDialog;