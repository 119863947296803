import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: 'auto',
	},
	title: {
		fontSize: '22px',
		fontWeight: 600,
	},
	description: {
		fontSize: '18px',
	},
	redirectButton: {
		margin: '20px',
		width: '15em',
		fontWeight: 'bold',
	},
}));

export default useStyles;
