import React from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import { phoneValidation } from '../../containers/integrations/view/modals/fieldArrayComponents/smsArrayField';

const PhoneNumberWrapper = ({
	name,
	input,
	type,
	label,
	placeholder = '',
	size = 'medium',
	margin = 'normal',
	isDefaultDisplayed = true,
	variant = 'outlined',
	disabled = false,
	onInputChange,
	value,
	isError,
	helperText,
	setFieldValue,
	setFieldTouched,
	...rest
}) => <MuiPhoneNumber
	defaultCountry={isDefaultDisplayed ? 'us' : ''}
	onChange={value => setFieldValue(name, value)}
	error={isError}
	helperText={helperText}
	onBlur={() => setFieldTouched(name, true)}
	value={value}
	{...{
		size,
		placeholder,
		margin,
		type,
		name,
		label,
		variant,
		disabled,
		...input,
		...rest,
	}}
/>;

export default PhoneNumberWrapper;
