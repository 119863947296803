import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import InsightOrigineMenu from './insightOrigineMenu/insightOrigineMenu';
import InsightTable from './insighTable/insighTable';


import { connect } from 'react-redux';

import InsightIcon from '../../../../assets/insights/insight-icon.svg';
import {  createAlerts, getAlertes} from "../../../alertsManagement/controller/redux/actions";

import RoundCloseButton from "../../../../shared/components/form/Buttons/roundCloseButton";
import { createDevice } from '../../controller/businessLogic/entityCreator';
import {BASE_QUERY_EXTRA} from "../../controller/const";

class Insight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isMoreOpen: false,
            anchorEl: null
        }
        this.onCreateAlert = this.onCreateAlert.bind(this);
        this.onRemoveLine = this.onRemoveLine.bind(this);
    }

    onCreateAlert = async () => {
        const { originalQuery, customAlert, insight, assetId, assetTitle, queryType } = this.props.insight;
        const insightValue = insight.getValue(originalQuery);
        const queryObj = {
            name: `${queryType.shorter} spike in ${assetTitle} over ${insight.value}_${insightValue[0]}`,
            thresholdType: customAlert.threshold.type.toUpperCase(),
            thresholdValue: customAlert.threshold.value,
            timeWindow: 60,
            version: 1,
            devices: createDevice(assetTitle, assetId, 'o'),
            queryTypes: [queryType.value],
            extra: BASE_QUERY_EXTRA
        };
        queryObj[insight.label] = originalQuery[insight.label];
        await this.props.createAlerts(queryObj)
        await this.props.getAlertes();
    }
    handleClose = () => {
        this.setState({ isOpen: false });
    }
    onRemoveLine = () => { this.props.removeItem(this.props.insight.id) };
    render() {
        const { assetTitle, message, instances, queryType } = this.props.insight;

        const { isOpen, anchorEl } = this.state;
        let isNew = false;
        let classes = ['threat-wrapper'];

        return (
            <>
                <div
                    className={ classes.join(' ') }
                >


                    <div className='threat'>

                        <div className="threat-severity-time">
                            <img src={ InsightIcon } className="threat-severity" alt="" />
                        </div>
                        <div className="threat-message">
                            <div className="threat-title makebold" style={ isNew ? { fontWeight: "bold" } : {} }>
                                { assetTitle }
                            </div>
                            <div className="threat-description">
                                { message }
                            </div>
                        </div>

                    </div>

                    <div style={ { display: 'flex' } }>
                        <div>

                            <RoundCloseButton onClick={ (e) => { this.onRemoveLine(e) } } />
                            <InsightOrigineMenu
                                onCreateAlert={ (e) => { this.onCreateAlert(e) } }
                                onDetails={ (e) => { this.setState({ isOpen: true, anchorEl: e }); } }
                            />
                        </div>

                    </div>
                </div>
                <div>
                    <Popover
                        open={ isOpen }
                        onClose={ this.handleClose }
                        anchorPosition={ anchorEl }
                        anchorReference='anchorPosition'
                    >

                        <InsightTable
                            instanceType={ queryType.shorter }
                            insightData={ this.props.insight }
                            showMore={ e => { this.setState({ isMoreOpen: true, content: e }) } }
                            runQuery={ e => { this.runQuery(e) } }
                            instances={ instances } />

                    </Popover>
                </div>

            </>
        );
    }
}


const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = {
    createAlerts,
    getAlertes
};
export default connect(mapStateToProps, mapDispatchToProps)(Insight);


