import React from 'react';
import PropTypes from 'prop-types';

import Loader from '../../../shared/components/Loader/Loader';
import './layoutForm.scss';

const LayoutForm = ({ children, customMessage, isLoading, logo, logoClassName }) => (
	<div className="form-layout">
		<div className="form-wrapper">
			<div className="form-holder">
				{isLoading ? <Loader />
					: <>
						{logo ? <img className={logoClassName} src={logo} alt="logo" /> : <div className="form-logo" />}
						<div className="form-welcome">
							{customMessage ? customMessage : 'Welcome to Cynamics'}
						</div>
						{children}
					</>}
			</div>
		</div>
		<div className="layout-image" />
	</div>
);

LayoutForm.defaultProps = {
	logo: null,
	logoClassName: '',
};

LayoutForm.propTypes = {
	children: PropTypes.node,
	customMessage: PropTypes.string,
	isLoading: PropTypes.bool,
	logo: PropTypes.string,
	logoClassName: PropTypes.string,
};

export default LayoutForm;
