import timeZones from 'moment-timezone';
import moment from "moment";


const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]


const getCurrentTimeZone = () => {
    return timeZones.tz.guess();
}


const build_minutes = () => {
    return Array.from(Array(58)).map((e, index) => {
        return {
            label: `${index + 1}`,
            value: `${index + 1}`
        }
    })
}

const build_houres = () => {
    return Array.from(Array(24)).map((e, index) => {
        return {
            label: `${index} : 00`,
            value: index
        }
    })
}

const build_timeZone = () => {
    return timeZones.tz.names().map(e => {
        return {
            label: e,
            value: e
        }
    }).filter(e => {
        return e.value == getCurrentTimeZone()
    });
}

const build_week_days = () => {
    return days.map((e, i) => {
        return {
            value: i,
            label: e
        }
    })
}

const processTimeZone = (policy) => {
    return {
        SendingTimeZone: build_timeZone()[0],
        SendingTriggerDay: policy.SendingTriggerDay,
        SendingTriggerHour: policy.SendingTriggerHour
    }
}

export {
    build_houres,
    build_minutes,
    build_timeZone,
    build_week_days,
    getCurrentTimeZone,
    processTimeZone
}