import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
	tooltip: {
		backgroundColor: 'white',
		boxShadow: '0px 2px 7px #c7c7c7',
		maxWidth: '50em',
	},
}));

export default useStyles;
