import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {insightOrigineMenuConfig} from "./insightOrigineMenuConfig";



class InsightOriginalMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };

        this.onDetails = this.onDetails.bind(this)
        this.onCreateAlert = this.onCreateAlert.bind(this)
    }
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: { 'left': event.clientX, 'top': event.clientY } });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    onDetails = async () => {

        this.setState({ anchorEl: null });
        this.props.onDetails({
            ... this.state.anchorEl,
            left: this.state.anchorEl.left - 400
        })
    }
    onCreateAlert = async () => {
        this.setState({ anchorEl: null });
        this.props.onCreateAlert();
    }
    render() {
        const { anchorEl } = this.state;
        const { className } = this.props;
        const open = Boolean(anchorEl);
        return (
            <div className={ className }>
                <IconButton
                    onClick={ this.handleClick }
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorPosition={ anchorEl }
                    open={ open }
                    anchorReference='anchorPosition'
                    onClose={ this.handleClose }
                >
                    <MenuItem onClick={ (e) => { this.onDetails() } }>{insightOrigineMenuConfig.SEE_DETAILS}</MenuItem>
                    <MenuItem onClick={ (e) => { this.onCreateAlert() } }>{insightOrigineMenuConfig.CREATE_ALERT}</MenuItem>
                </Menu>
            </div>
        );
    }
}

// 
// TO ADD WHEN WE NEED TO CREATE ALERT FROM THE INSIGHTS

export default InsightOriginalMenu;


