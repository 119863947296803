const getPaymentMethod = async (stripe, paymentMethodData) => {
    let paymentMethod;
    try {
        // stripe method
        paymentMethod = await createStripePaymentMethod(stripe, paymentMethodData);
    } catch (e) {
        throw new Error(e.message);
    }
    return paymentMethod;
}

const createStripePaymentMethod = async (stripe, data) => {
    const {error, paymentMethod} = await stripe.createPaymentMethod(data);
    if (error) {
        throw Error(error);
    } else {
        return paymentMethod;
    }
}

export {getPaymentMethod}

