import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	actionsWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
	},
	enabledSwitch: props => ({
		display: 'flex',
		alignItems: 'center',
		color: props.isEnabled ? theme.palette.primary.main : theme.palette.primary.contrastText,
	}),
	divider: {
		height: '30px',
		alignSelf: 'center',
	},
	actionIcon: {
		cursor: 'pointer',
	},
}));

export default useStyles;
