import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, ListItemIcon } from '@material-ui/core';
import useStyles from './filterDropdown.css';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';

import { Check } from '@material-ui/icons';

const FilterDropdown = ({ items, onItemSelected, defaultSelectionIndex, inputItem, userSelectedIndex }) => {
	const [selectedIndex, setSelectedIndex] = useState(defaultSelectionIndex);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const classes = useStyles({ selectedIndex, defaultSelectionIndex });

	const menuProps = {
		classes: {
			paper: classes.paper,
			list: classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
	};

	useEffect(() => {
		if (userSelectedIndex) {
			setSelectedIndex(userSelectedIndex);
		} else {
			setSelectedIndex(defaultSelectionIndex);
		}

	}, [defaultSelectionIndex, userSelectedIndex]);

	useEffect(() => {
		if (inputItem && inputItem.value) {
			const itemIndex = items.findIndex(item => item.value === inputItem.value);
			setSelectedIndex(itemIndex);
		}
	}, [inputItem && inputItem.value]);

	const handleItemSelected = event => {
		const index = event.target.value;
		const dirty = index !== defaultSelectionIndex;
		const selectedItem = items[index];

		selectedItem.isDirty = dirty;

		setSelectedIndex(index);
		onItemSelected(selectedItem);
	};

	const handleMenuOpen = () => {
		setIsMenuOpen(true);
	};

	const handleMenuClose = () => {
		setIsMenuOpen(false);
	};

	return (
		<FormControl
			variant="outlined"
			size="small"
			className={`${classes.formControl} ${
				selectedIndex === defaultSelectionIndex
					? ''
					: classes.formControlItemSelected
			} ${isMenuOpen
				? ''
				: classes.selectInputClosed
			}`}
		>
			<Select
				open={isMenuOpen}
				onOpen={handleMenuOpen}
				onClose={handleMenuClose}
				value={selectedIndex}
				onChange={handleItemSelected}
				renderValue={selected => (
					<div
						className={`${classes.mainBoxInner} ${
							selectedIndex === selected ? classes.selectedMainBox : ''
						}`}
					>
						{selectedIndex !== defaultSelectionIndex && (
							<Check className={classes.checkIcon} />
						)}
						{items[selected]?.icon}
						<span className={classes.title}>{items[selected]?.label}</span>
					</div>
				)}
				classes={{
					root: `${classes.select} ${
						selectedIndex === defaultSelectionIndex
							? classes.selectItemNotSelected
							: classes.selectItemSelected
					}`,
				}}
				IconComponent={ExpandMoreIcon}
				MenuProps={menuProps}
				disableUnderline
			>
				{items.map((item, index) => (
					<MenuItem
						key={item.value}
						value={index}
						classes={{ root: classes.menuItem }}
					>
						{item.icon && (
							<ListItemIcon className={classes.itemIcon}>
								{item.icon}
							</ListItemIcon>
						)}
						{item.clickable ? <span onClick={item.onClick} className={classes.itemClickable}> {item.label} </span> : item.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

FilterDropdown.defaultProps = {
	items: [],
	defaultSelectionIndex: 0,
	onItemSelected() {},
	inputItem: {},
};

const itemPropType = PropTypes.shape({
	label: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	icon: PropTypes.node,
	isDirty: PropTypes.bool,
});

FilterDropdown.propTypes = {
	items: PropTypes.arrayOf(itemPropType).isRequired,
	defaultSelectionIndex: PropTypes.number,
	onItemSelected: PropTypes.func.isRequired,
	inputItem: itemPropType,
	userSelectedIndex: Number,
};

export default FilterDropdown;
