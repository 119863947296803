const LOW = 'LOW';
const MEDIUM = 'MEDIUM';
const HIGH = 'HIGH';

const THREATS_SEVERITIES_CONFIG = {
    2: LOW,
    5: MEDIUM,
    10: HIGH
};

export {
    HIGH, MEDIUM, THREATS_SEVERITIES_CONFIG
}