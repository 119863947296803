import React, {useEffect, useState} from 'react';
import {Field, getFormValues, reduxForm} from 'redux-form';
import {Divider, Grid, Typography} from '@material-ui/core';
import DirectionSelectCreateable
    from "../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable";
import {
    AVAILABILITY_ZONES,
    REGIONS,
    SERVICES
} from "./awsConfiguration";

import {
    ACCOUNT_ID_VALIDATOR,
    INTERFACE_ID_VALIDATOR,
    VPC_ID_VALIDATOR,
    SUBNET_ID_VALIDATOR,
    INSTANCE_ID_VALIDATOR, AZ_ID_VALIDATOR
} from "./validator";
import SubmitButton from "../../../shared/components/form/Buttons/SubmitButton";
import {INCLUDE_SIGN} from "../../../shared/components/queryLine/selectors/directionSelectCreatable/consts";
import {useStyles} from "./awsFilter.css";
import {useSelector} from "react-redux";
import {mapGroups} from "../../backgroundProcess/controller/businessLogic/mappers";
import { textSelectorStyles } from '../../../shared/utils/customSelectorStyles';

function AwsFilterForm({onSubmit, hostingFormName}) {
    const classes = useStyles();
    const formData = useSelector(state => getFormValues(hostingFormName)(state)['aws']);
    const [azOptions, setAzOptions] = useState([]);
    const groups = useSelector(state => state.account.groups);
    const mappedGroups = mapGroups(groups);

    const renderAzList = (event) => {
        let selectedAvailabilityZones = [];
        event.map(({direction, label, value}) => {
            if (direction === INCLUDE_SIGN) {
                selectedAvailabilityZones = selectedAvailabilityZones.concat(AVAILABILITY_ZONES[value]);
            }
        });
        setAzOptions(selectedAvailabilityZones);
    }

    return (
        <Grid container xs={12} className={classes.mainGrid}>
            <Grid item container xs={12} justify={"space-between"}>
                <Grid item xs={6} className={classes.smallInputSize}>
                    <Grid item>
                        <Typography className={classes.fieldLabel}>
                            Account IDs
                        </Typography>
                    </Grid>
                    <Field component={DirectionSelectCreateable}
                           onChange={() => {
                           }}
                           name={"aws.accountIds"}
                           label={"Accounts ID"}
                           placeholder={"Enter Account ID"}
                           validation={ACCOUNT_ID_VALIDATOR}
                           isFreeText={true}
                           additionalClasses={"react-select-creatable-filters"}
                           extraStyles={textSelectorStyles}
                    />
                </Grid>
                <Grid item xs={6} className={classes.smallInputSize}>
                    <Grid item>
                        <Typography className={classes.fieldLabel}>
                            Interface IDs
                        </Typography>
                    </Grid>
                    <Field component={DirectionSelectCreateable}
                           onChange={() => {
                           }}
                           name={"aws.interfacesIds"}
                           label={"Interfaces ID"}
                           placeholder={"Enter Interface Id"}
                           isFreeText={true}
                           validation={INTERFACE_ID_VALIDATOR}
                           additionalClasses={"react-select-creatable-filters"}
                           extraStyles={textSelectorStyles}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} justify={"space-between"}>
                <Grid item xs={6} className={classes.smallInputSize}>
                    <Grid item><Typography className={classes.fieldLabel}>
                        VPC IDs
                    </Typography>
                    </Grid>
                    <Field component={DirectionSelectCreateable}
                           onChange={() => {
                           }}
                           name={"aws.vpcIds"}
                           label={"VPC IDs"}
                           placeholder={"Enter VPC ID"}
                           isFreeText={true}
                           validation={VPC_ID_VALIDATOR}
                           additionalClasses={"react-select-creatable-filters"}
                           extraStyles={textSelectorStyles}
                    />
                </Grid>
                <Grid item xs={6} className={classes.smallInputSize}>
                    <Grid item>
                        <Typography className={classes.fieldLabel}>
                            Subnet IDs
                        </Typography>
                    </Grid>
                    <Field component={DirectionSelectCreateable}
                           onChange={() => {
                           }}
                           name={"aws.subnetIds"}
                           label={"Subnet IDs"}
                           placeholder={"Enter Subnet Id"}
                           validation={SUBNET_ID_VALIDATOR}
                           isFreeText={true}
                           additionalClasses={"react-select-creatable-filters"}
                           options={mappedGroups}
                           extraStyles={textSelectorStyles}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid item>
                    <Typography className={classes.fieldLabel}>
                        Instance IDs
                    </Typography>
                </Grid>
                <Field component={DirectionSelectCreateable}
                       onChange={() => {
                       }}
                       name={"aws.instanceIds"}
                       label={"Instance IDs"}
                       placeholder={"Enter Instance ID"}
                       isFreeText={true}
                       additionalClasses={"react-select-creatable-filters"}
                       validation={INSTANCE_ID_VALIDATOR}
                       options={mappedGroups}
                       extraStyles={textSelectorStyles}
                />
            </Grid>

            <Grid item xs={12} className={classes.divider}>
                <Divider/>
            </Grid>
            <Grid item container xs={12} justify={"space-between"}>
                <Grid xs={6} item className={classes.smallInputSize}>
                    <Grid item>
                        <Typography className={classes.fieldLabel}>
                            AWS Region
                        </Typography>
                    </Grid>
                    <Field component={DirectionSelectCreateable}
                           name={"aws.regions"}
                           label={"AWS Region"}
                           placeholder={"Select regions"}
                           isFreeText={false}
                           options={REGIONS}
                           additionalClasses={"react-select-creatable-filters"}
                           extraStyles={textSelectorStyles}
                    />
                </Grid>
                <Grid item xs={6} className={classes.smallInputSize}>
                    <Grid item>
                        <Typography
                            className={classes.fieldLabel}>
                            Availability Zones
                        </Typography>
                    </Grid>
                    <Field component={DirectionSelectCreateable}
                           onChange={() => {
                           }}
                           name={"aws.azs"}
                           label={"Availability Zones"}
                           placeholder={"Select availability zones"}
                           isFreeText={true}
                           validation={AZ_ID_VALIDATOR}
                           /*options={azOptions}
                           disabled={azOptions.length === 0}
                           additionalClasses={azOptions.length === 0 ? "react-select-creatable-filters-disabled" : "react-select-creatable-filters"}*/
                           additionalClasses={"react-select-creatable-filters"}
                           extraStyles={textSelectorStyles}
                    />
                </Grid>
            </Grid>
            <Grid xs={12} item>
                <Grid item>
                    <Typography className={classes.fieldLabel}>
                        Src AWS Service
                    </Typography>
                </Grid>
                <Field component={DirectionSelectCreateable}
                       onChange={() => {
                       }}
                       name={"aws.srcServices"}
                       label={"Src AWS Service"}
                       placeholder={"Select source"}
                       isFreeText={false}
                       options={SERVICES}
                       additionalClasses={"react-select-creatable-filters"}
                       extraStyles={textSelectorStyles}
                />
            </Grid>
            <Grid xs={12} item>
                <Grid item>
                    <Typography className={classes.fieldLabel}>
                        Dst AWS Service
                    </Typography>
                </Grid>
                <Field component={DirectionSelectCreateable}
                       onChange={() => {
                       }}
                       name={"aws.destServices"}
                       label={"Dst AWS Service"}
                       placeholder={"Select destination"}
                       isFreeText={false}
                       options={SERVICES}
                       additionalClasses={"react-select-creatable-filters"}
                       extraStyles={textSelectorStyles}
                />
            </Grid>

            <Grid item container xs={12} justify={"center"}>
                <SubmitButton onClick={onSubmit} label={"Close"} className={classes.submitButton}/>
            </Grid>

        </Grid>
    )
}

export default AwsFilterForm;
