import React, { Component } from 'react';
import _ from 'lodash';

function makeReduxTimeUnit(TimeUnit) {
  return class extends Component {
    render() {
      const { input, hasUnit } = this.props;
      return (
        <TimeUnit
          { ...input }
          hasUnit={ _.isUndefined(hasUnit) ? true : false }
          onChange={ value => { ; input.onChange(value) } }
          onBlur={ (e) => input.onBlur(e) }
          value={ input.value <= 0 ? 1 : input.value  }

        />
      )
    }
  }
}


export default makeReduxTimeUnit;