import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   submitButton:{
       display: 'flex',
       flexDirection: 'row',
       margin:' 0 0 60px 0',
       width: '100%',
       justifyContent: 'space-evenly'
   }
}));

export default useStyles
