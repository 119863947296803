import React, { Component } from 'react';
import { connect } from 'react-redux';
import integrations from '../../controller/constants/integrations';
import './integrationsPage.scss';
import IntegrationBox from './intergrationBox';
import IntegrationModalWrapper from '../modals/integrationModalWrapper';
import { setTitle } from '../../../app/controller/redux/app/appActions';
import _ from 'lodash';
import { getAvailableIntegrations } from '../../controller/services/requests';
import {
	activateIntegrationsByType,
	arrangeEnabledIntegrations,
	deleteIntegrationsByType,
} from '../../controller/businessLogic/enabled';
import { activeTypes } from '../../controller/constants/activeTypes';
import { DAILY_REPORT, WEEKLY_REPORT } from '../../controller/constants/integrationTypes';
import analyzer from '../../../../helpers/analyzer';
import { INTEGRATION_CATEGORY, OPEN_ACTION } from '../../../../helpers/analyzerConstants';
import PropTypes from 'prop-types';
import DeleteModal from '../../../../shared/components/deleteModal/deleteModal';
import {openSnackbar} from "../../../snackbarAlert/controller/redux/actions";

const ANALYZER_CATEGORY = INTEGRATION_CATEGORY;

class IntegrationsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedIntegration: null,
			reportIds: {
				dailyReport: '',
				weeklyReport: '',
			},
			enabled: {},
			integrationsLoadingFinished: false,
			deleteModalOpen: false,
			deleteModalTitle: '',
			selectedIntegrationForDelete: null,
		};
		this.updateSelectedIntegration = this.updateSelectedIntegration.bind(this);
		this.activateIntegration = this.activateIntegration.bind(this);
		this.updateEnabledIntegration = this.updateEnabledIntegration.bind(this);
		this.openDeleteModal = this.openDeleteModal.bind(this);
		this.deleteIntegration = this.deleteIntegration.bind(this);
		this.handleCancelDelete = this.handleCancelDelete.bind(this);
	}

	updateSelectedIntegration(selectedIntegration) {
		this.setState({ selectedIntegration });
		selectedIntegration && analyzer.event({
			category: ANALYZER_CATEGORY,
			action: OPEN_ACTION,
			label: `INTEGRATION_${selectedIntegration.toString().toUpperCase()}`,
		});
	}

	updateEnabledIntegration(integrationType, isActive) {
		this.setState({ enabled: { ...this.state.enabled, [integrationType]: isActive }, selectedIntegration: null });
	}

	async getEnabledIntegrations() {
		try {
			const availableIntegrations = await getAvailableIntegrations();
			if (!_.isEmpty(availableIntegrations)) {
				const dailyReportId = availableIntegrations[DAILY_REPORT].id;
				const weeklyReportId = availableIntegrations[WEEKLY_REPORT].id;
				const enabled = arrangeEnabledIntegrations(availableIntegrations);
				this.setState({
					reportIds: {
						dailyReport: dailyReportId,
						weeklyReport: weeklyReportId,
					},
					integrationsLoadingFinished: true,
				});
				this.setState({ enabled });
			}
		} catch (error) {
			console.log(error);
		}
	}

	async activateIntegration(integrationType, isActive) {
		const isEnabled = (isActive === activeTypes.ON);
		await activateIntegrationsByType(integrationType, isEnabled, this.state.reportIds);
		this.setState(prevState => ({ enabled: { ...prevState.enabled, [integrationType]: isActive } }));
	}

	async deleteIntegration(integration) {
		try{
			await deleteIntegrationsByType[integration.integrationType]();
			this.setState(prevState => ({
				enabled: { ...prevState.enabled, [integration.integrationType]: activeTypes.OFF },
				deleteModalOpen: false,
				selectedIntegrationForDelete: null,
			}));
			this.props.openSnackbar('success', 'Integration has been deleted successfully', 4000);
		} catch (e) {
			this.props.openSnackbar('error', 'Unable to delete configuration. Please contact support for further assistance', 4000);
		}

	}

	openDeleteModal(integration) {
		this.setState({
			...this.state,
			deleteModalOpen: true,
			deleteModalTitle: integration.deleteMessage,
			selectedIntegrationForDelete: integration,
		});
	}

	handleCancelDelete() {
		this.setState({
			...this.state,
			deleteModalOpen: false,
		});
	}

	async componentDidMount() {
		this.props.setTitle('Integrations');
		await this.getEnabledIntegrations();
	}

	render() {
		const {
			selectedIntegration,
			integrationsLoadingFinished,
			enabled,
			deleteModalOpen,
			deleteModalTitle,
			selectedIntegrationForDelete,
		} = this.state;
		return <>
			<div className="integration-page-header"> Connect, Integrate and manage applications</div>
			<div className="integrations-container" style={{ zIndex: 1000 }}>
				{integrationsLoadingFinished
					&& integrations.map((integration, index) => {
						if (!_.isEmpty(enabled[integration.integrationType]) || integration.isComingSoon || integration.isShowEnable) {
							return (
								<IntegrationBox
									title={integration.title}
									integrationType={integration.integrationType}
									descriptions={integration.description}
									icon={integration.icon}
									isComingSoon={integration.isComingSoon}
									enabled={enabled[integration.integrationType]}
									updateSelectedIntegration={this.updateSelectedIntegration}
									activateIntegration={this.activateIntegration}
									key={index}
									isDelete={integration.isDelete}
									isShowEnable={integration.isShowEnable}
									deleteIntegration={() => this.openDeleteModal(integration)}
                                    additionalSettings={integration.additionalSettings}
								/>);
						}


                    	return null;
                    })}

			</div>
			{!deleteModalOpen && <IntegrationModalWrapper
				selectedIntegration={selectedIntegration}
				updateSelectedIntegration={this.updateSelectedIntegration}
				updateEnabledIntegration={this.updateEnabledIntegration}
			/>}
			<DeleteModal
				modalOpen={deleteModalOpen}
				toggle={this.handleCancelDelete}
				onConfirm={() => this.deleteIntegration(selectedIntegrationForDelete)}
				message={deleteModalTitle}
			/>

		</>;
	}
}

/** <ToolProposalBanner /> */

const mapStateToProps = state => ({
	features: state.account.features,
});

const mapDispatchToProps = {
	setTitle,
	openSnackbar,
};

IntegrationsPage.propTypes = {
	setTitle: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsPage);

