import React from 'react';
import useStyles from './EmptyTable.css';
import emptyTableFilters from '../../../../assets/detectionCenter/emptyTableFilters.svg';

const NO_DETECTIONS_TEXT = 'No results found';
const NO_DETECTIONS_SUBTEXT_LINE_1 = 'No detection matched your search. You may want to try';
const NO_DETECTIONS_SUBTEXT_LINE_2 = 'adjusting your search and\\or filter options.';

const EmptyTableFilters = () => {
	const classes = useStyles();

	return (
		<>
			<img
				src={emptyTableFilters}
				alt="No detections with filter"
				className={classes.noDetectionsImageFilters}
			/>
			<div className={classes.emptyStateText}>{NO_DETECTIONS_TEXT}</div>
			<div className={classes.emptyStateSubtext}>{NO_DETECTIONS_SUBTEXT_LINE_1} <br/> {NO_DETECTIONS_SUBTEXT_LINE_2}</div>
		</>
	);
};

export default EmptyTableFilters;
