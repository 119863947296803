import _ from "lodash";
import moment from 'moment';
import {attackLevels} from "../../../../services/enums";
import SummaryData from "../../../../helpers/summaryData";
import {toLocaleDateString} from "../../../../helpers/datesHelper";

const generateCreationTimeWarning = (creationTime) => `Query using parameters created on
 ${toLocaleDateString(moment.unix(creationTime))} `;

const processSimpleAnalysis = (data, est_data) => {

    return {
        [est_data]: convertSimpleData(data.analyzed_df)
    }
}

const convertSimpleData = (data) => {

    const XValue = Object.values(data.packet_size)
    const YValue = Object.values(data.packet_count)
    const newDataArray = XValue.map((p, index) => {
        return {
            x: XValue[index],
            y: YValue[index]
        }
    })
    return newDataArray
}
const manipulateAnalyzedData = (analyzedData, est_data, timeWindow) => {
    const toReturn = _.cloneDeep(analyzedData)


    if (!_.isEmpty(analyzedData) && analyzedData.hasData
        && analyzedData[est_data]) {

        let analysisLevel = Object.keys(toReturn[est_data]);

        for (let keyindex = 0; keyindex < analysisLevel.length; keyindex++) {
            let key = analysisLevel[keyindex];

            for (let index = 0; index < toReturn[est_data][key].points.length; index++) {
                let element = toReturn[est_data][key].points[index];

                let topTalkers = element.topTalkers;

                topTalkers.forEach((o, i) => {

                    element.topTalkers[i].Count = element.topTalkers[i].Count / timeWindow
                });

                toReturn[est_data][key].points.splice(index, 1, {
                    y: element.y / timeWindow,
                    topTalkers: element.topTalkers,
                    x: element.x
                })
            }

        }
    }
    return toReturn;
};

const processAnalysis = (data, fromTime, toTime, est_data) => {
    const { analyzed_df, mid_attacks, high_attacks } = data;

    const { time_start, time_end } = analyzed_df;

    return {
        [est_data]: convertDataset(time_start, analyzed_df[est_data], _.pick(analyzed_df, ['top_dest_ports', 'top_source_ports', 'top_protocols', 'top_talkers']), mid_attacks, high_attacks)
    }
};
const convertDataset = (_aXis, _yXis, _topTalkers, _midAttacks, _highAttacks, queryType = null) => {

    let ret = [];
    const midAttacks = Object.values(_midAttacks), highAttacks = Object.values(_highAttacks);
    const aXis = Object.values(_aXis);
    const yXis = Object.values(_yXis);

    const topTalkers = Object.values(_topTalkers['top_talkers']);
    const topSourcePort = Object.values(_topTalkers['top_source_ports']);
    const topDestinationPort = Object.values(_topTalkers['top_dest_ports']);
    const topProtocol = Object.values(_topTalkers['top_protocols']);

    let dsPoints = Object.values(aXis).map((x, i) => {
        //return {x, y: scaleY ? scaleY * yXis[i] : yXis[i]};
        return {
            x, y: yXis[i],
            topTalkers: topTalkers[i],
            topSourcePort: topSourcePort[i],
            topDestinationPort: topDestinationPort[i],
            topProtocol: topProtocol[i]
        };
    });
    ret = highAttacks.map(arr => {
        const points = dsPoints.filter(dp => dp.x >= arr[0] && dp.x <= arr[1]); //take all x axis points that are in each high attack time range
        // const points = arr.map(x => {
        //     const pt = dsPoints.find(dp => dp.x === x);
        //     return {x, y: pt ? pt.y : dsPoints[dsPoints.length - 1].y}
        // });
        return { level: attackLevels.SEVERE, points: points };
    }).concat(midAttacks.map(arr => {
        const points = dsPoints.filter(dp => dp.x >= arr[0] && dp.x <= arr[1]); //take all x axis points that are in each mid attack time range
        // const points = arr.map(x => {
        //     const pt = dsPoints.find(dp => dp.x === x);
        //     return {x, y: pt ? pt.y : dsPoints[dsPoints.length - 1].y}
        // });
        return { level: attackLevels.WARNING, points: points };
    }));
    let section;
    dsPoints.forEach((dp) => {
        if (isInSections(highAttacks, dp) || isInSections(midAttacks, dp)) { //if current point is in high/mid attack sections
            if (section) { //if there is already a section, initalize
                section = null;
            }
        } else {
            if (!section) { //if current time isn't in high/mid attack sections
                section = { level: attackLevels.NONE, points: [] };
                ret.push(section);
            }
            section.points.push(dp);
        }
    });
    // const xMinDp = Math.min(...dsPoints.map(p => p.x));
    // const xMaxDp = Math.max(...dsPoints.map(p => p.x));
    // if (aXis.indexOf(xStart) === -1)
    //     ret.push({level: attackLevels.NONE, points: [{x: xStart, y: 0}, {x: xMinDp - 1000, y: 0}]});
    // if (aXis.indexOf(xEnd) === -1)
    //     ret.push({level: attackLevels.NONE, points: [{x: xMaxDp + 1000, y: 0}, {x: xEnd, y: 0}]});
    ret = ret.sort((sec1, sec2) => {
        return sec1.points[0].x - sec2.points[0].x;
    });

    console.log(ret, ret[0], queryType);

    return queryType ? SummaryData.enqueue(queryType, ret[0]) : ret;
};

const isInSections = (sections, point) => { //find the section that contains the point time
    return sections.find(sec => sec[0] <= point.x && sec[1] >= point.x)
}


export {
    isInSections,
    convertDataset,
    manipulateAnalyzedData,
    processSimpleAnalysis,
    processAnalysis,
    convertSimpleData,
    generateCreationTimeWarning
}