import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    },
    stepper: {
        backgroundColor: theme.palette.advancedButtons.main,
        dotActive: {
            backgroundColor: "red"
        }
    },
    buttons: {
        color: "white"
    },
    dots: {
        color: "white"
    }
}));

export {useStyles}