const REGIONS = [
    {
        value: 'us-east-2',
        label: 'US East 2 (Ohio)'
    }, {
        value: 'us-east-1',
        label: 'US East 1 (N. Virginia)'
    }, {
        value: 'us-west-1',
        label: 'US West 1 (N. California)'
    }, {
        value: 'us-west-2',
        label: 'US West 2 (Oregon)'
    }, {
        value: 'af-south-1',
        label: 'Africa 1 (Cape Town)'
    }, {
        value: 'ap-east-1',
        label: 'Asia Pacific 1 (Hong Kong)'
    }, {
        value: 'ap-south-1',
        label: 'Asia Pacific 1 (Mumbai)'
    }, {
        value: 'ap-northeast-3',
        label: 'Asia Pacific 3 (Osaka)'
    }, {
        value: 'ap-northeast-2',
        label: 'Asia Pacific 2 (Seoul)'
    }, {
        value: 'ap-southeast-1',
        label: 'Asia Pacific 1 (Singapore)'
    }, {
        value: 'ap-southeast-2',
        label: 'Asia Pacific 2 (Sydney)'
    }, {
        value: 'ap-northeast-1',
        label: 'Asia Pacific 1 (Tokyo)'
    }, {
        value: 'ca-central-1',
        label: 'Canada 1 (Central)'
    }, {
        value: 'eu-central-1',
        label: 'Europe 1 (Frankfurt)'
    }, {
        value: 'eu-west-1',
        label: 'Europe 1 (Ireland)'
    }, {
        value: 'eu-west-2',
        label: 'Europe 2 (London)'
    }, {
        value: 'eu-south-1',
        label: 'Europe 1 (Milan)'
    }, {
        value: 'eu-west-3',
        label: 'Europe 3 (Paris)'
    }, {
        value: 'eu-north-1',
        label: 'Europe 1 (Stockholm)'
    }, {
        value: 'me-south-1',
        label: 'Middle East 1 (Bahrain)'
    }, {
        value: 'sa-east-1',
        label: 'South America 1 (São Paulo)'
    }, {
        value: 'us-gov-east-1',
        label: 'AWS GovCloud 1 (US-East)'
    }, {
        value: 'us-gov-west-1',
        label: 'AWS GovCloud 1 (US-West)'
    },
];

const AVAILABILITY_ZONES = {
    'us-east-2': [
        {
            value: 'us-east-2a',
            label: 'us-east-2a'
        },
        {
            value: 'us-east-2b',
            label: 'us-east-2b'
        }, {
            value: 'us-east-2c',
            label: 'us-east-2c'
        }
    ],
    'us-east-1': [
        {
            value: 'us-east-1a',
            label: 'us-east-1a'
        }, {
            value: 'us-east-1b',
            label: 'us-east-1b'
        }, {
            value: 'us-east-1c',
            label: 'us-east-1c'
        }, {
            value: 'us-east-1d',
            label: 'us-east-1d'
        }, {
            value: 'us-east-1e',
            label: 'us-east-1e'
        }, {
            value: 'us-east-1f',
            label: 'us-east-1f'
        },
    ],
    'us-west-1': [
        {
            value: 'us-west-1a',
            label: 'us-west-1a'
        }, {
            value: 'us-west-1b',
            label: 'us-west-1b'
        }, {
            value: 'us-west-1c',
            label: 'us-west-1c'
        }
    ],
    'us-west-2': [
        {
            value: 'us-west-2a',
            label: 'us-west-2a'
        }, {
            value: 'us-west-2b',
            label: 'us-west-2b'
        }, {
            value: 'us-west-2c',
            label: 'us-west-2c'
        }, {
            value: 'us-west-2d',
            label: 'us-west-2d'
        }
    ],
    'us-gov-west-1': [
        {
            value: 'us-gov-west-1a',
            label: 'us-gov-west-1a'
        }, {
            value: 'us-gov-west-1b',
            label: 'us-gov-west-1b'
        }, {
            value: 'us-gov-west-1c',
            label: 'us-gov-west-1c'
        },
    ],
    'us-gov-east-1': [
        {
            value: 'us-gov-east-1a',
            label: 'us-gov-east-1a'
        }, {
            value: 'us-gov-east-1b',
            label: 'us-gov-east-1b'
        }, {
            value: 'us-gov-east-1c',
            label: 'us-gov-east-1c'
        }
    ],
    'ap-east-1': [
        {
            value: 'ap-east-1a',
            label: 'ap-east-1a'
        }, {
            value: 'ap-east-1b',
            label: 'ap-east-1b'
        }, {
            value: 'ap-east-1c',
            label: 'ap-east-1c'
        }
    ],
    'ap-south-1': [
        {value: 'ap-south-1a', label: 'ap-south-1a'},
        {value: 'ap-south-1b', label: 'ap-south-1b'},
        {value: 'ap-south-1c', label: 'ap-south-1c'}
    ],
    'ap-northeast-2': [
        {value: 'ap-northeast-2a', label: 'ap-northeast-2a'},
        {value: 'ap-northeast-2b', label: 'ap-northeast-2b'},
        {value: 'ap-northeast-2c', label: 'ap-northeast-2c'}

    ],
    'ap-southeast-1': [
        {value: 'ap-southeast-1a', label: 'ap-southeast-1a'},
        {value: 'ap-southeast-1b', label: 'ap-southeast-1b'},
        {value: 'ap-southeast-1c', label: 'ap-southeast-1c'}
    ],
    'ap-southeast-2': [
        {value: 'ap-southeast-2a', label: 'ap-southeast-2a'},
        {value: 'ap-southeast-2b', label: 'ap-southeast-2b'},
        {value: 'ap-southeast-2c', label: 'ap-southeast-2c'}
    ],
    'ap-northeast-1': [
        {value: 'ap-northeast-1a', label: 'ap-northeast-1a'},
        {value: 'ap-northeast-1b', label: 'ap-northeast-1b'},
        {value: 'ap-northeast-1c', label: 'ap-northeast-1c'},
        {value: 'ap-northeast-1d', label: 'ap-northeast-1d'}
    ],
    'ca-central-1': [
        {value: 'ca-central-1a', label: 'ca-central-1a'},
        {value: 'ca-central-1b', label: 'ca-central-1b'}
    ],
    'eu-central-1': [
        {value: 'eu-central-1a', label: 'eu-central-1a'},
        {value: 'eu-central-1b', label: 'eu-central-1b'},
        {value: 'eu-central-1c', label: 'eu-central-1c'}
    ],
    'eu-west-1': [
        {value: 'eu-west-1a', label: 'eu-west-1a'},
        {value: 'eu-west-1b', label: 'eu-west-1b'},
        {value: 'eu-west-1c', label: 'eu-west-1c'}
    ],
    'eu-west-2': [
        {value: 'eu-west-2a', label: 'eu-west-2a'},
        {value: 'eu-west-2b', label: 'eu-west-2b'},
        {value: 'eu-west-2c', label: 'eu-west-2c'}
    ],
    'eu-west-3': [
        {value: 'eu-west-3a', label: 'eu-west-3a'},
        {value: 'eu-west-3b', label: 'eu-west-3b'},
        {value: 'eu-west-3c', label: 'eu-west-3c'}
    ],
    'eu-north-1': [
        {value: 'eu-north-1a', label: 'eu-north-1a'},
        {value: 'eu-north-1b', label: 'eu-north-1b'},
        {value: 'eu-north-1c', label: 'eu-north-1c'}
    ],
    'me-south-1': [
        {value: 'me-south-1a', label: 'me-south-1a'},
        {value: 'me-south-1b', label: 'me-south-1b'},
        {value: 'me-south-1c', label: 'me-south-1c'}
    ],
    'sa-east-1': [
        {value: 'sa-east-1a', label: 'sa-east-1a'},
        {value: 'sa-east-1b', label: 'sa-east-1b'},
        {value: 'sa-east-1c', label: 'sa-east-1c'}
    ]
};


const SERVICES = [{
    value: 'AMAZON',
    label: 'Amazon'
}, {
    value: 'AMAZON_APPFLOW',
    label: 'Amazon AppFlow'
}, {
    value: 'AMAZON_CONNECT',
    label: 'Amazon Connect'
}, {
    value: 'API_GATEWAY',
    label: 'API Gateway'
}, {
    value: 'CHIME_MEETINGS',
    label: 'Amazon Chime (Meeting)'
}, {
    value: 'CHIME_VOICECONNECTOR',
    label: 'Amazon Chime (Voice Connector)'
}, {
    value: 'CLOUD9',
    label: 'Cloud9'
}, {
    value: 'CLOUDFRONT',
    label: 'CloudFront'
}, {
    value: 'CODEBUILD',
    label: 'CodeBuild'
}, {
    value: 'DYNAMODB',
    label: 'DynamoDB'
}, {
    value: 'EBS',
    label: 'Elastic Block Store (EBS)'
}, {
    value: 'EC2',
    label: 'EC2'
}, {
    value: 'EC2_INSTANCE_CONNECT',
    label: 'EC2 (Instance Connect)'
}, {
    value: 'GLOBALACCELERATOR',
    label: 'Global Accelerator'
}, {
    value: 'KINESIS_VIDEO_STREAMS',
    label: 'Kinesis Video streams'
}, {
    value: 'ROUTE53',
    label: 'Route 53'
}, {
    value: 'ROUTE53_HEALTHCHECKS',
    label: 'Route 53 (Health Checks)'
}, {
    value: 'ROUTE53_HEALTHCHECKS_PUBLISHING',
    label: 'Route53 (Health Checks Publishing)'
}, {
    value: 'ROUTE53_RESOLVER',
    label: 'Route 53 (Resolver)'
}, {
    value: 'S3',
    label: 'S3'
}, {
    value: 'WORKSPACES_GATEWAYS',
    label: 'Workspaces (Gateways)'
}];

export {
    REGIONS,
    AVAILABILITY_ZONES,
    SERVICES
}