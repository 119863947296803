import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import InputWrapper from '../../../../../alertsManagement/view/wrapper/inputWrapper';
import DirectionSelectCreateable from '../../../../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable';
import { getGroupsAsync } from '../../../../../app/controller/redux/account/accountActions';
import { mapGroups } from '../../../../controller/businessLogic/mappers';
import { customSelectorStyles, customPortSelectorStyles } from './customSelectorStyles';
import { validateIpOrMac, validateMac, validateNumericSelector, validatePort } from '../../../../controller/validations/validation';
import '../../../../../alertsManagement/view/createAlert/createAlerts.scss';
import './advanceLine1.scss';
import useProtocolIdAutoComplete from '../../../../../../shared/customHooks/useProtocolIdAutoComplete';

const AdvanceLine1 = ({
	groups,
	initialValues,
	getGroupsAsync,
	onChange,
}) => {
	const [options, setOptions] = useState([]);
	const { options: ipProtocolOptions, updateOptions } = useProtocolIdAutoComplete();

	useEffect(() => {
		const fetchGroups = async () => {
			await getGroupsAsync();
		};

		fetchGroups();
	}, [getGroupsAsync]);

	useEffect(() => {
		setOptions(mapGroups(groups));
	}, [groups]);

	const setValueType = value =>
		value.type || (validateMac(value.value.toUpperCase()) ? 'mac' : 'ip');

	return (
		<div className="line2">
			<div className="ipsource">
				<InputWrapper
					name={'Source IP or MAC'}
					input={
						<Field
							name="ipSource"
							component={DirectionSelectCreateable}
							options={options}
							onChange={value => onChange(value)}
							value={initialValues.ipSource}
							additionalClasses={'react-select-redux-forms-extra'}
							extraStyles={customSelectorStyles}
							placeholder={'Type an IP or MAC address / select one of your pre-defined groups'}
							validation={validateIpOrMac}
							addType={setValueType}
						/>
					}
				/>
			</div>
			<div className="portsource">
				<InputWrapper
					name={'Source Port'}
					input={
						<Field
							name="portsSource"
							component={DirectionSelectCreateable}
							additionalClasses={'react-select-redux-forms-extra'}
							extraStyles={customPortSelectorStyles}
							placeholder="Type"
							validation={validatePort}
						/>
					}
				/>
			</div>
			<div className="ipdestination">
				<InputWrapper
					name={'Destination IP or MAC'}
					input={
						<Field
							name="ipDestination"
							component={DirectionSelectCreateable}
							options={options}
							onChange={value => onChange(value)}
							value={initialValues.ipDestination}
							additionalClasses={'react-select-redux-forms-extra'}
							extraStyles={customSelectorStyles}
							placeholder={'Type an IP or MAC address / select one of your pre-defined groups'}
							validation={validateIpOrMac}
							addType={setValueType}
						/>
					}
				/>
			</div>
			<div className="portdestination">
				<InputWrapper
					name={'Destination Port'}
					input={
						<Field
							name="portsDestination"
							component={DirectionSelectCreateable}
							additionalClasses={'react-select-redux-forms-extra'}
							extraStyles={customPortSelectorStyles}
							placeholder="Type"
							validation={validatePort}
						/>
					}
				/>
			</div>
			<div className="protocol">
				<InputWrapper
					name={'Protocol ID'}
					tooltipText={'IP protocol number such as 6 (TCP) and 17 (UDP)'}
					input={
						<Field
							name="ipProtocol"
							component={DirectionSelectCreateable}
							placeholder={'Enter Protocol ID / select from dropdown'}
							options={ipProtocolOptions}
							value={initialValues.ipProtocol}
							additionalClasses={'react-select-redux-forms-extra'}
							validation={ value => {
								updateOptions(value);
								return validateNumericSelector(value);
							}}
							extraStyles={customSelectorStyles}
						/>
					}
				/>
			</div>
		</div>
	);
};

AdvanceLine1.propTypes = {
	groups: PropTypes.array,
	initialValues: PropTypes.object,
	onChange: PropTypes.func,
	getGroupsAsync: PropTypes.func,
};

const mapStateToProps = state => ({
	groups: state.account.groups,
	features: state.account.features,
});

const mapDispatchToProps = {
	getGroupsAsync,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdvanceLine1);
