import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	popoverPaperProps: {
		minHeight: '48px',
		minWidth: '400px',
	},
	wrapper: {
		padding: '10px 30px',
	},
	rowContainer: {
		height: '100%',
		padding: '5px 0px',
	},
	titleContainer: {
		marginRight: '10px',
		marginLeft: '20px',
	},
	title: {
		fontWeight: 'bold',
	},
	skeleton: {
		height: '25px',
		width: '100%',
	},
}));

export { useStyles };
