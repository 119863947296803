import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './textWithTooltip.css';
import CustomTooltip from '../Tooltip/CustomTooltip';

const TextWithTooltip = ({ text, maxLines, ...props }) => {
	const styleProps = { maxLines };
	const classes = useStyles(styleProps);
	const textRef = useRef(null);
	const [isOverflowing, setIsOverflowing] = useState(false);

	useEffect(() => {
		const handleWindowResize = () => {
			if (textRef.current) {
				const isOverflowing = textRef.current.scrollWidth > textRef.current.offsetWidth || textRef.current.offsetHeight < textRef.current.scrollHeight;
				setIsOverflowing(isOverflowing);
			}
		};

		handleWindowResize();
		window.addEventListener('resize', handleWindowResize);
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, [text]);
	return (
		<div>
			<CustomTooltip disableHoverListener={!isOverflowing} title={<p className={classes.tooltipText}>{text}</p>} classes={{ tooltip: classes.tooltip }} {...props}>
				<div ref={textRef} className={classes.text}>
					{text}
				</div>
			</CustomTooltip>
		</div>
	);
};

TextWithTooltip.defaultProps = {
	maxLines: 2,
};

TextWithTooltip.propTypes = {
	text: PropTypes.string.isRequired,
	maxLines: PropTypes.number,
};

export default TextWithTooltip;
