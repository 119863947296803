import React from "react";
import myAlertsSide from "../../../../../../assets/walkthrough/customAlerts/steps/myAlertsLocation.png";
import moreFeaturesMain from "../../../../../../assets/walkthrough/customAlerts/steps/moreFeatures.png";
import {STEP_4_DESCRIPTION, STEP_4_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV4
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV4/mainPanelTemplateV4";

const Step4 = () => {
    return (
        <StepTemplate sidePanelImage={myAlertsSide}>
            <MainPanelTemplateV4 title={STEP_4_TITLE}
                                 image={<img src={moreFeaturesMain}/>}
                                 description={STEP_4_DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step4;