import { chunk } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import FieldFactory from '../../../shared/components/simpleTable/fields/fieldsFactory';
import SimpleTable from '../../../shared/components/simpleTable/simpleTable';
import SkeletonTable from '../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import { RBAC_FEATURE_TOGGLE } from '../../../shared/components/smartTable/modals/const';
import { MAX_ROW_IN_TABLE } from '../../app/view/tableConfig/const';
import {
	ADMIN_TEXT,
	ADMIN_TOOLTIP,
	ANALYST_TEXT,
	ANALYST_TOOLTIP,
} from './const';
import useStyles from './userTable.css';

const UsersTable = ({ data, onDelete, isLoading, onEdit }) => {
	const classes = useStyles();
	const isCurrentUserAdmin = useSelector(state => state.auth.user.isAdmin);

	const isUserDeletable = () => true;

	const roleTooltip = role => role === ADMIN_TEXT ? ADMIN_TOOLTIP : ANALYST_TOOLTIP;

	data = data.map(row => ({
		...row,
		isAdmin: row.isAdmin ? ADMIN_TEXT : ANALYST_TEXT,
	}));

	const isMfaEnabledField = isCurrentUserAdmin ? FieldFactory.createCheckboxField('isMfaEnabled', 'MFA', false, true, true, true) : null;

	const columns = [
		FieldFactory.createTextField('firstName', 'First Name', false, true, true, true),
		FieldFactory.createTextField('lastName', 'Last Name', false, true, true, true),
		FieldFactory.createTextField('email', 'Email', false, true, true, true),
		FieldFactory.createTextField('isAdmin', 'Role', false, true, false, RBAC_FEATURE_TOGGLE, null, null, roleTooltip),
		isMfaEnabledField,
		FieldFactory.createDateField('lastLogin', 'Last Modified', false, true, false, true),
		FieldFactory.createAddEditField('editOrRemove', RBAC_FEATURE_TOGGLE && onEdit, onDelete, null, isUserDeletable, null),
	].filter(field => field);

	return (
		<>
			{isLoading || !data ? (
				<SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} className={classes.table} />
			) : (
				<SimpleTable
					totalItemsInPage={ MAX_ROW_IN_TABLE }
					pages={ chunk(data, MAX_ROW_IN_TABLE).length }
					columns={ columns } data={ data } isResizable={ false } />)
			}
		</>
	);
};

UsersTable.propTypes = {
	data: PropTypes.array,
	onDelete: PropTypes.func,
	isLoading: PropTypes.bool,
	onEdit: PropTypes.func.isRequired,
};

export default UsersTable;

