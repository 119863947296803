const IP_KEY = 'ip';
const AVERAGE_VOLUME_KEY = 'averageVolume';
const CURRENT_VOLUME_KEY = 'currentVolume';
const VOLUME_CHANGE_KEY = 'volumeChange';
const TIMESTAMP_KEY = 'timestamp';
const VOLUME_DIRECTION_IS_UP_KEY = 'volumeDirectionIsUp';

const endpointsDeviationColumns = [
	{
		id: IP_KEY,
		label: 'IP',
	},
	{
		id: AVERAGE_VOLUME_KEY,
		label: 'Average Volume',
	},
	{
		id: CURRENT_VOLUME_KEY,
		label: 'Hourly Volume',
	},
	{
		id: VOLUME_CHANGE_KEY,
		label: 'Volume Change (%)',
	},
];

export {
	endpointsDeviationColumns,
	IP_KEY,
	AVERAGE_VOLUME_KEY,
	CURRENT_VOLUME_KEY,
	VOLUME_CHANGE_KEY,
	TIMESTAMP_KEY,
	VOLUME_DIRECTION_IS_UP_KEY,
};
