import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { ReactComponent as Minus } from '../../../../../assets/selectors/minus.svg';
import { ReactComponent as Plus } from '../../../../../assets/selectors/plus.svg';
import { ReactComponent as X } from '../../../../../assets/selectors/x.svg';
import useStyles from './SelectionButton.css';
import { INCLUDED, EXCLUDED } from './consts';
import PropTypes from 'prop-types';

const SelectionButton = ({ value, onDelete, included }) => {
  const [status, setStatus] = useState(included ? INCLUDED : EXCLUDED);
  const classes = useStyles();

  const handleClick = () => {
    setStatus(status === INCLUDED ? EXCLUDED : INCLUDED);
  };

  return (
    <div className={classes.container}>
      <Button variant="contained" className={classes[status]} onClick={handleClick} disableRipple style={{ padding: 0 }}>
        {status === INCLUDED ? <Plus className={classes.includeExcludeButton} /> : <Minus className={classes.includeExcludeButton} />}
        <span className={classes.text}>{value}</span>
        <X onClick={onDelete} className={classes.deleteButton} />
      </Button>
    </div>
  );
};

SelectionButton.defaultProps = {
  value: '',
  onDelete: () => { },
  included: true,
}

SelectionButton.propTypes = {
  value: PropTypes.string,
  onDelete: PropTypes.func,
  included: PropTypes.bool
}

export default SelectionButton;
