import React, { Component } from 'react';

import './switch.scss';
import CustomTooltip from '../../../../../shared/components/Tooltip/CustomTooltip';
import StyledToggle from '../../../../../shared/components/form/Toggle/StyledToggle';
import PropTypes from 'prop-types';

class LineSwitch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEnabled: this.props.isEnabled,
		};
	}

	handleChange = () => {
		this.setState({ isEnabled: !this.state.isEnabled });
		this.props.onChange(!this.state.isEnabled);
	};

	render() {
		const { isEnabled } = this.state;
		return (
			<CustomTooltip
				title={isEnabled ? 'Disable' : 'Enable'}
				PopperProps={{ style: { marginTop: 0, marginLeft: 5 } }}
			>
				<div className="switch-container">
					<StyledToggle
						checked={ isEnabled }
						onChange={ this.handleChange}
						name="allowToggle"
					/>
				</div>
			</CustomTooltip>

		);
	}
}

LineSwitch.propTypes = {
	onChange: PropTypes.func,
	isEnabled: PropTypes.bool,
};
export default LineSwitch;
