import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import Dropdown from '../../../../../shared/components/dropdown/dropdown';

import useStyles from './CollectorsSubForm.css';

const DESCRIPTION_TEXT = 'Data from this device will be sent to the Sampler, which will convert the data to sFlow. The sFlow will then be sent through the Collector to Cynamics.';

const CollectorsSubForm = ({ collectors, samplers, isEditMode, selectedCollector, selectedSampler, change, form }) => {
	const classes = useStyles();

	const formData = useSelector(state => getFormValues(form)(state));
	const withSampler = 'isDeviceSupported' in formData ? !formData.isDeviceSupported : false || selectedSampler;

	samplers.forEach(sampler => {
		sampler.isAvailable = !sampler.attached;
	});

	return (
		<Grid container xs={12} rowSpacing={3}>
			{withSampler && (<>
				<Grid item>
					<Typography className={classes.title}>Collectors</Typography>
					<Typography paragraph className={classes.description}>{DESCRIPTION_TEXT}</Typography>
				</Grid>
				<Grid item xs={12} className={classes.selector}>
					<Field
						label="Sampler"
						name="samplerId"
						isDisabled={isEditMode}
						initialValue={selectedSampler}
						options={samplers}
						component={Dropdown}
						disabledTooltipText="Sampler is already allocated to a different device"
						onFieldChange={value => {
							change('samplerId', value);
						}}
						required
					/>
				</Grid>
			</>)}
			<Grid item xs={12} className={classes.selector}>
				<Field
					label="Collector"
					name="collectorId"
					isDisabled={isEditMode}
					initialValue={selectedCollector}
					options={collectors}
					component={Dropdown}
					onFieldChange={value => {
						change('collectorId', value);
					}}
					required
				/>
			</Grid>
		</Grid>
	);
};

CollectorsSubForm.propTypes = {
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	isEditMode: PropTypes.bool,
	selectedCollector: PropTypes.object,
	selectedSampler: PropTypes.object,
	change: PropTypes.func.isRequired,
	form: PropTypes.string,
};

export default CollectorsSubForm;
