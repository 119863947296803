import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DetectionAcceptlist from '../../../assets/icons/detection-acceptlist.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { FINDINGS_TABLE_BUTTON_TO_ACCEPTLIST } from '../../../constants/featureToggles';

const ACCEPTLIST_FORM_ROUTE = '/acceptlist/definition/';
const TOOLTIP_MESSAGE = 'Add to Acceptlist';

const AcceptlistButton = ({ history, children: definitionId }) => (
	FINDINGS_TABLE_BUTTON_TO_ACCEPTLIST && definitionId
		? <Tooltip title={TOOLTIP_MESSAGE}>
			<IconButton edge="start" onClick={() => {
				history.push(`${ACCEPTLIST_FORM_ROUTE}${definitionId}`);
			}}>
				<img src={DetectionAcceptlist} alt={TOOLTIP_MESSAGE}/>
			</IconButton>
		</Tooltip>
		: <></>
);

AcceptlistButton.propTypes = {
	children: PropTypes.string,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export default AcceptlistButton;
