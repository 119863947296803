import PropTypes from 'prop-types';

const DIRECTION_GROUP_TYPE = PropTypes.arrayOf(PropTypes.shape({
	label: PropTypes.string,
	value: PropTypes.string,
	direction: PropTypes.string,
	type: PropTypes.string,
}));

const AWS_TYPE = PropTypes.shape({
	accountIds: DIRECTION_GROUP_TYPE,
	interfacesIds: DIRECTION_GROUP_TYPE,
	vpcIds: DIRECTION_GROUP_TYPE,
	subnetsIds: DIRECTION_GROUP_TYPE,
	instanceIds: DIRECTION_GROUP_TYPE,
	regions: DIRECTION_GROUP_TYPE,
	azs: DIRECTION_GROUP_TYPE,
	srcServices: DIRECTION_GROUP_TYPE,
	destServices: DIRECTION_GROUP_TYPE,
});

const VLAN_TYPE = PropTypes.shape({
	srcVlanId: DIRECTION_GROUP_TYPE,
	dstVlanId: DIRECTION_GROUP_TYPE,
	vxlanId: DIRECTION_GROUP_TYPE,
});

const QUERY_TYPES_TYPE = PropTypes.shape({
	prefix: PropTypes.string,
	shorter: PropTypes.string,
	label: PropTypes.string,
	value: PropTypes.number,
	accessor: PropTypes.string,
	isPerSecondSupported: PropTypes.bool,
	queryType: PropTypes.string,
	isAlert: PropTypes.bool,
});

const LAST_TIME_RANGE_TYPE = PropTypes.shape({
	label: PropTypes.string,
	amount: PropTypes.number,
	timeUnit: PropTypes.string,
	value: PropTypes.number,
});

export const ANALYSIS_PAYLOAD_TYPE = {
	timeWindow: PropTypes.string,
	datefrom: PropTypes.string,
	timefrom: PropTypes.string,
	dateto: PropTypes.string,
	timeto: PropTypes.string,
	lastTimeRange: LAST_TIME_RANGE_TYPE,
	ipSource: DIRECTION_GROUP_TYPE,
	ipDestination: DIRECTION_GROUP_TYPE,
	portsDestination: DIRECTION_GROUP_TYPE,
	portsSource: DIRECTION_GROUP_TYPE,
	ipProtocol: DIRECTION_GROUP_TYPE,
	devices: DIRECTION_GROUP_TYPE,
	queryTypes: QUERY_TYPES_TYPE,
	isIn: PropTypes.bool,
	isOut: PropTypes.bool,
	isFWE_ALLOW: PropTypes.bool,
	isFWE_DENY: PropTypes.bool,
	SYN: PropTypes.bool,
	ACK: PropTypes.bool,
	FIN: PropTypes.bool,
	URG: PropTypes.bool,
	PSH: PropTypes.bool,
	RST: PropTypes.bool,
	ECE: PropTypes.bool,
	CWR: PropTypes.bool,
	vlan: VLAN_TYPE,
	vlanIdBidirectionalTraffic: PropTypes.bool,
	aws: AWS_TYPE,
	srcContinent: DIRECTION_GROUP_TYPE,
	srcCountryName: DIRECTION_GROUP_TYPE,
	srcOrganization: DIRECTION_GROUP_TYPE,
	srcDns: DIRECTION_GROUP_TYPE,
	srcIsp: DIRECTION_GROUP_TYPE,
	destContinent: DIRECTION_GROUP_TYPE,
	destCountryName: DIRECTION_GROUP_TYPE,
	destOrganization: DIRECTION_GROUP_TYPE,
	destDns: DIRECTION_GROUP_TYPE,
	destIsp: DIRECTION_GROUP_TYPE,
};
