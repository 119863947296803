import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';

const propTypes = {
	autoHideDuration: PropTypes.number.isRequired,
	severity: PropTypes.string.isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	action: PropTypes.node,
	children: PropTypes.node,
	customClass: PropTypes.string,
};

const SnackBarAlert = ({ autoHideDuration, severity, children, isOpen, onClose, action, customClass }) => <Snackbar open={isOpen} autoHideDuration={autoHideDuration} onClose={onClose}>
	<Alert severity={severity} variant="filled" action={action} className={customClass && customClass}>
		{children}
	</Alert>
</Snackbar>;

SnackBarAlert.propTypes = propTypes;

export default SnackBarAlert;
