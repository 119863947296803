import { SubmissionError } from 'redux-form';
import {
	VALIDATION_ATLEAST_ONE_FIELD,
	VALIDATION_ATLEAST_ONE_SERVICE,
	VALIDATION_DATA_ERROR,
	VALIDATION_SERVICE_ERROR,
} from '../acceptlistManagementConfig';

const RULE_TO_MODAL_PROPERTY_MAP = {
	additionalInfo: additionalInfo => ({ ...additionalInfo, ...additionalInfo.services }),
};

export const covertRuleToModalValues = rule =>
	Object.entries(rule).reduce((formRule, [key, value]) => {
		const property = key in RULE_TO_MODAL_PROPERTY_MAP ? RULE_TO_MODAL_PROPERTY_MAP[key](value) : { [key]: value };
		return Object.assign(formRule, property);
	}, {});

export const validateRuleForm = formData => {
	let isDataEntered = false;
	let isServiceSelected = false;

	for (const key in formData) {
		if (key) {
			if (!isDataEntered) {
				if (VALIDATION_ATLEAST_ONE_FIELD.includes(key) && formData[key].length > 0) {
					isDataEntered = true;
				}
			}

			if (!isServiceSelected) {
				if (VALIDATION_ATLEAST_ONE_SERVICE.includes(key) && formData[key]) {
					isServiceSelected = true;
				}
			}

			if (isDataEntered && isServiceSelected) {
				return;
			}
		}
	}

	const error = [];
	if (!isDataEntered) {
		error.push(VALIDATION_DATA_ERROR);
	}

	if (!isServiceSelected) {
		error.push(VALIDATION_SERVICE_ERROR);
	}

	if (formData.description && formData.description.trim().length === 0) {
		throw new SubmissionError({ description: 'Please enter rule name' });
	}

	throw new SubmissionError({
		_error: error,
	});
};
