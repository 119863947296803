import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { allOptionsFilterShow } from '../../controller/constants/setFiltersContent.js';
import _ from 'lodash';

class FilterTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }
    handleClick = (event: React.MouseEvent<HTMLElement>) => {

        this.setState({ anchorEl: { 'left': event.clientX , 'top': event.clientY } });

    };
    handleClose = (value, unit, title) => {
        
        if (_.isNumber(value) && !_.isEmpty(unit) && !_.isEmpty(title)) {
            this.setState({ anchorEl: null })
            this.props.onCloseReponse(value, unit, title);
        } else {
            const defaultValue = allOptionsFilterShow[0];
            this.setState({ anchorEl: null })
          //  this.props.onCloseReponse(defaultValue.value, defaultValue.timeUnit, defaultValue.title);
        }
    };
    render() {
        const { anchorEl } = this.state;
        const { value = "Select A value" } = this.props
        return (

            <div>
                <Button
                    aria-controls="simple-menu"
                    onClick={ this.handleClick }>
                    { value }

                </Button>
                <Menu
                    id="simple-menu"
                    anchorReference='anchorPosition'
                    anchorPosition={ anchorEl }
                    open={ Boolean(anchorEl) }
                    onClose={ this.handleClose }
                >
                    { allOptionsFilterShow.map((e, i) => {
                        return <MenuItem key={ i } onClick={ (a) => { this.handleClose(e.value, e.timeUnit, e.title) } }> { e.title } </MenuItem>
                    }) }
                </Menu>
            </div>
        );
    }
}



export default FilterTime;