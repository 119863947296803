import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	topSection: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '25px 25px 0 50px',
	},
	pageDescriptionText: {
		fontFamily: theme.typography.fontFamily,
		fontSize: '16px',
	},
	addRuleButton: {
		fontFamily: theme.typography.fontFamily,
		fontSize: '12px',
		minWidth: '110px',
		height: '36px',
	},
	table: {
		margin: '22px 26px',
	},
}));

export default useStyles;
