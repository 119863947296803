import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '100%'
    },

    plansSummary: {
        display: 'inline-flex',
        justifyContent: 'space-between',
        width: '100%'
    },

    arrow:{
        alignSelf: 'center'
    },

    disclaimer: {
        border: '1px solid #F06A65',
        backgroundColor: ' #FEE3E3',
        padding: '15px',
        borderRadius: '5px'
    },

    buttonsWrapper: {
        alignSelf: 'center',
        width: '50%'
    },

    button: {
        width: '172px',
        height: '40px'
    },

    link: {
        alignSelf: 'center',
        color: '#8391A7'
    },
    linkHover:{
        '&:hover':{
            color: theme.palette.primary.main
        }
    }
}));

export {useStyles}