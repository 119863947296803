import React from 'react';
import {Typography} from "@material-ui/core";
import moment from "moment";
import {useStyles} from "./orderSummary.css";
import clsx from "clsx";

function OrderSummary({planName, price, priceType, priceAsLabel, date, isStarting}) {
    const classes = useStyles();
    const subscriptionAction = isStarting ? 'Starts' : 'Ends';

    return (

            <div className={classes.container}>
                <div className={clsx(classes.borderBottom, classes.textAlign)}>
                    <Typography className={classes.planName} variant={"h6"}>{planName}</Typography>
                    {!priceAsLabel? <Typography className={classes.price}>${Number(price).toLocaleString()}/{priceType}</Typography> :
                        <Typography className={classes.price}>{priceAsLabel}</Typography>}

                </div>
                <div className={classes.textAlign}>
                    <Typography className={classes.subscriptionText}>{subscriptionAction} on <span
                        className={classes.subscriptionDate}>{date}</span></Typography>
                </div>
            </div>

    )

}

export default OrderSummary;