import React ,{useState} from "react";
import CheckboxInput from "../../../shared/components/checkboxInput/CheckboxInput";
import './tcpFlagsFields.css'
import {useStyles} from './tcpFlagsFields.css';

const TcpFlagsFields = ({formName}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.fields}>
                <CheckboxInput name={"SYN"} label={"SYN"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
                <CheckboxInput name={"PSH"} label={"PSH"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
                <CheckboxInput name={"FIN"} label={"FIN"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
                <CheckboxInput name={"ECE"} label={"ECE"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
            </div>
            <div className={classes.fields}>
                <CheckboxInput name={"ACK"} label={"ACK"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
                <CheckboxInput name={"RST"} label={"RST"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
                <CheckboxInput name={"URG"} label={"URG"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
                <CheckboxInput name={"CWR"} label={"CWR"} formName={formName} optionalStyles={{label: classes.label, icon: classes.icon}}/>
            </div>
        </div>
    );
}

export default TcpFlagsFields;