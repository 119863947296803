import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ThreatTable from '../table/threatTable';
import { DeviceTab } from '../../../../shared/components/tabs/style/DeviceTab';
import { Tab } from '@material-ui/core';
import TabPanel from '../../../../shared/components/tabPanel/tabPanel';
import { REFRESH_THREATS_TIME } from '../../controller/constants/const';
import { setTitle } from '../../../app/controller/redux/app/appActions';
import { getHighlightedThreats, mapThreatsResponse } from '../../controller/businessLogic/filters';
import { getThreats, hideThreats, markThreats } from '../../controller/services/requests';
import { configTreathsManagement } from '../configTreathsManagement';
import './threatsPage.scss';

class ThreatsPage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: 0,
			newinfo: false,
			threats: [],
			isLoading: false,
		};
		this.handleChange = this.handleChange.bind(this);
	}

	loadThreats = async () => {
		const threats = await getThreats();

		this.setState({ threats: mapThreatsResponse(threats) });
	};

	componentDidMount = async () => {
		this.props.setTitle(configTreathsManagement.pageTitle);
		this.setState({ isLoading: true });
		await this.loadThreats();
		this.setState({ isLoading: false });
		setInterval(() => {
			this.loadThreats();
		}, REFRESH_THREATS_TIME);
	};

	handleChange = async (event, newValue) => {
		this.setState({ value: newValue });
	};

	onDeleteThreat = async threat => {
		await hideThreats(threat.Id);
		this.loadThreats();
	};

	onChangeFavThreat = async threat => {
		await markThreats(threat.Id, !threat.isMarked);
		this.loadThreats();
	};

	render() {
		const { value, threats } = this.state;
		const allThreats = threats ? threats : [];
		const HighlightedThreats = getHighlightedThreats(threats, value);

		return <div className="content">
			<div className="management-container">
				<div className="public-device-line-break"></div>

				<div>
					<DeviceTab
						className="device-management-tabs-menu"
						onChange={ this.handleChange }
						value={ value }>
						<Tab className="device-management-tab" label={ configTreathsManagement.tab1Name } value={ 0 } />
						<Tab className="device-management-tab" label={ configTreathsManagement.tab2Name } value={ 1 }
							disabled={ HighlightedThreats.length === 0 } />
					</DeviceTab>
				</div>
				<div className="tab-wrapper">
					<TabPanel value={ value } index={ 0 }>
						<ThreatTable
							onChangeFavThreat={ this.onChangeFavThreat }
							onDeleteThreat={ this.onDeleteThreat }
							threats={ allThreats }
							isLoading={ this.state.isLoading }
						/>
					</TabPanel>
					<TabPanel value={ value } index={ 1 }>
						<ThreatTable
							onChangeFavThreat={ this.onChangeFavThreat }
							onDeleteThreat={ this.onDeleteThreat }
							threats={ HighlightedThreats }
							isLoading={ this.state.isLoading }
						/>
					</TabPanel>
				</div>
			</div>
		</div>;
	}
}

ThreatsPage.propTypes = {
	setTitle: PropTypes.func,
};

const mapDispatchToProps = {
	setTitle,
};

export default connect(null, mapDispatchToProps)(ThreatsPage);

