import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	container: {
		padding: '20px',
		height: '100%',
		overflow: 'auto',
		display: 'flex',
		flexDirection: 'column',
	},

	billing: {
		marginTop: '30px',
		width: '48%',
	},
	payment: {
		marginBottom: '0.8em',
		width: '48%',
	},
}));

export { useStyles };
