import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FieldArray } from 'redux-form';

import { SNACKBAR_HIDE_DURATION } from '../../../../backgroundProcess/view/visibilityView/rawdataExport/constants';
import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import { activeTypes } from '../../../controller/constants/activeTypes';
import { DAILY_TOP_DETECTIONS_REPORT } from '../../../controller/constants/integrationTypes';
import { updateDailyTopDetectionsReportIntegration } from '../../../controller/services/dailyTopDetectionsReport';
import { getSmsEmailIntegrations } from '../../../controller/services/smsRequests';
import BaseIntegrationForm from '../baseIntegrationForm';
import EmailArrayField from '../fieldArrayComponents/emailArrayField';

const FORM_NAME = 'daily-top-detections-report-integration-form';
const RECIPIENTS_FIELD_ARRAY_NAME = 'recipients';

const SUCCESS_MESSAGE = 'Your daily top detections report details successfully saved';
const ERROR_MESSAGE = 'Something went wrong, please try again';

const DailyTopDetectionsReport = ({ updateSelectedIntegration, updateEnabledIntegration }) => {
	const dispatch = useDispatch();

	const [initialValues, setInitialValues] = useState([]);

	useEffect(() => {
		const getInitialValues = async () => {
			const data = await getSmsEmailIntegrations();
			if (data?.integrations?.dailyTopDetectionsReport) {
				const { recipients } = data.integrations.dailyTopDetectionsReport;
				setInitialValues(recipients);
			}
		};

		getInitialValues();
	}, []);

	const saveIntegration = async formData => {
		const { recipients } = formData;
		try {
			await updateDailyTopDetectionsReportIntegration(recipients);
			dispatch(openSnackbar('success', SUCCESS_MESSAGE, SNACKBAR_HIDE_DURATION));
			updateEnabledIntegration(DAILY_TOP_DETECTIONS_REPORT, activeTypes.ON);
		} catch {
			dispatch(openSnackbar('error', ERROR_MESSAGE, SNACKBAR_HIDE_DURATION));
		} finally {
			updateSelectedIntegration(null);
		}
	};

	const RecipientsReportForm = () => (
		<div className="email-recipients">
			<FieldArray
				name={RECIPIENTS_FIELD_ARRAY_NAME}
				component={EmailArrayField}
				formName={FORM_NAME}
				required
			/>
		</div>);

	return (
		<BaseIntegrationForm
			title="Daily top detections report"
			form={FORM_NAME}
			onSubmit={saveIntegration}
			BaseIntegrationFormComponent={RecipientsReportForm}
			initialValues={{ recipients: initialValues }}
		/>
	);
};

DailyTopDetectionsReport.propTypes = {
	updateSelectedIntegration: PropTypes.func,
	updateEnabledIntegration: PropTypes.func,
};

export default React.memo(DailyTopDetectionsReport);
