import http from '../../../../services/httpService/http';
import { samplersPath } from './urls';

const createSampler = async samplerName => {
	const newSampler = { name: samplerName };
	const response = await http.post(samplersPath, newSampler);
	return response;
};

const getSamplers = async () => {
	const samplers = await http.get(samplersPath);
	return samplers;
};

const deleteSampler = async samplerId => {
	const deletedSampler = await http._delete(`${samplersPath}${samplerId}`);
	return deletedSampler;
};

export {
	createSampler,
	getSamplers,
	deleteSampler,
};
