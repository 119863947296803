import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   containerWidth:{
       width: '100%'
   },

    strengthMeter: {
        position: "relative",
        height: "3px",
        margin: "7px 0",
        borderRadius: "2px",
        width: '100%'

    },

    meterLabel: {
      float: 'right'
    },
    "strengthMeterFill0": {
        width: '25%',
        backgroundColor: "#ddd"
    },
    "strengthMeterSpan0": {
        color: "#ddd"
    },
    "strengthMeterFill1": {
        width: '50% !important',
        backgroundColor: "orangered"
    },
    "strengthMeterSpan1": {
        color: "orangered"
    },
    "strengthMeterFill2": {
        width: '75%',
        background: 'orange'
    },
    "strengthMeterSpan2": {
        color: 'orange'
    },
    "strengthMeterFill3": {
        width: '100%',
        background: 'yellowgreen'
    },
    "strengthMeterSpan3": {
        color: 'yellowgreen'
    },
    "strengthMeterFill4": {
        width: '100%',
        background: 'green'
    },
    "strengthMeterSpan4": {
        color: 'green'
    }

}));

export {useStyles}