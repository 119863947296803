import {START_WITH_PRIMARY_POPUP, OPEN_REFER_A_FRIEND_CONTAINER} from "./actionTypes";

const initialState = {
    isReferAFriendContainerOpen: false,
    isStartFlowWithPrimaryPopup: false
}


export default function (state = initialState, {type, payload}) {
    switch (type) {
        case START_WITH_PRIMARY_POPUP:
            return {
                ...state,
                isStartFlowWithPrimaryPopup: payload
            };
        case OPEN_REFER_A_FRIEND_CONTAINER:
            return {
                ...state,
                isReferAFriendContainerOpen: payload
            }
        default:
            return state;
    }
}

