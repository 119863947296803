import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		height: '40em',
		fontSize: '22px',
		gap: '30px',
	},
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: '30px',
	},
	section: {
		alignContent: 'left',
		marginLeft: '10px',
	},
	title: {
		fontSize: '20px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
		marginBottom: 0,
	},
	subTitle: {
		color: theme.palette.secondary.main,
		fontWeight: 'bold',
		fontSize: '18px',
	},
	text: {
		color: '#48648B',
		fontSize: '18px',
	},
	noteText: {
		color: '#48648B',
		fontSize: '16px',
	},
	clickable: {
		textDecoration: 'underline',
		cursor: 'pointer',
	},
	lookingForDataImage: {
		height: '115px',
		width: '215px',
		alignSelf: 'center',
		marginTop: '2%',
	},
	buttonsContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	button: {
		width: '250px',
		height: '50px',
		fontWeight: 700,
	},
}));

export { useStyles };
