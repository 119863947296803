import React from 'react';
import './graphBox.scss';
import GraphTime from "../graph/graphs/series/graph";
import {GRAPH_BUCKET_INTERVAL} from "../../../constants/constants";
import Moment from "moment";

const TIME_WINDOW = GRAPH_BUCKET_INTERVAL * 16;
const TIME_UNITS = GRAPH_BUCKET_INTERVAL;
const GraphBox = ({title, data}) => {
    const timeNow = Moment().toDate();
    const remainder = 5 - (timeNow.getSeconds() % 5);
    const nearestTimeNow = Moment(timeNow).add(remainder, "seconds").set("millisecond", 0).valueOf();
    return (
        <div className='graphbox'>
            <div className='title'>
                {title}
            </div>
            <GraphTime data={data}
                   yTitle={title}
                   xDomain={[nearestTimeNow - TIME_WINDOW, nearestTimeNow]}
                   timeUnits={TIME_UNITS / 1000}
                   spreadToBuckets={true}/>
        </div>
    )
};
export default GraphBox;