import http from "../../services/httpService/http";


const RESET_URL = 'users/v1/reset';

async function sendResetLink(email) {
    return http.post(RESET_URL, email)
}

async function validateLink(linkId) {
    return http.get(`${RESET_URL}/${linkId}`);
}

async function updatePassword(linkId, data) {
    return http.put(`${RESET_URL}/${linkId}`, data);
}




export {sendResetLink, validateLink, updatePassword}