import React, { Component, useState } from 'react';
import './suspicious.scss';
import CopyAll from './copyall';
import ArrowRightIcon from '../../../../assets/threats/arrowDirection-right.svg';
import ArrowLeftIcon from '../../../../assets/threats/arrowDirection-left.svg';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useStyles } from './suspiciousConnections.css';
import EnrichmentPopoverWrapper from '../../../../shared/components/enrichmentPopoverWrapper/enrichmentPopoverWrapper';
import { Typography } from '@material-ui/core';

export class ArrowComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { value } = this.props;
		return (
			<div style={ { display: 'flex', gap: 10 } }>
				<div><img src={ ArrowLeftIcon } alt="" /></div>
				{ !_.isEmpty(value) ? value : null }
				<div><img src={ ArrowRightIcon } alt="" /></div>
			</div>
		);
	}
}

const SuspiciousConnectionsTop = ({ array, title }) => {
	const classes = useStyles();

	return (
		<div className={classes.wrapper}>
			<h4> { title }</h4>
			<div className={classes.connectionsWrapper}>
				{ array.map((e, i) => <div key={ i } style={ { display: 'flex' } }>
					<div>
						<CopyAll
							text={ _.isEmpty(e.value) ? e.src : e.value.src }
						/>
					</div>

					<EnrichmentPopoverWrapper>
						{ _.isEmpty(e.value) ? e.src : e.value.src }
					</EnrichmentPopoverWrapper>
					<ArrowComponent />
					<EnrichmentPopoverWrapper>
						{ _.isEmpty(e.value) ? e.dst : e.value.dst }
					</EnrichmentPopoverWrapper>
					<div>
						<CopyAll text={ _.isEmpty(e.value) ? e.dst : e.value.dst } />
					</div>
				</div>) }
			</div>
		</div>

	);
};

const Connections = ({ connections, title, queryType }) => {
	const classes = useStyles();

	// [ { destIp : '123.3.3.2' , srcIp : '12.2.3.2' , value : 121}]
	return (
		<div className={classes.wrapper}>
			<h4> { title }</h4>
			<div className={classes.connectionsWrapper}

			>
				{ connections.map((connection, i) => <div key={ i } style={ { display: 'flex', gap: 6, justifyContent: 'center', alignItems: 'center' } }>
					<div>
						<CopyAll
							text={ connection.srcIp }
						/>
					</div>
					<EnrichmentPopoverWrapper>
						{ connection.srcIp }
					</EnrichmentPopoverWrapper>
					<Typography><ArrowComponent value={ `${connection.value} ${queryType.name}` } /></Typography>
					<EnrichmentPopoverWrapper>
						{ connection.destIp }
					</EnrichmentPopoverWrapper>

					<div>
						<CopyAll text={ connection.destIp } />
					</div>
				</div>) }
			</div>

		</div>

	);
};

class SuspiciousPortsDstTop extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { array, title } = this.props;

		return (
			<div className="extra-content">
				<h4> { title }</h4>
				<div><Typography>{ array.map(e => parseInt(e)).join(' / ') }</Typography></div>
			</div>

		);
	}
}

class SuspiciousPortsSrcTop extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { array, title } = this.props;

		return (
			<div className="extra-content">
				<h4> { title }</h4>
				<div>
					<Typography>{ array.map(e => {
						e = JSON.parse(e);
						return parseInt(e);
					}).join(' / ') }</Typography>
				</div>
			</div>

		);
	}
}

const SuspiciousIpsTop = ({ connections, title }) => {
	const classes = useStyles();
	return (
		<div className="extra-content">
			<h4> { title }</h4>
			<div className="sus-connection-top">{ connections.map((e, i) =>
				<div key={i} className={classes.topSusIpsItem}>
					<EnrichmentPopoverWrapper>
						{ e }
					</EnrichmentPopoverWrapper>
					<CopyAll text={ e } />
				</div>)
			}</div>
		</div>
	);
};

export {
	SuspiciousConnectionsTop,
	SuspiciousPortsDstTop,
	SuspiciousPortsSrcTop,
	SuspiciousIpsTop,
	Connections,

};

SuspiciousConnectionsTop.propTypes = {
	array: PropTypes.array.isRequired,
	title: PropTypes.string.isRequired,
};

Connections.propTypes = {
	connections: PropTypes.array,
	queryType: PropTypes.string,
	title: PropTypes.string,
};

SuspiciousIpsTop.propTypes = {
	connections: PropTypes.array,
	title: PropTypes.string,
};
