import React, {useState} from 'react';
import {InputAdornment, TextField} from "@material-ui/core";
import useStyles from "./CustomLinkGenerator.css";

const LINK_TEMPLATE = 'https://www.cynamics.ai/registration/?referralId='

const CopyLink = ({onClick}) => {
    const classes = useStyles();
    return (
        <InputAdornment classes={{root: classes.copy}}
                        position="end"
                        onClick={onClick}>
            Copy Link
        </InputAdornment>
    )
}

const LinkCopied = () => {
    const classes = useStyles();
    return (
        <InputAdornment classes={{root: classes.linkCopied}}
                        position="end">
            Copied!
        </InputAdornment>
    )
}

const CustomLinkGenerator = ({referralId}) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const copyToClipboard = async () => {
        const referralLink = LINK_TEMPLATE + referralId;
        try {
            await navigator.clipboard.writeText(referralLink);
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 1000);
        } catch (e) {

        }
    }

    return (
        <TextField disabled
                   fullWidth
                   value={referralId}
                   variant={"outlined"}
                   style={{
                       '&: .MuiOutlinedInput-notchedOutline':{
                           borderColor: 'red'
                       }
                   }}
                   InputProps={{
                       endAdornment: (
                           !copySuccess ? <CopyLink onClick={copyToClipboard}/> : <LinkCopied/>
                       )
                   }}
        />
    )

}

export default CustomLinkGenerator;