import { makeStyles } from '@material-ui/core/styles';

const successChipStyle = theme => ({
	backgroundColor: theme.palette.chips.successBackground,
	color: theme.palette.chips.success,
});

const errorChipStyle = theme => ({
	backgroundColor: theme.palette.chips.failureBackground,
	color: theme.palette.chips.failure,
});

const devicesChipStyle = {
	fontSize: '14px',
	padding: '4px 8px 4px 8px',
	marginLeft: '-5px',
};

const useStyles = makeStyles(theme => ({
	base: {
		minWidth: '68px',
		minHeight: '31px',
		borderRadius: '6.5px',
		textAlign: 'center',
		padding: '5px',
		alignSelf: 'flex-start',
		fontSize: '16px',
		fontWeight: 400,
	},
	on: {
		...successChipStyle(theme),
	},
	off: {
		width: '87px',
		backgroundColor: '#F6F6F6',
		color: '#757575',
	},
	none: {
		display: 'none',
		height: '0',
		width: '0',
	},
	failed: {
		width: '143px',
		...errorChipStyle(theme),
	},
	activeDevice: {
		...devicesChipStyle,
		...successChipStyle(theme),
	},
	inactiveDevice: {
		...devicesChipStyle,
		...errorChipStyle(theme),
	},

}));

export { useStyles };
