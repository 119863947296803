import {
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,useStripe
} from '@stripe/react-stripe-js';
import React,{useCallback,useEffect,useState} from "react";
import {Field, reduxForm} from "redux-form";
import TextField from "../../../shared/components/form/TextField/TextField";
import Grid from "@material-ui/core/Grid";
import StripeInput from "../stripe/stripeInput";
import BillingForm from "./billingForm/billingForm";
import {required,validateForm} from "redux-form-validators";
import {useDispatch} from "react-redux";
import Divider from "@material-ui/core/Divider";
import {useStyles} from "./checkoutForm.css";

const INITIAL_VALUES = {
    name: null,
    email: null,
    addressLine1: null,
    addressLine2: null,
    state: null,
    country: "",
    city: null,
    phoneNumber: null,
    zipCode: null
}

const CheckoutForm = ({stripe, handleSubmit, handleSubmitForm, paymentType, isSubmitted, setIsSubmitted, selectedPriceName, selectedBaseProduct, valid, change, setIsSubmitValid}) => {
    const [isCardNumberValid, setIsCardNumberValid] = useState(false);
    const [isExpDateValid, setIsExpDateValid] = useState(false);
    const [isCvcValid, setIsCvcValid] = useState(false);
    const dispatch = useDispatch();
    const elements = useElements();
    const classes = useStyles();
    const inputStyle = {
        height: "45px"
    }

    const cardValidation = useCallback(()=>!isCardNumberValid ? 'Invalid card number': undefined,[isCardNumberValid]);
    const expiryValidation = useCallback(()=> !isExpDateValid ? 'Invalid expiry date': undefined, [isExpDateValid])
    const cvcValidation = useCallback(() => !isCvcValid ? 'Invalid security code': undefined, [isCvcValid])

    const onSubmit = async (formData) => {
        if (!stripe || !elements) {
            // Stripe has not loaded yet
            return;
        }
        setIsSubmitValid(true);
        const cardElement = elements.getElement(CardNumberElement);
        const paymentMethodData = {
            type: paymentType,
            card: cardElement,
            billing_details: {
                name: formData.name,
                address: {
                    city: formData.city,
                    country: formData.country,
                    line1: formData.addressLine1,
                    line2: formData.addressLine2,
                    state: formData.state,
                    postal_code: formData.zipcode
                },
                phone: (formData.phoneNumber !== "+" ? formData.phoneNumber: null),
                email: formData.email
            }
        };
        const subscriptionData = {selectedPriceName: selectedPriceName, targetBaseProductId: selectedBaseProduct.baseProductId}
        const submitData = {paymentMethodData, subscriptionData};
        handleSubmitForm(submitData, onSuccessCallback);
    }

    const onSuccessCallback = () => {
        window.location.reload();
        return false;
    }

    const handleCardChange = (event) => {
        if (event.complete) {
            setIsCardNumberValid(true);
        } else {
            setIsCardNumberValid(false);
        }
    }
    const handleExpDateChange = (event) => {
        if (event.complete) {
            setIsExpDateValid(true);
        } else {
            setIsExpDateValid(false);
        }
    }
    const handleCvcChange = (event) => {
        if (event.complete) {
            setIsCvcValid(true);
        } else {
            setIsCvcValid(false);
        }
    }
    useEffect(()=>{
        if (isSubmitted){
            dispatch(handleSubmit(onSubmit));
            if (!valid || !stripe || !elements){
                setIsSubmitted(false);
            }
        }
    },[isSubmitted])


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Field
                        component={TextField}
                        required={true}
                        label="Card Number"
                        type="text"
                        onChange={handleCardChange}
                        validate={[cardValidation]}
                        name="cardNumber"
                        margin="none"
                        InputProps={{
                            style: inputStyle,
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={ TextField }
                        required={true}
                        label="Name On Card"
                        name="name"
                        type="text"
                        margin="none"
                        InputProps={{style: inputStyle}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={ TextField }
                        required={true}
                        label="Exp. Date"
                        name="expDate"
                        type="text"
                        margin="none"
                        onChange={handleExpDateChange}
                        validate={[expiryValidation]}
                        InputProps={{
                            style: inputStyle,
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardExpiryElement
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        component={ TextField }
                        required={true}
                        label="Security Code"
                        name="cvc"
                        type="text"
                        margin="none"
                        onChange={handleCvcChange}
                        validate={[cvcValidation]}
                        InputProps={{
                            style: inputStyle,
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardCvcElement
                            }
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Divider orientation="horizontal" className={classes.divider}/>
                </Grid>
            </Grid>
            <BillingForm change={change}/>
        </form>
    );
}

const validate = validateForm({
    name: [required({ msg: 'Please enter your name' })],
    email: [required({ msg: 'Please enter your email' })],
    addressLine1: [required({ msg: 'Please enter your address' })],
    city: [required({ msg: 'Please enter your city' })],
    zipcode: [required({ msg: 'Please enter your zipcode' })],
    country: [required({ msg: 'Please enter your country' })]
});



export default reduxForm({
    form: 'checkoutForm',
    initialValues: INITIAL_VALUES,
    validate
})(CheckoutForm);