import React from 'react';
import '../../alertNotifications/noNotification/notification.scss';

import { connect } from 'react-redux';

import _ from 'lodash';
import CustomAlertNotification from './customAlert';

import { withRouter } from 'react-router';
import { getAlertes, getAlertesGraph } from '../../../../alertsManagement/controller/redux/actions';
import EmptyBox from '../../emptyStateBox/EmptyBox';
import Bell from '../../../../../assets/alerts/bell.png';
import StyleButton from '../../../../../shared/components/form/Buttons/StyleButton';
import PropTypes from 'prop-types';

const ALERTS_BOX_TITLE = 'Alerts';

class CustomAlertBox extends React.Component {
	componentDidMount = async () => {
		await this.props.getAlertes();
	};

	render() {
		const { alertes, graphs, generateEmptyOverviewBoxesConfig, canCreateCustomAlert } = this.props;
		const ActiveAlerts = alertes.filter(e => e.enabled === true);
		const noCustomAlerts = _.isEmpty(ActiveAlerts) && ActiveAlerts.length === 0;
		const emptyBoxConfig = generateEmptyOverviewBoxesConfig(noCustomAlerts);
		return (
			<div className="notifications">
				<div className="title">
					<div className="title-and-icon">
						<img src={Bell} className="general-box-icon" alt="Threats" />
						<div>{ALERTS_BOX_TITLE}</div>
					</div>
					{ ActiveAlerts.length !== 0 && (<div>{ ActiveAlerts.length } { 'Enabled' }</div>) }
				</div>
				<div className="custom-alerts-notifications" style={ { height: window.innerHeight * 39 / 100 } }>
					{/* No Threats */ }
					{ noCustomAlerts
                        && <EmptyBox title={emptyBoxConfig.customAlerts.title} description={emptyBoxConfig.customAlerts.description} image={emptyBoxConfig.customAlerts.image}> {canCreateCustomAlert && <StyleButton className="table-add-button add-collector-button" label={'Create an alert'} onClick={() => this.props.history.push('/alerts-management')} />}
                        </EmptyBox>
					}

					{ ActiveAlerts
						.map((customAlert, i) => <CustomAlertNotification
							key={ i }
							customAlert={ customAlert }
							graphs={ graphs }
						/>) }
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	alertes: state.alertes.alertes,
	graphs: state.alertes.graphs,

});

const mapDispatchToProps = {
	getAlertes,
	getAlertesGraph,

};

CustomAlertBox.propTypes = {
	alertes: PropTypes.array,
	graphs: PropTypes.object,
	generateEmptyOverviewBoxesConfig: PropTypes.func,
	canCreateCustomAlert: PropTypes.bool,
	history: PropTypes.object,
	getAlertes: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomAlertBox));
