import React, { useEffect, useRef, useState } from 'react';
import { WidgetContainer } from '../../index';
import { STORE_HEALTH_WIDGET_TITLE } from './storeHealthTexts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highChartsTilemap from 'highcharts/modules/tilemap';
import highChartsHeatmap from 'highcharts/modules/heatmap';
import highchartsMore from 'highcharts/highcharts-more';
import { useTileMap } from '../../tileMapGraph/useTileMap';
import { CHART_HEIGHT, COLOR_KEY_VALUE, MAX_COLUMNS, overrides } from './tileMapConfig';
import { useStyles } from './storeHealth.jsx.css';
import { getDevicesPerfData } from '../services/requests';
import useInterval from '../../../customHooks/useInterval';

highchartsMore(Highcharts);
highChartsHeatmap(Highcharts);
highChartsTilemap(Highcharts);
require('highcharts/modules/map')(Highcharts);
const FETCH_STORE_HEALTH_DATA_INTERVAL_MS = 1000 * 60;
const generateActiveDevicesNumberLabel = devicesNumber => `${devicesNumber} Devices`;

const StoreHealth = () => {
	const [devicesPerformanceData, setDevicesPerformanceData] = useState([]);
	const chartRef = useRef(null);
	const { chartOptions } = useTileMap(devicesPerformanceData, COLOR_KEY_VALUE, CHART_HEIGHT, MAX_COLUMNS, overrides, chartRef);

	useEffect(() => {
		getDevicesPerformanceData();
	}, []);

	const getDevicesPerformanceData = async () => {
		try {
			const data = await getDevicesPerfData();
			setDevicesPerformanceData(data);
		} catch (error) {
			console.error('Error fetching devices performance data:', error);
		}
	};

	useInterval(getDevicesPerformanceData, FETCH_STORE_HEALTH_DATA_INTERVAL_MS);

	const classes = useStyles();

	return <WidgetContainer title={STORE_HEALTH_WIDGET_TITLE} sideComponent={generateActiveDevicesNumberLabel(devicesPerformanceData.length)}>
		{devicesPerformanceData
			&& <div className={classes.highchartsContainer}><HighchartsReact
				highcharts={Highcharts}
				constructorType={'mapChart'}
				options={chartOptions}
				ref={chartRef}
			/></div>}
	</WidgetContainer>;
};

StoreHealth.propTypes = {
};
StoreHealth.defaultProps = {
};

export default StoreHealth;
