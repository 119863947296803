import React from "react";
import Notification from "../../../../shared/components/Notification/Notification";
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error, info);
  }

  render() {
    if (this.state.hasError && this.state.error) {
      // You can render any custom fallback UI
      return <Notification color={"danger"} title={"Error"} content={this.state.error} />;
    }

    return this.props.children;
  }
}
