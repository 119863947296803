import React, { Component } from 'react';
import { attackLevels } from '../../../../../services/enums';
// Third party libraries
import Moment from 'moment';
import * as d3 from 'd3-format';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
	XYPlot,
	XAxis,
	YAxis,
	HorizontalGridLines,
	VerticalGridLines,
	CustomSVGSeries,
	Hint,
	VerticalBarSeries,
} from 'react-vis';
import GraphHint from './GraphHint';
import './graph.scss';
import { connect } from 'react-redux';
import { selectPointOnGraph } from '../../../../../containers/backgroundProcess/controller/redux/graphActions';
// TODO Refractor CustomMaskSerie is not use Yet

import { dataTypeOptions } from '../../../../../containers/backgroundProcess/controller/redux/analyzeActions';
import _ from 'lodash';
import {
	levelMarkColors,
	verticalGridLinesStyle,
	horizontalGridLinesStyle,
	axisStyle, numberOfRecordsByScreenSize,
} from '../../graphConfig';
import {
	redGradient,
	blueGradient,
	yellowGradient,
} from '../../gradients/gradients';
import { timeFormaterGraphPopOver } from '../../../../../constants/formater';
import withWidth from '@material-ui/core/withWidth';

const MINIMUM_POINTS_ON_GRAPH = 3;

class GraphTime extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hintValue: null,
			hintExpanded: false,
			selectedBar: null,
		};
	}

	// Add start and end point
	addEndingPoints = (data, startPoint, endPoint) => {
		let lineData = [];
		data.forEach(section => {
			lineData = lineData.concat(section.points);
		});
		// LineData.unshift({x: startPoint, y: 110}); // todo - why is it needed
		// lineData = lineData.concat([{x: endPoint, y: 110}]); // todo - why is it needed
		return lineData;
	};

	filterDataByDomain = (data, domain) => {
		const filteredData = data.map(section => ({
			...section,
			points: section.points.filter(point => point.x >= domain[0] && point.x <= domain[1]),
		}));
		return filteredData;
	};

	aggregateBucket = (existingBucket, newPoint) => {
		const isExistingHigherSeverity = !_.isUndefined(newPoint.color) && !_.isUndefined(existingBucket.color) && existingBucket.color > newPoint.color;
		// The bucket initialized without toptalkers & with y=0, so we insert the first point toptalkers & y value to the bucket
		const topTalkers = _.isUndefined(existingBucket.topTalkers) ? newPoint.topTalkers : existingBucket.topTalkers;
		const yValue = existingBucket.y == 0 ? newPoint.y : existingBucket.y;
		const aggregatedBucket = {
			...newPoint,
			color: isExistingHigherSeverity ? existingBucket.color : newPoint.color,
			x: existingBucket.x,
			y: yValue,
			topTalkers: isExistingHigherSeverity ? topTalkers : newPoint.topTalkers,
		};
		return aggregatedBucket;
	};

	createBarData = (data, domain, spreadToBuckets) => {
		let barData = [];
		const { width: screenWidth } = this.props;
		data.forEach(section => {
			barData = barData.concat(section.points.map(point => ({ ...point, color: section.level })));
		});
		const numOfBuckets = numberOfRecordsByScreenSize[screenWidth];

		// Separates columns on graph
		if (spreadToBuckets || barData.length === 1) {
			const startTime = domain[0];
			const endTime = domain[1];
			const interval = Math.floor((endTime - startTime) / numOfBuckets);
			const buckets = [...Array(numOfBuckets).keys()].map(key => ({
				x: startTime + key * interval,
				y: 0,
			}));
			barData.map(point => {
				try {
					const bucketIndex = Math.floor((point.x - startTime) / interval);
					buckets[bucketIndex] = this.aggregateBucket(buckets[bucketIndex], point);
				} catch (error) {
					console.log(`Error while creating bar data: ${error}`);
				}
			});
			return buckets;
		}

		return barData;
	};

	getTotalPointsOnGraph = pointsOnGraph => pointsOnGraph.reduce((total, level) => total + level.points.length, 0);

	// Checks if timeframe extension in needed
	isRangeExtendNeeded = (range, window, pointsOnGraph) => {
		const totalPoints = this.getTotalPointsOnGraph(pointsOnGraph);
		if (totalPoints === 1) {
			return true;
		}

		const diffBetweenEdgePoints = Math.floor(((range[1] - range[0])));
		const numberOfWindows = diffBetweenEdgePoints / (window * 1000);
		return totalPoints <= MINIMUM_POINTS_ON_GRAPH && numberOfWindows <= totalPoints;
	};

	// Mark the minimum y value for each section so we
	// know the minimum value for which the value is too high
	createDashedLines = (data, startPoint, endPoint) => {
		let dashedLineData = [];
		data.forEach(section => {
			if (section.level === attackLevels.NONE) {
				return;
			}

			let pointsArray = [];
			const smallestYvalue = [...section.points].sort((a, b) => a.y - b.y)[0].y;// Smallest value from the array
			const sPoint = { x: startPoint, y: smallestYvalue };
			const ePoint = { x: endPoint, y: smallestYvalue };
			pointsArray = pointsArray.concat([sPoint, ePoint]);
			dashedLineData = dashedLineData.concat([pointsArray]);
		});
		return dashedLineData;
	};

	// This is the highest value from the arrays
	getYEndpoint = (data, yAxis) => {
		let tempData = [];
		data.forEach(section => {
			tempData = tempData.concat(section.points);
		});
		let highestYValue = tempData.sort((a, b) => a.y - b.y)[tempData.length - 1].y;
		if (yAxis) {
			highestYValue = Math.max(highestYValue, ...yAxis);
		}

		return highestYValue;
	};

	// Here we are taking the smallest values from each array
	getSmallPointTicks = data => {
		let smallestPoints = [];
		data.forEach(section => {
			if (section.level === attackLevels.NONE) {
				return;
			}

			const smallestBar = [...section.points].sort((a, b) => a.y - b.y)[0];// Smallest bar from the array
			smallestPoints = smallestBar ? smallestPoints.concat(smallestBar.y) : smallestPoints;
		});
		return smallestPoints;
	};

	formatYValue = v => d3.format('.2s')(v);

	normalizeYValue = v => v > 10000000000 ? Math.floor(v / 1000000000)
		: v > 10000000 ? Math.floor(v / 1000000)
			: v > 10000 ? Math.floor(v / 1000) : Math.floor(v);

	// Checks if the point is from the smallest values
	isSmallPoint = (v, smallPointTicks) => smallPointTicks.some(spt => spt === v);
	generateXTickValues = (data, xDomain) => {
		const { width: screenWidth } = this.props;
		const numberOfRecords = numberOfRecordsByScreenSize[screenWidth];
		// The timeInterval and xGridsValued depend on the number of records for the graph to be responsive
		const timeInterval = (Math.floor(((xDomain[1] - xDomain[0]) / (Math.ceil(numberOfRecords / 2) - 1))));
		const xGridsValues = [...Array(Math.floor(numberOfRecords / 2)).keys()].map(val => xDomain[0] + val * timeInterval);
		return xGridsValues;
	};

	generateXGridValues = (xDomain, timeUnits) => {
		const timeMili = timeUnits * 1000;
		const numOfXGrids = Math.min(64, Math.floor(((xDomain[1] - xDomain[0]) / timeMili) / 2));
		const timeInterval = Math.floor(((xDomain[1] - xDomain[0]) / (numOfXGrids)));
		const xGridsValues = [...Array(numOfXGrids).keys()].map(val => xDomain[0] + (0.25 * timeInterval) + val * timeInterval);
		return xGridsValues;
	};

	generateYTickValues = (data, yMin, yMax, smallPointTicks, yAxis) => {
		const numOfTicks = 6;
		const specialTicks = (yAxis ? smallPointTicks.concat(yAxis) : smallPointTicks)
			.reduce((res, item) => {
				if (!res.includes(item)) {
					res.push(item);
				}

				return res;
			}, []);
		const numOfstandardTicks = 6 - specialTicks.length;
		const standardTicks = [];
		const interval = Math.floor((yMax - 0) / numOfTicks);
		const minDisBetweenPoints = interval / 3;
		if (interval === 0) {
			standardTicks.push(yMin);
		} else {
			for (let i = yMin; i <= yMax; i += interval) {
				if (!specialTicks.find(t => Math.abs(t - i) < minDisBetweenPoints)) {
					standardTicks.push(i);
				}
			}
		}

		const _tickValues = specialTicks.concat(standardTicks).sort();
		return _tickValues.filter((v, i, arr) => this.normalizeYValue(arr[i - 1]) !== this.normalizeYValue(arr[i]));
	};

	onClickHandlePopOverGraph = (value, event) => {
		this.setState({ hintValue: value, hintExpanded: false });
		const { clientX } = event;
		const { clientY } = event;
		const graphTitle = this.props.yTitle;

		const isGraphTitleFound = _.find(dataTypeOptions, e => {
			if (_.isEqual(e.label, graphTitle)) {
				return e;
			}
		});

		if (_.isEmpty(isGraphTitleFound)) {
			value.graphTitle = graphTitle;
		} else {
			value.graphTitle = isGraphTitleFound.shorter;
		}

		this.props.selectPointOnGraph(value, { top: clientY, left: clientX });
	};

	// Relates to y axis
	getMinMaxYValues = barData => {
		const yMax = Math.max(...barData.map(dp => dp.y), 100);
		const yMin = Math.min(...barData.map(dp => dp.y), 0);

		return [yMax, yMin];
	};

	render() {
		// NOTE: xDomain values are timestamps, timeUnits is in seconds
		const { data, label, yAxis, yTitle, xDomain, timeUnits, spreadToBuckets = false } = this.props;
		const { hintValue, hintExpanded, selectedBar } = this.state;
		const xDomainWithExtendedRange = Object.assign([], xDomain);
		let showRangeChangedDisclaimer = false;

		/// first and last nested x values from the server data
		if (!data) {
			return null;
		}

		const filteredData = this.filterDataByDomain(data, xDomain);

		if (this.isRangeExtendNeeded(xDomain, timeUnits, filteredData)) {
			// Extend timeframe in 10 minutes
			xDomainWithExtendedRange[1] += timeUnits * 10000;
			xDomainWithExtendedRange[0] -= timeUnits * 10000;
			showRangeChangedDisclaimer = true;
		}

		const barData = this.createBarData(filteredData, xDomainWithExtendedRange, spreadToBuckets);
		const [yMax, yMin] = this.getMinMaxYValues(barData);
		const yDomain = [0, 1.1 * yMax]; // Currently hardcoded

		const smallPointTicks = this.getSmallPointTicks(filteredData);
		const yTickValues = this.generateYTickValues(filteredData, yMin, yMax, smallPointTicks, yAxis);
		const xTickValues = this.generateXTickValues(data, xDomainWithExtendedRange, barData.length);
		const xGridValues = this.generateXGridValues(xDomainWithExtendedRange, timeUnits);
		return (
			<div className="graph">
				{label
                    && <div>
                    	<div className="graph-title">{label}</div>
                    	{/* showRangeChangedDisclaimer && <div className="graph-title-disclaimer">
                        We've expanded the graph's time frame in order to display a more informative view
                    </div> */}
                    </div>}

				<div className="graph-container">
					<AutoSizer className="autosize">

						{({ width, height }) => {
							/*
                            This is related to the zoom
                            while updating this, you must relate to zoom.scss file
                            */
							width *= 1;
							height *= 1;

							return (
								<XYPlot
									colorType="category"
									colorDomain={[0, 1, 2]}
									colorRange={['url(#blueGradient)', 'url(#yellowGradient)', 'url(#redGradient)']}
									style={{ stroke: 'black', strokeWidth: 0.6 }}
									xDomain={xDomainWithExtendedRange}
									yDomain={yDomain}
									margin={{ left: 75, right: 50 }} // To fix axis labels getting truncated
									width={width}
									height={height}
									onMouseLeave={() => {
										this.setState({ selectedBar: null, hintValue: null });
									}}
								>
									{blueGradient}
									{yellowGradient}
									{redGradient}
									<XAxis
										tickValues={xTickValues}
										tickFormat={v => `${Moment(v).format(timeFormaterGraphPopOver)}`}
										style={axisStyle}
									/>
									<YAxis
										tickValues={yTickValues}
										tickFormat={
											v => this.isSmallPoint(v, smallPointTicks)
												? (<tspan style={{
													opacity: 0.5,
												}}>{this.formatYValue(v)}</tspan>)
												: <tspan>{this.formatYValue(v)}</tspan>
										}
										style={axisStyle}
									/>
									<HorizontalGridLines
										tickValues={yTickValues}
										style={horizontalGridLinesStyle}
									/>
									<VerticalGridLines
										tickValues={xGridValues}
										style={verticalGridLinesStyle}
									/>
									{barData
                                        && <VerticalBarSeries
                                        	data={barData}
                                        	onNearestX={(value, event) => {
                                        		this.setState({
                                        			hintValue: value,
                                        			selectedBar: { ...value, level: value.color },
                                        		});
                                        	}}
                                        	onValueClick={(value, event) => {
                                        		this.onClickHandlePopOverGraph(value, event.event);
                                        	}}
                                        	onSeriesMouseOut={(value, event) => {
                                        		this.setState({ selectedBar: null, hintValue: null });
                                        	}}
                                        	barWidth={1}
                                        	style={{ cursor: 'pointer' }}
                                        />
									}
									{selectedBar

                                        && <CustomSVGSeries
                                        	className="mark-series"
                                        	data={[Object.assign(selectedBar, {
                                        		customComponent: (row, positionInPixels, globalStyle) => (
                                        			<g onClick={e => {
                                        				this.onClickHandlePopOverGraph(row, e);
                                        			}}>
                                        				<image
                                        					href={`./expand-new-${row.level == 0 || _.isUndefined(row.level) || row.y == 0 ? 'blue' : row.level == 1 ? 'yellow' : 'red'}.svg`}
                                        					fill={'#fd7e14'}
                                        					height="20" width="20"
                                        					transform="translate(-10 -10)"/>
                                        			</g>
                                        		),
                                        	})]
                                        	}
                                        	color={levelMarkColors[selectedBar.level]}
                                        	size="8"
                                        	onValueMouseOver={(value, event) => {
                                        		this.setState({ hintValue: value });
                                        	}}
                                        	onValueClick={(value, event) => {
                                        		this.onClickHandlePopOverGraph(value, event.event);
                                        	}}
                                        	onSeriesMouseOut={(value, event) => {
                                        		this.setState({ hintValue: null, hintExpanded: false });
                                        	}}
                                        />
									}
									{selectedBar
                                        && <Hint value={selectedBar} align={{ horizontal: 'left', vertical: 'top' }}>
                                        	<GraphHint info={selectedBar}
                                        		expanded={hintExpanded}
                                        		xTitle="Time"
                                        		yTitle={yTitle}/>
                                        </Hint>}
								</XYPlot>
							);
						}}
					</AutoSizer>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	newPoint: state.graph.newPoint,
});

const mapDispatchToProps = {
	selectPointOnGraph,
};

GraphTime = connect(mapStateToProps, mapDispatchToProps)(GraphTime);

export default withWidth()(GraphTime);
