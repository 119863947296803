import { arrangeLiteDevices, arrangeLitePublicCloudDevice } from '../controller/businessLogic/mappers';
import { collectorTypes, deviceTypes } from './devicesManagementConfig';

const parseDeviceByType = {
	[deviceTypes.PUBLIC_DEVICE](device) {
		return device;
	},
	[deviceTypes.CORE_DEVICE](device, collectors, samplers) {
		if (collectors) {
			device.collector = collectors.find(collector => collector._id === device.collectorId).name;
		}

		if (samplers) {
			device.sampler = samplers.find(sampler => sampler._id === device.samplerId).name;
		}

		return device;
	},
	[deviceTypes.CLOUD_DEVICE](device) {
		device.cloudProvider = device.cloudProvider.toUpperCase();
		device.brandName = device.cloudProvider;
		if (device.aws) {
			device.awsRegion = device.aws.region;
			device.awsAccountId = device.aws.accountId;
		}

		if (device.gcp) {
			device.gcpProjectId = device.gcp.projectId;
			device.gcpVpcName = device.gcp.vpcName;
		}

		return device;
	},
};

const arrangeLiteDevicesByType = {
	[deviceTypes.PUBLIC_DEVICE](devices) {
		return arrangeLiteDevices(devices.filter(device => device.deviceType === deviceTypes.PUBLIC_DEVICE));
	},
	[deviceTypes.CORE_DEVICE](devices) {
		return arrangeLiteDevices(devices.filter(device => device.deviceType === deviceTypes.CORE_DEVICE));
	},
	[deviceTypes.CLOUD_DEVICE](devices) {
		return arrangeLitePublicCloudDevice(devices.filter(device => device.deviceType === deviceTypes.CLOUD_DEVICE));
	},
};

const LEGACY_COLLECTOR_FIELD_UPDATE = 'collectorId';
const SAMPLER_FIELD_UPDATE = 'samplerId';

const fieldToUpdateByCollectorType = {
	[collectorTypes.LEGACY_COLLECTOR_TYPE](collector) {
		return { field: LEGACY_COLLECTOR_FIELD_UPDATE, newValue: collector.id };
	},
	[collectorTypes.SAMPLER_TYPE](sampler) {
		return { field: SAMPLER_FIELD_UPDATE, newValue: sampler._id };
	},
};

export {
	parseDeviceByType,
	arrangeLiteDevicesByType,
	fieldToUpdateByCollectorType,
};
