import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
	sidebarReducer,
	appReducer,
	authReducer,
	messageReducer,
	analyzeReducer,
	userReducer,
	adminReducer,
	accountReducer,
	backgroundProcessReducer,
	paymentReducer,
	registerReducer,
	alertReducer,
	graphReducer,
	subscriptionReducer,
	threatsReducer,
	lightweightDevicesReducer,
	insightsReducer,
	attackStatusReducer,
	snackbarAlertReducer
	, interactionsReducer,
	bannerReducer, fullScreenReducer,
} from '../index';

import newGroupReducer from '../../../../groupManagement/controller/redux/reducer';
import userSettingsReducer from '../../../../userSettings/controller/redux/userSettingsReducer';
import plansReducer from '../../../../plans/redux/plansReducer';
import referAFriendReducer from '../../../../referAFriend/redux/referAFriendReducer';
import visibleDevicesCountReducer from '../../../../deviceManagement/controller/visibleDeviceCount/reducer';

const reducer = combineReducers({
	form: reduxFormReducer, // Mounted under "form",
	app: appReducer,
	sidebar: sidebarReducer,
	auth: authReducer,
	interactions: interactionsReducer,
	message: messageReducer,
	analyze: analyzeReducer,
	userReducer,
	admin: adminReducer,
	account: accountReducer,
	backgroundProcesses: backgroundProcessReducer,
	payment: paymentReducer,
	register: registerReducer,
	alertes: alertReducer,
	graph: graphReducer,
	subscription: subscriptionReducer,
	threats: threatsReducer,
	insights: insightsReducer,
	// NEW REDUCER
	newGroups: newGroupReducer,
	liteDevices: lightweightDevicesReducer,
	attackStatuses: attackStatusReducer,
	snackbarAlert: snackbarAlertReducer,
	userSettings: userSettingsReducer,
	plans: plansReducer,
	visibleDevicesCount: visibleDevicesCountReducer,
	referAFriend: referAFriendReducer,
	banners: bannerReducer,
	fullScreen: fullScreenReducer,
});

const store = createStore(reducer,
	composeWithDevTools(applyMiddleware(thunk)));
export default store;
