import React from 'react';
import LayoutForm from "../../../shared/components/form/LayoutForm";
import {Typography} from "@material-ui/core";
import SubmitButton from "../../../shared/components/form/Buttons/SubmitButton";
import {useStyles} from "./screens.css";


function PasswordChanged({history}){
    const classes = useStyles();

    const goToLogin = () => {
        history.push("/login");
    }
    return(
        <LayoutForm customMessage={" "}>
            <div className={classes.screenTitle} >
                Congrats!
            </div>
            <Typography variant={"body1"} paragraph>
                Your password has been changed successfully.
            </Typography>

            <SubmitButton label={"Login"} onClick={goToLogin} />

        </LayoutForm>
    )
}

export default (PasswordChanged) ;