import _ from 'lodash';
import VIcon from '../../../shared/components/YesNoIcons/VIcon';
import { Typography } from '@material-ui/core';
import XIcon from '../../../shared/components/YesNoIcons/XIcon';
import EnrichmentPopoverWrapper from '../../../shared/components/enrichmentPopoverWrapper/enrichmentPopoverWrapper';
import vcaLogicConfig from './vcaLogicConfig.json';
import { LOGIC_FORMAT_VALUE_MAP } from './logicsFormat';
import InnerTable from '../rawdata/InnerTable/InnerTable';
import AcceptlistButton from '../acceptlistButton/AcceptlistButton';

const filterUnknownLogics = data => data.filter(
	record => record.additionalInfo && _.has(vcaLogicConfig.detectionLogicsDict, record.additionalInfo.logicKey)
		&& _.has(vcaLogicConfig.detectionLogicsDict[record.additionalInfo.logicKey], record.additionalInfo.logicVersion))
	.map(record => ({ ...record, logicName: vcaLogicConfig.detectionLogicsDict[record.additionalInfo.logicKey][record.additionalInfo.logicVersion].title }));

/* ------------------------------------------ private -----------------------------------*/

// Calculate each cell value and component by the column config of the additional data table
export const dataPrep = (additionalData, currentLogic, endpoints) => additionalData.map(
	logicRow => currentLogic.findingsTable.columns.map(
		column => ({
			Component: LOGIC_COMPONENT_MAP[column.dashboardComponent](logicRow[column.key]),
			align: column.dashboardAlign,
			value: LOGIC_FORMAT_VALUE_MAP[column.formatValue]
				? LOGIC_FORMAT_VALUE_MAP[column.formatValue](logicRow[column.key], endpoints)
				: logicRow[column.key],
		})));

const decideCheckOrUncheck = rowValue => rowValue ? VIcon : XIcon;

export const LOGIC_COMPONENT_MAP = {
	TEXT: () => Typography,
	CHECKBOX: rowValue => decideCheckOrUncheck(rowValue),
	ENRICHMENT_POPOVER: () => EnrichmentPopoverWrapper,
	TABLE: InnerTable,
	ACTION_BUTTON: () => AcceptlistButton,
};

export {
	filterUnknownLogics,
};
