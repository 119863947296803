import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import useStyles from './tabManager.css';
import PropTypes from 'prop-types';
import { TABS, TABS_CONFIGURATION } from './tabsConfiguration';
import TabColumns from '../tabColumns/tabColumns';

const TabPanelPropTypes = {
	children: PropTypes.element,
	value: PropTypes.number,
	index: PropTypes.number,
};

const TabPanel = ({ children, value, index, ...other }) => (
	<div
		role="tabpanel"
		hidden={value !== index}
		id={`top-talkers-tabpanel-${index}`}
		aria-labelledby={`top-talkers-tab-${index}`}
		{...other}
	>
		{value === index && (
			<Box>
				{children}
			</Box>
		)}
	</div>
);

TabPanel.propTypes = TabPanelPropTypes;

const tabManagerPropTypes = {
	data: PropTypes.object,
	setCopyAll: PropTypes.func,
};
const TabManager = ({ data, setCopyAll }) => {
	const classes = useStyles();
	const [activeTabIndex, setActiveTabIndex] = useState(0);

	useEffect(() => {
		setCurrentCopyAllValue(activeTabIndex);
	}, [activeTabIndex]);

	const setCurrentCopyAllValue = tabIndex => {
		const currentTabData = data[TABS_CONFIGURATION[tabIndex].dataKey];
		const copyKey = TABS_CONFIGURATION[tabIndex].recordCopyKey;
		if (currentTabData) {
			setCopyAll(currentTabData.map(record => record[copyKey]).join(', '));
		}
	};

	const handleChange = (event, newTabIndex) => {
		setActiveTabIndex(newTabIndex);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs textColor={'primary'} indicatorColor={'primary'} value={activeTabIndex} onChange={handleChange} classes={{ flexContainer: classes.tabContainer }}>
					{TABS.map((tabName, index) => <Tab key={`${tabName}-${index}`} classes={{ root: classes.tab }} label={tabName}/>)}
				</Tabs>
			</Box>
			{TABS_CONFIGURATION.map(({ tabName, Component, dataKey, columns, componentProps }, index) =>
				(<TabPanel key={`tabPanel-${tabName}`} value={activeTabIndex} index={index}>
					<div className={classes.columns}>
						<TabColumns columns={columns}/>
					</div>
					<Component
						data={data && data[dataKey] ? data[dataKey] : []}
						{...componentProps} />
				</TabPanel>),
			)}
		</Box>
	);
};

TabManager.propTypes = tabManagerPropTypes;
export default TabManager;
