import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        height: "100%",
        width: "100%",
        padding: "40px 225px",
        display: "flex",
        justifyContent: "center",
    },
}));

export default useStyles;