import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	translationEnabled: {
		'&:hover': {
			color: props => props.translation ? theme.palette.primary.main : 'unset',
		},
	},
}));

export default useStyles;
