import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel } from '@material-ui/core';

import { ReactComponent as CheckIcon } from '../../../../assets/plans/check.svg';

import useStyles from './StepsIndicator.css';

const renderStep = (title, index, completed) => {
	const classes = useStyles();
	let stepTitle = title;
	const titleProps = {
		classes: {
			label: classes.stepperLabel,
			active: classes.stepperActiveLabel,
			completed: classes.stepperActiveLabel,
		},
	};

	if (completed) {
		titleProps.icon = <CheckIcon />;
	} else {
		titleProps.icon = <></>;
		stepTitle = `${index + 1}. ${title}`;
	}

	return (
		<Step key={title}>
			<StepLabel {...titleProps}>
				{stepTitle}
			</StepLabel>
		</Step>
	);
};

const StepsIndicator = ({ activeStep, steps, completedSteps }) => {
	const classes = useStyles();

	return (
		<Stepper activeStep={activeStep} className={classes.stepper}>
			{steps.map(({ title }, index) => renderStep(title, index, completedSteps[index]))}
		</Stepper>
	);
};

StepsIndicator.propTypes = {
	activeStep: PropTypes.number.isRequired,
	steps: PropTypes.array.isRequired,
	completedSteps: PropTypes.object.isRequired,
};

export default StepsIndicator;
