import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";
import BoundedFormWithTitles from "../../../../contactSales/boundedFormWithTitles";
import {sendRequestToSales} from "../../../../services/planRequests";
import {
    CONTACT_FORM_SUBTITLE,
    CONTACT_FORM_TITLE,
    attachCallbackToSuccessComponent,
    attachCallbackToErrorComponent,
} from "./upgradeToCustomConfiguration";
import ContactSalesForm from "../../../../contactSales/forms/contactSalesFrom";

const UpgradeToCustomPlan = ({onError, onSuccess, onCloseDialog, flowType}) => {
    const onSubmit = async (data) => {
        try {
            const {numberOfRequiredDevices, numberOfRequiredUsers} = data;
            const salesData = {
                data:{
                    ...data,
                    numberOfRequiredDevices: Number(numberOfRequiredDevices),
                    numberOfRequiredUsers: Number(numberOfRequiredUsers),
                },
                flow: flowType
            }
            await sendRequestToSales(salesData);
            const SuccessComponent = attachCallbackToSuccessComponent(onCloseDialog);
            onSuccess(SuccessComponent);
        } catch (e) {
            const ErrorComponent = attachCallbackToErrorComponent(onCloseDialog);
            onError(ErrorComponent);
        }
    }

    return (
        <Grid container justify={"space-evenly"} alignContent={"center"} direction={"column"} style={{height: '90%'}}>
            <BoundedFormWithTitles title={CONTACT_FORM_TITLE} subtitle={CONTACT_FORM_SUBTITLE}>
                <ContactSalesForm displayAdditionalQuestions onSubmit={onSubmit}/>
            </BoundedFormWithTitles>
        </Grid>
    )
}

export default UpgradeToCustomPlan;