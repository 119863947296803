import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import { Delete, Edit } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';
import CustomTooltip from '../../../../shared/components/Tooltip/CustomTooltip';
import IpCard from '../ipCard/IpCard';
import { groupTypes } from '../ipgroupModal/groupTypes';
import './card.scss';

const MAX_CHIPS_TO_DISPLAY = 5;
const EDIT_TOOLTIP = 'Edit';
const EDIT_DISABLED_TOOLTIP = 'This group cannot be edited';
const DELETE_TOOLTIP = 'Delete';
const DELETE_DISABLED_TOOLTIP = 'This group cannot be deleted';

const Card = ({
	groupTitle,
	groupDescription,
	groupTags,
	cidrs,
	macs,
	organizations,
	awsInstanceIds,
	awsSubnetIds,
	onDeleteGroup,
	onEditGroup,
	isEditable = true,
	isDeletable = true,
}) => {
	const itemsToShow = [
		...cidrs.map(cidr => ({ value: cidr, type: groupTypes.CIDR })),
		...macs.map(mac => ({ value: mac, type: groupTypes.MAC })),
		...organizations.map(organization => ({
			value: organization,
			type: groupTypes.ORGANIZATION,
		})),
		...awsInstanceIds.map(instanceId => ({
			value: instanceId,
			type: groupTypes.AWS_INSTANCE_ID,
		})),
		...awsSubnetIds.map(subnetId => ({
			value: subnetId,
			type: groupTypes.AWS_SUBNET_ID,
		})),
	];

	return (
		<div className="cart-section-wrapper">
			<div className="custom-card">
				<div className="card-tags-section">
					{groupTags.map((tag, index) => <Chip className="card-tag" size="small" label={tag} key={index} />)}
				</div>
				<div className="card-top-section">
					<div className="card-top-section-title">{groupTitle}</div>
					<div className="card-top-section-edit">
						<Grid container>
							<Grid xs={6}></Grid>
							<Grid xs={2}>
								<CustomTooltip title={isEditable ? EDIT_TOOLTIP : EDIT_DISABLED_TOOLTIP}>
									<span>
										<IconButton onClick={onEditGroup} disabled={!isEditable}>
											<Edit style={{
												color: isEditable ? '#48648b' : '#8C8C8C',
												fontSize: '18px',
											}}/>
										</IconButton>
									</span>
								</CustomTooltip>
							</Grid>
							<Grid xs={2}>
								<Divider
									orientation={'vertical'}
									variant={'middle'}
									style={{
										marginLeft: '100%',
										display: 'inline-block',
										width: '2px',
									}}
								/>
							</Grid>
							<Grid xs={2}>
								<CustomTooltip title={isDeletable ? DELETE_TOOLTIP : DELETE_DISABLED_TOOLTIP}>
									<span> {/* span is used to show tooltip even when button is disabled */ }
										<IconButton onClick={onDeleteGroup} disabled={!isDeletable}>
											<Delete style={{
												color: isDeletable ? '#48648b' : '#8C8C8C',
												fontSize: '18px',
											}}/>
										</IconButton>
									</span>
								</CustomTooltip>
							</Grid>
						</Grid>
					</div>
				</div>
				<div className="card-center-section">
					<p>{groupDescription}</p>
				</div>
				<div className="card-botton-section">
					{itemsToShow.map((i, index) =>
						index < MAX_CHIPS_TO_DISPLAY ? (
							<IpCard key={index} value={i.value} type={i.type} />
						) : null,
					)}
					{itemsToShow.length > MAX_CHIPS_TO_DISPLAY && (
						<div className="others-ip box-ip-core">
							<p>+ {itemsToShow.length - MAX_CHIPS_TO_DISPLAY} others</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Card;

Card.propTypes = {
	awsInstanceIds: PropTypes.string,
	awsSubnetIds: PropTypes.string,
	cidrs: PropTypes.array,
	groupDescription: PropTypes.string,
	groupTitle: PropTypes.string,
	macs: PropTypes.array,
	onDeleteGroup: PropTypes.func,
	onEditGroup: PropTypes.func,
	organizations: PropTypes.array,
	groupTags: PropTypes.array,
	isEditable: PropTypes.bool,
	isDeletable: PropTypes.bool,
};
