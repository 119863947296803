import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './remediationPopover.css';
import remediationIcon from '../../../assets/remediation/remediationIcon_white.svg';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { Button } from '@material-ui/core';

function RemediationPopover({ header, data, onDismiss, onAutoRemediateClick, enableAutoRemediate }) {
	const classes = useStyles();

	const close = () => onDismiss();

	return (
		(<div className={classes.main}>
			<div className={classes.header}>
				<div className={classes.headerContent}>
					<div className={classes.headerIcon}>
						<img src={remediationIcon} alt={'remediation'} className={classes.remediationIcon} />
					</div>
					<span className={classes.headerText}>
						{header}
					</span>
					<IconButton aria-label="close" size="small" className={classes.headerCloseIcon} onClick={close}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</div>
			</div>
			<div className={classes.bodyContent}>
				<div className={classes.body}>
					<ol>
						{data.map((item, index) => <li key={`${item}-${index}`}>{item}</li>)}
					</ol>
				</div>
				{
					enableAutoRemediate
					&& <div>
						<div className={classes.autoRemediateText}>Click the button below to mitigate these threats.</div>
						<div className={classes.autoRemediateButtonContainer}>
							<Button
								className={classes.autoRemediateButton}
								onClick={onAutoRemediateClick}
								color="primary"
								variant="contained">Auto-remediate
							</Button>
						</div>
					</div>
				}
			</div>
		</div>
		)
	);
}

RemediationPopover.defaultProps = {
	header: 'Next step',
	data: [],
	onDismiss() {},
	onAutoRemediateClick() {},
	enableAutoRemediate: false,
};

RemediationPopover.propTypes = {
	header: PropTypes.string,
	data: PropTypes.arrayOf(PropTypes.string),
	onDismiss: PropTypes.func,
	onAutoRemediateClick: PropTypes.func,
	enableAutoRemediate: PropTypes.boolean,
};

export default RemediationPopover;
