import {
    GETCOMMENT,
    GETCOUNTRY, GETDEFAULTPLAN,
    CURRENTPLAN,
    SELECTEDPLAN
} from "./subscriptionAction";


const initialState = {
    plans: [],
    countries: [],
    comments: [],
    selectedPlan: null, 
    currentPlan : null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GETDEFAULTPLAN:

            return {
                ...state,
                plans: payload
            };


        case GETCOUNTRY:
            return {
                ...state,
                countries: payload

            };

        case GETCOMMENT:
            return {
                ...state,
                comments: payload

            };
        case SELECTEDPLAN:
            
            return {
                ...state,
                selectedPlan: payload
            }
        case CURRENTPLAN : 
            return { 
                ... state, 
                currentPlan : payload
            }
        default:
            return {
                ...state
            }
    }

}