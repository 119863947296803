import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

import CopyLink from '../../copyLink/copyLink';
import RawdataExport from '../../rawdataExport/rawdataExport';
import SubmitButton from '../../../../../../shared/components/form/Buttons/SubmitButton';
import CheckboxFilters from '../../../../../../shared/components/checkboxFilters/checkboxFilters';
import './advanceLine2.scss';
import BadgeButton from '../../../../../../shared/components/ButtonWithBadge/BadgeButton';
import { CLOUD_LABEL, DST_GEO_ORG_LABEL, MORE_OPTIONS_LABEL, SRC_GEO_ORG_LABEL } from '../../consts';

const AdvanceLine2 = ({ openCloudFilterDialog, openSrcIpEnrichment, openDestIpEnrichment, openMoreOptions, onCopyLinkClick, currentProcessLinkId, isSubmitting, activeFilters }) => {
	const features = useSelector(state => state.account.features);
	const isIpEnrichmentEnabled = features.includes('ip_resolution_enrichment');
	const formName = 'create-backgroung-process';

	return (

		<div className="line3">
			<div className="top-container">
				<div className="checkboxes">
					<CheckboxFilters formName={formName}/>
				</div>
				<div className="more-options-section">
					<div className="src-enrichment">
						<BadgeButton color={'white'}
							font={'small'}
							onClick={openCloudFilterDialog}
							label={CLOUD_LABEL}
							badgeContent={activeFilters.cloud} />
					</div>

					<div className="src-enrichment">
						<BadgeButton color={'white'}
							font={'small'}
							disabled={!isIpEnrichmentEnabled}
							onClick={openSrcIpEnrichment}
							label={SRC_GEO_ORG_LABEL}
							badgeContent={activeFilters.srcGeo} />
					</div>
					<div className="dst-enrichment">
						<BadgeButton color={'white'}
							font={'small'}
							disabled={!isIpEnrichmentEnabled}
							onClick={openDestIpEnrichment}
							badgeContent={activeFilters.dstGeo}
							label={DST_GEO_ORG_LABEL} />

					</div>
					<div className="more-options">
						<BadgeButton color={'white'}
							font={'small'}
							onClick={openMoreOptions}
							label={MORE_OPTIONS_LABEL}
							badgeContent={activeFilters.moreOptions}/>
					</div>
				</div>
			</div>
			<div className="bottom-container">
				<div className="copy-and-export">
					<CopyLink className="link" onCopyLinkClick={onCopyLinkClick} currentProcessLinkId={currentProcessLinkId}/>
					<RawdataExport className="export"/>
				</div>
				<div className="analyze">
					{ isSubmitting ? <CircularProgress color={'primary'} className="analyze-loader" /> : <SubmitButton className="analyze-button" label={ 'Analyze' } /> }
				</div>
			</div>
		</div>
	);
};

AdvanceLine2.propTypes = {
	currentProcessLinkId: PropTypes.string,
	onCopyLinkClick: PropTypes.func,
	openCloudFilterDialog: PropTypes.func,
	openDestIpEnrichment: PropTypes.func,
	openMoreOptions: PropTypes.func,
	openSrcIpEnrichment: PropTypes.func,
	isSubmitting: PropTypes.bool,
	activeFilters: PropTypes.shape({
		srcGeo: PropTypes.string,
		dstGeo: PropTypes.string,
		cloud: PropTypes.string,
		moreOptions: PropTypes.string,
	}),
};

export default AdvanceLine2;
