import http from '../../../services/httpService/http';

const subscriptions_URL = '/subscriptions/v2'
const contactSales_URL = '/contact-sales/v1'

const getDefaultPlans = async () => {
    return await http.get(`${subscriptions_URL}/plans`);
}

const geCurrentPlanDetails = async () => {
   return await http.get(`${subscriptions_URL}/current`);
}

const getFlowType = async (targetBaseProductId) => {
    return await http.get(`${subscriptions_URL}/flows?targetBaseProductId=${targetBaseProductId}`);
}
const sendRequestToSales = async (data) => {
    return await http.post(`${contactSales_URL}/custom-plan-request`, data);
}
const sendFeedbackToSales = async (request) => {
    return await http.post(`${contactSales_URL}/feedback`, request);
}
export {getDefaultPlans, geCurrentPlanDetails, sendRequestToSales, getFlowType, sendFeedbackToSales}