import Grid from "@material-ui/core/Grid";
import React ,{useState} from "react";
import SimpleModal from "../../../shared/components/simpleTable/simpleModal";
import SubmitButton from "../../../shared/components/form/Buttons/SubmitButton";
import {ReactComponent as Lock} from "../../../assets/payment/lock-new.svg";
import StyleButton from "../../../shared/components/form/Buttons/StyleButton";
import {useStyles} from "./changePaymentMethod.css";
import {Typography} from "@material-ui/core";
import {handleChangePaymentDetails} from "../../plans/plansStepperWrapper/completePurchaseWrapper/flowsComponents/upgradeToPremiumPlan/submitFormsFunctions";
import {useDispatch} from "react-redux";
import {openSnackbar} from "../../snackbarAlert/controller/redux/actions";
import creditCardModalIcon from "../../../assets/modal/creditCardModalIcon.svg";
import PaymentMethod from "../creditCard/paymentMethod";

const CHANGE_PM_SUCCESS_MESSAGE = "Payment method successfully updated";
const CHANGE_PM_ERROR_MESSAGE = "An error occurred, please try again later";

const modalIconStyle = {
    marginBottom:"2%",
    marginRight:'4%',
    width:131,
    height:130
}

const ChangePaymentMethod = ({ isOpen, closeModal, selectedBaseProduct, paymentMethodType, fetchPaymentInfo }) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmitValid, setIsSubmitValid] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();

    const handleSubmitForm = async (formData) => {
        try {
            await handleChangePaymentDetails(formData);
            dispatch(openSnackbar("success", CHANGE_PM_SUCCESS_MESSAGE , 4000))
            fetchPaymentInfo();
        } catch (e) {
            dispatch(openSnackbar("error", CHANGE_PM_ERROR_MESSAGE, 4000));
        }
        closeModal();
    }

    return (
        <>
            <SimpleModal header={ "Update payment details" }
                         isOpen={ isOpen } toggle={closeModal} icon={creditCardModalIcon} iconStyle={modalIconStyle}>
                <div className={classes.form}>
                    <Typography className={classes.paymentTitle} variant={"body1"}
                                color={"textSecondary"}>Payment</Typography>
                    <PaymentMethod paymentType={paymentMethodType}
                                   handleSubmitForm={handleSubmitForm}
                                   selectedBaseProduct={selectedBaseProduct}
                                   isSubmitted={isSubmitted}
                                   setIsSubmitted={setIsSubmitted}
                                   setIsSubmitValid={setIsSubmitValid}/>
                    <div className={classes.actionButtons}>
                        <StyleButton onClick={closeModal} className={classes.button} variant={"outlined"} label={"Cancel"} isDisabled={isSubmitValid} />
                        <StyleButton onClick={() => setIsSubmitted(true)} className={classes.button} label={"Save"} isDisabled={isSubmitValid} />
                    </div>
                </div>
            </SimpleModal>
        </>
    )
}

export default ChangePaymentMethod;