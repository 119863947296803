import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'redux-form';

import InputWrapper from '../../../wrapper/inputWrapper';
import DirectionSelectCreateable
	from '../../../../../../shared/components/queryLine/selectors/directionSelectCreatable/directionSelectCreatable';
import {
	validateIpOrMac,
	validateMac,
	validatePort,
} from '../../../../../backgroundProcess/controller/validations/validation';
import { mapGroups } from '../../../../../backgroundProcess/controller/businessLogic/mappers';
import '../group2/inputGroup2.scss';
import { customSelectorStyles } from '../customSelectorStyles';

class inputGroup4 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	options = this.props.groups ? mapGroups(this.props.groups) : [];

	setValueType = value => value.type || (validateMac(value.value.toUpperCase()) ? 'mac' : 'ip');

	render() {
		return (
			<div className="alertes-inputs-line2">
				<div className="alert-ip">
					<InputWrapper
						name={'Destination IP or MAC'}
						additionalClasses={'react-select-redux-forms-extra'}
						input={
							<Field
								name="ipDestination"
								additionalClasses={'react-select-redux-forms-extra'}
								component={DirectionSelectCreateable}
								options={this.options}
								placeholder="Type an IP or MAC address / select one of your pre-defined groups"
								extraStyles={customSelectorStyles}
								validation={validateIpOrMac}
								addType={this.setValueType}
							/>
						}
					/>
				</div>
				<div className="alert-port">
					<InputWrapper
						name={'Destination port'}
						input={
							<Field
								name="portsDestination"
								extraStyles={customSelectorStyles}
								additionalClasses={'react-select-redux-forms-extra'}
								component={DirectionSelectCreateable}
								placeholder="Type"
								options={[]}
								validation={validatePort}
							/>
						}
					/>
				</div>
			</div>
		);
	}
}

inputGroup4.propTypes = {
	getGroupsAsync: PropTypes.func,
	groups: PropTypes.array,
};

const mapStateToProps = state => ({
	groups: state.account.groups,
	features: state.account.features,
});

export default connect(mapStateToProps, null)(inputGroup4);
