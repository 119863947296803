import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    indicatePaper: {
        display: 'block',
        margin: 'auto',
        background: 'transparent'
    },

    loadingImage: {
        width: '200px',
        height: '96px',
        display: 'block',
        margin: 'auto'
    },
    loadingSubTitle: {
        marginTop: '10%',
        textAlign: 'center'
    }
}));

export {useStyles}