const { default: http } = require('../../../../../services/httpService/http');

const {
	GET_ANALYZE_TOTAL_DAILY_THREATS,
	GET_ANALYZE_TOTAL_DAILY_PACKETS,
	GET_ANALYZE_DATA_LATEST,
	QUERY_LINK_URL,
	RAWDATA_EXPORT_URL,
	IP_GROUP_ANOMALIES_PATH } = require('./urls');

const getIpGroupAnomalise = params => http.post(IP_GROUP_ANOMALIES_PATH, params);

const getTotalThreats = () => http.get(GET_ANALYZE_TOTAL_DAILY_THREATS);

const getTotalPackets = () => http.get(GET_ANALYZE_TOTAL_DAILY_PACKETS);

const getSummaryDatas = params => http.get(GET_ANALYZE_DATA_LATEST, params);

const getQueryById = id => http.get(QUERY_LINK_URL + id);

const createQueryLink = query => http.post(QUERY_LINK_URL, query);

const createRawdataExportRequest = (originalQuery, description) => http.post(RAWDATA_EXPORT_URL, { originalQuery, description });

const createRawdataExportRequestFromLink = (linkId, description) => http.post(`${RAWDATA_EXPORT_URL}${linkId}`, {description});

export {
	getSummaryDatas,
	getIpGroupAnomalise,
	getTotalPackets,
	getTotalThreats,
	getQueryById,
	createQueryLink,
	createRawdataExportRequest,
	createRawdataExportRequestFromLink,
};
