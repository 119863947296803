import {
    EXCLUDE_SIGN,
    GROUP_TYPE,
    INCLUDE_SIGN
} from "../../shared/components/queryLine/selectors/directionSelectCreatable/consts";
import {AWS_FILTER_VALUE, AZURE_FILTER_VALUE, GOOGLE_FILTER_VALUE} from "./cloudProvidersConfiguration";

const buildAWSOriginalQuery = (data) => {
    const {
        accountIds = [],
        interfacesIds = [],
        vpcIds = [],
        subnetIds = [],
        instanceIds = [],
        regions = [],
        azs = [],
        srcServices = [],
        destServices = []
    } = data;

    return {
        awsIncAccountIds: getStringArray(accountIds, INCLUDE_SIGN),
        awsExcAccountIds: getStringArray(accountIds, EXCLUDE_SIGN),
        awsIncInterfaceIds: getStringArray(interfacesIds, INCLUDE_SIGN),
        awsExcInterfaceIds: getStringArray(interfacesIds, EXCLUDE_SIGN),
        awsIncVpcIds: getStringArray(vpcIds, INCLUDE_SIGN),
        awsExcVpcIds: getStringArray(vpcIds, EXCLUDE_SIGN),
        awsIncSubnetIds: getStringArray(subnetIds, INCLUDE_SIGN),
        awsIncSubnetIdGroups: getStringArrayOfGroups(subnetIds, INCLUDE_SIGN),
        awsExcSubnetIds: getStringArray(subnetIds, EXCLUDE_SIGN),
        awsExcSubnetIdGroups: getStringArrayOfGroups(subnetIds, EXCLUDE_SIGN),
        awsIncInstanceIds: getStringArray(instanceIds, INCLUDE_SIGN),
        awsIncInstanceIdGroups: getStringArrayOfGroups(instanceIds, INCLUDE_SIGN),
        awsExcInstanceIds: getStringArray(instanceIds, EXCLUDE_SIGN),
        awsExcInstanceIdGroups: getStringArrayOfGroups(instanceIds, EXCLUDE_SIGN),
        awsIncAzNames: getStringArray(azs, INCLUDE_SIGN),
        awsExcAzNames: getStringArray(azs, EXCLUDE_SIGN),
        awsIncRegionNames: getStringArray(regions, INCLUDE_SIGN),
        awsExcRegionNames: getStringArray(regions, EXCLUDE_SIGN),
        awsIncServiceNamesSrc: getStringArray(srcServices, INCLUDE_SIGN),
        awsExcServiceNamesSrc: getStringArray(srcServices, EXCLUDE_SIGN),
        awsIncServiceNamesDest: getStringArray(destServices, INCLUDE_SIGN),
        awsExcServiceNamesDest: getStringArray(destServices, EXCLUDE_SIGN)
    }
}

const buildGoogleCloudOriginalQuery = (data) => {

}

const buildAzureOriginalQuery = (data) => {

}


const convertToPayload = (data, direction) => {
    return data.filter(entity => entity.direction === direction)
        .reduce((strArray, {value}) => {
            strArray.push(value);
            return strArray
        }, []);
}

const getStringArrayOfGroups = (data, direction) => {
    const allGroups =  data.filter(entity => entity.type === GROUP_TYPE);
    return convertToPayload(allGroups, direction);
}

const getStringArray = (data, direction) => {
    const filteredData = data.filter(entity => !entity.type);
    return convertToPayload(filteredData, direction);
}

export default {
    [AWS_FILTER_VALUE]: buildAWSOriginalQuery,
    [GOOGLE_FILTER_VALUE]: buildGoogleCloudOriginalQuery,
    [AZURE_FILTER_VALUE]: buildAzureOriginalQuery
}