export const formatBase2 = (num, decimals = 2) => {
	if (num === 0 || !num) {
		return '0';
	}

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(num) / Math.log(k));

	const normalizedNumber = parseFloat((num / (k ** i)).toFixed(dm));
	return `${normalizedNumber}${sizes[i]}`;
};

export const formatBase10 = (num, decimals = 2) => {
	if (num === 0 || _.isUndefined(num)) {
		return '0';
	}

	const k = 1000;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

	const i = Math.floor(Math.log(num) / Math.log(k));
	const normalizedNumber = parseFloat((num / (k ** i)).toFixed(dm));
	return `${normalizedNumber}${sizes[i]}`;
};