export const comments = [
    {
        "title": "Chief Technology Officer/Assistant City Manager",
        "name": "Brandon Branham",
        "comment": "I didn’t believe it when Cynamics told me how easy it was to onboard our network. I am a believer now, and have gained unparalleled visibility of our smart city network.",
        "face": "./f3.jpg"
    },
    {
        "title": "CIO Fayette County GA",
        "name": "Phil Frieder",
        "comment": "Cynamics is easily and quickly deployed. A person who works with the county can learn it quickly, so you can deploy it quickly.",
        "face": "./f1.jpg"
    },
    {
        "title": "Chief Security Officer Syntervision, Inc.",
        "name": "Jim Walker",
        "comment": "I have used plenty of network monitoring tools throughout my career and even built my own, trying to find a solution to my problem of finding anomalies and predicting threats.",
        "face": "./f2.jpg"
    }
]

export const countryList = ["United States (US)", "Canada (CA)", "Australia (AU)", "Austria (AT)", "Belgium (BE)", "Bulgaria (BG)", "Croatia (HR)", "Cyprus (CY)", "Czech Republic (CZ)", "Denmark (DK)", "Estonia (EE)", "Finland (FI)", "France (FR)", "Germany (DE)", "Greece (GR)", "Hungary (HU)", "Ireland (IE)", "Italy (IT)", "Latvia (LV)", "Lithuania (LT)", "Luxembourg (LU)", "Malta (MT)", "Netherlands (NL)", "Poland (PL)", "Portugal (PT)", "Romania (RO)", "Slovakia (SK)", "Slovenia (SI)", "Spain (ES)", "Sweden (SE)", "United Kingdom (GB)"]
