import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	noDataContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		height: '100%',
		maxHeight: '100%',
	},
	noDataImage: {
		width: '60%',
		height: '60%',
	},
	noDataText: {
		color: '#5B6F87',
		textAlign: 'center',
		fontSize: '22px',
		fontWeight: '500',
	},
}));

export { useStyles };
