import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	dialogPaperProps: {
		width: '680px',
		maxWidth: '700px',
		height: '520px',
	},
	gridContainer: {
		height: '100%',
		width: '100%',
		textAlign: 'left',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
	},
	titleGrid: {
		minWidth: '100%',
	},
	closeButton: {
		color: theme.palette.primary.main,
		textAlign: 'right',
	},
	closeIcon: {
		align: 'right',
		direction: 'rtl',
	},
	awareSection: {
		color: '#48648b',
		fontSize: '14px',
		fontWeight: 'bold',
		minWidth: '100%',
		cursor: 'pointer',
		userSelect: 'none',
	},
	checkbox: {
		color: '#48648b',
		paddingLeft: 0,
		backgroundColor: 'transparent !important',
	},
	checkboxIcon: {
		borderRadius: 3,
		border: 'black',
		flex: '0 0 auto',
		padding: '12px',
		transform: 'scale(0.7)',
		outline: 'auto',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
	},
	configuringProcessImage: {
		width: 230,
		height: 130,
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	continueButtonGrid: {
		color: theme.palette.primary.main,
		fontSize: '24px',
		fontWeight: 'normal',
		minWidth: '100%',
		textAlign: 'center',
	},
	configureDeviceTitle: {
		color: theme.palette.secondary.main,
		fontSize: '14px',
		fontWeight: 'bold',
		minWidth: '100%',
	},
	continueButton: {
		width: '180px',
		fontSize: '16px',
		height: '120%',
		fontWeight: 700,

	},
}));

export { useStyles };
