import React from "react";
import step1Side from "../../../../../../../assets/walkthrough/analysis/steps/step1Side.png";
import step2Main from "../../../../../../../assets/walkthrough/analysis/steps/step2Main.png";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step2.css";
import MainPanelTemplateV2
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const TITLE = "Range";
const DESCRIPTION = "Select a time range to run the query on, from a set of built-in options. The “from” field will update accordingly."

const Step2 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV2 title={TITLE}
                                 image={<img src={step2Main} className={classes.image}/>}
                                 description={DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step2;