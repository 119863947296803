import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	submitButton: {
		alignSelf: 'center',
		// width: '93%',
		width: '200px',
		fontWeight: '800',
	},
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	submitContainer: {
		marginBottom: 'auto',
		marginLeft: '5%',
	},
	circularProgress: {
		marginRight: 'auto',
		marginLeft: 'auto',
		display: 'block',
	},
	verifyContainer: {
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	verifyField: {
		width: '317px',
	},
}));

export { useStyles };
