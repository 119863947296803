import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	paper: {
		height: '100%',
		width: '628px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
		justifyContent: 'space-between',
		textAlign: 'center',
		paddingRight: '60px',
	},
	container: {
		height: '100%',
		width: '100%',

	},
	fullWidth: {
		minWidth: '100%',
	},
	sendCodeLabel: { marginBottom: '10px' },
	disclaimerApproval: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		marginTop: '14%',
		width: '200px',
		height: '45px',
	},
	submitButton: {
		alignSelf: 'center',
		width: '93%',
		height: '55px',
		fontWeight: '800',
	},
	circularProgress: {
		marginRight: 'auto',
		marginLeft: '70%',
		display: 'block',
	},
	text: { fontSize: '24px' },
	phoneNumber: { fontWeight: 'bold', textAlign: 'center', marginTop: '10px' },
	disclaimerQuestion: { textAlign: 'center', marginTop: '10px' },
	setUpNumber: { width: '200px', fontWeight: 'bold' },
}));

export { useStyles };
