import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 10,
        minWidth: "1100px",
        maxWidth:"570px",
        minHeight: "600px",
        textAlign: 'left',
        fontWeight: "bold",
        backgroundColor: '#fff !important',
        fontSize: "13px",
        borderRadius: "5px",
    },
    loadingContainer : {
        backgroundColor: '#f5f9ff !important',
        textAlign: "left",
        fontSize: '13px',
        fontWeight: 'bold',
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "$dark",
        margin: "10px",
    }
}));

export {useStyles}