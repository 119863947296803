import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from '@material-ui/core';
import PlusIcon from 'mdi-react/PlusIcon';
import _ from 'lodash';

import analyzer from '../../../../helpers/analyzer';
import CreateAlerts from '../createAlert/createAlerts';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import { mapFormToPayload } from '../../controller/businessLogic/payload-transformations';
import { createInitialPaylaod } from '../../controller/businessLogic/utils';
import { validateCreateAlertForm } from '../../controller/validations/createAlertFormValidation';
import { ALERTS_CATEGORY as ANALYZER_CATEGORY, SUBMIT_ACTION } from '../../../../helpers/analyzerConstants';
import { createAlerts, updateSelectedAlertEdition, updateAlert, getAlertes } from '../../controller/redux/actions';
import { SNACKBAR_HIDE_DURATION } from '../../../backgroundProcess/view/visibilityView/rawdataExport/constants';
import PropTypes from 'prop-types';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';

import './alerteModalWrapper.scss';

const CREATE = 'create';
const EDIT = 'edit';
const REEDIT = 'reedit';

class AlerteModalWrapper extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCreateAlerteModalOpen: false,
			lastAlertCreated: null,
			actionType: null,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleOpen = () => {
		this.setState({ isCreateAlerteModalOpen: true, actionType: CREATE });
	};

	handleSubmit = async newAlerte => {
		const isValidated = validateCreateAlertForm(newAlerte);
		let mode = _.isEmpty(this.props.selectedAlertEdition) ? CREATE : EDIT;

		if (!_.isEmpty(isValidated)) {
			mode = REEDIT;
		}

		let newPayload;
		switch (mode) {
			case CREATE:
				analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'NEW_ALERT_FORM' });
				newPayload = mapFormToPayload(newAlerte);
				await this.props.createAlerts(newPayload);
				await this.props.getAlertes();
				this.setState({
					lastAlertCreated: newAlerte.name,
					isCreateAlerteModalOpen: false,
				});

				if (!this.props.alertError) {
					this.props.openSnackbar('success', 'Alert created', SNACKBAR_HIDE_DURATION);
				}

				break;
			case EDIT:
				analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'EDIT_ALERT_FORM' });
				newPayload = mapFormToPayload(newAlerte);
				await this.props.updateAlert(newAlerte.objectId, newPayload);
				await this.props.getAlertes();
				this.props.updateSelectedAlertEdition(null);
				this.setState({
					lastAlertCreated: null,
					isCreateAlerteModalOpen: false,
				});
				break;

			case REEDIT:
				this.setState({
					reEdit: newAlerte,
					errors: isValidated,
					isCreateAlerteModalOpen: true,
				});

				break;

			default:
				break;
		}
	};

	handelonClose = () => {
		this.props.updateSelectedAlertEdition(null);
		this.setState({ isCreateAlerteModalOpen: false });
	};

	render() {
		const { isCreateAlerteModalOpen, errors } = this.state;
		const { selectedAlertEdition } = this.props;

		const shouldOpen = isCreateAlerteModalOpen || !_.isEmpty(selectedAlertEdition);

		const istype = _.isEmpty(selectedAlertEdition) ? CREATE : EDIT;
		let initialValues;
		if (istype === EDIT) {
			initialValues = selectedAlertEdition;
		}

		if (istype === REEDIT) {
			initialValues = this.state.reEdit;
		}

		if (istype === CREATE) {
			initialValues = createInitialPaylaod();
		}

		return (
			<div className="alerte-modal-button">
				<StyleButton
					type="submit"
					className="create-alertes-button"
					onClick={this.handleOpen}
					icon={<PlusIcon />}
					label="New alert"
				/>

				<div className="alert-modal-content">
					<Modal open={shouldOpen} onClose={this.handelonClose} className={'standard-modal alert-modal'} disableAutoFocus={true}>
						{isCreateAlerteModalOpen || !_.isEmpty(selectedAlertEdition) ? (
							<CreateAlerts
								form={`${istype}AlertModal`}
								type={istype}
								errors={errors}
								onSubmit={this.handleSubmit}
								onClose={this.handelonClose}
								initialValues={initialValues}
							/>
						) : (
							<div />
						)}
					</Modal>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	selectedAlertEdition: state.alertes.selectedAlertEdition,
	alertError: state.alertes.alertError,
});

const mapDispatchToProps = {
	createAlerts,
	updateSelectedAlertEdition,
	updateAlert,
	getAlertes,
	openSnackbar,
};

AlerteModalWrapper.propTypes = {
	createAlerts: PropTypes.func,
	updateSelectedAlertEdition: PropTypes.func,
	updateAlert: PropTypes.func,
	getAlertes: PropTypes.func,
	selectedAlertEdition: PropTypes.string,
	alertError: PropTypes.string,
	openSnackbar: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(AlerteModalWrapper);
