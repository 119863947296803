import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    titleLabel:{
        fontSize: '14px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.57',
        marginTop: '15%',
    },
    reportRow:{
        marginTop: '1%'
    }
}));

export {useStyles}