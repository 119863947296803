import { Grid } from '@material-ui/core';
import { Typography } from '@mui/material';
import FilterDropdown from '../../../filterDropdown/filterDropdown';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ALL_DEVICES_FILTER } from '../DeviceMonitoringConfig';

const Filter = ({ onItemSelected }) => {
	const [devicesDropdownData, setDevicesDropdownData] = useState([]);

	useEffect(() => {
		let devicesDropdownData = Object.entries(liteDevices)
			.flatMap(([, devicesByType]) =>
				Object.entries(devicesByType).map(([deviceId, device]) => ({
					label: device.name,
					value: deviceId,
				})),
			);
		devicesDropdownData.sort((a, b) => a.label.localeCompare(b.label));
		devicesDropdownData = [ALL_DEVICES_FILTER, ...devicesDropdownData];
		setDevicesDropdownData(devicesDropdownData);
	}, []);

	const liteDevices = useSelector(state => state.liteDevices);

	return (
		<>
			<Grid item>
				<Typography>
                    Filter by
				</Typography>
			</Grid>
			<Grid item>
				<FilterDropdown items = {Object.values(devicesDropdownData)} defaultSelectionIndex={0}
					userSelectedIndex={0} onItemSelected={onItemSelected} />
			</Grid>
		</>
	);
};

Filter.propTypes = {
	onItemSelected: PropTypes.func,
};
Filter.defaultValue = {
};

export default Filter;
