// Regexes
const ACCOUNT_VALIDATE_REGEX = /^(\d{12})$/
const VPC_VALIDATE_REGEX = /^vpc-(([0-9]|[a-z]){17}$|([0-9]|[a-z]){8}$)/
const SUBNET_VALIDATE_REGEX = /^subnet-(([0-9]|[a-z]){17}$|([0-9]|[a-z]){8}$)/

// Validators
const ACCOUNT_ID_VALIDATOR = (value) => ACCOUNT_VALIDATE_REGEX.test(value)
const INTERFACE_ID_VALIDATOR = (value) => /^eni-(([0-9]|[a-z]){17})$/.test(value)
const VPC_ID_VALIDATOR = (value) => VPC_VALIDATE_REGEX.test(value)
const SUBNET_ID_VALIDATOR = (value) => SUBNET_VALIDATE_REGEX.test(value)
const INSTANCE_ID_VALIDATOR = (value) => /^i-(([0-9]|[a-z]){17})$/.test(value)
const AZ_ID_VALIDATOR = (value) => /[a-z0-9-]+$/.test(value)

export {
    ACCOUNT_VALIDATE_REGEX,
    ACCOUNT_ID_VALIDATOR,
    INTERFACE_ID_VALIDATOR,
    VPC_ID_VALIDATOR,
    SUBNET_ID_VALIDATOR,
    INSTANCE_ID_VALIDATOR,
    AZ_ID_VALIDATOR,
    VPC_VALIDATE_REGEX
}