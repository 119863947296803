import React from 'react';
import SubmitButton from "../../../shared/components/form/Buttons/SubmitButton";
import StyleButton from "../../../shared/components/form/Buttons/StyleButton";




function ActionButtons ({onCancel, isSubmitting=false}){
    return (
        <>
            <SubmitButton isDisabled={isSubmitting} variant={"contained"} label={"SAVE"} style={{width: '141px', marginRight: '10px'}}/>
            <StyleButton isDisabled={isSubmitting} variant={"outlined"} label={"CANCEL"} onClick={onCancel} style={{width: '141px'}}/>
        </>
    )
}

export default ActionButtons;