import React, { Component } from 'react';
import InputGroup1 from './groups/group1/inputGroup1';
import InputGroup2 from './groups/group2/inputGroup2';
import Advance from './advance/advance';
import './createAlerts.scss';
import '../../../ipEnrichmentFields/ipEnrichmentDialog.scss';
import { getFormValues, reduxForm } from 'redux-form';
import CloseButton from '../../../../assets/alerts/close.svg';
import { validateCreateAlertForm } from '../../controller/validations/createAlertFormValidation';
import IpEnrichmentDialog from '../../../ipEnrichmentFields/ipEnrichmentDialog';
import dialogTypes from '../../../ipEnrichmentFields/dialogTypes';
import CreateAlertBottom from './createAlertBottom/createAlertBottom';
import MoreOptionsDialog from '../../../moreOptions/moreOptionsDialog';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import CloudFilterModal from '../../../cloudFilter/cloudFilterModal';
import PropTypes from 'prop-types';
import { activeFilterMap } from '../../../backgroundProcess/view/visibilityView/query/filterConfig';
import { connect } from 'react-redux';
import { ANALYSIS_PAYLOAD_TYPE } from '../../../backgroundProcess/view/visibilityView/propTypes';

class CreateAlerts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSrcIpEnrichmentOpen: false,
			isDestIpEnrichmentOpen: false,
			isMoreOptionsOpen: false,
			isCloudFilterModalOpen: false,
			activeFilters: {
				srcGeo: this.getActiveFilters(this.props.formData, activeFilterMap.srcEnrichment),
				dstGeo: this.getActiveFilters(this.props.formData, activeFilterMap.dstEnrichment),
				cloud: this.getActiveFilters(this.props.formData, activeFilterMap.cloud),
				moreOptions: this.getActiveFilters(this.props.formData, activeFilterMap.moreOptions),
			},
		};
	}

	getActiveFilters = (data, activeFilterOptions) => activeFilterOptions.reduce((total, { count }) => total + count(data), 0);

	handleCloseIpEnrichment = () => {
		const srcActiveFilters = this.getActiveFilters(this.props.formData, activeFilterMap.srcEnrichment);
		const dstActiveFilters = this.getActiveFilters(this.props.formData, activeFilterMap.dstEnrichment);
		this.setState({
			isSrcIpEnrichmentOpen: false, isDestIpEnrichmentOpen: false, activeFilters: {
				...this.state.activeFilters,
				srcGeo: srcActiveFilters,
				dstGeo: dstActiveFilters,
			} });
	};

	handleOpenSrcIpEnrichment = () => {
		this.setState({ isSrcIpEnrichmentOpen: true });
	};

	handleOpenDestIpEnrichment = () => {
		this.setState({ isDestIpEnrichmentOpen: true });
	};

	handleOpenMoreOptions = () => {
		this.setState({ isMoreOptionsOpen: true });
	};

	handleCloseMoreOptions = () => {
		const moreOptionsActiveFilters = this.getActiveFilters(this.props.formData, activeFilterMap.moreOptions);
		this.setState({ isMoreOptionsOpen: false, activeFilters: {
			...this.state.activeFilters,
			moreOptions: moreOptionsActiveFilters,
		} });
	};

	handleCloudFilterToggle = () => {
		const currentToggleState = this.state.isCloudFilterModalOpen;
		this.setState({ isCloudFilterModalOpen: !currentToggleState });
		if (currentToggleState) {
			const cloudActiveFilters = this.getActiveFilters(this.props.formData, activeFilterMap.cloud);
			this.setState({ activeFilters: {
				...this.state.activeFilters,
				cloud: cloudActiveFilters,
			} });
		}
	};

	render() {
		const { handleSubmit, type, errors, form, onClose } = this.props;

		return (<div className="alertes-content">

			<div className="create-alerte-close">
				<img src={CloseButton}
					onClick={onClose}/>
			</div>

			<div className="alertes-header">
				<span className="alert-modal-title"> {type === 'create' ? 'Create new' : 'Edit'} alert</span>

			</div>
			<div className="alertes-body">
				<form onSubmit={handleSubmit}>
					<InputGroup1 errors={errors}/>
					<InputGroup2/>
					<Advance />
					<IpEnrichmentDialog isOpen={this.state.isSrcIpEnrichmentOpen}
						handleClose={this.handleCloseIpEnrichment}
						enrichmentType={dialogTypes.SRC}

					/>
					<IpEnrichmentDialog isOpen={this.state.isDestIpEnrichmentOpen}
						handleClose={this.handleCloseIpEnrichment}
						enrichmentType={dialogTypes.DEST}
					/>
					<MoreOptionsDialog isOpen={this.state.isMoreOptionsOpen}
						handleClose={this.handleCloseMoreOptions}
						formName={form}
						showVlans={true}
					/>

					<CloudFilterModal isOpen={this.state.isCloudFilterModalOpen}
						handleClose={this.handleCloudFilterToggle}
						hostingFormName={form}
					/>
					<CreateAlertBottom handleOpenSrcIpEnrichment={this.handleOpenSrcIpEnrichment}
						handleOpenDestIpEnrichment={this.handleOpenDestIpEnrichment}
						handleOpenMoreOptions={this.handleOpenMoreOptions}
						handleOpenCloudFilter={this.handleCloudFilterToggle}
						formName={form}
						activeFilters={this.state.activeFilters}
					/>
					<div className="save-alert">
						<SubmitButton label="Save" className="save-alert-button"/>
					</div>
				</form>
			</div>
		</div>);
	}
}

const mapStateToProps = (state, props) => ({
	formData: getFormValues(props.form)(state),
});

const mapDispatchToProps = () => ({
	// ...
});

// eslint-disable-next-line no-class-assign
CreateAlerts = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CreateAlerts);

CreateAlerts.propTypes = {
	type: PropTypes.string,
	handleSubmit: PropTypes.func,
	errors: PropTypes.object,
	onClose: PropTypes.func,
	form: PropTypes.string,
	formData: PropTypes.shape(ANALYSIS_PAYLOAD_TYPE),
};

export default reduxForm({ validateCreateAlertForm })(CreateAlerts);

