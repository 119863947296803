import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	row: {
		fontSize: '14px',
		alignItems: 'center',
		color: theme.palette.secondary.main,
		padding: '4px 0px',
	},
	infoIcon: {
		alignSelf: 'center',
		marginBottom: '1px',
		'&>g>g': {
			'&:hover': {
				fill: `${theme.palette.primary.main} !important`,
				cursor: 'pointer',
			},
		},
	},
	icons: {
		display: 'flex',
		justifyContent: 'space-around',
		height: '100%',
	},
	copyWrapper: {
		display: 'flex',
		alignSelf: 'center',
	},
	summaryValues: {
		fontSize: '14px',
		cursor: 'pointer',
	},
}));

export default useStyles;
