import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	verifyField: {
		width: '220px',
		height: '55px',
		marginTop: 'auto',
		marginBottom: 'auto',
	},
	paper: {
		height: '100%',
		width: '628px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyItems: 'center',
		justifyContent: 'space-between',
	},
	container: {
		height: '100%',
		width: '100%',
	},
	fullWidth: {
		minWidth: '100%',
	},
	qrCodeWidth: {
		width: '112px',
		height: '112px',
	},
	form: {
		minWidth: '100%',
		marginTop: '10px',
	},
	sendCodeLabel: { marginBottom: '10px' },
	stepNumber: { marginBottom: '5px', marginTop: '20px', fontWeight: 'bold' },
	nextButton: { width: '145px', marginRight: 'auto', marginLeft: 'auto', display: 'block' },
	circularProgress: { marginRight: 'auto', marginLeft: 'auto', display: 'block', marginTop: '50px' },
	setUpButton: { width: '145px', marginRight: 'auto', marginLeft: 'auto', display: 'block' },
	radioGroup: { paddingLeft: '10px', paddingTop: '8px' },
}));

export { useStyles };
