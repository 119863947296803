import React, {useEffect, useState} from 'react';
import {Dialog, Grid, Step, StepButton, StepLabel, Stepper} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import {ReactComponent as CheckIcon} from "../../../assets/plans/check.svg"
import ExitButton from "../../../shared/components/ExitButton/ExitButton";
import SelectPlanWrapper from "./selectPlanWrapper/selectPlanWrapper"
import CompletePurchaseWrapper from "./completePurchaseWrapper/completePurchaseWrapper"
import ConfirmationWrapper from "./confirmationWrapper/confirmationWrapper";
import {smallScreenBoxDimension, useStyles} from "./plansStepperWrapper.css"
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import BackgroundImg from "../../../assets/plans/background.svg"
import analyzer from "../../../helpers/analyzer";
import {ANALYZER_FAILED_STATUS, ANALYZER_SUCCESS_STATUS, PLANS_CATEGORY, VISIT_ACTION} from "../../../helpers/analyzerConstants";
const ANALYZER_CATEGORY = PLANS_CATEGORY;

const steps = [{label: 'Select Plan',component: SelectPlanWrapper},
               {label: 'Complete Purchase',component: CompletePurchaseWrapper},
               {label: 'Confirmation', component: ConfirmationWrapper}];

const PLANS_DIALOG_HEIGHT = 900;
const PLANS_DIALOG_LARGE_WIDTH = 1300;

const renderStep = (label, index, completed) => {
    const classes = useStyles();
    let stepLabel = label;
    const labelProps = {
        classes:
            {label:classes.stepperLabel,
             active: classes.stepperActiveLabel,
             completed: classes.stepperActiveLabel}
    };

    if(!completed){
        labelProps["icon"] = <></>
        stepLabel = `${index + 1}. ${label}`
    } else {
        labelProps["icon"] = <CheckIcon/>
    }

    return (
        <Step key={label}>
            <StepLabel {...labelProps}>
                {stepLabel}
            </StepLabel>
        </Step>
    );
}

function PlansStepperWrapper({onClose}) {
    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompleted] = useState({});
    const [selectedBaseProduct, setSelectedBaseProduct] = useState();
    const [currentBaseProduct, setCurrentBaseProduct] = useState();
    const [selectedPriceName, setSelectedPriceName] = useState();
    const [indicationComponent, setIndicationComponent] = useState();

    // const [isSucceeded, setIsSucceeded] = useState(true);
    // const [confirmationCallback, setConfirmationCallback] = useState( () => {});
    const classes = useStyles({BackgroundImg});

    const handleNext = () => {
        const newCompleted = completedSteps;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        const newCompleted = completedSteps;
        newCompleted[activeStep - 1] = false;
        setCompleted(newCompleted);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleError = (component) => {
        setActiveStep(steps.length-1)
        setIndicationComponent(component)
        analyzer.event({category: ANALYZER_CATEGORY, action: ANALYZER_FAILED_STATUS, label: 'PLANS_FLOW'})
    }

    const handleSuccess = (component) => {
        setActiveStep(steps.length-1)
        setIndicationComponent(component)
        analyzer.event({category: ANALYZER_CATEGORY, action: ANALYZER_SUCCESS_STATUS, label: 'PLANS_FLOW'})
    }

    const CurrentStepComponent = steps[activeStep].component

    return (
        <Dialog onClose={onClose} open maxWidth={"xl"}>
            <Box css={smallScreenBoxDimension} height={PLANS_DIALOG_HEIGHT} width={PLANS_DIALOG_LARGE_WIDTH}>
                    <Grid item xs={12} >
                        <Grid container direction={"row"}>
                            <Grid item xs={3} className={classes.backButton}>
                                {activeStep === 1 && <Button
                                        onClick={handleBack}

                                    >
                                        <ArrowBackIcon/>
                                    </Button> }

                            </Grid>
                            <Grid item xs={6}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map(({label},index) => {
                                        return renderStep(label,index, completedSteps[index])
                                    })}
                                </Stepper>
                            </Grid>
                            <Grid item xs={3}>
                                <ExitButton onClick={onClose}/>
                            </Grid>
                        </Grid>

                    <Grid item xs={12} className={classes.header}>
                        <div className={classes.headerLabel}>
                            {steps[activeStep].label}
                        </div>
                    </Grid>

                    <Grid item xs={12} className={classes.stepsWrapperItem}>
                        <CurrentStepComponent
                            nextStep={handleNext}
                            backStep={handleBack}
                            onError={handleError}
                            onSuccess={handleSuccess}
                            selectedBaseProduct={selectedBaseProduct}
                            setSelectedBaseProduct={setSelectedBaseProduct}
                            currentBaseProduct={currentBaseProduct}
                            setCurrentBaseProduct={setCurrentBaseProduct}
                            selectedPriceName={selectedPriceName}
                            setSelectedPriceName={setSelectedPriceName}
                            indicationComponent={indicationComponent}
                            onCloseDialog={onClose}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Dialog>

    );
}

export default PlansStepperWrapper;