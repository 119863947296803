import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormControlLabel, Grid, Paper, RadioGroup, Typography, Radio } from '@material-ui/core';
import { QR_MFA_METHOD, SMS_MFA_METHOD } from '../stepsConfig';
import { useStyles } from './mfaSelector.css';
import {
	AUTHENTICATOR_APP_METHOD_LABEL,
	MFA_SELECTOR_HEADLINE_TITLE,
	MFA_SELECTOR_STEP_NUMBER,
	MFA_SELECTOR_STEP_SUBTITLE, SMS_METHOD_LABEL,
} from '../stepsTexts';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';

const MfaSelector = ({ setStep }) => {
	const classes = useStyles();
	const [selectedMfaMethod, setSelectedMfaMethod] = useState(QR_MFA_METHOD);

	const handleRadioChange = event => {
		setSelectedMfaMethod(event.target.value);
	};

	return (
		<Paper elevation={0} className={classes.paper}>
			<Grid container direction={'column'} className={classes.container}>
				<Grid item xs={2} className={classes.fullWidth}>
					<Typography>
						{MFA_SELECTOR_HEADLINE_TITLE}
					</Typography>
				</Grid>
				<Grid xs={1} className={classes.fullWidth} >
					<Typography className={classes.stepNumber}>
						{MFA_SELECTOR_STEP_NUMBER}
					</Typography>
					<Typography>
						{MFA_SELECTOR_STEP_SUBTITLE}
					</Typography>
				</Grid>

				<Grid xs={7}>
					<RadioGroup className={classes.radioGroup} aria-label="mfa selector" name="mfa selector" value={selectedMfaMethod} onChange={handleRadioChange}>
						<FormControlLabel value={QR_MFA_METHOD} control={<Radio size="small" />} label={AUTHENTICATOR_APP_METHOD_LABEL} />
						<FormControlLabel value={SMS_MFA_METHOD} control={<Radio size="small" />} label={SMS_METHOD_LABEL}/>
					</RadioGroup>
				</Grid>
				<Grid xs={1} className={classes.fullWidth}>
					<StyleButton label={'Next'} className={classes.nextButton} onClick={() => setStep(selectedMfaMethod)} />
				</Grid>
			</Grid>
		</Paper>);
};

export default MfaSelector;

MfaSelector.propTypes = {
	setStep: PropTypes.func.isRequired,
};
