import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { connect } from 'react-redux';
import _ from 'lodash';
import ReasonForm from '../reasonForm/reasonForm';
import { feedbackAnomalies } from '../../../../threatsManagement/controller/redux/threatsActions';



class FeedbackLoop extends Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            isReasonGive: false,
            selectedReason: null
        };

        this.feedback = this.feedback.bind(this);
    }
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: { 'left': event.clientX, 'top': event.clientY } });
    };

    handleClose = () => {
        this.setState({ anchorEl: null, isReasonGive: false });
    };

    feedback = (newValue) => {
        const { isReasonGive } = this.state;

        if (['FalsePositive', 'Known', 'TruePositive'].includes(newValue)) {
            this.setState({ isReasonGive: true, selectedReason: newValue });
        } 
    }
    onSubmitFeedback = async (content) => {

        const { selectedReason, description } = content;
        await this.props.feedbackAnomalies(content,
            {
                reason: selectedReason,
                description: description
            })
        this.handleClose();
    }
    render() {
        const { anchorEl, isReasonGive } = this.state;
        const { className, treath } = this.props;


        const open = Boolean(anchorEl);
        return (
            <div className={ className }>
                <IconButton
                    onClick={ this.handleClick }
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorPosition={ anchorEl }
                    open={ open }
                    anchorReference='anchorPosition'
                    onClose={ this.handleClose }
                >
                    { isReasonGive == true ? <ReasonForm
                        treath={ treath }
                        selectedReason={ this.state.selectedReason }
                        onSubmit={ (content) => { this.onSubmitFeedback(content) } }
                        closeReason={ (e) => {
                            this.setState({ isReasonGive: false })
                        } } /> : <>
                            <MenuItem onClick={ (e) => { this.feedback('TruePositive') } }>Nice catch! </MenuItem>
                            <MenuItem onClick={ (e) => { this.feedback('Known') } }>I’m familiar with it</MenuItem>
                            <MenuItem onClick={ (e) => { this.feedback('FalsePositive') } }>I don’t see anything…</MenuItem>
                        </> }


                </Menu>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = {
    feedbackAnomalies
};
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackLoop);


