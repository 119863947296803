import PropTypes from 'prop-types';
import React from 'react';
// Third party
import { Modal } from 'reactstrap';
import DeleteIcon from '../../../assets/modal/delete.svg';
import CloseButton from '../form/Buttons/CloseButton';
import StyleButton from '../form/Buttons/StyleButton';
import './deleteModal.scss';

const DeleteModal = ({
	modalOpen = true,
	toggle,
	onConfirm,
	message,
	withWarning = true,
	messageAdditionalStyles = null,
}) => {
	const onDeleteConfirm = () => {
		onConfirm();
		toggle();
	};

	return (
		<Modal
			contentClassName="delete-modal"
			isOpen={modalOpen}
			toggle={toggle}
			centered
		>
			<div className="delete-modal-header">
				<img src={DeleteIcon} alt="" className="header-image"/>
				<div className="delete-modal-text" style={messageAdditionalStyles}>
					{message}
				</div>
				<CloseButton onClick={toggle}/>
			</div>
			{withWarning && <div className="delete-modal-disclaimer">
				This action can not be undone
			</div>}
			<StyleButton className="button delete-button" onClick={onDeleteConfirm} label="Yes, Delete!"/>
			<StyleButton className="button cancel-button" onClick={toggle} label="Cancel"/>
		</Modal>
	);
};

DeleteModal.propTypes = {
	message: PropTypes.string,
	modalOpen: PropTypes.bool,
	onConfirm: PropTypes.func,
	toggle: PropTypes.func,
	withWarning: PropTypes.bool,
	messageAdditionalStyles: PropTypes.object,
};
export default DeleteModal;
