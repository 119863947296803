import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	highchartsContainer: {
		height: '100%',
		marginRight: '1%',
	},
}),
);

export { useStyles };
