import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { useFormik } from 'formik';
import useStyles from './ipAliasForm.css';
import { FormWrapper } from '../../../../shared/components/simpleTable/simpleFormContainer';
import SimpleModal from '../../../../shared/components/simpleTable/simpleModal';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import TextFieldWrapper from '../../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import GroupIcon from '../../../../assets/modal/group-icon.png';
import { CircularProgress } from '@material-ui/core';
import { groupTypes } from '../ipgroupModal/groupTypes';
import { configGroupManagement } from '../configGroupManagement';

const modalIconStyle = { marginRight: '4%', marginTop: '2%', width: 131, height: 56 };

const ipRegex
	= /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;

const validationSchema = yup.object({
	title: yup
		.string('Please enter a valid name')
		.required('Name is required'),
	description: yup
		.string('Please enter a valid alias description')
		.max(68, 'Description max limit Exceeded'),
	cidrs: yup
		.string('Please enter a valid IP/CIDR')
		.ipv4OrCidr()
		.required('IP/CIDR Is required'),
});

function buildInitialValues(isEditMode, selectedGroup) {
	if (isEditMode) {
		const { description, title } = selectedGroup.objectGroup;
		const cidrs = selectedGroup.objectItems.filter(item => item.type === groupTypes.CIDR) || [];
		return {
			title,
			cidrs: cidrs[0] ? cidrs[0].value.value : '',
			description,
			isAlias: true,
		};
	}

	return {
		isAlias: true,
	};
}

const AliasGroupForm = ({
	selectedGroup,
	isEditMode,
	isOpen,
	onSaveGroup,
	onCloseModal,
	isLoading,
}) => {
	const classes = useStyles();

	const handleSubmitForm = async formData => {
		const { cidrs } = formData;
		let parsedIpToCidr;
		if (ipRegex.test(cidrs)) {
			parsedIpToCidr = cidrs.concat('/32');
		}

		const groupToSave = parsedIpToCidr
			? { ...formData, cidrs: [parsedIpToCidr] }
			: { ...formData, cidrs: [cidrs] };

		const objectItems = groupToSave.cidrs.map(cidr => ({ type: groupTypes.CIDR, value: { value: cidr } }));

		const groupBody = {
			objectGroup: {
				title: groupToSave.title,
				description: groupToSave.description,
				objectType: configGroupManagement.GROUP_ALIAS_OBJECT_TYPE,
				creationType: configGroupManagement.GROUP_CUSTOM_CREATION_TYPE,
			},
			objectItems,
		};

		await onSaveGroup(isEditMode, groupBody);
		onCloseModal();
	};

	const { values, handleChange, touched, errors, handleSubmit } = useFormik({
		initialValues: buildInitialValues(isEditMode, selectedGroup),
		validationSchema,
		onSubmit: handleSubmitForm,
	});

	return (
		<SimpleModal
			header={isEditMode ? 'Edit alias' : 'Add alias'}
			isOpen={isOpen}
			toggle={onCloseModal}
			icon={GroupIcon}
			iconStyle={modalIconStyle}>
			<form onSubmit={handleSubmit}>
				<FormWrapper title="Alias properties">
					<TextFieldWrapper
						fullWidth
						id="title"
						name="title"
						label="Name"
						value={values.title}
						onChange={handleChange}
						error={touched.title && Boolean(errors.title)}
						helperText={touched.title && errors.title}
						required
					/>
					<TextFieldWrapper
						fullWidth
						id="description"
						name="description"
						label="Description"
						value={values.description}
						onChange={handleChange}
						error={touched.description && Boolean(errors.description)}
						helperText={touched.description && errors.description}
					/>
					<TextFieldWrapper
						fullWidth
						id="cidrs"
						name="cidrs"
						label="IP Address / CIDR"
						value={values.cidrs}
						onChange={handleChange}
						error={touched.cidrs && Boolean(errors.cidrs)}
						helperText={touched.cidrs && errors.cidrs}
						required
					/>
				</FormWrapper>
				<div className={classes.submitButton}>
					{isLoading ? (
						<CircularProgress color="primary"/>
					) : (
						<SubmitButton label={isEditMode ? 'Save' : 'Add IP alias'}/>
					)}
				</div>
			</form>
		</SimpleModal>
	);
};

export default AliasGroupForm;

AliasGroupForm.propTypes = {
	isEditMode: PropTypes.bool,
	isOpen: PropTypes.bool,
	onCloseModal: PropTypes.func,
	onSaveGroup: PropTypes.func,
	selectedGroup: PropTypes.object,
	isLoading: PropTypes.bool,
};
