import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%"
    },
    header: {
        height: '113px',
        backgroundColor: '#071C36',
        background: 'center',
        color: 'white',
        fontSize: '34px',
        fontWeight: 'bold',
        justifyContent: 'center'
    },
    mainContent: {
        //height: '715px'
        height: 'calc(90% - 113px)',
        padding: '10px',
        margin: '0 auto',
        alignContent: 'space-evenly',
        alignItems: 'center'
    },
    headerLabel: {
        textAlign: "center",
        display: "block",
        lineHeight: "112px",
        marginLeft: '10px'
    },

    headerIcon: {
        alignSelf: 'center'
    },

    inviteFriendsContainer:{
        height: '50%',
        justifyContent: 'space-between'
    },

    sendMailForm: {
        height: '100%',
        alignContent: 'space-around'
    }
}));

export default useStyles;