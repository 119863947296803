import Storage from "../../../../services/storage";
import _ from 'lodash'
import {
    CREATE_BACKGROUND_PROCESS,
    BACKGROUND_PROCESSES,
    STATUS_BACKGROUND_PROCESS,
    BACKGROUND_PROCESS_INIT,
    REMOVE_BACKGROUND_PROCESS,
    RENAME_BACKGROUND_PROCESS,
    GET_BACKGROUND_PROCESS,
    UPDATE_CURRENT_BACKGROUND_PROCESS,
    REMOVE_CURRENT_BACKGROUND_PROCESS,
    REMOVE_ALL_PROCESS,
    UPDATE_BACKGROUND_PROCESS_ARRAY,
    UPDATE_PER_SECOND,
    UPDATE_BACKGROUND_PROCESS_1BY1,
} from './backgroundProcessAction';
import {CONDENSE_FORM ,EXPAND_FORM ,TOGGLE_EXPAND_FORM} from "./actionTypes";

const initialState = {
    processes: !_.isEmpty(Storage.get('process')) ? Storage.get('process') : [],
    currentProcess: null,
    isPerSecond: false,
    isFormExpanded: true,
    next: null
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case UPDATE_BACKGROUND_PROCESS_ARRAY:
            const proposedNewArray = payload.newArray;
            const currentProcessWorkItems = state.processes
                .map(e => e.workItemId);
            const NewProcessList = _.filter(proposedNewArray, e => {
                return currentProcessWorkItems.includes(e.workItemId) ? true : false;
            });
            let newCurrentProces = null
            if (!_.isEmpty(state.currentProcess)) {
                const currentWorkItemId = state.currentProcess.workItemId;
                newCurrentProces = proposedNewArray.find((e) => e.workItemId == currentWorkItemId);
            }

            return {
                ...state,
                processes: NewProcessList,
                currentProcess: newCurrentProces
            };
        case UPDATE_PER_SECOND:
            return {
                ...state,
                isPerSecond: payload
            }

        case BACKGROUND_PROCESS_INIT:
            let savedProcess = [];
            if (!_.isEmpty(Storage.get('process'))) {
                savedProcess = Storage.get('process');
            }
            return {
                ...state,
                currentProcess: savedProcess[0],
                processes: !_.isEmpty(savedProcess) ? savedProcess : []
            };
            break
        case BACKGROUND_PROCESSES:
            return { ...state, processes: payload.processes };
            break
        case REMOVE_CURRENT_BACKGROUND_PROCESS:
            return { ...state, currentProcess: null }
        case CREATE_BACKGROUND_PROCESS:
            Storage.set('process', state.processes.concat(payload.process));
            return { ...state, processes: state.processes.concat(payload.process) };

        case REMOVE_ALL_PROCESS:
            Storage.set('process', []);
            return { ...state, processes: [], currentProcess: null }

        case STATUS_BACKGROUND_PROCESS:
            return { ...state };
            break
        case RENAME_BACKGROUND_PROCESS:

            let reNameIndex = _.findLastIndex(state.processes, f => {
                return (f.workItemId == payload.backgroundProcessId)
            })
            let toRename = state.processes[reNameIndex]
            toRename.title = payload.newName
            state.processes[reNameIndex] = toRename
            Storage.set('process', state.processes);
            return { ...state, processes: state.processes };
            break

        case TOGGLE_EXPAND_FORM:
            return { ...state, isFormExpanded: !state.isFormExpanded }

        case EXPAND_FORM:
            return { ...state, isFormExpanded: true }

        case CONDENSE_FORM:
            return { ...state, isFormExpanded: false }

        case REMOVE_BACKGROUND_PROCESS:
            Storage.set('process', payload.proc);

            return {
                ...state,
                processes: payload.proc,
                currentProcess: payload.isCurrentProcess ? null : state.currentProcess
            };

            break

        case UPDATE_CURRENT_BACKGROUND_PROCESS:
            state.currentProcess.status = 'MODIFYING'
            state.currentProcess.originalQuery[payload.key] = payload.value

            return { ...state, currentProcess: state.currentProcess }
            break

        case GET_BACKGROUND_PROCESS:
            let showIndex = _.findLastIndex(state.processes, f => {
                return (f.workItemId == payload.backgroundProcessId)
            })
            return { ...state, currentProcess: state.processes[showIndex] };
            break

        case UPDATE_BACKGROUND_PROCESS_1BY1:
            try {
                let insertIndex = _.findLastIndex(state.processes, f => {
                    return (f.workItemId == payload.workItemId)
                })

                state.processes[insertIndex] = payload
                let currentItem = state.currentProcess;
                if (!_.isEmpty(currentItem)
                    && _.has(currentItem, 'workItemId')
                    && currentItem.workItemId == payload.workItemId) {
                    currentItem = payload
                }
                Storage.set('process', state.processes);
                return {
                    ...state,
                    processes: _.cloneDeep(state.processes),
                    currentProcess: currentItem
                }
            } catch (error) {
                console.log(error)
            }

        default:
            return state;
    }
}
