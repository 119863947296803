import { EXCLUDE_SIGN, INCLUDE_SIGN } from '../../shared/components/queryLine/selectors/directionSelectCreatable/consts';
import { REGIONS, SERVICES } from './aws/awsConfiguration';
import store from '../app/controller/redux/store/store';
import { mapGroups } from '../backgroundProcess/controller/businessLogic/mappers';

const convertListToFormStruct = (includedPayload = [], excludedPayload = [], homeList) => {
	const included = includedPayload.map(value => convertToFormStruct(value, INCLUDE_SIGN, homeList));
	const excluded = excludedPayload.map(value => convertToFormStruct(value, EXCLUDE_SIGN, homeList));

	return included.concat(excluded);
};

const convertGroupsToFormStruct = (includedPayload = [], excludedPayload = []) => {
	const allIpGroups = store.getState().account.groups;

	const included = includedPayload.map(value => buildIpGroupFormStruct(allIpGroups, value, INCLUDE_SIGN));
	const excluded = excludedPayload.map(value => buildIpGroupFormStruct(allIpGroups, value, EXCLUDE_SIGN));

	return [...included, ...excluded].flat();
};

const convertToFormStruct = (attributeValue, direction, homeList = []) => ({
	direction,
	value: attributeValue,
	label: getValueLabelFromList(attributeValue, homeList),
});

const getValueLabelFromList = (valueFromPayload, list) => {
	if (list.length > 1) {
		const valueFromList = list.find(({ value }) => value === valueFromPayload);
		if (valueFromList) {
			return valueFromList.label;
		}
	}

	return valueFromPayload;
};

const buildIpGroupFormStruct = (allGroups, ipGroupsId, direction) => {
	const selectedGroups = allGroups.filter(group => ipGroupsId.includes(group.objectGroup.objectId));

	return mapGroups(selectedGroups, direction);
};

const parseAwsPayload = payload => {
	const formattedPayload = {};

	formattedPayload.accountIds = convertListToFormStruct(payload.awsIncAccountIds, payload.awsExcAccountIds);
	formattedPayload.interfacesIds = convertListToFormStruct(payload.awsIncInterfaceIds, payload.awsExcInterfaceIds);
	formattedPayload.vpcIds = convertListToFormStruct(payload.awsIncVpcIds, payload.awsExcVpcIds);
	formattedPayload.subnetIds = convertListToFormStruct(payload.awsIncSubnetIds, payload.awsExcSubnetIds)
		.concat(convertGroupsToFormStruct(payload.awsIncSubnetIdGroups, payload.awsExcSubnetIdGroups));
	formattedPayload.instanceIds = convertListToFormStruct(payload.awsIncInstanceIds, payload.awsExcInstanceIds)
		.concat(convertGroupsToFormStruct(payload.awsIncInstanceIdGroups, payload.awsExcInstanceIdGroups));
	formattedPayload.regions = convertListToFormStruct(payload.awsIncRegionNames, payload.awsExcRegionNames, REGIONS);
	formattedPayload.azs = convertListToFormStruct(payload.awsIncAzNames, payload.awsExcAzNames);
	formattedPayload.srcServices = convertListToFormStruct(payload.awsIncServiceNamesSrc, payload.awsExcServiceNamesSrc, SERVICES);
	formattedPayload.destServices = convertListToFormStruct(payload.awsIncServiceNamesDest, payload.awsExcServiceNamesDest, SERVICES);

	return formattedPayload;
};

export default (
	parseAwsPayload
);
