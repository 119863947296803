import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Sidebar from './sidebar/Sidebar';
import { setTitle } from '../../controller/redux/app/appActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../controller/redux/sideBar/sidebarActions';
import { SidebarProps } from '../../../../shared/prop-types/ReducerProps';
import {
	logout, onSubcription,
	onPlanSubcription,
	cancelAll,
	startOnBoarding,
} from '../../controller/redux/auth/authActions';
import CynamicsIntercom from './intercom/Intercom';

import { compose } from 'redux';

import Topbar from './topbar/Topbar';
import BackgroundProcessBottomPart
	from '../../../backgroundProcess/view/backgroundProcess/bottomPart/backgroundProcessBottomPart';
import {
	getAccountInteractions,
	getUserInteractions,
} from '../../controller/redux/dashboardInteractions/actions';
import {
	getAccountPublicFields,
} from '../../controller/redux/account/accountActions';
import { setCurrentPlanDetails } from '../../../plans/redux/plansActions';
import CoreDataLoader from '../../../../shared/components/coreDataLoader/coreDataLoader';
import { updateWelcomeDone } from '../../controller/redux/dashboardInteractions/actions';
import { getInvoices } from '../../../subscription/services/subscriptionRequests';
import { MISSING_DATA_BANNER_PAGES, PAYMENT_OVERDUE_BANNER_PAGES } from '../../../SummaryView/configSummaryView';
import DevicesBannerButton from '../../../../shared/components/form/Buttons/DevicesBannerButton';
import { downloadFromLink } from '../../../../helpers/utils';
import Banner from '../../../../shared/components/Banner/Banner';
import { openBanner, closeBanner } from '../../../../shared/components/Banner/redux/actions';
import PropTypes from 'prop-types';
import { activeFeatures } from '../../../../constants/activeFeatures';
import { FREE_PLAN_ID, YEARLY_BILLING_ID } from '../../../../constants/planFields';

const MILLIS_IN_A_DAY = 86400000;
const DAYS_BEFORE_DEACTIVATION = 29;
let isShowedBanner = false;

class Layout extends Component {
	static propTypes = {
		sidebar: SidebarProps.isRequired,
	};

	constructor() {
		super();
		this.state = {
			devicesNumber: 0,
			isDataFlowing: false,
			deviceBannerOpened: false
		};
	}

	componentDidMount = async () => {
		this.updateMissingDataBanner();

		await this.shouldOpenDeactivationWarningBanner();

		await this.props.getAccountInteractions();
		if (!this.props.welcomeDone) {
			this.props.history.push('/device-management');
			this.props.startOnBoarding();
			await this.props.updateWelcomeDone(true);
		}
	};

	componentDidUpdate(prevProps, prevState) {
		if (this.props.publicDevices !== prevProps.publicDevices || this.props.collectorDevices !== prevProps.collectorDevices || this.props.publicCloudDevices !== prevProps.publicCloudDevices) {
			const devicesNumber = this.getDevicesNumber();
			this.setState({
				devicesNumber,
			});
		}

		if (this.props.summaryData !== prevProps.summaryData || prevState.isDataFlowing !== this.state.isDataFlowing) {
			const isDataFlowing = this.isDataFlowing();
			this.setState({
				isDataFlowing,
			});
		}

		if (prevState.devicesNumber !== this.state.devicesNumber || this.isDataFlowingChanged(prevState)) {
			this.updateMissingDataBanner();
		}

		if (this.props.title !== prevProps.title) {
			this.updateMissingDataBanner();
		}
	}

	isDataFlowingChanged = prevState => prevState.isDataFlowing !== this.state.isDataFlowing;

	updateMissingDataBanner = () => {
		if (this.state.deviceBannerOpened && this.props.summaryData && this.props.summaryData.hasData) {
			this.setState({ deviceBannerOpened: false });
			this.props.closeBanner();
			return;
		}

		if (this.state.deviceBannerOpened && this.state.devicesNumber === 0) {
			this.setState({ deviceBannerOpened: false });
			this.props.closeBanner();
			return;
		}

		if (this.state.devicesNumber > 0 && !this.state.isDataFlowing) {
			this.setState({ deviceBannerOpened: true });
			this.props.openBanner('You are almost there! Cynamics is waiting for data from your device', MISSING_DATA_BANNER_PAGES, this.bannerDevicesButton, 'warning', 'top', true);
		}
	};

	openMissingDataBanner = () => {
		this.props.openBanner('You are almost there! Cynamics is waiting for data from your device', MISSING_DATA_BANNER_PAGES, this.bannerDevicesButton, 'warning', 'top', true);
	};

	getDevicesNumber = () => Object.keys(this.props.publicDevices).length + Object.keys(this.props.collectorDevices).length + Object.keys(this.props.publicCloudDevices).length;

	isDataFlowing = () => this.props.summaryData && Boolean(this.props.summaryData.volumeOverTime);

	bannerDevicesButton = <DevicesBannerButton
		onClick={() => this.props.history.push('/device-management')}
		size="small"
	>Go to Devices
	</DevicesBannerButton>;

	downloadInvoiceButton = link => link.length ? (
		<DevicesBannerButton
			onClick={() => downloadFromLink(link)}
			size="small"
		>Redownload invoice
		</DevicesBannerButton>
	) : null;

	shouldOpenDeactivationWarningBanner = async () => {
		// Plans not filled at start, need to wait
		if (!this.props.plans.currentPlanDetails.baseProductId || isShowedBanner) {
			return;
		}

		isShowedBanner = true;
		if (this.props.account.features.includes(activeFeatures.ORIGINAL_CUSTOMER)
			|| this.props.plans.currentPlanDetails.baseProductId === FREE_PLAN_ID
			|| this.props.plans.currentPlanDetails.priceKey !== YEARLY_BILLING_ID) {
			return;
		}

		const { subscriptionEnd } = this.props.account;
		const daysUntilSubscriptionEnds = Math.ceil((Date.parse(subscriptionEnd) - Date.now()) / MILLIS_IN_A_DAY);
		if (daysUntilSubscriptionEnds <= DAYS_BEFORE_DEACTIVATION) {
			const lastInvoice = await getInvoices(1);
			const pdf = (lastInvoice && lastInvoice[0] && lastInvoice[0].pdf.length) ? lastInvoice[0].pdf : '';

			this.props.openBanner(
				`Payment due: renew now to avoid account deactivation in ${daysUntilSubscriptionEnds} day${daysUntilSubscriptionEnds === 1 ? '' : 's'}`,
				PAYMENT_OVERDUE_BANNER_PAGES,
				this.downloadInvoiceButton(pdf),
				'warning',
				'top',
				true);
		}
	};

	getCurrentBanner = () => this.props.banners.slice().reverse().find(banner => banner.pagesToAppear.some(page => page === this.props.title));

	render() {
		this.shouldOpenDeactivationWarningBanner();
		const {
			sidebar,
			sidebarLinks,
			logout,
			app,
		} = this.props;
		const layoutClass = classNames({
			layout: true,
			'layout--collapse': sidebar.collapse,
		});

		const currentBanner = this.getCurrentBanner();
		return (
			<div className={layoutClass}>
				{currentBanner
					&& <Banner
						isOpen={currentBanner.isOpen}
						text={currentBanner.text}
						pagesToAppear={currentBanner.pagesToAppear}
						dismissed={currentBanner.dismissed}
						variant={currentBanner.variant}
						placement={currentBanner.placement}
						enableClose={currentBanner.enableClose}
					>
						{currentBanner.children}
					</Banner>
				}
				<Sidebar
					links={sidebarLinks}
					sidebar={sidebar}
					changeToDark={this.changeToDark}
					changeToLight={this.changeToLight}
					changeMobileSidebarVisibility={this.props.changeMobileSidebarVisibility}
					changeSidebarVisibility={this.props.changeSidebarVisibility}
					setTitle={this.props.setTitle}
					logout={logout}
				/>
				<CynamicsIntercom/>
				<div className="page-container">
					<Topbar title={app.title}/>

					<CoreDataLoader>
						{this.props.children}
					</CoreDataLoader>

					<BackgroundProcessBottomPart/>
				</div>

			</div>

		);
	}
}

const mapDispatchToProps = {
	logout,
	setTitle,
	changeSidebarVisibility,
	changeMobileSidebarVisibility,
	onSubcription,
	onPlanSubcription,
	cancelAll,
	getAccountInteractions,
	getUserInteractions,
	setCurrentPlanDetails,
	getAccountPublicFields,
	updateWelcomeDone,
	startOnBoarding,
	openBanner,
	closeBanner,
};
const mapStateToProps = state => ({
	app: state.app,
	sidebar: state.sidebar,
	user: state.auth.user,
	title: state.app.title,
	bgProcesses: state.backgroundProcesses.processes,
	currentProcess: state.backgroundProcesses.currentProcess,
	needSubscription: state.auth.needSubscription,
	needChoosePayment: state.auth.needChoosePayment,
	needPaymentDetails: state.auth.needPaymentDetails,
	endSubcription: state.auth.endSubcription,
	welcomeDone: state.interactions.firstLogin.welcomeDone,
	plans: state.plans,
	account: state.account,
	banners: state.banners,
	publicDevices: state.liteDevices.publicDevices,
	collectorDevices: state.liteDevices.collectorDevices,
	publicCloudDevices: state.liteDevices.publicCloudDevices,
	summaryData: state.analyze.summaryData,
});

Layout.propTypes = {
	openBanner: PropTypes.func,
	updateWelcomeDone: PropTypes.func,
	history: PropTypes.object,
	getAccountInteractions: PropTypes.func,
	startOnBoarding: PropTypes.func,
	welcomeDone: PropTypes.bool,
	title: PropTypes.string,
	publicDevices: PropTypes.object,
	changeSidebarVisibility: PropTypes.func,
	changeMobileSidebarVisibility: PropTypes.func,
	publicCloudDevices: PropTypes.object,
	collectorDevices: PropTypes.object,
	summaryData: PropTypes.object,
	account: PropTypes.object,
	app: PropTypes.object,
	banners: PropTypes.array,
	children: PropTypes.array,
	setTitle: PropTypes.func,
	sidebarLinks: PropTypes.array,
	logout: PropTypes.func,
	plans: PropTypes.object,
};
export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(Layout);
