import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../containers/snackbarAlert/controller/redux/actions';
import EnrichmentPopoverView from './enrichmentPopoverView/enrichmentPopoverView';
import { getData, getFieldsToDisplay } from './enrichmentFlows';

function EnrichmentPopover({ isOpen, anchorEl, handleClose, ipAddress, mac }) {
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [fieldsToDisplay, setFieldsToDisplay] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getAlias = async (ipAddress, mac) => {
		try {
			const fields = getFieldsToDisplay(ipAddress, mac);
			setFieldsToDisplay(fields);
			const data = await getData(ipAddress, mac, fields);
			setData(data);
			setIsLoading(false);
		} catch {
			dispatch(openSnackbar('error', 'Something went wrong, please try again'));
			handleClose();
		}
	};

	useEffect(() => {
		if (ipAddress) {
			getAlias(ipAddress, mac);
		}
	}, []);

	return (
		<EnrichmentPopoverView data={data}
			isLoading={isLoading}
			isOpen={isOpen}
			handleClose={handleClose}
			anchorEl={anchorEl}
			fieldsToDisplay={fieldsToDisplay}/>
	);
}

EnrichmentPopover.propTypes = {
	anchorEl: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	ipAddress: PropTypes.string.isRequired,
	mac: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
};

export default EnrichmentPopover;
