const customSelectorStyles = {
	menuList: (provided, state) => ({
		...provided,
		maxHeight: '140px',
	}),
	control: base => ({
		...base,
		height: 70,
		minHeight: 70,
		cursor: 'text',
	}),
	option: base => ({
		...base,
		cursor: 'pointer',
	}),
};

const customPortSelectorStyles = {
	control: base => ({
		...base,
		height: 70,
		minHeight: 70,
		cursor: 'text',
	}),
	option: base => ({
		...base,
		cursor: 'pointer',
	}),
};

export { customSelectorStyles, customPortSelectorStyles };
