import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
	container: {
		margin: '1.8em 0 0 0',
	},
	chipGroupSkeleton: {
		marginBottom: '2em',
	},
	titleSkeleton: {
		marginBottom: '2em',
	},
	skeletonItem: {
		marginBottom: '1em',
	},
}));

export default useStyles;
