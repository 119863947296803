import React from 'react';
import PropTypes from 'prop-types';

import { Box, Dialog, Grid } from '@material-ui/core';
import ModalHeader from '../../../../../shared/components/modalHeader/ModalHeader';
import SamplerCreationStepper from './SamplerCreationStepper';

const SamplerModal = ({ isOpen, onClose }) => (
	<Dialog open={isOpen} onClose={onClose}>
		<Box height={530} width={640}>
			<Grid container xs={12}>
				<ModalHeader title="Add Sampler" onClose={onClose} />
				<SamplerCreationStepper onClose={onClose} />
			</Grid>
		</Box>
	</Dialog>
);

SamplerModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func,
};

export default SamplerModal;
