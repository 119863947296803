import Grid from "@material-ui/core/Grid";
import React ,{useState} from "react";
import {Elements} from '@stripe/react-stripe-js';
import CheckoutForm from "../checkoutForm/checkoutForm";
import getStripeInstance from "../stripe/stripe";

const PaymentMethod = ({ handleSubmitForm, paymentType, isSubmitted, setIsSubmitted, selectedPriceName, selectedBaseProduct, setIsSubmitValid }) => {
    const [stripePromise, setStripePromise] = useState(getStripeInstance());

    return (
        <Grid container>
            <Grid item xs={12}>
                <Elements stripe={stripePromise}>
                    <CheckoutForm handleSubmitForm={handleSubmitForm}
                                  stripe={stripePromise}
                                  isSubmitted={isSubmitted}
                                  selectedPriceName={selectedPriceName}
                                  selectedBaseProduct={selectedBaseProduct}
                                  paymentType={paymentType}
                                  setIsSubmitted={setIsSubmitted}
                                  setIsSubmitValid={setIsSubmitValid}/>
                </Elements>
            </Grid>
        </Grid>
    )
}

export default PaymentMethod;