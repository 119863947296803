const devices = [
    { 'label': 'SFlow', 'value': 'sflow' },
    { 'label': 'NetFlow', 'value': 'netflow' },
    { 'label': 'IpFix', 'value': 'ipfix' }
]

const TIME_BEFORE_DEVICE_INACTIVATION = 10; // This is the time in minutes before an device become inactive

export{
    devices,
    TIME_BEFORE_DEVICE_INACTIVATION
}