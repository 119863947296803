import { useState } from 'react';
import protocolMapping from '../../containers/topTalkers/references/protocols_mapping.json';

export const ipProtocolOptionsDefault = [
	{
		value: '6',
		label: 'TCP (6)',
	},
	{
		value: '17',
		label: 'UDP (17)',
	},
	{
		value: '1',
		label: 'ICMP (1)',
	},
];

const useProtocolIdAutoComplete = () => {
	const [ipProtocolCurrentValue, setIpProtocolCurrentValue] = useState('');
	const [options, setOptions] = useState(ipProtocolOptionsDefault);

	const getIPProtocolsByNumber = protocolNumber => {
		const matches = [];
		for (const key in protocolMapping) {
			if (Object.hasOwnProperty.call(protocolMapping, key)) {
				if (key.toLowerCase().includes(`${protocolNumber}`.toLowerCase())) {
					matches.push(protocolMapping[key]);
				}
			}
		}

		return matches;
	};

	const getIPProtocolsByName = protocolName => {
		const matches = [];
		for (const key in protocolMapping) {
			if (Object.hasOwnProperty.call(protocolMapping, key)) {
				const protocolObject = protocolMapping[key];
				if (protocolObject.protocol_name?.toLowerCase().includes(protocolName.toLowerCase())) {
					matches.push(protocolObject);
				}
			}
		}

		return matches;
	};

	const getIPProtocol = value => {
		let ipProtocols = [];
		const ipProtocolsForDropdown = [];

		if (isNaN(parseInt(value, 10))) {
			ipProtocols = getIPProtocolsByName(value);
		} else {
			ipProtocols = getIPProtocolsByNumber(value);
		}

		for (const ipProtocol of ipProtocols) {
			ipProtocolsForDropdown.push({
				value: ipProtocol.protocol_number,
				label: `${ipProtocol.protocol_name} (${ipProtocol.protocol_number})`,
			});
		}

		return ipProtocolsForDropdown;
	};

	const updateOptions = searchValue => {
		if (ipProtocolCurrentValue === searchValue) {
			return;
		}

		if (searchValue === '') {
			setOptions(ipProtocolOptionsDefault);
			return;
		}

		setIpProtocolCurrentValue(searchValue);
		let newOptions = ipProtocolOptionsDefault;
		if (searchValue) {
			newOptions = getIPProtocol(searchValue);
		}

		setOptions(newOptions);
	};

	return {
		options,
		updateOptions,
	};
};

export default useProtocolIdAutoComplete;
