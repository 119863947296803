
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTitle } from '../app/controller/redux/app/appActions';
import PropTypes from 'prop-types';

const Page = './help/cynamicshelpcenter.html';

class HelpFrame extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		this.props.setTitle('Help Center');
	}

	render() {
		return (
			<>

				<div className="content" style={{ zIndex: 1000 }}>

					<iframe
						src={ Page }
						height="100%"
						width="100%"
						frameBorder="0"
					/>

				</div>
			</>
		);
	}
}

HelpFrame.propTypes = {
	setTitle: PropTypes.func,
};

const mapDispatchToProps = {
	setTitle,
};

export default connect(null, mapDispatchToProps)(HelpFrame);

