import {BASIC_PLAN_ID, CUSTOM_PLAN_ID, FEATURES, FREE_PLAN_ID} from "../../../constants/planFields";

const BUTTON =  'Upgrade';

const FEATURES_DESCRIPTIONS_TEXTS = {
    [FEATURES.DEVICE] : {
        [FREE_PLAN_ID]: `Upgrade to a new plan to add another device`,
        [BASIC_PLAN_ID]: `Upgrade to a new plan to add another device`,
        [CUSTOM_PLAN_ID]: `Please contact sales to modify your custom subscription`
    },
    [FEATURES.USER]: {
        [FREE_PLAN_ID]: `Upgrade to a new plan to add another user`,
        [BASIC_PLAN_ID]: `Upgrade to a new plan to add another user`,
        [CUSTOM_PLAN_ID]: `Please contact sales to modify your custom subscription`
    },
    [FEATURES.VCA]: {
        [FREE_PLAN_ID]: `To view in analysis and receive additional information over more detections, upgrade your plan.`,
        [BASIC_PLAN_ID]: `To view in analysis and receive additional information over more detections, upgrade your plan.`,
        [CUSTOM_PLAN_ID]: `To view in analysis and receive additional information over more detections, Please contact sales to modify your custom subscription`
    },
    [FEATURES.CUSTOM_ALERTS]: {
        [FREE_PLAN_ID]: `To view in analysis and receive additional information over more detections, upgrade your plan.`,
        [BASIC_PLAN_ID]: `To view in analysis and receive additional information over more detections, upgrade your plan.`,
        [CUSTOM_PLAN_ID]: `To view in analysis and receive additional information over more detections, Please contact sales to modify your custom subscription`
    },
    default: {
        [FREE_PLAN_ID]: 'Upgrade to a new plan to unlock this feature',
        [BASIC_PLAN_ID]: 'Upgrade to a new plan to unlock this feature',
        [CUSTOM_PLAN_ID]: 'Upgrade to a new plan to unlock this feature'
    }
}

const HEADERS = {
    [FREE_PLAN_ID]: 'You discovered a premium feature!',
    [BASIC_PLAN_ID]: 'This feature is limited',
    [CUSTOM_PLAN_ID]: 'Want to change your custom plan?',
    default: 'You discovered a premium feature!'
}

const getDialogTexts = (planId, feature='default') => {
    return {
        button: BUTTON,
        header: HEADERS[planId],
        description: FEATURES_DESCRIPTIONS_TEXTS[feature][planId]
    };
}

export {
    getDialogTexts
}