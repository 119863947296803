import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import useStockGraphWithZoom from './useStockGraphWithZoom';
import {
	graphOptions,
} from './config';
import StockGraphHeader from './stockGraphHeader';
import useStockGraph from './useStockGraph';

const StockGraphWithZoom = ({
	data,
	threshold,
	graphTitle,
	graphTitleTooltip,
	children,
	onPointSelect,
	date,
	handleChartMouseLeave,
	configOverrides,
	maxDate,
	minDate,
}) => {
	const {
		removeLastHoveredCircle,
		onChartMouseLeave,
		onMouseHover,
		onClickPoint,
		setPosition,
		removeLastClickedCircle,
		chartRef,
	} = useStockGraph({ data, date, handleChartMouseLeave, onPointSelect, threshold });

	const {
		handleZoomIn,
		handleZoomOut,
		handleDropdownChange,
		onZoom,
		isResetDisabled,
		dropdownItems,
		dropdownValue,
	} = useStockGraphWithZoom({ chartRef, data, threshold, date, onPointSelect, removeLastHoveredCircle, removeLastClickedCircle, minDate, maxDate });

	const options = graphOptions({
		data,
		threshold,
		maxDate,
		minDate,
		onZoom,
		onMouseHover,
		onClickPoint,
		setPosition,
		overrides: configOverrides,
	});

	return <>
		<StockGraphHeader
			graphTitle={graphTitle}
			onZoomIn={handleZoomIn}
			graphTitleTooltip={graphTitleTooltip}
			onZoomOut={handleZoomOut}
			dropdownItems={dropdownItems}
			dropdownValue={dropdownValue}
			onDropdownChange={handleDropdownChange}
			isResetDisabled={isResetDisabled}
		>
			{children}
		</StockGraphHeader>
		{data
			&& <div onMouseLeave={onChartMouseLeave}>
				<HighchartsReact
					highcharts={Highcharts}
					options={options}
					ref={chartRef}
				/>
			</div>}
	</>;
};

StockGraphWithZoom.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({
		x: PropTypes.string.isRequired,
		y: PropTypes.number.isRequired,
		color: PropTypes.string,
	})).isRequired,
	threshold: PropTypes.number.isRequired,
	graphTitle: PropTypes.string.isRequired,
	graphTitleTooltip: PropTypes.string.isRequired || PropTypes.object.isRequired,
	maxDate: PropTypes.instanceOf(Date).isRequired,
	minDate: PropTypes.instanceOf(Date).isRequired,
	date: PropTypes.string.isRequired,
	children: PropTypes.node,
	onPointSelect: PropTypes.func,
	handleChartMouseLeave: PropTypes.func,
	configOverrides: PropTypes.func,

};

StockGraphWithZoom.defaultProps = {
	configOverrides: {},
};

export default StockGraphWithZoom;
