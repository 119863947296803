import { chunk } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FieldFactory from '../../../../shared/components/simpleTable/fields/fieldsFactory';
import SimpleTable from '../../../../shared/components/simpleTable/simpleTable';
import SkeletonTable from '../../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import { MAX_ROW_IN_TABLE } from '../../../app/view/tableConfig/const';
import { isCollectorAttached } from '../../controller/businessLogic/activity';
import ENTITY_TYPES_LABELS from '../../controller/constantes/entityTypeLabels';
import { osType } from '../../controller/constantes/osType';
import useStyles from './collectorTable.css';
import { useSelector } from 'react-redux';
import { tableFieldsDisplayNames, additionalDataFieldsMapping, NOT_READY_TOOLTIP_CONTENT } from './collectorTableConfig';
import { toLocaleDateString } from '../../../../helpers/datesHelper';
import { isCollectorReady } from '../../controller/businessLogic/activity';
import { COLLECTORS_TABLE_PUBLIC_IP_COLUMN_FEATURE_TOGGLE } from '../../../../constants/featureToggles';
import { READY_SUPPORTED_COLLECTOR_TYPES } from '../../controller/constantes/collectors';

const CollectorTable = ({ onDeleteCollector, isLoading, collectors, samplers }) => {
	const classes = useStyles();
	const [displayedData, setDisplayedData] = useState([...collectors, ...samplers]);
	const { isAdmin } = useSelector(state => state.auth.user);

	const isCollectorDeletable = collectorData => !collectorData.attached;

	const getOSTypeLabel = value => {
		const osData = osType.find(element => element.value === value.toUpperCase());
		return osData.label;
	};

	const getEntityTypeLabel = value => ENTITY_TYPES_LABELS[value];

	const isCollectorTypeSupportReadiness = collectorEntityType => READY_SUPPORTED_COLLECTOR_TYPES.includes(collectorEntityType);

	useEffect(() => {
		modifyDataToDisplay();
	}, [JSON.stringify(collectors), JSON.stringify(samplers)]);

	const modifyDataToDisplay = () => {
		const updatedData = JSON.parse(JSON.stringify([...collectors, ...samplers]));
		updatedData.map(collector => {
			collector.createdAt = toLocaleDateString(collector.createdAt, true);
			collector.additionalData = additionalDataFieldsMapping[collector.entityType].map(field => ({ field: tableFieldsDisplayNames[field], value: collector[field] }));
			collector.additionalData = collector.additionalData.filter(field => field.value);
			return collector;
		});
		setDisplayedData(updatedData);
	};

	const columns = [
		FieldFactory.createTextField('entityType', 'Type', false, true, true, true, getEntityTypeLabel),
		FieldFactory.createTextField('name', 'Name', false, true, true, true),
		FieldFactory.createTextField('privateIP', 'Private IP', false, true, true, true),
		FieldFactory.createTextField('OSType', 'OS Type', false, true, true, true, getOSTypeLabel),
		FieldFactory.createReadyFieldWithTooltip('lastSeen', 'Ready', isCollectorReady, 'Ready', 'Not Ready', NOT_READY_TOOLTIP_CONTENT, isCollectorTypeSupportReadiness),
		FieldFactory.createAttachedField('attached', 'Attached', isCollectorAttached, 'Attached', 'Unattached'),
		FieldFactory.createAdditionalDataPopoverField('additionalData', 'Additional Data'),
		FieldFactory.createAddEditField(
			'editOrRemove',
			null,
			onDeleteCollector,
			null,
			isCollectorDeletable,
			null,
			'alignEnd',
			isAdmin,
		),
	];

	if (COLLECTORS_TABLE_PUBLIC_IP_COLUMN_FEATURE_TOGGLE) {
		columns.splice(2, 0, FieldFactory.createTextField('IP', 'Public IP', false, true, true, true));
	}

	if (isLoading || !displayedData) {
		return <SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} className={classes.table} />;
	}

	return <SimpleTable
		totalItemsInPage={ MAX_ROW_IN_TABLE }
		pages={ chunk(displayedData, MAX_ROW_IN_TABLE).length }
		columns={ columns } data={ displayedData } isResizable={ false } />;
};

CollectorTable.propTypes = {
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	onDeleteCollector: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default React.memo(CollectorTable);
