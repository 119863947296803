export const USER_SECTION_TITLE = 'User Details';
export const ROLE_SECTION_TITLE = 'User Role';
export const ADMIN_ROLE_LABEL = 'Admin';
export const ANALYST_ROLE_LABEL = 'Analyst';
export const ADMIN_ROLE_VALUE = 'admin';
export const ANALYST_ROLE_VALUE = 'analyst';
export const ADMIN_ROLE_DESC = 'Full Access: All dashboard actions available.';
export const ANALYST_ROLE_DESC = 'Partial Access: Access to most dashboard actions but cannot manage devices, integrations, users, or subscription.';
export const SUBMIT_BUTTON_LABEL = 'Save';
export const ROLES = [
	{ label: ADMIN_ROLE_LABEL, value: ADMIN_ROLE_VALUE, desc: ADMIN_ROLE_DESC },
	{ label: ANALYST_ROLE_LABEL, value: ANALYST_ROLE_VALUE, desc: ANALYST_ROLE_DESC },
];

export const RBAC_FEATURE_TOGGLE = true;

export const ROLE_DISABLED_TOOLTIP = 'To modify user role, ensure there is at least one other admin';
