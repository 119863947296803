import React from "react";
import step1Side from "../../../../../../../assets/walkthrough/analysis/steps/step1Side.png";
import step6Main from "../../../../../../../assets/walkthrough/analysis/steps/step6Main.png"
import {useStyles} from "./step6.css";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV6
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV6/mainPanelTemplateV6";


const TITLE = "Advanced features";
const TOP_DESCRIPTION_TITLE = "Advanced IP Details";
const TOP_DESCRIPTION = "Filter by geographical and organizational fields for source and destination IPs - continent, country, organization name, ISP name and DNS.";
const BOTTOM_DESCRIPTION = "Run advanced, fine-grained filters for TCP flags, network interfaces, and traffic direction on supported devices.";
const Step6 = () => {
    const classes = useStyles();
    const topDescription = (
        <>
                <span className={classes.title}>{TOP_DESCRIPTION_TITLE}</span> <br/>
                {TOP_DESCRIPTION}
        </>
    )
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV6 title={TITLE}
                                 image={<img src={step6Main}/>}
                                 topDescription={topDescription}
                                 bottomDescription={BOTTOM_DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step6;