import PlusIcon from 'mdi-react/PlusIcon';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import analyzer from '../../../../helpers/analyzer';
import {
	DEVICE_CATEGORY,
	DISMISS_ACTION,
	OPEN_ACTION,
	SUBMIT_ACTION,
	VISIT_ACTION,
} from '../../../../helpers/analyzerConstants';
import DeleteModal from '../../../../shared/components/deleteModal/deleteModal';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import InformationHover from '../../../../shared/components/informationHover/InformationHover';
import SimpleModal from '../../../../shared/components/simpleTable/simpleModal';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { createCollectorInternalPortMapping } from '../../controller/businessLogic/mappers';
import { addCollector, removeCollector } from '../../controller/services/collectorRequests';
import { deleteSampler } from '../../controller/services/samplerRequests';
import { collectorTypes } from '../devicesManagementConfig';
import CollectorForm from './collectorForm';
import CollectorTable from './collectorTable';
import SamplerModal from './samplerModal/SamplerModal';

const DEVICE_ADDED_SUCCESS_MESSAGE = 'Collector details were added to the dashboard. The file will download shortly';
const DEVICE_SAVE_ERROR_MESSAGE = 'Something went wrong. Try again';
const ANALYZER_CATEGORY = DEVICE_CATEGORY;

const COLLECTORS_HOVER_MESSAGE = <p><b>Collector:</b> Collector for devices that support sFlow/NetFlow which can send data straight to Cynamics SaaS. <br /><br /><b>Sampler:</b> Collector for devices that do not support sFlow/NetFlow.<br/>The Sampler will convert the data to sFlow and then the data will be sent to Cynamics SaaS.</p>;

class CollectorPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isDeleteModalOpen: false,
			isAddCollectorModalOpen: false,
			isAddSamplerModalOpen: false,
			selectedCollectorToDelete: null,
			isEditModalOpen: false,
			selectedCollectorToEdit: null,
		};
		this.actualDeleteCollector = this.actualDeleteCollector.bind(this);
	}

	async componentDidMount() {
		analyzer.event({ category: ANALYZER_CATEGORY, action: VISIT_ACTION, label: 'COLLECTOR_PAGE' });
	}

	openAddCollectorModal = () => {
		this.setState({ isAddCollectorModalOpen: true });
		analyzer.event({ category: ANALYZER_CATEGORY, action: OPEN_ACTION, label: 'COLLECTOR_ADD_DEVICE_FORM' });
	};

	openAddSamplerModal = () => {
		this.setState({ isAddSamplerModalOpen: true });
		analyzer.event({ category: ANALYZER_CATEGORY, action: OPEN_ACTION, label: 'SAMPLER_ADD_DEVICE_FORM' });
	};

	closeAddSamplerModal = () => {
		this.setState({ isAddSamplerModalOpen: false });
		this.props.setSamplers();
	};

	endAddingDevice = () => {
		this.setState({ refresh: true });
	};

	onClickedDeleteDevice = device => {
		this.setState({ isDeleteModalOpen: true, selectedDeviceToDelete: device });
	};

	onClickedDeleteCollector = collector => {
		this.setState({ isDeleteModalOpen: true, selectedCollectorToDelete: collector });
	};

	cancelDeleteDeviceModal = () => {
		this.setState({ isDeleteModalOpen: false, selectedCollectorToDelete: null });
	};

	cancelEditDeviceModal = () => {
		this.setState({ isEditModalOpen: false, selectedCollectorToEdit: null });
		analyzer.event({ category: ANALYZER_CATEGORY, action: DISMISS_ACTION, label: 'COLLECTOR_EDIT_DEVICE_FORM' });
	};

	DELETE_BY_COLLECTOR_TYPE = {
		[collectorTypes.LEGACY_COLLECTOR_TYPE]: async () => {
			await removeCollector(this.state.selectedCollectorToDelete._id);
			this.props.setCollectors();
		},
		[collectorTypes.SAMPLER_TYPE]: async () => {
			await deleteSampler(this.state.selectedCollectorToDelete._id);
			this.props.setSamplers();
		},
	};

	actualDeleteCollector = async () => {
		await this.DELETE_BY_COLLECTOR_TYPE[this.state.selectedCollectorToDelete.entityType]();
		this.setState({ isDeleteModalOpen: false, selectedCollectorToDelete: null });
	};

	actualDownloadDevice = async link => {
		if (typeof (link) === 'string') {
			const a = document.createElement('a');
			a.href = link;
			a.click();
		}
	};

	addCollector = async values => {
		analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'COLLECTOR_ADD_DEVICE_FORM' });
		const mapping = createCollectorInternalPortMapping(values);
		try {
			this.setState({ isAddCollectorModalOpen: false });
			const response = await addCollector(values.collectorName, values.osName.toLowerCase(), mapping, values.privateIP);
			this.props.setCollectors();
			this.props.openSnackbar('info', DEVICE_ADDED_SUCCESS_MESSAGE, 4000);
			await this.actualDownloadDevice(response.link);
		} catch {
			this.props.openSnackbar('error', DEVICE_SAVE_ERROR_MESSAGE, 4000);
		}
	};

	toggle = () => {
		this.setState({ isAddCollectorModalOpen: false });
	};

	render() {
		const { isDeleteModalOpen, isAddCollectorModalOpen, isAddSamplerModalOpen } = this.state;

		return <div>
			<div className="management-container">
				<div className="management-header">
					<div className="collectors-explanation">
						<p className="collectors-explanation-text">Here you can find a list of all your Collectors and Samplers</p>
						<InformationHover message={COLLECTORS_HOVER_MESSAGE}/>
					</div>
					{this.props.isAdmin && <div className="add-device-or-collector">
						<StyleButton onClick={this.openAddSamplerModal}
							label="Add Sampler"
							icon={<PlusIcon /> }
							className="table-add-button add-collector-button"
						/>
						<StyleButton onClick={ this.openAddCollectorModal }
							label={ 'Add Collector' }
							icon={ <PlusIcon /> }
							className="table-add-button add-collector-button"
						/>
					</div>}
				</div>
				<div className="public-device-line-break"/>
				{isAddSamplerModalOpen && <SamplerModal mode="add" isOpen={isAddSamplerModalOpen} onClose={this.closeAddSamplerModal}/>}

				<SimpleModal header={'Add Collector'} isOpen={isAddCollectorModalOpen} toggle={this.toggle}>
					<CollectorForm
						mode="add"
						onSubmit={ this.addCollector }
					/>
				</SimpleModal>

				<CollectorTable
					onDeleteCollector={ this.onClickedDeleteCollector }
					collectors={this.props.collectors}
					samplers = {this.props.samplers}
					isLoading={this.props.isLoading}
				/>

				<DeleteModal
					modalOpen={ isDeleteModalOpen }
					toggle={ this.cancelDeleteDeviceModal }
					onConfirm={ this.actualDeleteCollector }
					message={ 'Are you sure you want to delete this collector?' } />
			</div>
		</div>;
	}
}

CollectorPage.propTypes = {
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	openSnackbar: PropTypes.func,
	setCollectors: PropTypes.func,
	setSamplers: PropTypes.func,
	isLoading: PropTypes.bool,
	isAdmin: PropTypes.bool,
};

const mapStateToProps = state => ({
	isAdmin: state.auth.user.isAdmin,
});
const mapDispatchToProps = {
	openSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectorPage);
