import React, { Component } from 'react';
import CalendarBlankIcon from "mdi-react/CalendarBlankIcon";
// third party
import DateTime from 'react-datetime';
import _ from 'lodash'
import ErrorPopOver from '../errors/errorsPopOver';


class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    onChange = (value) => {
        this.props.input.onChange(value);
        this.props.input.onBlur()
    }
    render() {
        const { input, dateFormat, timeFormat, inputProps, defaultValue, meta } = this.props;
        const { value } = input;

        return (
            <div className="wrapper-time-input">
                <CalendarBlankIcon className="picker-icon" />
                <DateTime
                    defaultValue={ _.isEmpty(value) ? meta.initial.format(dateFormat) : value }
                    onChange={ this.onChange }
                    dateFormat={ dateFormat }
                    timeFormat={ timeFormat }
                    inputProps={ inputProps }
                    defaultValue={ defaultValue }
                    value={ _.isEmpty(value) ? meta.initial.format(dateFormat) : value }
                />
                <ErrorPopOver
                    message={ this.props.meta.warning }
                    hasWarning={ !_.isEmpty(this.props.meta.warning) ? true : false } />

            </div>
        )
    }
}

export default DatePicker;
