import { getDefinition } from './services/requests';
import { formatRulesFromServer } from './services/transformers';
import { matchPath } from 'react-router';

const DEFINITION_PROJECTION = [
	'DefinitionPayload',
	'DefinitionId',
	'DetectionId',
	'Source',
	'Metadata',
].join(',');

export const getAndParseDefinition = async (definitionId, mappedGroups, mappedDevices) => {
	const params = { params: { projection: DEFINITION_PROJECTION } };
	const definition = await getDefinition(definitionId, params);
	if (!definition || !definition.DefinitionPayload) {
		return;
	}

	// Parse the data
	definition.DisplayData = definition.DefinitionPayload;
	const definitionPayload = JSON.parse(definition.DefinitionPayload);
	definition.IsBidirectional = definitionPayload.isBidirectional;
	definition.Threats = definitionPayload.threatsService;
	definition.CustomAlerts = definitionPayload.customAlertsService;
	definition.VCA = definitionPayload.vcaService;
	definition.IsEnabled = true;

	const rules = formatRulesFromServer([definition], mappedGroups, mappedDevices);

	return {
		...rules[0],
		definitionId: definition.DefinitionId,
		detectionId: definition.DetectionId,
		source: definition.Source,
		metadata: JSON.parse(definition.Metadata),
	};
};

export const getFullScreenDefinitionId = () => {
	const match = matchPath(window.location.hash, {
		path: '#/acceptlist/definition/:definitionId',
		exact: false,
		strict: false,
	});
	return match ? match.params.definitionId : null;
};
