import React, { Component } from 'react';
import Delete from './delete';
import Stars from './stars';
import './threatwrapper.scss';
import _ from 'lodash';
import { connect } from 'react-redux';

import moment from 'moment';
import FeedbackLoop from "../../../SummaryView/view/alertNotifications/feedbackLoop/feedbackLoop";



class ThreatWrapper extends Component {
    constructor(props) {
        super(props);
    }
    onChangeFavThreat = (e) => {
        this.props.onChangeFavThreat(e);
    }
    onDeleteThreat = (e) => {

        this.props.onDeleteThreat(e);
    }

    render() {
        const value = this.props.value;
        return (
            <div className="threath-action">

                {moment(moment()).diff(moment(value.Timestamp * 1000), 'minutes') > 10 ? <FeedbackLoop
                    treath={ value }
                    feedback={ this.feedback } /> : null }
                <div className="separator"></div>
                <Stars value={ value }
                    onChangeFavThreat={ (e) => { this.onChangeFavThreat(e) } } />
                <div className="separator"></div>
                { this.props.hadDelete ? <Delete
                    value={ value }
                    onDeleteThreat={ (e) => { this.onDeleteThreat(e) } } /> : null }
            </div>
        );
    }
}




const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ThreatWrapper);
