import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import analyzer from '../../../../../helpers/analyzer';
import { SUBMIT_ACTION } from '../../../../../helpers/analyzerConstants';
import { default as ToggleButtons } from '../../../../../shared/components/toggleButton/toggleButtons';
import { openSnackbar } from '../../../../snackbarAlert/controller/redux/actions';
import { ADD_LEGACY_COLLECTOR_STEP, ADD_SAMPLER_STEP, ANALYZER_CATEGORY, CREATE_MODE, DEVICE_EDIT_SUCCESS_MESSAGE, DEVICE_ERROR_MESSAGE, DEVICE_FORMS, DEVICE_SAVE_SUCCESS_MESSAGE, DEVICE_SUBMISSION_MAP, DEVICE_TYPES, EDIT_MODE, FORMS_EXTRA_PROPS } from '../addDeviceConfig';
import { useStyles } from './addDeviceModal.css';

// TODO: When we will create page, initialValues will be set to selected device
const AddDeviceModal = ({
	isEditMode,
	initialValues: selectedDevice,
	modalOpen,
	toggle,
	deviceReferences,
	configType,
	setConfigType,
	collectors,
	samplers,
	onSubmit,
	setStep,
	selectedDeviceType,
	setSelectedDeviceType,
	...props
}) => {
	const [isSubmittingForm, setIsSubmittingForm] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();

	const mode = isEditMode ? EDIT_MODE : CREATE_MODE;
	const formInitialValues = isEditMode ? selectedDevice : FORMS_EXTRA_PROPS[selectedDeviceType].initialValues;
	const FormComponent = DEVICE_FORMS[selectedDeviceType];
	const formAdditionalProps = { ...FORMS_EXTRA_PROPS[selectedDeviceType], initialValues: formInitialValues };
	// WIP - collectorsList
	const collectorsList = collectors && [...collectors, { value: null, label: '+ Add new collector', onClick: () => setStep(ADD_LEGACY_COLLECTOR_STEP), style: { color: 'rgba(0, 184, 236, 1)', fontWeight: '600' } }];
	const samplersList = samplers && [...samplers, { value: null, label: '+ Add new sampler', onClick: () => setStep(ADD_SAMPLER_STEP), style: { color: 'rgba(0, 184, 236, 1)', fontWeight: '600' } }];

	const handleSubmit = async device => {
		analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'DEVICE_FORM' });
		try {
			setIsSubmittingForm(true);
			const createdDevice = await DEVICE_SUBMISSION_MAP[selectedDeviceType][mode](device);
			onSubmit(createdDevice);
			toggle();
			dispatch(openSnackbar('info', isEditMode ? DEVICE_SAVE_SUCCESS_MESSAGE : DEVICE_EDIT_SUCCESS_MESSAGE));
		} catch {
			dispatch(openSnackbar('error', DEVICE_ERROR_MESSAGE));
		} finally {
			setIsSubmittingForm(false);
		}
	};

	return (
		<>
			<Grid container direction="column" className={classes.gridContainer}>
				<Grid xs={3} container className={classes.gridItem}>
					<Grid xs={12} item className={classes.gridItem}>
						<Typography className={classes.deviceTypeText}> Device type </Typography>
					</Grid>
					<Grid xs={12} item className={classes.gridItem}>
						<ToggleButtons toggleButtons={DEVICE_TYPES} selectedButton={selectedDeviceType} setSelectedButton={setSelectedDeviceType} tabsDisabled={isEditMode}/>
					</Grid>
				</Grid>
				<Grid xs={9} className={classes.formContainer} >
					<FormComponent
						modalOpen={modalOpen}
						toggle={toggle}
						deviceReferences={deviceReferences}
						configType={configType}
						setConfigType={setConfigType}
						isSubmittingForm={isSubmittingForm}
						onSubmit={handleSubmit}
						collectors = {collectorsList}
						samplers={samplersList}
						isEditMode = {isEditMode}
						setStep = {setStep}
						{...formAdditionalProps}
						{...props}
					/>
				</Grid>
			</Grid>
		</>
	);
};

AddDeviceModal.propTypes = {
	initialValues: PropTypes.object,
	collectors: PropTypes.array,
	samplers: PropTypes.array,
	configType: PropTypes.object,
	deviceReferences: PropTypes.any,
	modalOpen: PropTypes.bool,
	setConfigType: PropTypes.any,
	toggle: PropTypes.func,
	isEditMode: PropTypes.bool,
	onSubmit: PropTypes.func,
	setStep: PropTypes.func,
	selectedDeviceType: PropTypes.string,
	setSelectedDeviceType: PropTypes.func,
};

AddDeviceModal.defaultProps = {
	isEditMode: false,
	onSubmit() {},
	collectors: [],
};

export default AddDeviceModal;
