import { storeCollectorDevices, storePublicCloudDevices, storePublicDevices } from '../controller/lightweightReducer/actions';

const SERVICE_NAME = 'Devices';
const SERVICE_DESCRIPTION = 'Your devices management center';

const deviceTypes = {
	PUBLIC_DEVICE: 'publicDevice',
	CORE_DEVICE: 'coreDevice',
	CLOUD_DEVICE: 'cloudDevice',
};

const deviceTypesTableOrder = [deviceTypes.PUBLIC_DEVICE, deviceTypes.CORE_DEVICE, deviceTypes.CLOUD_DEVICE];

const collectorTypes = {
	LEGACY_COLLECTOR_TYPE: 'Legacy-Network-Collector',
	SAMPLER_TYPE: 'SFlow-Sampler',
};

const configDevicesManagement = {
	pageTitle: 'Device Management',
	tabDevicesName: 'Devices',
	tabCollectorName: 'Collectors',
	removeDeviceConfirmationMessage: 'Are you sure you want to delete this device?',
	removeCollectorDeviceConfirmationMessage: 'Are you sure you want to delete this device?',
};

const POLLING_INTERVAL = 30000;
const CONFIGURATION_INTERVAL_IN_SECONDS = 120;

const deviceStoreByType = {
	[deviceTypes.PUBLIC_DEVICE]: storePublicDevices,
	[deviceTypes.CORE_DEVICE]: storeCollectorDevices,
	[deviceTypes.CLOUD_DEVICE]: storePublicCloudDevices,
};

const deviceConfigFields = {
	[deviceTypes.PUBLIC_DEVICE]: ['brand', 'model', 'samplingRate', 'IP'],
	[deviceTypes.CORE_DEVICE]: ['brand', 'model', 'samplingRate', 'IP', 'collectorId', 'samplerId'],
	[deviceTypes.CLOUD_DEVICE]: ['_id'],
};

export {
	SERVICE_NAME,
	SERVICE_DESCRIPTION,
	deviceTypes,
	configDevicesManagement,
	POLLING_INTERVAL,
	CONFIGURATION_INTERVAL_IN_SECONDS,
	deviceStoreByType,
	collectorTypes,
	deviceConfigFields,
	deviceTypesTableOrder,
};
