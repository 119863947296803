import React from 'react';
import './imputWrapper.scss';
import _ from 'lodash';
import InformationHover from '../../../../shared/components/informationHover/InformationHover';
import PropTypes from 'prop-types';

const InputWrapper = ({ additionalClasses, name, hasButton, tooltipText, hasTimeorDate, hasLink, error, input }) => (<div className="input-wrapper">
	<div className={`name${_.isEmpty(name) ? '-empty' : ''}`}> {/* className: 'name-empty' or 'name' */}
		<div className="name-tooltip">
			{_.isEmpty(name) ? '-' : name} {/* display '-' or props.name */}
			<div className="tooltip-icon">
				<InformationHover message={tooltipText || ''} iconColor={'#ffffff'}/>
			</div>
		</div>
	</div>

	{hasButton
		? <div className="name">  {/* if props.hasButton -> display the input with 'name' className */}
			{input}
		</div>
		: hasTimeorDate
			? <>
				{input} {/* if it's a date component display the input without any class */}
			</>
			: hasLink
				? <div className="link"> {/* if it's a link component, className is 'link' */}
					{input}
				</div>
				: <div
					className={`input ${additionalClasses}`}>  {/* if it's not any of the others, use "input" className */}
					{input}
				</div>
	}

	<span
		className="input-wrapper-error">{error}</span> {/* if props.error display error in span (no css) */}
</div>);

InputWrapper.propTypes = {
	additionalClasses: PropTypes.object,
	name: PropTypes.string,
	hasButton: PropTypes.bool,
	tooltipText: PropTypes.string,
	hasTimeorDate: PropTypes.bool,
	hasLink: PropTypes.bool,
	error: PropTypes.string,
	input: PropTypes.object,
};
export default InputWrapper;
