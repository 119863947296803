import React, { useEffect, useState } from 'react';
import StockGraphWithZoom from '../../../../shared/components/stockGraph/stockGraphWithZoom';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import ErrorMessage from '../../../../shared/components/ErrorMessage/ErrorMessage';
import useStyles from './findingsGraph.css';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye-icon-fill-color.svg';
import CustomTooltip from '../../../../shared/components/Tooltip/CustomTooltip';
import { createQueryFromAlertPolicy } from '../../../alertsManagement/controller/services/requests';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';
import { getQueryById } from '../../../backgroundProcess/controller/services/visibilityView/requests';
import { createBackgroundProcessesById } from '../../../backgroundProcess/controller/redux/backgroundProcessAction';
import {
	getNowTime,
	getTimeFormatterHhMm,
	getTodayStartTime, getTomorrowStartTime, isToday,
} from '../../../../shared/components/stockGraph/timeManipulations';
import {
	ERROR_TITLE,
	ERROR_MESSAGE,
	GRAPH_TITLE,
	GRAPH_TITLE_TOOLTIP,
	VIEW_ANALYSIS_BTN_LABEL,
	ANALYSIS_TOOLTIP_DISABLED,
	ALERTS_SAMPLING_RATE,
	HOUR_IN_MILLIE_SECONDS,
	CREATE_QUERY_FROM_ALERT_REQUEST_FAILED,
	REACHED_MAXIMUM_QUERIES,
	QUERY_SUCCESSFULLY_CREATED,
} from './const';
import { MOCK_TIMESTAMP, mockData } from './mockData';

const FindingsGraph = ({ eventData }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [graphData, setGraphData] = useState(null);
	const [isAnalysisDisabled, setAnalysisDisabled] = useState(true);
	const [isAnalysisLoading, setAnalysisLoading] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [maxDate, setMaxDate] = useState(new Date(getNowTime()));
	const [minDate, setMinDate] = useState(new Date(getTodayStartTime(new Date(graphData?.firstDetectionTime))));

	useEffect(() => {
		const formattedDate = new Date(graphData ? graphData.firstDetectionTime : null);

		if (!isToday(graphData?.firstDetectionTime)) {
			// Set the date to the next date of the parameter
			setMaxDate(getTomorrowStartTime(formattedDate));
		}

		setMinDate(getTodayStartTime(formattedDate));
	}, [graphData?.firstDetectionTime]);

	const dotColor = value => value < graphData.threshold ? classes.tooltipDotBelow : classes.tooltipDotAbove;

	const analysisEnabledTooltip
		= selectedItem ? <div>
			<span className={classes.tooltipHeader}>Event Selected:</span>
			<span className={dotColor(selectedItem.y)}>&#9679;</span>
			<span className={classes.tooltipValue}>{Math.round(selectedItem.y / 1000) + 'k'}</span>
			<span className={classes.tooltipDate}>{getTimeFormatterHhMm(selectedItem.x)}</span>
		</div> : <></>;

	const adjustItemsData = data => {
		if (data && data.graph) {
			return data.graph.alertStatistics.items.map(item => ({
				x: item.startTime,
				y: item.value,
			}));
		}

		return [];
	};

	useEffect(() => {
		if (eventData) {
			// Todo: remove this code - mock data, change threshold to additionalInfo.threshold.value
			const findings = mockData(eventData.eventId, eventData.firstDetectionTime);
			/// ///

			const items = adjustItemsData(findings);
			setGraphData({ ...eventData, items,
				threshold: findings.threshold.value,
				additionalInfo: { alertId: findings.alertId },
			});
		}
	}, [eventData]);
	const handlePointSelected = point => {
		setAnalysisDisabled(!point);
		setSelectedItem(point);
	};

	const onAnalysisClick = async () => {
		setAnalysisLoading(true);

		try {
			// MOCK_TIMESTAMP = selectedItem.x;
			const timestampInMilliseconds = MOCK_TIMESTAMP * 1000;
			const middleSamplingAlertTimestamp = (ALERTS_SAMPLING_RATE / 2) + timestampInMilliseconds;
			const startTime = middleSamplingAlertTimestamp - (HOUR_IN_MILLIE_SECONDS);
			const endTime = middleSamplingAlertTimestamp + (HOUR_IN_MILLIE_SECONDS);

			const { queryId } = await createQueryFromAlertPolicy(graphData.additionalInfo.alertId, startTime, endTime);
			const response = await loadLinkQueryData(queryId);

			if (response) {
				dispatch(openSnackbar('success', QUERY_SUCCESSFULLY_CREATED, 4000));
			} else {
				dispatch(openSnackbar('error', REACHED_MAXIMUM_QUERIES, 4000));
			}
		} catch {
			dispatch(openSnackbar('error', CREATE_QUERY_FROM_ALERT_REQUEST_FAILED, 4000));
		} finally {
			setAnalysisLoading(false);
		}
	};

	const loadLinkQueryData = async id => {
		const data = await getQueryById(id);
		const originalQuery = JSON.parse(data.query.OriginalQuery);
		const creationTime = data.query.CreationTime;
		return dispatch(createBackgroundProcessesById(id, originalQuery, creationTime));
	};

	if (!graphData || !graphData.items || !graphData.items.length) {
		return <Box className={classes.messageWrapper}><ErrorMessage title={ERROR_TITLE} message={ERROR_MESSAGE}/></Box>;
	}

	return graphData && graphData.items
		? <StockGraphWithZoom
			data={graphData.items}
			threshold={graphData.threshold}
			graphTitle={GRAPH_TITLE}
			graphTitleTooltip={GRAPH_TITLE_TOOLTIP}
			onPointSelect={handlePointSelected}
			date={graphData.firstDetectionTime.toString()}
			maxDate={maxDate}
			minDate={minDate}
		>
			{isAnalysisLoading
				? <div className={classes.loader}><CircularProgress color="primary" size={20}/></div>
				: <CustomTooltip
					title={isAnalysisDisabled ? ANALYSIS_TOOLTIP_DISABLED : analysisEnabledTooltip}
				>
					<span>
						<Button
							variant="text"
							onClick={ onAnalysisClick }
							disabled={isAnalysisDisabled}
							className={classes.analysisButton}
						>
							<div>
								<EyeIcon className={isAnalysisDisabled ? classes.analysisButtonIconDisabled : classes.analysisButtonIconActive}/>
							</div>
							<Typography className={classes.analysisButtonLabel}>
								{VIEW_ANALYSIS_BTN_LABEL}
							</Typography>
						</Button>
					</span>
				</CustomTooltip>
			}
		</StockGraphWithZoom>
		: <></>;
};

FindingsGraph.propTypes = {
	eventData: PropTypes.shape({
		graph: PropTypes.shape({
			alertStatistics: PropTypes.shape({
				items: PropTypes.arrayOf(PropTypes.shape({
					startTime: PropTypes.string.isRequired,
					value: PropTypes.number.isRequired,
				})).isRequired,
			}).isRequired,
		}),
		firstDetectionTime: PropTypes.string.isRequired,
		eventId: PropTypes.string,
		additionalInfo: PropTypes.shape({
			alertId: PropTypes.string.isRequired,
			threshold: PropTypes.shape({
				value: PropTypes.number.isRequired,
			}).isRequired,
		}).isRequired,
	}).isRequired,
};
export default FindingsGraph;
