import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CheckboxFilters from '../../../../../shared/components/checkboxFilters/checkboxFilters';
import '../createAlerts.scss';
import BadgeButton from '../../../../../shared/components/ButtonWithBadge/BadgeButton';
import {
	CLOUD_LABEL,
	DST_GEO_ORG_LABEL, MORE_OPTIONS_LABEL,
	SRC_GEO_ORG_LABEL,
} from '../../../../backgroundProcess/view/visibilityView/consts';

const CreateAlertBottom = ({ handleOpenSrcIpEnrichment, handleOpenDestIpEnrichment, handleOpenMoreOptions, handleOpenCloudFilter, formName, activeFilters = {} }) => {
	const features = useSelector(state => state.account.features);
	const isIpEnrichmentDisabled = !features.includes('ip_resolution_enrichment');

	return (
		<div className="create-alert-bottom">
			<div className="checkbox-filters">
				<CheckboxFilters formName={formName}/>
			</div>
			<div className="more-options-buttons">
				<BadgeButton color={'white'}
					font={'small'}
					onClick={handleOpenCloudFilter}
					label={CLOUD_LABEL}
					badgeContent={activeFilters.cloud}
					contained
				/>

				<BadgeButton color={'white'}
					font={'small'}
					disabled={isIpEnrichmentDisabled}
					onClick={handleOpenSrcIpEnrichment}
					label={SRC_GEO_ORG_LABEL}
					badgeContent={activeFilters.srcGeo}
					contained
				/>

				<BadgeButton color={'white'}
					font={'small'}
					disabled={isIpEnrichmentDisabled}
					onClick={handleOpenDestIpEnrichment}
					badgeContent={activeFilters.dstGeo}
					label={DST_GEO_ORG_LABEL}
					contained
				/>

				<BadgeButton color={'white'}
					font={'small'}
					onClick={handleOpenMoreOptions}
					label={MORE_OPTIONS_LABEL}
					badgeContent={activeFilters.moreOptions}
					contained
				/>
			</div>

		</div>
	);
};

CreateAlertBottom.propTypes = {
	formName: PropTypes.string,
	handleOpenCloudFilter: PropTypes.func,
	handleOpenDestIpEnrichment: PropTypes.func,
	handleOpenMoreOptions: PropTypes.func,
	handleOpenSrcIpEnrichment: PropTypes.func,
	activeFilters: PropTypes.shape({
		srcGeo: PropTypes.string,
		dstGeo: PropTypes.string,
		cloud: PropTypes.string,
		moreOptions: PropTypes.string,
	}),
};

export default CreateAlertBottom;
