import React, { PureComponent } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { withRouter } from "react-router-dom";

class TopBarMenu extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        };
    }


    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: { 'left': event.clientX - 150, 'top': event.clientY } });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    showAccountDetails = () => {
        this.handleClose();
        this.props.history.push('/account-details');
    }
    showContactUs = () => {
        let a = document.createElement('a');
        a.href = "mailto:support@cynamics.ai";
        a.target = '_blank';
        a.download = 'download'
        a.click();

        this.handleClose();

    }
    showHelpCenter = () => {
        this.handleClose();
        this.props.history.push('/help');

    }
    render() {
        const { anchorEl } = this.state;
        const { className } = this.props;
        const open = Boolean(anchorEl);
        return (
            <div className={ className }>
                <IconButton
                    onClick={ this.handleClick }
                >
                    <ArrowDropDownIcon />
                </IconButton>
                <Menu
                    anchorPosition={ anchorEl }
                    anchorReference='anchorPosition'
                    open={ open }
                    onClose={ this.handleClose }
                >
                    <MenuItem onClick={ (e) => { this.showAccountDetails() } }>Account details</MenuItem>
                    <MenuItem onClick={ (e) => { this.showContactUs() } }>Contact</MenuItem>
                    <MenuItem onClick={ (e) => { this.showHelpCenter() } }>Help Center</MenuItem>
                </Menu>
            </div>
        );
    }
}
// MuiList-root MuiMenu-list MuiList-padding
// <MenuItem onClick={ (e) => { this.showHelpCenter() } }>Help Center</MenuItem>


export default withRouter(TopBarMenu);




