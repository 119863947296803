import React from "react";
import step4Side from "../../../../../../assets/walkthrough/overview/steps/step4side.png";
import step4Main from "../../../../../../assets/walkthrough/overview/steps/step4main.gif";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import {useStyles} from "./step4.css";
import MainPanelTemplateV2
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV2/mainPanelTemplateV2";

const Step4 = () => {
    const classes = useStyles();
    const description = (
        <>
            Geographical map of active network devices
        </>
    )
    return (
        <StepTemplate sidePanelImage={step4Side}>
            <MainPanelTemplateV2 title="Map"
                                 image={<img src={step4Main} className={classes.image}/>}
                                 description={description}/>
        </StepTemplate>
    )
}

export default Step4;