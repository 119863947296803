import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		margin: '12px 0',
		alignItems: 'center',
	},
	groupChip: {
		backgroundColor: theme.palette.secondary.main,
		color: '#FFF',
		fontSize: '14px',
		padding: '6px 8px',
		borderRadius: '4px',
	},
	editIcon: {
		color: '#48648B',
		marginLeft: '12px',
		padding: 0,
	},
}));

export { useStyles };
