import numeral from 'numeral'

export const isValidNumber = (value) => {
    return value && numeral(value) && value > 0 ? numeral(value.toString().toLowerCase())._value : null;
}

export const isValidInt = (value) => {
    const number = numeral(value).value();
    return number && Number.isInteger(number) && isValidNumber(value);
}

