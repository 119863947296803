import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    formContainer: {
        width: '100%',
        height: '100%'
    },
    mainGrid: {
        height: '100%'
    },
    smallInputSize: {
        maxWidth: '300px'
    },
    fieldLabel: {
        fontSize: '14px',
        color: '#425262',
        marginLeft: '5px'
    },

    disableFieldLabel: {
        color: '#7F7F7F'
    },

    divider: {
        height: '2%',
        alignSelf: 'center'
    },

    submitButton: {
        alignSelf: 'center'
    }
}));

export {useStyles}
