import React, {Component} from 'react';
import Section from '../section/section';
import {connect} from 'react-redux';
import _ from 'lodash';
import './alertePage.scss';

import GraphAlertPopOver from '../section/graph/popOver/popOverAlertes';
import {setTitle} from '../../../app/controller/redux/app/appActions';

import Filters from '../filters/filters';
import AlerteModalWrapper from '../alerteModal/alerteModalWrapper';
import Step1 from "../walkthrough/steps/step1/step1";
import WalkthroughDialog from "../../../walkthrough/walkthroughDialog/walkthroughDialog";
import Welcome from "../../../../shared/components/walkthrough/welcome";
import welcomeImage from "../../../../assets/walkthrough/customAlerts/steps/welcome.png";
import Step2 from "../walkthrough/steps/step2/step2";
import Step3 from "../walkthrough/steps/step3/step3";
import Step4 from "../walkthrough/steps/step4/step4";
import {
    updateWalkthroughDone
} from "../../../app/controller/redux/dashboardInteractions/actions";
import {SERVICE_DESCRIPTION, SERVICE_NAME} from "../walkthrough/texts";
import {CUSTOM_ALERTS_KEY} from "../../../app/controller/redux/dashboardInteractions/consts";


class AlertePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0
        }
    }

    componentDidMount = () => {
        this.props.setTitle('Alerts');
    }

    finishWalkthrough = () => {
        this.props.updateWalkthroughDone(CUSTOM_ALERTS_KEY, true);
    }

    render() {
        const {alertes, graphs, selectedBar} = this.props;
        const {tabValue} = this.state;
        const steps = [Step1, Step2, Step3, Step4];
        return (
            <>
                {alertes ? <>
                    <div className="section-filter">
                        <Filters/>
                        <AlerteModalWrapper/>

                    </div>
                    <div className="section-header">{`My Alerts (${alertes.length})`}</div>
                    <Section
                        alertes={alertes} graphs={graphs}/>


                </> : null}

                {!_.isEmpty(selectedBar) ? <GraphAlertPopOver
                    anchorPosition={selectedBar.anchorPosition}
                    selectedBar={selectedBar.selectedBar}/> : null}

                {!this.props.isWalkthroughDone && this.props.visibleDevicesCount !== 0 && <WalkthroughDialog
                    serviceName={SERVICE_NAME}
                    serviceDescription={SERVICE_DESCRIPTION}
                    welcomeImage={welcomeImage}
                    StartComponent={Welcome}
                    finishCallback={this.finishWalkthrough}
                    skipCallback={this.finishWalkthrough}
                    steps={steps}
                />}
            </>

        );
    }
}


const mapStateToProps = state => {
    return {
        alertes: state.alertes.alertes,
        graphs: state.alertes.graphs,
        selectedBar: state.alertes.selectedBar,
        isWalkthroughDone: state.interactions.customAlerts.walkthroughDone,
        visibleDevicesCount: state.visibleDevicesCount.value
    };
};
const mapDispatchToProps = {
    setTitle,
    updateWalkthroughDone
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertePage);

