import {
	GRAPH_POINT_COLOR,
	TOOLTIP_HIDE_DELAY,
	TOOLTIP_SNAP,
} from '../../../stockGraph/config';
import utils from '../../../../../services/util';
import { getTimeFormatterHhMm } from '../../../stockGraph/timeManipulations';

const CHART_ANIMATION = false;
const CHART_ALLOW_MUTATING_DATA = false;
export const overrides = {
	chart: {
		animation: CHART_ANIMATION,
		allowMutatingData: CHART_ALLOW_MUTATING_DATA,
	},
	yAxis: {
		title: {
			text: ' ',
		},
		labels: {
			formatter() {
				return `${utils.convertNumber(this.value, 0, true)}bps`;
			},
		},
	},
	tooltip: {
		enabled: true,
		snap: TOOLTIP_SNAP,
		useHTML: true,
		hideDelay: TOOLTIP_HIDE_DELAY,
		backgroundColor: 'transparent',
		formatter() {
			const suffix = 'bps';
			const time = getTimeFormatterHhMm(this.x);
			const value = utils.convertNumber(this.y, 2, true);
			const dotColor = GRAPH_POINT_COLOR;
			const dot = `<span style="color:${dotColor}; font-weight:bold;margin-right:4px">&#9679;</span>`;
			const valueString = `<span style="margin-right: 7px; font-weight: 600">${value}${suffix}</span>`;
			const style = `color: #425262; background-color: #ffffff; padding: 4px 6px 4px 6px;
			 box-shadow: -1px 1px 7px 1px rgba(133, 133, 133, 0.25); height: 24px;
			 border-radius: 5px; font-family: 'Source Sans Pro';font-size: 14px;line-height: 16px;`;

			return `<div style="${style}">${dot}${valueString}${time}</div>`;
		},
	},
	credits: {
		enabled: false,
	},
	loading: {
		style:{"position": "absolute", "backgroundColor": "#ffffff", "opacity": 0.8, "textAlign": "center"}
	}
};
