import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    stepIconContainer: {
        alignSelf: "center"
    },
    stepIcon: {
        height: '105px',
        width: '105px'
    },
    stepTitle: {
        whiteSpace: 'break-spaces',
        fontWeight: 'bold'
    },
    stepDescription: {
        whiteSpace: 'pre'
    }
}));


export default useStyles;