import http from "../../../../../services/httpService/http";
import {
    BACKGROUND_PROCESS_STATUS_URI,
    CREATE_BACKGROUND_PROCESS_URI,
    CREATE_PROCESS_BY_ID_URI
} from "./urls";

const createBackgroundProcess = (query) => {
    return http.post(CREATE_BACKGROUND_PROCESS_URI, {...query, timeWindow: parseInt(query.timeWindow)});
}

const createBackgroundProcessByQueryId = (queryId) => {
    return http.post(CREATE_PROCESS_BY_ID_URI, {id: queryId});
}

const backgroundProcessStatus = (bgProcessId) => {
    return http.get(`${BACKGROUND_PROCESS_STATUS_URI}/${bgProcessId}`);
}
export {
    createBackgroundProcess,
    createBackgroundProcessByQueryId,
    backgroundProcessStatus
}

