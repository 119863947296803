import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	selected: {
		color: 'white !important',
		backgroundColor: '#48648B !important',
	},
	root: {
		color: 'rgb(7 28 54 / 80%)',
		fontSize: '14 !important',
		textTransform: 'none',
		border: '1px solid rgba(114, 135, 165, 1) !important',
	},
}));

export { useStyles };
