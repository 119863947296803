import PropTypes from 'prop-types';
import React from 'react';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { InfoOutlined } from '@material-ui/icons';

import useStyles from './InformationHover.css';

const getTooltipContent = (message, additionalStyles) => <p style={additionalStyles ? additionalStyles : {}}>{message}</p>;

const InformationHover = ({ message, additionalStyles, iconColor }) => {
	const classes = useStyles();

	const TOOLTIP_PROPS = {
		title: getTooltipContent(message, additionalStyles),
		id: 'tooltip',
		placement: 'bottom',
		background: '#4a4a4a',
		border: '#4a4a4a',
		offset: 10,
		padding: 8,
		radius: 4,
		...additionalStyles,
	};

	return (
		<CustomTooltip {...TOOLTIP_PROPS}>
			{ message
				? <InfoOutlined className={classes.icon} htmlColor={iconColor || '#585858'}/>
				: <></>
			}
		</CustomTooltip>);
};

InformationHover.propTypes = {
	message: PropTypes.string.isRequired || PropTypes.object.isRequired,
	additionalStyles: PropTypes.object,
	iconColor: PropTypes.string,
};

export default InformationHover;
