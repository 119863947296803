import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '450px',
		height: '300px',
	},
	image: {
		height: '128px',
		width: '200px',
		margin: '42px 124px 24px 124px',
	},
	title: {
		fontWeight: 'bold',
		color: theme.palette.inputText.main,
		fontSize: '20px',
		textAlign: 'center',
		lineHeight: '16px',
	},
	text: {
		fontSize: '14px',
		textAlign: 'center',
		color: theme.palette.text.secondary,
		margin: '20px 60px 35px 60px',
	},
	mail: {
		color: theme.palette.stepsText.active,
		textDecoration: 'none',
		display: 'inline-flex',
		marginBottom: '2px',
		fontSize: 'inherit',
		'&:hover': {
			color: theme.palette.stepsText.active,
		},
	},
}));

export default useStyles;
