import { useStyles } from '../mfaSelector/mfaSelector.css';
import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
	QR_VERIFICATION_FIRST_STEP_TEXT,
} from '../stepsTexts';
import { generateOtp } from '../../requests';
import QRCode from 'react-qr-code';

const buildQRTotp = secretCode => `otpauth://totp/Cynamics?secret=${secretCode}`;
const QrVerification = () => {
	const classes = useStyles();
	const [totpToken, setTotpToken] = useState('');

	useEffect(() => {
		generateToken();
	}, []);

	const generateToken = async () => {
		const { token } = await generateOtp();
		const qrTotp = buildQRTotp(token);
		setTotpToken(qrTotp);
	};

	return (
		<Paper elevation={0} className={classes.paper}>
			<Grid container direction={'column'} className={classes.container}>
				<Grid xs={3} className={classes.fullWidth} >
					<Typography> {QR_VERIFICATION_FIRST_STEP_TEXT} </Typography>
				</Grid>
				<Grid xs={9} className={classes.qrCodeWidth}>
					{totpToken
						? <QRCode
							size={256}
							className={classes.qrCode}
							value={totpToken}
							viewBox={'0 0 256 256'}
						/>
						: <Skeleton variant="rectangular" width={112} height={112} /> }
				</Grid>
			</Grid>
		</Paper>);
};

export default QrVerification;
