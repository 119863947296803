import { GRAPH_HIGH_DELTA, MINUTES_IN_HOUR } from './config';

// Change this when we get the account's timezone when we will get this from server
// const ACCOUNT_TIMEZONE = 1;
// const USER_TIMEZONE = new Date().getTimezoneOffset() / -60;
// export const GRAPH_TIMEZONE = USER_TIMEZONE - ACCOUNT_TIMEZONE;

export const getHigherLowerPoint = relevantPoints => {
	let highestValue = 0;
	let lowestValue = relevantPoints[0].y;

	relevantPoints.forEach(point => {
		const valueWithDelta = point.y * GRAPH_HIGH_DELTA;
		if ((point.y - valueWithDelta) < lowestValue) {
			lowestValue = point.y - valueWithDelta;
		}

		if ((point.y + valueWithDelta) > highestValue) {
			highestValue = point.y + valueWithDelta;
		}
	});
	return { lowestValue, highestValue };
};

export const getTomorrowStartTime = date => Date.UTC(date.getFullYear(),
	date.getMonth(),
	date.getDate() + 1,
	0,
	0,
);

export const getTodayStartTime = date => Date.UTC(date.getFullYear(),
	date.getMonth(),
	date.getDate(),
	0,
	0,
);

export const getNowTime = () => Date.now();

export const isToday = date => {
	const today = new Date();
	const formattedDate = new Date(date);

	return formattedDate.getFullYear() === today.getFullYear()
		&& formattedDate.getMonth() === today.getMonth()
		&& formattedDate.getDate() === today.getDate();
};

export const calculateGraphTimesByTimeframe = (maxDate, minutes) => {
	const maxDateFormatted = new Date(maxDate);

	return Date.UTC(
		maxDateFormatted.getFullYear(),
		maxDateFormatted.getMonth(),
		maxDateFormatted.getDate(),
		maxDateFormatted.getUTCHours(),
		maxDateFormatted.getUTCMinutes() - minutes,
	);
};

export const getTodayHoursInMinutes = () => new Date().getUTCHours() * MINUTES_IN_HOUR;

export const getTimeFormatterHhMm = timestamp => {
	const date = new Date(timestamp);
	const hours = date.getUTCHours();
	const minutes = date.getUTCMinutes();

	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');

	return `${formattedHours}:${formattedMinutes}`;
};

export const getTimeFormatterHhMmSs = timestamp => {
	const date = new Date(timestamp);
	const hours = date.getUTCHours();
	const minutes = date.getUTCMinutes();
	const seconds = date.getUTCSeconds();

	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');
	const formattedSecond = seconds.toString().padStart(2, '0');

	return `${formattedHours}:${formattedMinutes}:${formattedSecond}`;
};