import React, { Component } from "react";
import _ from 'lodash';
import BaseIntegrationForm from '../baseIntegrationForm';
import { DAILY_REPORT } from '../../../controller/constants/integrationTypes';
import reportIcon from '../../../../../assets/integrations/weekly-email.svg';

import './reportIntegration.scss';

import {Field, FieldArray} from "redux-form";
import {
    build_houres,
    build_timeZone, build_week_days, getCurrentTimeZone,
    processTimeZone
} from "../../../controller/businessLogic/reportsTimeUtils";
import { getDailyReportIntegration,
    updateDailyReportPolicy,
    createDailyReportPolicy
} from "../../../controller/services/dailyReportRequests";
import inputArrayFields from "../formInputs/inputArrayFields";
import {inputTypes} from "../../../controller/constants/inputTypes";
import http from "../../../../../services/httpService/http";
import {activeTypes} from "../../../controller/constants/activeTypes";
import Dropdown from "../../../../../shared/components/dropdown/dropdown";
import Grid from "@material-ui/core/Grid";
import EmailArrayField from "../fieldArrayComponents/emailArrayField";
import {openSnackbar} from "../../../../snackbarAlert/controller/redux/actions";
import {connect} from "react-redux";
const FORM_NAME = "daily-report-integration-form"
const RECIPIENTS_FIELD_ARRAY_NAME = "emails"
const ERROR_MESSAGE = "Something went wrong, please try again";
const SUCCESS_MESSAGE = "Your daily report details successfully saved";

class DailyReportIntegration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dailyReport: {},
            initialValues: {}
        };
        this.saveIntegration = this.saveIntegration.bind(this);
        this.source = http.getCancelTokenSource();
    }

    async getDailyReportPolicies(){
        const policies = await getDailyReportIntegration({cancelToken: this.source.token});
        let policy = {}
        if (!_.isEmpty(policies)) {
            policy = policies[0];
            policy.email = JSON.parse(policy.Recipients).email;
        }
        return _.isEmpty(policies) ? {} : policy;
    }

    async createDailyReportPolicy(object){
        const emails = object.emails.filter((email) => !_.isEmpty(email));
        const payload = {
            "SendingTriggerHour": parseInt(object.SendingTriggerHour),
            "SendingTimeZone": object.SendingTimeZone,
            "Recipients": {
                "email": emails
            },
            "Version": "1"
        }
        const policies = await createDailyReportPolicy(payload);
        return policies;
    }

    async updateDailyReportPolicy(reportPolicyID, policy){
        const emails = policy.emails.filter((email) => !_.isEmpty(email));
        const toUpdateObject = {
            "SendingTriggerHour": parseInt(policy.SendingTriggerHour),
            "SendingTimeZone": policy.SendingTimeZone,
            "Recipients": {
                "email": emails
            },
            "Version": "1",
            "Enabled": true
        }
        let policies = updateDailyReportPolicy(reportPolicyID, toUpdateObject);
        return policies;
    }

    componentDidMount = async () => {
        try {
            const dailyReportPolicies = await this.getDailyReportPolicies();
            let dailyReport = _.isEmpty(dailyReportPolicies) ? {} : dailyReportPolicies;
            this.setState({dailyReport});
            this.setState({initialValues: this.createInitialValues()});
        } catch (exception) {
            console.log(exception.message);
        }
    }

    componentWillUnmount() {
        this.source.cancel('Daily reports request was canceled');
    }

    createInitialValues() {
        const report = this.state.dailyReport;
        if (!_.isEmpty(report)){
            return {
                emails: report.email,
                SendingTriggerHour: report.SendingTriggerHour,
                mode: 'edit'
            }
        }
        return {
            emails: [],
            SendingTriggerHour: build_houres()[0],
            mode: 'create'
        }

    }

    saveIntegration = async (reportPolicy) => {
        const { dailyReport } = this.state;
        try {
            if (reportPolicy.mode === 'create') {
                await this.createDailyReportPolicy({...reportPolicy, SendingTimeZone: getCurrentTimeZone()});
            } else if (reportPolicy.mode === 'edit') {
                await this.updateDailyReportPolicy(dailyReport.Id, {...reportPolicy, SendingTimeZone: getCurrentTimeZone() });
            }
            await this.props.updateSelectedIntegration(null);
            this.props.updateEnabledIntegration(DAILY_REPORT, activeTypes.ON);
            this.props.openSnackbar("success", SUCCESS_MESSAGE, 4000);

        }catch (e) {
            this.props.openSnackbar("error", ERROR_MESSAGE, 4000);

        }

    }

    DailyReportForm = ({ change }) => {
        return <>
            <div style={{marginBottom: '30px'}}>
                    <Field
                        name="SendingTriggerHour"
                        label="Hour"
                        type="number"
                        component={Dropdown}
                        onFieldChange={(value) => {
                            change('SendingTriggerHour', value)
                        }}
                        options={build_houres()}
                        required
                    />
            </div>

            <div className="email-report">
                <FieldArray
                    name={RECIPIENTS_FIELD_ARRAY_NAME}
                    component={EmailArrayField}
                    formName={FORM_NAME}
                    required
                />
            </div>
        </>
    }
    render() {
        const {initialValues} = this.state;
        return (
            <BaseIntegrationForm
                title="Daily summarized alert report"
                subTitle="Get a concise report once a day, with information security insights."
                form={FORM_NAME}
                onSubmit={this.saveIntegration}
                initialValues={initialValues}
                BaseIntegrationFormComponent={this.DailyReportForm}
            />
        )

    }
}

const mapDispatchToProps = {
    openSnackbar,
};

export default connect(null, mapDispatchToProps)(DailyReportIntegration);


