import {
	SUBSCRIPTION_USER,
	END_SUBCRIPTION,
	SUBCRIPTION_PAYMENT_USER,
	SUBCRIPTION_PLAN_USER,
	ACCOUNT_CUSTOMIZATION,
	UPDATE_ACCOUNT_CUSTOMIZATION,
	CANCEL_ALL,
	GET_CURRENT_USER,
	START_ONBOARDING,
	END_ONBOARDING, UPDATE_CURRENT_USER_INFO,
} from './authActions';
import Storage from '../../../../../services/storage';

const initialState = {
	pinCode: null,
	user: null,
	needSubscription: false,
	needChoosePayment: false,
	needPaymentDetails: false,
	trialFirstLoginClicked: false,
	endSubcription: false,
	customization: {},
	onBoarding: false,
};
export default function (state = initialState, { type, payload }) {
	switch (type) {
		case ACCOUNT_CUSTOMIZATION:

			return {
				...state,
				customization: payload.customization,
			};
		case CANCEL_ALL:
			return {
				...state, needSubscription: false, endSubcription: false,
				needChoosePayment: false, needPaymentDetails: false,
			};
		case UPDATE_ACCOUNT_CUSTOMIZATION:
			return {
				...state,
				customization: payload.customization,
			};
		case SUBSCRIPTION_USER:
			return {
				...state, needSubscription: true,
				needChoosePayment: false, needPaymentDetails: false,
			};

		case SUBCRIPTION_PLAN_USER:
			return {
				...state, needChoosePayment: false,
				needSubscription: false, needPaymentDetails: false,
			};

		case SUBCRIPTION_PAYMENT_USER:
			return {
				...state, needChoosePayment: false, needSubscription: false,
				needPaymentDetails: false,
			};
		case END_SUBCRIPTION:

			return {
				...state, needChoosePayment: false, needSubscription: false,
				needPaymentDetails: false, endSubcription: true,
			};

		case GET_CURRENT_USER:// We are update form for initialValues react redux
			return {
				...state,
				user: payload.user,
			};
		case UPDATE_CURRENT_USER_INFO:
			return {
				...state,
				user: {
					...state.user,
					...payload.userUpdateInfo,
				},
			};
		case START_ONBOARDING:
			return {
				...state,
				onBoarding: true,
			};
		case END_ONBOARDING:
			return {
				...state,
				onBoarding: false,
			};

		default:
			return state;
	}
}
