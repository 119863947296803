import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.secondary.main,
        height: '40px',
    },

    title: {
        color: 'white',
        lineHeight: '40px',
        fontSize: '16px',
        fontWeight: 'bold',
        marginLeft: '15px'
    }
}));

export {useStyles}
