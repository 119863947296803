import Highcharts from 'highcharts';
import { theme } from '../../../helpers/muiTheme.jsx';
import { getTimeFormatterHhMm } from './timeManipulations';

export const GRAPH_POINT_COLOR = theme.palette.graph.point;

export const GRAPH_BIG_CIRCLE_COLOR = theme.palette.graph.outerCircle;
export const OVER_THRESHOLD_COLOR = theme.palette.graph.aboveThreshold;
export const UNDER_THRESHOLD_COLOR = theme.palette.graph.belowThreshold;
const MOUSE_HOVER_LINE_COLOR = 'rgba(0, 0, 0, 0.3)';
const THRESHOLD_COLOR = 'rgba(0, 0, 0, 0.4)';
const THRESHOLD_WIDTH = 1;
const MOUSE_HOVER_LINE_WIDTH = 1;
const GRADIENT_FIRST_COLOR = 'rgba(0, 89,173,0.6)';
const GRADIENT_SECOND_COLOR = 'rgba(0,89,173,0)';
const POINTS_DENSE_SIZE = 5;
const POINTS_RADIUS = 3;
export const TOOLTIP_HIDE_DELAY = 0;
export const TOOLTIP_SNAP = 5000;
export const RESET_BUTTON_TEXT = 'Reset zoom';
export const DROPDOWN_DAY_VALUE = '1440';
export const DROPDOWN_12_HOURS_VALUE = '720';
export const DROPDOWN_4_HOURS_VALUE = '240';
export const DROPDOWN_HOUR_VALUE = '60';
export const DROPDOWN_30_MINUTES_VALUE = '30';
export const DROPDOWN_CUSTOM_VALUE = 'CUSTOM';
export const DROPDOWN_CUSTOM_LABEL = 'Custom';

export const DROPDOWN_DAY_LABEL = 'Last day';
export const DROPDOWN_12_HOURS_LABEL = 'Last 12 hours';
export const DROPDOWN_4_HOURS_LABEL = 'Last 4 hours';
export const DROPDOWN_30_MINUTES_LABEL = 'Last 30 minutes';
export const DROPDOWN_HOUR_LABEL = 'Last hour';
export const DROPDOWN_ITEMS = [
	{ label: DROPDOWN_30_MINUTES_LABEL, value: DROPDOWN_30_MINUTES_VALUE },
	{ label: DROPDOWN_HOUR_LABEL, value: DROPDOWN_HOUR_VALUE },
	{ label: DROPDOWN_4_HOURS_LABEL, value: DROPDOWN_4_HOURS_VALUE },
	{ label: DROPDOWN_12_HOURS_LABEL, value: DROPDOWN_12_HOURS_VALUE },
	{ label: DROPDOWN_DAY_LABEL, value: DROPDOWN_DAY_VALUE },
];
export const OVER_HALO_COLOR = 'rgba(240,95,90,0.3)';
export const UNDER_HALO_COLOR = 'rgba(36, 187, 99, 0.3)';
export const RING_PADDING_COLOR = '#FFFFFF';
export const CHART_TOOLTIP_PADDING_END = 50;
export const CHART_TOOLTIP_PADDING_START = 40;
export const RING_RADIUS = 7;
export const HOVER_PADDING_RADIUS = 4;
export const CLICK_PADDING_RADIUS = 5;
export const CIRCLE_RADIUS = 3;
export const STARTING_CIRCLE_OPACITY_ANIMATION = 0;
export const MINUTES_IN_HOUR = 60;
export const GRAPH_HIGH_DELTA = 0.1;
export const DRAG_ZOOM_EVENT = 'zoom';

export const graphOptions = (
	{
		data,
		threshold,
		maxDate,
		minDate,
		onZoom,
		onMouseHover,
		onClickPoint,
		setPosition,
		overrides,
	}) => ({ ...{
	chart: {
		animation: false,
		spacingRight: 0,
		spacingLeft: 0,
		resetZoomButton: {
			theme: {
				style: {
					display: 'none', // Remove default reset button
				},
			},
		},
		...onZoom ? zoomTypeOnZoom() : {},
	},
	title: {
		text: '',
		align: 'left',
	},
	subtitle: {
		text: '',
		align: 'left',
	},
	xAxis: {
		type: 'datetime',
		max: maxDate,
		min: minDate,
		labels: {
			formatter() {
				if (new Date(this.value).getUTCHours() === 0) {
					return Highcharts.dateFormat('%e %b', this.value);
				}

				return Highcharts.dateFormat('%H:%M', this.value);
			},
		},
		crosshair: { // Mouse hover line
			width: MOUSE_HOVER_LINE_WIDTH,
			color: MOUSE_HOVER_LINE_COLOR,
			dashStyle: 'solid',
		},
		...onZoom ? xAxisEventsOnZoom(onZoom) : {},
	},
	yAxis: {
		...threshold ? { plotLines: [
			{
				value: threshold,
				color: THRESHOLD_COLOR,
				dashStyle: 'shortDash',
				width: THRESHOLD_WIDTH,
			},
		] } : {},
		title: {
			text: '',
		},
		labels: {
			formatter() {
				return Highcharts.numberFormat(this.value / 1000, 0, '.', ',') + 'k';
			},
		},
	},
	legend: {
		enabled: false,
	},
	plotOptions: {
		area: {
			fillColor: {
				linearGradient: {
					x1: 0,
					y1: 0,
					x2: 0,
					y2: 1,
				},
				stops: [
					[
						0,
						GRADIENT_FIRST_COLOR,
					],
					[
						1,
						GRADIENT_SECOND_COLOR,
					],
				],
			},
			states: {
				hover: {
					lineWidth: 1,
					halo: {
						size: 0, // Remove hover shadow
					},
				},
			},
			marker: {
				enabledThreshold: POINTS_DENSE_SIZE, // When dense to show the dots on the graph, larger number is deeper
				radius: POINTS_RADIUS,
				lineColor: GRAPH_POINT_COLOR,
				states: {
					hover: {
						radius: 1, // Smaller so the hover point will show right
					},
				},
			},
		},
		series: {
			marker: {
				states: {
					normal: {
						animation: false, // Remove hover shrink animation
					},
				},
			},
			color: GRAPH_POINT_COLOR,
			point: {
				events: {
					mouseOver: onMouseHover,
					...(onClickPoint ? { click: e => onClickPoint(e.point) } : {}),
				},
			},
		},
	},
	series: [
		{
			type: 'area',
			name: 'Value',
			data,
		},
	],
	tooltip: {
		enabled: true,
		snap: TOOLTIP_SNAP, // Somehow fix click bug
		useHTML: true,
		hideDelay: TOOLTIP_HIDE_DELAY,
		backgroundColor: 'transparent',
		positioner: setPosition,
		formatter() {
			const time = getTimeFormatterHhMm(this.x);
			const value = Math.round(this.y / 1000) + 'k';
			const dotColor = this.y > threshold ? OVER_THRESHOLD_COLOR : UNDER_THRESHOLD_COLOR;
			const dot = `<span style="color:${dotColor}; font-weight:bold;margin-right:4px">&#9679;</span>`;
			const valueString = `<span style="margin-right: 7px; font-weight: 600">${value}</span>`;
			const style = `color: #425262; background-color: #ffffff; padding: 4px 6px 4px 6px;
			 box-shadow: -1px 1px 7px 1px rgba(133, 133, 133, 0.25); height: 24px;
			 border-radius: 5px; font-family: 'Source Sans Pro';font-size: 14px;line-height: 16px;`;

			return `<div style="${style}">${dot}${valueString}${time}</div>`;
		},
	},
}, ...{ overrides },
});
const zoomTypeOnZoom = () => ({ zoomType: 'x' });
const xAxisEventsOnZoom = onZoom => ({ events: { setExtremes: onZoom } });
