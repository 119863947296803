import action from "../middleware";
import http from "../../../../../services/httpService/http";
import {USER_URI} from "../../services/urls";

export const REQ_USER = "REQ_USER";
export const REQ_USER_SUCCESS = "REQ_USER_SUCCESS";
export const REQ_USER_FAILURE = "REQ_USER_FAILURE";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const NODATA_USER_FAILURE = "NODATA_USER_FAILURE";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";

const getUsers = () => {
    return action(async dispatch => {
        dispatch({ type: REQ_USER });
        try {
            // const project = await http.get(`users`);
            //const users = await mockPromise(makeData());
            const users = await http.get(USER_URI);
            if (users.error) {
                dispatch({
                    type: REQ_USER_FAILURE,
                    payload: users.error.message || 'Users load failed.'
                });
            } else {
                dispatch({
                    type: REQ_USER_SUCCESS,
                    payload: users
                });
            }
        } catch (e) {
            dispatch({
                type: REQ_USER_FAILURE,
                payload: e.message || 'Users load failed.'
            });
        }
    });
};
const inviteUser = (newUser) => {
    return action(async dispatch => {
        dispatch({ type: REQ_USER });
        try {
            let result = await http.post(`${USER_URI}/create`, newUser);
            return result;
        } catch (e) {
            dispatch({
                type: INVITE_USER_FAILURE,
                payload: e.message || 'User invite failed.'
            });
        }
    });
};
const editUser = (user) => {
    return action(async dispatch => {
        dispatch({ type: REQ_USER });
        try {
            //console.log(`user`);
            //console.log(user);
            await http.put(`${USER_URI}/${user._id}`, user);
            dispatch({
                type: EDIT_USER_SUCCESS,
                payload: user
            });
        } catch (e) {
            dispatch({
                type: REQ_USER_FAILURE,
                payload: e.message || 'Users save failed.'
            });
        }
    });
};
const removeUser = (user) => {
    return action(async dispatch => {
        dispatch({ type: REQ_USER });
        try {
            await http._delete(`${USER_URI}/${user._id}`, user);
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: user._id,
            });
        } catch (e) {
            dispatch({
                type: NODATA_USER_FAILURE,
                payload: e.message || 'Delete failed.'
            });
        }
    });
}



const updateForm = (payload) => {
    return async dispatch => {
        try {
            dispatch({ type: UPDATE_FORM_DATA, payload });
        } catch (e) {
            console.log(e)
        }
    }
}

export {
    inviteUser,
    editUser,
    getUsers,
    removeUser,
    updateForm
}
