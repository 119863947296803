import action from "../../../app/controller/redux/middleware";

export const SELECT_POINT = "SELECT_POINT";
export const REMOVE_EVENT_POINT = "REMOVE_EVENT_POINT"; 

const selectPointOnGraph = (newPoint , event) => {
    return action(async (dispatch, getState) => {
         
        dispatch({
            type: SELECT_POINT,
            payload: { newPoint , event }
        });
    });
};




export {
    selectPointOnGraph, 
}
