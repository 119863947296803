import PropTypes from 'prop-types';
import { useStyles } from '../mfaSelector/mfaSelector.css';
import React, { useState } from 'react';
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import {
	CONFIGURE_SMS_STEP_NUMBER, CONFIGURE_SMS_STEP_SUBTITLE,
} from '../stepsTexts';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import SetupPhone from '../../setupPhone/setupPhone';

const ConfigureSmsMfa = ({ configurePhoneAsMfa }) => {
	const classes = useStyles();
	const [isVerified, setIsVerified] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handlePhoneSetup = () => {
		setIsVerified(true);
	};

	const handleSetupMfaMethod = async () => {
		setIsLoading(true);
		await configurePhoneAsMfa();
		setIsLoading(false);
	};

	return (
		<Paper elevation={0} className={classes.paper}>
			<Grid container direction={'column'} className={classes.container}>
				<Grid item xs={2} className={classes.fullWidth}>
					<Typography style={{ fontWeight: 'bold' }}>
						{CONFIGURE_SMS_STEP_NUMBER}
					</Typography>
					<Typography>
						{CONFIGURE_SMS_STEP_SUBTITLE}
					</Typography>
				</Grid>
				<Grid item xs={8} className={classes.fullWidth}>
					<SetupPhone onSuccessCallback={handlePhoneSetup}/>
				</Grid>
				<Grid xs={1} className={classes.fullWidth}>
					{isLoading
						? <CircularProgress color="primary" className={classes.circularProgress} />
						: <StyleButton label={'Set-up MFA'} className={classes.setUpButton} isDisabled={!isVerified} onClick={handleSetupMfaMethod} />}
				</Grid>
			</Grid>
		</Paper>);
};

export default ConfigureSmsMfa;

ConfigureSmsMfa.propTypes = {
	configurePhoneAsMfa: PropTypes.func,
};
