import React, { Component } from 'react';
import { connect } from 'react-redux';

import Process from '../process/process'
import NewProcess from '../process/newProcess';
import RemoveAllProcess from '../process/removeAllProcess'
import { removeBackgroundProcess, getProcessByToken, renameBackgroundProcess } from '../../../controller/redux/backgroundProcessAction';
import _ from 'lodash';

class BackgroundProcessBottomPart extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    isRenamingItem = async (wItem, newName) => {
        await this.props.renameBackgroundProcess(wItem, newName)
        this.setState({ 'key': 'value' })

    }

    isRemovingItem = async (value) => {
        await this.props.removeBackgroundProcess(value.workItemId)
        this.setState({ 'key': value })
    }
    render() {
        const { currentProcess , processes} = this.props;
        return (!_.isEmpty(processes) ? <div className="background-process-container background-process-content" >
            { processes ? processes.map((p, index) => {
                return <Process
                    isRenamingItem={ this.isRenamingItem }
                    isRemovingItem={ this.isRemovingItem }
                    { ...p } key={ index }
                    object={ p } index={ index } />
            }) : null }

            { currentProcess ? <section style={ { display: 'flex' } }><NewProcess /><RemoveAllProcess /></section> : null }
        </div> : null);
    }
}

const mapStateToProps = state => {
    return {
        currentProcess: state.backgroundProcesses.currentProcess,
        processes: state.backgroundProcesses.processes
    };
};
const mapDispatchToProps = {
    removeBackgroundProcess,
    getProcessByToken,
    renameBackgroundProcess
};
export default connect(mapStateToProps, mapDispatchToProps)(BackgroundProcessBottomPart); 
