const REACT_APP_CLIENT_ID_CYNAMICS_DEV = '39b91970mfpb6jebogp339jqnu';
const REACT_APP_CLIENT_ID_CYNAMICS_EU = '6107n2q5btmabo5p7oihg6858s';
const REACT_APP_CLIENT_ID_CYNAMICS_PROD = '6q2raa3efd8fnj776s8nclegja';
const REACT_APP_CLIENT_ID_CYNAMICS_GOV = '2s1583pe4i3qdpcgpia1febv2o';

export default {
	[REACT_APP_CLIENT_ID_CYNAMICS_DEV]: {
		hostedUi: 'https://app.dev-auth.cynamics.net',
		clientId: REACT_APP_CLIENT_ID_CYNAMICS_DEV,
		scope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
		responseType: 'code',
	},
	[REACT_APP_CLIENT_ID_CYNAMICS_EU]: {
		hostedUi: 'https://app.eu-auth.cynamics.net',
		clientId: REACT_APP_CLIENT_ID_CYNAMICS_EU,
		scope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
		responseType: 'code',
	},
	[REACT_APP_CLIENT_ID_CYNAMICS_PROD]: {
		hostedUi: 'https://app.auth.cynamics.net',
		clientId: REACT_APP_CLIENT_ID_CYNAMICS_PROD,
		scope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
		responseType: 'code',
	},
	[REACT_APP_CLIENT_ID_CYNAMICS_GOV]: {
		hostedUi: 'https://gov-cynamics-app-idp.auth-fips.us-gov-west-1.amazoncognito.com',
		clientId: REACT_APP_CLIENT_ID_CYNAMICS_GOV,
		scope: 'aws.cognito.signin.user.admin+email+openid+phone+profile',
		responseType: 'code',
	},

};
