import _ from 'lodash';
import { findQuery } from './mappers';
import { parseAnalyzedData } from './parseAnalyzedData';
import parseAwsPayload from '../../../cloudFilter/requestParser';

const parseProcessBackground = res => {
	try {
		const analyseResponse = {};
		let query = {};
		const receivedPayload = JSON.parse(res.originalQuery);

		if (!_.isEmpty(res.originalQuery)) {
			query = parseOriginalQuery(receivedPayload);
		}

		if (res.status !== 'ERROR') {
			if (!_.isEmpty(res.analysisResponse)) {
				const resAnalysis = res.analysisResponse;
				const analysisObj = JSON.parse(resAnalysis);

				analyseResponse.analyzed_df = JSON.parse(analysisObj.analyzed_df);
				analyseResponse.mid_attacks = JSON.parse(analysisObj.mid_attacks);
				analyseResponse.high_attacks = JSON.parse(analysisObj.high_attacks);
				analyseResponse.message = analysisObj.message;
				analyseResponse.attack_status = parseInt(analysisObj.attack_status);

				const hasData = Boolean(Object.values(analyseResponse.analyzed_df).length);
				const analyzedData = { hasData };
				if (hasData) {
					analyseResponse.analyzedData = { ...analyzedData, ...parseAnalyzedData(hasData, query, analyseResponse) };
				}
			}
		}

		return { ...res, analysisResponse: analyseResponse, originalQuery: query };
	} catch (error) {
		console.log(error);
	}
};

const parseOriginalQuery = receivedPayload => {
	const query = {};
	const reqPayload = receivedPayload.extra;
	query.ipsDestination = [].concat(reqPayload.includedIpsDest ? reqPayload.includedIpsDest : [])
		.concat(reqPayload.excludedIpsDest ? reqPayload.excludedIpsDest : []);

	query.ipsSource = [].concat(reqPayload.includedIpsSrc ? reqPayload.includedIpsSrc : [])
		.concat(reqPayload.excludedIpsSrc ? reqPayload.excludedIpsSrc : []);
	query.dataTypeOptions = findQuery(receivedPayload.queryTypes[0]);
	query.deviceSelected = receivedPayload.devices;
	query.timeWindow = receivedPayload.timeWindow;

	const { typeproto } = reqPayload;
	if (typeproto) {
		query.typeproto = {
			isIn: typeproto.isIn, isOut: typeproto.isOut,
		};
	}

	const { firewall } = reqPayload;
	if (firewall) {
		query.firewall = {
			isFWE_ALLOW: firewall.isFWE_ALLOW,
			isFWE_DENY: firewall.isFWE_DENY,
		};
	}

	query.xDomain = [receivedPayload.fromTimestamp, receivedPayload.toTimestamp];
	query.ipProtocols = receivedPayload.ipProtocols;
	query.typeproto = reqPayload.typeproto;
	query.ipWhoIs = receivedPayload.ipWhoIs;
	query.tcpFlags = receivedPayload.tcpFlags;
	query.interfaces = receivedPayload.interfaces;
	query.srcPorts = receivedPayload.srcPorts;
	query.destPorts = receivedPayload.destPorts;
	if (!_.isEmpty(receivedPayload.cloudAws)) {
		query.aws = parseAwsPayload(receivedPayload.cloudAws);
	}

	if (receivedPayload.vlan) {
		const { vlanIdBidirectionalTraffic, ...rest } = receivedPayload.vlan;
		query.vlan = rest;
		query.vlanIdBidirectionalTraffic = vlanIdBidirectionalTraffic;
	}

	return query;
};

export { parseProcessBackground, parseOriginalQuery };
