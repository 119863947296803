import React, { Component } from 'react';
import renderField from './render';
import { isValidInt } from '../../../../containers/alertsManagement/controller/validations/fieldValidation';
import { Field } from 'redux-form';

class ThresholdField extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {

        return (
            <Field
                name="thresholdValue"
                type="text"
                component={ renderField }
                warn={ isValidInt }
            />
        );
    }
}



export default ThresholdField;