import greenIndicator from "../../../../assets/animations/GreenDotAnimated.png";
import yellowIndicator from "../../../../assets/animations/YellowDotAnimated.png";
import redIndicator from "../../../../assets/animations/RedDotAnimated.png";

import statusWarningIcon from '../../../../assets/icons/yellow-alert.svg';
import statusDangerIcon from '../../../../assets/icons/red-alert.svg';
import statusNormalIcon from '../../../../assets/icons/devices.svg';

import statusCollectorNormalIcon from '../../../../assets/icons/collectorIcon.svg';

const deviceStatusIcons = [
    { severity: 'normal', asset: greenIndicator, status: 0 },
    { severity: 'medium', asset: yellowIndicator, status: 1 },
    { severity: 'high', asset: redIndicator, status: 2 }
];


const statusIconsDevices = {
    0: statusNormalIcon,
    1: statusWarningIcon,
    2: statusDangerIcon
};

const statusIconsCollector = {
    0: statusCollectorNormalIcon,
    1: statusWarningIcon,
    2: statusDangerIcon
};


export { 
    deviceStatusIcons, 
    statusIconsDevices, 
    statusIconsCollector
}