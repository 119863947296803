export const INCLUDED_SRC_VLAN_ID_KEY = 'includedSrcVlanId';
export const EXCLUDED_SRC_VLAN_ID_KEY = 'excludedSrcVlanId';

export const INCLUDED_DST_VLAN_ID_KEY = 'includedDstVlanId';
export const EXCLUDED_DST_VLAN_ID_KEY = 'excludedDstVlanId';

export const VLAN_ID_BIDIRECTIONAL_TRAFFIC = 'vlanIdBidirectionalTraffic';

export const INCLUDED_VXLAN_ID_KEY = 'includedVXLanId';
export const EXCLUDED_VXLAN_ID_KEY = 'excludedVXLanId';
