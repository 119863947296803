import http from "../../../../services/httpService/http";
import {WEEKLY_REPORT_PATH} from "../constants/urls";

const getWeeklyReportIntegration = (config) => {
    return http.get(WEEKLY_REPORT_PATH, config);
}
const activateWeeklyReportIntegration = (weeklyReportPolicyId, newValue) => {
    return http.put(`${WEEKLY_REPORT_PATH}/toggle/${weeklyReportPolicyId}`, {Enabled: newValue});
}

const createWeeklyReportPolicy = (payload) => {
    return http.post(WEEKLY_REPORT_PATH, payload);
}

const updateWeeklyReportPolicy = (reportPolicyId, toUpdateObject) => {
    return http.put(`${WEEKLY_REPORT_PATH}/${reportPolicyId}`, toUpdateObject);
}

export {
    getWeeklyReportIntegration,
    activateWeeklyReportIntegration,
    createWeeklyReportPolicy,
    updateWeeklyReportPolicy
}