import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	tabsContainer: {
		borderBottom: '1px solid #DBDFE0',
	},
	tabIndicator: {
		height: '2px',
		fontWeight: 700,
		backgroundColor: '#7287A5',
		float: 'left',
	},
	tab: {
		fontWeight: 700,
		fontSize: '18px',
		lineHeight: '16px',
		textTransform: 'none',
		color: theme.palette.secondary.main,
		opacity: 0.7,
		marginRight: '65px',
	},
	hoveredTab: {
		'&:hover': {
			backgroundColor: 'rgba(7,28,54,0.1)',
		},
	},
	tabContent: {
		marginTop: '34px',
		height: '95%',
	},
	tooltipContent: {
		maxWidth: '12.5em',
		wordWrap: 'break-word',
	},
}));

export default useStyles;
