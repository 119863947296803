/* eslint-disable react/prop-types */
import { ListItem, ListItemText } from '@material-ui/core';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import SimpleField from '../../../shared/components/simpleTable/fields/simpleField';
import ActionsField from './advancedCells/actionsField/actionsField';
import PopoverField from './advancedCells/popoverField/popoverField';

import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as XIcon } from '../../../assets/icons/x.svg';
import TooltipField from "../../../shared/components/simpleTable/fields/tooltipField";

const LIST_HEADER_STYLE = { paddingTop: '8px', paddingBottom: 0 };
const LIST_ITEM_STYLE = { paddingTop: 0, paddingBottom: 0 };

const DividedListLine = ({ value }) => (
	<>
		<ListItem style={LIST_ITEM_STYLE}>
			<ListItemText primary={value} />
		</ListItem>
	</>
);

DividedListLine.propTypes = {
	value: PropTypes.string,
};

const SubList = ({ title, content }) => (
	<>
		<ListItem style={LIST_HEADER_STYLE}>
			<ListItemText disableTypography primary={capitalize(title)} style={{ fontWeight: 600 }} />
		</ListItem>
		{Array.isArray(content) ? (
			content.map((value, index) => <DividedListLine key={index} value={value} />)
		) : (
			<ListItem style={LIST_ITEM_STYLE}>
				<ListItemText primary={content}/>
			</ListItem>
		)}
	</>
);

SubList.propTypes = {
	content: PropTypes.string,
	title: PropTypes.string,
};

class FieldsFactory {
	createTextField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => <SimpleField alias={alias} className={className} filterable={filterable} />,
			Cell: ({ value }) => (value ? value : '-'),
		};
	}

	createTooltipTextField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, mapper, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => <SimpleField alias={alias} className={className} filterable={filterable} />,
			Cell: ({ value }) => {
				const containerRef = useRef();
				let displayTooltip = false;
				if (!value || value.length === 0) {
					return '-';
				}

				if(containerRef.current){
					const width = containerRef.current.offsetWidth;
					const scrollWidth = containerRef.current.scrollWidth;
					displayTooltip = width < scrollWidth;
				}

				return(<div style={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					}} ref={containerRef}>
						<TooltipField useTooltip={displayTooltip} text={value} />
				</div>
				)
			},
		};
	}

	createMultipleValuesField(
		accessor,
		alias,
		label,
		filterable = true,
		allowView = true,
		allowEdit = true,
		show = true,
		mapper = null,
		className = null,
	) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => <SimpleField alias={alias} className={className} filterable={filterable} />,
			Cell({ value }) {
				if (!value || value.length === 0) {
					return '-';
				}

				if (mapper) {
					value = mapper(value);
				}

				if (value.length === 1) {
					return value;
				}

				return (
					<PopoverField title={label} contentTitle={alias}>
						{value.map((currentValue, index) => (
							<DividedListLine key={index} value={currentValue} withDivider={index !== currentValue.length - 1} />
						))}
					</PopoverField>
				);
			},
		};
	}

	createCheckboxField(accessor, alias, filterable = true, allowView = true, allowEdit = true, show = true, className = null) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			type: 'text',
			Header: () => <SimpleField alias={alias} className={className} filterable={filterable} />,
			Cell: ({ value }) => (value ? <CheckIcon /> : '-'),
		};
	}

	createAdditionalInfoField(
		accessor,
		alias,
		filterable = true,
		allowView = true,
		allowEdit = true,
		show = true,
		mapper = null,
		className = null,
		labelTransformer = {},
	) {
		return {
			accessor,
			alias,
			filterable,
			allowView,
			allowEdit,
			show,
			className,
			labelTransformer,
			type: 'text',
			Header: () => <SimpleField alias={alias} className={className} filterable={filterable} />,
			Cell({ value }) {
				if (!value || Object.values(value).every(property => property.length < 1 || !property)) {
					return '-';
				}

				if (mapper) {
					value = mapper(value);
				}

				return (
					<PopoverField title="Additional Info" contentTitle={alias}>
						{Object.keys(value).map((key, index) => (<SubList key={index} title={labelTransformer[key] || key} content={value[key]} />))}
					</PopoverField>
				);
			},
		};
	}

	createActionsField(accessor, editHandler, deleteHandler, toggleHandler, className = 'alignEnd') {
		return {
			accessor,
			alias: '',
			filterable: false,
			allowView: false,
			allowEdit: false,
			show: true,
			resizable: false,
			className,
			type: 'actions',
			Header: () => <SimpleField alias="" className={className} filterable={false} />,
			Cell({ original, value }) {
				return (
					<ActionsField
						rule={original}
						isEnabled={value}
						onEdit={editHandler}
						onDelete={deleteHandler}
						onToggle={toggleHandler}
					/>
				);
			},
		};
	}
}

export default new FieldsFactory();
