import React, {Component} from 'react';
import {Typography} from "@material-ui/core";
import visa from '../../../assets/payment/visa.svg'
import americanExpress from '../../../assets/payment/amex.svg'
import diners from '../../../assets/payment/combined-shape.svg'
import mastercard from '../../../assets/payment/mastercard.svg'
import jcb from '../../../assets/payment/jcb.svg'
import unionPay from '../../../assets/payment/union-pay.svg'
import discover from '../../../assets/payment/discover.svg'
import DefaultCreditCardIcon from "@material-ui/icons/CreditCard";


// as expected from api
const VISA_CREDIT_CARD_TYPE = "visa";
const DINERS_CREDIT_CARD_TYPE = "diners";
const MASTERCARD_CREDIT_CARD_TYPE = "mastercard";
const AMERICAN_EXPRESS_CREDIT_CARD_TYPE = "amex";
const DISCOVER_CREDIT_CARD_TYPE = "discover";
const JCB_CREDIT_CARD_TYPE = "jcb";
const UNION_PAY_CREDIT_CARD_TYPE = "unionpay";

const CreditCardIcon = (icon) => <img width={"69px"} height={"24px"} alt={"credit card icon"} src={icon}/>
const JCBCreditCardIcon = (icon) => <img alt={"credit card icon"} src={icon}/>
const NoCreditIcon = () => <DefaultCreditCardIcon />

const CreditCardOptions = {
    [VISA_CREDIT_CARD_TYPE]: {
        icon: () => CreditCardIcon(visa)
    },
    [DINERS_CREDIT_CARD_TYPE]: {
        icon: () => CreditCardIcon(diners)
    },
    [MASTERCARD_CREDIT_CARD_TYPE]: {
        icon: ()=> CreditCardIcon(mastercard)
    },
    [AMERICAN_EXPRESS_CREDIT_CARD_TYPE]: {
        icon: ()=> CreditCardIcon(americanExpress)
    },
    [DISCOVER_CREDIT_CARD_TYPE]: {
        icon: ()=> CreditCardIcon(discover)
    },
    [JCB_CREDIT_CARD_TYPE]: {
        icon: ()=> JCBCreditCardIcon(jcb)
    },
    [UNION_PAY_CREDIT_CARD_TYPE]: {
        icon: ()=> CreditCardIcon(unionPay)
    },
    default: {
        icon: ()=> NoCreditIcon()
    }
}


const getCreditCardData = (ccType) => {
    if(CreditCardOptions[ccType]){
        return CreditCardOptions[ccType];
    }else{
        return CreditCardOptions.default;
    }
}

export {CreditCardOptions, getCreditCardData}