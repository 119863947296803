

function getValue(obj, spec) {
    let path = typeof spec == 'object' ? spec.path : spec;
    if (!path)
        return null;
    let defaultVal = typeof spec == 'object' ? spec.default : null;

    return _getValue(obj, path.split('.'), defaultVal);
}

let _getValue = (obj, path, defaultVal) => {
    if (typeof obj == 'undefined' || obj == null)
        return defaultVal;
    if (path.length == 1)
        return typeof obj[path] == 'undefined' || obj[path] == null || obj[path] == ''  ? defaultVal : obj[path];

    return _getValue(obj[path[0]], path.slice(1), defaultVal);
};

let setValue = (obj, path, value) =>{
    _setValue(obj, path.split('.'), value);
};

let _setValue = (obj, path, value) =>{
    let field = path[0];
    if (path.length == 1)
        return obj[path] = value;
    if (typeof obj[field] == 'undefined')
        obj[field] = {};

    _setValue(obj[path[0]], path.slice(1), value);
}

const transformNumber = number => {
    if (isNaN(number)) return null;

    const biggerThanThousand = number > 1000;
    const biggerThanMillion = number > 1000000;

    let transformed = String(Number(number.toFixed(2)));

    if (biggerThanThousand && !biggerThanMillion)
        transformed = String(Number((number / 1000).toFixed(1))) + 'K';

    if (biggerThanMillion)
        transformed = String(Number((number / 1000000).toFixed(1))) + 'M';

    return transformed
};

export {
    getValue,
    setValue,
    transformNumber,
}
