import PropTypes from 'prop-types';
import React from 'react';
import { useStyles } from './enrichmentPopoverView.css';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { dataKeysDisplayMappings } from '../enrichmentMappings';

function EnrichmentPopoverView({ isOpen, anchorEl, handleClose, fieldsToDisplay, data, isLoading }) {
	const classes = useStyles();

	return (
		<Popover open={isOpen} onClose={handleClose} anchorEl={anchorEl} anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'left',
		}} PaperProps={{ className: classes.popoverPaperProps }}>
			<div className={classes.wrapper}>
				{
					fieldsToDisplay.map((field, index) => (
						<Grid key={`popover${index}`} container direction="row" className={classes.rowContainer} xs={12}>
							<Grid item xs={6} >
								<Typography className={classes.title}>{dataKeysDisplayMappings[field]}</Typography>
							</Grid>
							<Grid item xs={6} >
								<Typography>{isLoading ? <Skeleton className={classes.skeleton}/> : (data[field] || '-')}</Typography>
							</Grid>
						</Grid>
					))
				}
			</div>
		</Popover>
	);
}

EnrichmentPopoverView.propTypes = {
	anchorEl: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
	data: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	fieldsToDisplay: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default EnrichmentPopoverView;
