import {useStyles} from "./mainPanelTemplateV6.css";
import {Grid,Typography} from "@material-ui/core";
import React from "react";

const MainPanelTemplateV6 = ({title, image, topDescription, bottomDescription}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.title}>
                    { title }
                </Typography>
                <div className={classes.contentWrapper}>
                    <div className={classes.image}>
                        { image }
                    </div>
                    <div className={classes.description}>
                        <Typography className={classes.topDescription}>
                            { topDescription }
                        </Typography>
                        <Typography className={classes.bottomDescription}>
                            { bottomDescription }
                        </Typography>
                    </div>
                </div>
            </Grid>
        </Grid>
    )
}

export default MainPanelTemplateV6;