import ReactGA from 'react-ga'
import {useEffect, useState} from "react";
import {TRACKING_CODE, SITE_PAGES_SAMPLING_RATE, SUBMIT_ACTION} from "./analyzerConstants"
import {isFilledTcpFlags} from "../shared/utils/formToPayload";
import conf from '../config';


// Current it is a stub for the future.
// In future maybe will be option to disable from privacy reasons the tracing for specific user.
// F.E. Flag from DB
const trackUser = true;

const initialize = () =>{
    trackUser && ReactGA.initialize(conf.googleAnalyticsTrackingCode, {gaOptions: {
        siteSpeedSampleRate: SITE_PAGES_SAMPLING_RATE
    }})
}

const pageView = (path) => {
    trackUser && ReactGA.pageview(path);
}

const event = (...eventArgs) => {
    trackUser && ReactGA.event(...eventArgs)
}

const timing = (...eventArgs) => {
    trackUser && ReactGA.timing(...eventArgs);
}

const useTimingEvent = (category,
                        label,
                        timingVarName) => {
    const [startTime, setStartTime] = useState(Date.now());

    const start = () => {
        setStartTime(Date.now());
    }

    const stop = (status=null) => {
        let timeDiff = Date.now() - startTime; //in ms
        let milliseconds = Math.round(timeDiff);
        timing( {category: category,
                variable: status || timingVarName,
                value: milliseconds,
                label: label})
    }

    return{
        start,
        stop
    }
}

const trackQuery = (queryPayload, category, action = SUBMIT_ACTION) => {
    try {
        if (queryPayload.extra.excludedIpsDest.length > 0 || queryPayload.extra.includedIpsDest.length > 0){
            event({category: category, action: action, label: 'IPS_DESTINATION_INPUTS'})
        }

        if (queryPayload.extra.excludedIpsSrc.length > 0 || queryPayload.extra.includedIpsSrc.length > 0 ){
            event({category: category, action: action, label: 'IPS_SOURCE_INPUTS'})
        }

        queryPayload.devices.length > 0 &&
            event({category: category, action: action, label: 'DEVICES_INPUTS'})

        if(queryPayload.extra.firewall && (queryPayload.extra.firewall.isFWE_ALLOW || queryPayload.extra.firewall.isFWE_DENY) )
            event({category: category, action: action, label: 'IPS_FIREWALL_CHECKBOXES'})

        queryPayload.interfaces && Object.keys(queryPayload.interfaces).length !== 0 &&
            event({category: category, action: action, label: 'INTERFACES_INPUTS'})

        queryPayload.tcpFlags && Object.keys(queryPayload.tcpFlags).length !== 0 &&
            event({category: category, action: action, label: 'TCP_FLAGS_CHECKBOXES'})

        queryPayload.extra.typeproto && Object.values(queryPayload.extra.typeproto).some((protocolType) => protocolType===true) &&
            event({category: category, action: action, label: 'PROTOCOLS_TYPES_CHECKBOXES'})

        Object.values(queryPayload.ipWhoIs).some((element) => element.length > 0) &&
            event({category: category, action: action, label: 'GEOGRAPHY_ORIGIN_INPUTS'})
    } catch (e) { }
}
export default {
    initialize,
    pageView,
    event,
    useTimingEvent,
    trackQuery
}