import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useStyles from './groupsPage.css';
import CardContainer from '../cardContainer/CardContainer';
import { Grid } from '@material-ui/core';
import { addGroup, deleteGroup as deleteGroupRequest, updateGroup } from '../../controller/services/requests';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';
import DeleteModal from '../../../../shared/components/deleteModal/deleteModal';
import {
	ALERT_LINK_TO_GROUP_DELETE_ERROR,
	DELETE_ERROR,
	DELETE_SUCCEEDED,
	OPERATION_ERROR_STATUS_CODE,
	SAVE_ERROR,
	SAVE_SUCCEEDED,
} from './groupPageConfig';

const GroupsPage = ({
	data,
	loadGroups,
	groupModalComponent: GroupModal,
	isAddButtonClicked,
	setIsAddButtonClicked,
	removeConfirmationMessage,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const classes = useStyles();

	useEffect(() => {
		if (!isEditMode) {
			setSelectedGroup({});
		}
	}, [isEditMode]);

	useEffect(() => {
		if (isAddButtonClicked) {
			setIsEditMode(false);
			setIsModalOpen(true);
			setIsAddButtonClicked(false);
		}
	}, [isAddButtonClicked]);

	const handleCloseModal = async () => {
		setIsModalOpen(false);
	};

	const handleEditGroup = item => {
		setSelectedGroup(item);
		setIsEditMode(true);
		setIsModalOpen(true);
	};

	const handleClickDeleteGroup = group => {
		setSelectedGroup(group);
		setIsDeleteModalOpen(true);
	};

	const deleteGroup = async () => {
		try {
			await deleteGroupRequest(selectedGroup.objectGroup.objectId);
			setIsDeleteModalOpen(false);
			dispatch(openSnackbar('success', DELETE_SUCCEEDED, 4000));
			await loadGroups();
		} catch (e) {
			dispatch(
				openSnackbar(
					'error',
					e.status === OPERATION_ERROR_STATUS_CODE
						? ALERT_LINK_TO_GROUP_DELETE_ERROR
						: DELETE_ERROR,
					4000,
				),
			);
		}
	};

	const handleSaveGroup = async (isEditMode, groupParams) => {
		try {
			setIsLoading(true);
			if (isEditMode) {
				await updateGroup(selectedGroup.objectGroup.objectId, groupParams);
			} else {
				await addGroup(groupParams);
			}

			dispatch(openSnackbar('success', SAVE_SUCCEEDED, 4000));
			loadGroups();
		} catch {
			dispatch(openSnackbar('error', SAVE_ERROR, 4000));
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			<Grid container className={classes.container}>
				<Grid xs={12} className={classes.groupsContainerSection}>
					{/* TODO: 'Legacy' section (divs), className drilling into cardContainer should be replaced */}
					<div className="group-top-segments ipgroup-overlay">
						<div className={'card-container-page'}>
							{data && (
								<CardContainer
									groups={data}
									onEditGroup={handleEditGroup}
									onDeleteGroup={handleClickDeleteGroup}
								/>
							)}
						</div>
					</div>
				</Grid>
			</Grid>

			{isModalOpen && (
				<GroupModal
					isEditMode={isEditMode}
					isOpen={isModalOpen}
					selectedGroup={selectedGroup}
					onCloseModal={handleCloseModal}
					onDeleteGroup={handleClickDeleteGroup}
					onSaveGroup={handleSaveGroup}
					isLoading={isLoading}
				/>
			)}
			<DeleteModal
				modalOpen={isDeleteModalOpen}
				toggle={() => setIsDeleteModalOpen(false)}
				onConfirm={deleteGroup}
				message={removeConfirmationMessage}
			/>
		</>
	);
};

export default GroupsPage;

GroupsPage.propTypes = {
	data: PropTypes.object.isRequired,
	loadGroups: PropTypes.func.isRequired,
	deleteGroup: PropTypes.func.isRequired,
	groupModalComponent: PropTypes.func.isRequired,
	onSaveGroup: PropTypes.func.isRequired,
	isAddButtonClicked: PropTypes.bool.isRequired,
	setIsAddButtonClicked: PropTypes.func.isRequired,
	removeConfirmationMessage: PropTypes.string.isRequired,
};
