import _ from 'lodash';
const AlphaNumericChars = new RegExp('^[ a-zA-Z0-9\-]+$');
const EnglishChars = new RegExp('^[a-zA-Z\-]+$');
const LegalChars = new RegExp('^[0-9\-\u0590-\u05FF ]+$');
const IsraeliPhone = new RegExp('^(?:(?:(\\+?972|\\(\\+?972\\)|\\+?\\(972\\))(?:\\s|\\.|-)?([1-9]\\d?))|(0[23489])|(0[57][0-9]))(?:\\s|\\.|-)?([^0\\D]\\d{2}(?:\\s|\\.|-)?\\d{4})$');
const isNumber = value => typeof value === 'number';
const isPhoneNumber = value => value && !IsraeliPhone.test(value) ? 'Phone number invalid' : undefined;
const isAlpha = value => value && !EnglishChars.test(value) ? 'The field can only contain alphabet characters' : undefined;
const isLegalChars = value => value && !AlphaNumericChars.test(value) ? 'The field can only contain alphanumeric characters' : undefined;
const required = value => value ? undefined : 'Required field';
const isPasswordStrong = value => value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*"'()+,.\/:;<=>?[\]^_`{|}~-])[a-zA-Z\d!@#$%^&*"'()+,.\/:;<=>?[\]^_`{|}~-]{12,}$/i.test(value) ? 'Password not strong enough' : undefined;
const multiRequired = value => value ? (value.length === 0 ? 'At Least one choice is required' : undefined) : 'Required field';
const yesOrNoRequired = value => value === undefined ? 'Required field' : undefined;
const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid Email address' : undefined;
const maxLength = max => value => value && value.length < max ? `Must be ${max} characters long or more` : undefined;
const passwordMinLength = value => value && value.length < 12 ? 'Password must contain at least 12 characters' : undefined;
const passwordsMatch = (value, allValues) => {
	if (allValues.passwordConfirm && (allValues.password !== allValues.passwordConfirm)) {
		return 'Passwords don’t match';
	}

	return undefined;
};

const oldPasswordsMatch = (value, allValues) => {
	if (allValues.password && allValues.password === allValues.oldPassword) {
		return 'Your new password cannot be the same as your current password';
	}

	return undefined;
};

const fullName = value => {
	const index = value.indexOf(' ');
	const nextChar = value.charAt(index + 1);
	// Check if there's a whitespace and there's something after the whitespace
	return (index !== -1 && !_.isEmpty(nextChar) && nextChar !== ' ') ? undefined : 'Please enter full name';
};

const between = (lower, upper) => (value, previousValue, allValues) =>
	value ? (lower < parseFloat(value) && parseFloat(value) < upper ? value : previousValue) : value;

/* This function is necessary because the function is being added to the validate function list in the "oldPassword"
field inside "ChangePasswordForm" so the form will be able to handle response from the app-server and show this error. */
const wrongCurrentPasswordError = () => {
	return 'Current password is incorrect';
};

export {
	isNumber,
	isPhoneNumber,
	isAlpha,
	isLegalChars,
	required,
	multiRequired,
	email,
	maxLength,
	passwordMinLength,
	passwordsMatch,
	yesOrNoRequired,
	between,
	fullName,
	isPasswordStrong,
	oldPasswordsMatch,
	wrongCurrentPasswordError
};
