import React from 'react';
import './submitButton.scss'
import _ from 'lodash';
import {Button} from "@material-ui/core";

function SubmitButton({label, className, onClick , type, isDisabled = false, variant="contained", ...props}) {
    let customClassName = 'submit-button ' + (className ? className : "");

    return <Button
        type={_.isEmpty(type) ? 'submit' : type}
        onClick={onClick}
        className={customClassName}
        disabled={isDisabled}
        color={"primary"}
        variant={variant}
        {...props}
    >
        {label}
    </Button>
}

export default SubmitButton;