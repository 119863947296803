import React ,{useEffect ,useState} from 'react';
import _ from 'lodash';
import {Button} from "@material-ui/core";
import {useStyles} from "./copy-link.css";
import {useDispatch} from "react-redux";
import {openSnackbar} from "../../../../snackbarAlert/controller/redux/actions";

const DURATION_COPY_ANIMATION = 1500;

const CopyLink = ({currentProcessLinkId, onCopyLinkClick}) => {
    const dispatch = useDispatch();
    const classes=useStyles();
    const LINK_COPIED_MESSAGE = "Link copied!";

    const copyLink = (link) => {
        const dummyInput = document.createElement("input");
        document.body.appendChild(dummyInput);
        dummyInput.setAttribute("value", link)
        dummyInput.select();
        document.execCommand("copy");
        document.body.removeChild(dummyInput);
    }

    const handleClick = async (e) => {
        if (_.isEmpty(currentProcessLinkId)){
            const queryId = await onCopyLinkClick();
            if (queryId) {
                const link = `https://${window.location.host}/#/query/${queryId}`;
                copyLink(link);
                dispatch(openSnackbar("success", LINK_COPIED_MESSAGE , 4000));
            }
        }
        else {
            const link = `https://${window.location.host}/#/query/${currentProcessLinkId}`;
            copyLink(link);
            dispatch(openSnackbar("success", LINK_COPIED_MESSAGE , 4000));
        }
    }

    return (
        <Button className={classes.root} onClick={ handleClick }>Copy Link</Button>
    )
}


export default CopyLink
