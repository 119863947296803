import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		height: '100%',
		padding: '20px',
	},
	image: {
		height: '100px',
		width: '220px',
	},
	description: {
		fontSize: '14px',
		color: theme.palette.dialogDescription.main,
	},
	text: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	title: {
		fontWeight: 'bold',
		color: theme.palette.inputText.main,
		fontSize: '16px',
	},
	close: {
		justifyContent: 'flex-end',
		color: theme.palette.inputText.main,
		borderRadius: '50%',
		minWidth: 'auto',
		padding: '8px',
		'&:hover': {
			'&::after': {
				borderRadius: '50%',
			},
		},
	},
}));

export { useStyles };
