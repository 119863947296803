import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({
	tooltipText: {
		fontSize: '14px',
	},
	text: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		'-webkit-line-clamp': props => props.maxLines,
		display: '-webkit-box',
		'-webkit-box-orient': 'vertical',
	},
	tooltip: {
		padding: '0.6em',
		lineHeight: '1.4em',
		fontSize: '15px',
	},
}));

export default useStyles;
