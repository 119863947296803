import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import ContactForm from "./cotactForm/contactForm";


function PurchaseOrderWireTransfer({handleSubmitForm, paymentType, paymentName = "", selectedPriceName, selectedBaseProduct}) {

    const submit = async (data) => {
        const paymentData = {
                paymentType: paymentType,
                ...data
        };
        const subscriptionData = {priceKey: selectedPriceName, targetProductId: selectedBaseProduct.baseProductId}

        await handleSubmitForm({paymentData, subscriptionData})
    }

    return (
        <Grid>
            <Grid>
                <Typography color={"textSecondary"}>Contact sales team to pay
                    by {paymentName}</Typography>
            </Grid>
            <Grid>
                <ContactForm handleSubmitForm={submit}/>
            </Grid>
        </Grid>
    )
}

export default PurchaseOrderWireTransfer;