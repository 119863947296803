import React, {useEffect} from 'react';
import {Typography} from "@material-ui/core";
import blueCheck from "../../../assets/blue-check.svg";

function Saved() {
    return (
        <>
            <Typography color={"primary"} variant={"body1"} style={{size: '16px'}} component={"span"}><img height={"10px"}
                                                                                        style={{width: 'unset'}} src={blueCheck}/> Saved</Typography>
        </>
    )
}

export default Saved;