import React, {useState} from 'react';
import {Field, getFormValues, reduxForm} from "redux-form";
import SelectCreatable from "../../../../shared/components/queryLine/selectors/selectCreatable/SelectCreatable";
import SubmitButton from "../../../../shared/components/form/Buttons/SubmitButton";
import emailValidation from "../../../integrations/controller/validations/emailInputValidation";
import useStyles from "./SendMailForm.css";
import {useSelector} from "react-redux";
import {Typography} from "@material-ui/core";
import {required, validateForm} from "redux-form-validators";

const MAX_INVITATIONS = 10;

const SendMailForm = ({onSubmit, handleSubmit}) => {
    const classes = useStyles();
    const selectedEmails = useSelector(state => getFormValues('inviteFriendsForm')(state)['emails']);
    const [displayLimitMessage, setDisplayLimitMessage] = useState(false);

    const validateEmail = (value) => {
        if (selectedEmails.length === MAX_INVITATIONS) {
            setDisplayLimitMessage(true);
            return false;
        }
        if (value) {
            return !emailValidation(value);
        }
        setDisplayLimitMessage(false);
        return false;

    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}
              className={classes.form}>
            <div className={classes.mailFieldContainer}>
                <Field component={SelectCreatable}
                       name={'emails'}
                       label="Friend׳s Email"
                       isFreeText={true}
                       placeholder="Friend׳s Email"
                       noOptionsMessage=""
                       validation={validateEmail}
                       additionalClasses={"react-select-creatable-refer-a-friend"}/>
                {displayLimitMessage &&
                <Typography className={classes.mailHelperText} variant="span">You can invite up to 10 friends at
                    once</Typography>}
            </div>


            <SubmitButton className={classes.button} fullWidth label={"Submit"}/>
        </form>
    )
}

export default reduxForm({
        form: 'inviteFriendsForm',
        initialValues: {
            emails: []
        }
    }
)(SendMailForm);