import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import CopyButtonComponent from '../../shared/components/copyButtonComponent/copyButtonComponent';
import TabManager from './tabManager/tabManager';

import useStyles from './topTalkers.css';

const TopTalkers = ({ startTime, estVolume, topConnectionsData }) => {
	const classes = useStyles();

	const [copyAllValue, setCopyAllValue] = useState();

	return (
		<Box className={classes.boxMargin}>
			<div className={classes.mainContainer}>
				<div className={classes.header}>
					<Grid item>
						<Typography className={classes.headerTitle}>
							<span
								className={classes.headerTitleAttrName}>Time</span> { startTime }
						</Typography>
					</Grid>
					<Grid item>
						<Typography className={classes.headerTitle}>
							<span
								className={classes.headerTitleAttrName}>Volume</span> { estVolume }
						</Typography>
					</Grid>
				</div>
				{ topConnectionsData && <Grid item container className={classes.body}>
					<TabManager data={topConnectionsData} setCopyAll={setCopyAllValue}/>
				</Grid>}
				<Grid item className={classes.footer}>
					<CopyButtonComponent valueToCopy={copyAllValue} label={'Copy all'} successMessage={'Copied!'} iconHeight={20} iconWidth={20}/>
				</Grid>
			</div>
		</Box>
	);
};

TopTalkers.propTypes = {
	estVolume: PropTypes.string,
	startTime: PropTypes.string,
	topConnectionsData: PropTypes.object,
};

export default TopTalkers;

