import {ACTIVATE_PLANS_SELECTION_FLOW, SET_CURRENT_PLAN_DETAILS} from "./actionTypes";

const initialState = {
    isPlansSelectionFlowActive: false,
    usePreFlowDialog: true,
    requestedFeature: '',
    currentPlanDetails: {},
    isInitialized: false
}


export default function (state = initialState, { type, payload }) {
    switch (type) {
        case ACTIVATE_PLANS_SELECTION_FLOW:
            return  {
                ...state,
                isPlansSelectionFlowActive: payload.isPlansSelectionFlowActive,
                usePreFlowDialog: payload.usePreFlowDialog,
                requestedFeature: payload.feature
            };
        case SET_CURRENT_PLAN_DETAILS:
            return{
                ...state,
                currentPlanDetails: payload,
                isInitialized: true
            }
        default:
            return state;
    }
}

