import { CLOSE_FULL_SCREEN, OPEN_FULL_SCREEN } from './fullScreenActions';
import initialValues from './initialValues';

export default function (state = initialValues, { type }) {
	switch (type) {
		case OPEN_FULL_SCREEN:
			return {
				...state,
				isOpen: true,
			};
		case CLOSE_FULL_SCREEN:
			return {
				...state,
				isOpen: false,
			};
		default:
			return state;
	}
}
