
export const OPEN_FULL_SCREEN = 'OPEN_FULL_SCREEN';
export const CLOSE_FULL_SCREEN = 'CLOSE_FULL_SCREEN';

export const openFullScreen = () => ({
	type: OPEN_FULL_SCREEN,
	payload: {},
});

export const closeFullScreen = () => ({
	type: CLOSE_FULL_SCREEN,
	payload: {},
});
