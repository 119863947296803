import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './copyIconSvgComponent.css';

const propTypes = {
	height: PropTypes.number,
	isHover: PropTypes.bool,
	width: PropTypes.number,
};

const CopyIconSvgComponent = ({ isHover, width, height }) => {
	const classes = useStyles({ isHover });
	const viewBox = `0 0 ${width} ${height}`;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={classes.icon} width={width} height={height} viewBox={viewBox}>
			<path d="M14.85 3.65L11.434.316C11.35.15 11.1.066 10.85.066H4.766c-.5 0-.833.334-.833.834v2.666h-3C.434 3.566.1 3.9.1 4.4v11.5c0 .5.334.834.834.834h9.5c.5 0 .833-.334.833-.834v-2.666h3c.5 0 .833-.334.833-.834V4.234c0-.25-.084-.418-.25-.584zm-1.75.584h-2.166V2.15L13.1 4.234zM9.6 15.066H1.766V5.234h2.167v7.083c0 .5.333.834.833.834h4.917v1.915H9.6zm.834-3.5H5.6V1.734h3.666v3.333c0 .5.334.834.834.834h3.334v5.666h-3z"/>
		</svg>
	);
};

CopyIconSvgComponent.propTypes = propTypes;

CopyIconSvgComponent.defaultProps = {
	height: 16,
	width: 14,
};

export default CopyIconSvgComponent;

