import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './InnerTable.css';

const InnerTable = ({ children: tableData }) => {
	const classes = useStyles();
	return <table>
		<tbody>
			{tableData.map((row, rowIndex) => (
				<tr key={rowIndex}>
					{row.map((col, colIndex) => (
						<td key={`${rowIndex}-${colIndex}`} className={!colIndex && classes.firstColumn}>{col}</td>
					))}
				</tr>
			))}
		</tbody>
	</table>;
};

InnerTable.propTypes = {
	children: PropTypes.array,
};

export default InnerTable;
