import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	main: {
		width: '25em',
		height: 'max-content',
		background: '#ffffff',
	},
	header: {
		display: 'flex',
		width: '100%',
		height: '42px',
		backgroundColor: '#071C36',
		color: '#ffffff',
	},
	headerContent: {
		margin: '0 20px 0 20px',
		width: '100%',
		height: '100%',
		position: 'relative',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerText: {
		lineHeight: '50px',
		fontWeight: '600',
		fontSize: '16px',
	},
	headerIcon: {
		marginRight: '7px',
	},
	headerCloseIcon: {
		color: 'white',
		position: 'absolute',
		right: 0,
	},
	bodyContent: {
		margin: '20px',
	},
	body: {
		fontSize: '14px',
		fontStyle: 'normal',
		width: 'auto',
		height: 'auto',
		background: '#ffffff',
		color: '#000000',
	},
	autoRemediateText: {
		fontWeight: 600,
		fontStyle: 'normal',
		fontSize: '14px',
		lineHeight: '16px',
		margin: '30px 0 0 0',
	},
	autoRemediateButtonContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	autoRemediateButton: {
		fontWeight: 700,
		height: '45px',
		width: '145px',
		fontStyle: 'normal',
		fontSize: '16px',
		lineHeight: '16px',
		margin: '30px 0',
	},
});

export default useStyles;
