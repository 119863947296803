export const SETUP_PHONE_CREATE_TYPE = 'CREATE';
export const SETUP_PHONE_EDIT_TYPE = 'EDIT';

export const MODAL_TYPE_TO_TITLE = {
	[SETUP_PHONE_CREATE_TYPE]: 'Add phone number',
	[SETUP_PHONE_EDIT_TYPE]: 'Edit phone number',
};

export const MODAL_TYPE_TO_PHONE_SETUP_ERROR_TEXT = {
	[SETUP_PHONE_CREATE_TYPE]: 'Failed to add phone, try again',
	[SETUP_PHONE_EDIT_TYPE]: 'Failed to edit phone, try again',
};

export const MODAL_TYPE_TO_PHONE_SETUP_SUCCESS_TEXT = {
	[SETUP_PHONE_CREATE_TYPE]: 'Your phone has been edited successfully',
	[SETUP_PHONE_EDIT_TYPE]: 'Your phone has been added successfully',
};

export const CODE_FAILED_MESSAGE = 'Failed to send code, try again';
export const CODE_INCORRECT_MESSAGE = 'Incorrect code, please try again';

export const MFA_FAILED_MESSAGE = 'Failed to update MFA with your new phone number';

// FORMS
export const SUBMITTING_MAX_COUNT = 3;
export const CODE_FORM_KEY = 'code';
export const VERIFICATION_FORM_INITIAL_VALUE = { [CODE_FORM_KEY]: '' };
export const PHONE_NUMBER_KEY = 'phoneNumber';
export const PHONE_NUMBER_FORM_INITIAL_VALUE = { [PHONE_NUMBER_KEY]: '+1' };
