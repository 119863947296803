const ACCEPT_PROPOSAL_BUTTON_TEXT = "Accept proposal";
const ACCEPT_PROPOSAL_TEXT = `Almost done! Please take a final review of the details, and click the \"${ACCEPT_PROPOSAL_BUTTON_TEXT}\" to receive an invoice.`;
const THANK_YOU_PROPOSAL_ACCEPT = "Thank you for accepting Cynamics proposal";
const INVOICE_WILL_BE_SENT = "An invoice will be sent to your email shortly.";

const currencies = {
    DOLLAR: "$",
}

const getCurrency = (currencyName) => {
    if (currencies[currencyName]) {
        return currencies[currencyName];
    }
    return "$";
}

export {
    ACCEPT_PROPOSAL_BUTTON_TEXT,
    ACCEPT_PROPOSAL_TEXT,
    getCurrency,
    THANK_YOU_PROPOSAL_ACCEPT as THANK_YOU_ACCEPT,
    INVOICE_WILL_BE_SENT as INVOICE
}