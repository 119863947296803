import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "10px"
  },
  fields: {
    width: '100%',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "black"
  },
  input: {
    alignSelf: "flex-start"
  },
  label: {
    width: "50px",
    fontSize: "14px",
    color: theme.palette.inputText.main
  },
  icon: {
    borderRadius: 3,
    border: "black",
    flex: '0 0 auto',
    padding: '12px',
    transform: 'scale(0.7)',
    outline: 'auto',
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center'
  }
}));

export {useStyles};