import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import TextField from '../../../../shared/components/form/TextField/TextField';
import { email, isNumber, required } from '../../../../shared/components/form/Validation/FieldLevelValidation';
import phoneValidation from '../../../integrations/controller/validations/phoneInputValidation';
import { useStyles } from '../conactSalesForm.css';

const ContactSalesForm = ({ onSubmit, handleSubmit, submitting, displayAdditionalQuestions = false }) => {
	const classes = useStyles();
	return (
		<form onSubmit={handleSubmit(onSubmit)} className={classes.formAlignment}>
			<Grid container xs={12} justify={'center'}>
				<Grid item spacing={1} container xs={12} justify={'space-between'}>
					<Grid item xs={6}>
						<Field
							component={TextField}
							placeholder="First name"
							name="firstName"
							size={'large'}
							type="text"
							validate={[required]}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							component={TextField}
							placeholder="Last name"
							size={'large'}
							name="lastName"
							type="text"
							validate={[required]}
						/>
					</Grid>
				</Grid>
				{displayAdditionalQuestions
				&& < Grid spacing={1} item container xs={12}>
					<Grid item xs={6}>
						<Field
							component={TextField}
							placeholder="Number of users"
							size={'large'}
							name="numberOfRequiredUsers"
							type="number"
							validate={[required, isNumber]}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							component={TextField}
							placeholder="Number of devices"
							size={'large'}
							name="numberOfRequiredDevices"
							type="number"
							validate={[required, isNumber]}
						/>
					</Grid>
				</Grid>}
				<Grid item spacing={1} container xs={12}>
					<Grid item xs={6}>
						<Field
							component={TextField}
							placeholder="Email"
							size={'large'}
							name="email"
							type="text"
							validate={[required, email]}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							component={TextField}
							placeholder="Phone number"
							size={'large'}
							name="phoneNumber"
							type="tel"
							validate={[required, phoneValidation]}
						/>
					</Grid>
				</Grid>
				<Grid spacing={1} item container xs={12}>
					<Grid item xs={12}>
						<Field
							component={TextField}
							placeholder="Description"
							name="moreInfo"
							type="text"
							rows={'5'}
							multiline={true}
							validate={[required]}
						/>
					</Grid>

				</Grid>
				<Grid item container xs={12} justify={'space-around'} className={classes.buttonGrid} >
					<Grid item xs={5}>
						<SubmitButton label="Submit"
							className={classes.submitButton}
							isDisabled={submitting}
						/>
					</Grid>
				</Grid>

			</Grid>
		</form>
	);
};

ContactSalesForm.propTypes = {
	displayAdditionalQuestions: PropTypes.bool,
	handleSubmit: PropTypes.func,
	onSubmit: PropTypes.func,
	submitting: PropTypes.bool,
};

export default reduxForm({
	form: 'contact-sales-form',
})(ContactSalesForm);
