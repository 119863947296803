import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: 'max-content',
	},
	label: {
		color: props => theme.palette.severity[props.label],
		verticalAlign: 'bottom',
		paddingLeft: '10px',
		fontWeight: '600',
		textTransform: 'capitalize',
	},

    icon: {
        height: '20px',
        width: 'unset'
    }
}));

export {useStyles}
