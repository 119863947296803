import React from 'react';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DownloadIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from './addEditField.css';
import StyledToggle from '../../form/Toggle/StyledToggle';
import PropTypes from 'prop-types';
import CustomTooltip from '../../Tooltip/CustomTooltip';
import {
	DISABLED_DELETE_ICON_TOOLTIP_TITLE,
	ENABLED_DELETE_ICON_TOOLTIP_TITLE,
} from '../../../../containers/deviceManagement/view/collector/collectorTableConfig';

const AddEditField = props => {
	const { deviceInfo, handleDownload, handleEdit, handleDelete, handleRecorder } = props;
	const classes = useStyles();
	const isDeviceActive = deviceInfo.status === 'active';
	const createDeleteIconTooltipTitle = () => handleDelete ? ENABLED_DELETE_ICON_TOOLTIP_TITLE : DISABLED_DELETE_ICON_TOOLTIP_TITLE;

	return (
		<div className="button-container">
			{
				handleRecorder
				&& <>
					<CustomTooltip
						title={isDeviceActive ? 'Pause' : 'Record'}
						PopperProps={{ style: { marginTop: 0 } }}
					>
						<div className={classes.toggleContainer}>
							<StyledToggle
								checked={ isDeviceActive }
								onChange={ () => handleRecorder(deviceInfo)}
								name="recordingToggle"
							/>
						</div>
					</CustomTooltip>
				</>

			}
			{ deviceInfo.deviceHasDownload
				? <>
					<div className="menu-separator" />
					<CustomTooltip title={'Download configuration files'} >
						<IconButton aria-label="download"
							className={classes.button}
							onClick={ () => {
								handleDownload(deviceInfo);
							} }>
							<DownloadIcon fontSize="small"/>
						</IconButton>
					</CustomTooltip>
				</>
				: null }
			{ !deviceInfo.deviceHasDownload && _.has(deviceInfo, 'deviceType')
				&& <>
					<div className="menu-separator" />
					<CustomTooltip title="Download not available">
						<span>
							<IconButton aria-label="delete" disabled color="primary">
								<DownloadIcon fontSize="small" />
							</IconButton>
						</span>
					</CustomTooltip>
				</>
			}
			{ handleEdit
				&& <>
					{(handleRecorder || handleDownload) && <div className="menu-separator" />}
					<CustomTooltip title={'Edit'} >
						<IconButton aria-label="edit"
							className={classes.button}
							onClick={ () => {
								handleEdit(deviceInfo);
							} }>
							<EditIcon fontSize="small" htmlColor="#48648B"/>
						</IconButton>
					</CustomTooltip>
				</>}
			<>
				<div className="menu-separator" />
				<CustomTooltip title={createDeleteIconTooltipTitle()}>
					<span>
						<IconButton aria-label="delete"
							className={classes.button}
							onClick={() => {
								handleDelete(deviceInfo);
							}}
							disabled={!handleDelete}>
							<DeleteIcon fontSize="small" htmlColor={handleDelete ? '#48648B' : '#8C8C8C'}/>
						</IconButton>
					</span>
				</CustomTooltip>
			</>
		</div>
	);
};

AddEditField.propTypes = {
	deviceInfo: PropTypes.object,
	handleDownload: PropTypes.func,
	handleEdit: PropTypes.func,
	handleDelete: PropTypes.func,
	handleRecorder: PropTypes.func,
};
export default AddEditField;
