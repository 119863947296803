import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

import CopyIconSvgComponent from './CopyIconSvgComponent/copyIconSvgComponent';
import useStyles from './copyButton.css';

const propTypes = {
	valueToCopy: PropTypes.string.isRequired,
	successMessageDuration: PropTypes.number,
	label: PropTypes.string,
	successMessage: PropTypes.string.isRequired,
	labelSize: PropTypes.oneOf(['xs', 'small']),
	iconWidth: PropTypes.number,
	iconHeight: PropTypes.number,
};

const CopyButtonComponent = ({
	valueToCopy,
	label = '',
	successMessage,
	successMessageDuration = 1000,
	labelSize = 'small',
	iconWidth,
	iconHeight,
}) => {
	const classes = useStyles();

	const [copySuccess, setCopySuccess] = useState(false);
	const [isHover, setIsHover] = useState(false);

	const copyData = () => {
		if (valueToCopy) {
			navigator.clipboard.writeText(valueToCopy);
		}

		setCopySuccess(true);
		setTimeout(() => setCopySuccess(false), successMessageDuration);
	};

	const updateHover = hoverState => {
		setIsHover(hoverState);
	};

	return (
		<Box onClick={copyData} display={'inline-flex'} sx={{ height: '100%' }}>
			{copySuccess ? (
				<Typography
					onMouseLeave={() => updateHover(false)}
					className={clsx(classes[`${labelSize}-label`], classes.successMessage)}
				>
					{successMessage}
				</Typography>
			) : (
				<Box
					display={'inline-flex'}
					onMouseEnter={() => updateHover(true)}
					onMouseLeave={() => updateHover(false)}
				>
					<div className={classes.iconWrapper}>
						<CopyIconSvgComponent width={iconWidth} height={iconHeight} isHover={isHover} />
					</div>
					<Typography
						color={'textPrimary'}
						className={clsx(classes.label, classes[`${labelSize}-label`])}
					>
						{label}
					</Typography>
				</Box>
			)}
		</Box>
	);
};

CopyButtonComponent.propTypes = propTypes;
export default CopyButtonComponent;
