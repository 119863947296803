import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	wrapper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		margin: 'auto',
	},
	text: {
		fontSize: '22px',
		color: 'rgba(0, 0, 0, 0.7)',
	},
	title: {
		fontWeight: 600,
	},
	message: {
		fontWeight: 400,
	},
}));

export default useStyles;
