import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: "column",
    marginTop: '10px'
  },
  vxlan: {
    marginTop: '20px'
  }
}));

export {useStyles};
