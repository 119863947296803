import React, { PureComponent } from 'react';
import Select from 'react-select'
import _ from 'lodash'
import makeReduxSelectInterface from './maker/makeReduxSelectInterface';
import './react-select.scss';
import { ReactComponent as Inout } from '../../../../assets/selectors/inout.svg';
import { ReactComponent as Out } from '../../../../assets/selectors/out.svg';
import analyzer from "../../../../helpers/analyzer";
import {CHANGE_ACTION} from "../../../../helpers/analyzerConstants";

class CustomInterfacesSelector extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            options: this.props.options,
            selectedValues: this.props.value
        }
        this.handleChange = this.handleChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleClickOnLabel = this.handleClickOnLabel.bind(this)

    }
    makeOpositeDirection = (input) => {
        return input.direction == 'i' ? 'o' : 'i';
    }
    propagateUpdateOnDirection = (value) => {
        let previousValue = this.state.selectedValues;
        let Index = _.findIndex(previousValue, (a) => {
            if (a.value == value) {
                return a;
            }
        })
        let NewArrayValue = previousValue[Index];
        NewArrayValue.direction = this.makeOpositeDirection(NewArrayValue);
        previousValue[Index] = NewArrayValue;
        this.setState({ selectedValues: previousValue, menuIsOpen: false })

    }
    updateDirectionValueOnClick = (value) => {
        this.propagateUpdateOnDirection(value)
    }
    handleMouseOut = (e) => {
        this.props.onBlur(e);
    }
    handleClickOnLabel = (e, value) => {
        e.preventDefault();
        this.updateDirectionValueOnClick(value)
        this.props.onFocus(e)
    }
    formatOptionLabel = ({ value, label, direction }) => (
        <div style={ { display: 'flex' } }>
            <div>{ label } </div>
        </div>
    );
    onFocus = (e) => {
        this.setState({ menuIsOpen: true })
        this.props.onFocus(e)
    }
    onBlur = (e) => {
        this.setState({ menuIsOpen: false })
        this.props.onBlur(e)
    }
    handleChange = (e) => {
        this.setState({ selectedValues: e })
        this.props.onChange(e || [])
        this.props.analyzerCategory && analyzer.event({category: this.props.analyzerCategory, action: CHANGE_ACTION, label: this.props.input.name})
    }

    formatOptionValue = (props) => {

        const { data } = props;
        const { direction, label, value } = data;

        return <div style={ { display: 'flex' } }>
            <button
                style={ { background: 'transparent', borderColor: 'transparent' } }
                onClick={ (e) => { e.preventDefault() } }
            /**   
             *  onClick={ (e) => { this.handleClickOnLabel(e, value) } }
                onMouseOut={ (e) => { this.handleMouseOut(e, value) } }
                onMouseOver={ (e) => { this.handleMouseOut(e, value) } }
            */
            >
                { direction == "i" ? <Inout /> : <Out /> }
            </button>

            <div> { label } </div>
        </div>


    }
    render() {

        return <Select
            options={ this.props.options }
            value={ this.props.value }
            onChange={ this.handleChange }
            formatOptionLabel={ this.formatOptionLabel }
            components={ { MultiValueLabel: this.formatOptionValue } }
            menuIsOpen={ this.state.menuIsOpen }
            onFocus={ this.onFocus }
            onBlur={ this.onBlur }
            isMulti
            placeholder={this.props.placeholder}
            className={`react-select-redux-forms react-select-underline react-select-creatable ${this.props.additionalClasses}`}
            classNamePrefix="react-select"
            styles={this.props.selectorStyles}
        />
    }
}

export default makeReduxSelectInterface(CustomInterfacesSelector)