import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles } from './addEditField.css';

const DeleteField = props => {
	const classes = useStyles();

	return (
		<div className="button-container">
			<div className="separator" />
			<IconButton aria-label="delete"
				className={classes.button}
				onClick={this.props.onDeleteThreat}>
				<DeleteIcon />
			</IconButton>
		</div>
	);
};

export default DeleteField;
