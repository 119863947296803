import React from 'react';
import PropTypes from 'prop-types';
import SimpleModal from '../../../shared/components/simpleTable/simpleModal';
import useStyles from './ProposalModal.css';
import ProposalSummary from './proposalSummary/ProposalSummary';
import SuccessIndication from '../../../shared/components/IndicationComponent/types/SuccessIndication';
import BackgroundImage from '../../../assets/plans/background.png';
import { THANK_YOU_ACCEPT, INVOICE } from './const';

const ProposalModal = ({ quote, onDismiss, isOpen, onProposalAccept: onAcceptProposal, proposalCompleted, isLoading }) => {
	const classes = useStyles();

	return (
		<div>
			<SimpleModal
				isOpen={isOpen}
				height={638}
				width={1067}
				style={{ maxWidth: 1067, width: 'max-content' }}
				header="Proposal summary"
				iconStyle={{ display: 'none' }}
				toggle={onDismiss}
				backgroundImage={BackgroundImage}
			>
				<div className={classes.container}>
					{proposalCompleted ? <SuccessIndication
						header={THANK_YOU_ACCEPT}
						description={INVOICE}
					/> : quote && <ProposalSummary
						quote={quote}
						onAcceptProposal={quoteId => {
							onAcceptProposal(quoteId);
						}}
						isLoading = {isLoading}
					/>}
				</div>
			</SimpleModal>
		</div >
	);
};

ProposalModal.defaultProps = {
	quote: {
		currency: 'DOLLAR',
		discountTotal: 0,
		discounts: [],
	},
	isLoading: false,
};

ProposalModal.propTypes = {
	quote: PropTypes.shape({
		id: PropTypes.string.isRequired,
		total: PropTypes.number.isRequired,
		interval: PropTypes.oneOf(['year', 'month']).isRequired,
		status: PropTypes.oneOf(['open', 'accepted', 'canceled']).isRequired,
		currency: PropTypes.string,
		discountTotal: PropTypes.number,
		products: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			price: PropTypes.number.isRequired,
		})).isRequired,
		discounts: PropTypes.arrayOf(PropTypes.shape({
			name: PropTypes.string.isRequired,
			percentOff: PropTypes.number.isRequired,
			amountOff: PropTypes.number.isRequired,
			priceOff: PropTypes.number.isRequired,
		})),
	}).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onProposalAccept: PropTypes.func.isRequired,
	proposalCompleted: PropTypes.bool.isRequired,
	onDismiss: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
};

export default ProposalModal;
