import {STORE_COLLECTOR_DEVICES , STORE_PUBLIC_DEVICES, STORE_PUBLIC_CLOUD_DEVICES } from "./actionTypes";
import { initialValue } from "./initialValues";

export default function (state = initialValue, { type, payload }) {
    switch (type) {
        case STORE_PUBLIC_DEVICES:
            return {
                ...state,
                publicDevices: payload
            };
        case STORE_COLLECTOR_DEVICES:
            return {
                ...state,
                collectorDevices: payload
            }
        case STORE_PUBLIC_CLOUD_DEVICES:
            return {
                ...state,
                publicCloudDevices: payload
            }
        default:
            return state;
    }
}