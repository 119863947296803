import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	fullScreenActionsPosition: {
		position: 'absolute',
		top: 0,
		right: 6,
	},
	wrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		zIndex: 1,
		height: '100%',
		minWidth: '900px',
		width: props => props.isFullScreen ? '100%' : '62%',
		padding: '0 22px 22px',
		background: '#FFFFFF',
		boxShadow: '-4px 0px 19px rgba(0, 0, 0, 0.05)',
		transform: 'translateX(0%)',
		transition: 'transform 0.5s ease-in-out',
		[theme.breakpoints.down('sm')]: {
			padding: '14px',
			minWidth: '0',
			width: '100%',
		},
		overflow: 'scroll',
	},
	hidden: {
		transform: 'translateX(100%)',
	},
	scrollArea: {
		height: '100%',
		overflow: 'hidden',
	},
	topBar: {
		position: 'sticky',
		top: '0px',
		padding: '16px 0',
		backgroundColor: '#FFFFFF',
		zIndex: 100,
	},
}));

export default useStyles;
