import moment from 'moment';
import { findQuery } from '../../../controller/businessLogic/mappers';
import { defaultMapping, timeRangeOptions } from '../timeRangeMapping/timeRangeMapping';

const Reset = {
	timeWindow: 60,
	datefrom: moment().subtract(1, 'h'),
	timefrom: moment().subtract(1, 'h'),
	dateto: moment(),
	timeto: moment(),
	lastTimeRange: timeRangeOptions.find(e => e.value === defaultMapping),
	ipSource: [],
	ipDestination: [],
	portsDestination: [],
	portsSource: [],
	ipProtocol: [],
	devices: [],
	queryTypes: findQuery(1),
	isIn: false,
	isOut: false,
	isFWE_ALLOW: false,
	isFWE_DENY: false,
	SYN: false,
	ACK: false,
	FIN: false,
	URG: false,
	PSH: false,
	RST: false,
	ECE: false,
	CWR: false,
	aws: {
		accountIds: [],
		interfacesIds: [],
		vpcIds: [],
		subnetsIds: [],
		instanceIds: [],
		regions: [],
		azs: [],
		srcServices: [],
		destServices: [],
	},
};

export { Reset };
