import React from 'react';
import useStyles from './masterDetailsSkeleton.css';
import { Skeleton } from '@material-ui/lab';

const OverviewSkeleton = () => {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<Skeleton className={classes.chipGroupSkeleton} animation="wave" variant="rounded" width="30%" height="1.6em" />
			<Skeleton className={classes.titleSkeleton} animation="wave" variant="rounded" width="40%" height="1.6em" />
			<Skeleton className={classes.skeletonItem} animation="wave" variant="rounded" width="95%" height="1.5em" />
			<Skeleton className={classes.skeletonItem} animation="wave" variant="rounded" width="95%" height="1.5em" />
			<Skeleton animation="wave" variant="rounded" width="95%" height="1.5em" />
		</div>
	);
};

export default OverviewSkeleton;
