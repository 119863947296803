import React from 'react';
// SetupPhone.jsx
export const SEND_CODE_LABEL = '1. Add your phone number and press ‘Send code’. you will receive an authorization code.';
export const VERIFY_CODE_LABEL = <p>2.  Complete the phone number verification by entering the <strong>6 digits code </strong> listed in the SMS.</p>;

// PhoneVerificationForm.jsx
export const VERIFY_CODE_FIELD_LABEL = 'Enter verification code';

// Wrapper.jsx
export const MFA_DISCLAIMER_TEXT = 'Phone number was updated successfully.';
export const MFA_DISCLAIMER_TEXT_SECOND_LINE =  'This phone number will also be used for MFA.'
// Code validation error
export const CODE_VALIDATION_ERROR_MESSAGE = 'Please enter a valid code';
