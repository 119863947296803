import React, { Component } from 'react';

import TableHeader from '../header/tableHeader';
import moment from 'moment';
import * as d3 from 'd3-format';
import '../../colapseTable/colapseTable.scss';

// Const columns = ['From', 'To', 'SHORT QUERY NAME', '% More than normal'];

const columns = [{
	name: 'From',
	width: '30px',
},
{
	name: 'To',
	width: '30px',
},
{
	name: 'Packets',
	width: '30px',
},
{
	name: '% More than normal',
	width: '70px',

}];

class CollapsibleTable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { instances, instanceType, insightData } = this.props;

		return (
			<div className="colaper">
				<TableHeader column={ columns } rows={ instances } insightData={ insightData } />
			</div>
		);
	}
}

export default CollapsibleTable;

