import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import {
	CONFIGURE_EXISTING_PHONE_MFA_DISCLAIMER_QUESTION, CONFIGURE_EXISTING_PHONE_MFA_DISCLAIMER_TEXT,
} from '../stepsTexts';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import { useStyles } from './ConfigureExistingPhoneMfa.css';

const ConfigureExistingPhoneMfa = ({ configurePhoneAsMfa, phoneNumber, handleSetNewPhone }) => {
	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);

	const handleClickKeepTheNumber = async () => {
		setIsLoading(true);
		await configurePhoneAsMfa();
		setIsLoading(false);
	};

	return (
		<Paper elevation={0} className={classes.paper}>
			<Grid container direction={'column'} className={classes.container}>
				<Grid item xs={2}/>
				<Grid item xs={8} className={classes.fullWidth}>
					<Typography className={classes.text}>
						{CONFIGURE_EXISTING_PHONE_MFA_DISCLAIMER_TEXT}
					</Typography>
					<Typography className={`${classes.phoneNumber} ${classes.text}`} >
						{phoneNumber}
					</Typography>
					<Typography className={`${classes.disclaimerQuestion} ${classes.text}`}>
						{CONFIGURE_EXISTING_PHONE_MFA_DISCLAIMER_QUESTION}
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.fullWidth}>
					<Grid container direction={'row'} >
						<Grid item xs={6}>
							<StyleButton label={'Set-up new number'} variant={'outlined'} onClick={handleSetNewPhone} className={classes.setUpNumber}/>
						</Grid>
						<Grid item xs={1}/>
						<Grid item xs={2}>
							{ isLoading
								? <CircularProgress color="primary" className={classes.circularProgress}/>
								: <StyleButton label={'Keep this number'} className={classes.setUpNumber} onClick={handleClickKeepTheNumber} />}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>);
};

ConfigureExistingPhoneMfa.propTypes = {
	configurePhoneAsMfa: PropTypes.func.isRequired,
	handleSetNewPhone: PropTypes.func.isRequired,
	phoneNumber: PropTypes.string.isRequired,
};

export default ConfigureExistingPhoneMfa;

