import React from "react";
import {useStyles} from "./stepTemplate.css";
import {Grid} from "@material-ui/core";

const StepTemplate = ({sidePanelImage, children}) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Grid item xs={3} className={classes.sidePanel}>
                <img src={sidePanelImage} className={classes.sidePanelImage}/>
            </Grid>
            <Grid item xs={9} style={{height: "100%"}}>
                { children }
            </Grid>
        </Grid>
    )
}

export default StepTemplate;