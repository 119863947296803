import React, { useEffect } from 'react';
import { Divider, Grid } from '@material-ui/core';
import StoreHealth from './storeHealth';
import { DeviceMonitoring } from './deviceMonitoring';
import { useStyles } from './performanceMonitoringPage.jsx.css';
import { setTitle } from '../../../containers/app/controller/redux/app/appActions';
import { useDispatch } from 'react-redux';


const PERFORMANCE_MONITORING_TITLE = 'Monitoring';

const PerformanceMonitoringPage = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setTitle(PERFORMANCE_MONITORING_TITLE));
	}, []);

	const classes = useStyles();
	return (<>
		<Grid container spacing={0} alignItems="top" alignContent={'center'} direction={'row'} className={classes.mainGrid}>
			<Grid item xs={12} className={classes.storeHealthGrid}>
				<StoreHealth />
			</Grid>
			<Grid item xs={12} className={classes.deviderGrid}>
				<Divider orientation="horizontal" light variant="middle" />
			</Grid>
			<Grid item xs={12} className={classes.deviceMonitorGrid}>
				<DeviceMonitoring />
			</Grid>
		</Grid>
	</>);
};

PerformanceMonitoringPage.propTypes = {
};
PerformanceMonitoringPage.defaultValue = {
};

export default PerformanceMonitoringPage;
