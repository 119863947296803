import _ from 'lodash';
import { dataTypeOptions } from '../redux/actionTypes';
import {
	GROUP_TYPE,
	INCLUDE_SIGN,
} from '../../../../shared/components/queryLine/selectors/directionSelectCreatable/consts';

const findQuery = queryValue => dataTypeOptions.find(e => e.value === queryValue);
const getQueryAlerts = () => dataTypeOptions.find(e => e.isAlert === true);

const mapGroups = (groups, direction = INCLUDE_SIGN) => groups && groups.length ? groups.map(({ objectGroup }) => ({
	value: objectGroup.objectId,
	label: objectGroup.title,
	direction,
	type: GROUP_TYPE,
})) : [];

const mapDevices = devices => devices && devices.length ? devices.map(e => _.pick(e, 'value', 'label', 'type', 'direction')) : [];

const mapIps = (ips, direction) => ips && ips.length ? ips
	.filter(e => e.direction === direction)
	.map(e => _.pick(e, ['value', 'type', 'direction', 'label'])).map(e => ({
		...e,
		value: e.value.trim(),
		label: e.label.trim(),
	})) : [];

const mapPortsProtocols = (ports, type) => ports && ports.length ? ports.map(e => ({
	value: e.value.trim(),
	label: e.label.trim(),
	type,
})) : [];

const convertIpFiltersSymbols = ipFilters => ipFilters ? ipFilters
	.map(e => {
		e = _.pick(e, ['value', 'type', 'direction', 'label']);
		const { direction } = e;
		if (direction === '+') {
			return { ...e, direction: 'i' };
		}

		if (direction === '-') {
			return { ...e, direction: 'o' };
		}

		return e;
	}) : [];

export { mapDevices, mapIps, mapPortsProtocols, findQuery, getQueryAlerts, mapGroups, convertIpFiltersSymbols };
