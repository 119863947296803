import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from 'mdi-react/ExpandMoreIcon';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Query from '../query/query';
import Accordion from '@material-ui/core/Accordion';
import React from 'react';
import PropTypes from 'prop-types';

import { useStyles } from './analysisAccordion.css';

const AnalysisAccordion = ({ queryTitle, isExpanded, toggleExpand, initialValues, currentProcessLinkId, onSubmit, createQueryLink, isSubmitting }) => {
	const classes = useStyles();
	return (
		<Accordion expanded={isExpanded} onChange={toggleExpand} classes={{ root: classes.root }}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className={classes.icon}/>}
				classes={{ root: classes.summary, content: classes.content }}
			>
				<span>{queryTitle}</span>
				<span className={classes.expandText}>{isExpanded ? 'Collapse' : 'Expand'}</span>
			</AccordionSummary>
			<AccordionDetails>
				<Query
					isToogleOpen={ isExpanded }
					initialValues={ initialValues }
					onSubmit={ onSubmit }
					createQueryLink={ createQueryLink }
					form={ 'create-backgroung-process' }
					currentProcessLinkId={currentProcessLinkId}
					isSubmitting={isSubmitting}
				/>
			</AccordionDetails>
		</Accordion>
	);
};

AnalysisAccordion.propTypes = {
	queryTitle: PropTypes.string,
	isExpanded: PropTypes.bool,
	toggleExpand: PropTypes.func,
	initialValues: PropTypes.object,
	currentProcessLinkId: PropTypes.string,
	onSubmit: PropTypes.func,
	createQueryLink: PropTypes.func,
	isSubmitting: PropTypes.bool,
};

export default AnalysisAccordion;
