import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from './actionTypes';

const openSnackbar = (severity, message, autoHideDuration = 4000, action, customClass) => ({
	type: OPEN_SNACKBAR,
	payload: { severity, message, autoHideDuration, action, customClass },
});

const closeSnackbar = () => ({
	type: CLOSE_SNACKBAR,
	payload: {},
});

export { openSnackbar, closeSnackbar };
