import React, {useEffect, useState} from 'react';
import {Grid, Paper, Typography} from "@material-ui/core";
import StyleButton from "../../../shared/components/form/Buttons/StyleButton";
import PriceBlock from "./priceBlock/priceBlock";
import {useStyles} from "./plan.css";
import check from '../../../assets/darkblue-check.svg';
import clsx from "clsx";
import {FREE_PLAN_ID, CUSTOM_PLAN_ID, BASIC_PLAN_ID} from "../../../constants/planFields";

// workaround to fix render&state issues
let tempPrices = {
    [FREE_PLAN_ID]:null,
    [CUSTOM_PLAN_ID]: null,
    [BASIC_PLAN_ID]: null
};

function Plan({key, isCurrent, selectedPriceName, onPlanSelected, plan, isDisabled}) {
    const buttonText = isCurrent ? 'Current plan' : 'Select plan';
    const mostPopularText = "MOST POPULAR!";
    const {plansDetails, prices, isPopular, displayText, productName, baseProductId} = plan;
    const classes = useStyles({mostPopularText});
    const isPriceNameValid = (prices, name) => {
        const index = prices.findIndex(price => price.priceName === name);
        return index !== -1;
    }
    const [localSelectedPriceName, setLocalSelectedPriceName] = useState(() => {
        if (selectedPriceName && plan.prices && isPriceNameValid(plan.prices, selectedPriceName))
            return selectedPriceName;
        if (plan.prices) {
            return prices[0].priceName;
        }
    });

    useEffect(() => {
        tempPrices[baseProductId] = localSelectedPriceName;
    }, [localSelectedPriceName]);


    const selectPlan = () => {
        onPlanSelected(plan, tempPrices[baseProductId]);

    }

    const onPriceChange = (priceName) => {
        setLocalSelectedPriceName(priceName);
        tempPrices[baseProductId] = priceName;
    }

    return (
        <div key={key} className={clsx({[classes.popularBorder]: isPopular})}>
            <Paper className={clsx(classes.paper, {[classes.popularPaper]: isPopular})} elevation={0}
                   variant={"outlined"} square>
                <div style={{flex: '0 1 auto'}}>
                    <Typography className={classes.planName} align={"center"} variant={"h6"}> {displayText}</Typography>
                    <PriceBlock selectedPriceName={localSelectedPriceName}
                                onPriceChange={onPriceChange}
                                pricesArray={prices}
                                isDisabled={isCurrent || isDisabled}
                                planType={baseProductId}/>
                    <StyleButton className={classes.selectButton} label={buttonText} isDisabled={isCurrent || isDisabled}
                                 onClick={selectPlan}/>
                </div>
                <Grid container xs={10} spacing={2} justify={"space-between"} alignItems={"center"}
                      alignContent={"flex-start"} style={{marginTop: '15px'}}>
                    {plansDetails.map(feature =>
                        <Grid item container xs={12} className={classes.planDetail} spacing={0}>
                            <Grid item xs={11}><Typography>{feature}</Typography></Grid>
                            <Grid item xs={1}><img src={check} alt={"check"} height={'16px'}/></Grid>
                        </Grid>
                    )}

                </Grid>
            </Paper>
        </div>

    )

}

export default Plan;