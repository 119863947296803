import React, {useState} from 'react';
import PremiumFeatureDialog from "../premiumFeatureDialog/premiumFeatureDialog";
import PlansStepperWrapper from "../plansStepperWrapper/plansStepperWrapper";
import {useDispatch, useSelector} from "react-redux";
import {setPlansFlowActivation} from "../redux/plansActions";
import {CUSTOM_PLAN_ID} from "../../../constants/planFields";
import ContactSalesForm from "../contactSales/forms/contactSalesFrom";
import BoundedFormWithTitles from "../contactSales/boundedFormWithTitles";
import ChangeCustomPlan from "../changeCustomPlan/changeCustomPlan";
import analyzer from "../../../helpers/analyzer";
import {ANALYZER_SUCCESS_STATUS, DISMISS_ACTION, PLANS_CATEGORY} from "../../../helpers/analyzerConstants";
const ANALYZER_CATEGORY = PLANS_CATEGORY;



function SelectPlanStarterComponent() {
    const usePreFlowDialog = useSelector(state => state.plans.usePreFlowDialog);
    const [isPremiumFeatureDialogOpen, setIsPremiumFeatureDialogOpen] = useState(false);
    const [isCustomChangeDialogOpen, setIsCustomChangeDialogOpen] = useState(false);
    const { baseProductId } = useSelector(state => state.plans.currentPlanDetails);

    const dispatch = useDispatch();

    const stopPlanSelectionFlow = () => {
        dispatch(setPlansFlowActivation(false));
        analyzer.event({category: ANALYZER_CATEGORY, action: DISMISS_ACTION, label: 'PLANS_FLOW'})
    }

    const onUpgrade = () => {
        setIsPremiumFeatureDialogOpen(!isPremiumFeatureDialogOpen);
    }

    const onCustom = () => {
        setIsCustomChangeDialogOpen(true);
    }

    const RenderPremiumPlans = () => {
        return isPremiumFeatureDialogOpen || !usePreFlowDialog ?
            <PlansStepperWrapper onClose={stopPlanSelectionFlow}  /> :
            <PremiumFeatureDialog onClose={stopPlanSelectionFlow} callback={onUpgrade}/>
    }

    const RenderCustom = () => {
        return !isCustomChangeDialogOpen ?
            <PremiumFeatureDialog onClose={stopPlanSelectionFlow} callback={onCustom}/> :
            <ChangeCustomPlan onClose={stopPlanSelectionFlow} />
    }

    return(
        <>
            { baseProductId === CUSTOM_PLAN_ID ? RenderCustom() : RenderPremiumPlans() }
        </>
    )

}

export default SelectPlanStarterComponent;