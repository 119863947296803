import React, { Component } from 'react';
import './integrationBox.scss';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import OptionsMenu from './optionsMenu/optionsMenu';
import { activeTypes } from '../../controller/constants/activeTypes';
import PropTypes from 'prop-types';
import Status from '../../../../shared/components/statusIcon/status';
import { SYSLOG } from '../../controller/constants/integrationTypes';

export default class IntegrationBox extends Component {
	activate = async integrationType => {
		await this.props.activateIntegration(integrationType, activeTypes.ON);
	};

	deactivate = async integrationType => {
		await this.props.activateIntegration(integrationType, activeTypes.OFF);
	};

	delete = async integrationType => {
		await this.props.deleteIntegration(integrationType);
	};

	openModal = async integrationType => {
		await this.props.updateSelectedIntegration(integrationType);
	};

	render() {
		const {
			title,
			descriptions,
			icon,
			isComingSoon = false,
			integrationType,
			enabled,
			isDelete = false,
			isShowEnable = false,
            additionalSettings = {},
		} = this.props;
		const { ON, OFF, NONE, NOT_CONNECTED } = activeTypes;

		return (<div className="integration-box">
			<div className="integration-box-header">
				{ enabled ? <Status status={enabled} poller={additionalSettings.statusPollerFn} /> : null }
				<img src={ icon } className="integration-box-logo" alt={integrationType}></img>
			</div>

			<div className="integration-box-title"><b>{title}</b></div>
			<div className="integration-box-description">{descriptions}</div>
			<div className="integration-box-buttons" key={`${integrationType}-${enabled}`}>

				{!isComingSoon && (enabled === ON || enabled === NOT_CONNECTED)
					? <SubmitButton
						onClick={async () => {
							await this.openModal(integrationType);
						}}
						className="add-integration"
						label={additionalSettings.activeButtonText || 'Settings'} />

					: null}

				{!isComingSoon && enabled === OFF
					? <SubmitButton
						onClick={async () => {
							await this.openModal(integrationType);
						}}
						className="add-integration"
						label={additionalSettings.activeButtonText || "Add"}/>

					: null}

				{!isComingSoon && enabled === NONE
					? <SubmitButton
						onClick={async () => {
							await this.openModal(integrationType);
						}}
						className="add-integration"
						label={additionalSettings.noneButtonText || "View"}/>
					: null}

				{isComingSoon
					? <SubmitButton
						isDisabled={true}
						className="add-integration"
						label="Add"/>
					: null}

				{
					integrationType === SYSLOG?
						(enabled === ON || enabled === NOT_CONNECTED || enabled === OFF ) &&
						<OptionsMenu
							className="integration-box-button-button"
							isDelete={isDelete}
							isShowEnable={enabled === OFF && isShowEnable}
							settings={async () => this.openModal(integrationType)}
							delete={async () => this.delete(integrationType)}
							deactivate={async () => this.deactivate(integrationType)}
							activate={async () => this.activate(integrationType)}
						/>
						: enabled === ON && <OptionsMenu
						className="integration-box-button-button"
						isDelete={isDelete}
						isShowEnable={enabled === OFF && isShowEnable}
						settings={async () => this.openModal(integrationType)}
						delete={async () => this.delete(integrationType)}
						deactivate={async () => this.deactivate(integrationType)}
						activate={async () => this.activate(integrationType)}
					/>

				}

			</div>
		</div>);
	}
}

IntegrationBox.propTypes = {
	title: PropTypes.string,
	descriptions: PropTypes.string,
	icon: PropTypes.string,
	isComingSoon: PropTypes.bool,
	integrationType: PropTypes.string,
	enabled: PropTypes.bool,
	isDelete: PropTypes.bool,
	isShowEnable: PropTypes.bool,
	activateIntegration: PropTypes.func,
	updateSelectedIntegration: PropTypes.func,
	deleteIntegration: PropTypes.func,
};
