import React from "react";
import {useStyles} from "./dateTimePicker.css";
import {TextField} from "@material-ui/core";


const DateTimePicker = ({ onChangeDateTime, input, customClasses}) => {
    const classes = customClasses || useStyles();

    return (
        <TextField
            type={"datetime-local"}
            className={classes.root}
            value={input.value.format("YYYY-MM-DDTHH:mm")}
            onChange={onChangeDateTime}
            InputProps={{ disableUnderline: true, classes: {root: classes.input} }}
        />
    )
}


export default DateTimePicker;