import React from "react";
import {DEVICES_RESTRICTIONS_FIELD, USERS_RESTRICTIONS_FIELD} from "../constants/restrictionsFields"
import store from "../containers/app/controller/redux/store/store";

const CORE_DEVICES_ACTIVE_FEATURE = "collector_devices";

const isDevicesRestrictionExceeded = () => {
    const {liteDevices: devices} = store.getState();
    const accountRestrictions = store.getState().account.restrictions;
    if(DEVICES_RESTRICTIONS_FIELD in accountRestrictions) {
        const maxDevicesAllowed = accountRestrictions[DEVICES_RESTRICTIONS_FIELD];
        const devicesNumber = Object.keys(devices.collectorDevices).length + Object.keys(devices.publicDevices).length + Object.keys(devices.publicCloudDevices).length;

        if (devicesNumber >= maxDevicesAllowed)
            return true;
    }
    return false;
}

const isCollectorDevicesAllowed = () => {
    const {features} = store.getState().account;
    if (features.includes(CORE_DEVICES_ACTIVE_FEATURE))
        return isDevicesRestrictionExceeded();
    return true;
}

const isUsersRestrictionExceeded = () => {
    const {data : users} = store.getState().userReducer.users || []
    const accountRestrictions = store.getState().account.restrictions;
    if(USERS_RESTRICTIONS_FIELD in accountRestrictions) {
        const maxUsersAllowed = accountRestrictions[USERS_RESTRICTIONS_FIELD];
        if (users.length >= maxUsersAllowed)
            return true;
    }
    return false;
}

export {
    isDevicesRestrictionExceeded,
    isUsersRestrictionExceeded,
    isCollectorDevicesAllowed
}