import React, { PureComponent, useState } from 'react';
import { withRouter } from "react-router";
import { compose } from "redux";
import { connect } from 'react-redux';

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { registered } = this.state;

        const lineStyle = { fontWeight: 400 };
        return (
            <div className="form-layout">
                <div className="form-wrapper">
                    <div className="form-holder">
                        <div className='form-logo' />
                        <div className="form-welcome">
                        Service Evaluation Agreement
                    </div>
                        <div style={{ height: 700, width:800, overflowY: "scroll"}} >
                            <p><span style={lineStyle}>THIS AGREEMENT (THE &ldquo;</span><strong>AGREEMENT</strong><span style={lineStyle}>&rdquo;) GOVERNS THE USE OF THE NETWORK VISIBILITY AND CYBERSECURITY SERVICES FOR THE INFERENCE OF COMPLETE NETWORK VISIBILITY (THE &ldquo;</span><strong>SERVICES</strong><span style={lineStyle}>&rdquo;), WHICH ARE PROVIDED BY CYNAMICS, INC. (&ldquo;</span><strong>CYNAMICS</strong><span style={lineStyle}>&rdquo;) ON EVALUATION BASIS.</span></p>
                            <p><span style={lineStyle}>THE PERSON OR LEGAL ENTITY (AN INDIVIDUAL, A COMPANY, A PARTNERSHIP, OR ANY OTHER LEGAL ENTITY) SEEKING THE SERVICES, AS IDENTIFIED BY THE DETAILS PROVIDED DURING THE REGISTRATION PROCESS (&ldquo;</span><strong>CUSTOMER</strong><span style={lineStyle}>&rdquo;) UNDERSTANDS AND ACKNOWLEDGES THAT BY CLICKING THE BUTTON &ldquo;I HAVE READ, UNDERSTOOD &amp; AGREE&rdquo;, IT IS EXECUTING THIS AGREEMENT AND AGREEING TO BE CONTRACTUALLY BOUND BY ITS TERMS AND IT SHALL CONSTITUTE A BINDING CONTRACT BETWEEN THE CUSTOMER AND CYNAMICS, IN THE SAME WAY THAT A PAPER CONTACT IS LEGALLY BINDING. CYNAMICS AND THE CUSTOMER MAY EACH BE REFERRED TO AS A &ldquo;</span><strong>PARTY</strong><span style={lineStyle}>&rdquo; AND BOTH COLLECTIVELY, THE &ldquo;</span><strong>PARTIES</strong><span style={lineStyle}>&rdquo;.&nbsp;</span></p>
                            <p><strong>An individual under the age of 18 may not enter into this Agreement, whether as a Customer or on behalf of one.</strong></p>
                            <p><span style={lineStyle}>IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE AUTHORITY TO BIND SUCH ENTITY AND ITS AFFILIATES TO THESE TERMS AND CONDITIONS, IN WHICH CASE THE TERMS &ldquo;</span><strong>YOU</strong><span style={lineStyle}>&rdquo; OR &ldquo;</span><strong>YOUR</strong><span style={lineStyle}>&rdquo; SHALL REFER TO SUCH ENTITY AND ITS AFFILIATES.&nbsp; IF YOU DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT AGREE WITH THESE TERMS AND CONDITIONS, YOU MUST NOT ACCEPT THIS AGREEMENT AND MAY NOT USE THE SERVICES.</span></p>
                            <ol>
                                <li style={lineStyle}><strong>Definitions</strong><strong>.</strong><strong>&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&ldquo;</span><strong>Confidential Information</strong><span style={lineStyle}>&rdquo; of a Party (referred to as a &ldquo;</span><strong>Discloser</strong><span style={lineStyle}>&rdquo;) means any and all technical,&nbsp; non-technical, business, financial, marketing or sales information or materials &ndash; which the Discloser makes accessible, discloses to or provides the other Party (referred to as a &ldquo;</span><strong>Recipient</strong><span style={lineStyle}>&rdquo;) by virtue of this Agreement, and which is identified as &ldquo;confidential&rdquo; or &ldquo;proprietary&rdquo; or which, given its nature or circumstances, reasonably ought to be considered confidential or proprietary, provided however, that Confidential Information shall not include information or materials that, as can be demonstrated by the Recipient&rsquo;s tangible records: </span><strong><em>(a)</em></strong><span style={lineStyle}> is rightfully in the public domain through no fault of the Recipient; </span><strong><em>(b)</em></strong><span style={lineStyle}> is lawfully obtained by the Recipient from any source other than the Discloser, free of any obligation to keep it confidential; </span><strong><em>(c)</em></strong><span style={lineStyle}> was known to the Recipient prior to receiving it from the Discloser; or </span><strong><em>(d)</em></strong><span style={lineStyle}> is independently developed by the Recipient without access, use or knowledge of the Discloser&rsquo;s Confidential Information.&nbsp;</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&ldquo;</span><strong>Dashboard</strong><span style={lineStyle}>&rdquo; means the web-based interface through which Customer may access and use the functionality and features of the Service.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;&ldquo;</span><strong>Feedback</strong><span style={lineStyle}>&rdquo; means information or content concerning enhancements, changes or additions to the Service that Customer requests, desires or suggests.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&ldquo;</span><strong>Meta-Data</strong><span style={lineStyle}>&rdquo; means communications meta-data (i.e., not the content of transmitted data) that is indicative of anomalies and potential threats in Customer&rsquo;s network.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&ldquo;</span><strong>Output Data</strong><span style={lineStyle}>&rdquo; means the various reports, datasets and other information that the Service may generate, provide or make available to Customer, whether through the Service&rsquo;s web-based interface, an output file, or otherwise.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;&ldquo;</span><strong>User</strong><span style={lineStyle}>&rdquo; means an individual who is authorized by Customer to use the Service, for whom Customer has ordered the Service, and to whom We have supplied user identification and password. Users may include, for example, Customer&rsquo;s employees, consultants, contractors and agents.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&ldquo;</span><strong>We</strong><span style={lineStyle}>&rdquo;,</span> <span style={lineStyle}>&ldquo;</span><strong>Us</strong><span style={lineStyle}>&rdquo;</span> <span style={lineStyle}>or</span> <span style={lineStyle}>&ldquo;</span><strong>Our</strong><span style={lineStyle}>&rdquo; means Cynamics, Inc., a Delaware corporation.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Interpretation</strong><strong>.</strong><strong>&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;The section headings in this Agreement are included for convenience and orientation only and will take no part in the interpretation of the Agreement.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;The term &ldquo;including&rdquo;, as used in the Agreement, means including but not limited to and without limitation to the generality of the preceding provision or phrase.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Scope of Agreement</strong><strong>.&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;The Service is hereby granted to You on an evaluation basis at no charge, in the framework of which You shall be permitted to use the Service only to determine whether it is suitable for Your business purposes.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;This Agreement is effective upon your acceptance of this Agreement (the &ldquo;</span><strong>Effective Date</strong><span style={lineStyle}>&rdquo;) and will terminate upon the lapse of three (3) months thereafter, unless ended earlier in accordance with this Agreement (the &ldquo;</span><strong>Evaluation Period</strong><span style={lineStyle}>&rdquo;). If You would like to use the Service after the Evaluation Period, provided that Cynamics has made the Services commercially available, You must purchase a subscription to such Services from Cynamics under a separate contract.&nbsp;</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;We may discontinue the Services at any time in Our sole discretion and may never make them generally available. You acknowledge that any use made by You of the Service is done entirely at Your own risk. We will have no liability for any harm or damage arising out of or in connection with the Service.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Rights Granted</strong><strong>.</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer may, on a non-exclusive, non-transferable, non-assignable basis, during the Evaluation Period </span><strong><em>(i)</em></strong><span style={lineStyle}> access and use the Service strictly for its internal business purposes, and </span><strong><em>(ii)</em></strong><span style={lineStyle}> access, use and further disseminate the Output Data for any reasonable business purpose.</span></li>
                                    <li style={lineStyle}><strong>&nbsp;</strong><span style={lineStyle}>Following the lapse of the Evaluation Period, the continued use of the Service shall be subject to the purchase of an applicable subscription.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Setup of User Account &amp; Training</strong><strong>.&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;In the framework of registration process, you will be requested to set up a user account and define related settings on Your network as required for the use of the Service, which will require, </span><em><span style={lineStyle}>inter alia</span></em><span style={lineStyle}>, the provision of true, accurate and complete information by the Customer as reasonably requested by Cynamics.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Login to the Dashboard is authenticated with a password, which Customer is to periodically change. Customer must maintain the confidentiality of its account login details.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Data</strong><strong>.&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer is solely and exclusively responsible for all actions it may take in relation to the Output Data. Cynamics is not responsible or liable for Customer&rsquo;s reliance upon, or use of, the Output Data, its actions in connection with the Output Data, or any consequences resulting therefrom.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer acknowledges and agrees that Meta-Data will be used to provide the Service to the Customer, to conduct administrative and technical activities necessary to provide the Service to the Customer, and to improve Cynamics&rsquo;s and the Service&rsquo;s ability to detect malicious behavior and cybersecurity risks.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer acknowledges and agrees that Cynamics will process, handle and use (by itself or using trusted third party service providers such as cloud service providers) the Feedback in order to provide the Service, as well as value-added services, to Customer and other customers, to enhance the Service, to develop new products and services, for research and testing and for any other purpose Cynamics determines. Customer will not be entitled to any remuneration from Cynamics, for its use of such Feedback.</span></li>
                                    <li style={lineStyle}><strong>&nbsp;ANY DATA COLLECTED BY US OR DATA YOU PROVIDED FOR THE PURPOSE OF RECEIVING THE SERVICES, AND ANY CUSTOMIZATIONS MADE TO THE SERVICES BY OR FOR YOU, DURING THE EVALUATION PERIOD WILL BE PERMANENTLY LOST UNLESS YOU PURCHASE A SUBSCRIPTION TO THE SAME SERVICES AS THOSE COVERED HEREUNDER OR PURCHASE UPGRADED SERVICES BEFORE THE END OF THE EVALUATION PERIOD. YOU CANNOT TRANSFER DATA PROVIDED OR CUSTOMIZATIONS MADE DURING EVALUATION PERIOD TO A SERVICE THAT WOULD BE A DOWNGRADE FROM THAT COVERED HEREUNDER.&nbsp; THEREFORE, IF YOU PURCHASE A SERVICE THAT WOULD BE A DOWNGRADE FROM THAT COVERED HEREUNDER, YOUR DATA WILL BE PERMANENTLY LOST.</strong><span style={lineStyle}>&nbsp;</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Support</strong><strong>.</strong><span style={lineStyle}> The Service is provided hereunder with the purpose of providing you with the opportunity to evaluate the Service before the purchase thereof. Cynamics is not obligated to provide any technical support or updates for the Service within the Evaluation Period. Notwithstanding the foregoing, Cynamics may provide you with access to certain help features as part of the evaluation via </span><a href="mailto:support@cynamics.ai"><span style={lineStyle}>support@cynamics.ai</span></a><span style={lineStyle}> (or such other address as Cynamics may designate by notice to the Customer hereunder), and if so provided, you may use this feature to contact Cynamics with respect to any technical questions, problems and inquiries regarding the Service (the &ldquo;</span><strong>Support Requests</strong><span style={lineStyle}>&rdquo;). Cynamics will attempt to respond to Support Requests within a reasonable time.&nbsp;</span></li>
                                <li style={lineStyle}><strong>Termination</strong><strong>.</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Services provided under this Agreement shall be provided for the Evaluation Period, unless earlier terminated in accordance herewith.&nbsp;</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Either Party may terminate this Agreement at any time.&nbsp;</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Immediately upon termination of this Agreement: </span><strong><em>(i)</em></strong><span style={lineStyle}> Cynamics may terminate Customer&rsquo;s account on the Service and delete data stored in its systems; and </span><strong><em>(ii)</em></strong><span style={lineStyle}> Customer must cease any and all use of the Service.&nbsp;</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Sections in this Agreement that by their purpose of nature should survive termination of this Agreement, will so survive.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Restrictions</strong><strong>.</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Except as relevant law may otherwise require to be permitted, Customer may not modify, make derivative works of, disassemble, de-compile or reverse engineer any part of the Service, or otherwise attempt to discover its underlying code, structure, implementation or algorithms.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer may not use the Service in order to develop, or create, or permit others to develop or create, a product or service similar or competitive to the Service.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Except for the Output Data, Customer may not offer the Service to third parties, including by reselling, licensing, renting, leasing, transferring, lending, timesharing, assigning or redistributing the Service or any part thereof.&nbsp;</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer may not perform or attempt to perform any of the following in connection with the Service:&nbsp;</span></li>
                                    <ol>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;Breaching the security of the Service, identifying, probing or scanning any security vulnerabilities in the Service;&nbsp;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;Accessing data not intended for Customer, or accessing an account Customer is not authorized to access;&nbsp;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;&nbsp;Interfering with, circumventing, manipulating, overloading, impairing or disrupting the operation, or the functionality of the Service;&nbsp;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;&nbsp;Working around any technical limitations in the Service;&nbsp;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;&nbsp;Using any tool to enable features or functionalities that are otherwise disabled, inaccessible or undocumented in the Service;&nbsp;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;&nbsp;Collecting or processing information or data about the Service&rsquo;s subscribers;&nbsp;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;&nbsp;Sending any virus, worm, Trojan horse or other malicious or harmful code or attachment;</span></li>
                                        <li style={lineStyle}><span style={lineStyle}>&nbsp;&nbsp;Using robots, crawlers and similar applications to scrape, harvest, collect or compile content from or through the Service.</span></li>
                                    </ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Customer may not use the Service for any activity that constitutes, or encourages conduct that would constitute, a criminal offense, give rise to civil liability or otherwise violate any applicable law.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;We may employ measures to detect and prevent fraudulent or abusive use of the Service, as well as misuse of the Service. Cynamics may suspend or terminate Customer&rsquo;s account on and access to the Service, without prior notice, if Cynamics, in its sole discretion, believes that Customer has engaged in fraudulent or abusive use, or misuse, of the Service.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Confidential</strong> <strong>Information</strong><strong>.</strong><span style={lineStyle}>&nbsp;</span></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Subject to Section &lrm;6, during the Evaluation Period and for a period of three (3) years after termination of this Agreement, each Party will maintain strict confidentiality of the Confidential Information of the other Party in its capacity as a Discloser. The Recipient will not disclose the Discloser&rsquo;s Confidential Information or have the Discloser&rsquo;s Confidential Information disclosed, directly or indirectly to any third party, or make copies of the Discloser&rsquo;s Confidential Information, except as specifically required to perform this Agreement, or subject to the Discloser&rsquo;s prior written permission. Except as otherwise expressly agreed in writing by a Discloser, the Recipient shall not alter, reverse engineer, decompile, disassemble or otherwise modify the Confidential Information.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;The Recipient will take precautions to maintain the confidentiality of the Discloser&rsquo;s Confidential Information, with the same degree of care used to protect its own proprietary data, but in no case less than reasonable care. The Confidential Information may be disclosed only to employees or contractors of a Recipient with a &ldquo;need to know&rdquo; who are instructed and agree not to disclose the Confidential Information and not to use the Confidential Information for any purpose, except as set forth herein. Recipient shall have appropriate written agreements with any such employees or contractors sufficient to comply with the provisions of this Agreement.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>Nothing herein shall be construed as granting or conferring any rights by license or otherwise in the Confidential Information except as expressly provided herein.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Notwithstanding the foregoing, the Discloser agrees and acknowledges that the Recipient may, wholly or partially, disclose the Confidential Information: </span><strong><em>(i)</em></strong><span style={lineStyle}> to the extent required in order to comply with a legal obligation including, orders, subpoenas, decrees or request prescribed by a competent judicial, administrative or regulatory authority; or </span><strong><em>(ii)</em></strong><span style={lineStyle}> to enforce the Parties&rsquo; rights under this Agreement.&nbsp;</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Intellectual Property</strong><strong>.</strong><strong>&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Cynamics retains all right, title, and interest in and to the Service, including without limitation all software used to provide the Service and all logos and trademarks reproduced through the Service, and this Agreement does not grant Customer any intellectual property rights in the Service or any of its components. The Service is made available for use and access on an evaluation basis, and is not sold or licensed.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Except for Customer&rsquo;s limited access to use the Service and the Output Data according to the terms of this Agreement, this Agreement does not grant Customer or assigns to Customer, any license, right, title, or interest in or to the Service or the intellectual property rights associated with it. All rights, title and interest, including copyrights, patents, trademarks, trade names, trade secrets and other intellectual property rights, and any goodwill associated therewith, in and to the Service or any part thereof, and any work product or deliverable created within the scope of the services rendered pursuant thereto including computer code, graphic design, layout and the user interfaces of the Service, whether or not based on or resulting from Feedback, but excluding data Cynamics processes or is exposed to in the course of providing the Service, as well as the Output Data, are and will remain at all times, owned by, or licensed, to Cynamics. For the avoidance of any doubt, Customer will retain any and all rights, title and interest, including copyrights, trademarks, trade names, trade secrets and other intellectual property rights, and any goodwill associated therewith, in and to its data.</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Disclaimer</strong> <strong>of</strong> <strong>Warranty</strong><strong>.</strong><strong>&nbsp;</strong></li>
                                <ol>
                                    <li style={lineStyle}><span style={lineStyle}>&nbsp;Although Cynamics uses skill and efforts to develop the Service and have the Service operate properly and provide accurate Output Data, as a service that relies on back-end software, computer algorithms and third party networks and continuous internet connectivity, Cynamics does not guarantee, makes no representation, and provides no warranty about the reliability, effectiveness, accuracy or completeness of the Service or Output Data, the expected business results, outcome or yields or any other operational benefits from utilizing the Service.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>The Services are provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. Cynamics disclaims all warranties and representations, either express or implied, with respect to the Services and any part thereof, including, without limitation, any warranties of fitness for a particular purpose, quality, non-infringement, title, compatibility, performance, security or accuracy. Cynamics does not warrant that </span><strong><em>(i)</em></strong><span style={lineStyle}> the Services will operate in an uninterrupted or error-free manner or that any defects or errors in the Services will be corrected, </span><strong><em>(ii)</em></strong><span style={lineStyle}> the Services will operate in combination with your content or your applications, or with any other software, hardware, systems, or data, </span><strong><em>(iii)</em></strong><span style={lineStyle}> that the Services will always be available, free from errors, omissions or malfunctions, </span><strong><em>(iv)</em></strong><span style={lineStyle}> your content and your applications will be secure or not otherwise lost or damaged, or </span><strong><em>(v)</em></strong><span style={lineStyle}> the Services will meet your requirements or expectations.</span></li>
                                    <li style={lineStyle}><span style={lineStyle}>Cynamics reserves the right to make changes or updates to the Services at any time without notice.&nbsp;</span></li>
                                </ol>
                                <li style={lineStyle}><strong>Limitation of Liability</strong><strong>. </strong><span style={lineStyle}>To the maximum extent permitted by law, Cynamics, its officers, directors, shareholders, employees, sub-contractors and agents will have no liability of any nature whatsoever, for any direct, indirect, incidental, punitive or consequential damage, or any other damage, and loss (including loss of profit and loss of data), costs, expenses and payments, even if such damages were foreseen, whether arising in contract, tort, delict, negligence of any degree, strict liability or any other legal theory, with respect to the Services or the Output Data, their use or the inability to use them, their content, from any failure, error, or breakdown in the function of the Services, or from reliance on or in connection with the Services or the Output Data, or from any fault, or error made by Cynamics staff or anyone acting on its behalf. You acknowledge that any use made by You of the Service is done entirely at Your own risk. We will have no liability for any harm or damage arising out of or in connection with a Service.</span><strong>&nbsp;</strong></li>
                                <li style={lineStyle}><strong>Indemnity</strong><strong>. </strong><span style={lineStyle}>Customer agrees to indemnify and hold harmless Cynamics and its directors, officers, employees, and subcontractors, upon Cynamics&rsquo; request and at Customer&rsquo;s own expense, from, and against, any damages, loss, costs, expenses and payments, including reasonable attorney&rsquo;s fees and legal expenses, arising from any third party complaint, claim, plea, or demand in connection with Customer&rsquo;s breach of any provision or representation in this Agreement.</span></li>
                                <li style={lineStyle}><strong>Relationship of the Parties</strong><strong>.</strong><span style={lineStyle}> The relationship between the Parties hereto is strictly that of independent contractors, and neither party is an agent, partner, joint venturer or employee of the other.</span></li>
                                <li style={lineStyle}><strong>Assignment</strong><strong>.</strong><span style={lineStyle}> Customer may not assign this Agreement without Cynamics&rsquo; prior written consent, which it shall not unreasonably withhold or deny. Any purported assignment without such prior written consent is void. To the greatest extent permissible by law, Cynamics may assign this Agreement, including all right, duties, liabilities, performances and obligations herein, upon notice to the Customer and without obtaining its further specific consent, to a third-party, upon a merger, acquisition, change of control or the sale of all or substantially all of Cynamics&rsquo; equity or assets.&nbsp;</span></li>
                                <li style={lineStyle}><strong>Subcontracting</strong><strong>. </strong><span style={lineStyle}>Cynamics may subcontract or delegate the performance of its obligations under this Agreement, or the provision of the Service (or any part thereof), to any third party of its choosing. Customer acknowledges and agrees that the technical means by which Cynamics provides the Service is at its sole discretion.</span></li>
                                <li style={lineStyle}><strong>Force Majeure</strong><strong>.</strong><span style={lineStyle}> Neither of the Parties shall be responsible for events beyond its reasonable control. Both Parties will use reasonable efforts to mitigate the effects of a force majeure event.</span></li>
                                <li style={lineStyle}><strong>Complete Terms &amp; Severability</strong><strong>.</strong><span style={lineStyle}> This Agreement constitutes the entire and complete agreement between Customer and Cynamics concerning the subject matter herein. This Agreement supersedes all prior oral or written statements, understandings, negotiations and representations with respect to the subject matter herein. If any provision of this Agreement is held invalid or unenforceable, that provision shall be construed in a manner consistent with the applicable law to reflect, as nearly as possible, the original intentions of the parties, and the remaining provisions will remain in full force and effect. This Agreement may be modified or amended only in writing, signed by the duly authorized representatives of both parties.</span></li>
                                <li style={lineStyle}><strong>No waiver</strong><strong>.</strong><span style={lineStyle}> Neither party will, by mere lapse of time, without giving express notice thereof, be deemed to have waived any breach, by the other party, of any terms or provisions of this Agreement. The waiver, by either party, of any such breach, will not be construed as a waiver of subsequent breaches or as a continuing waiver of such breach.</span></li>
                                <li style={lineStyle}><strong>Miscellaneous</strong><strong>.&nbsp; </strong><span style={lineStyle}>Except to the extent this Agreement provides otherwise, all costs and expenses which a Party has incurred or must incur in preparing, concluding or performing this Agreement are for its own account. This Agreement shall be governed by the laws of the State of Delaware without regard to the conflict of law principals thereof. The competent courts in the State of Delaware and of the United States of America located in such state shall have exclusive jurisdiction over any matter arising in relation to this Agreement.</span></li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="layout-image" />
            </div>
        );
    }
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
)(Terms)