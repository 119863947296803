import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    popOverPaper: {
        width:"300px",
        // minHeight: "350px",
        backgroundColor: '#fff !important',
        fontSize: "13px",
    },
    mainGrid:{
        height: "100%",
        boxSizing: "border-box"
    },
    dateGrid:{
        minWidth:"100%",
        boxSizing: "border-box",
        backgroundColor:"#071c36",
        fontSize: "18px",
        fontWeight: "bold",
        textAlign: "center",
        color:"#ffffff",
        padding:"5%"
    },
    dayStatisticGrid:{
        minWidth:"100%",
        boxSizing: "border-box"
    },
    recentAlertsLabelGrid:{
        minWidth:"100%",
        boxSizing: "border-box",
        backgroundColor:"#7287a5",
        paddingLeft: "10%",
        fontWeight: "bold",
        color:" #ffffff",
        fontSize: "16px"
    },
    recentAlertsTableGrid:{
        minWidth:"100%",
        boxSizing: "border-box"
    },
    titlePaper : {
        backgroundColor: '#f5f9ff !important',
        textAlign: "left",
        fontSize: '13px',
        fontWeight: 'bold',
        width:"100%",
        lineHeight: 1.25,
        letterSpacing: "normal",
        color: "$dark",
        margin: "10px",
    }
}));

export {useStyles}