import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100%",
        paddingLeft: "20px",
        paddingRight: "30px",
        paddingTop: "15px"
    },
    title: {
        paddingTop: "40px",
        display: "flex",
        alignItems: "center",
        height: "25%",
        fontSize: "30px",
        fontWeight: "bold",
        lineHeight: "normal"
    },
    v2Description: {
        display: "flex",
        height: "50%",
        paddingTop: "10%",
        fontSize: "16px",
        color: theme.palette.dialogDescription.main
    },
    v2Image: {
        display: "flex",
        alignItems: "center"
    }
}));

export {useStyles}