import React from "react";
import step1Side from "../../../../../../../assets/walkthrough/analysis/steps/step1Side.png";
import step5Main from "../../../../../../../assets/walkthrough/analysis/steps/step5Main.png"
import {useStyles} from "./step5.css";
import StepTemplate from "../../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV5
    from "../../../../../../../shared/components/stepTemplates/mainPanelTemplateV5/mainPanelTemplateV5";

const TITLE = "Source / Destination IP or MAC";
const DESCRIPTION = "Filter specific IP/MAC addresses or select one of your pre-defined groups:";
const SECOND_BULLET_POINT = "MAC filtering available on supported devices";
const FIRST_BULLET_POINT_TEXT = "You can include/exclude by clicking on the ";
const INC_EXC_OPTIONS = "+/-";

const Step5 = () => {
    const classes = useStyles();
    const firstBulletPoint = (
        <>
            {FIRST_BULLET_POINT_TEXT} <span className={classes.example}>{INC_EXC_OPTIONS}</span>
        </>
    )
    return (
        <StepTemplate sidePanelImage={step1Side}>
            <MainPanelTemplateV5 title={TITLE}
                                 image={<img src={step5Main}/>}
                                 bulletPoints={[firstBulletPoint, SECOND_BULLET_POINT]}
                                 description={DESCRIPTION} />
        </StepTemplate>
    )
}

export default Step5;