import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	contentContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'stretch',
		flexDirection: 'column',
	},
	formPadding: {
		padding: '30px 36px 0px 36px',
		marginBottom: '0px',
	},
	titleSubTitleContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	title: {
		fontWeight: 700,
		fontSize: '14px',
		lineHeight: '16px',
		color: '#071C36',
		marginBottom: '10px',
	},
	subTitle: {
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '19px',
		color: '#425262',
	},
	halfWidthTextFieldsContainer: {
		display: 'flex',
		flexDirection: 'row',
		gridColumnGap: '12px',
	},
	textField: {
		width: '100%',
		padding: 0,
	},
	selectionButtonsContainer: {
		'& > *': {
			marginRight: '8px',
		},
		display: 'flex',
		flexDirection: 'row',
	},
	submitButtonContainer: {
		width: '100%',
		minHeight: 50,
		maxHeight: 50,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	submitButton: {
		fontWeight: 600,
		fontStyle: 'normal',
		fontSize: '20px',
		lineHeight: '16px',
		padding: '16px 60px',
	},
	helperText: {
		fontStyle: 'normal',
		fontWeight: 400,
		fontSize: '12px',
		lineHeight: '19px',
		color: '#7F7F7F',
	},
});

export default useStyles;
