import http from "../../../../services/httpService/http";
import {SMS} from "../constants/integrationTypes";
import {INTEGRATIONS_ALL_PATH, INTEGRATION_SMS_PATH, INTEGRATIONS_ENABLED_PATH} from "../constants/urls";

const getSmsEmailIntegrations = (config) => {
    return http.get(INTEGRATIONS_ALL_PATH, config);
}

const activateSmsIntegration = (newValue) => {
    return http.put(INTEGRATIONS_ENABLED_PATH, {type: SMS, newValue: newValue});
}

const updateSmsIntegrations = (phoneNumbers) => {
    return http.put(INTEGRATION_SMS_PATH, phoneNumbers);
};

export {
    getSmsEmailIntegrations,
    activateSmsIntegration,
    updateSmsIntegrations
}