import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		border: '1px solid',
		borderColor: theme.palette.table.border,
	},
	tableRow: {
		backgroundColor: 'white',
		paddingTop: '2em',
		paddingBottom: '2em',
		width: '100%',
	},
	head: {
		backgroundColor: theme.palette.secondary.main,
		borderRadius: '0.2em',

	},
	headerTextColor: {
		color: 'white !important',
		fontWeight: 400,
		fontSize: '16px',
		minWidth: '150px',
	},
	messageWrapper: {
		marginTop: '10%',
	},
}));

export { useStyles };
