import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		display: 'flex',
		alignContent: 'center',
		padding: '0!important',
		margin: '2px!important',
	},
	hasType: {
		backgroundColor: `${theme.palette.primary.dark}!important`,
	},
	chipLabel: {
		padding: '0 0 0 6px',
	},
}));

export default useStyles;
