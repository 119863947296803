import React from 'react';
import PropTypes from "prop-types";
import {IconButton, SvgIcon} from "@material-ui/core";
import {useStyles} from "./ExitButton.css";

const propsType = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired
}

const defaultProps = {
    width: '16',
    height: '16',
    color: 'black',
    viewBox: '-5 -5 25 25'

}


function ExitButton({width, height, viewBox, onClick, styles, color}) {
    const classes = useStyles();

    return (
        <IconButton onClick={onClick} className={classes.button}>
            <SvgIcon width={width} height={height} viewBox={viewBox} >
                <path d="M1 1L15 14.6552" stroke={color} stroke-width="2"/>
                <path d="M15 1L7.52319 8.29266L1 14.6552" stroke={color} stroke-width="2"/>
            </SvgIcon>
        </IconButton>

    )
}


ExitButton.prototype = propsType;
ExitButton.defaultProps = defaultProps;
export default ExitButton;