// MfaSelector.jsx
export const MFA_SELECTOR_HEADLINE_TITLE = 'Secure your account by adding verification to your password.';
export const MFA_SELECTOR_STEP_NUMBER = 'Step 1';
export const MFA_SELECTOR_STEP_SUBTITLE = 'How would you like to be authenticated? ';
export const AUTHENTICATOR_APP_METHOD_LABEL = 'Authenticator App';
export const SMS_METHOD_LABEL = 'SMS';

// SmsMfaMethod.jsx
export const MFA_SETUP_SUCCESSFULLY_SETTLED = 'Your MFA has been set-up successfully';
export const MFA_SETUP_FAILED_SETTLED = 'Something went wrong.. Please try again';

// ConfigureSmsMFA.jsx
export const CONFIGURE_SMS_STEP_NUMBER = 'Step 2';
export const CONFIGURE_SMS_STEP_SUBTITLE = 'Add your phone number to Cynamics to set-up MFA:';

// ConfigureExistingPhoneMfa.jsx
export const CONFIGURE_EXISTING_PHONE_MFA_DISCLAIMER_TEXT = 'You were previously authenticated by the number';
export const CONFIGURE_EXISTING_PHONE_MFA_DISCLAIMER_QUESTION = 'How would you like to continue?';

// QrVerification.jsx
export const CONFIGURE_QR_STEP_NUMBER = 'Step 2';
export const QR_VERIFICATION_FIRST_STEP_TEXT = '1. Scan the QR code using a Authenticator app';
export const QR_VERIFICATION_SECOND_STEP_TEXT = '2. Once you’ve scanned the QR code, complete the set-up by entering the code listed in Authenticator app.';

export const QR_MFA_SETUP_SUCCESSFULLY_SETTLED = 'Your Varification Code has been set-up successfully';
export const QR_MFA_SETUP_FAILED_SETTLED = 'Something went wrong.. Please try again';
