import { makeStyles } from '@material-ui/core/styles';
import BackgroundImg from "../../../assets/plans/background.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%"
    },

    stepper: {
        backgroundColor: theme.palette.advancedButtons.main,
        dotActive: {
            backgroundColor: "red"
        }
    },
    buttons: {
        color: "white"
    },
    backButton: {
        marginTop: "auto",
        marginBottom: "auto",
        display: "block"
    },
    iconContainer:{
        paddingLeft: "2px !important"
    },
    dots: {
        color: "white"
    },
    header: {
        height: '113px',
        backgroundImage: (props) => `url(${props.BackgroundImg})`,
        background: 'bottom',
        color: 'white',
        fontSize: '34px',
        fontWeight: 'bold'
    },
    stepsWrapperItem: {
        height: '715px'
    },
    headerBackground:{
        backgroundImage: (props) => `url(${props.BackgroundImg})`,
    },
    headerLabel:{
        textAlign:"center",
        display: "block",
        lineHeight:"112px",
    },
    stepperLabel:{
        color:theme.palette.stepsText.notActive
    },
    stepperActiveLabel:{
        color:`${theme.palette.stepsText.active} !important`
    }
}));

const smallScreenBoxDimension = {
    "@media (max-width: 1400px)":{
        width: "1200px"
    }
}

export {useStyles, smallScreenBoxDimension}