import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EyeIcon from '../../../../assets/threats/visibility-off.svg';
import PopoverPopupState from './PopoverPopupState';
import './advanceActions.scss';

class ExploreVisibility extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: this.props.isOpen || false,
		};
	}

	render() {
		const { hasEyes } = this.props;
		const { isOpen } = this.state;

		return (
			<div className="exploration">
				<div className="explore">
					{hasEyes ? <div><img src={ EyeIcon } alt="" /></div> : null}
					<div>
						<PopoverPopupState
							title={ this.props.title || ' Click to view ' }
							isOpen={ isOpen } content={ this.props.content } /></div>
				</div>
			</div>);
	}
}

ExploreVisibility.propTypes = {
	content: PropTypes.object,
	hasEyes: PropTypes.bool,
	isOpen: PropTypes.bool,
	title: PropTypes.string,
};

export default ExploreVisibility;
