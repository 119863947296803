import http from '../../../../services/httpService/http';
import { EMAIL } from '../constants/integrationTypes';
import { INTEGRATIONS_EMAIL_PATH, INTEGRATIONS_ENABLED_PATH } from '../constants/urls';

const activateEmailIntegration = newValue => http.put(INTEGRATIONS_ENABLED_PATH, { type: EMAIL, newValue });

const updateEmailIntegrations = emails => http.put(INTEGRATIONS_EMAIL_PATH, emails);

export {
	activateEmailIntegration,
	updateEmailIntegrations,
};

