import { Grid } from '@material-ui/core';
import React from 'react';
import StyleButton from '../../../../../../shared/components/form/Buttons/StyleButton';
import { deviceTypes } from '../../../devicesManagementConfig';
import { useStyles } from './configuringTypeSteps.css';
import CynamicsFlowDetails from './CynamicsFlowDetails';
import PropTypes from 'prop-types';

const PUBLIC_DEVICE_TYPE = deviceTypes.PUBLIC_DEVICE;
const COLLECTOR_DEVICE_TYPE = deviceTypes.CORE_DEVICE;

const ScriptSteps = ({ downloadDeviceCallBack }) => {
	const classes = useStyles();

	return (
		<Grid container className={classes.containerGrid}>
			<Grid item s={5}>
                1. Download the configuration script here {'>'} <br/>
                2. Open the file with a text editor and follow the instructions at the top <br/>
                3. Copy and Paste the commands to the device CLI
			</Grid>
			<Grid item xs={2}>
				<StyleButton label={'Download'} onClick={downloadDeviceCallBack} variant="contained" className={classes.button}/>
			</Grid>
		</Grid>);
};

ScriptSteps.propTypes = {
	downloadDeviceCallBack: PropTypes.func,
};

const GuideSteps = ({ downloadDeviceCallBack }) => {
	const classes = useStyles();
	return (
		<Grid container className={classes.containerGrid}>
			<Grid item xs={8}>
                1. Download the configuration guide here {'>'} <br/>
                2. Follow the steps on the download file
			</Grid>
			<Grid item xs={2}>
				<StyleButton label={'Download'} onClick={downloadDeviceCallBack} variant="contained" className={classes.button}/>
			</Grid>
		</Grid>);
};

GuideSteps.propTypes = {
	downloadDeviceCallBack: PropTypes.func,
};
const NoneSteps = ({ deviceType }) => {
	const classes = useStyles();
	return (
		<Grid xs={5} className={classes.containerNoneGrid}>
			<Grid container>
				<Grid item xs={12}>
					{deviceType === PUBLIC_DEVICE_TYPE
                        && <>
                            Configure the device with the following Collectors details, <br/>
                            or contact Cynamics support for configuration details <br/>
                        </>}
					{deviceType === COLLECTOR_DEVICE_TYPE
                        && <>
                            Configure the device with the Cynamics Collector details, <br/>
                            or contact Cynamics support for configuration details <br/>
                        </>}

					{deviceType === PUBLIC_DEVICE_TYPE
                        && <CynamicsFlowDetails style={{ marginTop: '2%' }}/>}
				</Grid>
			</Grid>
		</Grid>);
};

NoneSteps.propTypes = {
	deviceType: PropTypes.string,
};
export default { guide: GuideSteps,
	script: ScriptSteps,
	none: NoneSteps,
	awsCloudDevice: GuideSteps,
	gcpCloudDevice: GuideSteps,
};
