import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignContent: 'center',
		marginRight: '1em',
	},
	icon: {
		color: '#48648B',
	},
	highlighted: {
		color: theme.palette.severity.medium,
	},
	remediation: {
		marginTop: '0.1em',
		width: '20px',
		height: '16px',
	},
	disabledRemediation: {
		opacity: 0.6,
		cursor: 'default',
	},
}));

export default useStyles;
