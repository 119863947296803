import React from 'react';
import {Paper, Typography} from "@material-ui/core";
import ContactSalesForm from "./forms/contactSalesFrom";
import Grid from "@material-ui/core/Grid";
import {useStyles} from "./conactSalesForm.css";
import {sendRequestToSales} from "../services/planRequests";

function BoundedFormWithTitles({variant="" , title, subtitle, children}) {
    const classes = useStyles();

    return (

        <Paper elevation={0} variant={variant} className={classes.paper} >
            <Grid xs={12} spacing={0} alignContent={"center"} alignItems={"center"} justify={"center"}>
                <Grid item container justify={"center"}>
                    <Grid xs={12}>
                        <Typography className={classes.title} paragraph align={"center"} variant={"span"} color={"textSecondary"} component={"h1"}>{title}</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Typography className={classes.subtitle} paragraph align={"center"} variant={"body1"}>{subtitle}</Typography>
                    </Grid>
                </Grid>

                <Grid item container justify={"center"} alignItems={"center"} alignContent={"center"} xs={12}>
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Paper>


    )
}

export default BoundedFormWithTitles;