import React from 'react';
// Redux
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Switch } from 'react-router-dom';
// Page containers
import MainWrapper from './mainWrapper';
import ThankYou from '../../../registration/thankyou';
import Terms from '../../../terms/terms';
import { withRouter } from 'react-router';
import SetPassword from '../../../invitations/SetPassword';
import { MainLayoutPages } from './MainLayoutPages';
import AuthenticateUser from '../../../authenticateUser/authenticateUser';
import GetResetLinkScreen from '../../../resetPassword/screens/GetResetLinkScreen';
import ResetLinkSentScreen from '../../../resetPassword/screens/ResetLinkSentScreen';
import ChoosePasswordScreen from '../../../resetPassword/screens/ChoosePasswordScreen';
import PasswordChanged from '../../../resetPassword/screens/PasswordChangedScreen';
import ContactMe from '../../../contactMe/contactMe';

const Router = ({ user, features }) => (
	<MainWrapper>
		<main>
			<Switch>
				<Route path="/thankyou" component={ThankYou} />
				<Route path="/terms" component={Terms} />
				<Route path="/reset-password" component={GetResetLinkScreen} />
				<Route path="/reset-link-sent" component={ResetLinkSentScreen} />
				<Route path="/password-changed" component={PasswordChanged} />
				<Route path="/reset/:linkId" component={ChoosePasswordScreen} />
				<Route path="/invitations/setpassword/:id" component={SetPassword} />
				{user ? <Route path="/subscription/quote/:quoteId/contact" component={ContactMe} /> : <AuthenticateUser />}
				{user ? <Route path="/" render={props => <MainLayoutPages {...props} features={features} user={user} />} /> : <AuthenticateUser />}
			</Switch>
		</main>
	</MainWrapper>
);

const mapStateToProps = state => ({
	user: state.auth.user,
});

const mapDispatchToProps = {
};

export default compose(withRouter,
	connect(mapStateToProps, mapDispatchToProps))(Router);
