import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    copy: {
        '&>p': {
            fontWeight: 'bold',
            color: theme.palette.primary.main,
            cursor: 'pointer'
        }
    },

    linkCopied: {
        '&>p': {
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        }
    }

}));

export default useStyles;