import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { END_PHASE, START_PHASE, STEPPER_PHASE } from './const';
import { useStyles } from './walkthroughDialog.css';
import WalkthroughStepper from './walkthroughStepper/walkthroughStepper';

const WalkthroughDialog = ({ serviceName, serviceDescription, welcomeImage, StartComponent, steps,
	EndComponent, finishCallback, skipCallback }) => {
	const [currentPhase, setCurrentPhase] = useState(START_PHASE);
	const [isOpen, setIsOpen] = useState(true);
	const classes = useStyles();

	const onStepperEnd = () => {
		if (EndComponent) {
			setCurrentPhase(END_PHASE);
		} else {
			setIsOpen(false);
			finishCallback();
		}
	};

	const finish = async data => {
		finishCallback(data);
		setIsOpen(false);
	};

	const skip = async () => {
		skipCallback();
		setIsOpen(false);
	};

	const handleClose = () => {
		if (currentPhase === END_PHASE) {
			setIsOpen(false);
			finish();
		}
	};

	return (
		<Dialog classes={{ paperWidthMd: classes.root }} maxWidth={'md'} open={isOpen} onClose={handleClose}>
			{skipCallback && currentPhase === START_PHASE && <Typography className={classes.skipButton} onClick={skip}>Skip</Typography>}
			{currentPhase === START_PHASE && <StartComponent
				serviceName={serviceName}
				serviceDescription={serviceDescription}
				imageSrc={welcomeImage}
				moveToNextPhase={() => {
					setCurrentPhase(STEPPER_PHASE);
				}}/>}
			{currentPhase === STEPPER_PHASE && <WalkthroughStepper steps={steps} finishCallback={onStepperEnd} hasEndComponent={Boolean(EndComponent)}/>}
			{currentPhase === END_PHASE && <EndComponent finishCallback={finish} handleClose={handleClose}/>}
		</Dialog>
	);
};

WalkthroughDialog.propTypes = {
	serviceName: PropTypes.string,
	serviceDescription: PropTypes.string,
	welcomeImage: PropTypes.object,
	StartComponent: PropTypes.func,
	skipCallback: PropTypes.func,
	EndComponent: PropTypes.func,
	finishCallback: PropTypes.func,
	steps: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.any,
		])),
};

export default WalkthroughDialog;
