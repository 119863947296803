import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		width: '600px',
		height: '400px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-around',
		alignItems: 'center',
		padding: '20px',
	},
	image: {
		height: 'auto',
		width: '200px',
		alignItems: 'center',
		marginTop: '20px',
	},
	title: {
		fontWeight: 'bold',
		color: theme.palette.inputText.main,
		fontSize: '20px',
		textAlign: 'center',
		marginBottom: '10px',
	},
	description: {
		fontSize: '18px',
		color: theme.palette.dialogDescription.main,
		textAlign: 'center',

	},
	button: {
		marginTop: '30px',
		width: '170px',
	},
	close: {
		justifyContent: 'flex-end',
		color: theme.palette.inputText.main,
		borderRadius: '50%',
		minWidth: 'auto',
		padding: '8px',
		'&:hover': {
			'&::after': {
				borderRadius: '50%',
			},
		},
	},
}));

export { useStyles };
