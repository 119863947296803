const mapAlerts =(alerts)=> {
    return alerts.map((e) => {
        let currentData = JSON.parse(e.data);
        return Object.assign(e, {
            data: {
                originalQuery: JSON.parse(currentData.originalQuery),
                queryPayload: JSON.parse(currentData.queryPayload),
                threshold: currentData.threshold
            }
        });
    });
}

export  { mapAlerts}