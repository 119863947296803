import React, { Component } from 'react';
import { ReactComponent as CopyButton } from '../../../../assets/topTalkers/iconCopy.svg';
import './copy.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const DURATION_COPY_ANIMATION = 1500;

class copyAll extends Component {
    constructor(props) {
        super(props)
        this.state = {
            copied: false
        }
        this.timeout = {};
    }

    onCopy = (e) => {
        this.timeout = setTimeout(() => { this.setState({ copied: false }) }, DURATION_COPY_ANIMATION);
        this.setState({ copied: true });
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    render() {

        return (
            <CopyToClipboard text={ this.props.text }
                onCopy={ this.onCopy }>
                { this.state.copied == false ? <button> <CopyButton className="copy-button" /> </button> : <div className="copied">copied!</div> }
            </CopyToClipboard>


        )
    }
}


export default copyAll
