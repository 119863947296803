import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../helpers/muiTheme';

export default makeStyles(() => ({
	graphHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	graphTitle: {
		fontWeight: 700,
		fontSize: '16px',
		color: theme.palette.inputText.main,
		display: 'flex',
		alignItems: 'center',
	},
	graphActions: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	resetButton: {
		marginLeft: '16px',
		marginRight: '16px',
		color: theme.palette.text.secondary,
	},
	resetButtonLabel: {
		marginLeft: '6px',
		fontSize: '16px',
	},
	resetButtonIconDisabled: {
		fill: theme.palette.stepsText.notActive,
	},
	resetButtonIconActive: {
		fill: theme.palette.text.secondary,
	},
	zoomDropdown: {
		width: 'inherit',
	},
	graphContainer: {
		height: '95%',
		marginTop: '2%',
		marginRight: '1%',
	},
}));
