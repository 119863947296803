import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import CreateableSelect from 'react-select/creatable';
import clsx from 'clsx';

import makeReduxCreatable from '../commonSelectCreatable/makeReduxCreatable';

import useStyles from './SelectCreatable.css';

const SPECIAL_TYPES = 'group';

const SelectCreateable = ({
	onChange: saveChanges,
	value,
	options,
	validation,
	isFreeText,
	placeholder,
	createOptionOnStart,
	additionalClasses,
	extraStyles,
	customMultiValueComponent,
	noOptionsMessage = 'No Options',
	addType = () => null,
	disabled = false,
}) => {
	const classes = useStyles();

	const isValidNewOption = value => {
		if (validation) {
			return validation(value);
		}

		if (isFreeText && value) {
			return true;
		}

		return false;
	};

	const customMultiValueChip = props => {
		// eslint-disable-next-line react/prop-types
		const { data: { label, type }, innerRef } = props;

		return (
			<components.MultiValue
				className={clsx(classes.wrapper, SPECIAL_TYPES.includes(type) && classes.hasType)}
				ref={innerRef}
				{...props}
			>
				<span className={classes.chipLabel}>{label}</span>
			</components.MultiValue>
		);
	};

	const updateNewValueHandler = e => {
		let newOptions = [];
		if (e) {
			newOptions = e.map(option => {
				const type = option.type || addType(option);
				return {
					label: option.label,
					value: option.value,
					...(type && { type }),
				};
			});
		}

		saveChanges(newOptions);
	};

	return (
		<CreateableSelect
			isMulti
			isClearable
			name={'create'}
			value={value}
			onChange={updateNewValueHandler}
			isValidNewOption={isValidNewOption}
			options={options}
			placeholder={placeholder}
			createOptionPosition={createOptionOnStart ? 'first' : 'last'}
			formatCreateLabel={value => `Add free text: ${value}`}
			noOptionsMessage={() => noOptionsMessage}
			styles={extraStyles}
			className={`react-select-redux-forms react-select-underline react-select-creatable ${additionalClasses}`}
			classNamePrefix="react-select"
			isDisabled={disabled}
			components={{
				MultiValue: customMultiValueComponent || customMultiValueChip,
			}}
		/>
	);
};

SelectCreateable.propTypes = {
	addType: PropTypes.func,
	additionalClasses: PropTypes.string,
	createOptionOnStart: PropTypes.bool,
	customMultiValueComponent: PropTypes.node,
	disabled: PropTypes.bool,
	extraStyles: PropTypes.object,
	isFreeText: PropTypes.bool,
	noOptionsMessage: PropTypes.string,
	onChange: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	validation: PropTypes.func,
	value: PropTypes.any,
};

export default makeReduxCreatable(SelectCreateable);
