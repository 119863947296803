import React, {useEffect, useState} from "react";
import MakeRemovable from "../formInputs/makeRemovable";
import TextField from '../../../../../shared/components/form/TextField/TextField';
import {change, Field, untouch} from "redux-form";
import {useDispatch} from "react-redux";
import StyleButton from "../../../../../shared/components/form/Buttons/StyleButton";
import {useStyles} from './arrayField.css';
import {Grid} from "@material-ui/core";

const EMAIL_ALREADY_EXIST_ERROR_MSG = 'Email already exist'
const ADD_BUTTON_TITLE = 'Add'
const ARRAY_INPUT_FIELD_LABEL = 'Email'
const ARRAY_INPUT_FIELD_PLACEHOLDER = 'your@email.com'


const emailValidation = (value, {recipients}) => {
    let error = value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined
    const isFound = !!recipients && value && recipients.find((email) => {
        return email.toLowerCase() === value.toLowerCase()
    })
    if (isFound) {
        error = EMAIL_ALREADY_EXIST_ERROR_MSG
    }
    return error
}

const EmailArrayField = ({formName, fields, required = false}) => {
    const [newEmail, setNewEmail] = useState();
    const dispatch = useDispatch();
    const classes = useStyles()

    const handleAddItemButtonClick = () => {
        fields.push(newEmail);
        dispatch(change(formName, 'newEmail', ''));
        dispatch(untouch(formName, 'newEmail'));
        setNewEmail('')
    }

    return (
        <div className={classes.mainDiv}>
            <Grid container spacing={4}>
                <Grid item xs={10}>
                    <Field
                        name='newEmail'
                        type="text"
                        variant='outlined'
                        component={TextField}
                        onChange={(event) => setNewEmail(event.target.value)}
                        label={ARRAY_INPUT_FIELD_LABEL}
                        placeholder={ARRAY_INPUT_FIELD_PLACEHOLDER}
                        InputLabelProps={{shrink: true}}
                        validate={emailValidation}
                        className={classes.inputField}
                        required={required && fields.length === 0}
                    />
                </Grid>
                <Grid item xs={2}>
                    <StyleButton
                        label={ADD_BUTTON_TITLE}
                        disabled={!newEmail || emailValidation(newEmail, {recipients: fields.getAll()})}
                        onClick={handleAddItemButtonClick}
                        className={classes.addButton}/>
                </Grid>
            </Grid>

            <div className={classes.list}>
                {
                    fields.map((value, index) => {
                        return (
                            <>
                                <MakeRemovable
                                    className={'remove-button-email'}
                                    handleRemove={(e) => {
                                        e.preventDefault();
                                        fields.remove(index);
                                    }}
                                    key={index}>
                                    {fields.get(index)}
                                </MakeRemovable>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}
export default (EmailArrayField);