import React, { useState} from "react";
import {Checkbox} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import StyleButton from "../../../../../shared/components/form/Buttons/StyleButton";
import configurationTypeSteps from "./configuringTypeSteps/configurationTypeSteps"
import {useStyles} from "./configuringDevice.css"
import ConfiguringProcessImage from "../../../../../assets/deviceConfiguringProcess/configuring-process.svg"
import PropTypes from "prop-types";


const propTypes = {
    downloadDeviceCallBack: PropTypes.func.isRequired,
    deviceBrandName: PropTypes.string.isRequired,
    deviceModelName: PropTypes.string.isRequired,
    setIsConfiguredDevice: PropTypes.string.isRequired,
    closeConfiguringProcess: PropTypes.func.isRequired,
    selectedDeviceConfigType: PropTypes.string,
}


const ConfiguringDevice = ( { downloadDeviceCallBack, deviceBrandName, deviceModelName, setIsConfiguredDevice, deviceType, iAmAwareText, selectedDeviceConfigType='none' } ) => {

    const [isAware, setIsAware] = useState(false)
    const classes = useStyles()
    const configurationTitle = `Configuring ${deviceBrandName} ${deviceModelName}`

    const GetConfigurationTypeSteps = () => {
        return configurationTypeSteps[selectedDeviceConfigType]({downloadDeviceCallBack, deviceType})
    }

    return (
            <Grid container direction={"column"} spacing={0} className={classes.gridContainer}>
                <Grid item xs={3} className={classes.titleGrid} >
                        <img
                            className={classes.configuringProcessImage}
                            src={ ConfiguringProcessImage }/>
                </Grid>
                <Grid xs={1} className={classes.configureDeviceTitle}>
                    {configurationTitle}
                </Grid>
                <GetConfigurationTypeSteps/>
                <Grid item xs={1} className={classes.awareSection} onClick={() => {
                    setIsAware(!isAware);
                }}>
                    <Checkbox
                        disableRipple
                        color={'primary'}
                        className={classes.checkbox}
                        checked={isAware}
                        onChange={() => {
                            setIsAware(!isAware);
                        }}
                        icon={<span className={classes.checkboxIcon} />}
                    />
                    {iAmAwareText}
                </Grid>
                <Grid xs={1} className={classes.continueButtonGrid}>
                    <StyleButton
                        onClick={() => setIsConfiguredDevice(true)}
                        isDisabled={!isAware}
                        label="Got it"
                        className={classes.continueButton}
                    />
                </Grid>
            </Grid>
    );
}

ConfiguringDevice.propTypes = propTypes
export default ConfiguringDevice
