import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './enrichmentPopoverWrapper.css';
import EnrichmentPopover from './enrichmentPopover/enrichmentPopover';

const EnrichmentPopoverWrapper = ({ ip, mac, children }) => {
	const [isAliasPopoverOpen, setIsAliasPopoverOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedIp, setSelectedIp] = useState(ip);
	const classes = useStyles();

	const openAliasPopover = event => {
		if (!ip) {
			setSelectedIp(event.currentTarget.innerText);
		}

		setIsAliasPopoverOpen(true);
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setIsAliasPopoverOpen(false);
	};

	return (
		<>
			<Typography className={classes.popoverAnchorElement} onClick={openAliasPopover}>
				{children}
			</Typography>
			{ isAliasPopoverOpen && <EnrichmentPopover isOpen={isAliasPopoverOpen}
													   handleClose={handlePopoverClose}
													   anchorEl={anchorEl}
													   ipAddress={selectedIp}
													   mac={mac}/> }
		</>
	);
};

EnrichmentPopoverWrapper.propTypes = {
	ip: PropTypes.string,
	mac: PropTypes.string,
	children: PropTypes.node.isRequired,
};

export default EnrichmentPopoverWrapper;

