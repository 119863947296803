import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';

import {
	CONFIGURE_QR_STEP_NUMBER,
	MFA_SETUP_FAILED_SETTLED,
	MFA_SETUP_SUCCESSFULLY_SETTLED,
	QR_VERIFICATION_SECOND_STEP_TEXT,
} from '../stepsTexts';
import {
	CODE_FORM_KEY,
	CODE_INCORRECT_MESSAGE,
	VERIFICATION_FORM_INITIAL_VALUE,
	CODE_FAILED_MESSAGE,
} from '../../setupPhone/setupPhoneConfig';
import QrVerification from './qrVerification';
import TextFieldWrapper from '../../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import { VERIFY_CODE_FIELD_LABEL } from '../../setupPhone/mfaSetUpTexts';
import { mfaToggle, verifyTotp } from '../../requests';
import { codeValidationSchema } from '../stepsConfig';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { useDispatch } from 'react-redux';
import { TOTP_MFA_KEY } from '../../../userSettings/mfaTextsMapping';
import { updateUserInfo } from '../../../app/controller/redux/auth/authActions';

import { useStyles } from './configureQrMfa.css';

const QrMfaMethod = ({ onSuccess }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const { values, handleChange, touched, errors, handleSubmit } = useFormik({
		initialValues: VERIFICATION_FORM_INITIAL_VALUE,
		enableReinitialize: true,
		validationSchema: codeValidationSchema,
		async onSubmit({ code }) {
			setIsLoading(true);
			const isVerified = await verifyCode(code);
			if (isVerified) {
				isVerified && await configureQrAsMfa();
				await onSuccess();
			}

			setIsLoading(false);
		},
	});

	const verifyCode = async verificationCode => {
		try {
			await verifyTotp(verificationCode);
			return true;
		} catch (error) {
			if (error.status === 400) {
				dispatch(openSnackbar('error', CODE_INCORRECT_MESSAGE));
			} else {
				dispatch(openSnackbar('error', CODE_FAILED_MESSAGE));
			}

			return false;
		}
	};

	const configureQrAsMfa = async () => {
		try {
			await mfaToggle(TOTP_MFA_KEY);
			const dataToUpdate = {
				mfa: TOTP_MFA_KEY,
			};
			dispatch(updateUserInfo(dataToUpdate));
			dispatch(openSnackbar('success', MFA_SETUP_SUCCESSFULLY_SETTLED));
		} catch {
			dispatch(openSnackbar('error', MFA_SETUP_FAILED_SETTLED));
		}
	};

	return (
		<Paper elevation={0} className={classes.paper}>
			<Grid container direction={'column'} className={classes.container}>
				<form onSubmit={handleSubmit}>

					<Grid item xs={1} className={classes.fullWidth}>
						<Typography style={{ fontWeight: 'bold' }}>
							{CONFIGURE_QR_STEP_NUMBER}
						</Typography>
					</Grid>
					<Grid item xs={9} direction={'column'} container className={classes.fullWidth}>
						<Grid xs={7}>
							<QrVerification/>
						</Grid>
						<Grid xs={4} container className={classes.fullWidth}>
							<Grid xs={9} className={classes.fullWidth} >
								<Typography> {QR_VERIFICATION_SECOND_STEP_TEXT} </Typography>
							</Grid>
							<Grid xs={3} style={{ width: '220px', marginTop: '20px' }}>

								<TextFieldWrapper
									fullWidth
									id={CODE_FORM_KEY}
									name={CODE_FORM_KEY}
									label={VERIFY_CODE_FIELD_LABEL}
									onChange={handleChange}
									value={values[CODE_FORM_KEY]}
									error={touched[CODE_FORM_KEY] && Boolean(errors[CODE_FORM_KEY])}
									helperText={touched[CODE_FORM_KEY] && errors[CODE_FORM_KEY]}
									size={'small'}
									className={classes.verifyField}
									required
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid xs={1} className={classes.fullWidth} style={{ marginTop: '65px'}}>
						{isLoading
							? <CircularProgress color="primary" className={classes.circularProgress} />
							: <SubmitButton label={'Set-up MFA'} className={classes.setUpButton} />}
					</Grid>
				</form>
			</Grid>
		</Paper>);
};

QrMfaMethod.propTypes = {
	onSuccess: PropTypes.func,
};

export default QrMfaMethod;
