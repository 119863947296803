import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },
    sidePanel: {
        height: "100%",
        width: "42%",
        backgroundColor: theme.palette.walkthroughPanel.main,
        display: "flex",
        alignItems: "center"
    },
    sidePanelImage: {
        height: "130px"
    },
    mainPanel: {
        paddingRight: "20px"
    },
    mainPanelImage: {
        width: "515px",
        height: "120px"
    },
    title: {
        fontSize: "30px",
        fontWeight: "bold",
        paddingBottom: "10px"
    },
    description: {
        paddingTop: "10px",
        fontSize: "16px",
        color: theme.palette.dialogDescription.main
    }
}));

export {useStyles}