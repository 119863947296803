import React from 'react';
import FilterDropdown from '../../../../shared/components/filterDropdown/filterDropdown';
import PropTypes from 'prop-types';
import {
	LAST_48_HOURS_LABEL,
	LAST_7_DAYS_LABEL,
	LAST_14_DAYS_LABEL,
	LAST_30_DAYS_LABEL,
	LAST_60_DAYS_LABEL,
	LAST_48_HOURS_VALUE,
	LAST_7_DAYS_VALUE,
	LAST_14_DAYS_VALUE,
	LAST_30_DAYS_VALUE,
	LAST_60_DAYS_VALUE,
} from '../filtersConfig';
import { getDaysBackDateToNow } from '../../../../helpers/datesHelper';
import Storage from "../../../../services/storage";

const TimeRangeFilter = ({ onTimeRangeChange, defaultSelectionIndex }) => {
	const items = [{
		label: LAST_48_HOURS_LABEL,
		value: LAST_48_HOURS_VALUE,
	},
	{
		label: LAST_7_DAYS_LABEL,
		value: LAST_7_DAYS_VALUE,
	},
	{
		label: LAST_14_DAYS_LABEL,
		value: LAST_14_DAYS_VALUE,
	},
	{
		label: LAST_30_DAYS_LABEL,
		value: LAST_30_DAYS_VALUE,
	},
	{
		label: LAST_60_DAYS_LABEL,
		value: LAST_60_DAYS_VALUE,
	}];


	const getUserSelectedIndex = () => {
		const userSelectedTimeRange = parseInt(Storage.get('dcTimeRangeFilter'));
		const userSelectedTimeRangeIndex = items.findIndex(element => element.value === userSelectedTimeRange);
		return userSelectedTimeRangeIndex >= 0 ? userSelectedTimeRangeIndex : undefined;
	}

	const onItemSelected = item => {
		const { from, to } = getDaysBackDateToNow(item.value);
		onTimeRangeChange({ from, to, isDirty: item.isDirty });
		Storage.set('dcTimeRangeFilter', item.value);
	}

	return (
		<div>
			<FilterDropdown items = {items} defaultSelectionIndex={defaultSelectionIndex}
							userSelectedIndex={getUserSelectedIndex()} onItemSelected={onItemSelected} />
		</div>
	);
};

TimeRangeFilter.propTypes = {
	onTimeRangeChange: PropTypes.func.isRequired,
	defaultSelectionIndex: PropTypes.number,
};

export default TimeRangeFilter;
