import React from 'react';
import { collectorTypes } from '../devicesManagementConfig';

const tableFieldsDisplayNames = {
	ipfix: 'IPFIX',
	sflow: 'sFlow',
	netflow: 'NetFlow',
	version: 'Version',
	createdAt: 'Creation Date',
};

const additionalDataFieldsMapping = {
	[collectorTypes.LEGACY_COLLECTOR_TYPE]: ['ipfix', 'sflow', 'netflow', 'version', 'createdAt'],
	[collectorTypes.SAMPLER_TYPE]: ['ipfix', 'sflow', 'netflow', 'version', 'createdAt'],
};

const NOT_READY_TOOLTIP_CONTENT = <p>The Collector is currently disconnected<br/>from Cynamics. Consider checking if<br/>the Collector VM is down, blocked by<br/>a Firewall, or misconfigured.</p>;

const ENABLED_DELETE_ICON_TOOLTIP_TITLE = 'Delete';
const DISABLED_DELETE_ICON_TOOLTIP_TITLE = <p style={{ width: '200px' }}>This collector cannot be deleted because it is attached to a device</p>;

export {
	tableFieldsDisplayNames,
	additionalDataFieldsMapping,
	NOT_READY_TOOLTIP_CONTENT,
	ENABLED_DELETE_ICON_TOOLTIP_TITLE,
	DISABLED_DELETE_ICON_TOOLTIP_TITLE,
};
