import { allOptionsFilterShow } from "../constants/setFiltersContent";

const initialValue = {
    alertes: [],
    selectedAlertEdition: {},
    isLoadingAlerte: false,
    selectedFilter: allOptionsFilterShow[2],
    graphs: [],
    alertError: null,
};

export { initialValue };
