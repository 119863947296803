import React, { Component } from 'react';
import Error from '../../../assets/threshold/error.svg';
import { Popover } from '@material-ui/core';
import _ from 'lodash';


class ErrorPopOver extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onOver = (type, e) => {
        switch (type) {
            case 'onMouseOver':
                
                this.setState({ isOver: true, overPosition: { 'left': e.clientX, 'top': e.clientY } })
                setTimeout(() => { this.setState({ isOver: false }) }, 1500)
                break;

            default:
                break;
        }
    }
    render() {
        return (
            <>
                <Popover
                    open={ this.state.isOver }
                    anchorEl={ this.state.overPosition }
                    anchorPosition={ this.state.overPosition }
                    anchorReference='anchorPosition'
                >
                    <div className="error-messages">{ this.props.message }</div>
                </Popover>
                { this.props.hasWarning ? <img
                    onMouseOver={ (e) => { this.onOver("onMouseOver", e) } }
                    src={ Error } /> : '' }
            </>
        );
    }
}

export default ErrorPopOver;