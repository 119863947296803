import TextField from '../../../../../../shared/components/form/TextField/TextField';
import SubmitButton from "../../../../../../shared/components/form/Buttons/SubmitButton";
import React from "react";
import {Field ,reduxForm} from "redux-form";
import {useStyles} from "./setNotificationsForm.css";
import emailValidation from "../../../../../integrations/controller/validations/emailInputValidation";

const SetNotificationsForm = ({handleSubmit}) => {
    const classes = useStyles();

    return (
        <form onSubmit={handleSubmit} className={classes.form}>
            <Field
                component={ TextField }
                name="email"
                label="Email address"
                placeholder="your@email.com"
                required={true}
                type="email"
                variant="outlined"
                validate={emailValidation}
                className={classes.input}
            />
            <SubmitButton label={"Save"} className={classes.button}/>
        </form>
    )
}

export default reduxForm({
    enableReinitialize: true,
    form: 'walkthroughSetNotifications' // a unique identifier for this form
})(SetNotificationsForm)