const devicePath = 'public-devices/v1/';
const collectorPath = 'collectors/v1/';
const samplersPath = 'collectors/v1/samplers/';
const collectorDevicePath = 'collector-devices/v1/';
const publicCloudDevicePath = 'public-cloud-devices/v1/';
const publicCloudDeviceVisibleCountPath = 'public-cloud-devices/v1/visible';
const lightWeightPublicCloudDevicePath = 'public-cloud-devices/v1/lite/';
const deviceConfigurationPath = 'device-configurations/v1/';
const lightweightCollectorDevicesPath = 'collector-devices/v1/lite/';
const lightweightPublicDevicesPath = 'public-devices/v1/lite/';
const publicDeviceVisibleCountPath = 'public-devices/v1/visible';
const collectorDeviceVisibleCountPath = 'collector-devices/v1/visible';
export const publicCloudDeviceConfigPath = deviceId => `public-cloud-devices/v1/${deviceId}/config`;

export {
	collectorPath,
	samplersPath,
	collectorDevicePath,
	devicePath,
	deviceConfigurationPath,
	lightweightCollectorDevicesPath,
	lightweightPublicDevicesPath,
	publicDeviceVisibleCountPath,
	collectorDeviceVisibleCountPath,
	publicCloudDevicePath,
	publicCloudDeviceVisibleCountPath,
	lightWeightPublicCloudDevicePath,
};
