import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
	titleStyle: {
		marginBottom: theme.spacing(1),
		fontSize: '16px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
		width: '546px',
		textAlign: 'flex-start',
	},
	subnetsContainer: {
		backgroundColor: '#F2F2F2',
		height: '84px',
		overflow: 'scroll',
		justifyContent: 'flex-start',
		padding: '8px',
		marginBottom: '30px',
	},
	subnetChip: {
		borderRadius: '5px',
		display: 'flex',
		padding: '4px 5px 6px 7px',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '14px',
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.text.light,
		height: '25px',
	},
}));

export { useStyles };
