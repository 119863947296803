/**
 * Formats a number to a string with commas and [decimalNumbers] numbers after the decimal point.
 * ex: 123456.789, 2 -> 123,456.78
 * @param {*} number is the number to format
 * @param {*} decimalNumbers is the count of numbers after the decimal points
 * @returns a string of the formatted number.
 */
const addCommasToNumber = (number, decimalNumbers) => Number(number).toFixed(decimalNumbers).replace(/(\d)(?=(\d{3})+\.\d\d$)/g, '$1,');

export {
	addCommasToNumber,
};
