import React from 'react';

import '../../../containers/alertsManagement/view/createAlert/advance/checkBoxs/checkboxs.scss';
import { useStyles } from './checkboxFilters.css';
import CheckboxInput from '../checkboxInput/CheckboxInput';
import PropTypes from 'prop-types';

const CheckboxFilters = ({ formName }) => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<CheckboxInput name="isFWE_ALLOW"
				label="FW ALLOW"
				formName={formName}
				optionalStyles={{ label: classes.checkboxLabel }}
				icon={<span className={classes.icon} />}
			/>
			<CheckboxInput name="isFWE_DENY"
				label="FW DENY"
				formName={formName}
				optionalStyles={{ label: classes.checkboxLabel }}
				icon={<span className={classes.icon} />}
			/>
		</div>
	);
};

CheckboxFilters.propTypes = {
	formName: PropTypes.string.isRequired,
};

export default CheckboxFilters;
