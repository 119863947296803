export const activeFeatures = {
	CYNAMICS_APIS: 'cynamics-apis',
	COLLECTOR_DEVICES: 'collector_devices',
	IP_RESOLUTION_ENRICHMENT: 'ip_resolution_enrichment',
	ENDPOINT_INVENTORY: 'endpoint_inventory',
	DEMO_MODE: 'demo_mode',
	ENDPOINT_INVENTORY_APIS: 'endpoint-inventory-apis',
	VCA_DETECTION: 'vca_detection',
	ORIGINAL_CUSTOMER: 'original_customer',
	PERFORMANCE_MONITORING: 'performance_monitoring',
	THREATS: 'ai_detections',
};
