import React, { Component } from "react";

// react daterange library
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

export default class DateRange extends Component {

    constructor() {
        super();
        this.state = {
            date: [null, null],
        }
    }

    // sets the dates from the date picker into the component 
    // and passes the dates to the event onChange
    onChange = date => {
        this.props.onChange(date);
        this.setState({ date });
    }

    /// render date range component
    render() {
        return (
            <div className='date-range-wrapper'>
                <DateRangePicker
                    onChange={this.onChange}
                    value={this.state.date}
                />
            </div>
        )
    }
}