
import React from 'react';
import './Activity.scss';
import ActivityOnIcon from '../../../../../assets/smart-table/activityon.svg';
import ActivityOffIcon from '../../../../../assets/smart-table/activityoff.svg';
import PropTypes from 'prop-types';

const Activity = ({ isActive, activeLabel, inactiveLabel }) => <>
	{ isActive
		? <ActivityOn activeLabel={activeLabel}/>
		: <ActivityOff inactiveLabel={inactiveLabel}/> }</>;

const ActivityOn = ({ activeLabel }) => (
	<div className="activated-type-container" >
		<div className="activated-type-image">
			<img className="activated" src={ ActivityOnIcon } alt="Active device" />
		</div>
		<div className="activated-type-text">
			{ activeLabel }
		</div>
	</div>
);

ActivityOn.propTypes = {
	activeLabel: PropTypes.string,
};

const ActivityOff = ({ inactiveLabel }) => (
	<div className="inactivated-type-container">
		<div className="inactivated-type-image">
			<img className="inactivated" src={ ActivityOffIcon } alt="Inactive device" />
		</div>
		<div className="inactivated-type-text">
			{ inactiveLabel }
		</div>
	</div>
);

ActivityOff.propTypes = {
	inactiveLabel: PropTypes.string,
};

Activity.propTypes = {
	isActive: PropTypes.bool,
	activeLabel: PropTypes.string,
	inactiveLabel: PropTypes.string,
};
export default Activity;
