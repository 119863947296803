import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useStyles } from './PaperFrame.css';

function PaperFrame({ height = '360px', minHeight = '360px', width = 921, children, title, elevation }) {
	const classes = useStyles();

	return (
		<Paper style={{ height, width: `${width}px`, minHeight: `${minHeight}` }} elevation={elevation}>
			<div className={classes.header}>
				<Typography className={classes.title} variant={'h6'}>{title}</Typography>
			</div>

			<div style={{ height: 'calc(100% - 50px)' }}>
				{children}
			</div>
		</Paper>

	);
}

export default PaperFrame;
