import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SkeletonTable from '../../../shared/components/simpleTable/skeletonTable/skeletonTable';
import { MAX_ROW_IN_TABLE } from '../../app/view/tableConfig/const';
import { useStyles } from './findingsTable.css';
import { getLogicConfig } from '../vcaManagementConfig';
import { Box } from '@material-ui/core';
import ErrorMessage from '../../../shared/components/ErrorMessage/ErrorMessage';

const ERROR_TITLE = 'Something went wrong';
const ERROR_MESSAGE = 'Error loading \'Findings\' tab. Please try again.';

const FindingsTable = ({ history, eventData, isLoading }) => {
	const classes = useStyles();
	const [rows, setRows] = useState([]);
	const [columns, setColumns] = useState([]);

	useEffect(() => {
		if (eventData && eventData.additionalInfo) {
			const { additionalInfo } = eventData;
			if (additionalInfo.additionalData) {
				const logicConfig = getLogicConfig(additionalInfo.additionalData,
					additionalInfo.logicKey,
					additionalInfo.logicVersion,
					eventData.endpoints);
				setColumns(logicConfig.columns);
				setRows(logicConfig.dataPrep);
			}
		}
	}, [eventData]);

	if (isLoading || !rows) {
		return <SkeletonTable columns={columns} rowsPerPage={MAX_ROW_IN_TABLE} />;
	}

	if (!rows || !rows.length) {
		return <Box className={classes.messageWrapper}><ErrorMessage title={ERROR_TITLE} message={ERROR_MESSAGE}/></Box>;
	}

	return <TableContainer >
		<Table aria-label="Extra data" className={classes.root}>
			<TableHead className={classes.head}>
				<TableRow>
					{columns.map(col =>
						<TableCell className={classes.headerTextColor}
							key={`HEADER-${col}`}>{col}
						</TableCell>)}
				</TableRow>
			</TableHead>
			<TableBody>
				{rows.map((row, rowIndex) => (
					<TableRow key={rowIndex} className={classes.tableRow}>
						{row.map((cell, index) =>
							<TableCell key={`${rowIndex}-${index}`} align={cell.align} component="th" scope="row">
								<cell.Component history={history}>{cell.value}</cell.Component>
							</TableCell>)}
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>;
};

FindingsTable.propTypes = {
	eventData: PropTypes.object.isRequired,
	isLoading: PropTypes.bool,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export default FindingsTable;
