import React, { PureComponent } from 'react';
import SetPasswordForm from './components/SetPasswordForm';
import { checkValidInvitation, getCurrentUser, login, setPassword } from '../app/controller/redux/auth/authActions';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import LayoutForm from '../../shared/components/form/LayoutForm';
import ErrorMessage from '../../shared/components/form/ErrorMessage';
import _ from 'lodash';
import { createUser } from './requests';
import { openSnackbar } from '../snackbarAlert/controller/redux/actions';
import { createAuthenticationMetaDataCookie, redirectToHostedUi } from '../authenticateUser/services/tools';

const OPERATION_ERROR_STATUS_CODE = 500;
const SUBMISSION_ERROR_STATUS_CODE = 'Something went wrong... Please try again';

class SetPassword extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			id: this.props.match.params.id,
			email: '',
			isLoading: false,
		};
	}

	async componentDidMount() {
		const { id } = this.state;
		const result = await this.props.checkValidInvitation(id);
		if (!_.isEmpty(result)) {
			this.setState({ valid: true, email: result.email });
		} else {
			this.setState({ valid: false, errorMessage: 'Invalid invitation' });
		}
	}

	handleSubmit = async submittedInfo => {
		const { id: invitationId } = this.state;
		let result;
		try {
			this.setState({ isLoading: true });
			result = await createUser(invitationId, submittedInfo.password);
		} catch (e) {
			if (e.status === OPERATION_ERROR_STATUS_CODE) {
				redirectToHostedUi();
			} else {
				this.props.openSnackbar('error', SUBMISSION_ERROR_STATUS_CODE, 4000);
			}
		} finally {
			this.setState({ isLoading: false });
		}

		if (result) {
			const { expiresIn, userId } = result;
			const { origin } = window.location;
			const expirationDate = new Date(expiresIn);
			await createAuthenticationMetaDataCookie(expirationDate, userId);
			window.location.href = origin;
		}
	};

	renderPasswordForm = () => {
		const { valid, email } = this.state;
		return valid ? <SetPasswordForm email={email} onSubmit={this.handleSubmit}/>
			: <ErrorMessage message={this.state.errorMessage} subMessage="Please contact account management" onSubmit={this.goToLogin}/>;
	};

	render() {
		const { isLoading } = this.state;

		return (
			<LayoutForm isLoading={isLoading}>
				{this.renderPasswordForm()}
			</LayoutForm>
		);
	}
}

const mapStateToProps = state => ({
	user: state.auth.user,
});

const mapDispatchToProps = {
	checkValidInvitation,
	setPassword,
	login,
	getCurrentUser,
	openSnackbar,
};
export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
