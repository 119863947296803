import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../app/controller/redux/app/appActions';
import PaperFrame from '../../shared/components/PaperFrame/PaperFrame';
import { Divider, Grid, Typography } from '@material-ui/core';
import { useStyles } from './userSettings.css';
import StyleButton from '../../shared/components/form/Buttons/StyleButton';
import password from '../../assets/password.svg';
import { updateUser } from '../userManagement/controller/services/requests';
import { SET_ERROR } from '../app/controller/redux/message/messageActions';
import { updateUserInfo } from '../app/controller/redux/auth/authActions';
import UpdateUserInfoForm from './forms/updateUserInfoForm';
import ChangePasswordDialog from './controller/changePasswordDialog';
import Saved from './sharedComponents/saved';
import {mfaTextsMapping, NO_MFA_KEY, REMOVE_MFA_DISCLAIMER_TEXT, REMOVE_MFA_TEXT, SMS_MFA_KEY} from './mfaTextsMapping';
import SetupPhoneProcessWrapper from '../mfaSetUp/setupPhone/setupPhoneProcessWrapper';
import MfaWrapper from '../mfaSetUp/mfaWrapper';
import DeleteModal from '../../shared/components/deleteModal/deleteModal';
import { mfaToggle } from '../mfaSetUp/requests';

function UserSettings() {
	const dispatch = useDispatch();
	const currentUser = useSelector(state => state.auth.user);

	const [isFieldChanged, setIsFieldChanged] = useState(false);
	const [updateInfoSuccess, setUpdateInfoSuccess] = useState(false);
	const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
	const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] = useState(false);
	const [isSetPhoneDialogOpen, setIsSetPhoneDialogOpen] = useState(false);
	const [isMfaWrapperOpen, setIsMfaWrapperOpen] = useState(false);
	const [isRemoveMfaModalOpen, setIsRemoveMfaModalOpen] = useState(false);

	const isSmsMfaConfigured = currentUser.mfa === SMS_MFA_KEY;
	const isMfaConfigured = currentUser.mfa !== NO_MFA_KEY;

	const classes = useStyles();

	useEffect(() => {
		dispatch(setTitle('Settings'));
	}, []);

	const handleChangePasswordDialog = () => {
		setIsChangePasswordDialogOpen(!isChangePasswordDialogOpen);
	};

	const onChange = (e, fieldName) => {
		if ('target' in e) {
			const newValue = e.target.value;
			if (newValue === currentUser[fieldName]) {
				setIsFieldChanged(false);
			} else {
				setUpdateInfoSuccess(false);
				setIsFieldChanged(true);
			}
		}
	};

	const cancelChanges = () => {
		setUpdateInfoSuccess(false);
		setIsFieldChanged(false);
	};

	const saveChanges = async data => {
		const dataToUpdate = {
			firstName: data.firstName,
			lastName: data.lastName,
		};
		try {
			await updateUser(currentUser._id, dataToUpdate);
			setIsFieldChanged(false);
			fadeOutSaved(setUpdateInfoSuccess);
			dispatch(updateUserInfo(dataToUpdate));
		} catch {
			dispatch({ type: SET_ERROR, message: 'Failed to update user information' });
		}
	};

	const changePassword = async data => {
		const dataToUpdate = {
			oldPassword: data.oldPassword,
			newPassword: data.password,
		};
		try {
			await updateUser(currentUser._id, dataToUpdate);
			fadeOutSaved(setUpdatePasswordSuccess);
			setIsChangePasswordDialogOpen(false);
		} catch(e) {
			dispatch({ type: SET_ERROR, message: 'Failed to update users password' });
			throw e;
		}
	};

	const removeMfa = async () => {
		await mfaToggle(NO_MFA_KEY);
		const dataToUpdate = {
			mfa: NO_MFA_KEY,
		};
		dispatch(updateUserInfo(dataToUpdate));
		setIsRemoveMfaModalOpen(false);
	};

	const fadeOutSaved = setSavedField => {
		setSavedField(true);
		setTimeout(() => {
			setSavedField(false);
		}, 2500);
	};

	const renderAddPhone = () => (
		<Grid item container xs={6} justify={'center'}>
			<Grid item xs={3}>
				<Typography variant="body2">Phone</Typography>
			</Grid>
			<Grid item xs={6}>
				<Typography variant="body2" color={'primary'} className={classes.phoneNumber} onClick={() => setIsSetPhoneDialogOpen(true)}>+Add Phone Number</Typography>
				{/* <SetupPhoneProcessWrapper/> */}
			</Grid>
		</Grid>
	);

	const renderEditPhone = () =>
		<>
			<Grid item container xs={6} justify={'center'}>
				<Grid item xs={3}>
					<Typography variant="body2">Phone</Typography>
				</Grid>
				<Grid item xs={6} >
					<Typography variant="body2">{currentUser.phone}</Typography>
				</Grid>
			</Grid>
			<Grid item xs={3}>
				<StyleButton label={'Edit'} variant={'outlined'} style={{ width: '200px' }} onClick={() => setIsSetPhoneDialogOpen(true)}/>
				{/* <SetupPhoneProcessWrapper/> */}
			</Grid>
		</>;

	const renderUserInfo = () => <PaperFrame title={'User Info'} height={'450px'} minHeight={'unset'}>
		<Grid container xs={12} style={{ height: '100%' }} justify={'space-between'}>

			<UpdateUserInfoForm onSubmit={saveChanges}
				cancelChanges={cancelChanges}
				onChange={onChange}
				updateSuccess={updateInfoSuccess}
				isFieldChanged={isFieldChanged}
				setIsFieldChanged={setIsFieldChanged}/>
			<Grid item xs={12}>
				<Divider variant={'middle'}/>
			</Grid>

			<Grid item container xs={12} justify={'space-between'}>
				{/* <Grid item container xs={6} justify={'center'}> */}
				{currentUser.phone
					? renderEditPhone()
					: renderAddPhone()}
				{/* </Grid> */}
			</Grid>

			<Grid item xs={12}>
				<Divider variant={'middle'} fl/>
			</Grid>

			<Grid item container xs={12} justify={'space-between'}>
				<Grid item container xs={6} justify={'center'}>
					<Grid item xs={3}>
						<Typography variant="body2">Email</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography variant="body2">{currentUser.email}</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Divider variant={'middle'} fl/>
			</Grid>

			<Grid item container xs={12} justify={'space-between'}>
				<Grid item container xs={6} justify={'center'}>
					<Grid item xs={3}>
						<Typography variant="body2">Password</Typography>
					</Grid>
					<Grid item xs={6}>
						<img src={password} height={'6px'} alt={'password placeholder'} style={{ width: 'unset' }}/>
					</Grid>
				</Grid>
				{updatePasswordSuccess ? <Grid item xs={1}>
					<Saved/>
				</Grid>
					: <Grid item xs={3} style={{ float: 'left' }}>
						<StyleButton label={'Change Password'} variant={'outlined'} style={{ width: '180px' }} onClick={handleChangePasswordDialog}/>
					</Grid>}
			</Grid>
		</Grid>
	</PaperFrame>;

	const renderMfaActions = () => {
		if (isMfaConfigured) {
			return (
				<Grid xs={5}>
					<StyleButton
						label="Edit"
						variant="outlined"
						style={{ width: 160, marginRight: '18px' }}
						onClick={() => {
							setIsMfaWrapperOpen(true);
						}}
					/>
					<StyleButton
						label="Remove"
						variant="outlined"
						style={{ width: 160 }}
						onClick={() => setIsRemoveMfaModalOpen(true)} />
				</Grid>
			);
		}

		return <Grid xs={3}>
			<StyleButton
				label="Set up"
				variant="contained"
				style={{ width: 135, height: 45, marginLeft:53 }}
				onClick={() => {
					setIsMfaWrapperOpen(true);
				}}
			/>
		</Grid>;
	};

	const renderMfaText = () => {
		return <>
			<Typography variant="body2">
				{REMOVE_MFA_TEXT}
			</Typography>
			<Typography variant="body2" style={{ textAlign: 'center', marginBottom:"30px"}}>
				{REMOVE_MFA_DISCLAIMER_TEXT}
			</Typography>
		</>
	}

	return (
		<div style={{ margin: '20px' }} className={classes.root}>
			{renderUserInfo()}
			{isChangePasswordDialogOpen
				&& <ChangePasswordDialog onClose={handleChangePasswordDialog} onSubmit={changePassword}/>}

			<div style={{ marginTop: '20px' }}>
				<PaperFrame title={'Multi Factor Authentication'} height={'180px'} minHeight={'unset'} margin>
					<Grid container style={{ height: '100%', alignContent: 'center' }} right-spacing={5} className={classes.mfaSection}>
						<Grid xs={isMfaConfigured ? 7 : 9} style={{ marginBottom: 'auto', marginTop: 'auto', display: 'inline-block' }}>
							<Typography variant="body2" style={{ marginLeft: '50px' }}> {mfaTextsMapping[currentUser.mfa]} </Typography>
						</Grid>
						{renderMfaActions()}
					</Grid>

				</PaperFrame>
				{isSetPhoneDialogOpen && <SetupPhoneProcessWrapper
					isSmsMfaConfigured={isSmsMfaConfigured}
					onClose={() => {
						setIsSetPhoneDialogOpen(false);
					}}
					isEditMode={Boolean(currentUser.phone)}
				/>}
				{isMfaWrapperOpen && <MfaWrapper
					phoneNumber={currentUser.phone}
					onClose={() => {
						setIsMfaWrapperOpen(false);
					}}
				/>}
				<DeleteModal
					modalOpen={isRemoveMfaModalOpen}
					toggle={() => setIsRemoveMfaModalOpen(false)}
					message={renderMfaText()}
					onConfirm={removeMfa}
					withWarning={false}
					messageAdditionalStyles={{ fontWeight: 400, fontSize: '18px' }}
					centered
				/>
			</div>
		</div>
	);
}

export default UserSettings;
