import React, { useEffect, useState } from 'react';
import { storeGroupLabelValue } from '../controller/redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from '../controller/services/requests';
import { setTitle } from '../../app/controller/redux/app/appActions';
import { configGroupManagement } from './configGroupManagement';
import { mapMiminulGroupValues } from '../controller/businessLogic/mapper';
import WalkthroughDialog from '../../walkthrough/walkthroughDialog/walkthroughDialog';
import welcomeImage from '../../../assets/walkthrough/groups/steps/welcome.png';
import Welcome from '../../../shared/components/walkthrough/welcome';
import { updateWalkthroughDone } from '../../app/controller/redux/dashboardInteractions/actions';
import { IP_GROUPS_KEY } from '../../app/controller/redux/dashboardInteractions/consts';
import Step1 from './walkthrough/steps/Step1/step1';
import Step3 from './walkthrough/steps/Step3/step3';
import Step2 from './walkthrough/steps/Step2/step2';
import { SERVICE_DESCRIPTION, SERVICE_NAME } from './walkthrough/texts';
import GroupsManagementTabs from './groupsManagementTabs/groupsManagementTabs';
import Box from '@material-ui/core/Box';

const GROUP_CUSTOM_CREATION_TYPE = 'custom';
const GROUP_ALIAS_OBJECT_TYPE = 'alias';

const GroupManagement = () => {
	const [customGroups, setCustomGroups] = useState([]);
	const [assetsGroups, setAssetsGroups] = useState([]);
	const [ipAliasesGroups, setIpAliasesGroups] = useState([]);
	const isWalkthroughDone = useSelector(state => state.interactions.ipGroups.walkthroughDone);
	const visibleDevicesCount = useSelector(state => state.visibleDevicesCount.value) || 0;
	const dispatch = useDispatch();

	const handleLoadGroups = async () => {
		const accountGroups = await getGroups();
		setAssetsGroups(accountGroups.filter(({ objectGroup }) => objectGroup.creationType !== configGroupManagement.GROUP_CUSTOM_CREATION_TYPE));
		setCustomGroups(accountGroups.filter(({ objectGroup }) => objectGroup.creationType === configGroupManagement.GROUP_CUSTOM_CREATION_TYPE
			&& objectGroup.objectType !== configGroupManagement.GROUP_ALIAS_OBJECT_TYPE));
		setIpAliasesGroups(accountGroups.filter(({ objectGroup }) => objectGroup.objectType === configGroupManagement.GROUP_ALIAS_OBJECT_TYPE));
		dispatch(storeGroupLabelValue(mapMiminulGroupValues(accountGroups)));
	};

	useEffect(() => {
		dispatch(setTitle(configGroupManagement.PAGE_TITLE));
		handleLoadGroups();
	}, []);

	const finishWalkthrough = () => {
		dispatch(updateWalkthroughDone(IP_GROUPS_KEY, true));
	};

	const steps = [Step1, Step2, Step3];
	return (
		<Box height={'90%'} width={'100%'} style={{ zIndex: 1000 }}>
			<GroupsManagementTabs
				onLoadGroups={handleLoadGroups}
				assetsGroups={assetsGroups}
				customGroups={customGroups}
				ipAliasesGroups={ipAliasesGroups}
			/>
			{!isWalkthroughDone && visibleDevicesCount !== 0 && (
				<WalkthroughDialog
					serviceName={SERVICE_NAME}
					serviceDescription={SERVICE_DESCRIPTION}
					welcomeImage={welcomeImage}
					StartComponent={Welcome}
					finishCallback={finishWalkthrough}
					skipCallback={finishWalkthrough}
					steps={steps}
				/>
			)}
		</Box>
	);
};

export default GroupManagement;
