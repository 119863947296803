import {useEffect, useState} from "react";

const usePolling = (intervalCallback,
                    delay,
                    conditionalFinishCallback = (data) => { return !!data },
                    isStartPolling = true,
                    timeOut = null) => {
    let startTime;
    let interval;
    const [data, setData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [timeOutReached, setTimeOutReached] = useState(false)

    const intervalHandler = async () => {
        try {
            let elapsedTime = (new Date().getTime() - startTime);
            if (!timeOut ||  elapsedTime < timeOut) {
                const fetchData = await intervalCallback();
                if (conditionalFinishCallback(fetchData)) {
                    setData(fetchData);
                    setIsLoading(false);
                    clearInterval(interval)
                }
            } else {
                setIsLoading(false)
                setTimeOutReached(true)
                clearInterval(interval)
            }
        } catch (e) {
            setError(e)
            setIsLoading(false)
            clearInterval(interval)
        }
    }

    useEffect(() => {
        if (!isStartPolling) {
            return;
        }
        setIsLoading(true);
        startTime = new Date().getTime()
        interval = setInterval(intervalHandler, delay);
        return () => {
            clearInterval(interval)
        };
    }, [isStartPolling])

    return {
        data,
        isLoading,
        error,
        timeOutReached,
    }
}

export default usePolling