import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	highlighted: {
		color: '#ECAA00',
		marginRight: '5px',
		width: '20px',
	},
	all: {
		color: '#48648B',
		marginRight: '5px',
		width: '20px',
	},
}));

export default useStyles;
