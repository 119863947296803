import { useEffect, useState } from 'react';
import { parseProcessBackground } from '../../containers/backgroundProcess/controller/redux/backgroundProcessAction';
import { backgroundProcessStatus, createBackgroundProcessByQueryId } from '../../containers/backgroundProcess/controller/services/backgroundProcess/requests';

const ANALYSIS_SHORT_POLLING_INTERVAL = 1000;
const ANALYSIS_SUCCESS_STATUS = 'COMPLETE';
const ANALYSIS_ERROR_STATUS = 'ERROR';

const useEventAnalysisData = eventData => {
	const [result, setResult] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const analysisFailureHandler = error => {
		setError(error);
		setIsLoading(false);
	};

	const analysisSuccessHandler = result => {
		setResult(result);
		setIsLoading(false);
	};

	const queryEventAnalysis = async () => {
		const { queryId } = eventData.additionalInfo.linkToQuery;
		const response = await createBackgroundProcessByQueryId(queryId);
		return response.workItemId;
	};

	const shortPollingEventAnalysis = async workItemId => {
		const intervalId = setInterval(async () => {
			const queryStatus = await backgroundProcessStatus(workItemId);
			if (queryStatus.status === ANALYSIS_SUCCESS_STATUS) {
				const parsedAnalysisResult = parseProcessBackground(queryStatus);
				analysisSuccessHandler(parsedAnalysisResult);
				clearInterval(intervalId);
			} else if (queryStatus.status === ANALYSIS_ERROR_STATUS) {
				analysisFailureHandler(new Error(queryStatus.analysisResponse));
				clearInterval(intervalId);
			}
		}, ANALYSIS_SHORT_POLLING_INTERVAL);
	};

	useEffect(() => {
		if (eventData) {
			setIsLoading(true);
			queryEventAnalysis()
				.then(workItemId => {
					shortPollingEventAnalysis(workItemId);
				}).catch(e => {
					analysisFailureHandler(e);
				});
		}

		return () => {
			setResult();
			setError();
			setIsLoading(false);
		};
	}, [eventData]);

	return {
		result,
		error,
		isLoading,
	};
};

export default useEventAnalysisData;
