import moment from 'moment';
import _ from 'lodash';
import { mapDevices, findQuery, mapIps, convertIpFiltersSymbols } from './mappers';
import {
	createEnrichmentInputsByPayload,
	createEnrichmentPayload,
	createInterfacesFromPayload,
	createInterfacesPayload,
	createDestPortsPayload,
	createSrcPortsPayload,
	createTcpFlagsPayload,
	isFilledTcpFlags,
	createDestPortsFromPayload,
	createSrcPortsFromPayload,
	createIpProtocolsPayload,
	createIpProtocolFromPayload,
	createAwsFilterFromPayload,
	createGoogleCloudFilterFromPayload,
	createAzureFilterFromPayload,
	createVlanFromPayload,
	createVlanPayload,
} from '../../../../shared/utils/formToPayload';
import analyzer from '../../../../helpers/analyzer';

import { SUBMIT_ACTION, ANALYSIS_CATEGORY } from '../../../../helpers/analyzerConstants';
import {
	EXCLUDE_SIGN,
	INCLUDE_SIGN,
} from '../../../../shared/components/queryLine/selectors/directionSelectCreatable/consts';

const ANALYZER_CATEGORY = ANALYSIS_CATEGORY;

const FormToPayload = content => {
	const { datefrom, dateto } = content;
	let PayloadTarget = {};

	const fromTimestamp = datefrom.valueOf();
	const toTimestamp = dateto.valueOf();

	const tcpFlags = isFilledTcpFlags(content) ? createTcpFlagsPayload(content) : {};
	const interfaces = createInterfacesPayload(content);
	const srcPortsData = createSrcPortsPayload(content);
	const destPortsData = createDestPortsPayload(content);
	const ipProtocolsData = createIpProtocolsPayload(content);

	PayloadTarget = {
		timeWindow: content.timeWindow,
		fromTimestamp,
		toTimestamp,
		devices: mapDevices(content.devices),
		srcPorts: srcPortsData,
		destPorts: destPortsData,
		ipProtocols: ipProtocolsData,
		extra: {
			excludedIpsDest: mapIps(content.ipDestination, EXCLUDE_SIGN),
			excludedIpsSrc: mapIps(content.ipSource, EXCLUDE_SIGN),
			includedIpsDest: mapIps(content.ipDestination, INCLUDE_SIGN),
			includedIpsSrc: mapIps(content.ipSource, INCLUDE_SIGN),
			typeproto:
				{
					isIn: _.has(content, 'isIn') ? content.isIn : false,
					isOut: _.has(content, 'isOut') ? content.isOut : false,
				},
			firewall: {
				isFWE_ALLOW: _.has(content, 'isFWE_ALLOW') ? content.isFWE_ALLOW : false,
				isFWE_DENY: _.has(content, 'isFWE_DENY') ? content.isFWE_DENY : false,
			},
		},
		queryTypes: [_.has(content, 'queryTypes') ? content.queryTypes.value : [1]],
		vlan: createVlanPayload(content),
	};
	PayloadTarget.ipWhoIs = createEnrichmentPayload(content);

	if (!_.isEmpty(tcpFlags)) {
		PayloadTarget.tcpFlags = tcpFlags;
	}

	if (!_.isEmpty(interfaces)) {
		PayloadTarget.interfaces = interfaces;
	}

	if (content.aws) {
		PayloadTarget.cloudAws = createAwsFilterFromPayload(content);
	}

	if (content.googleCloud) {
		PayloadTarget.googleCloud = createGoogleCloudFilterFromPayload(content);
	}

	if (content.azure) {
		PayloadTarget.azureCloud = createAzureFilterFromPayload(content);
	}

	analyzer.trackQuery(PayloadTarget, ANALYZER_CATEGORY, SUBMIT_ACTION);

	return PayloadTarget;
};

const fromPayloadtoForm = payload => {
	const proto = payload.typeproto;
	const { firewall } = payload;

	const {
		ipsSource,
		ipsDestination,
		ipWhoIs,
		tcpFlags,
		interfaces,
		srcPorts,
		destPorts,
		ipProtocols,
		aws,
		vlan,
		vlanIdBidirectionalTraffic,
	} = payload;
	const formIpWhoIs = _.isEmpty(ipWhoIs) ? {} : createEnrichmentInputsByPayload(ipWhoIs);
	const formTcpFlags = _.isEmpty(tcpFlags) ? {} : tcpFlags;
	const formInterfaces = _.isEmpty(interfaces) ? {} : createInterfacesFromPayload(interfaces);
	const formSrcPorts = srcPorts ? createSrcPortsFromPayload(srcPorts) : [];
	const formDestPorts = destPorts ? createDestPortsFromPayload(destPorts) : [];
	const formIpProtocols = ipProtocols ? createIpProtocolFromPayload(ipProtocols) : [];
	const formVlanFilter = vlan ? createVlanFromPayload(vlan) : {};

	return {
		timeWindow: payload.timeWindow,
		datefrom: moment(payload.xDomain[0]),
		dateto: moment(payload.xDomain[1]),
		timeto: moment(payload.xDomain[1]),
		ipSource: convertIpFiltersSymbols(ipsSource),
		ipDestination: convertIpFiltersSymbols(ipsDestination),
		portsDestination: formDestPorts,
		portsSource: formSrcPorts,
		ipProtocol: formIpProtocols,
		devices: payload.deviceSelected,
		queryTypes: findQuery(payload.dataTypeOptions.value),
		isIn: _.has(proto, 'isIn') ? payload.typeproto.isIn : false,
		isOut: _.has(proto, 'isOut') ? payload.typeproto.isOut : false,
		isFWE_ALLOW: _.has(firewall, 'isFWE_ALLOW') ? payload.firewall.isFWE_ALLOW : false,
		isFWE_DENY: _.has(firewall, 'isFWE_DENY') ? payload.firewall.isFWE_DENY : false,
		...formIpWhoIs,
		...formTcpFlags,
		...formInterfaces,
		aws,
		vlan: formVlanFilter,
		vlanIdBidirectionalTraffic,
	};
};

export { fromPayloadtoForm, FormToPayload };
