import React from 'react';
import changePasswordDialogHeader from '../../../assets/changePasswordDialogHeader.svg';
import ChangePasswordForm from "../forms/changePasswordForm";
import SimpleModal from "../../../shared/components/simpleTable/simpleModal";


function ChangePasswordDialog({onClose, onSubmit}) {

    return (
        <SimpleModal isOpen
                     icon={changePasswordDialogHeader}
                     header={"Change password"}
                     toggle={onClose}
                     height={"500px"}
                     width={"900px"}>
            <ChangePasswordForm onSubmit={onSubmit} cancelFormChanges={onClose}/>
        </SimpleModal>
    )
}

export default ChangePasswordDialog;