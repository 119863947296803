import action from '../../../app/controller/redux/middleware';
import { dataTypeOptions, processAnalysis, processSimpleAnalysis } from './analyzeActions';
import Storage from '../../../../services/storage';

import http from '../../../../services/httpService/http';
import _ from 'lodash';
import { BACKGROUND_PROCESS_STATUS_URI } from '../services/backgroundProcess/urls';
import { parseOriginalQuery } from '../businessLogic/parseProcessBackground';
import { findQuery } from '../businessLogic/mappers';
import { createBackgroundProcess, createBackgroundProcessByQueryId } from '../services/backgroundProcess/requests';
import { toLocaleShortDateString } from '../../../../helpers/datesHelper';
export const REMOVE_CURRENT_BACKGROUND_PROCESS = 'REMOVE_CURRENT_BACKGROUND_PROCESS';
export const BACKGROUND_PROCESSES = 'BACKGROUND_PROCESSES';
export const BACKGROUND_PROCESS_INIT = 'BACKGROUND_PROCESS_INIT';
export const CREATE_BACKGROUND_PROCESS = 'CREATE_BACKGROUND_PROCESS';
export const STATUS_BACKGROUND_PROCESS = 'STATUS_BACKGROUND_PROCESS';
export const REMOVE_BACKGROUND_PROCESS = 'REMOVE_BACKGROUND_PROCESS';
export const RENAME_BACKGROUND_PROCESS = 'RENAME_BACKGROUND_PROCESS';
export const UPDATE_BACKGROUND_PROCESS = 'UPDATE_BACKGROUND_PROCESS';
export const UPDATE_PER_SECOND = 'UPDATE_PER_SECOND';
export const REMOVE_ALL_PROCESS = 'REMOVE_ALL_PROCESS';
export const UPDATE_CURRENT_BACKGROUND_PROCESS = 'UPDATE_CURRENT_BACKGROUND_PROCESS';
export const GET_BACKGROUND_PROCESS = 'GET_BACKGROUND_PROCESS';
export const UPDATE_BACKGROUND_PROCESS_ARRAY = 'UPDATE_BACKGROUND_PROCESS_ARRAY';

export const UPDATE_BACKGROUND_PROCESS_1BY1 = 'UPDATE_BACKGROUND_PROCESS_1BY1';

const parseProcessBackground = res => {
	try {
		const e = {};
		let parsedQuery = {};
		const receivedPayload = JSON.parse(res.originalQuery);

		if (!_.isEmpty(res.originalQuery)) {
			parsedQuery = parseOriginalQuery(receivedPayload);
		}

		if (res.status !== 'ERROR') {
			if (!_.isEmpty(res.analysisResponse)) {
				const resAnalysis = res.analysisResponse;
				const analysisObj = JSON.parse(resAnalysis);

				e.analyzed_df = JSON.parse(analysisObj.analyzed_df);
				e.mid_attacks = JSON.parse(analysisObj.mid_attacks);
				e.high_attacks = JSON.parse(analysisObj.high_attacks);
				e.message = analysisObj.message;
				e.attack_status = parseInt(analysisObj.attack_status);

				const hasData = Boolean(Object.values(e.analyzed_df).length);
				let analyzedData = { hasData };
				if (hasData) {
					let data;
					const est_data = dataTypeOptions.find(e => e.value === receivedPayload.queryTypes[0]);

					if (est_data.queryType === 'timeUnit') {
						data = processAnalysis(e, receivedPayload.fromTimestamp, receivedPayload.toTimestamp, est_data.accessor);
						analyzedData = { ...data, ...analyzedData, message: e.message, status: parseInt(e.attack_status) };
					} else if (est_data.queryType === 'numberUnit') {
						data = processSimpleAnalysis(e, est_data.accessor);
						analyzedData = { ...data, ...analyzedData, message: e.message, status: parseInt(e.attack_status) };
					} else {
						console.log('Query Type not supported');
					}

					e.analyzedData = analyzedData;
				}
			}
		}

		return { ...res, analysisResponse: e, originalQuery: parsedQuery };
	} catch (error) {
		console.log(error);
	}
};

const initBackgroundProcess = () => action(async dispatch => {
	dispatch({
		type: BACKGROUND_PROCESS_INIT,
		payload: { processes: null },
	});
});

const statusBackGroundProcesses = async bgProcessId => {
	const res = await http.get(`${BACKGROUND_PROCESS_STATUS_URI}/${bgProcessId}`);
	if (!res || res.error) {
		return parseProcessBackground(res);
	}

	return parseProcessBackground(res);
};

const generateProcessInfo = (res, query) => {
	const resData = res;
	const est_data = findQuery(query.queryTypes[0]);
	resData.title = `${est_data.shorter} ${toLocaleShortDateString(query.fromTimestamp, true, '-')} - ${toLocaleShortDateString(query.toTimestamp, true, '-')}`;
	resData.status = 'INITIATE';
	return resData;
};

const newUpdateBackgroundProcess = () => action(async (dispatch, getState) => {
	const backgroundProcesses = getState().backgroundProcesses.processes.filter(e => _.has(e, 'status') && e.status !== 'COMPLETE');
	console.log('START UPDATE newUpdateBackgroundProcess');
	for (let index = 0; index < backgroundProcesses.length; index++) {
		const element = backgroundProcesses[index];
		const response = await statusBackGroundProcesses(element.workItemId);

		await dispatch({
			type: UPDATE_BACKGROUND_PROCESS_1BY1,
			payload: {
				...response,
				title: element.title,
				workItemId: element.workItemId,
				linkId: element.linkId,
				creationTime: element.creationTime,
			},
		});
	}
});

const createBackgroundProcessesById = (queryId, query, creationTime) => action(async (dispatch, getState) => {
	if (getState().backgroundProcesses.processes.length < 7) {
		const res = await createBackgroundProcessByQueryId(queryId);
		if (!res || res.error) {
			return null;
		}

		const data = generateProcessInfo(res, query);
		dispatch({
			type: CREATE_BACKGROUND_PROCESS,
			payload: { process: { ...data, linkId: queryId, creationTime } },
		});
		return data;
	}
});

const createBackGroundProcesses = query => action(async (dispatch, getState) => {
	if (getState().backgroundProcesses.processes.length < 7) {
		const res = await createBackgroundProcess(query);
		if (!res || res.error) {
			return null;
		}

		const data = generateProcessInfo(res, query);
		dispatch({
			type: CREATE_BACKGROUND_PROCESS,
			payload: { process: { ...data } },
		});
		return data;
	}
});

const removeCurrentBackgroundProcess = (backgroundProcessId, newName) => ({
	type: REMOVE_CURRENT_BACKGROUND_PROCESS,
	payload: { backgroundProcessId, newName },
});

const getProcessByToken = backgroundProcessId => ({
	type: GET_BACKGROUND_PROCESS,
	payload: { backgroundProcessId },
});

const removeBackgroundProcess = backgroundProcessId => action(async (dispatch, getState) => {
	const proc = getState().backgroundProcesses.processes;
	const indexProcess = proc.findIndex(e => e.workItemId === backgroundProcessId);
	proc.splice(indexProcess, 1);
	Storage.set('process', proc);
	const IsBackgroundProcess = getState().backgroundProcesses.currentProcess;
	let isCurrentProcess = false;
	if (!_.isEmpty(IsBackgroundProcess)
            && backgroundProcessId === IsBackgroundProcess.workItemId) {
		isCurrentProcess = true;
	}

	return {
		type: REMOVE_BACKGROUND_PROCESS,
		payload: { proc, isCurrentProcess },
	};
});

const renameBackgroundProcess = (backgroundProcessId, newName) => ({
	type: RENAME_BACKGROUND_PROCESS,
	payload: { backgroundProcessId, newName },
});

const updateIsPerSecond = newValue => ({ type: UPDATE_PER_SECOND, payload: newValue });

const removeAllBackgroundProcess = () => ({
	type: REMOVE_ALL_PROCESS,
});

export {
	parseProcessBackground,
	initBackgroundProcess,
	newUpdateBackgroundProcess,
	createBackgroundProcessesById,
	createBackGroundProcesses,
	removeCurrentBackgroundProcess,
	getProcessByToken,
	removeBackgroundProcess,
	renameBackgroundProcess,
	updateIsPerSecond,
	removeAllBackgroundProcess,
};
