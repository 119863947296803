import * as yup from 'yup';
import Validation from '../../../../../services/validation';
import { s3Address } from '../../../../deviceManagement/controller/constantes/osType';

export const LOW_SEVERITY_LABEL = 'Low';
export const LOW_SEVERITY_VALUE = 'low';
export const MEDIUM_SEVERITY_LABEL = 'Medium';
export const MEDIUM_SEVERITY_VALUE = 'medium';
export const HIGH_SEVERITY_LABEL = 'High';
export const HIGH_SEVERITY_VALUE = 'high';
export const CRITICAL_SEVERITY_LABEL = 'Critical';
export const CRITICAL_SEVERITY_VALUE = 'critical';

export const SEVERITIES = [
	{ label: LOW_SEVERITY_LABEL, value: LOW_SEVERITY_VALUE },
	{ label: MEDIUM_SEVERITY_LABEL, value: MEDIUM_SEVERITY_VALUE },
	{ label: HIGH_SEVERITY_LABEL, value: HIGH_SEVERITY_VALUE },
	{ label: CRITICAL_SEVERITY_LABEL, value: CRITICAL_SEVERITY_VALUE },
];

export const NAME_FORM_KEY = 'name';
export const NAME_FORM_LABEL = 'Name';
export const SERVER_ADDRESS_FORM_KEY = 'serverAddress';
export const SERVER_ADDRESS_FORM_LABEL = 'Hostname or IP';
export const PORT_FORM_KEY = 'port';
export const PORT_FORM_LABEL = 'Port';
export const PROTOCOL_FORM_KEY = 'protocol';
export const PROTOCOL_FORM_LABEL = 'Protocol';
export const CERTIFICATE_FORM_KEY = 'certificate';
export const CERTIFICATE_FORM_LABEL = 'CA Certificate';
export const CLIENT_KEY_FORM_KEY = 'key';
export const CLIENT_KEY_FORM_LABEL = 'Client key';
const SERVER_ADDRESS_VALIDATION_ERROR_MESSAGE = 'Please enter a valid Hostname or IP';
const NAME_VALIDATION_ERROR_MESSAGE = 'Please enter Syslog name';
const PORT_VALIDATION_ERROR_MESSAGE = 'Please enter a port number';
const CERTIFICATE_VALIDATION_ERROR_MESSAGE = 'Please enter a client certificate';
export const PROTOCOL_DEFAULT_LABEL = 'TLS';
export const CERTIFICATE_TITLE = 'Certificate';
export const CERTIFICATE_PLACEHOLDER_ROWS_SIZE = 10;
export const CERTIFICATE_EXAMPLE_PLACEHOLDER = '-----BEGIN CERTIFICATE-----'
	+ '\nMIICfTCCAeWgAwIBAgIJAIvIbr9S6qbzMA0GCSqGSIb3DQEBCwUAMBUxEzARBgNV'
	+ '.\n'
	+ '.\n'
	+ '.\n'
	+ '.\n'
	+ '.\n'
	+ '.\n'
	+ '.\ncW0OpFLgURp0d8p1Br0z0g5p8MHo+2uWqseCjgm48+BA/OhHXQ1uD54ZMqVmo'
	+ '.\n-----END CERTIFICATE-----';
export const CERTIFICATE_VIEW_ONLY_VALUE = '-----BEGIN CERTIFICATE-----'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n······················································································································································'
	+ '\n-----END CERTIFICATE-----';
export const SYSLOG_FORM_INITIAL_VALUE = { [PORT_FORM_KEY]: 6514, [PROTOCOL_FORM_KEY]: PROTOCOL_DEFAULT_LABEL };
export const formValidationSchema = yup.object({
	[SERVER_ADDRESS_FORM_KEY]: yup.string()
		.matches(Validation.ipAndHostnameRegex, SERVER_ADDRESS_VALIDATION_ERROR_MESSAGE)
		.required(SERVER_ADDRESS_VALIDATION_ERROR_MESSAGE),
	[NAME_FORM_KEY]: yup
		.string(NAME_VALIDATION_ERROR_MESSAGE)
		.required(NAME_VALIDATION_ERROR_MESSAGE),
	[PORT_FORM_KEY]: yup
		.string(PORT_VALIDATION_ERROR_MESSAGE)
		.required(PORT_VALIDATION_ERROR_MESSAGE),
	[CERTIFICATE_FORM_KEY]: yup
		.string(CERTIFICATE_VALIDATION_ERROR_MESSAGE)
		.required(CERTIFICATE_VALIDATION_ERROR_MESSAGE),
});

export const CONFIG_TITLE = 'Syslog configuration';
export const CONFIG_SUBTITLE = 'Logs will be sent based on CEF format, with detections and alerts. For help, download the ';
export const CONFIG_BUTTON = 'configuration guide.';
export const LOG_SETTING_TITLE = 'Log settings';
export const SEVERITY_TITLE = 'Minimum Severity';
export const SEVERITY_TOOLTIP = 'The system will send logs with the selected severity and above';
export const TEST_CONNECTION_LABEL = 'To test the Syslog configuration, press here';
export const TEST_CONNECTION_BUTTON_TEST = 'Test connection';
export const TEST_CONNECTION_FAILED = 'Failed to connect';
export const TEST_CONNECTION_SUCCESS = 'Connected successfully';
export const CREATE_SYSLOG_BUTTON_TEXT = 'Save';
export const WATCH_SYSLOG_BUTTON_TEXT = 'Close';
export const SAVE_ERROR_MESSAGE = 'Something went wrong.. Please try again';
export const SAVE_SUCCESS_MESSAGE = 'Syslog integration successfully configured';
export const DOWNLOAD_CONFIG = `https://${process.env.REACT_APP_CYNAMICS_ENV}-cynamics-public-assets.${s3Address}.amazonaws.com/syslog/syslog_configuration_guide.pdf`;
export const PROTOCOL_VALUE = 56;
