
const filterOptionsDays = [3, 7, 14, 30].map(e => {
    return { timeUnit: 'days', value: e, label: 'days' };
});
const filterOptionsMonths = [3, 6].map(e => {
    return { timeUnit: 'months', value: e, label: 'months' };
});

//.concat(filterOptionsMonths) 
export const allOptionsFilterShow = filterOptionsDays.map(e => {
    return {
        ...e,
        title: `Last ${e.value} ${e.label}`
    }
})



