import _ from 'lodash';

const mapDeviceCollectorWithCollector = (collectorDevices, collectors, samplers) => collectorDevices.map(e => {
	const isCollectorThere = _.find(collectors, q => {
		if (q._id === e.collectorId) {
			return q;
		}
	});

	const isSamplerThere = _.find(samplers, sampler => {
		if (sampler._id === e.samplerId) {
			return sampler;
		}
	});

	return { ...e,
		collector: _.isEmpty(isCollectorThere) ? '' : isCollectorThere.name,
		sampler: _.isEmpty(isSamplerThere) ? '' : isSamplerThere.name,
		attackStatus: 0 };
});
const mapCollector = collectors => collectors.map(a => ({
	...a,
	label: a.name,
	value: a._id,
}));

const mapSamplers = samplers => samplers.map(sampler => ({ ...sampler, label: sampler.name, value: sampler._id }));

const mapDeviceWithReference = (devices, references) => {
	const { brands, models } = references;

	return devices.map(collector => {
		const brand = _.has(collector, 'brand') ? brands.find(e => e.value === collector.brand) : null;
		const model = _.has(collector, 'model') && (!_.isEmpty(collector.model))
			? models[collector.brand].find(e => e.value === collector.model) : null;

		if (_.isEmpty(model)) {
			return ({
				...collector,
				attackStatus: 0,
			});
		}

		return ({
			...collector,
			brandName: brand.label,
			modelName: model.label,
			attackStatus: 0,
		});
	});
};

const createCollectorInternalPortMapping = info => {
	const mapping = {};
	if (info.isNetFlow) {
		mapping.netflow = info.netflow;
	}

	if (info.isSFlow) {
		mapping.sflow = info.sflow;
	}

	if (info.isIpFix) {
		mapping.ipfix = info.ipfix;
	}

	return mapping;
};

const arrangeLiteDevices = devices => {
	const liteDevices = {};
	devices.forEach(device => {
		liteDevices[device._id] = { IP: device.IP, name: device.name };
	});
	return liteDevices;
};

const arrangeLitePublicCloudDevice = devices => {
	const liteDevices = {};
	devices.forEach(device => {
		liteDevices[device._id] = {
			name: device.name,
			identifierValue: device.identifierValue,
		};
	});
	return liteDevices;
};

export {
	createCollectorInternalPortMapping,
	mapCollector,
	mapSamplers,
	mapDeviceCollectorWithCollector,
	mapDeviceWithReference,
	arrangeLiteDevices,
	arrangeLitePublicCloudDevice,
};

