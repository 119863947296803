import React from 'react';
import { IconButton, Divider } from '@material-ui/core';
import useStyles from './tableRowActions.css';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import RemediationIcon from '../../../../shared/../assets/icons/remediation.svg';
import StarIcon from '@material-ui/icons/Star';
import CustomTooltip from '../../Tooltip/CustomTooltip';

const UNAVAILABLE_REMEDIATION_TEXT = 'Remediation not available';
const REMEDIATION_TEXT = 'Remediation';

const TableRowActions = ({ onDelete, onHighlight, onRemediation, isHighlighted, hasRemediation }) => {
	const getTooltipContent = text => <p style={{ maxWidth: '13em', wordWrap: 'break-word', textAlign: 'center' }}>{text}</p>;
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<CustomTooltip title={getTooltipContent('Delete')}>
				<IconButton aria-label="delete" onClick={event => onDelete(event)}>
					<DeleteIcon className={classes.icon} alt="delete" fontSize="small"/>
				</IconButton>
			</CustomTooltip>
			<Divider orientation="vertical" variant="middle" flexItem/>
			<CustomTooltip title={getTooltipContent(isHighlighted ? 'Remove highlight' : 'Highlight')}>
				<IconButton aria-label="highlight" onClick={event => onHighlight(event)}>
					{
						isHighlighted
							? <StarIcon className={classes.highlighted} alt="highlight" fontSize="small"/>
							: <StarIcon className={classes.icon} alt="highlight" fontSize="small"/>
					}
				</IconButton>
			</CustomTooltip>
			<Divider orientation="vertical" variant="middle" flexItem />
			<CustomTooltip title={getTooltipContent(hasRemediation ? REMEDIATION_TEXT : UNAVAILABLE_REMEDIATION_TEXT)}>
				<span>
					<IconButton aria-label="remediate" onClick={event => onRemediation(event)} disabled={!hasRemediation}>
						<img src={RemediationIcon} alt="remediation" className={`${classes.remediation} ${!hasRemediation && classes.disabledRemediation}`}/>
					</IconButton>
				</span>
			</CustomTooltip>
		</div>
	);
};

TableRowActions.propTypes = {
	onDelete: PropTypes.func,
	onHighlight: PropTypes.func,
	onRemediation: PropTypes.func,
	isHighlighted: PropTypes.bool,
	hasRemediation: PropTypes.bool,
};

export default TableRowActions;
