export const SMS_MFA_KEY = 'SMS';
export const TOTP_MFA_KEY = 'AUTHENTICATOR';
export const NO_MFA_KEY = 'NO_MFA';

export const REMOVE_MFA_TEXT = 'This will remove the Multi-Factor Authentication for your account.';
export const REMOVE_MFA_DISCLAIMER_TEXT = 'Are you sure you want to continue?';


export const mfaTextsMapping = {
	[SMS_MFA_KEY]: 'Your account is currently protected by SMS',
	[TOTP_MFA_KEY]: 'Your account is currently protected by an Authenticator app',
	[NO_MFA_KEY]: 'Protect your account by enabling Multi-Factor Authentication',
};
