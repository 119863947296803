import React, {useEffect, useState} from 'react';
import LayoutForm from "../../../shared/components/form/LayoutForm";
import {updatePassword, validateLink} from "../resetPasswordRequests";
import SetPasswordForm from "../../invitations/components/SetPasswordForm";
import ErrorMessage from "../../../shared/components/form/ErrorMessage";
import {useStyles} from "./screens.css";
import {SET_ERROR} from "../../app/controller/redux/message/messageActions";
import {useDispatch} from "react-redux";

const UPDATE_PASSWORD_ERROR_MESSAGE = "Unable to reset password due to an unknown error. Please try again later.";
const INVALID_INVITE_TITLE = "Invalid invitation";
const INVALID_INVITE_SUBTITLE = "Please contact account management";

function ChoosePasswordScreen({match, history}) {
    const linkId = match.params.linkId;
    const dispatch = useDispatch();
    const [isLinkValid, setIsLinkValid] = useState(true);
    const [linkData, setLinkData] = useState({Email: ""});
    const classes = useStyles();

    useEffect(() => {
        validate();
    }, [])

    const validate = async () => {
        validateLink(linkId).then((res => {
            setLinkData(res);
        })).catch((error => {
            setIsLinkValid(false);
        }));
    }

    const handleSubmit = (data) => {
        const payload = {email: linkData.Email, password: data.password};
        updatePassword(linkId, payload).then(res => {
            history.push("/password-changed");
        }).catch(error => {
            if (error.status === 404) {
                setIsLinkValid(false);
            } else {
                dispatch({
                    type: SET_ERROR,
                    message: UPDATE_PASSWORD_ERROR_MESSAGE
                });
            }
        });
    }

    const goToLogin = () => {
        history.push("/login");
    }


    return (
        <LayoutForm customMessage={" "}>
            <div className={classes.screenTitle}>
                Select New Password
            </div>
            {isLinkValid ? <SetPasswordForm email={linkData.Email} onSubmit={handleSubmit}/> :
                <ErrorMessage message={INVALID_INVITE_TITLE} subMessage={INVALID_INVITE_SUBTITLE}
                              onSubmit={goToLogin}/>}
        </LayoutForm>
    )
}

export default (ChoosePasswordScreen);