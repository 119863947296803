import React from "react";
import {Table, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

const rows = [
    { samplingProtocol: 'Netflow', dns: 'netflow.cynamics.net', ip: '3.223.219.142', port: 8051},
    { samplingProtocol: 'sFlow', dns: 'sflow.cynamics.net', ip: '34.237.233.10', port: 8050},
    { samplingProtocol: 'IPFIX', dns: 'ipfix.cynamics.net', ip: '107.23.145.25', port: 4739},
];


const CynamicsFlowDetails = () => {
    return(
        <TableContainer style={{width:'520px', marginTop: '3%'}} component={Paper}>
            <Table size="small" aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" size={"small"}>Flow Type</TableCell>
                        <TableCell align="left" size={"small"}>DNS Address</TableCell>
                        <TableCell align="left" size={"small"}>IP</TableCell>
                        <TableCell align="left" size={"small"}>Port</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell align="left">{row.samplingProtocol}</TableCell>
                            <TableCell align="left">{row.dns}</TableCell>
                            <TableCell align="left">{row.ip}</TableCell>
                            <TableCell align="left">{row.port}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>)
}

export default CynamicsFlowDetails;