import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container:{
        height: '95%',
        paddingTop: '4%',
        paddingLeft: '10%',
        paddingRight: '10%'
    },
    contact: {
        padding: "10px 0 10px 0"
    },
    paymentContainer:{
        width: "90%"
    },
    paymentMethodItem:{
    },
    paymentText:{
        fontSize: "16px"
    },
    contactText: {
        fontSize: "14px"
    },
    contactLink: {
        color: theme.palette.primary.main,
        "&:hover":{
            color: theme.palette.primary.main
        }
    },
    formItem:{
    },
    divider: {
        textAlign: "center",
        paddingBottom: "20px",
        width: "48%"
    },
    plansOptions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },

    popularPlan: {

    },

    regularPlan: {
        alignSelf: 'center'
    },

    currentPlanTitle: {
        height: '50px',
        fontWeight: '400',
        fontSize: '21px',
        lineHeight: '50px'
    },
    planName: {
        fontWeight: '700',
    },
    creditCardSuppliers: {
        marginTop:"auto",
        paddingLeft: "2%",
        marginBottom:"auto",
        display:"block"
    },
    selectSection:{
        height: '100%'
    }
}));

export {useStyles}