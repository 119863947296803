import React from 'react';
import {useStyles} from "./groupTable.css";
import {TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeleteIcon from "../../../../assets/smart-table/delete.svg";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Paper from '@material-ui/core/Paper';
import TableBody from "@material-ui/core/TableBody";


const GroupTable = ({rows, onDeleteAddress}) => {
    const classes=useStyles();
    const headCells = [
        { id: 'address', label: 'Address'},
        { id: 'type', label: 'Type' },
        { id: 'delete', label: ''}
    ];

    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table>
                <TableHead className={classes.header}>
                    <TableRow>
                        {headCells.map((headCell) =>(
                            <TableCell key={headCell.id} size="small" align={'left'} className={classes.headCell}>
                                {headCell.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    { rows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell size="small" align="left" className={classes.cell}>{row.address}</TableCell>
                            <TableCell size="small" align="left" className={classes.cell}>{row.type}</TableCell>
                            <TableCell size="small" align="right" className={classes.cell}>
                                <img src={ DeleteIcon }
                                     alt='Delete'
                                     onClick={ () => { onDeleteAddress(row.address) } }
                                     className={classes.icon}
                                /></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default GroupTable;
