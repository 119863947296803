import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { Skeleton } from '@material-ui/lab';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

import useStyles from './skeletonTable.css';

const SkeletonTable = ({ columns, rowsPerPage, className }) => {
	const classes = useStyles();

	return (
		<Table component={Paper} elevation={3} className={`${classes.wrapper} ${className}`}>
			<TableHead className={classes.head}>
				{columns.map(column => (
					<TableCell className={classes.headCell} key={`header-${column.alias}`}>
						<span className={classes.headerTextColor}>{column.alias || column.label}</span>
					</TableCell>
				))}
			</TableHead>
			<TableBody>
				{range(rowsPerPage).map((value, rowIndex) => (
					<TableRow key={rowIndex}>
						{columns.map((nValue, columnIndex) => (
							<TableCell key={`${rowIndex}-${columnIndex}`}>
								<Skeleton animation="wave" variant="text" />
							</TableCell>
						))}
					</TableRow>
				))}
			</TableBody>
		</Table>
	);
};

SkeletonTable.propTypes = {
	className: PropTypes.string,
	columns: PropTypes.array,
	rowsPerPage: PropTypes.number,
};

export default SkeletonTable;
