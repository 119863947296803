import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        height: '144px',
        width: '375px',
        border: '1px solid #979797',
        borderRadius: '5px',
        boxSizing: 'border-box'
    },

    borderBottom: {
        height: '70%',
        paddingTop: '15px',
        borderBottom: '1px solid #979797'
    },

    textAlign: {
      paddingLeft: '15px'
    },

    planName: {
        fontSize: '24px',
        fontWeight: '600'
    },
    price: {
        fontSize: '18px',
        fontWeight: '400'
    },
    subscriptionText: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '40px'
    },
    subscriptionDate: {
        fontWeight: '700'
    }
}));

export {useStyles}