import React from 'react';
import './graphHint.scss'
import Moment from "moment";
import * as d3 from "d3-format";
import { levelColors } from '../../graphConfig';
import { minMaxformater, timeFormaterGraphPopOver } from '../../../../../constants/formater';


function GraphHint({ info, expanded, xTitle, yTitle }) {
    let ips, counts;
    if (info.topTalkers) {
        ips = Object.keys(info.topTalkers).map((ip, index) => {
            return (
                <div key={ index } className="detail-info space">
                    { ip }
                </div>
            )
        });
        counts = Object.keys(info.topTalkers).map((ip, index) => {
            return (
                <div key={ index } className="detail-info">
                    { d3.format(minMaxformater)(info.topTalkers[ip]) }
                </div>
            )
        });
    }
    return (
        <div className="hint-wrapper" style={ { borderColor: levelColors[info.y == 0 ? 0 : info.level] } }>
            <div className="hint">
                <div className="detail">
                    <div className="detail-title">
                        { xTitle }
                    </div>
                    <div className="detail-info">
                        { Moment(info.x).format(timeFormaterGraphPopOver) }
                    </div>
                </div>
                <div className="detail">
                    <div className="detail-title">
                        { yTitle }
                    </div>
                    <div className="detail-info">
                        { d3.format(minMaxformater)(info.y) }
                    </div>
                </div>
                { expanded &&
                    <div className="detail">
                        <div className="talker">
                            <div className="col space talkers-ip">
                                <div className="detail-title">
                                    Top Talkers IPs
                            </div>
                                { ips }
                            </div>
                            <div className="col talkers-count">
                                <div className="detail-title">
                                    Count
                            </div>
                                { counts }
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default GraphHint;
