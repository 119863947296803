import React from 'react';
import useStyles from './EmptyTable.css';
import emptyTableNoFilters from '../../../../assets/detectionCenter/emptyTableNoFilters.svg';

const NO_DETECTIONS_TEXT = 'No VCA Detections';
const NO_DETECTIONS_SUBTEXT = 'You may want to try adjusting the search and\\or filter options';

const EmptyTableNoFilters = () => {
	const classes = useStyles();

	return (
		<>
			<img
				src={emptyTableNoFilters}
				alt="No detections"
				className={classes.noDetectionsImageNoFilters}
			/>
			<div className={classes.emptyStateText}>{NO_DETECTIONS_TEXT}</div>
			<div className={classes.emptyStateSubtext}>{NO_DETECTIONS_SUBTEXT}</div>
		</>
	);
};

export default EmptyTableNoFilters;
