import React from 'react'
import InformationBox from "../base/InformationBox";
import generalHeader from '../../../../assets/notifications/blue-all-good.svg';
import PropTypes from "prop-types";


const propTypes = {
    header: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    callback: PropTypes.func,
}

const defaultProps = {
    buttonText: "finish",
}

function GeneralIndication({header, description, buttonText, outlined, callback}){

    return (
        <InformationBox header={header}
                        headerImg={generalHeader}
                        buttonText={buttonText}
                        description={description}
                        outlined={outlined}
                        callback={callback}
                        buttonColor={"primary"} />
    )
}

GeneralIndication.propTypes = propTypes;
GeneralIndication.defaultProps = defaultProps;
export default GeneralIndication;