import '../backgroundProcess/baseLine/baseline.scss';
import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';

import { createBackGroundProcesses, removeCurrentBackgroundProcess, createBackgroundProcessesById } from '../../controller/redux/backgroundProcessAction';
import { connect } from 'react-redux';
import CurrentProcess from './currentProcess/currentProcess';
import PopupGraph from '../../../topTalkers/popOvers/popOver';
import { Reset } from '../backgroundProcess/initialPayload/initialPayload';
import './background-process-view.scss';
import { getFormValues } from 'redux-form';
import { fromPayloadtoForm, FormToPayload } from '../../controller/businessLogic/formPayloadManipulations';

import {
	validateLinkQueryFutureTimes,
	validateQueryForm,
	validateQueryTimes,
} from '../../controller/validations/validation';
import { setTitle } from '../../../app/controller/redux/app/appActions';
import { timeFormaterGraphPopOver } from '../../../../constants/formater';
import { createQueryLink, getQueryById } from '../../controller/services/visibilityView/requests';
import { parseOriginalQuery } from '../../controller/businessLogic/parseProcessBackground';
import { generateCreationTimeWarning } from '../../controller/utils/common';
import AnalysisAccordion from './analysisAccordion/analysisAccordion';
import { condenseForm, toggleExpandForm } from '../../controller/redux/analysisFormActions';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import analyzer from '../../../../helpers/analyzer';
import { ANALYSIS_CATEGORY, LOAD_ACTION, SUBMIT_ACTION } from '../../../../helpers/analyzerConstants';
import WalkthroughDialog from '../../../walkthrough/walkthroughDialog/walkthroughDialog';
import welcomeImage from '../../../../assets/walkthrough/analysis/steps/welcome.png';
import Welcome from '../../../../shared/components/walkthrough/welcome';
import Step1 from './walkthrough/steps/step1/step1';
import Step2 from './walkthrough/steps/step2/step2';
import Step3 from './walkthrough/steps/step3/step3';
import Step4 from './walkthrough/steps/step4/step4';
import Step5 from './walkthrough/steps/step5/step5';
import Step6 from './walkthrough/steps/step6/step6';
import Step7 from './walkthrough/steps/step7/step7';
import Step8 from './walkthrough/steps/step8/step8';
import { updateWalkthroughDone } from '../../../app/controller/redux/dashboardInteractions/actions';
import { ANALYSIS_KEY } from '../../../app/controller/redux/dashboardInteractions/consts';
import TopTalkers from '../../../topTalkers/topTalkers';
import { formatBase2 } from '../../../topTalkers/formatters';

const MAX_SEGMENT_DURATION_IN_HOURS = 5;
const MAX_PROCESSES_COUNT = 7;
const ANALYZER_CATEGORY = ANALYSIS_CATEGORY;
const serviceName = 'Analysis';
const serviceDescription = 'Your network query and optimization center';

class BackgroundProcessView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLinkFromApp: false,
			isQueryLink: false,
			queryData: {},
			linkCreationTimeWarning: '',
			queryTitle: 'Run New Query',
			isSubmitting: false,
		};
		this.onSubmit = this.onSubmit.bind(this);
		this.createQueryLink = this.createQueryLink.bind(this);
	}

	componentDidMount = () => {
		this.props.setTitle('Analysis View');
		let isLinkFromApp = false;
		// Automaticaly close the Query bar when clicking on BG Process from an other view
		if (!_.isEmpty(this.props.location) && !_.isEmpty(this.props.location.state)) {
			const locationState = this.props.location.state;
			if (_.has(locationState, 'isQueryFromApp') && locationState.isQueryFromApp === true) {
				this.setState({ isLinkFromApp: true });
				isLinkFromApp = true;
			}
		}

		if (this.props.location.pathname.includes('query') && !isLinkFromApp) {
			this.loadLinkQueryData(this.props.match.params.id);
		}

		// For link queries, show link url and creation time warning
		if (!_.isEmpty(this.props.currentProcess) && !_.isEmpty(this.props.currentProcess.linkId)) {
			this.setState({ linkCreationTimeWarning: generateCreationTimeWarning(this.props.currentProcess.creationTime) });
			this.props.history.push(`/query/${this.props.currentProcess.linkId}`);
		}
	};

	buildQueryData = payload => {
		const parsedData = parseOriginalQuery(payload);
		const formData = fromPayloadtoForm(parsedData);
		return formData;
	};

	loadLinkQueryData = async id => {
		analyzer.event({ category: ANALYZER_CATEGORY, action: LOAD_ACTION, label: 'LINK_TO_QUERY' });

		const data = await getQueryById(id);
		const originalQuery = JSON.parse(data.query.OriginalQuery);
		const creationTime = data.query.CreationTime;
		let queryFormData = this.buildQueryData(originalQuery);
		queryFormData = validateLinkQueryFutureTimes(queryFormData);
		const error = validateQueryForm(queryFormData, this.props.processes, MAX_SEGMENT_DURATION_IN_HOURS, MAX_PROCESSES_COUNT);
		if (error) {
			this.props.openSnackbar('error', error, 4000);
		} else {
			await this.props.createBackgroundProcessesById(id, originalQuery, creationTime);
		}
	};

	onSubmit = async content => {
		this.setState({ isSubmitting: true });
		try {
			const error = validateQueryForm(content, this.props.processes, MAX_SEGMENT_DURATION_IN_HOURS, MAX_PROCESSES_COUNT);
			if (error) {
				this.props.openSnackbar('error', error, 4000);
			} else {
				analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'ANALYSIS_QUERY_REQUEST' });
				const backgroundPayload = FormToPayload(content);
				const result = await this.props.createBackGroundProcesses(backgroundPayload);
				if (!result) {
					throw new Error("Something went wrong. Try again");
				}
			}
		} catch (e) {
			this.props.openSnackbar('error', e.message, 4000);
		} finally {
			this.setState({ isSubmitting: false });
		}
	};

	requestLinkCreation = async backgroundPayload => {
		try {
			analyzer.event({ category: ANALYZER_CATEGORY, action: SUBMIT_ACTION, label: 'LINK_TO_QUERY' });
			const res = await createQueryLink({ originalQuery: backgroundPayload });
			return res;
		} catch (error) {
			this.props.openSnackbar('error', 'Error in link creation', 4000);
		}
	};

	createQueryLink = async event => {
		const content = this.props.values;
		const error = validateQueryTimes(content, MAX_SEGMENT_DURATION_IN_HOURS);
		if (error) {
			this.props.openSnackbar('error', error, 4000);
		} else {
			const backgroundPayload = FormToPayload(content);
			const res = await this.requestLinkCreation(backgroundPayload);
			if (res && res.queryId) {
				return res.queryId;
			}
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.currentProcess !== this.props.currentProcess) {
			if (!_.isEmpty(this.props.currentProcess) && this.props.currentProcess.creationTime) {
				this.setState({ linkCreationTimeWarning: generateCreationTimeWarning(this.props.currentProcess.creationTime) });
			} else {
				this.setState({ linkCreationTimeWarning: '' });
			}
		}
	}

	finishWalkthrough = async () => {
		this.props.updateWalkthroughDone(ANALYSIS_KEY, true);
	};

	render() {
		const { currentProcess } = this.props;
		const currentProcessLinkId = !_.isEmpty(currentProcess) ? currentProcess.linkId : null;

		let currentInitialValues = {};
		if (currentProcess && currentProcess.originalQuery) {
			currentInitialValues = fromPayloadtoForm(currentProcess.originalQuery);
		} else {
			currentInitialValues = Reset;
		}

		const steps = [Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8];
		return (
			<>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<div className="content">
						<AnalysisAccordion
							initialValues={currentInitialValues}
							isExpanded={this.props.isFormExpanded}
							toggleExpand={this.props.toggleExpandForm}
							currentProcessLinkId={currentProcessLinkId}
							createQueryLink={this.createQueryLink}
							onSubmit={this.onSubmit}
							isSubmitting={this.state.isSubmitting}
							queryTitle={currentProcess && currentProcess.title ? currentProcess.title : 'Define time segment to analyze'}
						/>
						<div className="error-box">
							<div className="error-messages">
								{!_.isEmpty(this.state.linkCreationTimeWarning) ? this.state.linkCreationTimeWarning : null}
							</div>
						</div>
						<CurrentProcess />
						<PopupGraph
							content={
								!_.isEmpty(this.props.newPoint)
									? <TopTalkers
										topConnectionsData={this.props.newPoint}
										startTime={moment(this.props.newPoint.x).format(timeFormaterGraphPopOver)}
										estVolume={formatBase2(this.props.newPoint.y)}
									/> : null}
							isOpen={!_.isEmpty(this.props.newPoint)}
							anchorPosition={this.props.event}
						/>
					</div>
					{this.props.visibleDevicesCount !== 0 && !this.props.isWalkthroughDone
						&& <WalkthroughDialog
							serviceName={serviceName}
							serviceDescription={serviceDescription}
							welcomeImage={welcomeImage}
							StartComponent={Welcome}
							steps={steps}
							skipCallback={this.finishWalkthrough}
							finishCallback={this.finishWalkthrough} />}
				</MuiPickersUtilsProvider>
			</>
		);
	}
}
//

const mapStateToProps = state => ({
	isFormExpanded: state.backgroundProcesses.isFormExpanded,
	currentProcess: state.backgroundProcesses.currentProcess,
	newPoint: state.graph.newPoint,
	event: state.graph.event,
	processes: state.backgroundProcesses.processes,
	isWalkthroughDone: state.interactions.analysis.walkthroughDone,
	visibleDevicesCount: state.visibleDevicesCount.value,
	values: getFormValues('create-backgroung-process')(state),
});

const mapDispatchToProps = {
	condenseForm,
	createBackGroundProcesses,
	createBackgroundProcessesById,
	removeCurrentBackgroundProcess,
	setTitle,
	toggleExpandForm,
	openSnackbar,
	updateWalkthroughDone,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundProcessView);
