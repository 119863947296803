import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, getFormValues } from 'redux-form';
import Dropdown from '../../../../shared/components/dropdown/dropdown';
import usePrevious from '../../../../shared/customHooks/usePrevious';
import { getModelsFromKey } from '../../controller/businessLogic/getModelsFromKey';
import SamplerCheckbox from './samplerCheckbox/SamplerCheckbox';

const DeviceFormTyped = ({ mode, initialValues, deviceReferences, onChangeConfig, setConfigType, form, change, isEditMode, isSamplerSupported = false }) => {
	const [brand, setBrand] = useState();
	const [model, setModel] = useState();
	const [values, setValues] = useState(initialValues);

	const { brands, models } = deviceReferences;
	const previousInitialValues = usePrevious(initialValues);
	const formData = useSelector(state => getFormValues(form)(state));

	const shouldAddSampler = isSamplerSupported && formData.model && formData.model.includes('other');

	useEffect(() => {
		if (formData.brand && formData.model) {
			setBrand(formData.brand);
			setModel(formData.model);
		}
	}, []);

	useEffect(() => {
		if (formData.brand && formData.model) {
			const configType = !_.isEmpty(models[formData.brand]) ? models[formData.brand].find(a => formData.model === a.value) : null;
			onChangeConfig({
				brand: formData.brand,
				model: formData.model,
			});
			const config = { ...configType, brand: formData.brand };
			setConfigType(config);
		}
	}, [form]);

	const onModelChange = newModel => {
		setValues({ initialValues: { previousInitialValues, model: newModel } });
		onChangeConfig({
			model: newModel,
			brand,
		});
		setModel(newModel);
		const configType = !_.isEmpty(models[brand]) ? models[brand].find(a => newModel === a.value) : null;
		const config = { ...configType, brand };
		setConfigType(config);
	};

	const onBrandChange = newBrand => {
		setBrand(newBrand);
		onChangeConfig({
			brand: newBrand,
			model: null,
		});
	};

	return (
		<div>

			<Grid container spacing={2}>
				<Grid item xs={6}>
					<Field
						isDisabled={mode === 'edit' ? _.has(values, 'brand') : false}
						name="brand"
						label="Brand"
						options={brands}
						onFieldChange={brand => {
							onBrandChange(brand);
						}}
						component={Dropdown}
						required
					/>
				</Grid>
				<Grid item xs={6}>
					<Field
						isDisabled={mode === 'edit' ? _.has(values, 'model') : false}
						name="model"
						label="Model"
						options={brand && getModelsFromKey(models, brand)}
						onFieldChange={model => {
							onModelChange(model);
						}}
						component={Dropdown}
						required
					/>
				</Grid>
				{shouldAddSampler && !isEditMode && <SamplerCheckbox change={change} form={form} />}
			</Grid>
		</div>
	);
};

DeviceFormTyped.propTypes = {
	mode: PropTypes.string,
	initialValues: PropTypes.object,
	deviceReferences: PropTypes.object,
	onChangeConfig: PropTypes.func,
	setConfigType: PropTypes.func,
	form: PropTypes.string,
};

DeviceFormTyped.propTypes = {
	deviceReferences: PropTypes.shape({
		brands: PropTypes.any,
		models: PropTypes.any,
	}),
	initialValues: PropTypes.object,
	mode: PropTypes.string,
	onChangeConfig: PropTypes.func,
	onDisapear: PropTypes.func,
	setConfigType: PropTypes.func,
	change: PropTypes.func,
	form: PropTypes.string,
	isEditMode: PropTypes.bool,
	isSamplerSupported: PropTypes.bool,
};

export default DeviceFormTyped;

