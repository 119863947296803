import React from 'react';
import TextFieldWrapper from '../../../../shared/components/form/textFieldWrapper/TextFieldWrapper';
import { FormControlLabel, Divider, Checkbox } from '@material-ui/core';
import useStyles from './checkboxTextField.css';
import { propTypes, defaultProps } from './props';
import { useState, useEffect } from 'react';

function CheckboxTextField({ textFieldLabel, checkboxes, checkboxSelectAll }) {
	const [labelsToChecked, setLabelsToChecked] = useState({});
	const [isAllSelected, setIsAllSelected] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		initLabelsToChecked();
	}, []);

	const initLabelsToChecked = () => {
		const values = {};
		if (checkboxes && checkboxSelectAll && checkboxes.length > 0) {
			for (let i = 0; i < checkboxes.length; i++) {
				values[checkboxes[i].label] = checkboxes[i].checked;
			}
		}

		setLabelsToChecked(values);
		updateIsAllSelected(values);
	};

	const updateIsAllSelected = (newLabelsToChecked, checked) => {
		const labels = Object.keys(newLabelsToChecked);
		if (checked !== null) {
			setIsAllSelected(checked);
			return;
		}

		for (let i = 0; i < labels.length; i++) {
			if (!newLabelsToChecked[labels[i]]) {
				setIsAllSelected(false);
				return;
			}
		}

		setIsAllSelected(true);
	};

	const selectAll = checked => {
		const labels = Object.keys(labelsToChecked);
		for (let i = 0; i < labels.length; i++) {
			updateCheckbox(checked, labels[i]);
		}

		updateIsAllSelected({}, checked);
		(checkboxSelectAll.onChange)();
	};

	const onChange = checkbox => {
		updateCheckbox(!labelsToChecked[checkbox.label], checkbox.label);
		(checkbox.onChange)();
	};

	const updateCheckbox = (checked, label) => {
		setLabelsToChecked(currentLabelsToChecked => {
			const newLabelsToChecked = { ...currentLabelsToChecked };
			newLabelsToChecked[label] = checked;
			updateIsAllSelected(newLabelsToChecked);
			return newLabelsToChecked;
		});
	};

	return ((checkboxes && checkboxSelectAll && checkboxes.length > 0) && <TextFieldWrapper
		fullWidth
		label={textFieldLabel}
		InputProps={{
			classes: {
				root: classes.checkboxContainer,
			},
			startAdornment: (
				<div className={classes.checkboxContainer}>
					<div className={classes.checkboxSection}>
						{checkboxes && Object.keys(checkboxes).length > 1 && <FormControlLabel
							className={classes.checkboxLabel}
							control={<Checkbox
								color={'primary'}
								onChange={e => selectAll((e.target.checked))}
								checked={isAllSelected}
							/>
							}
							label="Select all"
						/>}
					</div>
					<Divider className={classes.divider} />
					<div className={classes.checkboxSection}>
						{
							checkboxes.map(checkbox => <FormControlLabel
								className={classes.checkboxLabel}
								control={<Checkbox
									onChange={() => onChange(checkbox)}
									color={'primary'}
									defaultChecked={checkbox.checked}

								/>}
								label={checkbox.label}
								checked={Boolean(labelsToChecked[checkbox.label])}
								key={checkbox.label}
							/>,
							)
						}
					</div>
				</div>
			),
		}}
	/>
	);
}

CheckboxTextField.defaultProps = defaultProps;

CheckboxTextField.propTypes = propTypes;


export default CheckboxTextField;
