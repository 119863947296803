import {ReactComponent as AWSIcon} from "./servicesIcons/awsLogo.svg";
import {ReactComponent as AzureIcon} from "./servicesIcons/azureLogo.svg";
import {ReactComponent as GoogleCloudIcon} from "./servicesIcons/googleCloudLogo.svg";
import AwsFilterForm from "./aws/awsFilterForm";

const AWS_FILTER_LABEL = 'Amazon AWS Filter';
const AWS_FILTER_VALUE = 'aws';

const GOOGLE_FILTER_LABEL = 'Google Cloud Filter';
const GOOGLE_FILTER_VALUE = 'google';

const AZURE_FILTER_LABEL = 'Azure Cloud Filter';
const AZURE_FILTER_VALUE = 'azure';

const cloudFilterOptions = [
    {
        label: AWS_FILTER_LABEL,
        value: AWS_FILTER_VALUE,
        icon: AWSIcon,
        isDisabled: false
    },
    {
        label: GOOGLE_FILTER_LABEL,
        value: GOOGLE_FILTER_VALUE,
        icon: GoogleCloudIcon,
        isDisabled: true
    },
    {
        label: AZURE_FILTER_LABEL,
        value: AZURE_FILTER_VALUE,
        icon: AzureIcon,
        isDisabled: true
    }
]


const filterMapping = {
    [AWS_FILTER_VALUE]: {
        formComponent: AwsFilterForm
    },
    [GOOGLE_FILTER_VALUE]: {

    },
    [AZURE_FILTER_VALUE]: {

    }
}

export {cloudFilterOptions, filterMapping, AWS_FILTER_VALUE, GOOGLE_FILTER_VALUE, AZURE_FILTER_VALUE}