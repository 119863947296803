import { INACTIVE_TOOLTIP_CONTENT } from '../../../deviceManagement/view/devicesTable/devicesTableConfig';

const activeTypes = {
	ON: 'on',
	OFF: 'off',
	NONE: 'none',
	NOT_CONNECTED: 'failed',
};

const STATUS_LABELS = {
	on: {
		label: 'Active',
	},
	off: {
		label: 'Disabled',
	},
	none: {},
	failed: {
		label: 'Failed to connect',
		tooltip: 'Please verify that your network firewall allows connections from the Cynamics account to your Syslog server',
	},
	activeDevice: {
		label: 'Active',
	},
	inactiveDevice: {
		label: 'Inactive',
		tooltip: INACTIVE_TOOLTIP_CONTENT,
	},
};

export {
	activeTypes,
	STATUS_LABELS,
};
