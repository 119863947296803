import { Dialog, DialogActions, DialogContent, DialogContentText } from '@material-ui/core';
import IpEnrichmentFields from './ipEnrichmentFields';
import StyleButton from '../../shared/components/form/Buttons/StyleButton';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
	root: {
		overflow: 'visible',
	},
	actions: {
		justifyContent: 'center',
		paddingBottom: '18px',
	},
});

const IpEnrichmentDialog = ({ enrichmentType: type, isOpen, handleClose, useContinent, fieldComponent }) => {
	const classes = useStyles();
	const groups = useSelector(state => state.account.groups);
	return (
		<div>
			<Dialog classes={{ paperScrollPaper: classes.root }}
				open={isOpen}
				onClose={handleClose}
				aria-labelledby="ip-enrichment-dialog"
				PaperProps={{ style: { border: '2px black' } }}>
				<div className="ip-enrichment-dialog-wrapper">
					<DialogContent className={classes.root}>
						<DialogContentText className="ip-enrichment-dialog-title">
							Advanced {type.label} IP Details
						</DialogContentText>
						<IpEnrichmentFields useContinent={useContinent} type={type} groups={groups}
							fieldComponent={fieldComponent}/>
					</DialogContent>
					<DialogActions classes={{ root: classes.actions }}>
						<StyleButton
							className="ip-enrichment-dialog-save-button" onClick={handleClose} label="Close"
							color="primary"/>
					</DialogActions>
				</div>
			</Dialog>
		</div>);
};

IpEnrichmentDialog.propTypes = {
	enrichmentType: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	handleClose: PropTypes.func,
	useContinent: PropTypes.bool,
	isOpen: PropTypes.bool,
	fieldComponent: PropTypes.element,
};

export default IpEnrichmentDialog;
