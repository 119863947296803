import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	highchartsContainer: {
		maxHeight: '95%',
		overflowY: 'auto',
	},
}),
);

export { useStyles };
