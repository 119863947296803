import { DEFAULT_STRING, findIpAlias, replaceInvalidPorts, LOGIC_FORMAT_VALUE_MAP } from '../services/logicsFormat';
import { LOGIC_COMPONENT_MAP } from '../services/tableDataPrep';

const buildSrcIpTable = (rawdata, endpoints) => [
	['IP:', findIpAlias([rawdata.srcIp], endpoints) || DEFAULT_STRING],
	['Country:', rawdata.srcCountry || DEFAULT_STRING],
	['Org:', rawdata.srcOrg || DEFAULT_STRING],
];

const buildDstIpTable = (rawdata, endpoints) => [
	['IP:', findIpAlias([rawdata.dstIp], endpoints) || DEFAULT_STRING],
	['Country:', rawdata.dstCountry || DEFAULT_STRING],
	['Org:', rawdata.dstOrg || DEFAULT_STRING],
];

const findSinglePortAlias = port => replaceInvalidPorts([port]);

export const TABLE_COLUMNS = [
	{ column: 'Creation time', value: 'creationTime', formatValue: LOGIC_FORMAT_VALUE_MAP.FORMAT_DATE_TIME_FN },
	{ column: 'SRC IP', formatValue: buildSrcIpTable, component: LOGIC_COMPONENT_MAP.TABLE },
	{ column: 'SRC port', value: 'srcPort', formatValue: findSinglePortAlias },
	{ column: 'DST IP', formatValue: buildDstIpTable, component: LOGIC_COMPONENT_MAP.TABLE },
	{ column: 'DST port', value: 'dstPort', formatValue: findSinglePortAlias },
	{ column: 'IP protocol', value: 'ipProtocol' },
	{ column: 'Packets num', value: 'packetsNum' },
	{ column: 'Volume(Bytes)', value: 'volume' },
];
