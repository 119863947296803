import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import DeviceManagement from '../../../deviceManagement/view/deviceManagement';
import UserManagement from '../../../userManagement/userManagement';
import SummaryView from '../../../SummaryView/SummaryView';
import ipGroupManagement from '../../../groupManagement/view/groupManagement';
import IntegrationPage from '../../../integrations/view/page/integrationsPage';
import { sidebarLinks } from '../layout/sidebar/sideBarConfig';

import useStyles from './mainLayoutPage.css';

import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import Layout from '../layout/layout';
import visibilitiyViewBackgroundProcess from '../../../backgroundProcess/view/visibilityView/background-process-view';

import HelpFrame from '../../../helpcenter/helpFrame';
import AlertPage from '../../../alertsManagement/view/alertesPage/alertePage';
import { effectiveSideLinks } from '../../controller/businessLogic/effectiveSideLinks';
import VcaPage from '../../../vcaManagement/vcaPage';
import AcceptlistPage from '../../../acceptlist/acceptlistPage';
import SelectPlanStarterComponent from '../../../plans/plansContainer/selectPlanStarterComponent';
import SubscriptionPage from '../../../subscription/subscriptionPage';
import UserSettings from '../../../userSettings/userSettings';
import ReferAFriendWrapper from '../../../referAFriend/ReferAFriendWrapper/ReferAFriendWrapper';
import { setOpenReferAFriendPrimaryPopup } from '../../../referAFriend/redux/referAFriendActions';
import NotFoundPage from '../../../../shared/components/notFoundPage/notFoundPage';
import PerformanceMonitoringPage from '../../../../shared/components/performanceMonitoring/performanceMonitoringPage';
import {
	PERFORMANCE_MONITORING_FEATURE_TOGGLE,
	SUBSCRIPTION_PAGE_FEATURE_TOGGLE, THREATS_PAGE_FEATURE_TOGGLE,
} from '../../../../constants/featureToggles';
import ThreatsPage from '../../../threatsManagement/view/threatsPage/threatsPage';
import DialogSSO from '../../../sso/DialogSSO';

const REFER_A_FRIEND_QUERY_PARAM_KEY = 'campaign';
const REFER_A_FRIEND_QUERY_PARAM_VALUE = 'internal_refer_a_friend';

export const MainLayoutPages = ({ location, history }) => {
	const { isAdmin } = useSelector(state => state.auth.user);
	const isAnalyst = !isAdmin;
	const activeFeatures = useSelector(state => state.account.features) || {};
	const SideLinks = effectiveSideLinks(sidebarLinks, activeFeatures, isAnalyst);
	const isPlansSelectionFlowActive = useSelector(state => state.plans.isPlansSelectionFlowActive);
	const isFullScreen = useSelector(state => state.fullScreen.isOpen);
	const hasUserNotifiedOnSSOMigration = useSelector(state => state.interactions?.sso?.userNotifiedOnSSOMigration);
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		if (queryParams.get(REFER_A_FRIEND_QUERY_PARAM_KEY) === REFER_A_FRIEND_QUERY_PARAM_VALUE) {
			dispatch(setOpenReferAFriendPrimaryPopup(true));
		}
	}, [location.search]);

	return (
		<>
			<Layout sidebarLinks={SideLinks}>
				<div
					id="full-screen-portal"
					className={classes.fullScreenPortalContainer}
					style={{ zIndex: isFullScreen ? 999 : -999 }}
				/>
				<Switch>
					<Route path="/overview" component={SummaryView}/>
					<Route path="/query/:id" component={visibilitiyViewBackgroundProcess}/>
					<Route path="/analysis" component={visibilitiyViewBackgroundProcess}/>
					<Route path="/device-management" component={DeviceManagement}/>
					<Route path="/alerts-management" component={AlertPage}/>
					<Route path="/vca-management" component={VcaPage} history={history}/>
					{THREATS_PAGE_FEATURE_TOGGLE
						&& <Route path="/threats" component={ThreatsPage}/>
					}
					<Route path="/acceptlist" component={AcceptlistPage}/>
					<Route path="/user-management" component={UserManagement}/>
					<Route path="/ip-group-management" component={ipGroupManagement}/>
					<Route path="/integrations" component={IntegrationPage}/>
					{PERFORMANCE_MONITORING_FEATURE_TOGGLE
						&& <Route path="/monitoring" component={PerformanceMonitoringPage}/>}
					{SUBSCRIPTION_PAGE_FEATURE_TOGGLE ? <Route path="/subscription" component={SubscriptionPage}>
						<Route path="/quote/:quoteId"/>
					</Route> : <Route path="/subscription/quote/:quoteId" component={SubscriptionPage}/>}
					<Route path="/settings" component={UserSettings}/>
					<Route path="/help" component={HelpFrame}/>
					<Route path="/not-found" component={NotFoundPage}/>
					<Route path="/" component={SummaryView}/>

				</Switch>
				{isPlansSelectionFlowActive && <SelectPlanStarterComponent/>}
				{hasUserNotifiedOnSSOMigration === false && <DialogSSO/>}

				<ReferAFriendWrapper history={history}/>
			</Layout>
		</>
	);
};

MainLayoutPages.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	user: PropTypes.object,
};
