import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../app/controller/redux/app/appActions';
import { Grid } from '@material-ui/core';
import PaymentFrame from './paymentFrame/paymentFrame';
import {
	getInvoices,
	getPaymentInfo,
	getProposalDetails,
	acceptQuoteProposal,
} from './services/subscriptionRequests';
import BillingFrame from './billingFrame/billingFrame';
import { openSnackbar } from '../snackbarAlert/controller/redux/actions';
import { useStyles } from './subscriptionPage.css';
import ProposalModal from './proposal/ProposalModal';
import { matchPath } from 'react-router';

const INVOICES_ERROR_MESSAGE = 'Your invoices could not be displayed';
const ERROR_MESSAGE = 'Something went wrong, please try again';

function SubscriptionPage() {
	const dispatch = useDispatch();
	const classes = useStyles();
	const currentPlan = useSelector(state => state.plans.currentPlanDetails);
	const [paymentInfo, setPaymentInfo] = useState();
	const [invoices, setInvoices] = useState();
	const [displayPaymentMethodSection, setDisplayPaymentMethodSection]
    = useState(false);
	const [isProposalAccepted, setIsProposalAccepted] = useState(false);
	const [isLoadingProposalAccepted, setIsloadingProposalAccepted] = useState(false);

	const [showProposalModal, setShowProposalModal] = useState(false);
	const [proposalDetails, setProposalDetails] = useState(undefined);

	useEffect(() => {
		dispatch(setTitle('Subscription'));
		fetchPaymentInfo();
		fetchInvoices();
		fetchProposalDetails();
	}, []);

	const fetchProposalDetails = async () => {
		if (!window) {
			return;
		}

		const match = matchPath(window.location.hash, {
			path: '#/subscription/quote/:quoteId',
			exact: false,
			strict: false,
		});
		const quoteId = match ? match.params.quoteId : null;
		if (quoteId) {
			setShowProposalModal(quoteId !== null);
			try {
				const proposalDetails = await getProposalDetails(quoteId);
				setProposalDetails(proposalDetails.quote);
			} catch {
				dispatch(openSnackbar('error', ERROR_MESSAGE, 4000));
			}
		}
	};

	const fetchPaymentInfo = async () => {
		const paymentInfoRes = await getPaymentInfo();
		setPaymentInfo(paymentInfoRes);
		setDisplayPaymentMethodSection(true);
	};

	const fetchInvoices = async () => {
		try {
			const allInvoices = await getInvoices();
			setInvoices(allInvoices);
		} catch {
			dispatch(openSnackbar('error', INVOICES_ERROR_MESSAGE, 4000));
		}
	};

	const onAcceptProposal = async quoteId => {
		try {
			setIsloadingProposalAccepted(true);
			await acceptQuoteProposal(quoteId);
			setIsProposalAccepted(true);
		} catch {
			dispatch(openSnackbar('error', INVOICES_ERROR_MESSAGE, 4000));
		} finally {
			setIsloadingProposalAccepted(false);
		}
	};

	return (
		<Grid className={classes.container} style={{ zIndex: 1000 }}>
			<Grid item>
				<PaymentFrame
					paymentInfo={paymentInfo}
					currentPlan={currentPlan}
					fetchPaymentInfo={fetchPaymentInfo}
					displayPaymentMethodSection={displayPaymentMethodSection}
				/>
			</Grid>

			<Grid item className={classes.billing}>
				<BillingFrame invoices={invoices} />
			</Grid>
			<ProposalModal
				quote={proposalDetails}
				isOpen={showProposalModal}
				onDismiss={() => {
					setShowProposalModal(false);
				}}
				onProposalAccept={quoteId => {
					onAcceptProposal(quoteId);
				}}
				proposalCompleted={isProposalAccepted}
				isLoading = {isLoadingProposalAccepted}
			/>
		</Grid>
	);
}

export default SubscriptionPage;
