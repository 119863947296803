import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
	form: {
		padding: '40px 42px 0 42px',
		maxHeight: '83vh',
		overflow: 'scroll',
		overflowX: 'hidden',
	},
	configTitle: {
		fontSize: '16px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
		textAlign: 'flex-start',
	},
	configSubTitle: {
		fontSize: '14px',
		color: theme.palette.text.secondary,
		textAlign: 'flex-start',
		marginTop: '24px',
	},
	downloadConfigButton: {
		textDecoration: 'underline',
		color: theme.palette.text.secondary,
		'&:hover': {
			textDecoration: 'underline',
			color: theme.palette.text.secondary,
		},
	},
	sectionTitle: {
		fontSize: '14px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
		textAlign: 'flex-start',
		margin: '20px 0 16px',
	},
	severityTitleContainer: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '20px',
	},
	severityTitle: {
		fontSize: '14px',
		color: theme.palette.inputText.main,
		textAlign: 'flex-start',
	},
	severityRadio: {
		marginTop: '10px',
		display: 'inline-block',
		flexDirection: 'column',
	},
	radio: {
		color: theme.palette.inputText.main,
		height: '16px',
		marginTop: '2px',
	},
	radioControl: {
		marginRight: '-5px',
	},
	severityLabel: {
		fontSize: '16px',
		marginRight: '10px',
	},
	divider: {
		width: '100%',
		marginTop: '20px',
	},
	testConnection: {
		marginTop: '20px',
		display: 'flex',
		flexDirection: 'row',
		cursor: 'pointer',
	},
	testConnectionButton: {
		marginLeft: '8px',
		'&:hover': {
			textDecoration: 'none',
			color: theme.palette.stepsText.active,
		},
	},
	testConnectionResult: {
		fontWeight: 700,
	},
	testConnectionSuccess: {
		color: '#67AC5B',
	},
	settingsContainer: {
		display: 'flex',
		flexDirection: 'column',
	},
	footer: {
		margin: '20px 0 20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	sendButton: {
		width: '200px',
		height: '50px',
	},
}));

export { useStyles };
