import { SET_VISIBLE_DEVICES_COUNT } from "./actionTypes";

const initialState = {
    value: 0
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case SET_VISIBLE_DEVICES_COUNT:
            return {
                ...state,
                value : payload.count
            }
        default:
            return state;
    }
}