import {useStyles} from "./mainPanelTemplateV2.css";
import {Grid,Typography} from "@material-ui/core";
import React from "react";

const MainPanelTemplateV2 = ({title, image, description}) => {
    const classes = useStyles();
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={5}>
                <Typography className={classes.title}>
                    { title }
                </Typography>
                <Typography className={classes.v2Description}>
                    { description }
                </Typography>
            </Grid>
            <Grid item xs={7} className={classes.v2Image}>
                { image }
            </Grid>

        </Grid>
    )
}

export default MainPanelTemplateV2;