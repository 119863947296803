import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Paper } from '@material-ui/core';
import SimpleModal from '../../shared/components/simpleTable/simpleModal';
import { useStyles } from './mfaWrapper.css';
import { MFA_SELECTOR, MFA_SETUP_MODAL_HEADER, QR_MFA_METHOD, SMS_MFA_METHOD } from './steps/stepsConfig';
import MfaSelector from './steps/mfaSelector/mfaSelector';
import SmsMfaMethod from './steps/configureSmsMfa/SmsMfaMethod';
import QrMfaMethod from './steps/configureQrMfa/ConfigureQrMfa';

export const configurationSteps = {
	[MFA_SELECTOR]: MfaSelector,
	[SMS_MFA_METHOD]: SmsMfaMethod,
	[QR_MFA_METHOD]: QrMfaMethod,
};

const MfaWrapper = ({ onClose, phoneNumber }) => {
	const classes = useStyles();
	const [step, setStep] = useState(MFA_SELECTOR);
	const SelectedStepComponent = configurationSteps[step];

	const returnToBeginning = () => {
		setStep(MFA_SELECTOR);
	};

	return (
		<SimpleModal
			header={MFA_SETUP_MODAL_HEADER}
			toggle={onClose}
			returnBackCallback={ step !== MFA_SELECTOR && returnToBeginning}
			width={850}
			isOpen
		>
			<Paper className={classes.modalPaper}>
				<SelectedStepComponent
					setStep={setStep}
					onSuccess={onClose}
					phoneNumber={phoneNumber}/>
			</Paper>

		</SimpleModal>);
};

MfaWrapper.propTypes = {
	onClose: PropTypes.func.isRequired,
	phoneNumber: PropTypes.string.isRequired,
};

export default MfaWrapper;

