import {
	INACTIVE_IPS_TYPE,
	inactiveIpsColumns,
	NEW_IPS_TYPE,
	newIpsColumns,
} from '../InactiveEndpointsConfig';
import IpsTable from './IpsTable';
import { useStyles } from '../InactiveEndpoints.css';
import { NoData } from '../../sharedComponents/NoData';
import { toLocaleDateString } from '../../../../../../helpers/datesHelper';
import React from 'react';
import PropTypes from 'prop-types';

const NewAndInactiveTables = ({ newIps, newIpsNumber, inactiveIps, inactiveIpsNumber, isLoading }) => {
	let newIpsRows;
	let inactiveIpsRows;
	if (!isLoading) {
		if (newIps.length === 0 && inactiveIps.length === 0) {
			return (
				<NoData/>
			);
		}

		newIpsRows = newIps.map(item => (
			{
				ip: item.ip,
				firstSeen: toLocaleDateString(item.firstSeen, true),
				deviceName: item.deviceName,
			}
		));

		inactiveIpsRows = inactiveIps.map(item => (
			{
				ip: item.ip,
				lastSeen: toLocaleDateString(item.lastSeen, true),
				deviceName: item.deviceName,
			}
		));
	}

	const classes = useStyles();
	return (
		<div className={classes.card}>
			<IpsTable
				tableType={NEW_IPS_TYPE}
				columns={newIpsColumns}
				rows={newIpsRows}
				ipsNumber={newIpsNumber}
				isLoading={isLoading}/>
			<IpsTable
				tableType={INACTIVE_IPS_TYPE}
				columns={inactiveIpsColumns}
				rows={inactiveIpsRows}
				ipsNumber={inactiveIpsNumber}
				isLoading={isLoading}/>
		</div>
	);
};

NewAndInactiveTables.propTypes = {
	newIps: PropTypes.array,
	newIpsNumber: PropTypes.number,
	inactiveIps: PropTypes.array,
	inactiveIpsNumber: PropTypes.number,
	isLoading: PropTypes.bool,
};
NewAndInactiveTables.defaultValue = {
	newIps: [],
	newIpsNumber: 0,
	inactiveIps: [],
	inactiveIpsNumber: 0,
	devicesIdToDeviceNameMapping: {},
};

export default NewAndInactiveTables;
