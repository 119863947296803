import React from "react";
import createGroupsSide from "../../../../../../assets/walkthrough/groups/steps/creatingGroupsSide.png";
import itemType from "../../../../../../assets/walkthrough/groups/steps/itemTypeContent.png";
import {STEP_2_DESCRIPTION, STEP_2_TITLE} from "../../texts";
import StepTemplate from "../../../../../../shared/components/stepTemplates/stepTemplate";
import MainPanelTemplateV1
    from "../../../../../../shared/components/stepTemplates/mainPanelTemplateV3/mainPanelTemplateV3";
import {useStyles} from "./step2.css";

const Step2 = () => {
    const classes = useStyles();
    return (
        <StepTemplate sidePanelImage={createGroupsSide}>
            <MainPanelTemplateV1 title={STEP_2_TITLE}
                                 image={<img src={itemType} alt={"Type options"} className={classes.image}/>}
                                 description={STEP_2_DESCRIPTION}/>
        </StepTemplate>
    )
}

export default Step2;