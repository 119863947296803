
import http from '../../../../services/httpService/http';
import { THREATS_URI } from './urls';

const getThreats = () => http.get(THREATS_URI);

const markThreats = async (eventId, status) => http.put(THREATS_URI + eventId, { isMarked: status });

const hideThreats = async eventId => http.put(THREATS_URI + eventId, { isHidden: true });

const feedbackThreaths = async () => http.get(`${THREATS_URI}feedback`);

export {
	getThreats,
	markThreats,
	feedbackThreaths,
	hideThreats,
};

