import React, {Component} from "react";
import {Field, FieldArray, reduxForm} from 'redux-form';
import SubmitButton from "../../../../shared/components/form/Buttons/SubmitButton";
import './baseIntegrationForm.scss';
import {FormWrapper} from "../../../../shared/components/simpleTable/simpleFormContainer";

class BaseIntegrationForm extends Component {

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    render() {
        const {title, subTitle, BaseIntegrationFormComponent, withSaveButton = true} = this.props;
        return (
            <form
                onSubmit={this.props.handleSubmit}
                className='modal-form'
                onKeyPress={this.onKeyPress}
            >
                <FormWrapper title={title} subTitle={subTitle}>
                    <div className="email-recipients">
                        <BaseIntegrationFormComponent {...this.props} />
                    </div>
                </FormWrapper>
                {withSaveButton &&
                    <SubmitButton
                        label={'Save'}
                        style={{textAlign: 'center', marginBottom: '20px'}}
                    />}
            </form>)

    }
}

export default reduxForm({
    enableReinitialize: true,
})(BaseIntegrationForm);

