import {
    GET_INSIGHTS,
    REMOVE_INSIGHT
} from './actionTypes';
import Storage from "../../../../../services/storage";
import _ from 'lodash'; 

const initialState = {
    insights: [],
    removedInsights: !_.isEmpty(Storage.get('removedInsights')) ? Storage.get('removedInsights') : []
};

export default function (state = initialState, payload) {
    switch (payload.type) {
        case GET_INSIGHTS:
            return {
                ...state,
                insights: payload.payload
            };
        case REMOVE_INSIGHT:
            Storage.set('removedInsights', payload.payload)
            return {
                ...state,
                insights: state.insights,
                removedInsights: payload.payload
            }
        default:
            return state;
    }
}
