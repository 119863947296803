import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	wrapper: {
		textAlign: 'center',
		display: 'flex',
		maxWidth: '100%',
		flexDirection: 'column',
		padding: '1em',
	},
	connectionsWrapper: {
		display: 'flex',
		flexDirection: 'column',
		padding: '0.5em',
	},
	topSusIpsItem: {
		display: 'inline-flex',
		justifyContent: 'center',
	},
}));

export { useStyles };
