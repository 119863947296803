import http from '../../../../services/httpService/http';
import {
	INTEGRATIONS_AVAILABLE_PATH,
	INTEGRATIONS_ENABLED_PATH,
	INTEGRATIONS_SERVER_STATUS_PATH,
} from '../constants/urls';

const getAvailableIntegrations = () => http.get(INTEGRATIONS_AVAILABLE_PATH);
const getEnabledIntegrations = () => http.get(INTEGRATIONS_ENABLED_PATH);

const activateIntegration = (type, newValue) => http.put(INTEGRATIONS_ENABLED_PATH, { type, newValue });

const getSyslogServerStatus = () => http.get(INTEGRATIONS_SERVER_STATUS_PATH);

export {
	getAvailableIntegrations,
	getEnabledIntegrations,
	activateIntegration,
	getSyslogServerStatus,
};
