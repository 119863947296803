import { WidgetContainer } from '../../../index';
import React, { useEffect, useState } from 'react';
import { ENDPOINT_DEVIATION_WIDGET_TITLE } from '../deviceMonitoringTexts';
import { toLocaleDateString } from '../../../../../helpers/datesHelper';
import { NoData } from '../sharedComponents/NoData';
import {
	AVERAGE_VOLUME_KEY,
	CURRENT_VOLUME_KEY,
	TIMESTAMP_KEY,
} from './EndpointsDeviationConfig';
import PropTypes from 'prop-types';
import utils from '../../../../../services/util';
import EndpointsDeviationTable from './helpers/EndpointsDeviationTable';
import { mergeDeviceNameByMapping } from '../../utils/DeviceMonitoringUtils';

const EndpointDeviation = ({ endpointsDeviation, isLoading, devicesIdToDeviceNameMapping }) => {
	const [endpointsDeviationRows, setEndpointsDeviationRows] = useState([]);
	const convertBpsToSpeedUnits = value => utils.convertNumber(value, 2, true) + 'bps';

	useEffect(() => {
		if (!isLoading) {
			const endpointsDeviationRows = endpointsDeviation.map(item => (
				{ ...item,
					[AVERAGE_VOLUME_KEY]: convertBpsToSpeedUnits(item[AVERAGE_VOLUME_KEY]),
					[CURRENT_VOLUME_KEY]: convertBpsToSpeedUnits(item[CURRENT_VOLUME_KEY]),
					[TIMESTAMP_KEY]: toLocaleDateString(item[TIMESTAMP_KEY], true) }
			));
			const endpointsDeviationRowsWithDeviceName = mergeDeviceNameByMapping(endpointsDeviationRows, devicesIdToDeviceNameMapping);
			setEndpointsDeviationRows(endpointsDeviationRowsWithDeviceName);
		}
	}, [endpointsDeviation, isLoading]);

	return (
		<WidgetContainer title={ENDPOINT_DEVIATION_WIDGET_TITLE}>
			{
				!isLoading && endpointsDeviation.length === 0 ? <NoData/>
					: <EndpointsDeviationTable
						endpointsDeviationRows={endpointsDeviationRows}
						isLoading={isLoading}
					/>
			}
		</WidgetContainer>
	);
};

EndpointDeviation.propTypes = {
	endpointsDeviation: PropTypes.array,
	isLoading: PropTypes.bool,
	devicesIdToDeviceNameMapping: PropTypes.object,
};
EndpointDeviation.defaultValue = {
	devicesIdToDeviceNameMapping: {},
};

export default EndpointDeviation;
