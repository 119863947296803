import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import StepsIndicator from './stepIndicator/StepsIndicator';

import useStyles from './Stepper.css';

const Stepper = ({ steps, containerStyle }) => {
	const classes = useStyles();

	const [activeStep, setActiveStep] = useState(0);
	const [completedSteps, setCompletedSteps] = useState({});

	const handleNext = () => {
		setCompletedSteps(prevCompletedSteps => Object.assign(prevCompletedSteps, { [activeStep]: true }));
		setActiveStep(prevActiveStep => ++prevActiveStep);
	};

	const handleBack = () => {
		if (activeStep > 0) {
			setCompletedSteps(prevCompletedSteps => Object.assign(prevCompletedSteps, { [activeStep - 1]: false }));
			setActiveStep(prevActiveStep => --prevActiveStep);
		}
	};

	const CurrentStepComponent = steps[activeStep].component;

	return (
		<Grid container style={containerStyle && containerStyle}>
			<Grid item xs={12}>
				<StepsIndicator activeStep={activeStep} completedSteps={completedSteps} steps={steps} />
			</Grid>
			<Grid item xs={10} className={classes.stepComponentRoot}>
				<CurrentStepComponent onNext={handleNext} onBack={handleBack} {...steps[activeStep].additionalProps} />
			</Grid>
		</Grid>
	);
};

Stepper.propTypes = {
	steps: PropTypes.array.isRequired,
	containerStyle: PropTypes.object,
};

export default Stepper;
