import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { change, destroy } from 'redux-form';
import SimpleModal from '../../../../../shared/components/simpleTable/simpleModal';
import AddCollectorOnFly from '../addCollectorOnFly/addCollectorOnFly';
import {
	ADD_DEVICE_STEP, ADD_LEGACY_COLLECTOR_STEP, ADD_SAMPLER_STEP, CREATE_MODE,
	EDIT_MODE, FORMS_EXTRA_PROPS, HEADER_TEXT, LEGACY_COLLECTOR_TYPE, SAMPLER_TYPE,
} from '../addDeviceConfig';
import AddDeviceModal from '../addDeviceModal/addDeviceModal';
import { useStyles } from '../addDeviceModal/addDeviceModal.css';
import { cloudProvidersFormMapping } from '../../publicCloudDevice/publicCloudForms/formsConfigurations';

const addDeviceSteps = {
	[ADD_DEVICE_STEP]: AddDeviceModal,
	// eslint-disable-next-line new-cap
	[ADD_LEGACY_COLLECTOR_STEP]: AddCollectorOnFly(LEGACY_COLLECTOR_TYPE),
	// eslint-disable-next-line new-cap
	[ADD_SAMPLER_STEP]: AddCollectorOnFly(SAMPLER_TYPE),
};

const AddDeviceWrapper = ({
	isEditMode,
	modalOpen,
	toggle,
	initialSelectedDeviceType,
	addCollectorOnTheFly,
	...props
}) => {
	const classes = useStyles();
	const [step, setStep] = useState(ADD_DEVICE_STEP);
	const [headerText, setHeaderText] = useState();
	const [selectedDeviceType, setSelectedDeviceType] = useState(initialSelectedDeviceType);
	const SelectedStepComponent = addDeviceSteps[step];
	const dispatch = useDispatch();

	useEffect(() => () => destroyForms(), []);

	useEffect(() => {
		generateHeader();
	}, [isEditMode, step]);

	const destroyForms = () => {
		for (const formExtraProps in FORMS_EXTRA_PROPS) {
			if (FORMS_EXTRA_PROPS[formExtraProps].form) {
				dispatch(destroy(FORMS_EXTRA_PROPS[formExtraProps].form));
			}
		}

		// Remove the cloud's forms
		Object.values(cloudProvidersFormMapping).forEach(currentForm => {
			dispatch(destroy(currentForm.form));
		});
	};

	const returnToBeginning = (event, deviceType) => {
		setStep(ADD_DEVICE_STEP);
		if (deviceType) {
			setSelectedDeviceType(deviceType);
		}
	};

	const generateHeader = () => {
		setHeaderText(HEADER_TEXT[step][isEditMode ? EDIT_MODE : CREATE_MODE]);
	};

	const handleCreateCollectorOnTheFly = collector => {
		const { field, newValue } = addCollectorOnTheFly(collector);
		dispatch(change(FORMS_EXTRA_PROPS[selectedDeviceType].form, field, newValue));
	};

	return (
		<>
			<SimpleModal
				header={headerText}
				returnBackCallback={ step !== ADD_DEVICE_STEP && returnToBeginning}
				isOpen={modalOpen}
				width={640}
				toggle={toggle}>

				<Paper className={classes.rootPaper}>
					<SelectedStepComponent {...props} isEditMode={isEditMode} setStep={setStep} returnToBeginning={returnToBeginning} toggle={toggle} selectedDeviceType={selectedDeviceType} setSelectedDeviceType={setSelectedDeviceType} onCollectorCreate={handleCreateCollectorOnTheFly}/>
				</Paper>
			</SimpleModal>
		</>);
};

AddDeviceWrapper.propTypes = {
	isEditMode: PropTypes.bool,
	modalOpen: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
	initialSelectedDeviceType: PropTypes.string,
	addCollectorOnTheFly: PropTypes.func,
};

AddDeviceWrapper.defaultProps = {
	isEditMode: false,
	modalOpen: false,
};

export default AddDeviceWrapper;

