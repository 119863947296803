import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GroupIcon from '../../../../assets/modal/group-icon.png';
import StyleButton from '../../../../shared/components/form/Buttons/StyleButton';
import SubmitButton from '../../../../shared/components/form/Buttons/SubmitButton';
import { FormWrapper } from '../../../../shared/components/simpleTable/simpleFormContainer';
import SimpleModal from '../../../../shared/components/simpleTable/simpleModal';
import { openSnackbar } from '../../../snackbarAlert/controller/redux/actions';
import { formatAddresses } from '../../controller/businessLogic/addressFormatter';
import { useStyles } from './groupModal.css';
import {
	ADDRESS_EXISTS_ERROR_TEXT,
	CREATE_GROUP_ERROR,
	EDIT_GROUP_ERROR,
	VALIDATION_ERROR_TEXT,
} from './groupModalConfig';
import GroupTable from './groupTable';
import { groupTypesManager } from './groupTypes';
import { configGroupManagement } from '../configGroupManagement';

const modalIconStyle = { marginRight: '4%', marginTop: '2%', width: 131, height: 56 };

const GroupModal = ({ isOpen, onCloseModal, isEditMode, selectedGroup, onSaveGroup }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const userFeatures = useSelector(state => state.account.features);

	const [groupName, setGroupName] = useState(isEditMode ? selectedGroup.objectGroup.title : '');
	const [groupDescription, setGroupDescription] = useState(isEditMode ? selectedGroup.objectGroup.description : '');
	const [addresses, setAddresses] = useState(isEditMode ? formatAddresses(selectedGroup) : []);
	const [inputType, setInputType] = useState('');
	const [newAddress, setNewAddress] = useState('');
	const [newAddressError, setNewAddressError] = useState('');
	const [groupNameTouched, setGroupNameTouched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			setIsLoading(true);
			const objectItems = addresses.map(address => ({
				itemId: address.itemId,
				type: address.type,
				value: { value: address.address },
			}));

			const groupBody = {
				objectGroup: {
					title: groupName,
					description: groupDescription,
					creationType: configGroupManagement.GROUP_CUSTOM_CREATION_TYPE,
					objectType: configGroupManagement.GROUP_OBJECT_TYPE,
				},
				objectItems,
			};

			await onSaveGroup(isEditMode, groupBody);
			setIsLoading(false);
			onCloseModal();
			// eslint-disable-next-line no-unused-vars
		} catch (e) {
			dispatch(openSnackbar('error', isEditMode ? EDIT_GROUP_ERROR : CREATE_GROUP_ERROR, 4000));
		}
	};

	const addNewAddress = () => {
		const newAddressTrimmed = newAddress.trim();
		const existingValueIndex = addresses.findIndex(addressObj => addressObj.address === newAddressTrimmed);
		if (existingValueIndex !== -1) {
			setNewAddressError(ADDRESS_EXISTS_ERROR_TEXT);
			// eslint-disable-next-line no-negated-condition
		} else if (!groupTypesManager[inputType].validate(newAddressTrimmed)) {
			setNewAddressError(VALIDATION_ERROR_TEXT);
		} else {
			const newAddressObj = { address: newAddressTrimmed, type: inputType };
			setAddresses(prevAddresses => [...prevAddresses, newAddressObj]);
			setNewAddress('');
			setNewAddressError('');
		}
	};

	const handleTypeChange = e => {
		setInputType(e.target.value);
		setNewAddress('');
		setNewAddressError('');
	};

	const handleNewAddressChange = e => {
		setNewAddress(e.target.value);
		setNewAddressError('');
	};

	const handleAddressKeyDown = e => {
		if (e.key === 'Enter') {
			e.preventDefault();
			addNewAddress(e.target.value);
		}
	};

	const handleDeleteAddress = addressValue => {
		setAddresses(prevAddresses => prevAddresses.filter(addressObj => addressObj.address !== addressValue));
	};

	const typeSelectMenuProps = {
		classes: {
			paper: classes.paper,
			list: classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
	};

	return (
		<SimpleModal
			header={isEditMode ? 'Edit group' : 'Create a group'} isOpen={isOpen} icon={GroupIcon}
			toggle={onCloseModal} iconStyle={modalIconStyle}>
			<form onSubmit={handleSubmit}>
				<FormWrapper title="Group Properties" titleStyle={classes.title} wrapperClass={classes.wrapper}>
					<TextField
						value={groupName}
						label="Group Name"
						onChange={e => {
							setGroupName(e.target.value);
						}}
						onBlur={() => {
							setGroupNameTouched(true);
						}}
						error={!groupName && groupNameTouched}
						helperText={groupName === '' && groupNameTouched && 'Please enter group name'}
						className={classes.root}
						variant="outlined"
						name="name"
						type="text"
						margin="none"
						size="small"
						required
					/>
					<TextField
						value={groupDescription}
						onChange={e => {
							setGroupDescription(e.target.value);
						}}
						label="Description"
						variant="outlined"
						className={classes.root}
						name="name"
						type="text"
						margin="none"
						size="small"
					/>
					<div>
						<Typography className={classes.title}>Add items</Typography>
						<Typography className={classes.description}>
							Example: 127.0.0.1/31 (CIDR) / 00:1B:44:11:3A:B7 (MAC) / i-045q5308278b82z32 (Instance ID)
						</Typography>
					</div>
					<div>
						<Grid container spacing={1}>
							<Grid item xs={5}>
								<FormControl fullWidth variant="outlined" size="small">
									<InputLabel id="type-select-label">Type</InputLabel>
									<Select
										label="Type"
										labelId="type-select-label"
										value={inputType}
										onChange={handleTypeChange}
										MenuProps={typeSelectMenuProps}
										classes={{ root: classes.select }}
										disableunderline
										displayEmpty
									>
										{Object.keys(groupTypesManager).map(
											(typeKey, index) =>
												groupTypesManager[typeKey].shouldShow(userFeatures) && (
													<MenuItem key={index} value={typeKey}>
														{groupTypesManager[typeKey].selectDisplayText}
													</MenuItem>
												),
										)}
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={5}>
								<TextField
									label={
										inputType ? groupTypesManager[inputType].inputPlaceholder : 'Select type first'
									}
									disabled={!inputType}
									value={newAddress}
									onChange={handleNewAddressChange}
									onKeyDown={handleAddressKeyDown}
									className={classes.root}
									variant="outlined"
									name="name"
									type="text"
									margin="none"
									size="small"
									error={newAddressError !== ''}
									helperText={newAddressError}
								/>
							</Grid>
							<Grid item xs={2}>
								<StyleButton label="Add" className={classes.addButton} onClick={addNewAddress}/>
							</Grid>
						</Grid>
					</div>
					<GroupTable rows={addresses} onDeleteAddress={handleDeleteAddress}/>
				</FormWrapper>
				<div className={classes.formActions}>
					{isLoading ? (
						<CircularProgress color="primary"/>
					) : (
						<SubmitButton
							label={isEditMode ? 'Save' : 'Create group'}
							className={classes.saveButton}
						/>
					)}
				</div>
			</form>
		</SimpleModal>
	);
};

GroupModal.propTypes = {
	isEditMode: PropTypes.bool,
	onCloseModal: PropTypes.func,
	isOpen: PropTypes.bool,
	onSaveGroup: PropTypes.func,
	selectedGroup: PropTypes.object,
};

export default GroupModal;
