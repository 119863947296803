import Grid from '@material-ui/core/Grid';
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

import { ReactComponent as InfoIcon } from '../../../../../assets/ip-more-info.svg';
import CopyButtonComponent from '../../../../../shared/components/copyButtonComponent/copyButtonComponent';
import SpanWithTranslation from '../../../collapsibleRecordComponent/collapsibleRecordSummary/spanWithTranslation/spanWithTranslation';

import useStyles from './destIps.css';
import EnrichmentPopoverWrapper
	from '../../../../../shared/components/enrichmentPopoverWrapper/enrichmentPopoverWrapper';
import { formatBase10 } from '../../../formatters';

const DestIps = ({ rows }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			{rows.map((row, index) =>
				<div key={index}>
					<Grid className={classes.row} container xs={12}>
						<Grid item xs={5}>
							<SpanWithTranslation translation={row.MAC} additionalClasses={classes.summaryValues}>
								{row.IP}
							</SpanWithTranslation>
						</Grid>
						<Grid item xs={5}>
							{formatBase10(row.Count)}
						</Grid>
						<Grid item xs={2} className={classes.icons}>
							<EnrichmentPopoverWrapper ip={row.IP} mac={row.MAC}>
								<InfoIcon className={classes.infoIcon}/>
							</EnrichmentPopoverWrapper>
							<div className={classes.copyWrapper}>
								<CopyButtonComponent
									valueToCopy={row.IP}
									successMessage={'Copied!'}
									labelSize={'xs'}
									iconHeight={20}
									iconWidth={20}
								/>
							</div>
						</Grid>
					</Grid>
					{ index === rows.length - 1 ? null : <Divider/>}
				</div>,
			)}
		</div>
	);
};

DestIps.propTypes = {
	rows: PropTypes.array,
};

export default DestIps;
