import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	formWrapper: {
		width: '100%',
	},
	textField: {
		width: '100%',
		fontSize: '14px',
	},
	buttonWrapper: {
		marginTop: '50px',
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		width: '300px',
		height: '50px',
		fontWeight: 700,
		fontSize: '16px',
	},
	explanationWrapper: {
		display: 'flex',
		flexDirection: 'column',
		margin: '20px 0',
	},
	explanationLabel: {
		fontSize: '16px',
		lineHeight: '20px',
		fontWeight: 400,
		margin: '3px 0',
	},
	explanationLabelTop: {
		marginBottom: '10px !important',
	},
}));

export default useStyles;
