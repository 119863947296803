import Typography from '@material-ui/core/Typography/Typography';
import PropTypes from 'prop-types';
import { React } from 'react';
import { useSelector } from 'react-redux';

import GroupQuickEdit from '../../../../shared/components/GroupQuickEdit/GroupQuickEdit';
import LookingDataImage from './assets/waiting-for-data.svg';
import { useStyles } from './lookingForData.css';

const WAITING_FOR_DATA_HEADER = 'Cynamics is waiting for data from your device';
const UNMONITORED_NETWORKS_CATEGORY_KEY = 'UNMONITORED_NETWORKS';
const UNMONITORED_NETWORKS_TEXT = 'Do you have any networks you would like Cynamics to exclude from monitoring? Edit the existing “Unmonitored Networks” group.';
const UNMONITORED_NETWORKS_NOTE = '*You can always edit this group from the IP Groups page.';

const LookingForData = ({ redownloadConfig, openNeedHelp }) => {
	const classes = useStyles();

	const unmonitoredNetworksGroup = useSelector(state => state.account.groups).find(group => group.objectGroup.categoryKeys.includes(UNMONITORED_NETWORKS_CATEGORY_KEY));

	return (
		<div className={classes.container}>
			<div className={classes.header}>
				<img
					src={LookingDataImage} alt="Looking for data from your device"
					className={classes.lookingForDataImage}/>
				<Typography className={classes.title}>{WAITING_FOR_DATA_HEADER}</Typography>
			</div>
			<div className={classes.section}>
				<Typography className={classes.subTitle}>Need help?</Typography>
				<Typography className={classes.text}>
					Make sure you complete all the steps on your <span
						onClick={redownloadConfig}
						className={`${classes.text} ${classes.clickable}`}>configuration guide</span>. <br/>
					For commonly encountered issues and for more assistance <span
						onClick={openNeedHelp}
						className={`${classes.text} ${classes.clickable}`}>click here</span>.
				</Typography>
			</div>
			{unmonitoredNetworksGroup && <div className={classes.section}>
				<Typography className={classes.subTitle}>Unmonitored networks</Typography>
				<Typography className={classes.text}>{UNMONITORED_NETWORKS_TEXT}</Typography>
				<GroupQuickEdit group={unmonitoredNetworksGroup}/>
				<Typography className={classes.noteText}>{UNMONITORED_NETWORKS_NOTE}</Typography>
			</div>}
		</div>
	);
};

LookingForData.propTypes = {
	openNeedHelp: PropTypes.func,
	redownloadConfig: PropTypes.func,
};

export default LookingForData;
