import { DETECTION_TYPES } from './detectionsTableConfig';
import vcaLogicConfig from '../../../containers/vcaManagement/services/vcaLogicConfig.json';

export const DETECTIONS_EXPLANATIONS = {
	[DETECTION_TYPES.VCA](eventData) {
		return vcaLogicConfig.detectionLogicsDict[eventData.additionalInfo.logicKey][eventData.additionalInfo.logicVersion]
			.dashboard;
	},
	[DETECTION_TYPES.VCA_RT](eventData) {
		return vcaLogicConfig.detectionLogicsDict[eventData.additionalInfo.logicKey][eventData.additionalInfo.logicVersion]
			.dashboard;
	},
	[DETECTION_TYPES.CUSTOM_ALERT]() {
		return { explanation: 'Description will be added later on' };
	},
};
