import http from '../../../../services/httpService/http';
import { ALERT_PATH, ALERT_STATISTICS_PATH, ALERT_TOOGLE_PATH } from './urls';

const CREATE_LINK_QUERY_FROM_ALERT_POLICY_URL = '/link-queries/v1/alert-policy';

const createAlert = newAlert => http.post(ALERT_PATH, { ...newAlert, timeWindow: parseInt(newAlert.timeWindow) });

const getAlerts = () => http.get(ALERT_PATH);

const createQueryFromAlertPolicy = (alertPolicyId, startTime, endTime) => http.post(CREATE_LINK_QUERY_FROM_ALERT_POLICY_URL, { alertPolicyId, startTime, endTime });

const toogleAlert = (alerteId, enabled) => http.put(`${ALERT_TOOGLE_PATH}${alerteId}`, { enabled });

const deleteAlert = alerteId => http._delete(`${ALERT_PATH}${alerteId}`);

const updateAlert = (alerteId, newPayload) => http.put(`${ALERT_PATH}${alerteId}`, { ...newPayload, timeWindow: parseInt(newPayload.timeWindow) });

const getStatistics = (alerteId, startDate, endDate) => http.get(`${ALERT_STATISTICS_PATH}/${alerteId}?startTime=${startDate}&endTime=${endDate}&timeUnit=day`);

export { createAlert, getAlerts, getStatistics, toogleAlert, deleteAlert, updateAlert, createQueryFromAlertPolicy };
