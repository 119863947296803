import CheckboxInput from "../../../shared/components/checkboxInput/CheckboxInput";
import React from "react";
import {useStyles} from "./inOut.css";

const InOut = ({formName}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CheckboxInput name={"isIn"} label={"In"} formName={formName}/>
            <CheckboxInput name={"isOut"} label={"Out"} formName={formName}/>
        </div>
    )
}

export default InOut;