import React, {useEffect, useState} from 'react';
import {Typography} from "@material-ui/core";
import clsx from "clsx";
import {useStyles} from "./priceBlock.css";
import StyledSwitch from "../../../../shared/components/CustomSwitch/Switch";
import {BASIC_PLAN_ID, CUSTOM_PLAN_ID, FREE_PLAN_ID} from "../../../../constants/planFields";

const Free = () => {
    const classes = useStyles();

    return (
        <div className={classes.box}>
            <Typography align={"center"} className={clsx(classes.priceTitle, classes.boldCaption)}
                        variant={"h4"}>Free</Typography>
            <div className={classes.subTextForImage}/>
        </div>
    )
}

const Custom = () => {
    const classes = useStyles();
    return (
        <div className={classes.box}>
            <div className={classes.chat}/>
            <Typography align={"center"} variant={"body1"}
                        className={clsx(classes.boldCaption, classes.subTextForImage)}>Contact
                our sales
                team</Typography>
        </div>
    );
}

const TwoOptionPricePlan = ({pricesArray,onPriceChange, align="center", selectedPriceName, isDisabled}) => {
    const classes = useStyles({align});
    const [selectedPriceIndex, setSelectedPriceIndex] = useState(()=>{
        const priceIndexFromArray =  pricesArray.findIndex(price => price.priceName === selectedPriceName);
        return priceIndexFromArray == -1? 0 : priceIndexFromArray;
    });

    useEffect(() => {
        const currentIndex = pricesArray.findIndex(price => price.priceName === selectedPriceName);
        currentIndex !== -1 && setSelectedPriceIndex(currentIndex);
    },[selectedPriceName]);

    const onSwitchChange = (event) => {
        const currentPriceIndex = Number(event.target.checked);
        setSelectedPriceIndex(currentPriceIndex);
        onPriceChange(pricesArray[currentPriceIndex].priceName);
    }

    return(
        <div className={clsx(classes.box, classes.twoOptionPricePlan)}>
            <Typography className={clsx(classes.priceTitle, classes.boldCaption)} align={"center"}
                        variant={"h4"}>${pricesArray[selectedPriceIndex].price.toLocaleString()}
                <Typography variant={"caption"}>/{pricesArray[selectedPriceIndex].displayText}</Typography>
            </Typography>

            <div className={classes.switchContainer}>
                <Typography variant={"body2"}>{pricesArray[0].displayText}</Typography>
                <StyledSwitch checked={!!selectedPriceIndex} onChange={onSwitchChange} disabled={isDisabled} />
                <Typography variant={"body2"}>{pricesArray[1].displayText}</Typography>
            </div>

        </div>
    )
}

export default {[FREE_PLAN_ID]: Free, [BASIC_PLAN_ID]: TwoOptionPricePlan, [CUSTOM_PLAN_ID]: Custom};