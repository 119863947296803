import {  UPDATE_REGISTER_CAPTCHA} from "./registerActions";

const initialState = {
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        
        case UPDATE_REGISTER_CAPTCHA:
            
            return{
                ...state,
                captchaToken: payload.captchaToken
            }
            
        default:
            return state;
    }
}