import action from "../middleware";
import http from "../../../../../services/httpService/http";
import {PAYMENTS_URI} from "../../services/urls";
export const REQ_CHARGE = "REQ_CHARGE";
export const REQ_CHARGE_ERROR = "REQ_CHARGE_ERROR"

const chargeAsync = (paymentId, planType, billingInformation) => {

   return action(async dispatch => {
        
         try {
            let paymentResult = await http.post(`${PAYMENTS_URI}/create`, {paymentId: paymentId, planType: planType, billingInformation: billingInformation});
            
            dispatch({
                type: REQ_CHARGE,
                payload: {paymentResult}
            });
            return paymentResult.result;
        } catch (e) {
            console.log(e)      
            dispatch({
                type: REQ_CHARGE,
                payload: {payment : null} 
            });
        } 

    }) 
}

export {
    chargeAsync
}
