import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	gridChildrenStyle: {
		marginBottom: theme.spacing(2),
	},
	smallInputSize: {
		maxWidth: '440px',
		height: '70px',
	},
	fieldLabel: {
		fontSize: '14px',
		color: '#425262',
		marginLeft: '5px',
	},
	descriptionInput: {
		'& input::placeholder': {
			fontSize: '14px',
			color: '#7F7F7F !important',
			fontFamily: theme.typography.fontFamily,
			fontWeight: 400,
			opacity: 1,
		},
	},
	title: {
		marginBottom: '8px',
		fontSize: '16px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	section: {
		fontSize: '14px',
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	checkboxWrapper: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 20px 0 0',
	},
	checkboxRoot: {
		padding: '0 4px 0 0',
	},
	checkboxLabel: {
		fontWeight: 400,
		fontSize: '14px',
		color: theme.palette.inputText.main,
		margin: 0,
	},
	checkboxIcon: {
		borderRadius: 3,
		border: 'black',
		flex: '0 0 auto',
		padding: '12px',
		transform: 'scale(0.7)',
		outline: 'auto',
		overflow: 'visible',
		fontSize: '1.5rem',
		textAlign: 'center',
	},
	submitButton: {
		marginTop: '16px',
		alignSelf: 'center',
	},
	errorMessage: {
		display: 'inline-block',
		marginLeft: '8px!important',
		color: theme.palette.error.main,
		fontSize: '14px',
	},
	infoIcon: {
		marginLeft: 0,
		fontSize: '18px',
	},
	ruleModalHeader: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	bidirectionalCheckbox: {
		padding: '0.2em',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
	servicesContainer: {
		marginTop: '8px',
	},
}));

export default useStyles;
