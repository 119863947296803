import React, {useEffect} from 'react';
import {Dialog} from "@material-ui/core";
import PropTypes from 'prop-types';
import GeneralIndication from "../../../shared/components/IndicationComponent/types/GeneralIndication";
import ExitButton from "../../../shared/components/ExitButton/ExitButton";
import {getDialogTexts} from "./texts";
import {useSelector} from "react-redux";
import analyzer from "../../../helpers/analyzer";
import {OPEN_ACTION, PLANS_CATEGORY} from "../../../helpers/analyzerConstants";
const ANALYZER_CATEGORY = PLANS_CATEGORY;

const propTypes = {
    callback: PropTypes.func,
    onClose: PropTypes.func.isRequired
};



function PremiumFeatureDialog({onClose, callback}) {
    const requestedFeature = useSelector(state => state.plans.requestedFeature);
    const {productName, baseProductId} = useSelector(state => state.plans.currentPlanDetails);
    useEffect(()=>{
        analyzer.event({category: ANALYZER_CATEGORY, action: OPEN_ACTION, label: 'PLANS_FLOW_MODAL'})
    }, [])

    const {header, description, button} = getDialogTexts(baseProductId, requestedFeature);

    return (
        <Dialog open onClose={onClose}>
                <ExitButton onClick={onClose} />
            <GeneralIndication {...{description, header, callback, buttonText: button}}/>
        </Dialog>

    )

}

PremiumFeatureDialog.propTypes = propTypes;
export default PremiumFeatureDialog;